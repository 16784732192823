export function eventTypesList() {
  const events = [
    {
      "id": 1,
      "name": "Registration",
      "category": "General"
    },
    {
      "id": 2,
      "name": "Update app",
      "category": "General"
    },
    {
      "id": 3,
      "name": "Search",
      "category": "General"
    },
    {
      "id": 4,
      "name": "View content",
      "category": "General"
    },
    {
      "id": 5,
      "name": "Click item",
      "category": "General"
    },
    {
      "id": 6,
      "name": "Login",
      "category": "General"
    },
    {
      "id": 7,
      "name": "Added Payment info",
      "category": "General"
    },
    {
      "id": 8,
      "name": "Purchase/Spend Credit",
      "category": "General"
    },
    {
      "id": 9,
      "name": "Retention",
      "category": "General"
    },
    {
      "id": 10,
      "name": "Level Reached",
      "category": "Gaming"
    },
    {
      "id": 11,
      "name": "Level Completed",
      "category": "Gaming"
    },
    {
      "id": 12,
      "name": "Tutorial Completion",
      "category": "Gaming"
    },
    {
      "id": 13,
      "name": "Achievement Unlocked",
      "category": "Gaming"
    },
    {
      "id": 14,
      "name": "Reward Game Goods",
      "category": "Gaming"
    },
    {
      "id": 15,
      "name": "Other Game Action",
      "category": "Gaming"
    },
    {
      "id": 16,
      "name": "Booking/Reservation",
      "category": "Travelling, Food, LifeStyle"
    },
    {
      "id": 17,
      "name": "Add to Cart",
      "category": "e-commerce"
    },
    {
      "id": 18,
      "name": "Add to Wishlist",
      "category": "e-commerce"
    },
    {
      "id": 19,
      "name": "Checkout initiated",
      "category": "e-commerce"
    },
    {
      "id": 20,
      "name": "Message Sent/Received",
      "category": "Social Media"
    },
    {
      "id": 21,
      "name": "Post/Comment",
      "category": "Social Media"
    },
    {
      "id": 22,
      "name": "Review/Rating/Like",
      "category": "Social Media"
    },
    {
      "id": 23,
      "name": "Follow/Share/Invite",
      "category": "Social Media"
    },
    {
      "id": 1000,
      "name": "Custom",
      "category": "Customizable"
    }
  ];

  return events;
}