import React from "react";
import Button from "react-bootstrap/lib/Button";
import { IDataTable } from "./types";

type ReloadButtonProps = Button.ButtonProps & {
  tableRef: React.RefObject<IDataTable>;
};

const ReloadButton: React.FC<ReloadButtonProps> = ({
  tableRef,
  disabled,
  ...rest
}) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const api = tableRef.current?.api;

    api?.on("preXhr", () => setLoading(true));
    api?.on("xhr", () => setLoading(false));

    return () => {
      api?.off("preXhr").off("xhr");
    };
  }, [tableRef]);

  return (
    <Button
      onClick={() => tableRef.current?.reload()}
      disabled={disabled || loading}
      {...rest}
    >
      <i className={loading ? "fa fa-refresh fa-spin" : "fa fa-refresh"}></i>{" "}
      Reload
    </Button>
  );
};

export default ReloadButton;
