import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "lib/user";

type PrivateRouteProps = {
  loginPath: string;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  loginPath,
  children,
  ...rest
}) => {
  const user = React.useContext(UserContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.isAuthenticated ? (
          children
        ) : (
          <Redirect to={{ pathname: loginPath, state: { from: location } }} />
        )
      }
    ></Route>
  );
};

export default PrivateRoute;
