import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import download from "downloadjs";
import axios from "axios";
import { extractFilenameFromResponse } from "lib/Functions";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { SplitButton, MenuItem } from "react-bootstrap";

export default class Decode extends React.Component {
  static title = "Decode";

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      busy: false,
      lines: [],
      results: []
    };
  }

  render() {
    const error = this.state.error;
    const errorSummary = buildErrorSummary(error);

    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={Decode.title}
        description={
          <p>
            Extracts and decodes publisher/subpublisher ids from lines of text.
            <br />
            Each line may contain a single value, like{" "}
            <em>4d4cddd03380a5529771d46b359e36a8ae36ffb9</em>, or a pattern,
            like <em>1373696415_AED337AF8F22147FF36FC02439505B50</em>.
            <br />
            The tool can recognize several patterns and new ones may be added in
            the future.
            <br />
            You can try entering the text exactly as it was given to you by the
            advertiser. Empty lines will be ignored.
          </p>
        }
      >
        <>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          <Box busy={this.state.busy}>
            <Box.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Encoded Lines</label>
                    <span className="pull-right">
                      Lines entered: {this.state.lines.length}
                    </span>
                    <textarea
                      className="form-control"
                      rows="5"
                      onChange={e =>
                        this.setState({
                          lines: e.target.value.split("\n").filter(x => x)
                        })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </Box.Body>
            <Box.Footer>
              <SplitButton
                title={
                  <>
                    <i className="fa fa-key"></i> Decode
                  </>
                }
                bsStyle="primary"
                onClick={this.handleDecode.bind(this, false)}
                disabled={this.state.lines.length === 0}
              >
                <MenuItem onClick={this.handleDecode.bind(this, true)}>
                  Decode and Download
                </MenuItem>
              </SplitButton>
            </Box.Footer>
          </Box>
          {this.state.results.length > 0 && (
            <Box>
              <Box.Body>
                <table className="table table-condensed table-striped table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Original Text</th>
                      <th>Publisher ID</th>
                      <th>Publisher Name</th>
                      <th>Subpublisher ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.results.map((x, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{x.input}</td>
                        <td>{x.publisherId}</td>
                        <td>{x.publisherName}</td>
                        <td>{x.subpublisherId}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box.Body>
            </Box>
          )}
        </>
      </PageLayout>
    );
  }

  handleDecode(excel, e) {
    e.preventDefault();
    this.setState({ busy: true, error: null });
    axios({
      url: "/api/v1/tools/decode",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: {
        inputs: this.state.lines,
        output: excel ? "Excel" : "Resultset"
      },
      responseType: excel ? "blob" : "json"
    })
      .then(res => {
        const response = res.data;
        if (excel) {
          this.setState({
            error: null,
            busy: false
          });
          const filename = extractFilenameFromResponse(res) || "Decode.xlsx";
          download(
            response,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({
            results: response.results.map(x => ({
              input: x.input,
              publisherId: x.decodedPublisherId,
              publisherName: response.userNames[x.decodedPublisherId],
              subpublisherId: x.decodedSubpublisherId
            })),
            error: null,
            busy: false
          });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              error: e.srcElement.result,
              busy: false
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            error,
            busy: false
          });
        }
      });
  }
}
