export function combineClasses() {
  const classes = [];

  for (var i = 0; i < arguments.length; i++) {
    if (arguments[i]) {
      classes.push(arguments[i]);
    }
  }

  return classes.join(" ");
}

export function extractFilenameFromResponse(res) {
  let contentDisposition = "";
  if (res.headers) {
    if (res.headers.get) {
      contentDisposition = res.headers.get("Content-Disposition");
    } else if (res.headers.hasOwnProperty("content-disposition")) {
      contentDisposition = res.headers["content-disposition"];
    }
  }
  const matches = contentDisposition.match(
    /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  );
  if (Array.isArray(matches) && matches.length > 1) {
    return matches[1].replace(/['"]/g, "");
  }
  return null;
}

export function htmlEscape(str) {
  return str
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

export function payout(value) {
  return parseFloat(value).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export function buildGetParams(pairs) {
  const params = new URLSearchParams();

  for (const key in pairs) {
    const value = pairs[key];

    if (Array.isArray(value)) {
      value.forEach(x => params.append(key, x));
    } else if (value === null) {
      continue;
    } else {
      params.append(key, value);
    }
  }

  return params;
}

export function N(value, decimals) {
  return Number(value).toFixed(decimals);
}

export function NZero(value, decimals) {
  if (value === null || value === undefined) {
    return '-'
  }
  else {
    return Number(value).toFixed(decimals);
  }  
}

export function digitGrouping(d) {
  if (d) {
    return parseInt(d).toLocaleString("en-US", {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  }
  return d;
}

export function handleStatus(status) {
  if(status===401){
    window.location.replace('/login');
  }
  return status >= 200 && status < 300;
}