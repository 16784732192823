import React from "react";
import $ from "jquery";
import moment from "moment-timezone";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";

function renderUserName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    return m.settings.json.names[d] || d;
  }
  return d;
}

function renderDateTime(d, t, r, m) {
  return t === "display" && d ? moment.utc(d).format("YYYY-MM-DD HH:mm:ss") : d;
}

export default class DirectOffers extends React.Component {
  static title = "Direct Offers";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.colorizeRow = this.colorizeRow.bind(this);
  }

  render() {
    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={DirectOffers.title}
        description={
          <p>
            These offers have been marked as Direct. Any campaigns imported via
            API whose package names are found in this list will be rejected by
            Minimob.
            <br />
            You can remove the Direct flag by opening the offer properties in
            Moderation and unchecking the box "Direct Offer".
            <br />
            In order to remove the flag from offers that have been deleted and
            are no longer available in Moderation, please contact your
            Administrator.
          </p>
        }
      >
        <Box>
          <Box.Header>
            <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
          </Box.Header>
          <Box.Body>
            <DataTable
              className="table table-condensed"
              ref={this.tableRef}
              ajax={{
                url: "/api/v1/reports/directoffers",
                dataSrc: "data"
              }}
              columns={[
                {
                  title: `<i title="Store" class="fa fa-info-circle fa-1_4x grey"></i>`,
                  data: "storeId",
                  render: DataTable.StockRenderers.store()
                },
                { title: "Offer ID", data: "_id" },
                { title: "Offer Name", data: "offerName" },
                { title: "Application Name", data: "applicationName" },
                { title: "Package Name", data: "applicationPackageName" },
                {
                  title: "Advertiser",
                  data: "advertiserId",
                  render: renderUserName
                },
                { title: "Offer Status", data: "offerStatus" },
                {
                  title: "Moderator",
                  data: "moderatorId",
                  render: renderUserName
                },
                {
                  title: "Moderation Date",
                  data: "moderationDate",
                  className: "text-nowrap",
                  render: renderDateTime,
                  order: "desc"
                }
              ]}
              order={[[8, "desc"]]}
              createdRow={this.colorizeRow}
            />
          </Box.Body>
        </Box>
      </PageLayout>
    );
  }

  colorizeRow(row, data, dataIndex, cells) {
    if (data.offerStatus === "Deleted") {
      $(row).addClass("bg-danger");
    }
  }
}
