import React from "react";
import $ from "jquery";
import "select2";
import "select2/dist/css/select2.css";

class AdvertiserPicker extends React.Component {
  selectRef = React.createRef();
  minimumInputLength = 3;
  url = "/api/v1/lookup/advertiser";
  data = params => ({
    partialName: params.term,
    includeNetworks: this.props.includeNetworks,
    includeNetworkUsers: this.props.includeNetworkUsers
  });

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (!this.selectRef.current) return;
    const $select = $(this.selectRef.current);
    $select.select2({
      allowClear: true,
      placeholder: this.props.placeholder || " ",
      minimumInputLength: this.minimumInputLength,
      language: {
        inputTooShort: () =>
          `Enter at least ${this.minimumInputLength} characters`
      },
      ajax: {
        url: this.url,
        delay: 250,
        data: this.data,
        processResults: data => ({
          results: data.map(x => ({ id: x.key, text: x.value }))
        }),
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      }
    });
    $select.on("change", this.handleChange);
    $select.siblings("span.select2-container").css("width", "100%");
  }

  componentWillUnmount() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("destroy");
  }

  render() {
    return (
      <div>
        <select
          ref={this.selectRef}
          className={this.props.className}
          multiple={this.props.multiple}
        ></select>
      </div>
    );
  }

  handleChange(e) {
    e.preventDefault();
    if (!this.props.onChange) return;
    const data = $(e.target).select2("data");
    if (this.props.multiple) {
      this.props.onChange(data.map(x => x.id));
    } else {
      this.props.onChange(data.length ? data[0].id : null);
    }
  }

  setFocus() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("open");
  }

  clearSingle() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current)
      .val(null)
      .trigger("change");
  }
}

class PublisherPicker extends React.Component {
  selectRef = React.createRef();
  minimumInputLength = 4;
  url = "/api/v1/lookup/publisher";
  data = params => ({
    partialName: params.term
  });

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (!this.selectRef.current) return;
    const $select = $(this.selectRef.current);
    $select.select2({
      allowClear: true,
      tags: false,
      placeholder: this.props.placeholder || " ",
      minimumInputLength: this.minimumInputLength,
      language: {
        inputTooShort: () =>
          `Enter at least ${this.minimumInputLength} characters`
      },
      ajax: {
        url: this.url,
        delay: 250,
        data: this.data,
        processResults: data => ({
          results: data.map(x => ({ id: x.key, text: x.value }))
        }),
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      }
    });
    $select.on("change", this.handleChange);
    $select.siblings("span.select2-container").css("width", "100%");
  }

  componentWillUnmount() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("destroy");
  }

  render() {
    return (
      <div>
        <select
          ref={this.selectRef}
          className={this.props.className}
          multiple={this.props.multiple}
        ></select>
      </div>
    );
  }

  handleChange(e) {
    e.preventDefault();
    if (!this.props.onChange) return;
    const data = $(e.target).select2("data");
    if (this.props.multiple) {
      this.props.onChange(data.map(x => x.id));
    } else {
      this.props.onChange(data.length ? data[0].id : null, data.length ? data[0].text : null);
    }
  }

  setFocus() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("open");
  }

  clearSingle() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current)
      .val(null)
      .trigger("change");
  }
}

class ApplicationPicker extends React.Component {
  selectRef = React.createRef();
  minimumInputLength = 10;
  url = "/api/v1/lookup/application";
  data = params => ({
    id: params.term
  });

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (!this.selectRef.current) return;
    const $select = $(this.selectRef.current);
    $select.select2({
      allowClear: true,
      placeholder: this.props.placeholder || " ",
      minimumInputLength: this.minimumInputLength,
      language: {
        inputTooShort: () =>
          `Enter at least ${this.minimumInputLength} characters`
      },
      ajax: {
        url: this.url,
        delay: 250,
        data: this.data,
        processResults: data => ({
          results: data.map(x => ({ id: x.key, text: x.value }))
        }),
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      }
    });
    $select.on("change", this.handleChange);
    $select.siblings("span.select2-container").css("width", "100%");
  }

  componentWillUnmount() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("destroy");
  }

  render() {
    return (
      <div>
        <select
          ref={this.selectRef}
          className={this.props.className}
          multiple={this.props.multiple}
        ></select>
      </div>
    );
  }

  handleChange(e) {
    e.preventDefault();
    if (!this.props.onChange) return;
    const data = $(e.target).select2("data");
    if (this.props.multiple) {
      this.props.onChange(data.map(x => x.id));
    } else {
      this.props.onChange(data.length ? data[0].id : null);
    }
  }

  setFocus() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("open");
  }

  clearSingle() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current)
      .val(null)
      .trigger("change");
  }
}

class CampaignPicker extends React.Component {
  selectRef = React.createRef();
  minimumInputLength = 10;
  url = this.props.url||"/api/v1/lookup/campaign";
  data = params => ({
    id: params.term
  });

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (!this.selectRef.current) return;
    const $select = $(this.selectRef.current);
    $select.select2({
      allowClear: true,
      placeholder: this.props.placeholder || " ",
      minimumInputLength: this.minimumInputLength,
      language: {
        inputTooShort: () =>
          `Enter at least ${this.minimumInputLength} characters`
      },
      ajax: {
        url: this.url,
        delay: 250,
        data: this.data,
        processResults: data => ({
          results: this.props.onProcessResults?this.props.onProcessResults(data):data.map(x => ({ id: x.key, text: x.value }))
        }),
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      }
    });
    $select.on("change", this.handleChange);
    $select.siblings("span.select2-container").css("width", "100%");
  }

  componentWillUnmount() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("destroy");
  }

  render() {
    return (
      <div>
        <select
          ref={this.selectRef}
          className={this.props.className}
          multiple={this.props.multiple}
        ></select>
      </div>
    );
  }

  handleChange(e) {
    e.preventDefault();
    if (!this.props.onChange) return;
    const data = $(e.target).select2("data");
    if (this.props.multiple) {
      this.props.onChange(
        data.map(x => x.id),
        data
      );
    } else {
      this.props.onChange(data.length ? data[0].id : null, data);
    }
  }

  setFocus() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("open");
  }

  clearSingle() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current)
      .val(null)
      .trigger("change");
  }
}

class InvoiceIssuerPicker extends React.Component {
  selectRef = React.createRef();
  minimumInputLength = 4;
  url = "/api/v1/lookup/invoiceissuer";
  data = params => ({
    partialName: params.term
  });

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleUnselect = this.handleUnselect.bind(this);
  }

  componentDidMount() {
    if (!this.selectRef.current) return;
    const $select = $(this.selectRef.current);
    $select.select2({
      allowClear: true,
      tags: false,
      placeholder: this.props.placeholder || " ",
      minimumInputLength: this.minimumInputLength,
      language: {
        inputTooShort: () =>
          `Enter at least ${this.minimumInputLength} characters`
      },
      ajax: {
        url: this.url,
        delay: 250,
        data: this.data,
        processResults: data => ({
          results: data.map(x => ({ id: x._id, text: x.title }))
        }),
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      }
    });
    $select.on("change", this.handleChange);
    $select.on("select2:unselect", this.handleUnselect);
    $select.siblings("span.select2-container").css("width", "100%");
    
    // Fetch the preselected item, and add to the control
    if(this.props.selectedIdValue!==null && this.props.selectedIdValue!==undefined && this.props.selectedTextValue!==null && this.props.selectedTextValue!==undefined){
      var _this = this;
      $.ajax({
        type: 'GET',
        url: this.url+'?partialName='+_this.props.selectedTextValue
      }).then(function (data) {
        // create the option and append to Select2
        if(data && data.length>0){
          var _data = null;
             
          data.forEach((d, idx) => {
            if(d._id===_this.props.selectedIdValue){
              _data = d;
            }
          });
             
          if(_data){
            var option = new Option(_data.title, _data._id, true, true);
            $select.append(option).trigger('change');

            // manually trigger the `select` event
            $select.select2("trigger", "select", {
              data: { id: _this.props.selectedIdValue, text: _this.props.selectedTextValue }
            });
          }
         
         /*$select.trigger({
           type: 'select2:select',
           params: {
             data: data
           }
         });*/
        }    
     });
   }
    
  }

  componentWillUnmount() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("destroy");
  }

  render() {
    return (
      <div>
        <select
          ref={this.selectRef}
          className={this.props.className}
          multiple={this.props.multiple}
          disabled={this.props.disabled}
        ></select>
      </div>
    );
  }

  handleChange(e) {
    e.preventDefault();
    if (!this.props.onChange) return;
    const data = $(e.target).select2("data");
    if (this.props.multiple) {
      this.props.onChange(data.map(x => x.id));
    } else {
      this.props.onChange(data.length ? data[0].id : null, data.length ? data[0].text : null);
    }
  }
  
  handleUnselect(e) {
    e.preventDefault();
    if (!this.props.onUnselect) return;
    this.props.onUnselect();
  }
  
  setFocus() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("open");
  }

  clearSingle() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current)
      .val(null)
      .trigger("change");
  }
}

class InvoiceRecipientPicker extends React.Component {
  selectRef = React.createRef();
  minimumInputLength = 4;
  url = "/api/v1/lookup/invoicerecipient";
  data = params => ({
    partialName: params.term
  });

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleUnselect = this.handleUnselect.bind(this);
  }

  componentDidMount() {
    if (!this.selectRef.current) return;
    const $select = $(this.selectRef.current);
    $select.select2({
      allowClear: true,
      tags: false,
      placeholder: this.props.placeholder || " ",
      minimumInputLength: this.minimumInputLength,
      language: {
        inputTooShort: () =>
          `Enter at least ${this.minimumInputLength} characters`
      },
      ajax: {
        url: this.url,
        delay: 250,
        data: this.data,
        processResults: data => ({
          //results: data.map(x => ({ id: x.key, text: x.value }))
          results: data.map(x => ({ id: x._id, text: x.title, accountCategoryId: x.account_category_id, currency: x.currency, settings: x.settings }))
        }),
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      }
    });
    $select.on("change", this.handleChange);
    $select.on("select2:unselect", this.handleUnselect);
    $select.siblings("span.select2-container").css("width", "100%");

    // Fetch the preselected item, and add to the control
    if(this.props.selectedIdValue!==null && this.props.selectedIdValue!==undefined && this.props.selectedTextValue!==null && this.props.selectedTextValue!==undefined){
      var _this = this;
      $.ajax({
        type: 'GET',
        url: this.url+'?partialName='+_this.props.selectedTextValue
      }).then(function (data) {
        // create the option and append to Select2
        if(data && data.length>0){
          var _data = null;

          data.forEach((d, idx) => {
            if(d._id===_this.props.selectedIdValue){
              _data = d;
            }
          });

          if(_data){
            var option = new Option(_data.title, _data._id, true, true);
            $select.append(option).trigger('change');

            // manually trigger the `select` event
            $select.select2("trigger", "select", {
              data: { id: _this.props.selectedIdValue, text: _this.props.selectedTextValue, accountCategoryId: _data.account_category_id, currency: _data.currency, settings: _data.settings }
            });
          }
        }
      });
    }
  }

  componentWillUnmount() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("destroy");
  }

  render() {
    return (
      <div>
        <select
          ref={this.selectRef}
          className={this.props.className}
          multiple={this.props.multiple}
          disabled={this.props.disabled}
        ></select>
      </div>
    );
  }

  handleChange(e) {
    e.preventDefault();
    if (!this.props.onChange) return;
    const data = $(e.target).select2("data");
    if (this.props.multiple) {
      this.props.onChange(data.map(x => x.id));
    } else {
      this.props.onChange(data.length ? data[0].id : null, data.length ? data[0].text : null, data.length ? data[0].accountCategoryId : null, data.length ? data[0].currency : null, data.length ? data[0].settings : null );
    }
  }

  handleUnselect(e) {
    e.preventDefault();
    if (!this.props.onUnselect) return;
    this.props.onUnselect();
  }
  
  setFocus() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("open");
  }

  clearSingle() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current)
      .val(null)
      .trigger("change");
  }
}

class AccountManagerPicker extends React.Component {
  selectRef = React.createRef();
  minimumInputLength = 4;
  url = "/api/v1/lookup/accountmanager";
  data = params => ({
    partialName: params.term
  });

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleUnselect = this.handleUnselect.bind(this);
  }

  componentDidMount() {
    if (!this.selectRef.current) return;
    const $select = $(this.selectRef.current);
    $select.select2({
      allowClear: true,
      tags: false,
      placeholder: this.props.placeholder || " ",
      minimumInputLength: this.minimumInputLength,
      language: {
        inputTooShort: () =>
          `Enter at least ${this.minimumInputLength} characters`
      },
      ajax: {
        url: this.url,
        delay: 250,
        data: this.data,
        processResults: data => ({
          results: data.map(x => ({ id: x.key, text: x.value }))
        }),
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      }
    });
    $select.on("change", this.handleChange);
    $select.on("select2:unselect", this.handleUnselect);
    $select.siblings("span.select2-container").css("width", "100%");

    // Fetch the preselected item, and add to the control
    if(this.props.selectedIdValue!==null && this.props.selectedIdValue!==undefined && this.props.selectedTextValue!==null && this.props.selectedTextValue!==undefined){
      var _this = this;
      $.ajax({
        type: 'GET',
        url: this.url+'?partialName='+_this.props.selectedTextValue
      }).then(function (data) {
        // create the option and append to Select2
        if(data && data.length>0){
          var _data = null;

          data.forEach((d, idx) => {
            if(d.key===_this.props.selectedIdValue.toString()){
              _data = d;
            }
          });

          if(_data){
            var option = new Option(_data.value, _data.key, true, true);
            $select.append(option).trigger('change');

            // manually trigger the `select` event
            $select.select2("trigger", "select", {
              data: { id: _this.props.selectedIdValue.toString(), text: _this.props.selectedTextValue }
            });
          }
        }
      });
    }
  }

  componentWillUnmount() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("destroy");
  }

  render() {
    return (
      <div>
        <select
          ref={this.selectRef}
          className={this.props.className}
          multiple={this.props.multiple}
          disabled={this.props.disabled}
        ></select>
      </div>
    );
  }

  handleChange(e) {
    e.preventDefault();
    if (!this.props.onChange) return;
    const data = $(e.target).select2("data");
    if (this.props.multiple) {
      this.props.onChange(data.map(x => x.id));
    } else {
      this.props.onChange(data.length ? data[0].id : null, data.length ? data[0].text : null);
    }
  }

  handleUnselect(e) {
    e.preventDefault();
    if (!this.props.onUnselect) return;
    this.props.onUnselect();
  }
  
  setFocus() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current).select2("open");
  }

  clearSingle() {
    if (!this.selectRef.current) return;
    $(this.selectRef.current)
      .val(null)
      .trigger("change");
  }
}

export { AdvertiserPicker, PublisherPicker, ApplicationPicker, CampaignPicker, InvoiceIssuerPicker, InvoiceRecipientPicker, AccountManagerPicker };
