import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import SelectedOffers from "./SelectedOffersModal";
import { UserContext } from "lib/user";

function renderAdvertisersWithTooltip(d, t, r, m) {
  if (t === "display" || t === "sort") {
    let d2 = d
      .map(x => m.settings.json.advertiserNames[x])
      .sort()
      .join(", ");
    return `<span title="${d2}">${d2}</span>`;
  } else if (t === "filter") {
    return d.map(x => m.settings.json.advertiserNames[x]);
  }
  return d;
}

export default class OffersCountByPackageName extends React.Component {
  static title = "Offers Count by Package Name";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      showOffersFor: null
    };
  }

  render() {
    return (
      <>
        <PageLayout
          breadcrumbs={["Reports"]}
          title={OffersCountByPackageName.title}
          description="Displays a list of unique package names (bundle IDs) of all offers currently in Moderation."
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped table-hover"
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/reports/offerscountbypackagename",
                    dataSrc: "data"
                  }}
                  onUserCommand={({ commandData }) => {
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      this.setState({ showOffersFor: commandData })
                    }
                  }}
                  columns={[
                    {
                      title: `<i title="Store" class="fa fa-info-circle fa-1_4x grey"></i>`,
                      data: "storeId",
                      render: DataTable.StockRenderers.store()
                    },
                    {
                      title: "Package Name/Bundle ID",
                      data: "packageName",
                      order: "asc"
                    },
                    {
                      title: "Package Title",
                      data: "title"
                    },
                    {
                      title: "Advertisers",
                      data: "advertiserIds",
                      className: "truncate-cell-wide",
                      render: renderAdvertisersWithTooltip
                    },
                    DataTable.createUserCommandColumn({
                      title: "Offers",
                      data: "offerIds",
                      className: "text-right",
                      render: (d, t, r, m) => d.length,
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        packageName: rowData.packageName,
                        title: rowData.title,
                        offerIds: rowData.offerIds,
                        storeId: rowData.storeId
                      })
                    }),
                    {
                      title: "Pending",
                      data: "pending",
                      className: "bg-warning text-warning text-right",
                      defaultContent: 0
                    },
                    {
                      title: "Approved",
                      data: "available",
                      className: "bg-success text-success text-right",
                      defaultContent: 0
                    },
                    {
                      title: "Rejected",
                      data: "rejected",
                      className: "bg-danger text-danger text-right",
                      defaultContent: 0
                    },
                    {
                      title: `<span title="Revenue of all the offers belonging to the same Package Name during the last 30 days">Last 30d Revenue</span>`,
                      data: "revenue",
                      className: "text-right",
                      render: DataTable.StockRenderers.money()
                    }
                  ]}
                  order={[[1, "asc"]]}
                  deferRender={true}
                  autoWidth={false}
                />
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {this.state.showOffersFor && (
          <SelectedOffers
            onHide={() => this.setState({ showOffersFor: null })}
            {...this.state.showOffersFor}
          />
        )}
      </>
    );
  }
}
