import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
//import {ButtonGroup, Button} from "react-bootstrap";
import EditCountryCapDialog from "./EditCountryCapDialog";
import { UserContext } from "lib/user";

export default class TrafficMultiplier extends React.Component {
  static title = "Traffic Multiplier";
  
  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      showEditDataFor: null,
      busy: false,
      error: null
    };
  }
  
  render() {
    const { showEditDataFor } = this.state;
    
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={TrafficMultiplier.title}
          description={
          <p></p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped"
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/reports/impressioncountries",
                    dataSrc: ""
                  }}
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      if(commandData && commandData.action==='edit')
                        this.setState({ showEditDataFor: commandData, showEdit:true })
                    }
                  }
                  }
                  columns={[
                    {
                      title: "Country Code",
                      data: "country",
                      width:"6%",
                      className: "th-country-code",
                    },
                    {
                      title: "Multiplier",
                      data: "counter",
                      width:"7%",
                      className: "text-right",
                      render: DataTable.StockRenderers.count()
                    },
                    {
                      title: "",
                      data: "",
                      width:"70%",
                      orderable: false,
                      defaultContent:""
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        country: rowData.country,
                        offersnum: rowData.counter,
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Edit </button></div>")
                    }),
                  ]}
                  order={[[1, "asc"]]}
                />
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>

        {this.state.showEdit && (
          <EditCountryCapDialog
            country={showEditDataFor.country}
            offersnum={showEditDataFor.offersnum}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showEdit: false,
                showEditDataFor: null,
              })
            }}
          />
        )}
      </>
    );
  }
}
