import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

export default class Hint extends React.Component {
  render() {
    return (
      <OverlayTrigger
        trigger={["hover", "click"]}
        overlay={<Popover id={this.props.id}>{this.props.children}</Popover>}
      >
        <span>
          <i className="fa fa-info-circle grey"></i>
        </span>
      </OverlayTrigger>
    );
  }
}
