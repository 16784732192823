import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import PreviewButton from "components/stock/PreviewButton";
import DataTable from "components/DataTable";
import download from "downloadjs";
import { extractFilenameFromResponse } from "lib/Functions";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  ButtonToolbar,
  Alert,
  FormControl
} from "react-bootstrap";


export default class ImpressionLinks extends React.Component {
  static title = "Create Links";

  constructor(props) {
    super(props);
    this.state = {
      click: null,
      impression: null,
      error: null,
      busy: false,
      response: null
    };
  }

  render() {
    const { response } = this.state;
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={ImpressionLinks.title}
        description={
          <>
            This tool should be used in order to create a URL that includes click and impression URL for a specific offer.{" "}
          </>
        }
      >
        <>
          {errorSummary && (
            <Alert
              bsStyle="danger"
              onDismiss={() => this.setState({ error: null })}
            >
              {errorSummary}
            </Alert>
          )}
          <Box busy={this.state.busy}>
            <Box.Header>
              Please insert a click URL and an impression URL.
            </Box.Header>
            <Box.Body>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Click URL</ControlLabel>
                    <FormControl
                      componentClass="input"
                      onChange={e =>
                        this.setState({ click: e.target.value })
                      }
                      defaultValue={this.state.click}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Impression URL</ControlLabel>
                    <FormControl
                      componentClass="input"
                      onChange={e =>
                        this.setState({ impression: e.target.value })
                      }
                      defaultValue={this.state.impression}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Box.Body>
            <Box.Footer>
              <ButtonToolbar>
                <PreviewButton
                  disabled={
                    !this.state.click ||
                    !this.state.impression
                  }
                  onClick={this.handleBuildLink.bind(this, false)}
                />
              </ButtonToolbar>
            </Box.Footer>
          </Box>
        </>
        {response && (
          <Box>
            <Box.Body>
              <DataTable.Toolbar
                tableRef={this.tableRef}
                bsSize="small"
                noReloadButton
              />
              <DataTable
                className="table table-striped"
                ref={this.tableRef}
                data={response.data}
                columns={[
                  {
                    title: "Link",
                    data: "link",
                    name: "Link",
                    render: DataTable.StockRenderers.textWithTooltip()
                  }
                ]}
              />
            </Box.Body>
          </Box>
        )}
      </PageLayout>
    );
  }

  handleBuildLink(excel) {
    this.setState({ busy: true, error: null });

    axios({
      url: "/api/v1/tools/impressionlinks",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: excel ? "blob" : "json",
      data: {
        ClickInput: this.state.click,
        ImpressionInput: this.state.impression,
        output: "Resultset"
      }
    })
      .then(res => {
        if (excel) {
          this.setState({
            error: null,
            busy: false
          });
          const filename = extractFilenameFromResponse(res) || "Links.xlsx";
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({
            response: res.data,
            error: null,
            busy: false
          });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              error: e.srcElement.result,
              busy: false
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            error,
            busy: false
          });
        }
      });
  }
}
