import React from "react";
import FormControl from "react-bootstrap/lib/FormControl";
import { IDataTable } from "./types";

type PageSizerProps = FormControl.FormControlProps & {
  tableRef: React.RefObject<IDataTable>;
};

const PageSizer: React.FC<PageSizerProps> = ({
  tableRef,
  componentClass,
  ...rest
}) => (
  <FormControl
    componentClass="select"
    onChange={e => tableRef.current?.setPageSize((e.target as any).value)}
    {...rest}
  >
    <option value={10}>10</option>
    <option value={25}>25</option>
    <option value={50}>50</option>
    <option value={100}>100</option>
    <option value={200}>200</option>
    <option value={500}>500</option>
    <option value={1000}>1000</option>
    <option value={5000}>5000</option>
  </FormControl>
);

export default PageSizer;
