import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col, ToggleButton
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import { N } from "lib/Functions";
import { InvoiceIssuerPicker } from "components/pickers/SelectPickers";
import { InvoiceRecipientPicker } from "components/pickers/SelectPickers";
import DateRangePicker from "components/pickers/DateRangePicker";
import moment from "moment-timezone";

function displayNumberFormat(n,d){
  return N(n,d);
}
export default class AddDialog extends React.Component {
  static title = "Add/Edit Transaction";

  constructor(props) {
    super(props);
    this.state = {
      action: this.props.action||'add',
      transactionIsEditable: true,
      id: this.props.id || null,
      selectedDate: null,
      bank_id: null,
      status: 2,
      transaction_type: 1,
      amount: 0,
      issuerId: null,
      issuerTitle: null,
      issuerIdUnselected: null,
      recipientId: null,
      recipientTitle: null,
      recipientIdUnselected: null,
      transaction_date: this.props.action==='edit'?null:moment().startOf("day"),
      accountCategoryId: null,
      accountCode: null,
      notes: null,
      exchangeRate: this.props.action === 'edit' ? null : "0.0000",
      currency: '',
      currency_symbol: '',
      error: null,
      busy: false,
      message: null
    };
    this.issuerRef = React.createRef();
    this.recipientRef = React.createRef();
    this.handleSaveTransaction = this.handleSaveTransaction.bind(this);
    this.getRecipientCategory = this.getRecipientCategory.bind(this);
    this.getTransaction = this.getTransaction.bind(this);
  }
  
  componentDidMount() {
    if(this.state.action==='edit'){
      this.getTransaction();
    }      
  }
  
  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    if (this.state.currency === 'USD') {
      this.state.exchangeRate = "0.000";
    }
    var exchangeRateValue = this.state.exchangeRate!==null?displayNumberFormat(this.state.exchangeRate, 4):null;
    var transactionDateStart = this.state.invoiceDate!==null?moment.utc(this.state.invoiceDate).startOf("day"):null;
    var transactionDateEnd = this.state.invoiceDate !== null ? moment.utc(this.state.invoiceDate).endOf("day") : null;
    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide} enforceFocus={false}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>{this.props.action === 'edit' ? ("Edit Transaction"):("Add Transaction")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <Row><Col md={12}><p>Please fill out the transaction details.</p></Col></Row>
          <Box.Body>
            <Row>
              <Col md={7}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <label>Bank</label>
                      <select
                        className="form-control"
                        value={this.state.bank_id || ""}
                        disabled={!this.state.transactionIsEditable}
                        onChange={e =>
                          this.setState({ bank_id: e.target.value })
                        }
                      >
                        <option key="key_selectType" value="">Select bank..</option>
                        <option key="key_63f36981ac04121f785b898e" value="63f36981ac04121f785b898e">Paypal</option>
                        <option key="key_63f36a09ac04121f785b8992" value="63f36a09ac04121f785b8992">Payoneer</option>
                        <option key="key_63fded2939f28166b1df072a" value="63fded2939f28166b1df072a">Commerce</option>
                        <option key="key_63fded4239f28166b1df0731" value="63fded4239f28166b1df0731">DBS</option>
                        <option key="key_63fded5039f28166b1df0732" value="63fded5039f28166b1df0732">UOB</option>
                        <option key="key_63fded5939f28166b1df0733" value="63fded5939f28166b1df0733">Optima</option>
                        <option key="key_65facf7d1276572960fa8ce9" value="65facf7d1276572960fa8ce9">Crypto Wallet</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <label>Status</label>
                      <select
                        className="form-control"
                        value={this.state.status || ""}
                        disabled={!this.state.transactionIsEditable}
                        onChange={e =>
                          this.setState({ status: e.target.value })
                        }
                      >
                        <option key="key_selectType" value="">Select status..</option>
                        <option key="key_1" value="1">Pending</option>
                        <option key="key_2" value="2">Confirmed</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel style={{width: "100%"}}>
                        <span>Issuer</span>
                        <span className={!this.state.transactionIsEditable?"hidden":""} style={{float:"right"}}>
                          <a href="/tools/invoiceissuers" target="_blank">
                            Add New Issuer <i className="fa fa-external-link"></i>
                          </a>
                        </span>
                      </ControlLabel>
                      {((this.state.action==='edit'&&!this.state.busy&&this.state.issuerId!==null&&this.state.issuerTitle!==null) || this.state.issuerIdUnselected===true || this.state.action==='add') && (
                        <InvoiceIssuerPicker
                          id={"issuerId_"+this.state.issuerId}
                          ref={this.issuerRef}
                          className="form-control"
                          placeholder="Enter name"
                          disabled={!this.state.transactionIsEditable}
                          selectedIdValue={this.state.action==='edit'?this.state.issuerId:null}
                          selectedTextValue={this.state.action==='edit'?this.state.issuerTitle:null}
                          onChange={value => this.setState(
                            {issuerId: value},
                            () => {
                              if(this.state.issuerId===null){
                                this.setState(
                                  {
                                    accountCategoryName: null,
                                    accountCode: null,
                                    taxRate: null,
                                  });
                              }else{

                                //this.getInvoiceTypes();
                              }
                            })
                          }
                          onUnselect={value => this.setState({issuerIdUnselected: true}) }
                        />
                      )}                      
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel style={{width: "100%"}}>
                        <span>Recipient</span>
                        <span className={!this.state.transactionIsEditable?"hidden":""} style={{float:"right"}}>
                          <a href="/tools/invoicerecipients" target="_blank">
                            Add New Recipient <i className="fa fa-external-link"></i>
                          </a>
                        </span>
                      </ControlLabel>
                      {((this.state.action==='edit'&&!this.state.busy&&this.state.recipientId!==null&&this.state.recipientTitle!==null) || this.state.recipientIdUnselected===true || this.state.action==='add') && (
                        <InvoiceRecipientPicker
                          ref={this.recipientRef}
                          className="form-control"
                          placeholder="Enter id or name"
                          disabled={!this.state.transactionIsEditable}
                          selectedIdValue={this.state.action==='edit'?this.state.recipientId:null}
                          selectedTextValue={this.state.action==='edit'?this.state.recipientTitle:null}
                          onChange={(value, title, accountCategoryId, currency) => {
                            var newState = {};
                            if (this.state.action !== 'edit') {
                              var currencySymbol = "";
                              if (currency != null) {
                                switch (currency) {
                                  case "USD":
                                    currencySymbol = "$";
                                    break;
                                  case "SGD":
                                    currencySymbol = "S$";
                                    break;
                                  case "GBP":
                                    currencySymbol = "£";
                                    break;
                                  default:
                                    currencySymbol = "$";
                                    break;
                                }
                              }

                              newState = {
                                recipientId: value,
                                currency: currency,
                                currency_symbol: currencySymbol
                              }
                            }
                            else {
                              newState = {
                                currency: currency ? currency : this.state.currency,
                                recipientId: value
                              }
                            }

                            if(accountCategoryId!==undefined&&this.state.action==='edit'&&this.state.recipientIdUnselected!==true){
                              newState.accountCategoryId = accountCategoryId;
                            }
                            this.setState(
                              newState,
                              () => {
                                if (this.state.recipientId === null) {
                                  this.setState(
                                    {
                                      accountCategoryName: null,
                                      accountCode: null,
                                      taxRate: null,
                                    });
                                } else {
                                  this.getRecipientCategory();
                                }
                              })
                            }
                          }
                          onUnselect={value => this.setState({recipientIdUnselected: true}) }
                        />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <label>Transaction Date</label>
                      {((this.state.action==='edit') || this.state.action==='add') && (
                        <DateRangePicker
                          className="form-control"
                          opens="right"
                          disabled={!this.state.transactionIsEditable}
                          onChange={(value) => this.selectDate(value)}
                          minDate={moment("2019-01-18", "YYYY-MM-DD")}
                          maxDate={moment().endOf("day")}
                          startDate={this.state.action==='edit'?transactionDateStart:moment().startOf("day")}
                          endDate={this.state.action === 'edit' ?transactionDateEnd:moment().endOf("day")}
                          singleDatePicker={true}
                        />
                      )}
                    </FormGroup>
                    
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Amount</ControlLabel>
                      <FormControl
                        componentClass="input"
                        type="string"
                        maxLength={12}
                        disabled={!this.state.transactionIsEditable}
                        onChange={e =>
                          {
                              if (!isNaN(parseFloat(e.target.value))) {
                                let val = e.target.value.replaceAll(',', '.');
                                if (val && val.length > 0) {
                                  const first = val.indexOf('.');
                                  const valsub = val.substring(first + 1, val.length);
                                  if (valsub.indexOf('.') !== -1) {
                                    val = val.substring(0, first + 1) + valsub.replaceAll('.', '');
                                  }
                                  if (val.indexOf('.') !== -1) {
                                    if (val.substring(val.indexOf('.'), val.length).length > 2) {
                                      val = val.substring(0, val.indexOf('.') + 10);
                                    }
                                  }
                                }
                                this.setState({ amount: val });
                              }
                            }
                        }
                        value={this.state.amount}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>  
              <Col md={5}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <label>Transaction Type</label>
                      <select
                        className="form-control"
                        value={this.state.transaction_type || ""}
                        disabled={!this.state.transactionIsEditable}
                        onChange={e =>
                          this.setState({ transaction_type: e.target.value })
                        }
                      >
                        <option key="key_selectType" value="">Select type..</option>
                        <option key="key_transactiontype_1" value="1">Payment</option>
                        <option key="key_transactiontype_2" value="2">Prepayment</option>
                        <option key="key_transactiontype_3" value="3">Bonus</option>
                        <option key="key_transactiontype_4" value="4">Offset</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Notes</ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        value={this.state.notes||""}
                        disabled={!this.state.transactionIsEditable}
                        onChange={(e) => {
                          this.setState({ notes: e.target.value });
                        }}
                        rows={1}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Currency</ControlLabel>
                      <select
                        className="form-control"
                        value={this.state.currency || ""}
                        disabled={!this.state.transactionIsEditable}
                        onChange={e =>{
                          this.setState({ currency: e.target.value });
                          }                          
                        }
                      >
                        <option key="key_selectType" value="">Select currency..</option>
                        <option key="key_currency_1" value="USD">USD</option>
                        <option key="key_currency_2" value="SGD">SGD</option>
                        <option key="key_currency_3" value="EUR">EUR</option>
                        <option key="key_currency_4" value="GBP">GBP</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Exchange Rate</ControlLabel>
                      <FormControl
                        componentClass="input"
                        type="string"
                        maxLength={9}
                        disabled={!this.state.transactionIsEditable || this.state.currency === 'USD' || !this.state.currency}
                        onChange={e =>this.setState({ exchangeRate: e.target.value })}                        
                        defaultValue={exchangeRateValue}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          
        {(this.state.action==='add' || (this.state.action==='edit' && this.state.transactionIsEditable)) &&(
          <Button
              disabled={
                (this.state.currency !== 'USD' && this.state.exchangeRate <= 0) ||
                (this.state.amount <= 0) ||
                (
                  (!this.state.bank_id && this.state.transaction_type != 4) ||
                  !this.state.issuerId ||
                  !this.state.recipientId ||
                  !this.state.transaction_date ||
                  !this.state.status ||
                  !this.state.transaction_type
                )
            }
            bsStyle="primary"
            onClick={this.handleSaveTransaction}
          >
            Save
          </Button>
        )}
          
        {this.state.action==='edit'&& this.state.transactionIsEditable && (
            <Button
              bsStyle="danger"
              onClick={this.props.onHide}
            >
              Discard Changes
            </Button>
        )}
          
        {(this.state.action==='add' || (this.state.action==='edit'&&!this.state.transactionIsEditable)) && (
          <Button
            onClick={this.props.onHide}
          >
            Close
          </Button>
        )}

        </Modal.Footer>
      </Modal>
    );
  }

  selectDate(date) {
    this.setState({ transaction_date: date});
  }
 
  getRecipientCategory(){
    
    var postData = {
      IssuerId: this.state.issuerId||null,
      AccountCategoryId: this.state.accountCategoryId||null,
    };
    
    if(postData.IssuerId !== null && postData.AccountCategoryId !== null)
    {
      axios({
        url: "/api/v1/tools/recipientcategory",
        method: "post",
        data: postData
      })
        .then(res => {
          var resData = res && res.data ? res.data : null;
          this.setState({
            accountCategoryId: resData && resData.hasOwnProperty("account_category_id")?resData.account_category_id:null,
            accountCode: resData && resData.hasOwnProperty("account_code")?resData.account_code:null,
            accountCategoryName: resData && resData.hasOwnProperty("name")?resData.name:null,
            taxRate: resData && resData.hasOwnProperty("tax_rate")?resData.tax_rate:null,
            busy: false,
            message: null,
            error: null
          });
        })
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;
          this.setState({
            busy: false,
            message: null,
            error
          });
        });
    }
    
  }
  
  handleSaveTransaction() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    var transaction_date_ = this.state.transaction_date;
    if (this.state.transaction_date.length===2){ 
      transaction_date_ = this.state.transaction_date[0];
    }
    var transaction_date = transaction_date_ ? moment.utc(transaction_date_).format("YYYY-MM-DD") : null;
    
    var url = "/api/v1/tools/addtransaction";
    var postData = {
      Id: null,
      BankId: this.state.bank_id ? this.state.bank_id : null,
      IssuerId: this.state.issuerId?this.state.issuerId:null,
      RecipientId: this.state.recipientId?this.state.recipientId:null,
      TransactionDate: transaction_date,
      TransactionType: this.state.transaction_type ? this.state.transaction_type : null,
      Status: this.state.status ? this.state.status : null,
      Amount: this.state.amount ? this.state.amount : 0.000,
      Currency: this.state.currency ? this.state.currency : '',
      ExchangeRate: this.state.exchangeRate ? this.state.exchangeRate : 0,
      Notes: this.state.notes && this.state.notes.length > 0 ? this.state.notes.trim() : null
    };
    
    if(this.state.action==='edit'){
      postData.Id = this.state.id;
      url = "/api/v1/tools/edittransaction";
    }
    
    axios({
      url: url,
      method: "post",
      data: postData
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  getTransaction() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/gettransaction",
      method: "get",
      params: {id:this.state.id||null}
    })
      .then(res => {
        var resData = res && res.data ? res.data : null;
        
        this.setState({
          id: resData._id || null,
          issuerId: resData.issuer_id || null,
          issuerTitle: resData.issuer_title || null,
          bank_id: resData.bank_id||null,
          recipientId: resData.customer_id || null,
          recipientTitle: resData.customer_title || null,
          transaction_date: resData.transaction_date || null,
          transaction_type: resData.transaction_type || null,
          status: resData.status || null,
          amount: resData.amount || 0.000,
          currency: resData.currency || null,
          exchangeRate: resData.exchange_rate || 0.000,
          notes: resData.notes || null,
          busy: false,
          message: null,
          error: null
        },
          () => {
          //this.getInvoiceTypes();
        });
        
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

}
