import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import { FormGroup, Row, Col, MenuItem, SplitButton } from "react-bootstrap";
import { Box } from "components/admin-lte";
import axios from "axios";
import download from "downloadjs";
import Hint from "components/Hint";
import { extractFilenameFromResponse } from "lib/Functions";
import PageLayout from "components/PageLayout";

export default class Encode extends React.Component {
  static title = "Encode";

  constructor(props) {
    super(props);
    this.state = {
      lines: [],
      type: "sub",
      whitelabelEntity: "",
      response: null,
      error: null,
      busy: false
    };
  }

  render() {
    const {
      lines,
      type,
      whitelabelEntity,
      lastType,
      response,
      error,
      busy
    } = this.state;
    const errorSummary = buildErrorSummary(error);
    const hasMissingParameters =
      lines.length === 0 || (type === "pub" && !whitelabelEntity);

    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={Encode.title}
        description="Computes the encoded form of subpublisher or publisher IDs."
      >
        <>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          <Box busy={busy}>
            <Box.Body>
              <FormGroup>
                <label>Unencoded Lines</label>
                <span className="pull-right">
                  Lines entered: {lines.length}
                </span>
                <textarea
                  className="form-control"
                  rows="5"
                  onChange={e =>
                    this.setState({
                      lines: e.target.value.split("\n").filter(x => x)
                    })
                  }
                />
              </FormGroup>
              <Row>
                <Col md={2} sm={4}>
                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <label>Type of Input</label>
                      </Col>
                    </Row>
                    <label className="radio-inline">
                      <input
                        type="radio"
                        value="sub"
                        onChange={e => this.setState({ type: e.target.value })}
                        checked={type === "sub"}
                      />
                      Subpublisher
                    </label>
                    <label className="radio-inline">
                      <input
                        type="radio"
                        value="pub"
                        onChange={e => this.setState({ type: e.target.value })}
                        checked={type === "pub"}
                      />
                      Publisher
                    </label>
                  </FormGroup>
                  <FormGroup>
                    <label>Whitelabel</label>
                    &nbsp;
                    <Hint id="about-whitelabel">
                      <React.Fragment>
                        <p>
                          The same input value has a different encoded form for
                          each whitelabel.
                        </p>
                        <p>
                          When encoding publisher&nbsp;IDs, the whitelabel is{" "}
                          <strong>mandatory</strong>.
                        </p>
                      </React.Fragment>
                    </Hint>
                    <select
                      className="form-control"
                      onChange={e =>
                        this.setState({ whitelabelEntity: e.target.value })
                      }
                    >
                      <option value="">None</option>
                      <option value="14">ALF</option>
                      <option value="3">ANL</option>
                      <option value="16">APM</option>
                      <option value="17">AST</option>
                      <option value="6">DIY</option>
                      <option value="13">KAN</option>
                      <option value="5">MRG</option>
                      <option value="12">MBT</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </Box.Body>
            <Box.Footer>
              <SplitButton
                title={
                  <>
                    <i className="fa fa-key"></i> Encode
                  </>
                }
                bsStyle="primary"
                onClick={this.handleEncode.bind(this, false)}
                disabled={hasMissingParameters}
              >
                <MenuItem onClick={this.handleEncode.bind(this, true)}>
                  Encode and Download
                </MenuItem>
              </SplitButton>
            </Box.Footer>
          </Box>
          {response && (
            <Box>
              <Box.Body>
                <table className="table table-condensed table-striped table-hover">
                  {lastType === "sub" ? (
                    <>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Original Text</th>
                          <th>Encoded Subpublisher</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.results.map((x, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{x.input}</td>
                            <td>{x.encodedSubpublisherId}</td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Original Text</th>
                          <th>Encoded Publisher ID</th>
                          <th>Publisher Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.results.map((x, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{x.input}</td>
                            <td>{x.encodedPublisherId}</td>
                            <td>{response.userNames[x.input]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </table>
              </Box.Body>
            </Box>
          )}
        </>
      </PageLayout>
    );
  }

  handleEncode(excel) {
    const { type, lines, whitelabelEntity } = this.state;
    this.setState({ busy: true });
    axios({
      url:
        type === "sub"
          ? `/api/v1/tools/encodesubpublishers`
          : `/api/v1/tools/encodepublishers`,
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: excel ? "blob" : "json",
      data: {
        inputs: lines,
        whitelabelEntity,
        output: excel ? "Excel" : "Resultset"
      }
    })
      .then(res => {
        if (excel) {
          this.setState({
            error: null,
            busy: false
          });
          const filename = extractFilenameFromResponse(res) || "Encode.xlsx";
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({
            lastType: type,
            response: res.data,
            error: null,
            busy: false
          });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              error: e.srcElement.result,
              busy: false
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            error,
            busy: false
          });
        }
      });
  }
}
