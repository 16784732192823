import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {ButtonGroup, Button} from "react-bootstrap";
import AddDialog from "./AddDialog";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import DisableDialog from "./DisableDialog";
import { UserContext } from "lib/user";

export default class InvoiceRecipients extends React.Component {
  static title = "Invoice Recipients";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {     
      showAdd: false,
      showEdit: false,
      showDelete: false,
      showDisable: false,
      busy: false,
      error: null,
      showEditDataFor: null,
      showDeleteDataFor: null,
      showDisableDataFor: null
    };
  }
  
  render() {
    const { showEditDataFor } = this.state;
    const { showDeleteDataFor } = this.state;
    const { showDisableDataFor } = this.state;
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={InvoiceRecipients.title}
          description={
          <p></p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                  <ButtonGroup bsSize="small">
                    <Button
                      bsStyle="primary"
                      onClick={this.handleAddClick.bind(this,user)}>
                      <i className="fa fa-plus"></i> Add Invoice Recipient
                    </Button>
                  </ButtonGroup>
                </DataTable.Toolbar>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped"
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/tools/invoicerecipients",
                    dataSrc: "items"
                  }}
                  onUserCommand={({ commandData }) => {
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    } else {
                      if (commandData) {
                        if (commandData.action === 'delete') {
                          this.setState({showDeleteDataFor: commandData});
                        }
                        if (commandData.action === 'edit') {
                          this.setState({showEditDataFor: commandData});
                        }
                        if (commandData.action === 'disable')
                          this.setState({showDisableDataFor: commandData});
                        }
                      }
                    }
                  }
                  columns={[
                    {
                      title: "ID",
                      data: "_id",
                      visible: false
                    },
                    {
                      title: "External ID",
                      data: "external_id",
                      width:"6%"
                    },
                    {
                      title: "Company Name",
                      data: "title"
                    },
                    {
                      title: "Address",
                      data: "address"
                    },
                    {
                      title: "Address 2",
                      data: "address2"
                    },
                    {
                      title: "Currency",
                      data: "currency"
                    },
                    {
                      title: "Max Credit Amount",
                      data: "max_credit_amount",
                      width: "10%"
                    },
                    {
                      title: "Notes",
                      data: "notes"
                    },
                    //{
                    //  title: "Inter Company",
                    //  data: "inter_company",
                    //  render:(d, t, r, m) => {
                    //    if(d === true){
                    //      return "Yes";
                    //    }
                    //    else {
                    //      return "No";
                    //    }
                    //  }
                    //},
                    //{
                    //  title: "Account Category",
                    //  data: "account_category_id",
                    //  render:(d, t, r, m) => {
                    //    if(d === 1){
                    //      return "ZR - 0%";
                    //    }
                    //    else if (d === 2){
                    //      return "SR - 7%";
                    //    }else{
                    //      return "";
                    //    }
                    //  }
                    //},
                    {
                      title: "Status",
                      data: "disabled",
                      render:(d, t, r, m) => {
                        if(d === true){
                          return "Disabled";
                        }
                        else if (d === false){
                          return "Active";
                        }else{
                          return "";
                        }
                      }
                    },
                   /* {
                      title: "Company Details",
                      data: "companyDetails"
                    },*/
                    {
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "20px",
                      render: (d, t, r, m) => ( "<div></div>")
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        id: rowData._id,
                        title: rowData.title,
                        address: rowData.address,           
                        address2: rowData.address2,           
                        currency: rowData.currency,         
                        notes: rowData.notes,       
                        companyDetails: rowData.companyDetails,
                        interCompany: rowData.inter_company,
                        accountCategoryId: rowData.account_category_id,
                        externalId: rowData.external_id,
                        postalCode: rowData.postal_code,
                        city: rowData.city,
                        country: rowData.country,
                        vatNo: rowData.vat_no,
                        regNo: rowData.reg_no,
                        email: rowData.email,
                        max_credit_amount: rowData.max_credit_amount,
                        settings: rowData.settings
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Edit </button></div>")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'disable',
                        id: rowData._id,
                        title: rowData.title,
                        disable: (rowData.disabled===true?false:true)
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> "+(r.disabled===true?"Enable":"Disable")+" </button></div>")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Actions",
                      data: null,
                      className: "text-left",
                      orderable: false,
                      width: "30px",
                      defultContent: "",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'delete',
                        id: rowData._id,
                        title: rowData.title
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Delete </button></div>")
                    })
                  ]}
                  order={[[1, "asc"]]}
                />
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {this.state.showAdd && (
          <AddDialog   
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showAdd: false 
              })
            }}
          />
        )}

        {showEditDataFor && (
          <EditDialog
            id={showEditDataFor.id}
            title={showEditDataFor.title}
            address={showEditDataFor.address}
            address2={showEditDataFor.address2}
            companyDetails={showEditDataFor.companyDetails}
            currency={showEditDataFor.currency}
            notes={showEditDataFor.notes}
            interCompany={showEditDataFor.interCompany}
            accountCategoryId={showEditDataFor.accountCategoryId}
            externalId={showEditDataFor.externalId}
            postalCode={showEditDataFor.postalCode}
            city={showEditDataFor.city}
            country={showEditDataFor.country}
            vatNo={showEditDataFor.vatNo}
            regNo={showEditDataFor.regNo}
            email={showEditDataFor.email}
            max_credit_amount={showEditDataFor.max_credit_amount}
            settings={showEditDataFor.settings}
            onHide={() => {
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showEdit: false, 
                  showEditDataFor: null
                })
              );
            }}
          />
        )}

        {showDeleteDataFor && (
          <DeleteDialog
            id={showDeleteDataFor.id}
            title={showDeleteDataFor.title}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showDelete: false, 
                showDeleteDataFor: null 
              })
            }}
          />
        )}

        {showDisableDataFor && (
          <DisableDialog
            id={showDisableDataFor.id}
            title={showDisableDataFor.title}
            disable={showDisableDataFor.disable}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showDisable: false,
                showDisableDataFor: null
              })
            }}
          />
        )}
      </>
    );
  }
  
  handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({showAdd: true});
    }
  }
}
