import React from "react";
import PageLayout from "components/PageLayout";
import { Box, Spinner } from "components/admin-lte";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  ButtonToolbar
} from "react-bootstrap";
import DateRangePicker from "components/pickers/DateRangePicker";
import moment from "moment-timezone";
import axios from "axios";
import { buildGetParams, extractFilenameFromResponse } from "lib/Functions";
import download from "downloadjs";
import buildErrorSummary from "components/ErrorSummary";
import DataTable from "components/DataTable";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

export default class RejectionRate extends React.Component {
  static title = "Rejection Rate";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      date: null,
      busy: false
    };
    this.handleDownloadOrPreview = this.handleDownloadOrPreview.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <PageLayout breadcrumbs={["Reports"]} title={RejectionRate.title}>
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Date</ControlLabel>
                  <DateRangePicker
                    className="form-control"
                    onChange={value => this.setState({ date: value })}
                    ranges={{
                      Today: [moment().startOf("day"), moment().endOf("day")],
                      Yesterday: [
                        moment()
                          .subtract(1, "days")
                          .startOf("day"),
                        moment()
                          .subtract(1, "days")
                          .endOf("day")
                      ],
                      "This week so far": [
                        moment().startOf("isoWeek"),
                        moment().endOf("day")
                      ],
                      "Last week": [
                        moment()
                          .subtract(1, "weeks")
                          .startOf("isoWeek"),
                        moment()
                          .subtract(1, "weeks")
                          .endOf("isoWeek")
                      ],
                      "This month so far": [
                        moment().startOf("month"),
                        moment().endOf("day")
                      ]
                    }}
                    minDate={moment
                      .utc()
                      .subtract(3, "month")
                      .startOf("day")}
                    maxDate={moment().endOf("day")}
                    startDate={moment().startOf("day")}
                    endDate={moment().endOf("day")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <DownloadButton
                disabled={!this.state.date}
                onClick={this.handleDownloadOrPreview.bind(this, true)}
              />
              <PreviewButton
                disabled={!this.state.date}
                onClick={this.handleDownloadOrPreview.bind(this, false)}
              />
            </ButtonToolbar>
          </Box.Footer>
          <Spinner visible={this.state.busy} />
        </Box>
        {this.state.resultset && (
          <Box>
            <Box.Body>
              <DataTable.Toolbar
                tableRef={this.tableRef}
                bsSize="small"
                noReloadButton
              />
              <DataTable
                ref={this.tableRef}
                className="table table-condensed table-striped"
                data={this.state.resultset}
                columns={[
                  {
                    title: "Publisher ID",
                    data: "publisherId"
                  },
                  {
                    title: "Publisher Name",
                    data: "publisherName"
                  },
                  {
                    title: "Offer ID",
                    data: "offerId"
                  },
                  {
                    title: "Offer Name",
                    data: "offerName"
                  },
                  {
                    title: "Tracking Provider",
                    data: "provider"
                  },
                  {
                    title: "Accepted",
                    data: "acceptedInstalls",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Rejected",
                    data: "rejectedInstalls",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Total",
                    data: "totalInstalls",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Rejection Rate %",
                    data: "rejectionRate",
                    className: "text-right",
                    render: DataTable.StockRenderers.money()
                  }
                ]}
                order={[
                  [1, "asc"],
                  [3, "asc"]
                ]}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th colSpan={3} className="text-center bg-gray">
                      Installs
                    </th>
                    <th></th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody></tbody>
              </DataTable>
            </Box.Body>
          </Box>
        )}
      </PageLayout>
    );
  }

  handleDownloadOrPreview(excel) {
    this.setState({ busy: true });
    axios({
      url: "/api/v1/reports/rejectionrate",
      method: "GET",
      params: buildGetParams({
        fromDateInclusiveUtc: moment(this.state.date[0]).format("YYYY-MM-DD"),
        toDateExclusiveUtc: moment(this.state.date[1])
          .add(1, "second")
          .format("YYYY-MM-DD"),
        output: excel ? "Excel" : "Resultset"
      }),
      responseType: excel ? "blob" : "json"
    })
      .then(res => {
        if (excel) {
          this.setState({ error: null, busy: false });
          const filename = extractFilenameFromResponse(res);
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({ error: null, busy: false, resultset: res.data });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              error: e.srcElement.result,
              busy: false
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            error,
            busy: false
          });
        }
      });
  }
}
