import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { ButtonGroup, Button } from "react-bootstrap";
import AddDialog from "./AddDialog";
import DisableDialog from "./DisableDialog";
import EditDialog from "./EditDialog";

export default class ImpressionCapping extends React.Component {
  static title = "Impression Capping";

  tableRef = React.createRef();
  tableRef_ = React.createRef();

  constructor(props) {
    super(props);
    this.state = {     
      showAdd: false,
      showDisable: false,
      showEdit: false,
      busy: false,
      error: null
    };
  }

  render() {
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={ImpressionCapping.title}
          description={
            <p>
              Offers that have an active impression cap or click rate setting.
            <br />
              Number of Offers per Impression for each country.
          </p>
          }
        >
          <Box>
            <Box.Header>
              <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
              <ButtonGroup bsSize="small">
                <Button
                  onClick={() => this.setState({ showAdd: true })}
                >
                  Add/Edit
              </Button>
              </ButtonGroup>
              <ButtonGroup bsSize="small">
                <Button
                  onClick={() => this.setState({ showDisable: true })}
                >
                  Delete
              </Button>
              </ButtonGroup>
            </Box.Header>
            <Box.Body>
              <DataTable
                className="table table-condensed table-striped"
                ref={this.tableRef}
                ajax={{
                  url: "/api/v1/reports/impressioncapping",
                  dataSrc: "items"
                }}
                columns={[
                  {
                    title: "Offer ID",
                    data: "offerid"
                  },
                  {
                    title: "Offer Name",
                    data: "offer_name"
                  },
                  {
                    title: "Advertiser Name",
                    data: "advertiser_name"
                  },
                  {
                    title: "Impression Threshold",
                    data: "cap",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Click Rate",
                    data: "clickRate",
                    className: "text-right"
                  }
                ]}
                order={[[1, "asc"]]}
              />
            </Box.Body>
          </Box>

          <Box>
            <Box.Header>
              <DataTable.Toolbar tableRef={this.tableRef_} bsSize="small" />
              <ButtonGroup bsSize="small">
                <Button
                  onClick={() => this.setState({ showEdit: true })}
                >
                  Edit
              </Button>
              </ButtonGroup>
            </Box.Header>
            <Box.Body>
              <DataTable
                className="table table-condensed table-striped"
                ref={this.tableRef_}
                ajax={{
                  url: "/api/v1/reports/impressioncountries",
                  dataSrc: ""
                }}
                columns={[
                  {
                    title: "Country Code",
                    data: "country"
                  },
                  {
                    title: "Number of Offers",
                    data: "counter",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  }
                ]}
                order={[[1, "asc"]]}
              />
            </Box.Body>
          </Box>
        </PageLayout>
        {this.state.showAdd && (
          <AddDialog   
            onHide={() => this.setState({ showAdd: false })}
            onRowsChanged={() => {
              this.tableRef.current.api
                .rows()
                .invalidate()
                .draw();
            }}
          />
        )}
        {this.state.showDisable && (
          <DisableDialog
            onHide={() => this.setState({ showDisable: false })}
            onRowsChanged={() => {
              this.tableRef.current.api
                .rows()
                .invalidate()
                .draw();
            }}
          />
        )}
        {this.state.showEdit && (
          <EditDialog
            onHide={() => this.setState({ showEdit: false })}
            onRowsChanged={() => {
              this.tableRef_.current.api
                .rows()
                .invalidate()
                .draw();
            }}
          />
        )}
      </>
    );
  }
}
