import React from "react";
import ReactDOMServer from "react-dom/server";
import $ from "jquery";
import DataTable from "components/DataTable";
import { incr, incrPrc } from "../functions";

function PageTotalsRow() {
  return (
    <tr>
      {/* COMMON COLUMNS */}
      {/* ADOPS COLUMNS */}
      {/* GROUP BY AFFILIATE COLUMNS */}
      {/* GROUP BY CAMPAIGN COLUMNS */}
      <td colSpan={10} className="top-footer-row text-right">
        Page Totals
      </td>
      {/* LEFT COLUMNS */}
      <td className="top-footer-row text-right range0" id="pClicks"></td>
      <td className="top-footer-row text-right range0" id="pInstalls"></td>
      <td className="top-footer-row text-right range0" id="pInstallsOpt"></td>
      <td className="top-footer-row text-right range0" id="pEvents"></td>
      <td className="top-footer-row text-right range0" id="pRevenue"></td>
      <td className="top-footer-row text-right range0" id="pCost"></td>
      {/* COMPARISON TRIPLETS */}
      {[1, 2, 3, 4, 5].map(id => (
        <React.Fragment key={id}>
          <td
            className={`top-footer-row text-right range${id}`}
            id={`pClicks${id}`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pClicks${id}Incr`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pClicks${id}IncrPrc`}
          ></td>
          <td
            className={`top-footer-row text-right range${id}`}
            id={`pInstalls${id}`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pInstalls${id}Incr`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pInstalls${id}IncrPrc`}
          ></td>
          <td
            className={`top-footer-row text-right range${id}`}
            id={`pInstallsOpt${id}`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pInstallsOpt${id}Incr`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pInstallsOpt${id}IncrPrc`}
          ></td>
          <td
            className={`top-footer-row text-right range${id}`}
            id={`pEvents${id}`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pEvents${id}Incr`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pEvents${id}IncrPrc`}
          ></td>
          <td
            className={`top-footer-row text-right range${id}`}
            id={`pRevenue${id}`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pRevenue${id}Incr`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pRevenue${id}IncrPrc`}
          ></td>
          <td
            className={`top-footer-row text-right range${id}`}
            id={`pCost${id}`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pCost${id}Incr`}
          ></td>
          <td
            className={`top-footer-row text-right`}
            id={`pCost${id}IncrPrc`}
          ></td>
        </React.Fragment>
      ))}
    </tr>
  );
}

function GrandTotalsRow() {
  return (
    <tr>
      {/* COMMON COLUMNS */}
      {/* ADOPS COLUMNS */}
      {/* GROUP BY AFFILIATE COLUMNS */}
      {/* GROUP BY CAMPAIGN COLUMNS */}
      <td colSpan={10} className="text-right">
        Table Totals
      </td>
      {/* LEFT COLUMNS */}
      <td className="text-right range-cell-0" id="tClicks"></td>
      <td className="text-right range-cell-0" id="tInstalls"></td>
      <td className="text-right range-cell-0" id="tInstallsOpt"></td>
      <td className="text-right range-cell-0" id="tEvents"></td>
      <td className="text-right range-cell-0" id="tRevenue"></td>
      <td className="text-right range-cell-0" id="tCost"></td>
      {/* COMPARISON TRIPLETS */}
      {[1, 2, 3, 4, 5].map(id => (
        <React.Fragment key={id}>
          <td
            className={`text-right range-cell-${id}`}
            id={`tClicks${id}`}
          ></td>
          <td className={`text-right`} id={`tClicks${id}Incr`}></td>
          <td className={`text-right`} id={`tClicks${id}IncrPrc`}></td>
          <td
            className={`text-right range-cell-${id}`}
            id={`tInstalls${id}`}
          ></td>
          <td className={`text-right`} id={`tInstalls${id}Incr`}></td>
          <td className={`text-right`} id={`tInstalls${id}IncrPrc`}></td>
          <td
            className={`text-right range-cell-${id}`}
            id={`tInstallsOpt${id}`}
          ></td>
          <td className={`text-right`} id={`tInstallsOpt${id}Incr`}></td>
          <td className={`text-right`} id={`tInstallsOpt${id}IncrPrc`}></td>
          <td
            className={`text-right range-cell-${id}`}
            id={`tEvents${id}`}
          ></td>
          <td className={`text-right`} id={`tEvents${id}Incr`}></td>
          <td className={`text-right`} id={`tEvents${id}IncrPrc`}></td>
          <td
            className={`text-right range-cell-${id}`}
            id={`tRevenue${id}`}
          ></td>
          <td className={`text-right`} id={`tRevenue${id}Incr`}></td>
          <td className={`text-right`} id={`tRevenue${id}IncrPrc`}></td>
          <td className={`text-right range-cell-${id}`} id={`tCost${id}`}></td>
          <td className={`text-right`} id={`tCost${id}Incr`}></td>
          <td className={`text-right`} id={`tCost${id}IncrPrc`}></td>
        </React.Fragment>
      ))}
    </tr>
  );
}

export function getFooterCallback() {
  const renderInt = d => DataTable.StockRenderers.count()(d, "display");
  const renderIntIncr = d =>
    DataTable.StockRenderers.countIncrease()(d, "display");
  const renderIntIncrPrc = d =>
    DataTable.StockRenderers.countIncreasePercent()(d, "display");
  const renderCur = d => DataTable.StockRenderers.money()(d, "display");
  const renderCurIncr = d =>
    DataTable.StockRenderers.moneyIncrease()(d, "display");
  const renderCurIncrPrc = d =>
    DataTable.StockRenderers.moneyIncreasePercent()(d, "display");

  return function(tfoot, data, start, end, display) {
    let pageTotals = {};
    let tableTotals = {};

    ["", 1, 2, 3, 4, 5].forEach(id => {
      pageTotals[`clicks${id}`] = display
        .slice(start, end)
        .reduce((acc, cur) => (acc += data[cur][`clicks${id}`]), 0);
      pageTotals[`installs${id}`] = display
        .slice(start, end)
        .reduce((acc, cur) => (acc += data[cur][`installs${id}`]), 0);
      pageTotals[`installsOpt${id}`] = display
        .slice(start, end)
        .reduce((acc, cur) => (acc += data[cur][`installsOpt${id}`]), 0);
      pageTotals[`events${id}`] = display
        .slice(start, end)
        .reduce((acc, cur) => (acc += data[cur][`events${id}`]), 0);
      pageTotals[`revenue${id}`] = display
        .slice(start, end)
        .reduce((acc, cur) => (acc += data[cur][`revenue${id}`]), 0.0);
      pageTotals[`cost${id}`] = display
        .slice(start, end)
        .reduce((acc, cur) => (acc += data[cur][`cost${id}`]), 0.0);

      tableTotals[`clicks${id}`] = display.reduce(
        (acc, cur) => (acc += data[cur][`clicks${id}`]),
        0
      );
      tableTotals[`installs${id}`] = display.reduce(
        (acc, cur) => (acc += data[cur][`installs${id}`]),
        0
      );
      tableTotals[`installsOpt${id}`] = display.reduce(
        (acc, cur) => (acc += data[cur][`installsOpt${id}`]),
        0
      );
      tableTotals[`events${id}`] = display.reduce(
        (acc, cur) => (acc += data[cur][`events${id}`]),
        0
      );
      tableTotals[`revenue${id}`] = display.reduce(
        (acc, cur) => (acc += data[cur][`revenue${id}`]),
        0.0
      );
      tableTotals[`cost${id}`] = display.reduce(
        (acc, cur) => (acc += data[cur][`cost${id}`]),
        0.0
      );
    });

    const footer = $(tfoot).parent();

    ["", 1, 2, 3, 4, 5].forEach(id => {
      footer.find(`#pClicks${id}`).html(renderInt(pageTotals[`clicks${id}`]));
      footer
        .find(`#pInstalls${id}`)
        .html(renderInt(pageTotals[`installs${id}`]));
      footer
        .find(`#pInstallsOpt${id}`)
        .html(renderInt(pageTotals[`installsOpt${id}`]));
      footer.find(`#pEvents${id}`).html(renderInt(pageTotals[`events${id}`]));
      footer.find(`#pRevenue${id}`).html(renderCur(pageTotals[`revenue${id}`]));
      footer.find(`#pCost${id}`).html(renderCur(pageTotals[`cost${id}`]));

      footer.find(`#tClicks${id}`).html(renderInt(tableTotals[`clicks${id}`]));
      footer
        .find(`#tInstalls${id}`)
        .html(renderInt(tableTotals[`installs${id}`]));
      footer
        .find(`#tInstallsOpt${id}`)
        .html(renderInt(tableTotals[`installsOpt${id}`]));
      footer.find(`#tEvents${id}`).html(renderInt(tableTotals[`events${id}`]));
      footer
        .find(`#tRevenue${id}`)
        .html(renderCur(tableTotals[`revenue${id}`]));
      footer.find(`#tCost${id}`).html(renderCur(tableTotals[`cost${id}`]));

      if (id !== "") {
        footer
          .find(`#pClicks${id}Incr`)
          .html(
            renderIntIncr(
              incr(pageTotals[`clicks${id}`], pageTotals["clicks"]),
              "display"
            )
          );
        footer
          .find(`#pInstalls${id}Incr`)
          .html(
            renderIntIncr(
              incr(pageTotals[`installs${id}`], pageTotals["installs"]),
              "display"
            )
          );
        footer
          .find(`#pInstallsOpt${id}Incr`)
          .html(
            renderIntIncr(
              incr(pageTotals[`installsOpt${id}`], pageTotals["installsOpt"]),
              "display"
            )
          );
        footer
          .find(`#pEvents${id}Incr`)
          .html(
            renderIntIncr(
              incr(pageTotals[`events${id}`], pageTotals["events"]),
              "display"
            )
          );
        footer
          .find(`#pRevenue${id}Incr`)
          .html(
            renderCurIncr(
              incr(pageTotals[`revenue${id}`], pageTotals["revenue"]),
              "display"
            )
          );
        footer
          .find(`#pCost${id}Incr`)
          .html(
            renderCurIncr(
              incr(pageTotals[`cost${id}`], pageTotals["cost"]),
              "display"
            )
          );

        footer
          .find(`#pClicks${id}IncrPrc`)
          .html(
            renderIntIncrPrc(
              incrPrc(pageTotals[`clicks${id}`], pageTotals["clicks"]),
              "display"
            )
          );
        footer
          .find(`#pInstalls${id}IncrPrc`)
          .html(
            renderIntIncrPrc(
              incrPrc(pageTotals[`installs${id}`], pageTotals["installs"]),
              "display"
            )
          );
        footer
          .find(`#pInstallsOpt${id}IncrPrc`)
          .html(
            renderIntIncrPrc(
              incrPrc(
                pageTotals[`installsOpt${id}`],
                pageTotals["installsOpt"]
              ),
              "display"
            )
          );
        footer
          .find(`#pEvents${id}IncrPrc`)
          .html(
            renderIntIncrPrc(
              incrPrc(pageTotals[`events${id}`], pageTotals["events"]),
              "display"
            )
          );
        footer
          .find(`#pRevenue${id}IncrPrc`)
          .html(
            renderCurIncrPrc(
              incrPrc(pageTotals[`revenue${id}`], pageTotals["revenue"]),
              "display"
            )
          );
        footer
          .find(`#pCost${id}IncrPrc`)
          .html(
            renderCurIncrPrc(
              incrPrc(pageTotals[`cost${id}`], pageTotals["cost"]),
              "display"
            )
          );

        footer
          .find(`#tClicks${id}Incr`)
          .html(
            renderIntIncr(
              incr(tableTotals[`clicks${id}`], tableTotals["clicks"]),
              "display"
            )
          );
        footer
          .find(`#tInstalls${id}Incr`)
          .html(
            renderIntIncr(
              incr(tableTotals[`installs${id}`], tableTotals["installs"]),
              "display"
            )
          );
        footer
          .find(`#tInstallsOpt${id}Incr`)
          .html(
            renderIntIncr(
              incr(tableTotals[`installsOpt${id}`], tableTotals["installsOpt"]),
              "display"
            )
          );
        footer
          .find(`#tEvents${id}Incr`)
          .html(
            renderIntIncr(
              incr(tableTotals[`events${id}`], tableTotals["events"]),
              "display"
            )
          );
        footer
          .find(`#tRevenue${id}Incr`)
          .html(
            renderCurIncr(
              incr(tableTotals[`revenue${id}`], tableTotals["revenue"]),
              "display"
            )
          );
        footer
          .find(`#tCost${id}Incr`)
          .html(
            renderCurIncr(
              incr(tableTotals[`cost${id}`], tableTotals["cost"]),
              "display"
            )
          );

        footer
          .find(`#tClicks${id}IncrPrc`)
          .html(
            renderIntIncrPrc(
              incrPrc(tableTotals[`clicks${id}`], tableTotals["clicks"]),
              "display"
            )
          );
        footer
          .find(`#tInstalls${id}IncrPrc`)
          .html(
            renderIntIncrPrc(
              incrPrc(tableTotals[`installs${id}`], tableTotals["installs"]),
              "display"
            )
          );
        footer
          .find(`#tInstallsOpt${id}IncrPrc`)
          .html(
            renderIntIncrPrc(
              incrPrc(
                tableTotals[`installsOpt${id}`],
                tableTotals["installsOpt"]
              ),
              "display"
            )
          );
        footer
          .find(`#tEvents${id}IncrPrc`)
          .html(
            renderIntIncrPrc(
              incrPrc(tableTotals[`events${id}`], tableTotals["events"]),
              "display"
            )
          );
        footer
          .find(`#tRevenue${id}IncrPrc`)
          .html(
            renderCurIncrPrc(
              incrPrc(tableTotals[`revenue${id}`], tableTotals["revenue"]),
              "display"
            )
          );
        footer
          .find(`#tCost${id}IncrPrc`)
          .html(
            renderCurIncrPrc(
              incrPrc(tableTotals[`cost${id}`], tableTotals["cost"]),
              "display"
            )
          );
      }
    });
  };
}

export function getFooter() {
  return {
    __html: ReactDOMServer.renderToStaticMarkup([
      <PageTotalsRow />,
      <GrandTotalsRow />
    ])
  };
}
