import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import DetailReportModal from "./DetailReportModal";
import { ButtonGroup, FormGroup, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import { UserContext } from "lib/user";
import moment from "moment-timezone";
import DateRangePicker from "components/pickers/DateRangePicker";
import { N } from "lib/Functions";
import $ from "jquery";
import RtbExchangeDataHistoryModal from "./RtbExchangeDataHistoryModal";

function renderDate(d, t, r, m, selectedDate) {
  
  if(d && (t === "filter")){
    var date = new Date(moment.utc(d).format("YYYY-MM-DD"));
    if(selectedDate && selectedDate.length===2){
        var _dateMin = moment.utc(selectedDate[0]).format("YYYY-MM-DD");
        var _dateMax = moment.utc(selectedDate[1]).format("YYYY-MM-DD");
        var dateMin = new Date(_dateMin);
        var dateMax = new Date(_dateMax);
      if (
        ( dateMin === null && dateMax === null ) ||
        ( dateMin === null && date <= dateMax ) ||
        ( dateMin <= date   && dateMax === null ) ||
        ( dateMin <= date   && date <= dateMax )
      ) {
        return _dateMin+"_"+_dateMax;
      }
    }
    return moment.utc(d).format("YYYY-MM-DD");
  }
  if (d && t === "display") {
    return moment.utc(d).format("YYYY-MM-DD");
  }
  return d;
}

function renderPartner(d, t, r, m) {
  if (t === "filter") {
    if(d === "bidmachineExchange"){
      return "bidmachineExchange_"
    }
    return d?d:"";
  }
  return d;
}

export default class RtbExchangeDataReport extends React.Component {
  static title = "RTB Exchange Report";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showDetailReportFor: null,
      showHistoryFor: null,
      selectedDate: [moment().startOf("day"), moment().endOf("day")],
      reportType: ['byssp']
    };
    this.selectDate = this.selectDate.bind(this);
  }

  componentDidMount() {
    this.selectDate(this.state.selectedDate, true);
    //this.tableRef.current.setPageSize('25');
  }
  
  render() {
    
    const { showDetailReportFor, showHistoryFor } = this.state;
    var _this = this;
    return (
      <>
        <PageLayout
          breadcrumbs={["Reports"]}
          title={RtbExchangeDataReport.title}
          description={
            <p></p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                  <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                  <FormGroup className="date-range-picker-filter pull-left">
                    <label className="date-range-picker-filter-label">Report Date</label>
                      <DateRangePicker
                        className="form-control date-range-picker-filter-input"
                        opens="right"
                        onChange={(value) => {this.selectDate(value, false); }}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment()
                            .subtract(1, "days")
                            .startOf("day"),
                          moment()
                            .subtract(1, "days")
                            .endOf("day")
                        ],
                        "This week so far": [
                          moment().startOf("isoWeek"),
                          moment().endOf("day")
                        ],
                        "Last week": [
                          moment()
                            .subtract(1, "weeks")
                            .startOf("isoWeek"),
                          moment()
                            .subtract(1, "weeks")
                            .endOf("isoWeek")
                        ],
                        "This month so far": [
                          moment().startOf("month"),
                          moment().endOf("day")
                        ],
                        "Last month": [
                          moment()
                            .subtract(1, "months")
                            .startOf("month"),
                          moment()
                            .subtract(1, "months")
                            .endOf("month")
                        ]
                      }}
                      minDate={moment("2019-01-18", "YYYY-MM-DD")}
                      maxDate={moment().endOf("day")}
                      startDate={moment().startOf("day")}
                      endDate={moment().endOf("day")}
                    />
                  </FormGroup>

                    {/*<ButtonGroup>*/}
                    {/*<DataTable.ColumnFilter*/}
                    {/*  tableRef={this.tableRef}*/}
                    {/*  columnName="partner"*/}
                    {/*  bsSize="small"*/}
                    {/*  title="Partner"*/}
                    {/*  defaultValue=""*/}
                    {/*  values={[*/}
                    {/*    ["*", ""],                        */}
                    {/*    ["bidmachineExchangeEU", "bidmachineExchangeEU"],*/}
                    {/*    ["yandexExchangeEU", "yandexExchangeEU"],*/}
                    {/*    ["smaatoExchangeUS", "smaatoExchangeUS"]*/}
                    {/*  ]}*/}
                    {/*/>*/}
                    {/*</ButtonGroup>*/}
                    
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Report Type"
                      type="checkbox"
                      value={this.state.reportType}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ reportType: e });
                          this.loadBundleHistoryFilter({ reportType: e });
                        }
                        else {
                          var prevState = this.state.reportType;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ reportType: value });
                          //this.selectIssuerToggle(value);
                          //this.loadData({ reportType: value });
                        }
                      }}
                    >
                      <ToggleButton value={"byssp"}>by SSP</ToggleButton>
                      <ToggleButton value={"bydsp"}>by DSP</ToggleButton>
                    </ToggleButtonGroup>
                </DataTable.Toolbar>
              </Box.Header>
                <Box.Body>
                  <div className="table-overflow-y-div">
                    {this.state.reportType && this.state.reportType[0]==='byssp' && (
                      <DataTable
                        className="table table-condensed table-striped table-hover"
                        ref={this.tableRef}
                        ajax={{
                          url: "/api/v1/reports/rtbexchangedata",
                          dataSrc: "items",
                          data: function (d) {
                            d.dateFrom = _this.getSelectedDate()[0];
                            d.dateTo = _this.getSelectedDate()[1];
                          }
                        }}
                        onLoading={() => this.setState({ loading: true })}
                        onLoaded={() => this.setState({ loading: false })}
                        onUserCommand={({ commandData }) => {
                          if (user.tokenHasExpired) {
                            window.location.replace('/login');
                            return;
                          }
                          else {
                            if (commandData.action === "showHistory") {
                              this.setState({ showHistoryFor: commandData });
                            } else if (commandData.action === "showReportDetail") {
                              this.setState({ showDetailReportFor: commandData });
                            }
                          }
                        }}
                        columns={[
                          DataTable.createUserCommandColumn({
                            title: "",
                            data: null,
                            orderable: false,
                            defultContent: "",
                            width: "2%",
                            render: (d, t) => ("<button type='button' class='btn btn-default btn-small'> R1</button>"),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showReportDetail',
                              reportType: 'reportDetail1',
                              id: rowData._id,
                              reportData: rowData.perDspPartner || [],
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "",
                            data: "perDspPartner",
                            name: "perDspPartner",
                            sortable: false,
                            searchable: false,
                            width: "2%",
                            render: (d, t) => ("<button type='button' class='btn btn-default btn-small'> R2</button>"),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showReportDetail',
                              reportType: 'reportDetail2',
                              id: rowData._id,
                              reportData: rowData.perDspPartner || [],
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner
                            })
                          }),
                          {
                            title: "Report Date",
                            data: "reportDate",
                            name: "reportDate",
                            className: "text-nowrap",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => renderDate(d, t, r, m, this.state.selectedDate),
                            order: "desc"
                          },
                          {
                            title: "Partner",
                            data: "partner",
                            name: "partner",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => renderPartner(d, t, r, m)
                          },
                          DataTable.createUserCommandColumn({
                            title: "RTB Cost",
                            data: "rtbCost",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => N(d, 2),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "rtbCost",
                              reportMetricText: "RTB Cost",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "DC Cost",
                            data: "datacenter_cost",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => N(d, 2),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "datacenter_cost",
                              reportMetricText: "DC Cost",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "DC Cost Oth.",
                            data: "datacenter_cost_other",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => N(d, 2),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "datacenter_cost_other",
                              reportMetricText: "DC Cost Oth.",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "RTB Requests",
                            data: "rtbRequests",
                            className: "text-right",
                            width: "8%",
                            defaultContent: "",
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "rtbRequests",
                              reportMetricText: "RTB Requests",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "Timeouts",
                            data: "timeouts",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "timeouts",
                              reportMetricText: "Timeouts",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "Impressions",
                            data: "impressions",
                            className: "text-right",
                            width: "8%",
                            defaultContent: "",
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "impressions",
                              reportMetricText: "Impressions",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "Wins",
                            data: "wins",
                            className: "text-right",
                            width: "8%",
                            defaultContent: "",
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "wins",
                              reportMetricText: "Wins",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "QPS",
                            data: "qps",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => N(d, 2),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "qps",
                              reportMetricText: "QPS",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "Revenue Perf.",
                            data: "revenue",
                            className: "text-right",
                            width: "7%",
                            defaultContent: "",
                            render: (d, t, r, m) => N(d, 2),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "revenue",
                              reportMetricText: "Revenue Performance",
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "Revenue Exchange",
                            data: "revenueExchange",
                            className: "text-right",
                            width: "10%",
                            defaultContent: "",
                            render: (d, t, r, m) => N(d, 2),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showHistory',
                              id: rowData._id,
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner,
                              reportMetric: "revenueExchange",
                              reportMetricText: "Revenue Exchange",
                            })
                          }),
                          {
                            title: "",
                            data: null,
                            width: "3%",
                            defaultContent: "",
                            sortable: false,
                          },
                          DataTable.createUserCommandColumn({
                            title: "",
                            data: null,
                            className: "text-left",
                            orderable: false,
                            defultContent: "",
                            width: "6%",
                            render: (d, t) => ("<button type='button' class='btn btn-default btn-small'> Report detail 1</button>"),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showReportDetail',
                              reportType: 'reportDetail1',
                              id: rowData._id,
                              reportData: rowData.perDspPartner || [],
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner
                            })
                          }),
                          DataTable.createUserCommandColumn({
                            title: "",
                            data: "perDspPartner",
                            name: "perDspPartner",
                            className: "text-left",
                            sortable: false,
                            searchable: false,
                            width: "6%",
                            render: (d, t) => ("<button type='button' class='btn btn-default btn-small'> Report detail 2</button>"),
                            commandName: "",
                            commandData: ({ rowData }) => ({
                              action: 'showReportDetail',
                              reportType: 'reportDetail2',
                              id: rowData._id,
                              reportData: rowData.perDspPartner || [],
                              reportDate: rowData.reportDate ? moment.utc(rowData.reportDate).format("YYYY-MM-DD") : '',
                              partner: rowData.partner
                            })
                          }),
                        ]}
                        order={[[13, "desc"]]}
                        footerCallback={(tfoot, data, start, end, display) => {
                          const gt = {
                            rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                            datacenter_cost: display.reduce((acc, cur) => (acc += data[cur].datacenter_cost), 0),
                            datacenter_cost_other: display.reduce((acc, cur) => (acc += data[cur].datacenter_cost_other), 0),
                            rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                            timeouts: display.reduce((acc, cur) => (acc += data[cur].timeouts), 0),
                            impressions: display.reduce((acc, cur) => (acc += data[cur].impressions), 0),
                            wins: display.reduce((acc, cur) => (acc += data[cur].wins), 0),
                            revenue: display.reduce((acc, cur) => (acc += data[cur].revenue), 0),
                            revenueExchange: display.reduce((acc, cur) => (acc += data[cur].revenueExchange), 0),
                          };
                          const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                          const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                          const footer = $(tfoot).parent();

                          // Table totals
                          footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                          footer.find("#gtdatacenter_cost").html(renderMoney(gt.datacenter_cost));
                          footer.find("#gtdatacenter_cost_other").html(renderMoney(gt.datacenter_cost_other));
                          footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                          footer.find("#gttimeouts").html(renderCount(gt.timeouts));
                          footer.find("#gtimpressions").html(renderCount(gt.impressions));
                          footer.find("#gtwins").html(renderCount(gt.wins));
                          footer.find("#gtrevenue").html(renderMoney(gt.revenue));
                          footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                        }}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                        {/* prettier-ignore */}
                        <tfoot>
                          <tr>
                            <td></td>
                            <td></td>
                            <td className="text-right" colSpan={2}>
                              Totals
                            </td>
                            <td className="text-right" id="gtrtbCost">0</td>
                            <td className="text-right" id="gtdatacenter_cost">0</td>
                            <td className="text-right" id="gtdatacenter_cost_other">0</td>
                            <td className="text-right" id="gtrtbRequests">0</td>
                            <td className="text-right" id="gttimeouts">0</td>
                            <td className="text-right" id="gtimpressions">0</td>
                            <td className="text-right" id="gtwins">0</td>
                            <td></td>
                            <td className="text-right" id="gtrevenue">0</td>
                            <td className="text-right" id="gtrevenueExchange">0</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </DataTable>)}
                    {this.state.reportType && this.state.reportType[0] === 'bydsp' && (
                      <DataTable
                        className="table table-condensed table-striped table-hover"
                        ref={this.tableRef}
                        ajax={{
                          url: "/api/v1/reports/rtbexchangedatabydsp",
                          dataSrc: "items",
                          data: function (d) {
                            d.dateFrom = _this.getSelectedDate()[0];
                            d.dateTo = _this.getSelectedDate()[1];
                          }
                        }}
                        onLoading={() => this.setState({ loading: true })}
                        onLoaded={() => this.setState({ loading: false })}
                        onUserCommand={({ commandData }) => {
                          if (user.tokenHasExpired) {
                            window.location.replace('/login');
                            return;
                          }
                        }}
                        columns={[
                          {
                            title: "Report Date",
                            data: "reportDate",
                            name: "reportDate",
                            className: "text-nowrap",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => renderDate(d, t, r, m, this.state.selectedDate),
                            order: "desc"
                          },
                          {
                            title: "DSP Partner",
                            data: "dspPartner",
                            name: "dspPartner",
                            width: "6%",
                            defaultContent: "",
                          },
                          {
                            title: "Revenue",
                            data: "totalRevenueExchange",
                            name: "totalRevenueExchange",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => N(d, 2),
                          },
                          {
                            title: "Cost",
                            data: "totalRtbCost",
                            name: "totalRtbCost",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                            render: (d, t, r, m) => N(d, 2),
                          },
                          {
                            title: "RTB Requests",
                            data: "totalRtbRequests",
                            name: "totalRtbRequests",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                          },
                          {
                            title: "RTB Responses",
                            data: "totalRtbResponses",
                            name: "totalRtbResponses",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                          },
                          {
                            title: "No Bid",
                            data: "totalNoBids",
                            name: "totalNoBids",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                          },
                          {
                            title: "Timeout",
                            data: "totalTimeouts",
                            name: "totalTimeouts",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                          },
                          {
                            title: "Impressions",
                            data: "totalImpressions",
                            name: "totalImpressions",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                          },
                          {
                            title: "Wins",
                            data: "totalWins",
                            name: "totalWins",
                            className: "text-right",
                            width: "6%",
                            defaultContent: "",
                          },
                        ]}
                        order={[[2, "desc"]]}
                        footerCallback={(tfoot, data, start, end, display) => {
                          const gt = {
                            revenueExchange: display.reduce((acc, cur) => (acc += data[cur].totalRevenueExchange), 0),
                            rtbCost: display.reduce((acc, cur) => (acc += data[cur].totalRtbCost), 0),
                            rtbRequests: display.reduce((acc, cur) => (acc += data[cur].totalRtbRequests), 0),
                            rtbResponses: display.reduce((acc, cur) => (acc += data[cur].totalRtbResponses), 0),
                            nobid: display.reduce((acc, cur) => (acc += data[cur].totalNoBids), 0),
                            timeout: display.reduce((acc, cur) => (acc += data[cur].totalTimeouts), 0),
                            impressions: display.reduce((acc, cur) => (acc += data[cur].totalImpressions), 0),
                            wins: display.reduce((acc, cur) => (acc += data[cur].totalWins), 0),
                          };
                          const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                          const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                          const footer = $(tfoot).parent();

                          // Table totals
                          footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                          footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                          footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                          footer.find("#gtrtbResponses").html(renderCount(gt.rtbResponses));
                          footer.find("#gtrtbNobid").html(renderCount(gt.nobid));
                          footer.find("#gtrtbTimeout").html(renderCount(gt.timeout));
                          footer.find("#gtrtbImpressions").html(renderCount(gt.impressions));
                          footer.find("#gtrtbWins").html(renderCount(gt.wins));
                          
                        }}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                        {/* prettier-ignore */}
                        <tfoot>
                          <tr>
                            <td></td>
                            <td className="text-right" colSpan={1}>
                              Totals
                            </td>
                            <td className="text-right" id="gtrevenueExchange">0</td>
                            <td className="text-right" id="gtrtbCost">0</td>
                            <td className="text-right" id="gtrtbRequests">0</td>
                            <td className="text-right" id="gtrtbResponses">0</td>
                            <td className="text-right" id="gtrtbNobid">0</td>
                            <td className="text-right" id="gtrtbTimeout">0</td>
                            <td className="text-right" id="gtrtbImpressions">0</td>
                            <td className="text-right" id="gtrtbWins">0</td>
                          </tr>
                        </tfoot>
                      </DataTable>)}
                  </div>
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {showDetailReportFor && (
          <DetailReportModal
            id={showDetailReportFor.id}
            reportType={showDetailReportFor.reportType}
            reportData={showDetailReportFor.reportData}
            reportDate={showDetailReportFor.reportDate}
            partner={showDetailReportFor.partner}
            onHide={() => this.setState({ showDetailReportFor: null })}
          />
        )}

        {this.state.showHistoryFor && (
          <RtbExchangeDataHistoryModal
            id={showHistoryFor.id}
            partner={showHistoryFor.partner}
            reportDate={showHistoryFor.reportDate}
            reportMetric={showHistoryFor.reportMetric}
            reportMetricText={showHistoryFor.reportMetricText}
            onHide={() => this.setState({ showHistoryFor: null })}
          />
        )}
      </>
    );
  }

  selectDate(date, isInit) {
    this.setState({ selectedDate: date }, () => {
     
      if (this.tableRef?.current) {
        var columnName = "reportDate";
        if (date && date.length === 2) {
          var dateMin = moment.utc(date[0]).format("YYYY-MM-DD");
          var dateMax = moment.utc(date[1]).format("YYYY-MM-DD");

          this.tableRef.current.setColumnFilter(`${columnName}:name`, dateMin + "_" + dateMax);
        }
        else {
          this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
        }
        this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();

        if (!isInit) {
          this.tableRef.current.reload();
        }
      }
    });
  }

  getSelectedDate() {
    var selectedDate = this.state.selectedDate;
    if (selectedDate && selectedDate.length === 2) {
      var dateFrom = moment.utc(selectedDate[0]).format("YYYY-MM-DD");
      var dateTo = moment.utc(selectedDate[1]).format("YYYY-MM-DD");
      return [dateFrom, dateTo];
    }
    else {
      return [selectedDate, selectedDate];
    }

  }
}
