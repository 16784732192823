import React from "react"

export default ({ data, onDismiss, visible }) => {
  if (visible) {
    return (
      <div className="alert bg-gray alert-dismissible">
        <button type="button" className="close" aria-hidden="true" onClick={onDismiss}>&times;</button>
        <h4>Rejection Reason</h4>
        {getContent(data)}
      </div>
    )
  }
  else {
    return null
  }
}

function getContent(summary) {
  if (summary.errors && summary.errors.length > 0) {
    return (
      <ul className="list-unstyled">
        {summary.errors.map((x, i) => (
          <li key={i}>{x}</li>
        ))}
      </ul>
    )
  }
  else if (summary.isBelowMinPayout !== null) {
    return (
      <ul className="list-unstyled">
        <li>{`Payout was below the MinPayout: ${summary.isBelowMinPayout.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</li>
      </ul>
    )
  }
  else if (summary.isAboveMaxPayout !== null) {
    return (
      <ul className="list-unstyled">
        <li>{`Payout was above the MaxPayout: ${summary.isAboveMaxPayout.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</li>
      </ul>
    )
  }
  else if (summary.rejectionReason) {
    return (
      <ul className="list-unstyled">
        <li>{summary.rejectionReason}</li>
      </ul>
    )
  }
  return JSON.stringify(summary)
}