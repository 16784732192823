import React from "react";
import Highcharts from "highcharts";
import moment from "moment-timezone";
import { Row, Col } from "react-bootstrap";

moment.tz.setDefault("UTC");

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.createChart = this.createChart.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      var chart = this.createChart();
      var line = chart.series[1];
      line.points
        .filter(p => p.record.batchId === this.props.selectedSummary.batchId)
        .forEach(p => p.select(true, false));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      var chart = this.createChart();
      var line = chart.series[1];
      line.points
        .filter(p => p.record.batchId === this.props.selectedSummary.batchId)
        .forEach(p => p.select(true, false));
    }
  }

  render() {
    return (
      <Row>
        <Col md={12}>
          <div height="200" style={{ width: "100%" }} ref="container"></div>
        </Col>
      </Row>
    );
  }

  createChart() {
    const { data, onSummaryClick } = this.props;

    return Highcharts.chart(this.refs.container, {
      credits: { enabled: false },

      title: { text: "" },

      zoomType: "x",

      subtitle: {
        text: "Click on a point in time to view its execution details"
      },

      tooltip: {
        shared: true,
        dateTimeLabelFormats: {
          millisecond: "%A, %b %e, %H:%M"
        }
      },

      xAxis: [
        {
          title: { text: "Started at (UTC)" },
          type: "datetime",
          softMax: moment().valueOf()
        }
      ],

      yAxis: [
        {
          title: { text: "Offers" },
          allowDecimals: false
        },
        {
          title: { text: "Seconds" },
          opposite: true
        }
      ],

      series: [
        {
          name: "Duration",
          type: "column",
          color: "khaki",
          yAxis: 1,
          tooltip: {
            valueDecimals: 1,
            valueSuffix: " sec."
          },
          data: data.map(x => ({
            record: x,
            x: moment(x.startUtc).valueOf(),
            y: x.duration
          }))
        },
        {
          name: "Downloaded",
          type: "line",
          color: "green",
          dataLabels: {
            enabled: true,
            format: "{point.record.downloaded:0f}",
            padding: 10
          },
          allowPointSelect: true,
          marker: {
            states: {
              select: {
                enabled: true,
                radius: 6,
                fillColor: "chartreuse",
                lineColor: "green"
              }
            }
          },
          events: {
            click: function(e) {
              onSummaryClick(e.point.record.batchId);
            }
          },
          data: data.map(x => ({
            record: x,
            x: moment(x.startUtc).valueOf(),
            y: x.downloaded
          }))
        }
      ]
    });
  }
}
