import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { ButtonGroup, FormGroup } from "react-bootstrap";
import { UserContext } from "lib/user";
import moment from "moment-timezone";

export default class UserData extends React.Component {
  static title = "User Data";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    //this.selectDate(this.state.selectedDate, true);
    //this.tableRef.current.setPageSize('25');
  }

  render() {

    return (
      <>
        <PageLayout
          breadcrumbs={["Reports"]}
          title={UserData.title}
          description={
            <p></p>
          }
        >
          <UserContext.Consumer>
            {user => (
              <Box>
                <Box.Header>
                  <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                    <FormGroup className="date-range-picker-filter pull-left">
                     
                    </FormGroup>

                  </DataTable.Toolbar>
                </Box.Header>
                <Box.Body>
                  <DataTable
                    className="table table-condensed table-striped table-hover"
                    ref={this.tableRef}
                    ajax={{
                      url: "/api/v1/reports/userdata",
                      dataSrc: "items",
                    }}
                    onLoading={() => this.setState({ loading: true })}
                    onLoaded={() => this.setState({ loading: false })}
                    onUserCommand={({ commandData }) => {
                      if (user.tokenHasExpired) {
                        window.location.replace('/login');
                        return;
                      }
                    }}
                    columns={[
                      {
                        title: "Redis",
                        data: "redis",
                        name: "redis",
                        className: "text-nowrap",
                        width: "10%",
                        defaultContent: "",
                        order: "desc"
                      },
                      {
                        title: "Country",
                        data: "country",
                        name: "country",
                        width: "10%",
                        defaultContent: "",
                      },
                      {
                        title: "IDFAs",
                        data: "idfah",
                        name: "idfah",
                        width: "10%",
                        defaultContent: "",
                      },
                      {
                        title: "GAIDs",
                        data: "gaidh",
                        name: "gaidh",
                        width: "10%",
                        defaultContent: "",
                      },
                      {
                        title: "FP iOS",
                        data: "fP_IOSH",
                        name: "fP_IOSH",
                        width: "10%",
                        defaultContent: "",
                      },
                      {
                        title: "FP aOS",
                        data: "fP_AOSH",
                        name: "fP_AOSH",
                        width: "10%",
                        defaultContent: "",
                      },
                      {
                        title: "IP iOS",
                        data: "iP_IOSH",
                        name: "iP_IOSH",
                        width: "10%",
                        defaultContent: "",
                      },
                      {
                        title: "IP aOS",
                        data: "iP_AOSH",
                        name: "iP_AOSH",
                        width: "10%",
                        defaultContent: "",
                      },
                    ]}
                    order={[[0, "desc"]]}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                    {/* prettier-ignore */}
                  </DataTable>
                </Box.Body>
              </Box>
            )}
          </UserContext.Consumer>
        </PageLayout>

      </>
    );
  }

}
