import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col, ToggleButton, ButtonGroup
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
var _ = require('lodash');

export default class AddDialog extends React.Component {
  //static title = "Add Offers";

  constructor(props) {
    super(props);
    this.state = {
      campaign: props.campaign||null,
      dailyClicks: props.dailyClicks||"",
      hourlyClicks: props.hourlyClicks||"",
      pacing: props.pacing||null,
      editMode: props.editMode||false,
      evenPacingDisabled: false,
      evenPacing: false,
      campaignDisabled: props.campaign?true:false,
      error: null,
      busy: false,
      message: null,
    };
    this.handleClickCap = this.handleClickCap.bind(this);
  }

  componentDidMount() {
    if(this.state.editMode && this.state.pacing && this.state.dailyClicks){
      var evenPacing = this.checkEvenPacing(this.state.pacing,this.state.dailyClicks);
      
      this.setState({ evenPacing: evenPacing, evenPacingDisabled: evenPacing});
    }
    
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>{this.state.editMode?"Edit Offer Auto-Pause Rule":"Add Offer"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            {this.state.editMode?"Please edit the daily cap.":"Please add the id of the offer, the daily cap and check even pacing (optional)."}
          </p>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Offer ID</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    max={15}
                    disabled={this.state.editMode||this.state.campaignDisabled?true:false}
                    onChange={e =>
                      this.setState({ campaign: e.target.value })
                    }
                    defaultValue={this.state.campaign}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel># Daily Cap</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    onChange={e =>
                      this.setState({ dailyClicks: e.target.value })
                    }
                    defaultValue={this.state.dailyClicks}
                  />
                </FormGroup>
                
                <ButtonGroup>
                  <ToggleButton
                    id="toggle-check"
                    type="checkbox"
                    variant="secondary"
                    checked={this.state.evenPacing}
                    disabled={this.state.evenPacingDisabled}
                    value="1"
                    onChange={(e) => {
                      this.setState({ evenPacing: e.currentTarget.checked });
                    }}
                  >
                    &nbsp;Even Pacing
                  </ToggleButton>
                </ButtonGroup>
                
                <FormGroup className={"display-none"}>
                  <ControlLabel># Hourly Cap</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    onChange={e =>
                      this.setState({ hourlyClicks: e.target.value })
                    }
                    defaultValue={this.state.hourlyClicks}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !this.state.campaign
            }
            bsStyle="primary"
            onClick={this.handleClickCap}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleClickCap() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });
    
    var pacingToPost = null;
    if(this.state.evenPacing){
      pacingToPost = [];

      for(var i=0; i<24; i++){
        var fromTime = (i<10?'0':'')+i+':00';
        var toTime = i<23 ? ( ((i+1)<10?'0':'')+(i+1)+':00') : i+':59';
        var impressionCap = Math.floor(parseInt(this.state.dailyClicks)/24);
        pacingToPost.push({
          timeRange: fromTime+"-"+toTime,
          impressionCap: impressionCap
        });
      }
    }
    
    axios({
      url: "/api/v1/reports/addclickcap",
      method: "post",
      data: {
        _id: this.state.campaign,
        MaxDailyClicks: this.state.dailyClicks,
        Pacing: pacingToPost,
        EditRequest: this.state.editMode,
        /*EvenPacing: this.state.evenPacing,*/
        MaxHourlyClicks: this.state.hourlyClicks
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
  
  checkEvenPacing(pacing, dailyClicks){
    if (pacing && pacing.length===24 && dailyClicks) {
      var pacingClicks = Math.floor(parseInt(dailyClicks)/24);
      var noEvenPacing = _.find(pacing, function(p) { return p.impressionCap !== pacingClicks; });
      if(!noEvenPacing){
        return true
      } else {
        return false
      }
    } else {
      return false;
    }
  }
}