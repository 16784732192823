import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import axios from "axios";
import moment from "moment-timezone";
import Chart from "./Chart";
import { Spinner } from "components/admin-lte";


export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.loadHistory = this.loadHistory.bind(this);
    this.tableRef = React.createRef();
  }

  getDefaultState() {
    return {
      chartData: null,
      partner: this.props.partner,
      selectedRange: this.props.reportDate,
      error: null,
      busy: false
    };
  }

  componentDidMount() {
    this.loadHistory(this.state.selectedRange);
  }

  render() {
    const {
      chartData,
      partner,
      busy
    } = this.state;

    const body = () => {
      if (partner) {
        return (
          <>
            
            <br />
            {chartData && (
              <Chart
                id={this.props.partner+"_"+this.props.reportDate}
                data={chartData}
              />
            )}
          </>
        );
      } else if (busy) {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center">Loading...</div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center text-muted">No data</div>
            </Col>
          </Row>
        );
      }
    };

    return (
      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            QPS by minute for <b>{this.props.partner}</b> - Report date: <b>{this.props.reportDate}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body()}</Modal.Body>
        <Spinner visible={busy} />
      </Modal>
    );
  }

  loadHistory(reportDate) {
    this.setState({ busy: true });

    const dateFrom = moment(reportDate).format("YYYY-MM-DD");
    const dateTo = dateFrom;
    const partner = this.props.partner||null;

    axios({
      url: `/api/v1/reports/rtbdatahistory`,
      params: { partner, dateFrom, dateTo }
    })
      .then(res => {
        const chartData = res.data.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );
        this.setState({
          chartData,
          selectedRange: reportDate,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }
}
