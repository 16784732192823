import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import OffersPanel from "./OffersPanel";
import PublishersPanel from "./PublishersPanel";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";

export default class AddRemoveTargetedPublishers extends React.Component {
  static title = "Add/Remove Targeted Publishers";

  constructor(props) {
    super(props);
    this.state = {
      offers: [],
      publishers: [],
      busy: false,
      error: null,
      message: null
    };
    this.handleUpdateOffers = this.handleUpdateOffers.bind(this);
    this.handleClearOffers = this.handleClearOffers.bind(this);
    this.handleUpdatePublishers = this.handleUpdatePublishers.bind(this);
    this.handleClearPublishers = this.handleClearPublishers.bind(this);
  }

  render() {
    const { error, message, offers, publishers, busy } = this.state;
    const errorSummary = buildErrorSummary(error);

    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={AddRemoveTargetedPublishers.title}
        description={
          <p>
            This tool adds one or more publishers as "targeted" to a list of
            offers. The publishers will be <em>appended</em> to the existing
            ones and <em>not replace</em> them.
            <br />
            Specify one or more user ids separated by comma, semicolon, space or
            a new line and click "Update list" to add them to the publisher
            list. Do the same for the offers.
            <br />
            Finally press "Add selected publishers" to add them to the offers or
            "Remove selected publishers" to remove them.
          </p>
        }
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        {message != null && (
          <div className="alert alert-success">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ message: null })}
            >
              &times;
            </button>
            {message}
          </div>
        )}
        <Row>
          <Col md={3}>
            <Box>
              <Box.Header title="Publishers" />
              <Box.Body>
                <PublishersPanel
                  publishers={publishers}
                  busy={busy}
                  onUpdateList={this.handleUpdatePublishers}
                  onClearList={this.handleClearPublishers}
                />
              </Box.Body>
              <Box.Footer>
                <button
                  className="btn btn-primary btn-block"
                  disabled={offers.length === 0 || publishers.length === 0}
                  onClick={() => this.handleAddRemoveTargetedPublishers(false)}
                >
                  Add selected publishers
                </button>
                <button
                  className="btn btn-primary btn-block"
                  disabled={offers.length === 0 || publishers.length === 0}
                  onClick={() => this.handleAddRemoveTargetedPublishers(true)}
                >
                  Remove selected publishers
                </button>
              </Box.Footer>
            </Box>
          </Col>
          <Col md={9}>
            <Box>
              <Box.Header title="Offers" />
              <Box.Body>
                <OffersPanel
                  publishers={publishers}
                  offers={offers}
                  busy={busy}
                  onUpdateList={this.handleUpdateOffers}
                  onClearList={this.handleClearOffers}
                />
              </Box.Body>
            </Box>
          </Col>
        </Row>
      </PageLayout>
    );
  }

  handleUpdateOffers(text) {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/AddRemoveTargetedPublishers/Offers",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: JSON.stringify(text)
    })
      .then(res => {
        this.setState({
          busy: false,
          offers: res.data.filter(x => x != null)
        });
      })
      .catch(err => {
        const error =
          err.response && err.response.status === 401
            ? "Your session has expired. Please refresh the page."
            : err.response
            ? err.response.data
            : err.request || err.message;

        this.setState({
          busy: false,
          error
        });
      });
  }

  handleClearOffers() {
    this.setState({
      offers: [],
      message: null,
      error: null
    });
  }

  handleUpdatePublishers(text) {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/users/lookup",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: JSON.stringify(text)
    })
      .then(res => {
        this.setState({
          busy: false,
          publishers: res.data
        });
      })
      .catch(err => {
        const error =
          err.response && err.response.status === 401
            ? "Your session has expired. Please refresh the page."
            : err.response
            ? err.response.data
            : err.request || err.message;

        this.setState({
          busy: false,
          error
        });
      });
  }

  handleClearPublishers() {
    this.setState({
      publishers: [],
      message: null,
      error: null
    });
  }

  handleAddRemoveTargetedPublishers(remove = false) {
    const { offers, publishers } = this.state;

    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/offers/addremovetargetedpublishers",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: {
        remove,
        offerids: offers.map(x => x.id),
        publisherids: publishers.map(x => x._user_id)
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          offers: res.data.offers
        });
      })
      .catch(err => {
        const error =
          err.response && err.response.status === 401
            ? "Your session has expired. Please refresh the page."
            : err.response
            ? err.response.data
            : err.request || err.message;

        this.setState({
          busy: false,
          error
        });
      });
  }
}
