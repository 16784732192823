import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import { Modal, Button } from "react-bootstrap";
import DataTable from "components/DataTable";
import axios from "axios";

export default class DiscardDialog extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      error: null,
      message: null
    };
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleRowsChanged = this.handleRowsChanged.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Discard Offers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            You are about to discard the offers below. This action may take a
            while. Please do not close this browser window or navigate to a
            different page until you see the message that the action has been
            completed.
          </p>
          <DataTable
            ref={this.tableRef}
            className="table table-condensed table-striped"
            data={this.props.selectedRows}
            columns={[
              {
                title: `<i title="Platform" class="fa fa-info-circle fa-1_4x grey"></i>`,
                data: "targetPlatform",
                name: "targetPlatform",
                render: DataTable.StockRenderers.platform()
              },
              {
                title: `<i title="Store" class="fa fa-info-circle fa-1_4x grey"></i>`,
                data: "storeId",
                name: "storeId",
                render: DataTable.StockRenderers.store()
              },
              {
                title: "Offer ID",
                data: "offerid",
                name: "offerId"
              },
              {
                title: "Offer Name",
                data: "name",
                name: "name",
                className: "truncate-cell-wide",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: "Payout",
                data: "payout",
                name: "payout",
                className: "text-right",
                render: DataTable.StockRenderers.money()
              },
              {
                title: "Countries",
                data: "targetedCountries",
                name: "targetedCountries",
                className: "truncate-cell",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: "Advertiser",
                data: "adNetworkName",
                name: "advertiserName",
                className: "truncate-cell",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: "Clicks",
                data: "clicks",
                name: "clicks",
                className: "text-right",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: `<span title="Total conversions last 3 days">Inst.</span>`,
                data: "conversions",
                name: "conversions",
                className: "text-right",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: `<i title="Status" class="fa fa-info-circle fa-1_4x grey"></i>`,
                data: "moderationStatus",
                name: "moderationStatus",
                className: "text-center",
                render: DataTable.StockRenderers.moderationStatus()
              }
            ]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={this.handleDiscard}
            disabled={this.state.busy}
          >
            Discard Offers
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleDiscard() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/offers/setmoderationstatus",
      method: "post",
      data: {
        offerIds: this.props.selectedRows.map(x => x.offerid),
        moderationStatus: 2
      }
    })
      .then(res => {
        // Notify our parent form of the changes we just made.
        if (this.props.onRowsChanged) this.props.onRowsChanged(res.data);

        // Sync any visual elements with the current rows state.
        this.handleRowsChanged(res.data);

        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  handleRowsChanged(changedRows) {
    // changedRows is the array returned by the server call.
    // We extract pairs of id/moderationStatus.
    const newStatuses = changedRows.reduce((acc, cur) => {
      acc[cur.id] = cur.moderationStatus;
      return acc;
    }, {});

    // We use the pairs to update the underlying records
    // of this dialog with the new moderationStatus values.
    this.props.selectedRows.forEach(row => {
      row.moderationStatus = newStatuses[row.offerid];
    });

    // All table rows should be invalidated to reflect
    // the new state of the rows.
    this.tableRef.current.api
      .rows()
      .invalidate()
      .draw();
  }
}
