import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col, ToggleButton, Checkbox
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import { N } from "lib/Functions";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";

function displayNumberFormat(n, d) {
  return N(n, d);
}
export default class AddViewCommentsDialog extends React.Component {
  static title = (this.state && this.state.showViewAllComments)?"View all Comments":"Reply";

  constructor(props) {
    super(props);
    this.state = {
      showViewAllComments: props.showViewAllComments || false,
      showReply: props.showReply || false,
      user: props.user || false,
      rowDetailData: props.rowDetailData || null,
      action: this.props.action || 'add',
      id: this.props.id || null,
      selectedDate: null,
      issuerId: null,
      issuerTitle: null,
      issuerIdUnselected: null,
      recipientId: null,
      recipientTitle: null,
      recipientIdUnselected: null,
      accountManagerId: null,
      accountManagerTitle: null,
      accountManagerIdUnselected: null,
      notes: null,
      settings: null,
      error: null,
      busy: false,
      message: null
    };
    this.issuerRef = React.createRef();
    this.recipientRef = React.createRef();
    this.accountManagerRef = React.createRef();
    this.handleSaveComment = this.handleSaveComment.bind(this);
  }

  componentDidMount() {
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide} enforceFocus={false}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>{this.props.showViewAllComments?"View all Comments":"Reply"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <Box.Body>
            {(
              this.state.action === 'add' && this.state.issuerId && this.state.recipientId &&
              ((this.state.settings &&
                (_.find(this.state.settings, { 'issuer_id': this.state.issuerId })) &&
                (_.find(this.state.settings, { 'issuer_id': this.state.issuerId }).account_type === "1")) ||
                (this.state.settings && this.state.settings.length === 0)
                ||
                !this.state.settings)
            ) && (
                <Row>
                  <Col md={12}>
                    <div className="alert alert-warning" style={{ height: "60px" }}>
                      <span style={{ float: "left", paddingTop: "5px" }}><i className="fa fa-exclamation-triangle">&nbsp;</i> The selected issuer/customer invoicing is done through Accounting Operations.</span>
                    </div>
                  </Col>
                </Row>
              )
            }
            <Row>
              <Col md={7}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Campaign</ControlLabel>
                      <FormControl
                        name="Campaign"
                        componentClass="input"
                        disabled={true}
                        type="string"
                        maxLength={1000}
                        value={this.state?.rowDetailData?.originalName || ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Advertiser/Agency</ControlLabel>
                      <FormControl
                        name="invoiceIssuerTitle"
                        componentClass="input"
                        disabled={true}
                        type="string"
                        maxLength={1000}
                        value={this.state?.rowDetailData?.invoiceRecipientTitle || ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Campaign ID</ControlLabel>
                      <FormControl
                        name="_id"
                        componentClass="input"
                        disabled={true}
                        type="string"
                        maxLength={1000}
                        value={this.state?.rowDetailData?._id || ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"hidden"}>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Account Category</ControlLabel>
                      <FormControl
                        componentClass="input"
                        type="string"
                        maxLength={20}
                        disabled={true}
                        onChange={e =>
                          this.setState({ accountCategoryName: e.target.value })
                        }
                        defaultValue={this.state.accountCategoryName}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Account Code</ControlLabel>
                      <FormControl
                        componentClass="input"
                        type="string"
                        maxLength={20}
                        disabled={true}
                        onChange={e =>
                          this.setState({ accountCode: e.target.value })
                        }
                        defaultValue={this.state.accountCode}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"hidden"}>
                  <Col md={6}>
                    <ToggleButton
                      id="toggle-check"
                      type="checkbox"
                      variant="secondary"
                      style={{ background: "none", border: "none", boxShadow: "none", paddingLeft: "0" }}
                      checked={this.state.interCompany}
                      value="1"
                      disabled={true}
                      onChange={(e) => {
                        this.setState({ interCompany: e.currentTarget.checked });
                      }}
                    >
                      <span style={{ float: "right" }}>&nbsp;Inter-company</span>
                    </ToggleButton>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Managed by</ControlLabel>
                      <FormControl
                        name="invoiceIssuerTitle"
                        componentClass="input"
                        disabled={true}
                        type="string"
                        maxLength={1000}
                        value={this.state?.rowDetailData?.invoiceIssuerTitle || ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            {this.state.showReply && (
              <Row>
              <Col md={12}>
                  <FormGroup>
                    <ControlLabel>{(!this.state?.rowDetailData?.comments || this.state?.rowDetailData?.comments.length===0)?"New comment":"Reply"} (from: {this.state.user._jwt.email})</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    value={this.state.notes || ""}
                    onChange={(e) => {
                      this.setState({ notes: e.target.value });
                    }}
                    rows={3}
                    />
                    <ToggleButton
                      id="toggle-check"
                      type="checkbox"
                      variant="secondary"
                      style={{ background: "none", border: "none", boxShadow: "none", paddingLeft: "0" }}
                      checked={this.state.commentStatus}
                      value={this.state.commentStatus}
                      onChange={(e) => {
                        this.setState({ commentStatus: e.currentTarget.checked });
                      }}
                    >
                      <span style={{ float: "right" }}>&nbsp;Resolved</span>
                    </ToggleButton><br />
                    <Button
                      bsStyle="primary"
                      onClick={this.handleSaveComment}
                    >
                      Save Comment
                    </Button>
                </FormGroup>
                </Col>
                </Row>
              )}
            <Row>
              <Col md={12}>
                <div>
                  {(this.state?.rowDetailData?.comments || []).slice().reverse().map((comment, idx, array) => {
                    if ((this.state?.rowDetailData?.lastResolvedDate && (comment.dateModified > this.state?.rowDetailData?.lastResolvedDate)) || (!this.state?.rowDetailData?.lastResolvedDate)) {
                      return (<span> {comment.from + ":"}<br /> <i><b>{comment.comment}</b></i><br />{comment.dateModified}<br /><br /></span>);
                    }                  
                })}
                  </div>
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          {(this.state.action === 'add' || (this.state.action === 'edit' && !this.state.invoiceIsEditable)) && (
            <Button
              onClick={this.props.onHide}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  handleSaveComment() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    var url = "/api/v1/tools/savecomment";
    var postData = {
      _id: this.state.rowDetailData._id,
      commentStatus: this.state.commentStatus || false,
      newComment: this.state.notes || "",
      newCommentFrom: this.state.user._jwt.email,
      accountManagerUsername: this.state.rowDetailData.accountManagerUsername
    };

    axios({
      url: url,
      method: "post",
      data: postData
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
