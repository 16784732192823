import React from "react";
import $ from "jquery";
import moment from "moment-timezone";
import axios from "axios";
import download from "downloadjs";
import { Row, Col, FormGroup, Form, ButtonToolbar } from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import {
  AdvertiserPicker,
  PublisherPicker,
  ApplicationPicker,
  CampaignPicker
} from "components/pickers/SelectPickers";
import DateRangePicker from "components/pickers/DateRangePicker";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import Hint from "components/Hint";
import PageLayout from "components/PageLayout";
import { extractFilenameFromResponse } from "lib/Functions";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

export default class CRBySubpublisher extends React.Component {
  static title = "CR By Subpublisher";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      advertiserIds: [],
      publisherIds: [],
      subpublisherIds: [],
      applicationIds: [],
      campaignIds: [],
      date: null,
      groupByAdvertiser: false,
      groupByPublisher: false,
      groupByApplication: false,
      groupByCampaign: false,
      groupByDate: false,
      includeZeroInstalls: false,
      excludeMinClicks: false,
      minClicks: 0,
      includeBlockingRules: false,
      response: null,
      resultset: null,
      busy: false,
      error: null
    };
    this.renderAdvertiserName = this.renderAdvertiserName.bind(this);
    this.renderPublisherName = this.renderPublisherName.bind(this);
    this.renderApplicationName = this.renderApplicationName.bind(this);
    this.renderCampaignName = this.renderCampaignName.bind(this);
    this.renderCR = this.renderCR.bind(this);
    this.renderBlocked = this.renderBlocked.bind(this);
  }

  render() {
    const {
      groupByAdvertiser,
      groupByPublisher,
      groupByApplication,
      groupByCampaign,
      groupByDate,
      includeZeroInstalls,
      excludeMinClicks,
      minClicks,
      includeBlockingRules,
      resultset,
      busy,
      error
    } = this.state;
    const errorSummary = buildErrorSummary(error);

    const columns = [
      {
        title: "Date",
        data: "date",
        name: "date",
        defaultContent: "",
        visible: groupByDate
      },
      {
        title: "Advertiser",
        data: "advertiserid",
        name: "advertiser",
        defaultContent: "",
        visible: groupByAdvertiser,
        render: (d, t, r, m) => d && this.state.response.accountNames[d]
      },
      {
        title: "Publisher",
        data: "packageownerid",
        name: "publisher",
        defaultContent: "",
        visible: groupByPublisher,
        render: (d, t, r, m) => d && this.state.response.accountNames[d]
      },
      {
        title: "Application",
        data: "adv_application_fk",
        name: "application",
        defaultContent: "",
        visible: groupByApplication,
        render: (d, t, r, m) => d && this.state.response.applicationNames[d]
      },
      {
        title: "Campaign",
        data: "offerid",
        name: "campaign",
        defaultContent: "",
        visible: groupByCampaign,
        render: (d, t, r, m) => d && this.state.response.campaignNames[d]
      },
      {
        title: "Encoded Subpublisher",
        data: "trafficsource_subid_encoded_fk"
      },
      {
        title: "Subpublisher",
        data: "trafficsource_subid_fk"
      },
      {
        title: "Clicks",
        data: "clicks",
        className: "text-right",
        render: DataTable.StockRenderers.count()
      },
      {
        title: "Installs&nbsp;All",
        data: "installs",
        className: "text-right",
        render: DataTable.StockRenderers.count()
      },
      {
        title: "Installs&nbsp;Opt",
        data: "installs_opt",
        className: "text-right",
        render: DataTable.StockRenderers.count()
      },
      {
        title: "CR&nbsp;All",
        data: "cr",
        className: "text-right",
        render: this.renderCR
      },
      {
        title: "CR&nbsp;Opt",
        data: "cr_opt",
        className: "text-right",
        render: this.renderCR
      },
      {
        title: "Blocked",
        data: "blockedAt",
        className: "text-nowrap text-sm",
        name: "blocked",
        defaultContent: "",
        visible: includeBlockingRules,
        render: this.renderBlocked
      }
    ];

    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={CRBySubpublisher.title}
        description={
          <p>
            You can access data from 18/01/2019 and up to 2 hours before the
            current time.
          </p>
        }
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={busy}>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <label>Advertisers</label>
                  <AdvertiserPicker
                    className="form-control"
                    multiple
                    placeholder="Enter ids or names"
                    onChange={value => this.setState({ advertiserIds: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Publishers</label>
                  <PublisherPicker
                    className="form-control"
                    multiple
                    placeholder="Enter ids or names"
                    onChange={value => this.setState({ publisherIds: value })}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <label>Applications</label>
                  <ApplicationPicker
                    className="form-control"
                    multiple
                    placeholder="Enter hex ids"
                    onChange={value => this.setState({ applicationIds: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Campaigns</label>
                  <CampaignPicker
                    className="form-control"
                    multiple
                    placeholder="Enter hex ids"
                    onChange={value => this.setState({ campaignIds: value })}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <label>Date</label>
                  <DateRangePicker
                    className="form-control"
                    opens="left"
                    onChange={value => this.setState({ date: value })}
                    ranges={{
                      Today: [moment().startOf("day"), moment().endOf("day")],
                      Yesterday: [
                        moment()
                          .subtract(1, "days")
                          .startOf("day"),
                        moment()
                          .subtract(1, "days")
                          .endOf("day")
                      ],
                      "This week so far": [
                        moment().startOf("isoWeek"),
                        moment().endOf("day")
                      ],
                      "Last week": [
                        moment()
                          .subtract(1, "weeks")
                          .startOf("isoWeek"),
                        moment()
                          .subtract(1, "weeks")
                          .endOf("isoWeek")
                      ],
                      "This month so far": [
                        moment().startOf("month"),
                        moment().endOf("day")
                      ],
                      "Last month": [
                        moment()
                          .subtract(1, "months")
                          .startOf("month"),
                        moment()
                          .subtract(1, "months")
                          .endOf("month")
                      ]
                    }}
                    minDate={moment("2019-01-18", "YYYY-MM-DD")}
                    maxDate={moment().endOf("day")}
                    startDate={moment().startOf("day")}
                    endDate={moment().endOf("day")}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Encoded Subpublishers</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter one id per line"
                    onChange={e =>
                      this.setState({
                        subpublisherIds: e.target.value
                          .split("\n")
                          .filter(x => x !== "")
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <label>Group By</label>
                  <Row>
                    <Col md={12}>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          onChange={e => {
                            const checked = e.target.checked;
                            this.setState(state => ({
                              groupByAdvertiser: checked,
                              includeBlockingRules:
                                state.includeBlockingRules && checked
                            }));
                          }}
                        />
                        Advertiser
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          onChange={e => {
                            const checked = e.target.checked;
                            this.setState(state => ({
                              groupByPublisher: checked,
                              includeBlockingRules:
                                state.includeBlockingRules && checked
                            }));
                          }}
                        />
                        Publisher
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          onChange={e =>
                            this.setState({
                              groupByApplication: e.target.checked
                            })
                          }
                        />
                        Application
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          onChange={e =>
                            this.setState({ groupByCampaign: e.target.checked })
                          }
                        />
                        Campaign
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          onChange={e =>
                            this.setState({ groupByDate: e.target.checked })
                          }
                        />
                        Date
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <label>Other</label>
                  <Row>
                    <Col md={12}>
                      <Form inline>
                        <FormGroup>
                          <label className="checkbox-inline">
                            <input
                              type="checkbox"
                              checked={includeZeroInstalls}
                              onChange={e =>
                                this.setState({
                                  includeZeroInstalls: e.target.checked
                                })
                              }
                            />
                            Include zero installs
                          </label>
                        </FormGroup>
                        {includeZeroInstalls && (
                          <>
                            <FormGroup>
                              <label className="checkbox-inline tool-margin">
                                <input
                                  type="checkbox"
                                  checked={excludeMinClicks}
                                  onChange={e =>
                                    this.setState({
                                      excludeMinClicks: e.target.checked
                                    })
                                  }
                                />
                                but exclude clicks less than
                              </label>
                            </FormGroup>
                            <FormGroup>
                              <input
                                type="text"
                                className="form-control input-sm tool-margin"
                                style={{ width: "80px" }}
                                value={minClicks}
                                onChange={e =>
                                  this.setState({
                                    minClicks: parseInt(e.target.value) || 0
                                  })
                                }
                              />
                            </FormGroup>
                          </>
                        )}
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={includeBlockingRules}
                            onChange={e =>
                              this.setState({
                                includeBlockingRules: e.target.checked
                              })
                            }
                            disabled={!groupByAdvertiser || !groupByPublisher}
                          />
                          Include blocking rules
                        </label>
                        &nbsp;
                        <Hint id="about-include-blocking-rules">
                          <p>
                            Loads the active blocking rules for all the
                            advertisers in the results and compares them to each
                            row.
                          </p>
                          <p>
                            This option can be selected only if you have grouped
                            by at least Advertiser and Publisher.
                          </p>
                        </Hint>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <DownloadButton
                onClick={this.handleDownloadOrPreview.bind(this, true)}
              />
              <PreviewButton
                onClick={this.handleDownloadOrPreview.bind(this, false)}
              />
            </ButtonToolbar>
          </Box.Footer>
        </Box>
        {resultset && (
          <Box>
            <Box.Body>
              <DataTable.Toolbar tableRef={this.tableRef} noReloadButton />
              <DataTable
                ref={this.tableRef}
                className="table table-condensed table-striped"
                data={resultset}
                columns={columns}
                footer={
                  <>
                    <tr>
                      <td
                        className="text-right"
                        style={{ paddingTop: "20px" }}
                        colSpan={7}
                      >
                        Page Totals
                      </td>
                      <td
                        className="text-right"
                        style={{ paddingTop: "20px" }}
                        id="ptclicks"
                      ></td>
                      <td
                        className="text-right"
                        style={{ paddingTop: "20px" }}
                        id="ptinstalls"
                      ></td>
                      <td
                        className="text-right"
                        style={{ paddingTop: "20px" }}
                        id="ptinstallsopt"
                      ></td>
                      <td
                        className="text-right"
                        style={{ paddingTop: "20px" }}
                        colSpan={3}
                      ></td>
                    </tr>
                    <tr>
                      <td className="text-right" colSpan={7}>
                        Table Totals
                      </td>
                      <td className="text-right" id="gtclicks"></td>
                      <td className="text-right" id="gtinstalls"></td>
                      <td className="text-right" id="gtinstallsopt"></td>
                      <td className="text-right" colSpan={3}></td>
                    </tr>
                  </>
                }
                footerCallback={(tfoot, data, start, end, display) => {
                  const pt = {
                    clicks: display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].clicks), 0),
                    installs: display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].installs), 0),
                    installs_opt: display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].installs_opt), 0)
                  };
                  const gt = {
                    clicks: display.reduce(
                      (acc, cur) => (acc += data[cur].clicks),
                      0
                    ),
                    installs: display.reduce(
                      (acc, cur) => (acc += data[cur].installs),
                      0
                    ),
                    installs_opt: display.reduce(
                      (acc, cur) => (acc += data[cur].installs_opt),
                      0
                    )
                  };
                  const footer = $(tfoot).parent();

                  footer.find("#ptclicks").html(pt.clicks);
                  footer.find("#ptinstalls").html(pt.installs);
                  footer.find("#ptinstallsopt").html(pt.installs_opt);

                  footer.find("#gtclicks").html(gt.clicks);
                  footer.find("#gtinstalls").html(gt.installs);
                  footer.find("#gtinstallsopt").html(gt.installs_opt);
                }}
              />
            </Box.Body>
          </Box>
        )}
      </PageLayout>
    );
  }

  renderAdvertiserName(d, t, r, m) {
    if (d) {
      if (/^\d{1,5}$/.test(d)) {
        return this.state.response.networkNames[d] || "";
      } else {
        return this.state.response.userNames[d] || "";
      }
    }
    return d;
  }

  renderPublisherName(d, t, r, m) {
    if (d) {
      return this.state.response.userNames[d] || "";
    }
    return d;
  }

  renderApplicationName(d, t, r, m) {
    if (d) {
      return this.state.response.applicationNames[d] || "";
    }
    return d;
  }

  renderCampaignName(d, t, r, m) {
    if (d) {
      return this.state.response.campaignNames[d] || "";
    }
    return d;
  }

  renderCR(d, t, r, m) {
    if (d !== null && d > 0) {
      return parseFloat(d).toFixed(4);
    }
    return "-";
  }

  renderBlocked(d, t, r, m) {
    if (d) {
      const { userNames, blockingRules } = this.state.response;
      const blockingRule = blockingRules[r.blockingRuleId];
      const displayValue = moment.utc(d).format("YYYY-MM-DD HH:mm:ss");
      const content = r.blockedExplicitly
        ? displayValue
        : `<em>${displayValue}</em>`;
      const infos = [
        `advertiser: ${userNames[blockingRule.advertiserId]}`,
        `publisher: ${userNames[blockingRule.trafficSourceId]} (${
          blockingRule.trafficSourceId
        })`,
        `subpublisher: ${blockingRule.trafficSourceSubId || "*"}`,
        `application: ${blockingRule.applicationId || "*"}`,
        `campaign: ${blockingRule.campaignId || "*"}`,
        "",
        `blocked at: ${displayValue}`,
        `blocked by: ${userNames[blockingRule.moderatorId]}`
      ];
      const title = infos.join("&#13;").replace(/\s/g, "&nbsp;");
      return `<span title=${title}>${content}</span>`;
    }
    return d;
  }

  handleDownloadOrPreview(excel, e) {
    const {
      groupByAdvertiser,
      groupByPublisher,
      groupByApplication,
      groupByCampaign,
      groupByDate,
      includeZeroInstalls,
      excludeMinClicks,
      minClicks,
      includeBlockingRules,
      date
    } = this.state;
    this.setState({ busy: true, error: null });

    axios({
      url: `/api/v1/reports/crbysubpublisher`,
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: excel ? "blob" : "json",
      data: {
        advertiserIds: this.state.advertiserIds,
        publisherIds: this.state.publisherIds,
        subpublisherIds: this.state.subpublisherIds,
        applicationIds: this.state.applicationIds,
        campaignIds: this.state.campaignIds,
        groupByAdvertiser,
        groupByPublisher,
        groupByApplication,
        groupByCampaign,
        groupByDate,
        includeZeroInstalls,
        minClicks:
          includeZeroInstalls && excludeMinClicks && minClicks > 0
            ? minClicks
            : null,
        includeBlockingRules,
        fromDateInclusiveUtc: date
          ? moment(date[0]).format("YYYY-MM-DD")
          : undefined,
        toDateExclusiveUtc: date
          ? moment(date[1])
              .add(1, "second")
              .format("YYYY-MM-DD")
          : undefined,
        output: excel ? "Excel" : "Resultset"
      }
    })
      .then(res => {
        if (excel) {
          this.setState({
            error: null,
            busy: false
          });
          const filename = extractFilenameFromResponse(res);
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({
            response: res.data,
            resultset: res.data.data,
            error: null,
            busy: false
          });
          const {
            groupByAdvertiser,
            groupByPublisher,
            groupByApplication,
            groupByCampaign,
            groupByDate
          } = this.state;
          const dataTable = this.tableRef.current;
          dataTable.setColumnVisibility("date:name", groupByDate);
          dataTable.setColumnVisibility("advertiser:name", groupByAdvertiser);
          dataTable.setColumnVisibility("publisher:name", groupByPublisher);
          dataTable.setColumnVisibility("application:name", groupByApplication);
          dataTable.setColumnVisibility("campaign:name", groupByCampaign);
          dataTable.setColumnVisibility("blocked:name", includeBlockingRules);
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              error: e.srcElement.result,
              busy: false
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            error,
            busy: false
          });
        }
      });
  }
}
