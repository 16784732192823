import React from "react";
import { b64encode } from "@waiting/base64";

function cookieValue(key: string) {
  var cookieValueArr = document.cookie.split('; ');
  var jwtc = cookieValueArr.find(row => row.startsWith(key + '='));
  if (jwtc) {
    var jwtcValue = jwtc.split('=')[1]
    return jwtcValue
  }
  else return null; 
}

function parseJwt(token: string): IClaimsPrincipal | null {
  var base64Url = token.split(".")[1];
  if (!base64Url) return null;
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

function getDate(seconds: number) {
  let d = new Date(0);
  d.setUTCSeconds(seconds);
  return d;
}

interface IClaimsPrincipal {
  token: string;
  role: string | string[];
  nameid: string;
  unique_name: string;
  email: string;
  apikey: string;
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  aud: string;
  [claim: string]: any;
}

export class User {
  private _jwt: IClaimsPrincipal | null = null;

  constructor() {
    this.loadFromCookie();
  }

  loadFromCookie() {
    let token = cookieValue("jwtc");
    if (token) {
      this._jwt = parseJwt(token);
    } else {
      this._jwt = null;
    }
  }

  get isAuthenticated(): boolean {
    return this._jwt !== null;
  }

  get roles(): Array<string> | null {
    if (this._jwt) {
      if (typeof this._jwt.role === "string") return [this._jwt.role];
      if (Array.isArray(this._jwt.role)) return this._jwt.role;
    }
    return null;
  }

  get tokenExpires(): Date | null {
    if (this._jwt) return getDate(this._jwt.exp);
    return null;
  }

  get tokenIssued(): Date | null {
    if (this._jwt) return getDate(this._jwt.iat);
    return null;
  }

  get tokenHasExpired(): boolean {
    if (this._jwt && this._jwt.exp > (Date.now()/1000)) return false;
    return true;
  }
  
  isInRole(name: string): boolean {
    if (this._jwt) {
      return typeof this._jwt.role === "string"
        ? this._jwt.role === name
        : this._jwt.role.includes(name);
    }
    return false;
  }

  findFirst(claim: string): string | null {
    if (this._jwt) return this._jwt[claim];
    return null;
  }

  login(username: string, password: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      fetch("/api/v1/token/issue", {
        method: "GET",
        headers: {
          Authorization: `Basic ${b64encode(username + ":" + password)}`
        }
      })
        .then(res => {
          if (res.ok) {
            this.loadFromCookie();
            return resolve(true);
          } else if (res.status === 401) {
            return resolve(false);
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(err => {
          return reject(err);
        });
    });
  }

  logout() {
    return new Promise<void>((resolve, reject) => {
      fetch("/api/v1/token/clear")
        .then(res => {
          if (res.ok) {
            this.loadFromCookie();
            return resolve();
          } else {
            throw new Error(res.statusText);
          }
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
}

export const UserContext = React.createContext(new User());
