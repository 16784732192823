import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import DataTable from "components/DataTable";
import download from "downloadjs";
import { buildGetParams } from "lib/Functions";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  ButtonToolbar
} from "react-bootstrap";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

export default class AdvertiserApps extends React.Component {
  static title = "Advertiser Apps";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      objectiveLink: null,
      busy: false,
      error: null,
      response: null
    };
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={AdvertiserApps.title}
        description={
          <p>
            This report contains all the apps that a specific network has, after
            scanning the redirection data that are recorded on our side for all
            the offers.
            <br />
            Please insert a tracking link for the network that you would like to
            check.
            <br />
            You should not insert a link from a tracking provider (Adjust,
            AppsFlyer, Onelink, Kochava, AdBrix, Appmetrica, Singular, Branch
            etc), as we cannot automatically determine which is the network that
            generated the link.
            <br />
            You should not insert a link that belongs to a play store (google
            play, apple store, onestore etc)
            <br />
          </p>
        }
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={this.state.busy}>
          <Box.Body>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Objective link</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Enter tracking link from a single network"
                    rows={3}
                    onChange={e =>
                      this.setState({ objectiveLink: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <DownloadButton
                onClick={this.handleDownloadOrPreview.bind(this, true)}
              />
              <PreviewButton
                onClick={this.handleDownloadOrPreview.bind(this, false)}
              />
            </ButtonToolbar>
          </Box.Footer>
        </Box>
        {this.state.response && (
          <Box>
            <Box.Body>
              <DataTable.Toolbar
                tableRef={this.tableRef}
                bsSize="small"
                noReloadButton
              />
              <DataTable
                className="table table-striped"
                ref={this.tableRef}
                data={this.state.response.data}
                columns={[
                  {
                    title: "Package Name",
                    data: "packageName",
                    name: "Package Name"
                  },
                  {
                    title: "App Title",
                    data: "appTitle",
                    name: "App Title"
                  }
                ]}
              />
            </Box.Body>
          </Box>
        )}
      </PageLayout>
    );
  }

  handleDownloadOrPreview(excel) {
    this.setState({ busy: true, error: null });
    axios
      .get("/api/v1/reports/AdvertiserApps", {
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        responseType: excel ? "blob" : "json",
        params: buildGetParams({
          objectiveLink: this.state.objectiveLink,
          output: excel ? "Excel" : "Resultset"
        })
      })
      .then(res => {
        if (excel) {
          this.setState({
            busy: false,
            error: null
          });
          const filename = `AdvertiserApps.xlsx`;
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({
            busy: false,
            error: null,
            response: res.data
          });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              busy: false,
              error: e.srcElement.result
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            busy: false,
            error
          });
        }
      });
  }
}
