import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

export default class DeleteDialog extends React.Component {
  static title = "Delete App from Blacklist";

  constructor(props) {
    super(props);
    this.state = {
      entryId: props.entryId||null,
      osPlatform: props.osPlatform||null,
      package: props.package||null,
      title: props.title||null,
      exclusions: props.exclusions||null,
      //usernames: props.usernames||null,
      error: null,
      busy: false,
      message: null
    };
    this.handleAppDeletion = this.handleAppDeletion.bind(this);
    //this.getExclusionsHtml = this.getExclusionsHtml.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    var osPlatformStr = '';
    if(this.props.osPlatform!==null && this.props.osPlatform!==undefined){
      switch(parseInt(this.props.osPlatform)){
        case 0: osPlatformStr = 'Android'; break;
        case 1: osPlatformStr = 'iOS'; break;
        default: break;
      }
    }
    //var exclusionsHtml = this.getExclusionsHtml(this.state.exclusions, this.state.usernames);
    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Delete App from Blacklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          
          <Box.Body>
            <Row>
              <Col md={12}>
                <p>
                  You are about to <strong>delete</strong> the <strong>{osPlatformStr}</strong> {this.state.title?'App Title ':(this.state.package?'App Package Name ':'')} value <strong>"{this.state.title||this.state.package}"</strong> from Blacklist.
                </p>
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !this.state.entryId
            }
            bsStyle="danger"
            onClick={this.handleAppDeletion}
          >
            Delete
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  /*getExclusionsHtml(exclusions,usernames){
    return (
      exclusions && usernames &&
      exclusions.map(x => `<div>${usernames[x]}&nbsp;(${x})</div>`).join("")
    );
  }*/
  
  handleAppDeletion() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });
    
    let postData = {
      _id: this.state.entryId
    };
    if(this.state.package&&this.state.package.length){
      postData.valueIsPackage = true;
      postData.appBlacklistValue = this.state.package;
    }else if(this.state.title&&this.state.title.length){
      postData.valueIsPackage = false;
      postData.appBlacklistValue = this.state.title;
    }
    
    postData.hasExclusions = (this.state.exclusions && this.state.exclusions.length) ? true : false;
    
    axios({
      url: "/api/v1/reports/deleteblacklistedapp",
      method: "post",
      data: postData
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
