export function incr(before, after) {
  return after - before;
}

export function incrPrc(before, after) {
  if (before === 0 && after === 0) return 0;
  if (before === 0 && after > 0) return 100;
  if (before === 0 && after < 0) return -100;
  return ((after - before) / before) * 100;
}

export function isDemand(affiliateSide) {
  return affiliateSide === "demand";
}
