import React from "react";
import {
  Modal,
  FormGroup,
  ControlLabel,
  Button
} from "react-bootstrap";
import { PublisherPicker } from "components/pickers/SelectPickers";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

export default class DeleteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publisher: "",
      error: null
    };
    this.userPickerRef = React.createRef();
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.setState({
        publisher: "",
        error: null
      });
      this.userPickerRef.current.clearSingle();
    }
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.props.onHide}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Publisher Auto-Targeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          <FormGroup>
            <ControlLabel>Publisher</ControlLabel>
            <PublisherPicker
              ref={this.userPickerRef}
              className="form-control"
              placeholder="Enter id or name"
              onChange={value => this.setState({ publisher: value })}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" disabled={!this.state.publisher||!(this.state.publisher).length} onClick={this.handleRemove}>
            Remove
          </Button>
          <Button onClick={this.props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleRemove() {
    axios({
      url: "/api/v1/reports/removeautotargetedpublisher",
      method: "post",
      data: {
        PublisherId: this.state.publisher
      }
    })
      .then(() => {
        //this.props.onAdded();
        this.props.onHide();
      })
      .catch(error => this.setState({ error: error.response.data }));
  }
}
