import React from "react";
import both from "assets/incentivized/both.png";
import incent from "assets/incentivized/incent.png";
import non_incent from "assets/incentivized/non-incent.png";
import uncertain from "assets/incentivized/uncertain.png";

export default function(props) {
  if (props.value === 0) {
    return (
      <img className="incentivized-icon" src={both} title="Both" alt="Both" />
    );
  } else if (props.value === 1) {
    return (
      <img
        className="incentivized-icon"
        src={incent}
        title="Incentivized"
        alt="Incentivized"
      />
    );
  } else if (props.value === 2) {
    return (
      <img
        className="incentivized-icon"
        src={non_incent}
        title="Non-incentivized"
        alt="Non-incentivized"
      />
    );
  } else if (props.value === 3) {
    return (
      <img
        className="incentivized-icon"
        src={uncertain}
        title="Uncertain"
        alt="Uncertain"
      />
    );
  }
}
