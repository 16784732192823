import React from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import axios from "axios";
import download from "downloadjs";
import moment from "moment-timezone";
import { Box } from "components/admin-lte";
import buildErrorSummary from "components/ErrorSummary";
import { extractFilenameFromResponse } from "lib/Functions";
import DateRangePicker from "components/pickers/DateRangePicker";
import PageLayout from "components/PageLayout";
import DownloadButton from "components/stock/DownloadButton";

moment.tz.setDefault("UTC");

export default class TeamPerformance extends React.Component {
  static title = "Team Performance";

  constructor(props) {
    super(props);
    this.state = {
      date: [moment().subtract(1, "day"), moment().subtract(1, "day")],
      busy: false,
      error: null
    };
    this.handleDownload = this.handleDownload.bind(this);
  }

  render() {
    const { date, busy, error } = this.state;
    const errorSummary = buildErrorSummary(error);

    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={TeamPerformance.title}
        description={
          <p>
            Compares the revenues of each active account manager for the month
            of the Reference Date and until the Reference Date, to the 1st
            interim (6-month period) of 2019 and displays the targets to be
            reached until the end of that month.
            <br />
            This report uses the information entered on{" "}
            <a
              href="http://gcp-siarros.minimob.cloud/revenuereport"
              target="_blank"
              rel="noopener noreferrer"
            >
              this website
            </a>{" "}
            to determine the advertisers or networks that each account manager
            is assigned to. Please make sure you keep this information
            up-to-date.
          </p>
        }
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={busy}>
          <Box.Body>
            <Row>
              <Col sm={12} lg={3}>
                <FormGroup>
                  <label>Reference Date</label>
                  <DateRangePicker
                    className="form-control"
                    onChange={value => this.setState({ date: value })}
                    minDate={moment("2019-07-01")}
                    maxDate={moment().subtract(1, "day")}
                    singleDatePicker={true}
                    startDate={date[0]}
                    endDate={date[1]}
                    autoUpdateInput={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <DownloadButton onClick={this.handleDownload} disabled={!date} />
          </Box.Footer>
        </Box>
      </PageLayout>
    );
  }

  handleDownload() {
    const { date } = this.state;
    this.setState({ busy: true, error: null });
    axios({
      url: "/api/v1/reports/teamperformance",
      method: "get",
      params: {
        referenceDate: moment(date[0]).format("YYYY-MM-DD")
      },
      responseType: "blob"
    })
      .then(res => {
        this.setState({ busy: false, error: null });
        const filename = extractFilenameFromResponse(res);
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }
}
