import React from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";

export default class HopsDialog extends React.Component {
  state = {
    redirections: [],
    busy: true
  };

  componentDidMount() {
    axios({
      url: `/api/v1/reports/RedirectionData/${this.props.id}`,
      method: "get"
    })
      .then(res =>
        this.setState({
          redirections: res.data,
          busy: false
        })
      )
      .catch(console.log);
  }

  render() {
    const { id, name, onHide } = this.props;
    const { busy, redirections } = this.state;

    return (
      <Modal bsSize="large" show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <small>
              {id}: {name}
            </small>
            <br />
            Hops
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {busy ? (
            <div className="text-center">Loading...</div>
          ) : (
            <table className="table table-condensed">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tracking Link</th>
                  <th>Network</th>
                </tr>
              </thead>
              <tbody>
                {redirections.map((x, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td className="wrap-cell" style={{ width: "100%" }}>
                      {x.redirections}
                    </td>
                    <td className="text-nowrap">{x.network}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
