import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

export default class AddDialog extends React.Component {
  static title = "Add Invoice Issuer";

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      title: null,
      address: null,
      address2: null,
      vatNo: null,
      regNo: null,
      companyDetails: null,
      error: null,
      busy: false,
      message: null
    };
    this.handleInvoiceIssuer = this.handleInvoiceIssuer.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    
    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Add Invoice Issuer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>Please fill out the invoice issuer details.</p>
          <Box.Body>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Title</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={200}
                    onChange={e =>
                      this.setState({ title: e.target.value })
                    }
                    defaultValue={this.state.title}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Address</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={200}
                    onChange={e =>
                      this.setState({ address: e.target.value })
                    }
                    defaultValue={this.state.address}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Address 2</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={200}
                    onChange={e =>
                      this.setState({ address2: e.target.value })
                    }
                    defaultValue={this.state.address2}
                  />
                </FormGroup>
              </Col>  
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>VAT No.</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={20}
                    onChange={e =>
                      this.setState({ vatNo: e.target.value })
                    }
                    defaultValue={this.state.vatNo}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Reg. No.</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={20}
                    onChange={e =>
                      this.setState({ regNo: e.target.value })
                    }
                    defaultValue={this.state.regNo}
                  />
                </FormGroup>
                {/*<FormGroup>
                  <ControlLabel>Company Details</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={300}
                    onChange={e =>
                      this.setState({ companyDetails: e.target.value })
                    }
                    defaultValue={this.state.companyDetails}
                  />
                </FormGroup>*/}
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              (!this.state.title || !this.state.address)
            }
            bsStyle="primary"
            onClick={this.handleInvoiceIssuer}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleInvoiceIssuer() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/addinvoiceissuer",
      method: "post",
      data: {
        Title: this.state.title?this.state.title:null,
        Address: this.state.address?this.state.address:null,
        Address2: this.state.address?this.state.address2:null,
        Vat_no: this.state.vatNo?this.state.vatNo:null,
        Reg_no: this.state.regNo?this.state.regNo:null,
        CompanyDetails: this.state.companyDetails?this.state.companyDetails:null,
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
