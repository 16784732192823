import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import axios from "axios";
import download from "downloadjs";
import { extractFilenameFromResponse } from "lib/Functions";
import PageLayout from "components/PageLayout";
import DownloadButton from "components/stock/DownloadButton";
import DiscardDialog from "./DiscardDialog";
import { ButtonGroup, Button } from "react-bootstrap";
import { UserContext } from "lib/user";

export default class NonConvertingOffers extends React.Component {
  static title = "Non Converting Offers";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      showDiscard: false,
      busy: false,
      error: null
    };
    this.handleDownload = this.handleDownload.bind(this);
  }

  render() {
    return (
      <>
        <PageLayout
          breadcrumbs={["Reports"]}
          title={NonConvertingOffers.title}
          description={
            <p>
              All the active network offers that have more than 250k clicks and
              0 conversions during the last 3 days.
            </p>
          }
        >
          <Box busy={this.state.busy}>
            <Box.Header>
              <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
              <ButtonGroup bsSize="small">
                <UserContext.Consumer>
                  {user => (
                    <Button
                      disabled={this.state.selectedRows.length === 0}
                      //onClick={() => this.setState({ showDiscard: true })}
                      onClick={(e) => {
                        if(!this.checkTokenExpired(user)){
                          this.setState({ showDiscard: true });
                        }
                      }}
                    >
                      Discard...
                    </Button>
                  )}
                </UserContext.Consumer>
              </ButtonGroup>
            </Box.Header>
            <Box.Body>
              <DataTable
                className="table table-condensed table-striped table-hover"
                ref={this.tableRef}
                onSelect={rows => this.setState({ selectedRows: rows })}
                showCheckBoxes
                noSelectedOnly
                ajax={{
                  url: "/api/v1/reports/nonconvertingoffers",
                  dataSrc: "items"
                }}
                deferRender={true}
                columns={[
                  {
                    title: `<i title="Platform" class="fa fa-info-circle fa-1_4x grey"></i>`,
                    data: "targetPlatform",
                    name: "targetPlatform",
                    render: DataTable.StockRenderers.platform()
                  },
                  {
                    title: `<i title="Store" class="fa fa-info-circle fa-1_4x grey"></i>`,
                    data: "storeId",
                    name: "storeId",
                    render: DataTable.StockRenderers.store()
                  },
                  {
                    title: "Offer ID",
                    data: "offerid",
                    name: "offerId"
                  },
                  {
                    title: "Offer Name",
                    data: "name",
                    name: "name",
                    className: "truncate-cell-wide",
                    render: DataTable.StockRenderers.textWithTooltip()
                  },
                  {
                    title: "Payout",
                    data: "payout",
                    name: "payout",
                    className: "text-right",
                    render: DataTable.StockRenderers.money()
                  },
                  {
                    title: "Countries",
                    data: "targetedCountries",
                    name: "targetedCountries",
                    className: "truncate-cell",
                    render: DataTable.StockRenderers.textWithTooltip()
                  },
                  {
                    title: "Advertiser",
                    data: "adNetworkName",
                    name: "advertiserName",
                    className: "truncate-cell",
                    render: DataTable.StockRenderers.textWithTooltip()
                  },
                  {
                    title: "Clicks",
                    data: "clicks",
                    name: "clicks",
                    className: "text-right",
                    render: DataTable.StockRenderers.textWithTooltip()
                  },
                  {
                    title: `<span title="Total conversions last 3 days">Inst.</span>`,
                    data: "conversions",
                    name: "conversions",
                    className: "text-right",
                    render: DataTable.StockRenderers.textWithTooltip()
                  },
                  {
                    title: `<i title="Status" class="fa fa-info-circle fa-1_4x grey"></i>`,
                    data: "moderationStatus",
                    name: "moderationStatus",
                    className: "text-center",
                    render: DataTable.StockRenderers.moderationStatus()
                  }
                ]}
                order={[]}
              />
            </Box.Body>
            <Box.Footer>
              <DownloadButton
                onClick={this.handleDownload}
                disabled={this.state.loading}
              />
            </Box.Footer>
          </Box>
        </PageLayout>
        {this.state.showDiscard && (
          <DiscardDialog
            selectedRows={this.state.selectedRows}
            onHide={() => this.setState({ showDiscard: false })}
            onRowsChanged={() => {
              this.tableRef.current.api
                .rows()
                .invalidate()
                .draw();
            }}
          />
        )}
      </>
    );
  }

  handleDownload() {
    this.setState({ busy: true, error: null });
    axios({
      url: `/api/v1/reports/nonconvertingoffers?output=Excel`,
      method: "get",
      responseType: "blob"
    })
      .then(res => {
        this.setState({ busy: false, error: null });
        const filename = extractFilenameFromResponse(res);
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }

  checkTokenExpired(user){
    if (user.tokenHasExpired) {
      window.location.replace('/login');
      return true;
    }
    return false;
  }
}
