import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col, ToggleButton
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import { N } from "lib/Functions";
import { InvoiceIssuerPicker } from "components/pickers/SelectPickers";
import { InvoiceRecipientPicker } from "components/pickers/SelectPickers";
import { AccountManagerPicker } from "components/pickers/SelectPickers";
import DateRangePicker from "components/pickers/DateRangePicker";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";

function displayNumberFormat(n,d){
  return N(n,d);
}
export default class AddDialog extends React.Component {
  static title = "Add/Edit Invoice";

  constructor(props) {
    super(props);
    this.state = {
      action: this.props.action||'add',
      invoiceIsEditable: this.props.action==='edit'?false:true,
      id: this.props.id || null,
      invoiceLines: [this.getNewInvoiceLine()],
      invoiceLinesOrig: [{
        _id: null,
        description: null,
        unitPrice: 0.00,
        quantity: 1,
        deleted: false
      }],
      selectedDate: null,
      issuerId: null,
      issuerTitle: null,
      issuerIdUnselected: null,
      recipientId: null,
      recipientTitle: null,
      recipientIdUnselected: null,
      accountManagerId: null,
      accountManagerTitle: null,
      accountManagerIdUnselected: null,
      invoiceDate: this.props.action==='edit'?null:moment().startOf("day"),
      invoicePeriod: null,
      invoiceTypeId: null,
      invoiceTypes: null,
      accountCategoryId: null,
      accountCode: null,
      interCompany: null,
      invoiceText: null,
      notes: null,
      taxRate: null,
      exchangeRate: this.props.action === 'edit' ? null : "0.0000",
      exchangeRateOrig: this.props.action === 'edit' ? null : "0.0000",
      unlocked: null,
      exported: null,
      currency: '',
      currency_symbol: '',
      settings: null,
      error: null,
      busy: false,
      message: null
    };
    this.issuerRef = React.createRef();
    this.recipientRef = React.createRef();
    this.accountManagerRef = React.createRef();
    this.handleSaveInvoice = this.handleSaveInvoice.bind(this);
    this.getNewInvoiceLine = this.getNewInvoiceLine.bind(this);
    this.handleAddLine = this.handleAddLine.bind(this);
    this.handleDeleteLine = this.handleDeleteLine.bind(this);
    this.getRecipientCategory = this.getRecipientCategory.bind(this);
    this.getInvoice = this.getInvoice.bind(this);
    this.unlockInvoice = this.unlockInvoice.bind(this);
    this.exportInvoice = this.exportInvoice.bind(this);
  }
  
  componentDidMount() {
    if(this.state.action==='edit'){
      this.getInvoice();
    }      
  }
  
  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    var exchangeRateValue = this.state.exchangeRate!==null?displayNumberFormat(this.state.exchangeRate, 4):null;
    var invoiceDateStart = this.state.invoiceDate!==null?moment.utc(this.state.invoiceDate).startOf("day"):null;
    var invoiceDateEnd = this.state.invoiceDate !== null ? moment.utc(this.state.invoiceDate).endOf("day") : null;
    var totalAmountChanged = (
      (this.calculateServicesTotalOrig("dollar") !== this.calculateServicesTotal("dollar")) ||
      ((this.calculateServicesTotalOrig("euro") !== this.calculateServicesTotal("euro")) && this.state.exchangeRateOrig !== 0)
    );

    var invoiceAllowEdit = true;
    if (this.state.invoiceDate && this.state.invoiceDate.length === 2 && moment.isMoment(this.state.invoiceDate[0])) {
      let invoiceEditDateEnd;
      invoiceEditDateEnd = this.state.invoiceDate[0].clone();
      invoiceEditDateEnd.add(1, 'months').date(10);
      let now = moment();
      if (now > invoiceEditDateEnd) {
        invoiceAllowEdit = false;
      }
    }
    
    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide} enforceFocus={false}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>{this.props.action==='edit'?this.props.invoiceNo:"Add Invoice"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          {invoiceAllowEdit === false && (
            <Row>
              <Col md={12}>
                <div className="alert alert-warning" style={{ height: "60px" }}>
                  <span style={{ float: "left", paddingTop: "5px" }}><i className="fa fa-exclamation-triangle">&nbsp;</i> This document is locked for editing (after the 10th day of next invoice issue month).</span>
                </div>
              </Col>
            </Row>
          )
          }
          {this.state.credit_invoice_id && (
            <Row>
              <Col md={12}>
                <div className="alert alert-warning" style={{ height: "60px" }}>
                  <span style={{ float: "left", paddingTop: "5px" }}><i className="fa fa-exclamation-triangle">&nbsp;</i> This document is locked for editing (A credit note has been created for this invoice).</span>
                </div>
              </Col>
            </Row>
          )
          }
          {this.state.invoice_type_id && this.state.invoice_type_id.startsWith('CN-') && (
            <Row>
              <Col md={12}>
                <div className="alert alert-warning" style={{ height: "60px" }}>
                  <span style={{ float: "left", paddingTop: "5px" }}><i className="fa fa-exclamation-triangle">&nbsp;</i> This document is locked for editing (Credit Note edits are not allowed).</span>
                </div>
              </Col>
            </Row>
          )
          }
          {this.state.unlocked === false && this.state.exported === true && (
            <Row>
              <Col md={12}>
                <div className="alert alert-warning" style={{height:"60px"}}>
                  <span style={{float:"left",paddingTop:"5px"}}><i className="fa fa-lock">&nbsp;</i> This document is locked for editing because it has been exported.</span>
                  <Button
                    bsStyle="primary"
                    style={{float:"right"}}
                    onClick={() => this.unlockInvoice()}
                  >
                    Unlock
                  </Button>
                </div>
              </Col>
            </Row>
          )
          }
          {this.state.invoiceIsEditable === true && totalAmountChanged === true && this.state.showAdd === false && (
            <Row>
              <Col md={12}>
                <div className="alert alert-danger" style={{ height: "60px" }}>
                  <span style={{ float: "left", paddingTop: "5px" }}>Invoice total amount changed! (Original total amount: {this.calculateServicesTotalOrig("dollar")}$ - {this.calculateServicesTotalOrig("euro")}€)</span>
                </div>
              </Col>
            </Row>
          )
          }
          <Row><Col md={12}><p>Please fill out the invoice details.</p></Col></Row>
          <Box.Body>
            {(
              this.state.action==='add' && this.state.issuerId && this.state.recipientId &&
              ((this.state.settings &&
              (_.find(this.state.settings, { 'issuer_id': this.state.issuerId })) &&
                (_.find(this.state.settings, { 'issuer_id': this.state.issuerId }).account_type === "1")) ||
                (this.state.settings && this.state.settings.length === 0)
                ||
              !this.state.settings)
            ) && (
              <Row>
                <Col md={12}>
                  <div className="alert alert-warning" style={{ height: "60px" }}>
                    <span style={{ float: "left", paddingTop: "5px" }}><i className="fa fa-exclamation-triangle">&nbsp;</i> The selected issuer/customer invoicing is done through Accounting Operations.</span>
                  </div>
                </Col>
              </Row>
            )
            }
            <Row>
              <Col md={7}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel style={{width: "100%"}}>
                        <span>Issuer</span>
                        <span className={!this.state.invoiceIsEditable?"hidden":""} style={{float:"right"}}>
                          <a href="/tools/invoiceissuers" target="_blank">
                            Add New Issuer <i className="fa fa-external-link"></i>
                          </a>
                        </span>
                      </ControlLabel>
                      {((this.state.action==='edit'&&!this.state.busy&&this.state.issuerId!==null&&this.state.issuerTitle!==null) || this.state.issuerIdUnselected===true || this.state.action==='add') && (
                        <InvoiceIssuerPicker
                          id={"issuerId_"+this.state.issuerId}
                          ref={this.issuerRef}
                          className="form-control"
                          placeholder="Enter name"
                          disabled={!this.state.invoiceIsEditable}
                          selectedIdValue={this.state.action==='edit'?this.state.issuerId:null}
                          selectedTextValue={this.state.action==='edit'?this.state.issuerTitle:null}
                          onChange={value => this.setState(
                            {issuerId: value},
                            () => {
                              if(this.state.issuerId===null){
                                this.setState(
                                  {
                                    accountCategoryName: null,
                                    accountCode: null,
                                    taxRate: null,
                                  });
                              }else{

                                this.getInvoiceTypes();
                              }
                            })
                          }
                          onUnselect={value => this.setState({issuerIdUnselected: true}) }
                        />
                      )}                      
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel style={{width: "100%"}}>
                        <span>Recipient</span>
                        <span className={!this.state.invoiceIsEditable?"hidden":""} style={{float:"right"}}>
                          <a href="/tools/invoicerecipients" target="_blank">
                            Add New Recipient <i className="fa fa-external-link"></i>
                          </a>
                        </span>
                      </ControlLabel>
                      {((this.state.action==='edit'&&!this.state.busy&&this.state.recipientId!==null&&this.state.recipientTitle!==null) || this.state.recipientIdUnselected===true || this.state.action==='add') && (
                        <InvoiceRecipientPicker
                          ref={this.recipientRef}
                          className="form-control"
                          placeholder="Enter id or name"
                          disabled={!this.state.invoiceIsEditable}
                          selectedIdValue={this.state.action==='edit'?this.state.recipientId:null}
                          selectedTextValue={this.state.action==='edit'?this.state.recipientTitle:null}
                          onChange={(value, title, accountCategoryId, currency, settings) => {
                            var newState = {};
                            if (this.state.action !== 'edit') {
                              var currencySymbol = "";
                              if (currency != null) {
                                switch (currency) {
                                  case "USD":
                                    currencySymbol = "$";
                                    break;
                                  case "SGD":
                                    currencySymbol = "S$";
                                    break;
                                  case "GBP":
                                    currencySymbol = "£";
                                    break;
                                  default:
                                    currencySymbol = "$";
                                    break;
                                }
                              }

                              newState = {
                                recipientId: value,
                                settings: settings,
                                currency: currency,
                                currency_symbol: currencySymbol
                              }
                            }
                            else {
                              newState = {
                                recipientId: value,
                                settings: settings
                              }
                            }

                            if(accountCategoryId!==undefined&&this.state.action==='edit'&&this.state.recipientIdUnselected!==true){
                              newState.accountCategoryId = accountCategoryId;
                            }
                            this.setState(
                              newState,
                              () => {
                                if (this.state.recipientId === null) {
                                  this.setState(
                                    {
                                      accountCategoryName: null,
                                      accountCode: null,
                                      taxRate: null,
                                    });
                                } else {
                                  this.getRecipientCategory();
                                }
                              })
                            }
                          }
                          onUnselect={value => this.setState({recipientIdUnselected: true}) }
                        />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Account Manager</ControlLabel>
                      {((this.state.action==='edit'&&!this.state.busy&&this.state.accountManagerId!==null&&this.state.accountManagerTitle!==null) || this.state.accountManagerIdUnselected===true || this.state.action==='add') && (
                        <AccountManagerPicker
                          ref={this.accountManagerRef}
                          className="form-control"
                          placeholder="Enter id or name"
                          disabled={!this.state.invoiceIsEditable}
                          selectedIdValue={this.state.action==='edit'?this.state.accountManagerId:null}
                          selectedTextValue={this.state.action==='edit'?this.state.accountManagerTitle:null}
                          onChange={value => this.setState({ accountManagerId: value })}
                          onUnselect={value => this.setState({accountManagerIdUnselected: true}) }
                        />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <label>Invoice Date</label>
                      {((this.state.action==='edit'&&invoiceDateStart!==null&&invoiceDateEnd!==null) || this.state.action==='add') && (
                        <DateRangePicker
                          className="form-control"
                          opens="right"
                          disabled={!this.state.invoiceIsEditable}
                          onChange={(value) => this.selectDate(value)}
                          minDate={moment().startOf("day")}
                          maxDate={moment().endOf("day")}
                          startDate={this.state.action === 'edit' ? invoiceDateStart : moment().startOf("day")}
                          endDate={this.state.action === 'edit' ? invoiceDateEnd : moment().endOf("day")}
                          singleDatePicker={true}
                          disabled={true}
                        />
                      )}
                    </FormGroup>
                    
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Invoice Period</ControlLabel>
                      <DatePicker
                        className="form-control date-picker-input"
                        wrapperClassName="datePicker"
                        selected={this.state.invoicePeriod}
                        onChange={(value) => {
                          this.setState({ invoicePeriod: value })
                        }
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        disabled={!this.state.invoiceIsEditable}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>  
              <Col md={5}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <label>Invoice Type</label>
                      <select
                        className="form-control"
                        value={this.state.invoiceTypeId||""}
                        disabled={!this.state.invoiceIsEditable}
                        onChange={e =>
                          this.setState({ invoiceTypeId: e.target.value })
                        }
                      >
                        <option key="key_selectType" value="">Select type..</option>
                        {
                          (this.state.invoiceTypes || []).map((invoiceType) => {
                            return <option key={"key_"+invoiceType.invoice_type_id} value={invoiceType.invoice_type_id}>{invoiceType.invoice_type_id}</option>
                          })
                        }
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"hidden"}>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Account Category</ControlLabel>
                      <FormControl
                        componentClass="input"
                        type="string"
                        maxLength={20}
                        disabled={true}
                        onChange={e =>
                          this.setState({ accountCategoryName: e.target.value })
                        }
                        defaultValue={this.state.accountCategoryName}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Account Code</ControlLabel>
                      <FormControl
                        componentClass="input"
                        type="string"
                        maxLength={20}
                        disabled={true}
                        onChange={e =>
                          this.setState({ accountCode: e.target.value })
                        }
                        defaultValue={this.state.accountCode}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"hidden"}>
                  <Col md={6}>
                      <ToggleButton
                        id="toggle-check"
                        type="checkbox"
                        variant="secondary"
                        style={{background:"none",border:"none",boxShadow:"none",paddingLeft:"0"}}
                        checked={this.state.interCompany}
                        value="1"
                        disabled={true}
                        onChange={(e) => {
                          this.setState({ interCompany: e.currentTarget.checked });
                        }}
                      >
                        <span style={{float:"right"}}>&nbsp;Inter-company</span>
                      </ToggleButton>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Notes</ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        value={this.state.notes||""}
                        disabled={!this.state.invoiceIsEditable}
                        onChange={(e) => {
                          this.setState({ notes: e.target.value });
                        }}
                        rows={3}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <ControlLabel>Invoice Text</ControlLabel>
                      <FormControl
                        name="invoiceText"
                        componentClass="input"
                        type="string"
                        maxLength={1000}
                        disabled={!this.state.invoiceIsEditable}
                        onChange={(e) => {
                          this.setState({ invoiceText: e.target.value });

                        }}
                        value={this.state.invoiceText || ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <table className={"table table-sm"}>
                  <thead>
                    <tr>
                      <th style={{width: "50%"}}>Description</th>
                      <th className="text-right" style={{width: "15%"}}>Unit Price</th>
                      <th className="text-right" style={{width: "10%"}}>Qty</th>
                      <th className="text-center"><i className="fa fa-trash"></i></th>
                      <th className="text-right money-cell">Amount $</th>
                      <th className="text-right money-cell">Amount {this.state.currency_symbol !== "$" ? this.state.currency_symbol : "€"}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.invoiceLines||[]).map((invoiceLine, idx, array) => {
                    if(invoiceLine.deleted !== true) {
                      return (<tr key={"invoiceLineRow_key_" + idx}>
                        <td>
                          <FormControl
                            name="description"
                            componentClass="input"
                            type="string"
                            maxLength={1000}
                            disabled={!this.state.invoiceIsEditable}
                            onChange={e => {
                              var state = this.state;
                              var invoiceLines = state.invoiceLines;
                              state.invoiceLines[idx].description = e.target.value;
                              this.setState({invoiceLines: invoiceLines});

                            }}
                            value={invoiceLine.description||""}
                          />
                        </td>
                        <td>
                          <FormControl
                            name="unitPrice"
                            componentClass="input"
                            type="string"
                            maxLength={9}
                            disabled={!this.state.invoiceIsEditable}
                            onChange={e => {
                              var state = this.state;
                              var invoiceLines = state.invoiceLines;
                              if (!isNaN(parseFloat(e.target.value))) {
                                let val = e.target.value.replaceAll(',', '.');
                                if (val && val.length > 0) {
                                  const first = val.indexOf('.');
                                  const valsub = val.substring(first + 1, val.length);
                                  if (valsub.indexOf('.') !== -1) {
                                    val = val.substring(0, first + 1) + valsub.replaceAll('.', '');
                                  }
                                  if (val.indexOf('.') !== -1) {
                                    if (val.substring(val.indexOf('.'), val.length).length > 2) {
                                      val = val.substring(0, val.indexOf('.') + 3);
                                    }
                                  }
                                }
                                
                                state.invoiceLines[idx].unitPrice = val;
                                this.setState({ invoiceLines: invoiceLines });
                              }
                            }}
                            value={invoiceLine.unitPrice}
                          />
                        </td>
                        <td>
                          <FormControl
                            name="quantity"
                            componentClass="input"
                            type="string"
                            maxLength={9}
                            disabled={!this.state.invoiceIsEditable}
                            onChange={e => {
                              var state = this.state;
                              var invoiceLines = state.invoiceLines;
                              state.invoiceLines[idx].quantity = e.target.value;
                              this.setState({invoiceLines: invoiceLines});

                            }}
                            value={invoiceLine.quantity}
                          />
                        </td>
                        <td>
                          {this.state.invoiceIsEditable && (
                            <Button
                              bsStyle="default"
                              style={{background: "transparent", border: "none"}}
                              onClick={this.handleDeleteLine.bind(this, idx)}>
                              <i className="fa fa-trash"></i>
                            </Button>
                          )}
                        </td>
                        <td name="amountDollar" className="text-right">
                          {N((invoiceLine.unitPrice || 0) * (invoiceLine.quantity || 0), 2) + " $"}
                        </td>
                        <td name="amountEuro" className="text-right">
                          {N((this.state.exchangeRate !== null && this.state.exchangeRate > 0 ? ((invoiceLine.unitPrice || 0) * (invoiceLine.quantity || 0)) * this.state.exchangeRate : 0), 2) + (this.state.currency_symbol !== "$" ? " "+this.state.currency_symbol : " €")}
                        </td>
                      </tr>);
                    }else{
                      return(null);
                    }
                  })}
                    
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="6"><a href="#" className={"card-link"+(!this.state.invoiceIsEditable?" hidden":"")} onClick={this.handleAddLine.bind(this)}><i className="fa fa-plus"></i> Add Line</a></td>
                    </tr>
                  </tfoot>
                 
                </table>
              </Col>
            </Row>
            <Row>
              <Col md={2} mdOffset={3}>
                <ControlLabel>Tax Rate (%)</ControlLabel>
                <FormControl
                  componentClass="input"
                  type="string"
                  maxLength={9}
                  disabled={!this.state.invoiceIsEditable}
                  onChange={e =>
                    this.setState({ taxRate: e.target.value })
                  }
                  defaultValue={this.state.taxRate}
                />
              </Col>
              <Col md={2}>
                <ControlLabel>Exchange Rate</ControlLabel>
                <FormControl
                  componentClass="input"
                  type="string"
                  maxLength={9}
                  disabled={!this.state.invoiceIsEditable}
                  onChange={e => 
                    this.setState({ exchangeRate: e.target.value })        
                  }
                  defaultValue={exchangeRateValue}
                />
              </Col>
              <Col md={5}>
                <table className="table table-sm">
                  <tbody>
                  <tr>
                    <td>Services Total</td>
                    <td name="servicesTotalDollar" className="text-right money-cell">{this.calculateServicesTotal("dollar") + " $"}</td>
                      <td name="servicesTotalEuro" className="text-right money-cell">{this.calculateServicesTotal("euro") + (this.state.currency_symbol !== "$" ? " " + this.state.currency_symbol : " €")}</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td name="gstDollar" className="text-right money-cell">{this.calculateGSTtotal("dollar") + " $"}</td>
                      <td name="gstEuro" className="text-right money-cell">{this.calculateGSTtotal("euro") + (this.state.currency_symbol !== "$" ? " " + this.state.currency_symbol : " €")}</td>
                  </tr>
                  <tr>
                    <th>Grand Total</th>
                    <td name="grandTotalDollar" className="text-right money-cell">{this.calculateGrandTotal("dollar") + " $"}</td>
                      <td name="grandTotalEuro" className="text-right money-cell">{this.calculateGrandTotal("euro") + (this.state.currency_symbol !== "$" ? " " + this.state.currency_symbol : " €")}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
            </Row>            
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          
        {this.state.action==='edit'&& !this.state.invoiceIsEditable &&(
          <Button
              disabled={(this.state.unlocked === false && this.state.exported === true) || !invoiceAllowEdit || this.state.credit_invoice_id || (this.state.invoice_type_id && this.state.invoice_type_id.startsWith('CN-'))}
            bsStyle="primary"
            onClick={() => this.setState({invoiceIsEditable:true})}
          >
            Edit Invoice
          </Button>
        )}
        
        {(this.state.action==='add' || (this.state.action==='edit' && this.state.invoiceIsEditable)) && (
          <Button
              disabled={
                (this.state.exchangeRate > 0 && this.calculateGrandTotal("euro") <= 0) ||
                (this.calculateGrandTotal("dollar") <= 0) ||
                (!this.state.recipientId || !this.state.accountManagerId || !this.state.invoiceDate || !this.state.invoicePeriod || !this.state.invoiceTypeId || !this.state.invoiceLines || this.state.invoiceLines.length < 1 || (totalAmountChanged === true && this.state.showAdd === false)) ||
                (this.state.action === 'add' && this.state.issuerId && this.state.recipientId &&
                  ((this.state.settings &&
                  (_.find(this.state.settings, { 'issuer_id': this.state.issuerId })) &&
                  (_.find(this.state.settings, { 'issuer_id': this.state.issuerId }).account_type === "1")) ||
                  (this.state.settings && this.state.settings.length === 0) ||
                  !this.state.settings)
                )
            }
            bsStyle="primary"
            onClick={this.handleSaveInvoice}
          >
            Save
          </Button>
        )}
          
        {this.state.action==='edit'&& this.state.invoiceIsEditable && (
            <Button
              bsStyle="danger"
              onClick={() => this.setState({invoiceIsEditable:false},()=>{this.getInvoice()})}
            >
              Discard Changes
            </Button>
        )}
          
        {(this.state.action==='add' || (this.state.action==='edit'&&!this.state.invoiceIsEditable)) && (
          <Button
            onClick={this.props.onHide}
          >
            Close
          </Button>
        )}

          {(this.state.action === 'edit' && !this.state.exported && !this.state.invoiceIsEditable) && (
            <Button
              onClick={this.exportInvoice}
            >
              Export
            </Button>
          )}

          {/*{(this.state.action==='edit') && (

            <DropdownButton
            id="sendto"
            bsSize="small"
            title={
              <>
                <i className="fa fa-file-pdf"></i> Save PDF
              </>
            }
            >
              <MenuItem key="a" eventKey={1} onClick={this.props?.onPrintPdfModal(this.state.id||null,'USD')}>
                USD
              </MenuItem>
              <MenuItem key="b" eventKey={2} onClick={this.props?.onPrintPdfModal(this.state.id||null, 'EUR')}>
                EUR
              </MenuItem>
              <MenuItem key="c" eventKey={3} onClick={this.props?.onPrintPdfModal(this.state.id||null, 'USD-EUR')}>
                USD & EUR
              </MenuItem>
            </DropdownButton>
          )}*/}
        </Modal.Footer>
      </Modal>
    );
  }

  selectDate(date) {
    this.setState({ invoiceDate: date});
  }
  
  calculateServicesTotal(currency){
    var total = 0.00;
    var exchangeRate = this.state.exchangeRate;
    
    (this.state.invoiceLines || []).forEach((invoiceLine, idx, array) => {
      if (!invoiceLine.deleted) {
        var amount = invoiceLine.unitPrice * invoiceLine.quantity;
        if (currency === "euro") {
          if (exchangeRate !== null && exchangeRate > 0) {
            amount = amount * exchangeRate;
          } else {
            amount = 0;
          }
        }
        total += amount;
      }
    });
    return N(total,2);
  }
  
  calculateGSTtotal(currency){
    var total = 0.00;
    var taxRate = this.state.taxRate || 0.0000;
    var servicesTotal = this.calculateServicesTotal(currency);
    total = servicesTotal * (taxRate/100);
    
    return N(total,2);
  }

  calculateServicesTotalOrig(currency) {
    var total = 0.00;
    var exchangeRate = this.state.exchangeRateOrig;

    (this.state.invoiceLinesOrig || []).forEach((invoiceLine, idx, array) => {
      if (!invoiceLine.deleted) {
        var amount = invoiceLine.unitPrice * invoiceLine.quantity;
        if (currency === "euro") {
          if (exchangeRate !== null && exchangeRate > 0) {
            amount = amount * exchangeRate;
          } else {
            amount = 0;
          }
        }
        total += amount;
      }
    });
    return N(total, 2);
  }

  calculateGrandTotal(currency){
    var total = 0.00;
    var servicesTotal = this.calculateServicesTotal(currency);
    var GSTtotal = this.calculateGSTtotal(currency);
    total = parseFloat(servicesTotal) + parseFloat(GSTtotal);

    return N(total,2);
  }

  calculateGrandTotalOrig(currency) {
    var total = 0.00;
    var servicesTotal = this.calculateServicesTotalOrig(currency);
    //var GSTtotal = this.calculateGSTtotal(currency);
    total = parseFloat(servicesTotal);// + parseFloat(GSTtotal);

    return N(total, 2);
  }
  
  getNewInvoiceLine(){
    return {
      _id: null,
      description: null,
      unitPrice: 0.00,
      quantity: 1,
      deleted: false
    };
  }
  
  handleAddLine(){
    var invoiceLines = this.state.invoiceLines || [];
    var invoiceLine = this.getNewInvoiceLine();
    invoiceLines.push(invoiceLine);
    
    this.setState({invoiceLines: invoiceLines});
  }
  
  handleDeleteLine(idx){
    var invoiceLines = this.state.invoiceLines || [];
    if(invoiceLines.length>0){
      if(this.state.action==="edit"){
        invoiceLines[idx].deleted = true;
      }else{
        invoiceLines.splice(idx, 1);
      }
      
    }
    this.setState({invoiceLines: invoiceLines});
  }
  
  getInvoiceTypes(){
    if(this.state.issuerId !== null)
    {
      axios({
        url: "/api/v1/tools/invoicetypes", 
        method: "get",
        params: {issuerId: this.state.issuerId}
      })
        .then(res => {
          this.setState({
            invoiceTypes: res && res.data ? res.data : null,
            busy: false,
            message: null,
            error: null
          },()=> { this.getRecipientCategory() });
        })
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;
          this.setState({
            busy: false,
            message: null,
            error
          });
        });
    }
  }
  
  getRecipientCategory(){
    
    var postData = {
      IssuerId: this.state.issuerId||null,
      AccountCategoryId: this.state.accountCategoryId||null,
    };
    
    if(postData.IssuerId !== null && postData.AccountCategoryId !== null)
    {
      axios({
        url: "/api/v1/tools/recipientcategory",
        method: "post",
        data: postData
      })
        .then(res => {
          var resData = res && res.data ? res.data : null;
          this.setState({
            accountCategoryId: resData && resData.hasOwnProperty("account_category_id")?resData.account_category_id:null,
            accountCode: resData && resData.hasOwnProperty("account_code")?resData.account_code:null,
            accountCategoryName: resData && resData.hasOwnProperty("name")?resData.name:null,
            taxRate: resData && resData.hasOwnProperty("tax_rate")?resData.tax_rate:null,
            busy: false,
            message: null,
            error: null
          });
        })
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;
          this.setState({
            busy: false,
            message: null,
            error
          });
        });
    }
    
  }
  
  handleSaveInvoice() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    var invoicePeriod = this.state.invoicePeriod || null;
    if (invoicePeriod) {
      invoicePeriod = (invoicePeriod.getMonth() + 1) + '/' + invoicePeriod.getFullYear();
    }
    var invoicePeriodFrom = null;
    var invoicePeriodTo = null;
    
    if(invoicePeriod!==null && (invoicePeriod.length===7 || invoicePeriod.length===6)  && invoicePeriod.indexOf("/")>-1){
      var month = invoicePeriod.split('/')[0];
      var year = invoicePeriod.split('/')[1];
      
      if(month.length>0 && month.length<=2 && year.length===4){
        if(month.length===1&&parseInt(month)<10){
          month='0'+month;
        }
        var startOfMonth = year+'-'+month+'-01';
        var endOfMonth = moment(year+'-'+month+'-01').endOf('month').format('YYYY-MM-DD');

        invoicePeriodFrom = startOfMonth;
        invoicePeriodTo = endOfMonth;
      }
      
    }
    
    var invoiceLines = this.state.invoiceLines?this.state.invoiceLines:null;
    (invoiceLines || []).forEach(invoiceLine=>{
      invoiceLine.issuerId = this.state.issuerId;
    });
    
    var invoiceDate_ = this.state.invoiceDate;
    if(this.state.invoiceDate.length===2){ //moment.isMoment(invoiceDate_)
      invoiceDate_ = this.state.invoiceDate[0];
    }
    var invoiceDate = invoiceDate_? moment.utc(invoiceDate_).format("YYYY-MM-DD") : null;
    
    var url = "/api/v1/tools/addinvoice";
    var postData = {
      Id: null,
      IssuerId: this.state.issuerId?this.state.issuerId:null,
      RecipientId: this.state.recipientId?this.state.recipientId:null,
      AccountManagerId: this.state.accountManagerId!==null?parseInt(this.state.accountManagerId):null,
      InvoiceDate: invoiceDate,
      InvoicePeriodFrom: invoicePeriodFrom,
      InvoicePeriodTo: invoicePeriodTo,
      InvoiceTypeId: this.state.invoiceTypeId?this.state.invoiceTypeId:null,
      AccountCategoryId: this.state.accountCategoryId!==null&&this.state.accountCategoryId!==undefined?parseInt(this.state.accountCategoryId):null,
      AccountCode: this.state.accountCode?this.state.accountCode:null,
      InterCompany: this.state.interCompany===true?true:false,
      Notes: this.state.notes && this.state.notes.length > 0 ? this.state.notes.trim() : null,
      InvoiceText: this.state.invoiceText && this.state.invoiceText.length > 0 ? this.state.invoiceText.trim() : null,
      ExchangeRate: this.state.exchangeRate?this.state.exchangeRate:0,
      TaxRate: this.state.taxRate?this.state.taxRate:0,
      InvoiceLines: invoiceLines,
    };
    
    if(this.state.action==='edit'){
      postData.Id = this.state.id;
      url = "/api/v1/tools/editinvoice";
    }
    //Action = add
    else{
      (invoiceLines || []).forEach((invoiceLine,idx)=>{
        if(invoiceLine.deleted===true){
          delete invoiceLines[idx];
        }
      });
    }
    
    axios({
      url: url,
      method: "post",
      data: postData
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  getInvoice() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/getinvoice",
      method: "get",
      params: {id:this.state.id||null}
    })
      .then(res => {
        var resData = res && res.data ? res.data : null;
        
        var invoiceLines = (resData.invoice_lines||[]).map((line,idx)=>{
          line.unitPrice = line.unit_price;
          delete line.unit_price;
          return line;
        });

        this.setState({
          id: resData._id || null,
          issuerId: resData.issuer_id||null,
          issuerTitle: resData.issuer_title||null,
          recipientId: resData.recipient_id||null,
          recipientTitle: resData.recipient_title||null,
          accountManagerId: resData.account_manager_id!==null?parseInt(resData.account_manager_id):null,
          accountManagerTitle: resData.account_manager_title||null,
          invoiceDate: resData.invoice_date||null,
          invoicePeriod:  resData.invoice_period_from?new Date(resData.invoice_period_from):null,
         // invoicePeriodFrom: resData.invoice_period_from||null,
         // invoicePeriodTo: resData.invoice_period_to||null,
          invoiceTypeId: resData.invoice_type_id||null,
          accountCategoryId: resData.account_category_id!==null?parseInt(resData.account_category_id):null,
          accountCode: resData.account_code||null,
          interCompany: resData.inter_company===true?true:false,
          notes: resData.notes || null,
          invoiceText: resData.invoice_text || null,
          exchangeRate: resData.exchange_rate ? resData.exchange_rate : 0,
          exchangeRateOrig: resData.exchange_rate ? JSON.parse(JSON.stringify(resData.exchange_rate)) : 0,
          taxRate: resData.tax_rate?resData.tax_rate:0,
          unlocked: resData.unlocked===true?true:false,
          exported: resData.exported===true?true:false,
          invoiceLines: invoiceLines || [],
          invoiceLinesOrig: JSON.parse(JSON.stringify(invoiceLines)) || [],
          credit_invoice_id: resData.credit_invoice_id,
          invoice_type_id: resData.invoice_type_id,
          currency: resData.currency,
          currency_symbol: resData.currency_symbol,
          busy: false,
          message: null,
          error: null
        },
          () => {
          this.getInvoiceTypes();
        });
        
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  unlockInvoice(){
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/unlockinvoice",
      method: "get",
      params: {id:this.state.id||null}
    })
      .then(res => {
        var resData = res && res.data ? res.data : null;

        this.setState({
            unlocked: resData && resData.unlocked===true?true:false,
            exported: resData && resData.exported===true?true:false,
            busy: false,
            message: null,
            error: null
          });
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  exportInvoice(){
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/exportinvoice",
      method: "get",
      params: {id:this.state.id||null}
    })
      .then(res => {
        var resData = res && res.data ? res.data : null;

        this.setState({
          unlocked: resData && resData.unlocked===true?true:false,
          exported: resData && resData.exported===true?true:false,
          invoiceIsEditable:resData && resData.unlocked===true?true:false,
          busy: false,
          message: null,
          error: null
        });
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
