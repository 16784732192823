import React from "react";
import { Button } from "react-bootstrap";

export default class PreviewButton extends Button {
  render() {
    return (
      <Button {...this.props}>
        <i className="fa fa-table"></i> Preview
      </Button>
    );
  }
}
