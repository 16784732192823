import React from "react";
//import Highcharts from "highcharts";
import moment from "moment-timezone";
import { Row, Col } from "react-bootstrap";
var Highcharts = require('highcharts/highstock');

moment.tz.setDefault("UTC");

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.createChart = this.createChart.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      this.createChart();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data || this.props.id !== prevProps.id) {
      this.createChart();
    }
  }
  /*componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data || nextProps.id !== this.props.id) {
      this.createChart();
    }
  }*/
  render() {
    return (
      <Row>
        <Col md={12}>
          <div height="200" style={{ width: "100%" }} ref="container"></div>
        </Col>
      </Row>
    );
  }

  createChart() {
    const { data, reportMetric, reportMetricText, addDaysBack } = this.props;

    if (data && reportMetric && data.length > 0 && data[data.length - 1] && data[data.length - 1].modified) {

      const now = moment(data[data.length - 1].modified).utc();

        var dataFormatted = [];
        data.forEach(function (dataItem) {
          const b = moment(dataItem.modified);
          if (b.isSame(now, 'd')) {
            dataFormatted.push({
              record: dataItem,
              x: moment(dataItem.modified).valueOf(),
              y: dataItem[reportMetric]
            })
          }
        });

        var dataFormatted_yesterday = [];
        data.forEach(function (dataItem) {
          const b = moment(dataItem.modified);
          if (!b.isSame(now, 'd')) {
            const diffDays = now.endOf('day').diff(b, 'd');
            dataFormatted_yesterday.push({
              record: dataItem,
              x: moment(dataItem.modified).add(diffDays, 'd').valueOf(),
              y: dataItem[reportMetric]
            })
          }
        });
           

    }
    
    var decimals = 0;
    if( reportMetric==="qps"||reportMetric==="rtbCost"||reportMetric==="revenueExchange"||reportMetric==="revenue"||
      reportMetric==="latencyResponse"||reportMetric==="latencyThrottled"||reportMetric==="latencyNobid"||
      reportMetric==="responsesPercentage"||reportMetric==="timeoutPercentage"||reportMetric==="throttlePercentage"||
      reportMetric==="nobidPercentage"||reportMetric==="winsResponsesPercentage")
    {
      decimals = 2;
    }

    if(reportMetric==="eCPMwins"||reportMetric==="eCPMresponses"||reportMetric==="eCPMrequests"||
      reportMetric === "cpMwins" || reportMetric === "cpMresponses" || reportMetric === "cpMrequests" || reportMetric === "avgBidPrice"
      || reportMetric === "avgExchangeBidPrice" || reportMetric === "avgBidRequestsFloorPrice" )
    {
      decimals = 4;
    }



    return Highcharts.StockChart(this.refs.container, {
      credits: { enabled: false },

      title: { text: "" },

      zoomType: "x",

      rangeSelector: {
        enabled: false
      },

      subtitle: {
        text: ""
      },

      tooltip: {
        split: true,
        dateTimeLabelFormats: {
          millisecond: "%A, %b %e, %H:%M"
        },
        enabled: true,

        formatter: function () {
          if (this.key > 0) {
            let dt = (reportMetric === 'bidfloor') ?
              (('0' + (new Date(this.x)).getUTCHours()).slice(-2) + ':' + ('0' + (new Date(this.x)).getUTCMinutes()).slice(-2) + ':' + ('0' + (new Date(this.x)).getUTCSeconds()).slice(-2) + '.' + ('0' + (new Date(this.x)).getUTCMilliseconds()).slice(-3)) :
              (('0' + (new Date(this.x)).getUTCHours()).slice(-2) + ':' + ('0' + (new Date(this.x)).getUTCMinutes()).slice(-2))
            return ['<b>' + dt ].concat(
              this.points ?
                this.points.map(function (point) {
                  if (point.point.index > 0) {
                    let doMath = Highcharts.correctFloat(((point.series.points[point.point.index].y - point.series.points[point.point.index - 1].y) / point.series.points[point.point.index - 1].y) * 100, 5);
                    if (isNaN(doMath) || point.series.points[point.point.index - 1].y === 0) {
                      doMath = 0;
                    }
                    let diff = point.series.points[point.point.index].y - point.series.points[point.point.index - 1].y;
                    let rt_text = '';
                    if (point.point.record.bidfloor) {
                      if (point.point.record.response) {
                        rt_text = rt_text + ' RESPONSE '
                      }
                      if (!point.point.record.response) {
                        rt_text = rt_text + ' NO_RESPONSE '
                      }
                      if (point.point.record.creativeType) {
                        rt_text = rt_text + ' ' + point.point.record.creativeType.toUpperCase() + ' '
                      }
                      if (point.point.record.deviceType && point.point.record.deviceType === 4) {
                        rt_text = rt_text + ' Phone '
                      }
                      if (point.point.record.deviceType && point.point.record.deviceType === 5) {
                        rt_text = rt_text + ' Tablet '
                      }
                      if (point.point.record.rewarded) {
                        rt_text = rt_text + ' Rewarded '
                      }
                      if (point.point.record.interstitial) {
                        rt_text = rt_text + ' Interstitial '
                      }
                      return 'bidfloor: <b>' + Math.round(point.y * 10000) / 10000 + ' / ' + Math.round((diff) * 10000) / 10000 + ' </b> <b>(' + Math.round(doMath * 10000) / 10000 + '%)</b>' + (rt_text ? (' <b>' + rt_text + '</b>') : '');
                    }
                    return point.series.name + ': <b>' + Math.round(point.y * 10000) / 10000 + ' / ' + Math.round((diff) * 10000) / 10000 + ' </b> <b>(' + Math.round(doMath * 10000) / 10000 + '%)</b>' + (rt_text?(' <b>'+rt_text+'</b>'):'');
                  }
                  else {
                    return false;
                  }
                }) : []
            )
            
          } else {
            //return a.getUTCHours() + ':' + ('0' + a.getUTCMinutes()).slice(-2) + ' - ' + point.series.name + ': <b>' + Math.round(point.y * 10000) / 10000 + ' - ' + Math.round((diff))*10000/10000 +'</b>';
          }
        },
        style: {
          fontSize: '12px'
        }
      },

      xAxis: [
        {
          title: {
            text: "Modified at (UTC)",
            style: {
              fontSize: '12px'
            }
          },
          type: "datetime",
          
          labels: {
            style: {
              fontSize: '12px'
            }
          }
          //softMax: moment().valueOf()
        }
      ],

      yAxis: [
        {
          title: {
            text: reportMetricText,
            style: {
              fontSize: '12px'
            }
          },
          allowDecimals: true,
          opposite: false,
          labels: {
            style: {
              fontSize: '14px'
            }
          }
        }
      ],

      series: [
        {
          //id:'qps',
          name: reportMetricText,
          type: "line",
          color: "#3c8dbc",
          tooltip: {
            valueDecimals: decimals
          },
          data: dataFormatted,
          /* Group data by x mins */
          dataGrouping: {
            enabled: true,
            forced: true,
            units: [
              [(reportMetric === "bidfloor") ? 'millisecond' :'minute', [1]]
            ]
          },
          turboThreshold: (reportMetric === "bidfloor") ?8640:2880,
          zIndex: 1
        },
        {
      //id:'qps',
      name: reportMetricText,
      type: "line",
      color: "#dcedf7",
      tooltip: {
        valueDecimals: decimals
      },
      data: dataFormatted_yesterday,
      /* Group data by x mins */
      dataGrouping: {
        enabled: true,
        forced: true,
        units: [
          ['minute', [1]]
        ]
      },
          turboThreshold: 2880,
          zIndex: 0
    }]
    });
    
    /*return Highcharts.chart(this.refs.container, {
      credits: { enabled: false },

      title: { text: "" },

      zoomType: "x",

      subtitle: {
        text: ""
      },

      tooltip: {
        //shared: true,
        dateTimeLabelFormats: {
          millisecond: "%A, %b %e, %H:%M"
        }
      },

      xAxis: [
        {
          title: { text: "Modified at (UTC)" },
          type: "datetime",
          //softMax: moment().valueOf()
        }
      ],

      yAxis: [
        {
          title: { text: reportMetricText },
          allowDecimals: true
        }
      ],
      series: [
        {
          name: reportMetricText,
          type: "line",
          color: "#3c8dbc",
          tooltip: {
            valueDecimals: decimals
          },
          data: dataFormatted,
          /!*data: data.map(x => ({
            record: x,
            x: moment(x.modified).valueOf(),
            y: x.qps
          })),*!/
          turboThreshold:1440
        }
      ]
    });*/
  }
}
