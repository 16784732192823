import React from "react";
import { isNumber } from "util";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { ButtonGroup } from "react-bootstrap";

function N(value, decimals) {
  return isNumber(value) ? value.toFixed(decimals) : value;
}

// eslint-disable-next-line
function renderCR(d, t, r, m) {
  if (t === "display") {
    if (d === 0) return "-";
    if (d < 0) return `<span class="text-danger">${N(d, 2)}</span>`;
    return N(d, 2);
  }
  return d;
}

function renderRemainingCap(d, t, r, m) {
  if (t === "display") {
    if (d === 0) return "-";
    return N(d, 0);
  } else if (t === "filter") {
    return `${d > 0}`;
  }
  return d;
}

function renderDirectOffer(d, t, r, m) {
  if (t === "display") {
    return d === true ? "<span title='This offer is Direct'>D</span>" : "";
  } else if (t === "filter") {
    return `${d === true}`;
  }
  return d;
}

export default class ConvertingOffers extends React.Component {
  static title = "Converting Offers";

  tableRef = React.createRef();

  render() {
    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={ConvertingOffers.title}
        description="List of offers that have received at least one conversion since 00:00 UTC today."
      >
        <Box>
          <Box.Header>
            <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
              <ButtonGroup>
                <DataTable.ColumnFilter
                  tableRef={this.tableRef}
                  columnName="targetPlatform"
                  bsSize="small"
                  title="Platform"
                  defaultValue=""
                  values={[
                    ["*", ""],
                    ["Android", "Android"],
                    ["iOS", "iOS"]
                  ]}
                />
                <DataTable.ColumnFilter
                  tableRef={this.tableRef}
                  columnName="isMultiEvent"
                  bsSize="small"
                  title="Model"
                  defaultValue=""
                  values={[
                    ["*", ""],
                    ["CPI", "CPI"],
                    ["PIE", "PIE"]
                  ]}
                />
                <DataTable.ColumnFilter
                  tableRef={this.tableRef}
                  columnName="incentivized"
                  bsSize="small"
                  title="Traffic Type"
                  defaultValue=""
                  values={[
                    ["*", ""],
                    ["Both", 0],
                    ["Incent", 1],
                    ["Non-incent", 2],
                    ["Uncertain", 3]
                  ]}
                />
                <DataTable.ColumnFilter
                  tableRef={this.tableRef}
                  columnName="isDirectOffer"
                  bsSize="small"
                  title="Direct"
                  defaultValue=""
                  values={[
                    ["*", ""],
                    ["Direct Only", "true"],
                    ["Not Direct", "false"]
                  ]}
                />
                <DataTable.ColumnFilter
                  tableRef={this.tableRef}
                  columnName="isTargeted"
                  bsSize="small"
                  title="Targeting"
                  defaultValue=""
                  values={[
                    ["*", ""],
                    ["Open", "Open"],
                    ["Targeted", "Targeted"]
                  ]}
                />
                <DataTable.ColumnFilter
                  tableRef={this.tableRef}
                  columnName="remainingDailyCap"
                  bsSize="small"
                  title="Cap"
                  defaultValue=""
                  values={[
                    ["*", ""],
                    ["Non-zero", "true"],
                    ["Zero", "false"]
                  ]}
                />
              </ButtonGroup>
            </DataTable.Toolbar>
          </Box.Header>
          <Box.Body>
            <DataTable
              className="table table-condensed table-striped table-hover"
              ref={this.tableRef}
              ajax={{
                url: "/api/v1/reports/convertingoffers",
                dataSrc: ""
              }}
              columns={[
                {
                  title: `<i title="Platform" class="fa fa-info-circle fa-1_4x grey"></i>`,
                  data: "targetPlatform",
                  name: "targetPlatform",
                  render: DataTable.StockRenderers.platform()
                },
                {
                  title: `<i title="Store" class="fa fa-info-circle fa-1_4x grey"></i>`,
                  data: "storeId",
                  render: DataTable.StockRenderers.store()
                },
                {
                  title: "Offer ID",
                  data: "offerId"
                },
                {
                  title: "Offer Name",
                  data: "name",
                  className: "truncate-cell-wide",
                  render: DataTable.StockRenderers.textWithTooltip()
                },
                {
                  title: "Model",
                  data: "isMultiEvent",
                  name: "isMultiEvent",
                  className: "text-center",
                  render: (d, t, r, m) => (d ? "PIE" : "CPI")
                },
                {
                  title: "Payout",
                  data: "payout",
                  className: "text-right",
                  render: (d, t, r, m) => N(d, 2)
                },
                {
                  title: "Opt%",
                  data: "opt",
                  className: "text-right",
                  render: (d, t, r, m) => N(d, 0)
                },
                {
                  title: "Countries",
                  data: "geos",
                  className: "truncate-cell",
                  render: DataTable.StockRenderers.textWithTooltip()
                },
                {
                  title: "Advertiser",
                  data: "networkName",
                  className: "truncate-cell",
                  render: DataTable.StockRenderers.textWithTooltip()
                },
                {
                  title: `<i title="Traffic Type" class="fa fa-info-circle fa-1_4x grey"></i>`,
                  data: "incentivized",
                  name: "incentivized",
                  render: DataTable.StockRenderers.trafficType()
                },
                {
                  title: `<i title="Direct offer" class="fa fa-info-circle fa-1_4x grey"></i>`,
                  data: "isDirectOffer",
                  name: "isDirectOffer",
                  render: renderDirectOffer
                },
                {
                  title: "Targeting",
                  data: "isTargeted",
                  name: "isTargeted",
                  className: "text-center",
                  render: (d, t, r, m) => (d ? "Targeted" : "Open")
                },
                {
                  title: "Remaining Cap",
                  data: "remainingDailyCap",
                  name: "remainingDailyCap",
                  className: "text-right",
                  render: renderRemainingCap
                },
                { title: "Clicks", data: "clicks", className: "text-right" },
                {
                  title: `<span title="Total conversions since 00:00 UTC today">Installs</span>`,
                  data: "conversions",
                  className: "text-right",
                  order: "desc"
                },
                {
                  title: "Installs Opt",
                  data: "conversionsOpt",
                  className: "text-right"
                },
                {
                  title: `<span title="Conversions during the 1st past hour">-1h</span>`,
                  data: "conversions1h",
                  className: "text-right"
                },
                {
                  title: `<span title="Conversions during the 2nd past hour">-2h</span>`,
                  data: "conversions2h",
                  className: "text-right"
                },
                {
                  title: `<span title="Conversions during the 3rd past hour">-3h</span>`,
                  data: "conversions3h",
                  className: "text-right"
                },
                {
                  title: `<span title="Conversions during the 4th past hour">-4h</span>`,
                  data: "conversions4h",
                  className: "text-right"
                },
                {
                  title: `<span title="Conversions during the 5th past hour">-5h</span>`,
                  data: "conversions5h",
                  className: "text-right"
                },
                {
                  title: `<span title="Total conversions of the past 5 hours">&#931;5h</span>`,
                  data: "cumulativeConversions5h",
                  className: "text-right"
                },
                {
                  title: "Status",
                  data: "moderationStatus",
                  className: "text-center",
                  render: DataTable.StockRenderers.moderationStatus()
                }
              ]}
              order={[[14, "desc"]]}
              deferRender={true}
            />
          </Box.Body>
        </Box>
      </PageLayout>
    );
  }
}
