import React from "react";
import ReactDOMServer from "react-dom/server";
import { Box } from "components/admin-lte";
import DataTable from "components/DataTable";
import PageLayout from "components/PageLayout";
import AddDialog from "./AddDialog";
import DisableDialog from "./DisableDialog";
import {ButtonGroup, Button, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import axios from "axios";
import { UserContext } from "lib/user";
var _ = require('lodash');

function renderEmail(d, t, r, m) {
  if (t === "display") {
    if (Array.isArray(r.emailTo) && r.emailTo.length > 0) {
      let hiddenRecipients = r.emailTo.length - 1;
      let tooltip =
        "To:\n" +
        r.emailTo.join("\n") +
        "\n\n" +
        "Cc:\n" +
        r.emailCc.join("\n");
      return ReactDOMServer.renderToStaticMarkup(
        <div>
          {r.emailEnabled ? (
            <i
              className="fa fa-toggle-on text-primary"
              style={{ marginRight: 5 }}
            ></i>
          ) : (
            <i
              className="fa fa-toggle-off text-muted"
              style={{ marginRight: 5 }}
            ></i>
          )}
          <span title={tooltip}>{r.emailTo[0]}</span>
          {hiddenRecipients > 0 && (
            <span className="text-muted" style={{ marginLeft: 5 }}>
              (+{hiddenRecipients})
            </span>
          )}
        </div>
      );
    } else {
      return "";
    }
  }
  return d;
}

function renderEvenPacing(d, t, r, m) {
    if (r.pacing && r.pacing.length===24 && r.maxDailyClicks) {
      var pacingClicks = Math.floor(parseInt(r.maxDailyClicks)/24);
      var noEvenPacing = _.find(r.pacing, function(p) { return p.impressionCap !== pacingClicks; });
      if(!noEvenPacing){      
        return "Yes"
      } else {
        return "No"
      }
      
    } else {
      return "No";
    }
}


export default class AutoPause extends React.Component {
  static title = "Auto-Pause";

  tableRef = React.createRef();
  tableRef_ = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      resultset: null,
      loading: true,
      showAdd: false,
      showDisable: false,
      showEdit: false,
      showEditDataFor: null,
      showAddDataFor: null,
      tableUrl: "/api/v1/reports/autopause",      
      selectedCampaignState:["Active"],
      selectedModerationStatus:["Approved","PendingModeration"], //Approved=Available
      showNoAutoPauseOffers: false,
    };
  }

  render() {
    const { showEditDataFor } = this.state;
    const { showAddDataFor } = this.state;
    return (
      <>
      <PageLayout
        breadcrumbs={["Tools"]}
        title={AutoPause.title}
        description={
          <>
            <p>
              The rules in this list are evaluated every 2 minutes. During the
              evaluation:
            </p>
            <ul>
              <li>
                Each offer is tested against all the conditions specified in the
                rule.
              </li>
              <li>
                If any of the conditions in the rule are violated, the offer
                will be <em>paused</em>, i.e. its status will be set to Pending.
                If not, it will be <em>resumed</em>, i.e. its status will be set
                to Available.
              </li>
              <li>
                When a pause or resume occurs, a notification email can be sent
                to one or more recipients.
              </li>
            </ul>
            <p>
              NOTE: If a campaign or its respective application is disabled, or
              if the offer is Rejected, then no change will be made to the offer
              status.
            </p>
          </>
        }
      >
        <UserContext.Consumer>
        {user => (
          <Box>
            <Box.Header>
              <ButtonGroup bsSize="small" style={{marginBottom:'20px'}}>
                <Button bsStyle="primary" onClick={this.handleShowNoAutoPauseOffers.bind(this,user)}>
                  {this.state.showNoAutoPauseOffers?"Hide ":"Show "} offers with no auto-pause rules
                </Button>
              </ButtonGroup>
              {
                this.state.showNoAutoPauseOffers && (
                  <DataTable.Toolbar tableRef={this.tableRef_} bsSize="small" />
                )
              }
            </Box.Header>
            {
              this.state.showNoAutoPauseOffers && (
              <Box.Body>
              
                <div style={{marginBottom:'30px'}}>
                  <DataTable
                    ref={this.tableRef_}
                    className="table table-condensed table-striped"
                    ajax={{
                      url: "/api/v1/reports/noautopauseoffers",
                      dataSrc: ""
                    }}
                    onUserCommand={({ commandData }) =>{
                      if (user.tokenHasExpired) {
                        window.location.replace('/login');
                        return;
                      }
                      else {
                        if(commandData && commandData.action==='add'){
                          this.setState({ showAdd: true, showAddDataFor: commandData })
                        }
                      }
                    }}
                    //prettier-ignore
                    columns={[
                      DataTable.StockColumns.textTruncate("id", "Offer ID"),
                      DataTable.StockColumns.textTruncate("name", "Offer name"),
                      DataTable.StockColumns.count("clicks", "Daily clicks"),
                      {
                        title: " ",
                        data: "",
                        width:"200px",
                        orderable: false,
                        defaultContent:"",
                      },
                      DataTable.createUserCommandColumn({
                        title: "",
                        data: null,
                        className: "text-right",
                        orderable: false,
                        defultContent: "",
                        width: "20px",
                        commandName: "",
                        commandData: ({ rowData }) => ({
                          action: 'add',
                          campaign: rowData.id,
                        }),
                        render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> Add auto-pause rule</button></div>"),
                      }),
                    ]}
                    order={[[2, "desc"]]}
                  />
                </div>
              </Box.Body>)
            }
            
            <Box.Header>              
              <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
              <ButtonGroup bsSize="small">
                <Button onClick={this.handleAddClick.bind(this,user)}>
                  Add
                </Button>
              </ButtonGroup>
              <div style={{position:'absolute', top:'10px', left:'90px'}}>
                <ToggleButtonGroup
                  style={{marginRight:"15px"}}
                  bsSize="small"
                  label="Campaign state"
                  type="checkbox"
                  value={this.state.selectedCampaignState}
                  onChange={(e) => {
                    var value = e;
                    if(e && (e.length>2 || e.includes("All"))){
                      var prevState = this.state.selectedCampaignState;
                      if(prevState.includes("All") && !e.includes("All")){
                        value = [];
                      }else{
                        if(prevState.length>e.length){
                          value = e.filter(function(val) { return val !== "All" })
                        }else{
                          value = ["Active","Inactive","Deleted","All"];
                        }                      
                      }
                    }
                    this.setState({ selectedCampaignState: value });
                    this.handleReload("campaignState",value);
                  }}
                >
                  <ToggleButton value={"Active"}>Active</ToggleButton>
                  <ToggleButton value={"Inactive"}>Inactive</ToggleButton>
                  <ToggleButton value={"Deleted"}>Deleted</ToggleButton>
                  <ToggleButton value={"All"}>All</ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                  bsSize="small"
                  label="Moderation status"
                  type="checkbox"
                  value={this.state.selectedModerationStatus}
                  onChange={(e) => {
                    var value = e;
                    if(e && (e.length>2 || e.includes("All"))){
                      var prevState = this.state.selectedModerationStatus;
                      if(prevState.includes("All") && !e.includes("All")){
                        value = [];
                      }else{
                        if(prevState.length>e.length){
                          value = e.filter(function(val) { return val !== "All" })
                        }else{
                          value = ["Approved","PendingModeration","Rejected","All"];
                        }
                      }
                    }
                    this.setState({ selectedModerationStatus: value });
                    this.handleReload("moderationStatus",value);
                  }}
                >
                  <ToggleButton value={"Approved"}>Available</ToggleButton>
                  <ToggleButton value={"PendingModeration"}>Pending Moderation</ToggleButton>
                  <ToggleButton value={"Rejected"}>Rejected</ToggleButton>
                  <ToggleButton value={"All"}>All</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Box.Header>
            <Box.Body>
              <DataTable
                ref={this.tableRef}
                className="table table-condensed table-striped"
                ajax={{
                  url: this.getTableUrl('init'),
                  dataSrc: ""
                }}
                onUserCommand={({ commandData }) =>{
                  if (user.tokenHasExpired) {
                    window.location.replace('/login');
                    return;
                  } 
                  else {
                    if(commandData && commandData.action==='edit'){
                      this.setState({ showEditDataFor: commandData })
                    }
                    else if(commandData && commandData.action==='disable'){
                      var offerId = commandData && commandData.offerid!==null?commandData.offerId:null;
                      this.handleDisableClickCap(offerId);
                    }
                  }
                }}
                //prettier-ignore
                columns={[
                  DataTable.StockColumns.textTruncate("offerId", "Offer ID"),
                  DataTable.StockColumns.textTruncate("offerName", "Offer name"),
                  DataTable.StockColumns.textTruncateWide("advertiserName", "Advertiser"),
                  //DataTable.StockColumns.text("campaignState", "Campaign state"),
                  {
                    title: "Campaign state",
                    data: "campaignState",
                    name: "campaignState"
                  },
                  DataTable.StockColumns.moderationStatus("moderationStatus", "moderationStatus"),
                  DataTable.StockColumns.count("maxDailyClicks", "Daily imp/clicks"),
                  /*DataTable.StockColumns.count("maxHourlyClicks", "Hourly clicks"),*/
                  {
                    title: "Even pacing",
                    className: "",
                    data: "pacing",
                    defultContent: "",
                    render: renderEvenPacing
                  },
                  DataTable.StockColumns.toggle("enabled", "Rule status"),
                  {
                    title: "Email to",
                    className: "text-sm",
                    data: "emailTo",
                    render: renderEmail
                  },
                  {
                    title: "Created at",
                    className: "text-sm",
                    data: "createdAt",
                    render: DataTable.StockRenderers.utcDateTime()
                  },
                  {
                    title: "Created by",
                    className: "text-sm",
                    data: "createdBy"
                  },
                  DataTable.createUserCommandColumn({
                    title: "",
                    data: null,
                    className: "text-right",
                    orderable: false,
                    defultContent: "",
                    width: "20px",
                    commandName: "",
                    commandData: ({ rowData }) => ({
                      action: 'edit',
                      campaign: rowData.offerId,
                      dailyClicks: rowData.maxDailyClicks,
                      hourlyClicks: rowData.maxHourlyClicks,
                      pacing: rowData.pacing                    
                    }),
                    render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> Edit </button></div>"),
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "20px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'disable',
                        offerId: rowData.offerId
                      }),
                      render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small' "+(r.enabled?'':'disabled')+"> Disable </button></div>"),
                  }),
                ]}
                order={[[11, "desc"]]}
              />
            </Box.Body>
          </Box>
        )}
        </UserContext.Consumer>
      </PageLayout>
      {
      this.state.showAdd && (
        <AddDialog
          campaign={showAddDataFor?showAddDataFor.campaign:null}
          onHide={() => {
            if(showAddDataFor){
              this.tableRef_.current.reload();
            }else{
              this.tableRef.current.reload();
            }
            this.setState({
              busy: false,
              error: null,
              showAdd: false,
              showAddDataFor: null
            });
          }}
          onRowsChanged={() => {
            this.tableRef.current.api
              .rows()
              .invalidate()
              .draw();
          }}
        />
      )
    }
    {
      this.state.showEditDataFor && (
        <AddDialog
          campaign={showEditDataFor.campaign}
          dailyClicks={showEditDataFor.dailyClicks}
          hourlyClicks={showEditDataFor.hourlyClicks}
          pacing={showEditDataFor.pacing}
          editMode={true}
          onHide={() => {
            this.tableRef.current.reload();
            this.setState({  
              busy: false,
              error: null,
              showEdit: false, 
              showEditDataFor: null 
            });
          }}
          onRowsChanged={() => {
            this.tableRef.current.api
              .rows()
              .invalidate()
              .draw();
          }}
        />
      )
    }
    {
      this.state.showDisable && (
        <DisableDialog
          onHide={() => this.setState({ showDisable: false })}
          onRowsChanged={() => {
            this.tableRef.current.api
              .rows()
              .invalidate()
              .draw();
          }}
        />
      )
    }
     </>
    );
  }

  handleDisableClickCap(offerId) {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/reports/disableclickcap",
      method: "post",
      data: {
        _id: offerId
      }
    })
      .then(res => {
        this.tableRef.current.reload();
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({showAdd: true});
    }
  }

  constructTableUrl(name,e){
    //var isInit = name=="init"?true:false;
    var selectedCampaignState = name==="campaignState"?e:this.state.selectedCampaignState;
    var selectedModerationStatus = name==="moderationStatus"?e:this.state.selectedModerationStatus;
    var values1 = (selectedCampaignState && (selectedCampaignState.includes("All") || selectedCampaignState.length>2)) ? "" : selectedCampaignState.join(",");
    var values2 = (selectedModerationStatus && (selectedModerationStatus.includes("All") || selectedModerationStatus.length>2)) ? "" : selectedModerationStatus.join(",");
    var qs1 = ((values1&&values1.length)?"CampaignState="+values1:"");
    var qs2 = ((values2&&values2.length)?"ModerationStatus="+values2:"");
    
    var qs = "";
    if(qs1.length||qs2.length){
      qs = "?"+qs1+(qs2.length?"&"+qs2:"");
    }
    var newUrl = this.state.tableUrl+qs;
    
    return newUrl;
  }
  
  handleReload(name,e){
    var newUrl = this.constructTableUrl(name,e);
    this.tableRef.current.setNewUrl(newUrl);
  }
  
  getTableUrl(init){
    return this.constructTableUrl(init);
  }

  handleShowNoAutoPauseOffers(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      var newState = !this.state.showNoAutoPauseOffers;
      this.setState({showNoAutoPauseOffers: newState});
    }
  }
}
