import React from "react";
import moment from "moment-timezone";
import { Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import DateRangePicker from "components/pickers/DateRangePicker";

moment.tz.setDefault("UTC");

function Hours({ value, onChange }) {
  return (
    <select className="form-control" value={value} onChange={onChange}>
      <option value={0}>Start of day</option>
      <option value={1}>01:00</option>
      <option value={2}>02:00</option>
      <option value={3}>03:00</option>
      <option value={4}>04:00</option>
      <option value={5}>05:00</option>
      <option value={6}>06:00</option>
      <option value={7}>07:00</option>
      <option value={8}>08:00</option>
      <option value={9}>09:00</option>
      <option value={10}>10:00</option>
      <option value={11}>11:00</option>
      <option value={12}>12:00</option>
      <option value={13}>13:00</option>
      <option value={14}>14:00</option>
      <option value={15}>15:00</option>
      <option value={16}>16:00</option>
      <option value={17}>17:00</option>
      <option value={18}>18:00</option>
      <option value={19}>19:00</option>
      <option value={20}>20:00</option>
      <option value={21}>21:00</option>
      <option value={22}>22:00</option>
      <option value={23}>23:00</option>
      <option value={24}>End of day</option>
    </select>
  );
}

function Latest({ onClick }) {
  return (
    <a
      href="/"
      onClick={e => {
        e.preventDefault();
        const thisHour = moment().hour();
        onClick(thisHour === 0 ? 24 : thisHour);
      }}
      title="Today's latest whole hour"
    >
      Latest
    </a>
  );
}

function EOD({ onClick }) {
  return (
    <a
      href="/"
      onClick={e => {
        e.preventDefault();
        onClick(24);
      }}
      title="End of day"
    >
      EOD
    </a>
  );
}

export default function({ L1, L2, R1, R2, H1, H2, onChange }) {
  return (
    <Row>
      <Col lg={2}>
        <FormGroup>
          <ControlLabel>Left Date Range</ControlLabel>
          <DateRangePicker
            className="form-control range-input-0"
            autoUpdateInput={undefined}
            startDate={L1}
            endDate={L2}
            onChange={value =>
              onChange({
                L1: (value && value[0]) || null,
                L2: (value && value[1]) || null
              })
            }
          />
        </FormGroup>
      </Col>
      <Col lg={2}>
        <FormGroup>
          <ControlLabel>Right Date Range</ControlLabel>
          <DateRangePicker
            className="form-control range-input-1"
            autoUpdateInput={undefined}
            startDate={R1}
            endDate={R2}
            onChange={value =>
              onChange({
                R1: (value && value[0]) || null,
                R2: (value && value[1]) || null
              })
            }
          />
        </FormGroup>
      </Col>
      <Col lg={2}>
        <FormGroup>
          <ControlLabel>From Hour</ControlLabel>
          <Hours
            value={H1}
            onChange={e => onChange({ H1: parseInt(e.target.value) })}
          />
        </FormGroup>
      </Col>
      <Col lg={2}>
        <FormGroup>
          <ControlLabel>To Hour</ControlLabel>
          <span className="pull-right text-sm">
            <Latest onClick={x => onChange({ H2: x })} />
            &nbsp;|&nbsp;
            <EOD onClick={x => onChange({ H2: x })} />
          </span>
          <Hours
            className="form-control"
            value={H2}
            onChange={e => onChange({ H2: parseInt(e.target.value) })}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}
