import React from "react";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import DataTable from "components/DataTable";

function DimensionButtons(props) {
  return (
    <ToggleButtonGroup
      id="dimension"
      name="dimension"
      type="radio"
      bsSize="small"
      {...props}
    >
      <ToggleButton value="clicks">Clicks</ToggleButton>
      <ToggleButton value="installs">Installs</ToggleButton>
      <ToggleButton value="installsOpt">InstallsOpt</ToggleButton>
      <ToggleButton value="events">Events</ToggleButton>
      <ToggleButton value="revenue">Revenue</ToggleButton>
      <ToggleButton value="cost">Cost</ToggleButton>
    </ToggleButtonGroup>
  );
}

function RangeButtons(props) {
  return (
    <ToggleButtonGroup
      id="range"
      name="range"
      type="radio"
      bsSize="small"
      {...props}
    >
      <ToggleButton value={1}>None</ToggleButton>
      <ToggleButton value={2}>3d</ToggleButton>
      <ToggleButton value={3}>10d</ToggleButton>
      <ToggleButton value={4}>20d</ToggleButton>
      <ToggleButton value={5}>30d</ToggleButton>
      <ToggleButton value={0}>All</ToggleButton>
    </ToggleButtonGroup>
  );
}

/**
 * The functionality of the toolbar was extracted
 * to a separate component in order to avoid the
 * extra updates to the DataTable caused by the
 * changes in the toolbar's state. So now this
 * component keeps track of its state internally
 * and notifies the outside world when the state
 * changes through the onChange callback.
 */
export default function Toolbar({
  tableRef,
  includeLastDays,
  defaultDimension,
  defaultRange,
  onChange
}) {
  const [dimension, setDimension] = React.useState(defaultDimension);
  const [range, setRange] = React.useState(defaultRange);

  React.useEffect(() => onChange(dimension, range));

  return (
    <DataTable.Toolbar tableRef={tableRef} bsSize="small" noReloadButton>
      <DimensionButtons defaultValue={dimension} onChange={setDimension} />
      {includeLastDays && (
        <RangeButtons defaultValue={range} onChange={setRange} />
      )}
    </DataTable.Toolbar>
  );
}
