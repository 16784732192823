import React from "react";
import PageLayout from "components/PageLayout";
import { Box } from "components/admin-lte";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  ButtonToolbar
} from "react-bootstrap";
import { AdvertiserPicker } from "components/pickers/SelectPickers";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";
import download from "downloadjs";
import { extractFilenameFromResponse } from "lib/Functions";
import DataTable, { DataTableToolbar } from "components/DataTable";

function PIERemainingBudget() {
  const [busy, setBusy] = React.useState(false);
  const [advertiserId, setAdvertiserId] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const tableRef = React.useRef();

  function handleDownloadOrPreview(excel) {
    setBusy(true);
    let filename = "";
    fetch("/api/v1/reports/pieremainingbudget", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        advertiserId,
        output: excel ? "Excel" : "Resultset"
      })
    })
      /*.then(res => {
        if (excel) {
          filename = extractFilenameFromResponse(res);
          return res.blob();
        } else {
          return res.json();
        }
      })*/
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        if (excel) {
          filename = extractFilenameFromResponse(res);
          return res.blob();
        } else {
          return res.json();
        }
      })
      .then(data => {
        if (excel) {
          download(
            data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          setRecords(data);
        }
      })
      .finally(() => setBusy(false));
  }

  return (
    <PageLayout breadcrumbs={["Reports"]} title={PIERemainingBudget.title}>
      <Box busy={busy}>
        <Box.Body>
          <Row>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Advertiser</ControlLabel>
                <AdvertiserPicker
                  className="form-control"
                  placeholder="Enter id or name"
                  onChange={setAdvertiserId}
                />
              </FormGroup>
            </Col>
          </Row>
        </Box.Body>
        <Box.Footer>
          <ButtonToolbar>
            <DownloadButton
              onClick={handleDownloadOrPreview.bind(null, true)}
            />
            <PreviewButton
              onClick={handleDownloadOrPreview.bind(null, false)}
            />
          </ButtonToolbar>
        </Box.Footer>
      </Box>
      {records && (
        <Box>
          <Box.Body>
            <DataTableToolbar
              bsSize="small"
              tableRef={tableRef}
              noReloadButton
            />
            <DataTable
              ref={tableRef}
              className="table table-condensed table-hover"
              columns={[
                DataTable.StockColumns.text("_id", "Campaign ID"),
                DataTable.StockColumns.text("name", "Campaign Name"),
                DataTable.StockColumns.money("payoutCap", "Budget Cap"),
                DataTable.StockColumns.money("revenues", "Revenues"),
                DataTable.StockColumns.money("campaignRiskCeiling", "Max Risk"),
                DataTable.StockColumns.money("currentRisk", "Current Risk"),
                DataTable.StockColumns.money(
                  "remainingBudget",
                  "Remaining Budget"
                ),
                DataTable.StockColumns.money("remainingRisk", "Remaining Risk")
              ]}
              data={records}
              order={[]}
            />
          </Box.Body>
        </Box>
      )}
    </PageLayout>
  );
}

PIERemainingBudget.title = "PIE Campaign Remaining Budget";

export default PIERemainingBudget;
