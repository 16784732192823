import React from "react";
import ReactDOMServer from "react-dom/server";
import $ from "jquery";
import "datatables.net-bs";
import "datatables.net-select";
import "datatables.net-bs/css/dataTables.bootstrap.css";
import "datatables.net-select-bs/css/select.bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./styles.css";
import { IDataTable } from "./types";
import Toolbar from "./Toolbar";
import ReloadButton from "./ReloadButton";
import PageSizer from "./PageSizer";
import SearchBox from "./SearchBox";
import ColumnFilter from "./ColumnFilter";
import * as Renderers from "./renderers";
import * as Columns from "./columns";


(window as any).jQuery = $;
(window as any).$ = $;

type UserCommand = {
  commandName: string;
  commandData?: any;
};

type CreatedCellParameters = {
  cell: Node;
  cellData: any;
  rowData: any;
  row: number;
  col: number;
};

type UserCommandColumnDataCallback = (
  createdCellParams: CreatedCellParameters
) => any;

type UserCommandColumnSettings = DataTables.ColumnSettings & {
  commandName: string;
  commandData: UserCommandColumnDataCallback;
};

function createUserCommandColumn(
  settings: UserCommandColumnSettings
): DataTables.ColumnSettings {
  const { commandName, commandData, createdCell, ...rest } = settings;
  return {
    ...rest,
    createdCell: function(
      cell: Node,
      cellData: any,
      rowData: any,
      row: number,
      col: number
    ) {
      $(cell).wrapInner(
        $("<a>")
          .attr("href", "#")
          .click(function(e) {
            e.preventDefault();
            $(e.target).trigger("userCommand.dt", {
              commandName,
              commandData: commandData({
                cell,
                cellData,
                rowData,
                row,
                col
              })
            });
          })
      );
    }
  };
}

type LoadingEvent = {
  e: JQuery.Event;
  settings: DataTables.SettingsLegacy;
  data: any;
};

type LoadedEvent = {
  e: JQuery.Event;
  settings: DataTables.SettingsLegacy;
  json: any;
  xhr: JQuery.jqXHR;
};

type DataTableProps = DataTables.Settings & {
  className?: string;
  showCheckBoxes?: boolean;
  noSelectedOnly?: boolean;
  noSelectAll?: boolean;
  onLoading?: (e: LoadingEvent) => void;
  onLoaded?: (e: LoadedEvent) => void;
  onSelect?: (rows: Array<any>) => void;
  onUserCommand?: (e: UserCommand) => void;
};

class DataTable extends React.Component<DataTableProps> implements IDataTable {
  private tableRef = React.createRef<HTMLTableElement>();

  public get $table(): JQuery<HTMLTableElement> {
    return $(this.tableRef.current as HTMLTableElement);
  }

  public get api(): DataTables.Api {
    return this.$table.DataTable();
  }

  constructor(props: DataTableProps) {
    super(props);
    this.handleInit = this.handleInit.bind(this);
    this.handlePreXhr = this.handlePreXhr.bind(this);
    this.handleXhr = this.handleXhr.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleDraw = this.handleDraw.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleUserCommand = this.handleUserCommand.bind(this);
  }

  componentDidMount() {
    this.initializePlugin.call(this);
  }

  componentWillUnmount() {
    this.terminatePlugin.call(this);
  }

  componentDidUpdate(prevProps: DataTableProps) {
    if (prevProps.data !== this.props.data) {
      const api = this.api;
      api.clear();
      if (this.props.data) api.rows.add(this.props.data);
      api.draw();
      this.updateSelectAll();
    }
  }

  render() {
    return (
      <div>
        <table ref={this.tableRef} className={this.props.className}>
          {this.props.children || <tbody></tbody>}
        </table>
      </div>
    );
  }

  private initializePlugin() {
    const options: DataTables.Settings = {
      destroy: true,
      dom: `
        <'row'<'col-sm-12't>>
        <'row'<'col-sm-5'i><'col-sm-7'p>>
      `,
      ...this.props
    };

    if (options.ajax) {
      if (typeof options.ajax === "string") {
        options.ajax = {
          url: options.ajax,
          error: this.handleError
        };
      } else if (typeof options.ajax === "object") {
        options.ajax.error = this.handleError;
      }
    }

    if (this.props.showCheckBoxes) {
      // add extra column
      options.columns?.unshift({
        defaultContent: "",
        orderable: false,
        className: this.props.noSelectAll
          ? "selection-checkbox selection-checkbox-fa selection-checkbox-prevent-all"
          : "selection-checkbox selection-checkbox-fa",
        width: "15px"
      });

      // adjust select options
      options.select = {
        style: "multi",
        selector: "td:first-child",
        className: "selected-transparent" // the default is "selected", which uses Bootstrap's Primary color
      };
      if (options?.order?.length===0){
        
      }else{
        // move default order to the second column
        if (!options.order) options.order = [[1, "asc"]];
      }
      
    }

    // any events that run at initialization
    // need to be attached to the DOM element
    // beforehand, otherwise they won't fire
    this.$table.on("init.dt", this.handleInit);

    this.$table.DataTable(options);
    this.$table
      .on("preXhr.dt", this.handlePreXhr)
      .on("xhr.dt", this.handleXhr)
      .on("page.dt", this.handlePage)
      .on("select.dt deselect.dt", this.handleSelect)
      .on("draw.dt", this.handleDraw)
      .on("userCommand.dt", this.handleUserCommand)
      .css("width", "100%");
  }

  private terminatePlugin() {
    this.showOnlySelected(false);
    this.$table
      .off("init.dt")
      .off("preXhr.dt xhr.dt")
      .off("page.dt")
      .off("select.dt deselect.dt")
      .off("draw.dt")
      .off("userCommand.dt");
  }

  private handleInit() {
    if (this.props.showCheckBoxes) {
      $(this.api.table(undefined).header())
        .find(".selection-checkbox")
        .click(this.handleSelectAll);
    }
  }

  private handlePreXhr(
    e: JQuery.Event,
    settings: DataTables.SettingsLegacy,
    data: any
  ) {
    if (this.props.onLoading) this.props.onLoading({ e, settings, data });
  }

  private handleXhr(
    e: JQuery.Event,
    settings: DataTables.SettingsLegacy,
    json: any,
    xhr: JQuery.jqXHR
  ) {
    this.updateSelectAll();
    if (this.props.onLoaded) this.props.onLoaded({ e, settings, json, xhr });
  }

  private handlePage(_e: JQuery.Event, _settings: DataTables.SettingsLegacy) {
    this.updateSelectAll();
  }

  private handleSelect(_e: any, _dt: any, _type: any, _indexes: any[]) {
    const api = this.api;

    if (api.rows({ selected: true }).count() === 0) {
      this.showOnlySelected(false);
    }

    this.updateSelectAll();
    this.enhanceSelectInfo();

    if (this.props.onSelect) {
      this.props.onSelect(
        api
          .rows({ selected: true })
          .data()
          .toArray()
      );
    }
  }

  private handleSelectAll(_e: JQuery.ClickEvent) {
    if (this.props.noSelectAll) return;

    const api = this.api;
    const selectedPageCount = api
      .rows({ search: "applied", page: "current", selected: true })
      .count();

    if (selectedPageCount > 0) {
      this.selectNone();
    } else {
      this.selectAll();
    }
  }

  private handleDraw() {
    // On every draw of the table the select-info
    // spans are recreated by the library, so we
    // need to re-run enhance every time.
    this.enhanceSelectInfo();
  }

  /**
   * Called by jQuery in case of an error in the Ajax call.
   * @param jqXHR The jqXHR (in jQuery 1.4.x, XMLHttpRequest) object.
   * @param textStatus A string describing the type of error that occurred. Possible values (besides null) are "timeout", "error", "abort", and "parsererror".
   * @param errorThrown Receives the textual portion of the HTTP status, such as "Not Found" or "Internal Server Error".
   */
  private handleError(
    jqXHR: JQuery.jqXHR,
    _textStatus: JQuery.Ajax.ErrorTextStatus,
    _errorThrown: string
  ): void {
    if(jqXHR.status===401){
      window.location.replace('/login');
    }else{
      $(this.api.table(undefined).body())
        .find(".dataTables_empty")
        .html(
          ReactDOMServer.renderToStaticMarkup(
            <small className="text-danger">
              <i className="fa fa-exclamation-triangle"></i>
              {Boolean(jqXHR.status) && <span> {jqXHR.status}</span>}
              {Boolean(jqXHR.statusText) && <span> {jqXHR.statusText}</span>}
              {Boolean(jqXHR.responseText) && <span>: {jqXHR.responseText}</span>}
            </small>
          )
        );
    }
  }

  private handleUserCommand(jevent: JQuery.Event, cevent: UserCommand) {
    if (this.props.onUserCommand) this.props.onUserCommand(cevent);
  }

  /**
   * Updates the tri-state of the select-all button
   * to reflect the selection state of the table.
   */
  private updateSelectAll() {
    const api = this.api;

    const checkbox = $(api.table(undefined).header())
      .find(".selection-checkbox")
      .removeClass(["selection-checkbox-all", "selection-checkbox-some"]);

    const selectedCount = api
      .rows({ search: "applied", page: "current", selected: true })
      .count();
    if (selectedCount > 0) {
      if (
        selectedCount ===
        api.rows({ search: "applied", page: "current" }).count()
      ) {
        checkbox.addClass("selection-checkbox-all");
      } else {
        checkbox.addClass("selection-checkbox-some");
      }
    }
  }

  /**
   * Converts the default span that reads "N rows selected"
   * into a clickable anchor that allows the user to switch
   * between viewing selected rows and all rows.
   */
  private enhanceSelectInfo() {
    const container = $(this.api.table(undefined).container());

    if (this.api.rows({ selected: true }).count() === 0) {
      // Clear the selected flag in case an external
      // process, like a reload, has de-selected all
      // rows while the flag was enabled.
      container.removeClass("selected-only");
      return;
    }

    if (this.props.noSelectedOnly) return;

    const span = container.find(".select-info > .select-item:nth-child(1)");
    const text = span.text();
    const anchor = $("<a>").click(e => {
      e.preventDefault();
      if (container.hasClass("selected-only")) {
        container.removeClass("selected-only");
        this.showOnlySelected(false);
      } else {
        container.addClass("selected-only");
        this.showOnlySelected(true);
      }
      this.updateSelectAll();
    });
    if (container.hasClass("selected-only")) {
      anchor.attr("title", "Click to view both selected and unselected");
      anchor.attr("href", "#view-all");
      anchor.text(text);
    } else {
      anchor.attr("title", "Click to view selected only");
      anchor.attr("href", "#view-selected");
      anchor.text(text);
    }
    span.text("").append(anchor);
  }

  /**
   * Switches table view between selected-only and all rows.
   * @param enabled If truthy, only selected rows will be displayed.
   */
  private showOnlySelected(enabled: boolean, draw: boolean = true) {
    const searchCallbacks = $.fn.dataTable.ext.search;

    // Very important: since searchCallbacks is a global array
    // each table must have its own callback inside that array.
    // This table is identified by its id found in legace settings.
    const callbackId = `${this.api.settings()[0].nTable.id}-search-callback`;

    function selectedOnlyFilter(settings: any, _data: any, dataIndex: number) {
      if (callbackId !== `${settings.nTable.id}-search-callback`) return true;
      const api = new $.fn.dataTable.Api(settings);
      return $(api.row(dataIndex).node()).hasClass("selected-transparent");
    }
    selectedOnlyFilter.id = callbackId;

    const index = searchCallbacks.findIndex(x => x.id === callbackId);
    if (enabled) {
      if (index === -1) searchCallbacks.push(selectedOnlyFilter); // https://stackoverflow.com/a/43750405/523955
    } else {
      if (index >= 0) searchCallbacks.splice(index, 1);
    }

    if (draw) this.api.draw();
  }

  /**
   * Selects all rows.
   */
  public selectAll() {
    this.api.rows({ search: "applied", page: "current" }).select();
  }

  /**
   * Deselects all rows.
   */
  public selectNone() {
    this.api.rows({ search: "applied", page: "current" }).deselect();
  }

  public search(text: string) {
    this.api.search(text).draw("full-reset");
  }

  public setPageSize(size: number) {
    this.api.page.len(size).draw();
  }

  public reload(callback?: (json: any) => void) {
    this.api.ajax.reload(callback);
  } 
  
  public getAjaxJson(callback?: (json: any) => void) {
    return this.api.ajax.json();
  }

  public setNewUrl(newUrl: string, callback?: (json: any) => void) {
    //this.api.clear();
    //this.api.draw();
    this.api.ajax.url(newUrl).load();
    //this.api.ajax.reload(callback);
  }
  
  /**
   * Shortcut method for applying a filter on a list of columns.
   * @param modifier
   * @param filter Search string to apply to the table.
   * @param regex Treat as a regular expression (true) or not (default, false).
   * @param smart Perform smart search.
   * @param caseInsen Do case-insensitive matching (default, true) or not (false).
   */
  public setColumnFilter(
    columnSelector: any,
    filter: string,
    regex?: boolean,
    smart?: boolean,
    caseInsen?: boolean
  ) {
    this.api
      .columns(columnSelector)
      .search(filter, regex, smart, caseInsen)
      .draw();
  }

  /**
   * Shortcut method for showing or hiding a list of columns.
   * @param modifier
   * @param visible Specify if the column should be visible (true) or not (false).
   * @param redrawCalculations Indicate if DataTables should recalculate the column layout (true - default) or not (false).
   */
  public setColumnVisibility(
    columnSelector: any,
    visible: boolean,
    redrawCalculations?: boolean
  ) {
    this.api
      .columns(columnSelector)
      .visible(visible, redrawCalculations)
      .draw();
  }

  public static createUserCommandColumn = createUserCommandColumn;
  public static Toolbar = Toolbar;
  public static ReloadButton = ReloadButton;
  public static PageSizer = PageSizer;
  public static SearchBox = SearchBox;
  public static ColumnFilter = ColumnFilter;
  public static StockRenderers = Renderers;
  public static StockColumns = Columns;
}

export default DataTable;
