import React from "react";
import PageLayout from "components/PageLayout";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <PageLayout documentTitle="Error" breadcrumbs={null}>
      <div className="error-page">
        <h2 className="headline text-yellow">404</h2>
        <div className="error-content">
          <h3>Page not found.</h3>
          <p>
            You tried to reach a page that does not exist or you mistyped its
            path in the address bar. Please check your spelling and try again.
          </p>
          <Link to="/">Go to Home page</Link>
        </div>
      </div>
    </PageLayout>
  );
};

export default NotFound;
