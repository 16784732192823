import React from "react";
import DataTable from "components/DataTable";
import { getColumns } from "./columns";
import { getHeader } from "./header";
import { getFooter, getFooterCallback } from "./footer";
import { isDemand } from "../functions";
import Toolbar from "./toolbar";

function toggleColumns(tableRef, dimension, range, request) {
  const api = tableRef.current.api;
  const redraw = false;

  // first we hide all toggleable columns
  // and we decide which ones to show based
  // on the current state
  api.columns(".columnset").visible(false, redraw);

  if (request.includeAdopsInfo) {
    api.columns(".columnset-adops").visible(true, redraw);
  }

  if (request.groupByAffiliate) {
    api.columns(".columnset-groupByAffiliate").visible(true, redraw);
  }

  if (request.groupByCampaign) {
    api.columns(".columnset-groupByCampaign").visible(true, redraw);
  }

  // left comparison columns are always visible
  api.columns(`.columnset-${dimension}`).visible(true, redraw);

  // and so are right comparison columns
  api.columns(`.columnset-${dimension}-1`).visible(true, redraw);

  if (request.includeLastDays) {
    if (range === 2 || range === 0) {
      api.columns(`.columnset-${dimension}-2`).visible(true, redraw);
    }
    if (range === 3 || range === 0) {
      api.columns(`.columnset-${dimension}-3`).visible(true, redraw);
    }
    if (range === 4 || range === 0) {
      api.columns(`.columnset-${dimension}-4`).visible(true, redraw);
    }
    if (range === 5 || range === 0) {
      api.columns(`.columnset-${dimension}-5`).visible(true, redraw);
    }
  }

  api.columns.adjust().draw(false);
}

export default class extends React.PureComponent {
  tableRef = React.createRef();

  render() {
    return (
      <>
        <Toolbar
          tableRef={this.tableRef}
          includeLastDays={this.props.response.request.includeLastDays}
          defaultDimension={this.props.defaultDimension}
          defaultRange={this.props.defaultRange}
          onChange={(dimension, range) =>
            toggleColumns(
              this.tableRef,
              dimension,
              range,
              this.props.response.request
            )
          }
        />
        <DataTable
          key={JSON.stringify(this.props.response.request)} // very important: prevents unnecessary re-init of the DataTable!
          ref={this.tableRef}
          className="table table-condensed table-striped table-hover"
          columns={getColumns(this.props.affiliateSide, this.props.response)}
          data={
            isDemand(this.props.affiliateSide)
              ? this.props.response.demand
              : this.props.response.supply
          }
          footerCallback={getFooterCallback()}
          order={[[1, "asc"]]}
          deferRender={true}
        >
          <thead
            dangerouslySetInnerHTML={getHeader(
              this.props.response.actualRanges
            )}
          />
          <tbody></tbody>
          <tfoot dangerouslySetInnerHTML={getFooter()} />
        </DataTable>
      </>
    );
  }
}
