import React from "react";
import { Modal } from "react-bootstrap";
import store_and from "assets/storeIcons/google-play.png";
import store_ios from "assets/storeIcons/app-store.png";
import DataTable from "components/DataTable";

export default function SelectedOffers({
  packageName,
  title,
  offerIds,
  storeId,
  onHide
}) {
  const tableRef = React.createRef();
  const body = offerIds.join(",");
  return (
    <Modal bsSize="large" show={true} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{title}</small>
          <br />
          {storeId === "1" ? (
            <img
              className="store-icon"
              src={store_and}
              alt="Google Play"
              title="Google Play: The official google market store for Android apps."
            ></img>
          ) : (
            <img
              className="store-icon"
              src={store_ios}
              alt="Apple iTunes"
              title="Apple iTunes: The official market store for Apple iOS apps."
            ></img>
          )}
          &nbsp;{packageName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable.Toolbar tableRef={tableRef} bsSize="small" noReloadButton />
        <DataTable
          ref={tableRef}
          className="table table-condensed table-striped table-hover"
          ajax={{
            url: "/api/v1/reports/offerscountbypackagename/offers",
            method: "POST",
            contentType: "application/json",
            data: () => JSON.stringify(body),
            processData: false,
            dataSrc: ""
          }}
          columns={[
            { title: "Id", data: "id" },
            { title: "Name", data: "name" },
            {
              title: "Countries",
              data: "targetedCountries",
              className: "truncate-cell",
              render: DataTable.StockRenderers.textWithTooltip()
            },
            {
              title: "Advertiser",
              data: (r, t, s, m) => r.advertiserName || r.adNetworkName
            },
            {
              title: "Payout",
              data: "payout",
              className: "text-right",
              render: DataTable.StockRenderers.money()
            },
            {
              title: "Status",
              data: "moderationStatus",
              className: "text-center",
              render: DataTable.StockRenderers.moderationStatus()
            }
          ]}
        />
      </Modal.Body>
    </Modal>
  );
}
