import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {Button, ButtonGroup} from "react-bootstrap";
import DeleteDialog from "./DeleteDialog";
import { UserContext } from "lib/user";

function renderAdvertiserId(d, t, r, m) {
  return r.networkAdvertiserId || d;
}

function renderAdvertiserName(d, t, r, m) {
  if (t === "display" || t === "sort" || t === "filter") {
    return (
      (r.networkAdvertiserId || d) &&
      m.settings.json.names[r.networkAdvertiserId || d]
    );
  }
  return d;
}

function renderPublisherName(d, t, r, m) {
  if (t === "display" || t === "sort" || t === "filter") {
    return m.settings.json.names[d] || "";
  }
  return d;
}

export default class AutoTargetedPublishers extends React.Component {
  static title = "Auto-Targeted Publishers";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showDelete: false,
      busy: false,
      error: null
    };
    
  }
  
  render() {
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={AutoTargetedPublishers.title}
          description={
            <p>
              The publishers included in this list will be automatically set as
              targeted by Minimob to all offers of their respective advertisers.
            </p>
          }
        >
          <Box busy={this.state.busy}>
            <Box.Header>
              <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
              <ButtonGroup bsSize="small">
                <UserContext.Consumer>
                  {user => (
                    <Button
                      //onClick={() => this.setState({ showDelete: true })}
                      onClick={(e) => {
                        if(!this.checkTokenExpired(user)){
                          this.setState({ showDelete: true });
                        }
                      }}
                    >
                      Remove Publisher Auto-Targeting
                    </Button>
                  )}
                </UserContext.Consumer>
              </ButtonGroup>
            </Box.Header>
            <Box.Body>
              <DataTable
                className="table table-condensed table-striped"
                ref={this.tableRef}
                ajax={{
                  url: "/api/v1/reports/autotargetedpublishers",
                  dataSrc: "items"
                }}
                columns={[
                  {
                    title: "Advertiser ID",
                    data: "_id",
                    render: renderAdvertiserId
                  },
                  {
                    title: "Advertiser Name",
                    data: "_id",
                    render: renderAdvertiserName
                  },
                  {
                    title: "Publisher ID",
                    data: "autoTargetedTrafficSource"
                  },
                  {
                    title: "Publisher Name",
                    data: "autoTargetedTrafficSource",
                    render: renderPublisherName
                  }
                ]}
                order={[[1, "asc"]]}
              />
            </Box.Body>
          </Box>
        </PageLayout>
        {this.state.showDelete && (
          <DeleteDialog
            show={this.state.showDelete}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showDelete: false
              })
            }}
          />
        )}
      </>
    );
  }

  checkTokenExpired(user){
    if (user.tokenHasExpired) {
      window.location.replace('/login');
      return true;
    }
    return false;
  }
}