import React from "react";
import Platform from "components/Platform";
import Store from "components/Store";
import TrafficType from "components/TrafficType";
import ModerationStatus from "components/ModerationStatus";
import { CSVLink } from "react-csv";
import moment from "moment";
import { payout } from "lib/Functions";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
      expanded: false,
      showDecimalIds: false
    };
  }

  render() {
    const { offers, publishers, busy, onUpdateList, onClearList } = this.props;
    const { showDecimalIds, expanded, input } = this.state;

    const rows =
      offers === null || offers.length === 0 ? (
        <tr>
          <td colSpan="11" className="text-center text-muted">
            No offers selected
          </td>
        </tr>
      ) : (
        offers.map((x, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>
              <Platform value={x.targetPlatform} />
            </td>
            <td>
              <Store value={String(x.storeId)} />
            </td>
            <td>{showDecimalIds ? x.decimalId : x.id}</td>
            <td>{x.name}</td>
            <td className="text-right">{payout(x.payout)}</td>
            <td className="truncate-cell">
              {x.targetedCountries
                .map(x => x)
                .sort()
                .join(",")}
            </td>
            <td>{x.advertiserName}</td>
            <td>
              <TrafficType value={x.incentivized} />
            </td>
            <td>
              <ModerationStatus value={x.moderationStatus} />
            </td>
            <td className="truncate-cell-wide" style={{ width: "250px" }}>
              {expanded && x.targetedTrafficSources.length
                ? x.targetedTrafficSources
                    .map(t => ({ ...t }))
                    .sort((a, b) =>
                      a.username < b.username
                        ? -1
                        : a.username > b.username
                        ? 1
                        : 0
                    )
                    .map(t =>
                      (publishers || []).some(p => p._user_id === t.userId) ? (
                        <div key={t.userId}>
                          <span className="highlight">{t.username}</span>
                        </div>
                      ) : (
                        <div key={t.userId}>{t.username}</div>
                      )
                    )
                : x.targetedTrafficSources.length || ""}
            </td>
          </tr>
        ))
      );

    const csvHeaders = [
      "Platform",
      "OfferId",
      "Name",
      "Payout",
      "Countries",
      "TrafficType",
      "ModerationStatus"
    ];

    const csvData = (offers || []).map(x => [
      x.targetPlatform,
      showDecimalIds ? x.decimalId.toString() : x.id,
      x.name,
      x.payout,
      x.targetedCountries
        .map(x => x)
        .sort()
        .join(","),
      {
        0: "Both",
        1: "Incent",
        2: "Non-incent",
        3: "Uncertain"
      }[x.incentivized],
      {
        0: "PendingModeration",
        1: "Available",
        2: "Rejected"
      }[x.moderationStatus]
    ]);

    return (
      <div>
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Enter ANY TEXT containing offer ids either in hexadecimal or in decimal format"
            disabled={busy}
            onChange={e => this.setState({ input: e.target.value })}
          ></textarea>
        </div>
        <div className="form-group">
          <div className="btn-toolbar">
            <div className="btn-group btn-group-sm">
              <button
                className="btn btn-default"
                disabled={busy || !input}
                onClick={() => onUpdateList(input)}
              >
                Update list
              </button>
              <button
                className="btn btn-default"
                disabled={busy || offers === null || offers.length === 0}
                onClick={() => onClearList()}
              >
                Clear list
              </button>
            </div>
            <div className="btn-group btn-group-sm">
              <button
                className={`btn btn-default ${showDecimalIds ? "" : "active"}`}
                disabled={busy || offers === null || offers.length === 0}
                title="Show offer ids in hexadecimal format"
                onClick={() => this.setState({ showDecimalIds: false })}
              >
                Hex
              </button>
              <button
                className={`btn btn-default ${showDecimalIds ? "active" : ""}`}
                disabled={busy || offers === null || offers.length === 0}
                title="Show offer ids in decimal format"
                onClick={() => this.setState({ showDecimalIds: true })}
              >
                Dec
              </button>
            </div>
            <div className="btn-group btn-group-sm">
              <CSVLink
                className="btn btn-default"
                data={csvData}
                headers={csvHeaders}
                separator=","
                filename={`approved_offers_${moment().format(
                  "YYYYMMDDHHmmss"
                )}.csv`}
                onClick={() =>
                  !(busy || offers === null || offers.length === 0)
                }
                disabled={busy || offers === null || offers.length === 0}
              >
                <i className="glyphicon glyphicon-export"></i> Export
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="form-group">
          <table className="table table-condensed">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <i
                    title="Platform"
                    className="fa fa-info-circle fa-1_4x grey"
                  ></i>
                </th>
                <th>
                  <i
                    title="Store"
                    className="fa fa-info-circle fa-1_4x grey"
                  ></i>
                </th>
                <th>ID</th>
                <th>Name</th>
                <th>Payout</th>
                <th>Countries</th>
                <th>Advertiser</th>
                <th>
                  <i
                    title="Traffic Type"
                    className="fa fa-info-circle fa-1_4x grey"
                  ></i>
                </th>
                <th>
                  <i
                    title="Status"
                    className="fa fa-info-circle fa-1_4x grey"
                  ></i>
                </th>
                <th>
                  Targets &nbsp;
                  <a
                    href="/"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ expanded: !expanded });
                    }}
                  >
                    <i
                      className={`fa ${
                        expanded ? "fa-caret-up" : "fa-caret-down"
                      } grey`}
                    ></i>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
