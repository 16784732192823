import React from "react";
import $ from "jquery";
import DataTable from "components/DataTable";
import incent_b from "assets/incentivized/both.png";
import incent_i from "assets/incentivized/incent.png";
import incent_n from "assets/incentivized/non-incent.png";
import incent_u from "assets/incentivized/uncertain.png";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import UniquePackageNamesModal from "./UniquePackageNamesModal";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { UserContext } from "lib/user";
import "./styles.css";

let icon_b = `<img class="incentivized-icon" src="${incent_b}" title="Both" />`;
let icon_i = `<img class="incentivized-icon" src="${incent_i}" title="Incentivized" />`;
let icon_n = `<img class="incentivized-icon" src="${incent_n}" title="Non-incentivized" />`;
let icon_u = `<img class="incentivized-icon" src="${incent_u}" title="Uncertain" />`;

function renderAutoModeration(d, t, r, m) {
  if (!d) {
    return d;
  }
  if (t === "filter" || t === "display") {
    const descriptions = (d || "")
      .split("")
      .map(c => {
        switch (c) {
          case "B":
            return "Both";
          case "I":
            return "Incentivized";
          case "N":
            return "Non-incentivized";
          case "U":
            return "Uncertain";
          default:
            return "";
        }
      })
      .join(", ");
    if (t === "filter") {
      return descriptions;
    } else {
      return `<span title="${descriptions}">${d}</span>`;
    }
  }
  return d;
}

function renderAutoOpt(d, t, r, m) {
  if (!r.autoOptimizationEnabled) {
    return null;
  }
  if (t === "display" && d && r.autoOptimizationEnabled) {
    return `<span title="Ratio: ${d}, Start from: ${r.autoOptimizationStartFrom}">${d}%</span>`;
  }
  return d;
}

export default class OffersCountByApi extends React.Component {
  static title = "Offers Count by API";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedHour: 6,
      showPackageNamesFor: null
    };
    this.selectHour = this.selectHour.bind(this);
  }

  componentDidMount() {
    this.selectHour(this.state.selectedHour);
  }

  render() {
    const { showPackageNamesFor } = this.state;
    return (
      <>
        <PageLayout
          breadcrumbs={["Reports"]}
          title={OffersCountByApi.title}
          description={
            <p>
              Displays the number of offers each API has in Moderation.
              <br />
              Explanation of symbols: B=Both, I=Incentivized,
              N=Non-incentivized, U=Uncertain.
            </p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                  <ToggleButtonGroup
                    type="radio"
                    name="history"
                    bsSize="small"
                    defaultValue={this.state.selectedHour}
                    onChange={this.selectHour}
                  >
                    <ToggleButton disabled={this.state.loading} value={6}>
                      6h
                    </ToggleButton>
                    <ToggleButton disabled={this.state.loading} value={12}>
                      12h
                    </ToggleButton>
                    <ToggleButton disabled={this.state.loading} value={24}>
                      24h
                    </ToggleButton>
                    <ToggleButton disabled={this.state.loading} value={48}>
                      48h
                    </ToggleButton>
                  </ToggleButtonGroup>
                </DataTable.Toolbar>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped table-hover"
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/reports/offerscountbyapi?includeHistory=true",
                    dataSrc: "records"
                  }}
                  onLoading={() => this.setState({ loading: true })}
                  onLoaded={() => this.setState({ loading: false })}
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      this.setState({ showPackageNamesFor: commandData })
                    }
                  }}
                  //prettier-ignore
                  columns={[
                    { data: "advertiserId" },
                    { data: "advertiserName" },
                    { className: "text-right", data: "offers" },
                    { className: "text-right columnset-6", data: "offers6" },
                    { className: "text-right columnset-6", data: "offers6Incr", render: DataTable.StockRenderers.countIncrease() },
                    { className: "text-right columnset-6", data: "offers6IncrPrc", render: DataTable.StockRenderers.countIncreasePercent() },
                    { className: "text-right columnset-12", data: "offers12" },
                    { className: "text-right columnset-12", data: "offers12Incr", render: DataTable.StockRenderers.countIncrease() },
                    { className: "text-right columnset-12", data: "offers12IncrPrc", render: DataTable.StockRenderers.countIncreasePercent() },
                    { className: "text-right columnset-24", data: "offers24" },
                    { className: "text-right columnset-24", data: "offers24Incr", render: DataTable.StockRenderers.countIncrease() },
                    { className: "text-right columnset-24", data: "offers24IncrPrc", render: DataTable.StockRenderers.countIncreasePercent() },
                    { className: "text-right columnset-48", data: "offers48" },
                    { className: "text-right columnset-48", data: "offers48Incr", render: DataTable.StockRenderers.countIncrease() },
                    { className: "text-right columnset-48", data: "offers48IncrPrc", render: DataTable.StockRenderers.countIncreasePercent() },
                    { className: "bg-warning text-warning text-right", data: "p" },
                    { className: "text-right", data: "paff1" },
                    { className: "text-right", data: "paff0" },
                    { className: "text-right", data: "pi", visible: false },
                    { className: "text-right", data: "pn", visible: false },
                    { className: "text-right", data: "pb", visible: false },
                    { className: "text-right", data: "pu", visible: false },
                    { className: "bg-success text-success text-right", data: "a" },
                    { className: "text-right", data: "ai", visible: false },
                    { className: "text-right", data: "an", visible: false },
                    { className: "text-right", data: "ab", visible: false },
                    { className: "text-right", data: "au", visible: false },
                    { className: "bg-danger text-danger text-right", data: "r" },
                    { className: "text-right", data: "ri", visible: false },
                    { className: "text-right", data: "rn", visible: false },
                    { className: "text-right", data: "rb", visible: false },
                    { className: "text-right", data: "ru", visible: false },
                    DataTable.createUserCommandColumn({
                      title: "Unique Package Names",
                      data: "uniquePackageNames",
                      className: "text-right",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        id: rowData.advertiserId,
                        name: rowData.advertiserName
                      })
                    }),
                    {
                      title: "Auto-moderation",
                      data: "autoModeration",
                      className: "text-center",
                      render: renderAutoModeration
                    },
                    {
                      title: "Auto-commission",
                      data: "autoCommission",
                      className: "text-center"
                    },
                    {
                      title: "Auto-opt",
                      data: "autoOptimizationRatio",
                      className: "text-center",
                      render: renderAutoOpt
                    }
                  ]}
                  order={[[2, "desc"]]}
                  footerCallback={(tfoot, data, start, end, display) => {
                    const pto = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].offers), 0);
                    const pto6 = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].offers6), 0);
                    const pto12 = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].offers12), 0);
                    const pto24 = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].offers24), 0);
                    const pto48 = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].offers48), 0);
                    const ptp = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].p), 0);
                    const ptpaff1 = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].paff1), 0);
                    const ptpaff0 = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].paff0), 0);
                    const pta = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].a), 0);
                    const ptr = display
                      .slice(start, end)
                      .reduce((acc, cur) => (acc += data[cur].r), 0);
  
                    const gto = display.reduce(
                      (acc, cur) => (acc += data[cur].offers),
                      0
                    );
                    const gto6 = display.reduce(
                      (acc, cur) => (acc += data[cur].offers6),
                      0
                    );
                    const gto12 = display.reduce(
                      (acc, cur) => (acc += data[cur].offers12),
                      0
                    );
                    const gto24 = display.reduce(
                      (acc, cur) => (acc += data[cur].offers24),
                      0
                    );
                    const gto48 = display.reduce(
                      (acc, cur) => (acc += data[cur].offers48),
                      0
                    );
                    const gtp = display.reduce(
                      (acc, cur) => (acc += data[cur].p),
                      0
                    );
                    const gtpaff1 = display.reduce(
                      (acc, cur) => (acc += data[cur].paff1),
                      0
                    );
                    const gtpaff0 = display.reduce(
                      (acc, cur) => (acc += data[cur].paff0),
                      0
                    );
                    const gta = display.reduce(
                      (acc, cur) => (acc += data[cur].a),
                      0
                    );
                    const gtr = display.reduce(
                      (acc, cur) => (acc += data[cur].r),
                      0
                    );
  
                    const footer = $(tfoot).parent();
  
                    footer.find("#pto").html(pto);
                    footer.find("#pto6").html(pto6);
                    footer.find("#pto12").html(pto12);
                    footer.find("#pto24").html(pto24);
                    footer.find("#pto48").html(pto48);
                    footer.find("#ptp").html(ptp);
                    footer.find("#ptpaff1").html(ptpaff1);
                    footer.find("#ptpaff0").html(ptpaff0);
                    footer.find("#pta").html(pta);
                    footer.find("#ptr").html(ptr);
  
                    footer.find("#gto").html(gto);
                    footer.find("#gto6").html(gto6);
                    footer.find("#gto12").html(gto12);
                    footer.find("#gto24").html(gto24);
                    footer.find("#gto48").html(gto48);
                    footer.find("#gtp").html(gtp);
                    footer.find("#gtpaff1").html(gtpaff1);
                    footer.find("#gtpaff0").html(gtpaff0);
                    footer.find("#gta").html(gta);
                    footer.find("#gtr").html(gtr);
                  }}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="history-header" colSpan={3}>
                        6h ago
                      </th>
                      <th className="history-header" colSpan={3}>
                        12h ago
                      </th>
                      <th className="history-header" colSpan={3}>
                        24h ago
                      </th>
                      <th className="history-header" colSpan={3}>
                        48h ago
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Offers</th>
                      <th>
                        <span title="Total offers per API 6 hours ago">
                          &Sigma;
                        </span>
                      </th>
                      <th>
                        <span title="Difference between current total offers and 6 hours ago">
                          &Delta;
                        </span>
                      </th>
                      <th>
                        <span title="Difference percentage between current total offers and 6 hours ago">
                          &Delta;%
                        </span>
                      </th>
                      <th>
                        <span title="Total offers per API 12 hours ago">
                          &Sigma;
                        </span>
                      </th>
                      <th>
                        <span title="Difference between current total offers and 12 hours ago">
                          &Delta;
                        </span>
                      </th>
                      <th>
                        <span title="Difference percentage between current total offers and 12 hours ago">
                          &Delta;%
                        </span>
                      </th>
                      <th>
                        <span title="Total offers per API 24 hours ago">
                          &Sigma;
                        </span>
                      </th>
                      <th>
                        <span title="Difference between current total offers and 24 hours ago">
                          &Delta;
                        </span>
                      </th>
                      <th>
                        <span title="Difference percentage between current total offers and 24 hours ago">
                          &Delta;%
                        </span>
                      </th>
                      <th>
                        <span title="Total offers per API 48 hours ago">
                          &Sigma;
                        </span>
                      </th>
                      <th>
                        <span title="Difference between current total offers and 48 hours ago">
                          &Delta;
                        </span>
                      </th>
                      <th>
                        <span title="Difference percentage between current total offers and 48 hours ago">
                          &Delta;%
                        </span>
                      </th>
                      <th>Pending</th>
                      <th>
                        <span title="Pending offers that have been tested with Affilitest and passed.">
                          Good
                        </span>
                      </th>
                      <th>
                        <span title="Pending offers that have been tested with Affilitest and failed.">
                          Bad
                        </span>
                      </th>
                      <th>{icon_i}</th>
                      <th>{icon_n}</th>
                      <th>{icon_b}</th>
                      <th>{icon_u}</th>
                      <th>Approved</th>
                      <th>{icon_i}</th>
                      <th>{icon_n}</th>
                      <th>{icon_b}</th>
                      <th>{icon_u}</th>
                      <th>Rejected</th>
                      <th>{icon_i}</th>
                      <th>{icon_n}</th>
                      <th>{icon_b}</th>
                      <th>{icon_u}</th>
                      <th>Unique package names</th>
                      <th>Auto-moderation</th>
                      <th>Auto-commission</th>
                      <th>Auto-opt</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tfoot>
                    <tr>
                      <td className="top-footer-row text-right" colSpan={2}>
                        Page Totals
                      </td>
                      <td className="top-footer-row text-right" id="pto"></td>
                      <td className="top-footer-row text-right" id="pto6"></td>
                      <td className="top-footer-row text-right" id="pto6i"></td>
                      <td className="top-footer-row text-right" id="pto6ip"></td>
                      <td className="top-footer-row text-right" id="pto12"></td>
                      <td className="top-footer-row text-right" id="pto12i"></td>
                      <td className="top-footer-row text-right" id="pto12ip"></td>
                      <td className="top-footer-row text-right" id="pto24"></td>
                      <td className="top-footer-row text-right" id="pto24i"></td>
                      <td className="top-footer-row text-right" id="pto24ip"></td>
                      <td className="top-footer-row text-right" id="pto48"></td>
                      <td className="top-footer-row text-right" id="pto48i"></td>
                      <td className="top-footer-row text-right" id="pto48ip"></td>
                      <td className="top-footer-row text-right" id="ptp"></td>
                      <td className="top-footer-row text-right" id="ptpaff1"></td>
                      <td className="top-footer-row text-right" id="ptpaff0"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="top-footer-row text-right" id="pta"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="top-footer-row text-right" id="ptr"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="text-right" colSpan={2}>
                        Table Totals
                      </td>
                      <td className="text-right" id="gto"></td>
                      <td className="text-right" id="gto6"></td>
                      <td className="text-right" id="gto6i"></td>
                      <td className="text-right" id="gto6ip"></td>
                      <td className="text-right" id="gto12"></td>
                      <td className="text-right" id="gto12i"></td>
                      <td className="text-right" id="gto12ip"></td>
                      <td className="text-right" id="gto24"></td>
                      <td className="text-right" id="gto24i"></td>
                      <td className="text-right" id="gto24ip"></td>
                      <td className="text-right" id="gto48"></td>
                      <td className="text-right" id="gto48i"></td>
                      <td className="text-right" id="gto48ip"></td>
                      <td
                        className="text-right bg-warning text-warning"
                        id="gtp"
                      ></td>
                      <td className="text-right" id="gtpaff1"></td>
                      <td className="text-right" id="gtpaff0"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        className="text-right bg-success text-success"
                        id="gta"
                      ></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        className="text-right bg-danger text-danger"
                        id="gtr"
                      ></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </DataTable>
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {showPackageNamesFor && (
          <UniquePackageNamesModal
            id={showPackageNamesFor.id}
            name={showPackageNamesFor.name}
            onHide={() => this.setState({ showPackageNamesFor: null })}
          />
        )}
      </>
    );
  }

  selectHour(hour) {
    this.setState({ selectedHour: hour }, () => {
      const api = this.tableRef.current.api;
      api.columns(".columnset-6").visible(false);
      api.columns(".columnset-12").visible(false);
      api.columns(".columnset-24").visible(false);
      api.columns(".columnset-48").visible(false);
      api.columns(`.columnset-${hour}`).visible(true);
      api.draw();
    });
  }
}
