import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

export default class EditDialog extends React.Component {
  static title = "Edit App";

  constructor(props) {
    super(props);
    this.state = {
      package: props.package||null,
      title: props.title||null,
      osPlatform: props.osPlatform||null,
      exclusions: props.exclusions||[],
      error: null,
      busy: false,
      message: null
    };
    this.handleAppEdit = this.handleAppEdit.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Edit App</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            Please edit the blacklisted app details.
          </p>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>OS Platform</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    min={1}
                    onChange={e =>
                      this.setState({ osPlatform: e.target.value })
                    }
                    defaultValue={this.state.osPlatform}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Package</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    disabled={true}
                    defaultValue={this.state.package}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>App Title</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    min={1}
                    onChange={e =>
                      this.setState({ title: e.target.value })
                    }
                    defaultValue={this.state.title}
                  />
                </FormGroup>
                
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !this.state.package && !this.state.title
            }
            bsStyle="primary"
            onClick={this.handleAppEdit}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleAppEdit() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/reports/editblacklistedapp",
      method: "post",
      data: {
        package: this.state.package,
        title: this.state.title,
        exclusions: this.state.exclusions||null,
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
