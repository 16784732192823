import React from "react";
import moment from "moment-timezone";
import { Row, Col } from "react-bootstrap";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { UserContext } from "lib/user";

export default class Profile extends React.Component {
  static title = "Profile";

  render() {
    return (
      <PageLayout breadcrumbs={["User"]} title={Profile.title}>
        <UserContext.Consumer>
          {user => (
            <Row>
              <Col md={3}>
                <Box>
                  <Box.Header title="User Information" />
                  <Box.Body>
                    <dl>
                      <dt>nameid</dt>
                      <dd>{user.findFirst("nameid")}</dd>
                      <dt>unique_name</dt>
                      <dd>{user.findFirst("unique_name")}</dd>
                      <dt>email</dt>
                      <dd>{user.findFirst("email")}</dd>
                      <dt>apikey</dt>
                      <dl>{user.findFirst("apikey")}</dl>
                    </dl>
                  </Box.Body>
                </Box>
                <Box>
                  <Box.Header title="Roles" />
                  <Box.Body>
                    <ul>
                      {user.roles &&
                        user.roles.map((role, i) => <li key={i}>{role}</li>)}
                    </ul>
                  </Box.Body>
                </Box>
              </Col>
              <Col md={9}>
                <Box>
                  <Box.Header title="JWT Information" />
                  <Box.Body>
                    <dl>
                      {user.tokenIssued && (
                        <>
                          <dt>Issued At</dt>
                          <dd>{user.tokenIssued.toUTCString()}</dd>
                        </>
                      )}
                      {user.tokenExpires && (
                        <>
                          <dt>Expires At</dt>
                          <dd>
                            {user.tokenExpires.toUTCString()}&mdash;
                            <em>{moment(user.tokenExpires).fromNow()}</em>
                          </dd>
                        </>
                      )}
                      <dt>Minimob Bearer</dt>
                      <dd style={{ overflowWrap: "break-word" }}>
                        {user.findFirst("token")}
                      </dd>
                    </dl>
                  </Box.Body>
                </Box>
              </Col>
            </Row>
          )}
        </UserContext.Consumer>
      </PageLayout>
    );
  }
}
