import React from "react";
import moment from "moment-timezone";
import download from "downloadjs";
import axios from "axios";
import { FormGroup, Form, ButtonToolbar } from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { extractFilenameFromResponse, buildGetParams } from "lib/Functions";
import RangeSelectors from "./criteria/RangeSelectors";
import AdvancedSettings from "./criteria/AdvancedSettings";
import ResultTabs from "./tabs/ResultTabs";
import "./styles.css";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

moment.tz.setDefault("UTC");

function getInitialRanges() {
  const thisHour = moment().hour();
  if (thisHour === 0) {
    return {
      L1: moment()
        .subtract(1, "day")
        .startOf("day"),
      L2: moment()
        .subtract(1, "day")
        .endOf("day"),
      R1: moment()
        .subtract(2, "days")
        .startOf("day"),
      R2: moment()
        .subtract(2, "days")
        .endOf("day"),
      H1: 0,
      H2: 24
    };
  } else {
    return {
      L1: moment().startOf("day"),
      L2: moment()
        .startOf("day")
        .endOf("day"),
      R1: moment()
        .subtract(1, "day")
        .startOf("day"),
      R2: moment()
        .subtract(1, "day")
        .endOf("day"),
      H1: 0,
      H2: thisHour
    };
  }
}

export default class SideBySide extends React.Component {
  static title = "Side-by-Side Comparison";

  constructor(props) {
    super(props);
    const { L1, L2, R1, R2, H1, H2 } = getInitialRanges();
    this.state = {
      L1,
      L2,
      R1,
      R2,
      H1,
      H2,
      advertiserIds: [],
      publisherIds: [],
      applicationIds: [],
      campaignIds: [],
      groupByAffiliate: false,
      groupByCampaign: false,
      includeAdopsInfo: true,
      includeLastDays: false,
      showAdvanced: false,
      busy: false,
      error: null,
      response: null
    };
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <PageLayout breadcrumbs={["Reports"]} title={SideBySide.title}>
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={this.state.busy}>
          <Box.Body>
            <RangeSelectors onChange={x => this.setState(x)} {...this.state} />
            <Form inline>
              <FormGroup>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    this.setState(state => ({
                      showAdvanced: !state.showAdvanced
                    }));
                  }}
                >
                  Show Advanced settings&nbsp;
                  <i
                    className={
                      this.state.showAdvanced
                        ? "fa fa-caret-up"
                        : "fa fa-caret-down"
                    }
                  ></i>
                </a>
              </FormGroup>
            </Form>
            <AdvancedSettings
              visible={this.state.showAdvanced}
              onChange={x => this.setState(x)}
              {...this.state}
            />
          </Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <DownloadButton
                onClick={this.handleDownloadOrPreview.bind(this, true)}
              />
              <PreviewButton
                onClick={this.handleDownloadOrPreview.bind(this, false)}
              />
            </ButtonToolbar>
          </Box.Footer>
        </Box>
        {this.state.response && <ResultTabs response={this.state.response} />}
      </PageLayout>
    );
  }

  handleDownloadOrPreview(excel) {
    this.setState({ busy: true, error: null });

    axios({
      url: "/api/v1/reports/sidebyside",
      responseType: excel ? "blob" : "json",
      params: buildGetParams({
        output: excel ? "Excel" : "Resultset",
        leftFromDateInclusiveUtc:
          this.state.L1 &&
          moment(this.state.L1)
            .startOf("day")
            .format("YYYY-MM-DD"),
        leftToDateExclusiveUtc:
          this.state.L2 &&
          moment(this.state.L2)
            .startOf("day")
            .add(1, "day")
            .format("YYYY-MM-DD"),
        rightFromDateInclusiveUtc:
          this.state.R1 &&
          moment(this.state.R1)
            .startOf("day")
            .format("YYYY-MM-DD"),
        rightToDateExclusiveUtc:
          this.state.R2 &&
          moment(this.state.R2)
            .startOf("day")
            .add(1, "day")
            .format("YYYY-MM-DD"),
        fromHour: this.state.H1,
        toHour: this.state.H2,
        advertiserIds: this.state.advertiserIds,
        publisherIds: this.state.publisherIds,
        applicationIds: this.state.applicationIds,
        campaignIds: this.state.campaignIds,
        groupByAffiliate: this.state.groupByAffiliate,
        groupByCampaign: this.state.groupByCampaign,
        includeAdopsInfo: this.state.includeAdopsInfo,
        includeLastDays: this.state.includeLastDays
      })
    })
      .then(res => {
        if (excel) {
          this.setState({
            error: null,
            busy: false
          });
          const filename =
            extractFilenameFromResponse(res) || "SideBySide.xlsx";
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({
            response: res.data,
            error: null,
            busy: false
          });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              busy: false,
              error: e.srcElement.result
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            busy: false,
            error
          });
        }
      });
  }
}
