import React from "react";
import { FormGroup, FormControl, Button } from "react-bootstrap";

function FromTextTab({ onChange }) {
  const [text, setText] = React.useState("");

  return (
    <>
      <p>
        Add offers by providing any multiline text containing hex or dec ids.
        The content will be parsed and only the ids will be used in search.
      </p>
      <FormGroup>
        <FormControl
          componentClass="textarea"
          value={text}
          onChange={e => setText(e.target.value)}
          rows={6}
        />
      </FormGroup>
      <FormGroup>
        <Button bsSize="small" onClick={() => onChange(text)}>
          <i className="fa fa-search"></i> Fetch
        </Button>
      </FormGroup>
    </>
  );
}

export default FromTextTab;
