import React from "react";
import ReactDOMServer from "react-dom/server";
import {
  AdvertiserPicker,
  PublisherPicker,
  ApplicationPicker,
  CampaignPicker
} from "components/pickers/SelectPickers";
import UserPicker from "components/pickers/UserPicker";
import DateTimeRangePicker from "components/pickers/DateTimeRangePicker";
import Hint from "components/Hint";
import moment from "moment-timezone";
import download from "downloadjs";
import buildErrorSummary from "components/ErrorSummary";
import DataTable, { DataTableToolbar } from "components/DataTable";
import axios from "axios";
import { extractFilenameFromResponse } from "lib/Functions";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {
  FormGroup,
  Row,
  Col,
  ControlLabel,
  Button,
  ButtonGroup,
  ButtonToolbar
} from "react-bootstrap";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

const MARKUP_INVALID_SUBID = ReactDOMServer.renderToStaticMarkup(
  <span
    className="text-danger"
    title="This entry is invalid. You should remove it and create a new one, making sure this time to set the Subpublisher to null."
  >
    ZERO-LENGTH STRING
  </span>
);

function renderSubpublisher(d, t, r, m) {
  if (t === "display" && d === "") return MARKUP_INVALID_SUBID;
  return d;
}

export default class BlockingRules extends React.Component {
  static title = "Blocking Rules";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      advertiserId: null,
      applicationIds: [],
      campaignIds: [],
      publisherIds: [],
      subpublisherIds: [],
      moderator: null,
      date: null,
      response: null,
      resultset: null,
      selectedIds: [],
      busy: false,
      error: null
    };
    this.handleRemove = this.handleRemove.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <PageLayout breadcrumbs={["Reports"]} title={BlockingRules.title}>
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={this.state.busy}>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Advertiser</ControlLabel>
                  <AdvertiserPicker
                    className="form-control"
                    placeholder="Enter id or name"
                    onChange={value => this.setState({ advertiserId: value })}
                    includeNetworks={false}
                    includeNetworkUsers={true}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Publishers</ControlLabel>
                  <PublisherPicker
                    className="form-control"
                    multiple
                    placeholder="Enter ids or names"
                    onChange={value => this.setState({ publisherIds: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Encoded Subpublishers</ControlLabel>
                  <textarea
                    className="form-control"
                    placeholder="Enter one id per line"
                    onChange={e =>
                      this.setState({
                        subpublisherIds: e.target.value
                          .split("\n")
                          .filter(x => x !== "")
                      })
                    }
                  ></textarea>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Applications</ControlLabel>
                  <ApplicationPicker
                    className="form-control"
                    multiple
                    placeholder="Enter hex ids"
                    onChange={value => this.setState({ applicationIds: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Campaigns</ControlLabel>
                  <CampaignPicker
                    className="form-control"
                    multiple
                    placeholder="Enter hex ids"
                    onChange={value => this.setState({ campaignIds: value })}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>
                    Moderator &nbsp;
                    <Hint id="about-moderator">
                      <p>
                        The user account who applied the blocking rules. This
                        could be an advertiser or an account manager.
                      </p>
                    </Hint>
                  </ControlLabel>
                  <UserPicker
                    className="form-control"
                    includeAdmins
                    placeholder="Enter id or name"
                    onChange={value => this.setState({ moderator: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Date &amp; Time</ControlLabel>
                  <DateTimeRangePicker
                    className="form-control"
                    opens="left"
                    onChange={value => this.setState({ date: value })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <DownloadButton
                onClick={this.handleDownloadOrPreview.bind(this, true)}
              />
              <PreviewButton
                onClick={this.handleDownloadOrPreview.bind(this, false)}
              />
            </ButtonToolbar>
          </Box.Footer>
        </Box>
        {this.state.resultset && (
          <Box busy={this.state.busy}>
            <Box.Header>
              <DataTableToolbar
                tableRef={this.tableRef}
                bsSize="small"
                noReloadButton
              >
                <ButtonGroup bsSize="small">
                  <Button
                    onClick={this.handleRemove}
                    disabled={this.state.selectedIds.length === 0}
                  >
                    <i className="fa fa-trash-o"></i> Remove Selected
                  </Button>
                </ButtonGroup>
              </DataTableToolbar>
            </Box.Header>
            <Box.Body>
              <DataTable
                className="table table-condensed table-striped"
                ref={this.tableRef}
                data={this.state.resultset}
                columns={[
                  {
                    title: "Advertiser",
                    data: "advertiserId",
                    className: "text-nowrap",
                    render: (d, t, r, m) =>
                      d && this.state.response.userNames[d]
                  },
                  {
                    title: "Publisher",
                    data: "trafficSourceId",
                    className: "text-nowrap",
                    render: (d, t, r, m) =>
                      d && this.state.response.userNames[d]
                  },
                  {
                    title: "Encoded Subpublisher",
                    data: "trafficSourceSubId",
                    render: renderSubpublisher
                  },
                  {
                    title: "Subpublisher",
                    data: "decodedTrafficSourceSubId",
                    defaultContent: ""
                  },
                  {
                    title: "Application",
                    data: "applicationId",
                    className: "truncate-cell-wide",
                    render: (d, t, r, m) =>
                      d && this.state.response.applicationNames[d]
                  },
                  {
                    title: "Campaign",
                    data: "campaignId",
                    className: "truncate-cell-wide",
                    render: (d, t, r, m) =>
                      d && this.state.response.campaignNames[d]
                  },
                  {
                    title: "Moderation Date",
                    data: "moderationDate",
                    className: "text-nowrap text-sm",
                    render: DataTable.StockRenderers.utcDateTime()
                  },
                  {
                    title: "Moderator",
                    data: "moderatorId",
                    className: "truncate-cell text-sm",
                    render: (d, t, r, m) =>
                      d && this.state.response.userNames[d]
                  }
                ]}
                showCheckBoxes
                onSelect={rows =>
                  this.setState({ selectedIds: rows.map(x => x._id) })
                }
              />
            </Box.Body>
          </Box>
        )}
      </PageLayout>
    );
  }

  handleDownloadOrPreview(excel) {
    this.setState({ busy: true, error: null });
    axios({
      url: "/api/v1/tools/blockingrules",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: excel ? "blob" : "json",
      data: {
        advertiserId: this.state.advertiserId,
        applicationIds: this.state.applicationIds,
        campaignIds: this.state.campaignIds,
        publisherIds: this.state.publisherIds,
        subpublisherIds: this.state.subpublisherIds,
        moderatorId: this.state.moderator,
        fromDateTimeInclusiveUtc: this.state.date
          ? moment(this.state.date[0]).format("YYYY-MM-DD HH:mm:ss")
          : null,
        toDateTimeExclusiveUtc: this.state.date
          ? moment(this.state.date[1])
              .add(1, "second")
              .format("YYYY-MM-DD HH:mm:ss")
          : null,
        output: excel ? "Excel" : "Resultset"
      }
    })
      .then(res => {
        if (excel) {
          this.setState({
            error: null,
            busy: false
          });
          const filename =
            extractFilenameFromResponse(res) || "BlockingRules.xlsx";
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({
            busy: false,
            error: null,
            response: res.data,
            resultset: res.data.items
          });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              busy: false,
              error: e.srcElement.result
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            busy: false,
            error,
            response: null,
            resultset: null
          });
        }
      });
  }

  handleRemove() {
    const { selectedIds } = this.state;
    const message =
      selectedIds.length === 1
        ? "Do you want to remove the selected item?"
        : `Do you want to remove the ${selectedIds.length} selected items?`;
    if (!window.confirm(message)) return;

    this.setState({ busy: true });
    axios({
      url: "/api/v1/tools/blockingrules/removebyid",
      method: "post",
      data: selectedIds
    })
      .then(res => this.setState({ busy: false }))
      .catch(error =>
        this.setState({
          busy: false,
          error: error.response.data
        })
      );
  }
}
