import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  Button,
  Row,
  Col, FormGroup, ControlLabel, FormControl
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";

export default class ChangeOrderDialog extends React.Component {
  static title = "Change offer position";

  constructor(props) {
    super(props);
    this.state = {
      offerId: props.offerId||null,
      offerName: props.offerName||null,
      position: props.position||null,
      newPosition: props.position+1||null,
      offers: props.offers||[],
      error: null,
      busy: false,
      message: null,
      formErrors: {},
      formIsValid: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    var offers = this.state.offers;
    return (

      <Modal bsSize="large" show={true} onHide={()=>this.props.onHide(offers)}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Change Offer Position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          
          <Box.Body>
            <Row>
              <Col md={12}>
                <p>
                  Enter the new position for offer:<br/> <strong>"{this.props.offerName}"</strong> with ID: <strong>{this.props.offerId}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={12}>
                <FormGroup className={this.state.formErrors.newPosition?'form-error':null}>
                  <ControlLabel>New Offer position</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    min={1}
                    max={offers.length}
                    defaultValue={parseInt(this.state.newPosition)||null}
                    onChange={e =>{
                      this.setState({newPosition:e.target.value});
                      this.handleInputChange(e,"newPosition");
                      }
                    }
                  />
                  <div className={"error-msg"}>{this.state.formErrors.newPosition||''}</div>
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              (!this.state.newPosition || this.state.formErrors.newPosition)?true:false
            }
            bsStyle="primary"
            onClick={this.handleChange}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleInputChange(e, name){

    var val = e.target.value;
    var formErrors = this.state.formErrors;
    var offers = this.state.offers;

    if(typeof val !== "undefined"){
      
      if(val!==null && parseInt(val)>=1 && parseInt(val)<=offers.length){
        formErrors[name] = null;
      }else{
        formErrors[name] = (val==="" || val===null)?"This field cannot be empty":"Only numbers are allowed. Allowed range: 1 - "+offers.length;
      }

      var newState = { formErrors: formErrors }
      newState[name] = e.target.value;
      this.setState(newState)
    }
  }
  
  handleChange() {

    var data = this.state.offers||[];
    var newPosition = this.state.newPosition;
    var offerId = this.state.offerId;
    var index = null;
    var dataToMove = null;

    if(data.length>0) {
      data.forEach((d, idx) => {
        if (d.offerId === offerId) {
          index = idx;
          dataToMove = d;
        }
      });

      if(index!==null) {
        data.splice(index, 1);
        data.splice(newPosition-1, 0, dataToMove);
      }
    }
      
    this.props.onHide(data);
  }
  
}
