import React from "react";
import ReactDOMServer from "react-dom/server";
import ICON_GOOGLE_PLAY from "assets/storeIcons/google-play.png";
import ICON_APPLE_STORE from "assets/storeIcons/app-store.png";
import ICON_MINIMOB_STORE from "assets/storeIcons/minimob_store_icon.png";
import ICON_TRAFFIC_TYPE_BOTH from "assets/incentivized/both.png";
import ICON_TRAFFIC_TYPE_INCENT from "assets/incentivized/incent.png";
import ICON_TRAFFIC_TYPE_NONINCENT from "assets/incentivized/non-incent.png";
import ICON_TRAFFIC_TYPE_UNCERTAIN from "assets/incentivized/uncertain.png";
import ICON_MODERATION_PENDING from "assets/moderation/moderation_pending.png";
import ICON_MODERATION_AVAILABLE from "assets/moderation/moderation_available.png";
import ICON_MODERATION_REJECTED from "assets/moderation/moderation_rejected.png";
import moment from "moment-timezone";

export function csv() {
  return function(d: Array<any>, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d && d.length > 0) return d.join(",");
    }
    return d;
  };
}

export function csvOrdered() {
  return function(d: Array<any>, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d && d.length > 0) return d.sort().join(",");
    }
    return d;
  };
}

const MARKUP_PLATFORM_ANDROID = ReactDOMServer.renderToStaticMarkup(
  <span className="fa fa-android fa-1_6x light_green" title="Android"></span>
);
const MARKUP_PLATFORM_IOS = ReactDOMServer.renderToStaticMarkup(
  <span className="fa fa-apple fa-1_6x dark_grey" title="iOS"></span>
);
export function platform() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (String(d) === "0" || String(d) === "Android")
        return MARKUP_PLATFORM_ANDROID;
      if (String(d) === "1" || String(d) === "iOS") return MARKUP_PLATFORM_IOS;
    }
    return d;
  };
}

const MARKUP_GOOGLE_PLAY = ReactDOMServer.renderToStaticMarkup(
  <img
    className="store-icon"
    src={ICON_GOOGLE_PLAY}
    alt="Google Play"
    title="Google Play: The official google market store for Android apps."
  />
);
const MARKUP_APPLE_STORE = ReactDOMServer.renderToStaticMarkup(
  <img
    className="store-icon"
    src={ICON_APPLE_STORE}
    alt="Apple iTunes"
    title="Apple iTunes: The official market store for Apple iOS apps."
  />
);
const MARKUP_MINIMOB_STORE = ReactDOMServer.renderToStaticMarkup(
  <img
    className="store-icon"
    src={ICON_MINIMOB_STORE}
    alt="Minimob Store"
    title="Minimob Store: The official market store for Minimob."
  />
);
export function store() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (String(d) === "1") return MARKUP_GOOGLE_PLAY;
      if (String(d) === "2") return MARKUP_APPLE_STORE;
      if (String(d) === "3") return MARKUP_MINIMOB_STORE;
    }
    return d;
  };
}

const MARKUP_TRAFFIC_TYPE_BOTH = ReactDOMServer.renderToStaticMarkup(
  <img
    className="incentivized-icon"
    src={ICON_TRAFFIC_TYPE_BOTH}
    title="Both"
    alt="Both"
  />
);
const MARKUP_TRAFFIC_TYPE_INCENT = ReactDOMServer.renderToStaticMarkup(
  <img
    className="incentivized-icon"
    src={ICON_TRAFFIC_TYPE_INCENT}
    title="Incentivized"
    alt="Incentivized"
  />
);
const MARKUP_TRAFFIC_TYPE_NONINCENT = ReactDOMServer.renderToStaticMarkup(
  <img
    className="incentivized-icon"
    src={ICON_TRAFFIC_TYPE_NONINCENT}
    title="Non-incentivized"
    alt="Non-incentivized"
  />
);
const MARKUP_TRAFFIC_TYPE_UNCERTAIN = ReactDOMServer.renderToStaticMarkup(
  <img
    className="incentivized-icon"
    src={ICON_TRAFFIC_TYPE_UNCERTAIN}
    title="Uncertain"
    alt="Uncertain"
  />
);
export function trafficType() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d === 0) return MARKUP_TRAFFIC_TYPE_BOTH;
      if (d === 1) return MARKUP_TRAFFIC_TYPE_INCENT;
      if (d === 2) return MARKUP_TRAFFIC_TYPE_NONINCENT;
      if (d === 3) return MARKUP_TRAFFIC_TYPE_UNCERTAIN;
    } else if (t === "filter") {
      if (d === 0) return "Both";
      if (d === 1) return "Incentivized";
      if (d === 2) return "Non-incentivized";
      if (d === 3) return "Uncertain";
    }
    return d;
  };
}

const MARKUP_MODERATION_PENDING = ReactDOMServer.renderToStaticMarkup(
  <img
    src={ICON_MODERATION_PENDING}
    alt="Pending Moderation"
    title="Pending Moderation"
  />
);
const MARKUP_MODERATION_AVAILABLE = ReactDOMServer.renderToStaticMarkup(
  <img src={ICON_MODERATION_AVAILABLE} alt="Available" title="Available" />
);
const MARKUP_MODERATION_REJECTED = ReactDOMServer.renderToStaticMarkup(
  <img src={ICON_MODERATION_REJECTED} alt="Rejected" title="Rejected" />
);
export function moderationStatus() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d === 0 || d === "PendingModeration")
        return MARKUP_MODERATION_PENDING;
      if (d === 1 || d === "Available" || d === "Approved")
        return MARKUP_MODERATION_AVAILABLE;
      if (d === 2 || d === "Rejected") return MARKUP_MODERATION_REJECTED;
    }
    return d;
  };
}

const MARKUP_REDIRECTS_GOOD = ReactDOMServer.renderToStaticMarkup(
  <span className="label label-success" title="This offer redirects to store">
    <i className="fa fa-link"></i> Good
  </span>
);
const MARKUP_REDIRECTS_BAD = ReactDOMServer.renderToStaticMarkup(
  <span
    className="label label-danger"
    title="This offer does not redirect to store"
  >
    <i className="fa fa-chain-broken"></i> Bad
  </span>
);
export function redirects() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d === true) return MARKUP_REDIRECTS_GOOD;
      if (d === false) return MARKUP_REDIRECTS_BAD;
    } else if (t === "filter") {
      if (d === true) return "Good";
      if (d === false) return "Bad";
    }
    return d;
  };
}

const MARKUP_RECYCLED = ReactDOMServer.renderToStaticMarkup(
  <span
    className="label label-danger"
    title="This is our own offer and was returned to us by a network"
  >
    <i className="fa fa-recycle"></i> Recycled
  </span>
);
export function recycled() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      return d ? MARKUP_RECYCLED : "";
    } else if (t === "filter") {
      return d ? "Recycled" : "";
    }
    return d;
  };
}

export function textWithTooltip() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (d && t === "display") {
      return `<span title="${d}">${d}</span>`;
    }
    return d;
  };
}

export function responseLookup(propName: string) {
  return function(d: any, t: string, _r: any, m: any) {
    if (t === "display" || t === "sort" || t === "filter") {
      return d && m.settings.json[propName][d];
    }
    return d;
  };
}

export function money() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (d && t === "display") {
      return parseFloat(d).toLocaleString("en-US", {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
    return d;
  };
}

export function count() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (d && t === "display") {
      return parseInt(d).toLocaleString("en-US", {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    }
    return d;
  };
}

export function utcDateTime() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (d && t === "display") {
      return moment.utc(d).format("YYYY-MM-DD HH:mm");
    }
    return d;
  };
}

export function utcDate() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (d && t === "display") {
      return moment.utc(d).format("YYYY-MM-DD");
    }
    return d;
  };
}

export function utcTime() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (d && t === "display") {
      return moment.utc(d).format("HH:mm");
    }
    return d;
  };
}

const MARKUP_SUCCESS = (d: number, digits: number) =>
  ReactDOMServer.renderToStaticMarkup(
    <span className="text-success">
      +
      {d.toLocaleString("en-US", {
        useGrouping: true,
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      })}
    </span>
  );
const MARKUP_DANGER = (d: number, digits: number) =>
  ReactDOMServer.renderToStaticMarkup(
    <span className="text-danger">
      {d.toLocaleString("en-US", {
        useGrouping: true,
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      })}
    </span>
  );

export function countIncrease() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d < 0) return MARKUP_DANGER(parseInt(d), 0);
      if (d > 0) return MARKUP_SUCCESS(parseInt(d), 0);
    }
    if (t === "sort") {
      return d || 0;
    }
    return d;
  };
}

export function moneyIncrease() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d < 0) return MARKUP_DANGER(parseFloat(d), 2);
      if (d > 0) return MARKUP_SUCCESS(parseFloat(d), 2);
    }
    if (t === "sort") {
      return d || 0;
    }
    return d;
  };
}

export function countIncreasePercent() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d < 0) return MARKUP_DANGER(parseFloat(d), 2);
      if (d > 0) return MARKUP_SUCCESS(parseFloat(d), 2);
    }
    if (t === "sort") {
      return d || 0;
    }
    return d;
  };
}

export function moneyIncreasePercent() {
  return function(d: any, t: string, _r: any, _m: any) {
    if (t === "display") {
      if (d < 0) return MARKUP_DANGER(parseFloat(d), 2);
      if (d > 0) return MARKUP_SUCCESS(parseFloat(d), 2);
    }
    if (t === "sort") {
      return d || 0;
    }
    return d;
  };
}

export function first() {
  return function(d: Array<any>, t: string, _r: any, _m: any) {
    if (t === "display" || t === "sort") {
      return d.slice(0, 1);
    }
    return d;
  };
}

export function toggle() {
  return function(d: Array<any>, t: string, _r: any, _m: any) {
    if (t === "display") {
      return ReactDOMServer.renderToStaticMarkup(
        <span title={Boolean(d) ? "Enabled" : "Disabled"}>
          {Boolean(d) ? (
            <i className="fa fa-toggle-on text-primary"></i>
          ) : (
            <i className="fa fa-toggle-off text-muted"></i>
          )}
        </span>
      );
    }
    return d;
  };
}
