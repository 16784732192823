import React from "react";
import { Button, ButtonGroup, Col, FormGroup, Modal, Row, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import DataTable from "components/DataTable";
import { N, NZero } from "lib/Functions";
import $ from "jquery";
import moment from "moment-timezone";
import { UserContext } from "lib/user";
import axios from "axios";
import Chart from "./RtbExchangeDataHistoryModal/Chart";
import { Spinner } from "components/admin-lte";
import DateRangePicker from "components/pickers/DateRangePicker";

import { AgGridReact } from '@ag-grid-community/react'
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-quartz.css';

import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';

import { std, mean } from 'mathjs';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MasterDetailModule,
  MenuModule,
  ColumnsToolPanelModule,
  ClipboardModule,
  ExcelExportModule,
  SetFilterModule,
  RangeSelectionModule,
  RowGroupingModule
]);

function renderResponse(params) {
  const response = {
    true: 'Response',
    false: 'No Response'
  };
  let d;
  if (params.data) {
    d = params.data.response;
  }
  if (params.value) {
    d = params.value;
  }
  if (d !== null) {
    return response[d];
  }
  else
    return '';
}

function renderResponseRate(params) {
  return params.value.value;
}

function renderDynamicBidfloor(params) {
  const marginpercentage_experiment = {
    true: 'Dynamic Bidfloor',
    false: 'Not Dynamic Bidfloor'
  };
  let d;
  if (params.data) {
    d = params.data.marginpercentage_experiment;
  }
  if (params.value) {
    d = params.value;
  }
  if (d !== null) {
    return marginpercentage_experiment[d];
  }
  else
    return '';
}


function renderWin(params) {
  const win = {
    true: 'Win',
    false: 'No Win'
  };
  let d;
  if (params.data) {
    d = params.data.win;
  }
  if (params.value) {
    d = params.value;
  }
  if (d !== null) {
    return win[d];
  }
  else
    return '';
}

function renderInterstitial(params) {
  const response = {
    true: 'Interstitial',
    false: 'Not Interstitial'
  };
  let d;
  if (params.data) {
    d = params.data.interstitial;
  }
  if (params.value) {
    d = params.value;
  }
  if (d !== null) {
    return response[d];
  }
  else
    return '';
}

function renderRewarded(params) {
  const response = {
    true: 'Rewarded',
    false: 'Not Rewarded'
  };
  let d;
  if (params.data) {
    d = params.data.rewarded;
  }
  if (params.value) {
    d = params.value;
  }
  if (d !== null) {
    return response[d];
  }
  else
    return '';
}

function renderBidfloor(params) {
  let d;
  if (params.data) {
    d = params.data.bidfloor;
  }
  if (params.value) {
    d = params.value;
  }
  if (d !== null) {
    return N(d,2);
  }
  else
    return 0.00;
}

function renderDate(d, t, r, m) {
  if (d && (t === "display" || t === "filter")) {
    return moment.utc(d).format("YYYY-MM-DD");
  }
  return d;
}

function renderPartner(d, t, r, m) {
  if (t === "filter") {
    if(d==="Thirdpresence"){
      return "Thirdpresence_";
    }
    return d?d:"";
  }
  return d;
}

function renderLatencyResponse(d, t, r, m) {
  var retVal = d;
  
  if(d!=null){
    var mean = N(d,2);
    var std = r?.latency?.response?.std;
    retVal = mean + (std!==null&&std!==undefined?" ("+N(std,2)+") ms":"");
  }
  if(t === 'display'){
    return (retVal);
  }
  return d;
}

function renderLatencyThrottled(d, t, r, m) {
  var retVal = d;

  if(d!=null){
    var mean = N(d,2);
    var std = r?.latency?.throttled?.std;
    retVal = mean + (std!==null&&std!==undefined?" ("+N(std,2)+") ms":"");
  }
  if(t === 'display'){
    return (retVal);
  }
  return d;
}

function renderLatencyNobid(d, t, r, m) {
  var retVal = d;

  if(d!=null){
    var mean = N(d,2);
    var std = r?.latency?.nobid?.std;
    retVal = mean + (std!==null&&std!==undefined?" ("+N(std,2)+") ms":"");
  }
  if(t === 'display'){
    return (retVal);
  }
  return d;
}

function removeElement(arrayName, arrayElement) {
  let i = arrayName.length
  while (i--) {
    if (arrayName[i] === arrayElement) {
      arrayName.splice(i, 1);
    }
  }
}

export default class DetailReportModal extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.loadHistory = this.loadHistory.bind(this);
    this.loadOsHistory = this.loadOsHistory.bind(this);
    this.colorizeRow = this.colorizeRow.bind(this);
    this.colorizeRow2 = this.colorizeRow2.bind(this);
    this.colorizeRow3 = this.colorizeRow3.bind(this);
    this.toggleHiddenColumn = this.toggleHiddenColumn.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.getSelectedDate = this.getSelectedDate.bind(this);
    
    this.tableRef = React.createRef();
    this.tableRef2 = React.createRef();
    this.tableRef3 = React.createRef();
    this.tableRef4 = React.createRef();
    this.tableRef5 = React.createRef();
    this.tableRef6 = React.createRef();
  }


  getDefaultState() {
      return {
        chartData: null,
        RTData: null,
        reportType: this.props.reportType,
        partner: this.props.partner,
        reportDate: this.props.reportDate,
        selectedDate: [moment(this.props.reportDate),moment(this.props.reportDate)],
        selectedDateFrom: moment(this.props.reportDate),
        selectedDateTo: moment(this.props.reportDate),
        showHistoryFor: null,
        showOsHistory: null,
        showCountryHistoryFor: null,
        showByCountryFor: null,
        showByOsFor: null,
        showBundleHistoryFor: null,
        showByBundleFor: null,
        lastClickedRow: null,
        lastClickedRow2: null,
        lastClickedRow3: null,
        response: [],
        creativeType: [],
        deviceType: [],
        rewarded: [],
        interstitial: [],
        error: null,
        busy: false,
        busy_bundlehistory: false,
        loading: false,
        reportMetric: this.props.reportMetric,
        reportMetricText: this.props.reportMetricText
      };
    }
  
  componentDidMount() {
    this.toggleHiddenColumn(this.tableRef);
    this.selectDate(this.state.selectedDate, true);
  }
  
  render() {
    const {
      partner,
      showHistoryFor,
      showByCountryFor,
      showCountryHistoryFor,
      showByBundleFor,
      showBundleHistoryFor,
      showOsHistoryFor,
      showByOsFor,
      chartData,
      busy,
      reportMetric,
      reportMetricText
    } = this.state;

    const autoSizeStrategy = {
      type: 'fitCellContents'
    };
    //this.tableRef5?.current?.api?.autoSizeColumn('ag-Grid-AutoColumn');
    this.tableRef5?.current?.api?.setColumnWidth('ag-Grid-AutoColumn', 300)
    this.tableRef6?.current?.api?.setColumnWidth('ag-Grid-AutoColumn', 300)

    const byCountryReportBody = () => {
      if (partner) {
        return (
          <>

            <div className={showByCountryFor?"borderTop":"hidden"}>
              <p>By <b>Country</b> report for DSP partner: <b>{showByCountryFor?.dspPartner}</b> - Report date: <b>{showByCountryFor?.reportDate?moment(showByCountryFor?.reportDate).format("YYYY-MM-DD"):""}</b></p>
              <br />
              <DataTable.Toolbar tableRef={this.tableRef2} bsSize="small" noReloadButton="true" />
              <DataTable
                ref={this.tableRef2}
                className="table table-condensed table-striped table-hover table-small-th-font table-overflow-y"
                data={showByCountryFor?.reportData||[]}
                onUserCommand={({ commandData }) =>{
                  if(commandData.action === "showCountryHistory"){
                    this.setState({
                      showCountryHistoryFor: commandData,
                      showHistoryFor: null,
                      showByOsFor: null,
                      showOsHistoryFor: null,
                      lastClickedRow2: commandData.row
                    });

                    this.loadCountryHistory(commandData.reportDate, commandData.dspPartnerId, commandData.reportMetric, commandData.country);
                  }
                  else if(commandData.action === "showByOs"){
                    this.setState({
                      showByOsFor: commandData,
                      showHistoryFor: null,
                      showOsHistoryFor: null,
                      showCountryHistoryFor: null,
                      lastClickedRow2: commandData.row
                    }, ()=> this.toggleHiddenColumn(this.tableRef3));
                  }
                  
                  if(commandData.row!==null){
                    this.colorizeRow2(commandData.row);
                  }
                  
                }}
                columns={[
                  {
                    title: "Country",
                    data: "country",
                    defaultContent: "",
                    width: "70px",
                  },
                  DataTable.createUserCommandColumn({
                    title: "RTB Cost",
                    data: "rtbCost",
                    className: "text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showCountryHistory',
                        row: row,
                        id: rowData._id,
                        type: 'country',
                        reportDate: this.state.showByCountryFor?.reportDate,
                        dspPartner: this.state.showByCountryFor?.dspPartner,
                        dspPartnerId: this.state.showByCountryFor?.dspPartnerId,
                        country: rowData.country,
                        partner: rowData.partner,
                        reportMetric: "rtbCost",
                        reportMetricText: "RTB Cost",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "RTB Requests",
                    data: "rtbRequests",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showCountryHistory',
                        row: row,
                        id: rowData._id,
                        type: 'country',
                        reportDate: this.state.showByCountryFor?.reportDate,
                        dspPartner: this.state.showByCountryFor?.dspPartner,
                        dspPartnerId: this.state.showByCountryFor?.dspPartnerId,
                        country: rowData.country,
                        partner: rowData.partner,
                        reportMetric: "rtbRequests",
                        reportMetricText: "RTB Requests",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Impressions",
                    data: "impressions",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showCountryHistory',
                        row: row,
                        id: rowData._id,
                        type: 'country',
                        reportDate: this.state.showByCountryFor?.reportDate,
                        dspPartner: this.state.showByCountryFor?.dspPartner,
                        dspPartnerId: this.state.showByCountryFor?.dspPartnerId,
                        country: rowData.country,
                        partner: rowData.partner,
                        reportMetric: "impressions",
                        reportMetricText: "Impressions",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Wins",
                    data: "wins",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showCountryHistory',
                        row: row,
                        id: rowData._id,
                        type: 'country',
                        reportDate: this.state.showByCountryFor?.reportDate,
                        dspPartner: this.state.showByCountryFor?.dspPartner,
                        dspPartnerId: this.state.showByCountryFor?.dspPartnerId,
                        country: rowData.country,
                        partner: rowData.partner,
                        reportMetric: "wins",
                        reportMetricText: "Wins",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "QPS",
                    data: "qps",
                    className: "text-right",
                    width: "8%",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showCountryHistory',
                        row: row,
                        id: rowData._id,
                        type: 'country',
                        reportDate: this.state.showByCountryFor?.reportDate,
                        dspPartner: this.state.showByCountryFor?.dspPartner,
                        dspPartnerId: this.state.showByCountryFor?.dspPartnerId,
                        country: rowData.country,
                        partner: rowData.partner,
                        reportMetric: "qps",
                        reportMetricText: "QPS",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Revenue Exchange",
                    data: "revenueExchange",
                    className: "revenue-exchange-column text-right",
                    width: "140px",
                    defaultContent: "",
                    visible: false,
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showCountryHistory',
                        row: row,
                        id: rowData._id,
                        type: 'country',
                        reportDate: this.state.showByCountryFor?.reportDate,
                        dspPartner: this.state.showByCountryFor?.dspPartner,
                        dspPartnerId: this.state.showByCountryFor?.dspPartnerId,
                        country: rowData.country,
                        partner: rowData.partner,
                        reportMetric: "revenueExchange",
                        reportMetricText: "Revenue Exchange",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "",
                    data: null,
                    className: "text-right",
                    orderable: false,
                    defultContent: "",
                    width: "50px",
                    commandName: "",
                    commandData: ({ rowData, row }) => ({
                      action: 'showByOs',
                      row: row,
                      id: rowData._id,
                      reportData: rowData?.perOS||[],
                      type: 'os',
                      reportDate: this.state.showByCountryFor?.reportDate,
                      dspPartner: this.state.showByCountryFor?.dspPartner,
                      dspPartnerId: this.state.showByCountryFor?.dspPartnerId,
                      partner: rowData.partner,
                      country: rowData.country,

                    }),
                    render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> By OS </button></div>"),
                  }),
                ]}
                order={[[1, "desc"]]}
                footerCallback={(tfoot, data, start, end, display) => {
                  const gt = {
                    rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                    rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                    impressions: display.reduce((acc, cur) => (acc += data[cur].impressions), 0),
                    wins: display.reduce((acc, cur) => (acc += data[cur].wins), 0),
                    revenueExchange: display.reduce((acc, cur) => (acc += data[cur].revenueExchange), 0),
                  };
                  const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                  const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                  const footer = $(tfoot).parent();

                  // Table totals
                  footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                  footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                  footer.find("#gtimpressions").html(renderCount(gt.impressions));
                  footer.find("#gtwins").html(renderCount(gt.wins));
                  footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                }}
              >
                <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                <tr>
                  <td className="text-right">
                    Totals
                  </td>
                  <td className="text-right" id="gtrtbCost">0</td>
                  <td className="text-right" id="gtrtbRequests">0</td>
                  <td className="text-right" id="gtimpressions">0</td>
                  <td className="text-right" id="gtwins">0</td>
                  <td></td>
                  <td className="text-right" id="gtrevenueExchange">0</td>
                  <td></td>
                </tr>
                </tfoot>
              </DataTable>
            </div>
          </>
        );
      }
    };
    
    const byOsReportBody = () => {
      if (partner) {
        return (
          <>
            
          <div className={showByOsFor?"borderTop":"hidden"}>
            <p>By <b>OS</b> report for country: <b>{showByOsFor?.country}</b> - Report date: <b>{showByOsFor?.reportDate?moment(showByOsFor?.reportDate).format("YYYY-MM-DD"):""}</b></p>
              <br />
              <DataTable.Toolbar tableRef={this.tableRef2} bsSize="small" noReloadButton="true" />
              <DataTable
                ref={this.tableRef3}
                className="table table-condensed table-striped table-hover table-small-th-font"
                data={showByOsFor?.reportData||[]}
                onUserCommand={({ commandData }) =>{
                  if(commandData.action === "showOsHistory"){
                    this.setState({ 
                      showOsHistoryFor: commandData, 
                      showHistoryFor: null, 
                      showCountryHistoryFor: null, 
                      lastClickedRow3: commandData.row
                    });
                    
                    this.loadOsHistory(commandData.reportDate, commandData.dspPartnerId, commandData.reportMetric, commandData.country, commandData.os);

                    if(commandData.row!==null){
                      this.colorizeRow3(commandData.row);
                    }
                  }
                }}
                columns={[
                  {
                    title: "OS",
                    data: "os",
                    defaultContent: "",
                    width: "70px",
                  },
                  DataTable.createUserCommandColumn({
                    title: "RTB Cost",
                    data: "rtbCost",
                    className: "text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showOsHistory',
                        row: row,
                        id: rowData._id,
                        type: 'os',
                        reportDate: this.state.showByOsFor?.reportDate,
                        dspPartner: this.state.showByOsFor?.dspPartner,
                        dspPartnerId: this.state.showByOsFor?.dspPartnerId,
                        country: this.state.showByOsFor?.country,
                        os: rowData.os,
                        partner: rowData.partner,
                        reportMetric: "rtbCost",
                        reportMetricText: "RTB Cost",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "RTB Requests",
                    data: "rtbRequests",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showOsHistory',
                        row: row,
                        id: rowData._id,
                        type: 'os',
                        reportDate: this.state.showByOsFor?.reportDate,
                        dspPartner: this.state.showByOsFor?.dspPartner,
                        dspPartnerId: this.state.showByOsFor?.dspPartnerId,
                        country: this.state.showByOsFor?.country,
                        os: rowData.os,
                        partner: rowData.partner,
                        reportMetric: "rtbRequests",
                        reportMetricText: "RTB Requests",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Impressions",
                    data: "impressions",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showOsHistory',
                        row: row,
                        id: rowData._id,
                        type: 'os',
                        reportDate: this.state.showByOsFor?.reportDate,
                        dspPartner: this.state.showByOsFor?.dspPartner,
                        dspPartnerId: this.state.showByOsFor?.dspPartnerId,
                        country: this.state.showByOsFor?.country,
                        os: rowData.os,
                        partner: rowData.partner,
                        reportMetric: "impressions",
                        reportMetricText: "Impressions",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Wins",
                    data: "wins",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showOsHistory',
                        row: row,
                        id: rowData._id,
                        type: 'os',
                        reportDate: this.state.showByOsFor?.reportDate,
                        dspPartner: this.state.showByOsFor?.dspPartner,
                        dspPartnerId: this.state.showByOsFor?.dspPartnerId,
                        country: this.state.showByOsFor?.country,
                        os: rowData.os,
                        partner: rowData.partner,
                        reportMetric: "wins",
                        reportMetricText: "Wins",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "QPS",
                    data: "qps",
                    className: "text-right",
                    width: "8%",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showOsHistory',
                        row: row,
                        id: rowData._id,
                        type: 'os',
                        reportDate: this.state.showByOsFor?.reportDate,
                        dspPartner: this.state.showByOsFor?.dspPartner,
                        dspPartnerId: this.state.showByOsFor?.dspPartnerId,
                        country: this.state.showByOsFor?.country,
                        os: rowData.os,
                        partner: rowData.partner,
                        reportMetric: "qps",
                        reportMetricText: "QPS",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Revenue Exchange",
                    data: "revenueExchange",
                    className: "revenue-exchange-column text-right",
                    width:"140px",
                    defaultContent: "",
                    visible: false,
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showOsHistory',
                        row: row,
                        id: rowData._id,
                        type: 'os',
                        reportDate: this.state.showByOsFor?.reportDate,
                        dspPartner: this.state.showByOsFor?.dspPartner,
                        dspPartnerId: this.state.showByOsFor?.dspPartnerId,
                        country: this.state.showByOsFor?.country,
                        os: rowData.os,
                        partner: rowData.partner,
                        reportMetric: "revenueExchange",
                        reportMetricText: "Revenue Exchange",
                      })
                    }
                  }),
                ]}
                order={[[1, "desc"]]}
                footerCallback={(tfoot, data, start, end, display) => {
                  const gt = {
                    rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                    rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                    impressions: display.reduce((acc, cur) => (acc += data[cur].impressions), 0),
                    wins: display.reduce((acc, cur) => (acc += data[cur].wins), 0),
                    revenueExchange: display.reduce((acc, cur) => (acc += data[cur].revenueExchange), 0),
                  };
                  const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                  const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                  const footer = $(tfoot).parent();

                  // Table totals
                  footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                  footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                  footer.find("#gtimpressions").html(renderCount(gt.impressions));
                  footer.find("#gtwins").html(renderCount(gt.wins));
                  footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                }}
              >
                <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                <tr>
                  <td className="text-right">
                    Totals
                  </td>
                  <td className="text-right" id="gtrtbCost">0</td>
                  <td className="text-right" id="gtrtbRequests">0</td>
                  <td className="text-right" id="gtimpressions">0</td>
                  <td className="text-right" id="gtwins">0</td>
                  <td></td>
                  <td className="text-right" id="gtrevenueExchange">0</td>
                </tr>
                </tfoot>
              </DataTable>
            </div>
          </>
        );
      }
    };

    const byBundleReportBody = () => {
      if (partner) {
        return (
          <>

            <div className={showByBundleFor ? "borderTop" : "hidden"}>
              <p>By <b>Bundle</b> report for DSP partner: <b>{showByBundleFor?.dspPartner}</b> - Report date: <b>{showByBundleFor?.reportDate ? moment(showByBundleFor?.reportDate).format("YYYY-MM-DD") : ""}</b></p>
              <br />
              <DataTable.Toolbar tableRef={this.tableRef2} bsSize="small" noReloadButton="true"/>
              <DataTable
                ref={this.tableRef2}
                className="table table-condensed table-striped table-hover table-small-th-font table-overflow-y"
                data={showByBundleFor?.reportData || []}
                onUserCommand={({ commandData }) => {
                  if (commandData.action === "showBundleHistory") {
                    this.setState({
                      showBundleHistoryFor: commandData,
                      showCountryHistoryFor: null,
                      showHistoryFor: null,
                      showByOsFor: null,
                      showOsHistoryFor: null,
                      lastClickedRow2: commandData.row,
                      creativeType: [],
                      deviceType: [],
                      rewarded: [],
                      interstitial: [],
                      response: ["true"]
                    });

                    this.loadBundleHistory(commandData.reportDate, commandData.dspPartnerId, commandData.reportMetric, commandData.bundle);
                    this.tableRef4?.current?.reload();

                    //this.loadBundleRT(commandData.dspPartnerId, commandData.reportMetric, commandData.bundle);
                  }

                  if (commandData.row !== null) {
                    this.colorizeRow2(commandData.row);
                  }

                }}
                columns={[
                  {
                    title: "Bundle",
                    data: "bundle",
                    defaultContent: "",
                    width: "70px",
                  },
                  DataTable.createUserCommandColumn({
                    title: "RTB Cost",
                    data: "rtbCost",
                    className: "text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showBundleHistory',
                        row: row,
                        id: rowData._id,
                        type: 'bundle',
                        reportDate: this.state.showByBundleFor?.reportDate,
                        dspPartner: this.state.showByBundleFor?.dspPartner,
                        dspPartnerId: this.state.showByBundleFor?.dspPartnerId,
                        bundle: rowData.bundle,
                        partner: rowData.partner,
                        reportMetric: "rtbCost",
                        reportMetricText: "RTB Cost",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "RTB Requests",
                    data: "rtbRequests",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showBundleHistory',
                        row: row,
                        id: rowData._id,
                        type: 'bundle',
                        reportDate: this.state.showByBundleFor?.reportDate,
                        dspPartner: this.state.showByBundleFor?.dspPartner,
                        dspPartnerId: this.state.showByBundleFor?.dspPartnerId,
                        bundle: rowData.bundle,
                        partner: rowData.partner,
                        reportMetric: "rtbRequests",
                        reportMetricText: "RTB Requests",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "RTB Responses",
                    data: "responses",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showBundleHistory',
                        row: row,
                        id: rowData._id,
                        type: 'bundle',
                        reportDate: this.state.showByBundleFor?.reportDate,
                        dspPartner: this.state.showByBundleFor?.dspPartner,
                        dspPartnerId: this.state.showByBundleFor?.dspPartnerId,
                        bundle: rowData.bundle,
                        partner: rowData.partner,
                        reportMetric: "responses",
                        reportMetricText: "RTB Responses",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Impressions",
                    data: "impressions",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showBundleHistory',
                        row: row,
                        id: rowData._id,
                        type: 'bundle',
                        reportDate: this.state.showByBundleFor?.reportDate,
                        dspPartner: this.state.showByBundleFor?.dspPartner,
                        dspPartnerId: this.state.showByBundleFor?.dspPartnerId,
                        bundle: rowData.bundle,
                        partner: rowData.partner,
                        reportMetric: "impressions",
                        reportMetricText: "Impressions",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Wins",
                    data: "wins",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showBundleHistory',
                        row: row,
                        id: rowData._id,
                        type: 'bundle',
                        reportDate: this.state.showByBundleFor?.reportDate,
                        dspPartner: this.state.showByBundleFor?.dspPartner,
                        dspPartnerId: this.state.showByBundleFor?.dspPartnerId,
                        bundle: rowData.bundle,
                        partner: rowData.partner,
                        reportMetric: "wins",
                        reportMetricText: "Wins",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Revenue Exchange",
                    data: "revenueExchange",
                    className: "revenue-exchange-column text-right",
                    width: "140px",
                    defaultContent: "",
                    visible: false,
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showBundleHistory',
                        row: row,
                        id: rowData._id,
                        type: 'bundle',
                        reportDate: this.state.showByBundleFor?.reportDate,
                        dspPartner: this.state.showByBundleFor?.dspPartner,
                        dspPartnerId: this.state.showByBundleFor?.dspPartnerId,
                        bundle: rowData.bundle,
                        partner: rowData.partner,
                        reportMetric: "revenueExchange",
                        reportMetricText: "Revenue Exchange",
                      })
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "rpMillion",
                    data: "rpMillion",
                    className: "revenue-exchange-column text-right",
                    width: "140px",
                    defaultContent: "",
                    visible: false,
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showBundleHistory',
                        row: row,
                        id: rowData._id,
                        type: 'bundle',
                        reportDate: this.state.showByBundleFor?.reportDate,
                        dspPartner: this.state.showByBundleFor?.dspPartner,
                        dspPartnerId: this.state.showByBundleFor?.dspPartnerId,
                        bundle: rowData.bundle,
                        partner: rowData.partner,
                        reportMetric: "rpMillion",
                        reportMetricText: "rpMillion",
                      })
                    }
                  }),
                  /*
                  DataTable.createUserCommandColumn({
                    title: "",
                    data: "bidfloor",
                    className: "revenue-exchange-column text-left",
                    orderable: false,
                    width: "140px",
                    defaultContent: "",
                    visible: false,
                    //render: (d, t, r, m) => NZero(d, 2),
                    render: (d, t) => ("<button type='button' class='btn btn-default btn-small' " + ((this.state.showByBundleFor?.dspPartner === 'Yandex-Adspin') ? '' :'disabled')+"> RT Report</button>"),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showBundleHistory',
                        row: row,
                        id: rowData._id,
                        type: 'bundle',
                        reportDate: this.state.showByBundleFor?.reportDate,
                        dspPartner: this.state.showByBundleFor?.dspPartner,
                        dspPartnerId: this.state.showByBundleFor?.dspPartnerId,
                        bundle: rowData.bundle,
                        partner: rowData.partner,
                        reportMetric: "bidfloor",
                        reportMetricText: "bidfloor",
                      })
                    }
                  }),
                  */
                ]}
                order={[[1, "desc"]]}
                footerCallback={(tfoot, data, start, end, display) => {
                  const gt = {
                    rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                    rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                    responses: display.reduce((acc, cur) => (acc += data[cur].responses), 0),
                    impressions: display.reduce((acc, cur) => (acc += data[cur].impressions), 0),
                    wins: display.reduce((acc, cur) => (acc += data[cur].wins), 0),
                    revenueExchange: display.reduce((acc, cur) => (acc += data[cur].revenueExchange), 0),
                  };
                  const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                  const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                  const footer = $(tfoot).parent();

                  // Table totals
                  footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                  footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                  footer.find("#gtresponses").html(renderCount(gt.responses));
                  footer.find("#gtimpressions").html(renderCount(gt.impressions));
                  footer.find("#gtwins").html(renderCount(gt.wins));
                  footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <td className="text-right">
                      Totals
                    </td>
                    <td className="text-right" id="gtrtbCost">0</td>
                    <td className="text-right" id="gtrtbRequests">0</td>
                    <td className="text-right" id="gtresponses">0</td>
                    <td className="text-right" id="gtimpressions">0</td>
                    <td className="text-right" id="gtwins">0</td>
                    <td className="text-right" id="gtrevenueExchange">0</td>
                    <td></td>
                  </tr>
                </tfoot>
              </DataTable>
            </div>
          </>
        );
      }
    };

    const chartBody = () => {
      if (partner) {
        return (
          <div className="borderTop">
            {showHistoryFor && (
              <p><b>{showHistoryFor.reportMetricText}</b> per minute for DSP partner: <b>{showHistoryFor.dspPartner}</b>  - Report date: <b>{moment(showHistoryFor.reportDate).format("YYYY-MM-DD")}</b></p>
            )}
            {showCountryHistoryFor && (
              <p><b>{showCountryHistoryFor.reportMetricText}</b> per minute for DSP
                partner: <b>{showCountryHistoryFor.dspPartner}</b> and country: <b>{showCountryHistoryFor.country}</b> - Report date: <b>{moment(showCountryHistoryFor.reportDate).format("YYYY-MM-DD")}</b>
              </p>
            )}
            {(showBundleHistoryFor && (showBundleHistoryFor?.reportMetric !== 'bidfloor')) && (
              <p><b>{showBundleHistoryFor.reportMetricText}</b> per minute for DSP
                partner: <b>{showBundleHistoryFor.dspPartner}</b> and bundle: <b>{showBundleHistoryFor.bundle}</b> - Report date: <b>{moment(showBundleHistoryFor.reportDate).format("YYYY-MM-DD")}</b>
              </p>
            )}
            {(showBundleHistoryFor && (showBundleHistoryFor?.reportMetric === 'bidfloor')) && (
              <p><b>RT Report</b> for DSP
                partner: <b>{showBundleHistoryFor.dspPartner}</b> and bundle: <b>{showBundleHistoryFor.bundle}</b> - Report date: <b>{moment(showBundleHistoryFor.reportDate).format("YYYY-MM-DD")}</b>
              </p>
            )}
            {showOsHistoryFor && (
              <p><b>{showOsHistoryFor.reportMetricText}</b> per minute for DSP partner: <b>{showOsHistoryFor.dspPartner}</b>,
                country: <b>{showOsHistoryFor.country}</b> and OS: <b>{showOsHistoryFor.os}</b> - Report date: <b>{moment(showOsHistoryFor.reportDate).format("YYYY-MM-DD")}</b></p>
            )}
            {chartData && (
              <div className="borderTop">
                {(showBundleHistoryFor && ((showBundleHistoryFor?.reportMetric === 'bidfloor'))) && (
                  <div>
                  <div
                    className="ag-theme-quartz"
                    style={{
                      width: '100%',
                      height: '550px'
                    }}
                  >
                    <Button
                      bsSize="small"
                      onClick={() => {
                        this.loadBundleRT(showBundleHistoryFor?.dspPartnerId, showBundleHistoryFor?.reportMetric, showBundleHistoryFor?.bundle);
                      }}
                      disabled={this.state.busy}
                    ><i className={this.state.busy ? "fa fa-refresh fa-spin" : "fa fa-refresh"}></i> Reload</Button><br /><br />
                  <AgGridReact
                      id="masterGrid"
                      columnMenu={'new'}
                      ref={this.tableRef5}
                      autoSizeStrategy={autoSizeStrategy}
                      pivotMode={true}
                      aggFuncs={{
                        'sd': params => {
                          removeElement(params.values, 0);
                          return N(std((params && params.values && params.values.length >= 2 ? params.values : [0])), 2);
                        },
                        'mean': params => {
                            removeElement(params.values, 0);
                            return N(mean(((params && params.values && params.values.length) >= 2 ? params.values : [0])), 2);
                        },
                      }}
                      columnDefs={[
                        { flex: 1, headerName: 'Type', field: 'creativeType', hide: true, rowGroup: true, enableRowGroup: true, enablePivot: true },
                        { flex: 1, headerName: 'Dimensions', field: 'dimensions', hide: true, rowGroup: true,enableRowGroup: true, enablePivot: true },
                        { flex: 1, headerName: 'Interstitial', field: 'interstitial', enableRowGroup: true, enablePivot: true, valueGetter: renderInterstitial },
                        { flex: 1, headerName: 'Rewarded', field: 'rewarded',  enableRowGroup: true, enablePivot: true, valueGetter: renderRewarded },
                        { flex: 1, headerName: 'Response', field: 'response', rowGroup: true, enableRowGroup: true, enablePivot: true, valueGetter: renderResponse, },
                        { flex: 1, headerName: 'Win', field: 'win', rowGroup: true, enableRowGroup: true, enablePivot: true, valueGetter: renderWin, },
                        { flex: 1, headerName: 'Bidfloor', field: 'bidfloor', enablePivot: true, aggFunc: 'count' },
                        { flex: 1, headerName: 'Bidfloor', field: 'bidfloor', enablePivot: true, aggFunc: 'mean' },
                        { flex: 1, headerName: 'Bidfloor', field: 'bidfloor', enablePivot: true, aggFunc: 'sd' },
                        { flex: 1, headerName: 'Price', field: 'price', enablePivot: true, aggFunc: 'mean' },
                        { flex: 1, headerName: 'Price', field: 'price', enablePivot: true, aggFunc: 'sd' },
                        { flex: 1, headerName: 'Win Price', field: 'win_price', enablePivot: true, aggFunc: 'mean' },
                        { flex: 1, headerName: 'Win Price', field: 'win_price', enablePivot: true, aggFunc: 'sd' }
                      ]}
                      rowData={(this.state.RTData && this.state.RTData.length > 0) ? this.state.RTData : []}
                      style={{ width: '100%', height: '30vh' }}
                      overlayLoadingTemplate={'<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>'}
                      overlayNoRowsTemplate={'<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>'}
                      sideBar={"columns"}
                      groupDisplayType={'groupRows'}
                  >
                    </AgGridReact>
                    </div>
                  <div
                    className="ag-theme-quartz"
                    style={{
                      width: '100%',
                      height: '550px'
                    }}
                    ><br /><br /><br /><br />
                    <Button
                      bsSize="small"
                      onClick={() => {
                        this.loadBundleRT(showBundleHistoryFor?.dspPartnerId, showBundleHistoryFor?.reportMetric, showBundleHistoryFor?.bundle);
                      }}
                      disabled={this.state.busy}
                    ><i className={this.state.busy ? "fa fa-refresh fa-spin" : "fa fa-refresh"}></i> Reload</Button><br /><br />
                      <AgGridReact
                        style={{ width: '100%', height: '550px' }}
                      id="masterGrid"
                      columnMenu={'new'}
                      ref={this.tableRef6}
                      autoSizeStrategy={autoSizeStrategy}
                      pivotMode={true}
                      aggFuncs={{
                        'sd': params => {
                          removeElement(params.values, 0);
                          return N(std((params && params.values && params.values.length >= 2 ? params.values : [0])), 2);
                        },
                        'rate': params => {
                          let sum = 0;
                          let count = 0;

                          params.values.forEach((value) => {
                            const groupNode = (value !== null) && (value !== undefined) && (typeof value === 'object');
                            if (groupNode) {
                              sum += value.sum;
                              count += value.count;
                            } else {
                              if (typeof value === 'boolean') {
                                if (value) {
                                  sum += 1;
                                }
                                count++;
                              }
                            }
                          });

                          // avoid divide by zero error
                          let rate = null;
                          if (count !== 0) {
                            rate = N((Math.round((((sum / count) * 100)) * 10000000) / 10000000), 2).toString() + '%';
                          }
                          // the result will be an object. when this cell is rendered, only the avg is shown.
                          // however when this cell is part of another aggregation, the count is also needed
                          // to create a weighted average for the next level.
                          const result = {
                            sum: sum,
                            count: count,
                            value: rate,
                          };

                          return result;
                        },
                      }}
                      columnDefs={[
                        { flex: 1, headerName: 'Type', field: 'creativeType', hide: true, rowGroup: true, enableRowGroup: true, enablePivot: true },
                        { flex: 1, headerName: 'Dimensions', field: 'dimensions', hide: true, rowGroup: true, enableRowGroup: true, enablePivot: true },
                        { flex: 1, headerName: 'Dynamic Bidfloor', field: 'marginpercentage_experiment', hide: true, rowGroup: true, enableRowGroup: true, enablePivot: true, valueGetter: renderDynamicBidfloor, },
                        { flex: 1, headerName: 'Marginpercentage', field: 'marginpercentage', hide: true, rowGroup: true, enableRowGroup: true, enablePivot: true,  },
                        { flex: 1, headerName: 'Interstitial', field: 'interstitial', enableRowGroup: true, enablePivot: true, valueGetter: renderInterstitial },
                        { flex: 1, headerName: 'Rewarded', field: 'rewarded', enableRowGroup: true, enablePivot: true, valueGetter: renderRewarded },
                        //{ flex: 1, headerName: 'Response', field: 'response', rowGroup: true, enableRowGroup: true, enablePivot: true, valueGetter: renderResponse, },
                        //{ flex: 1, headerName: 'Win', field: 'win', rowGroup: true, enableRowGroup: true, enablePivot: true, valueGetter: renderWin, },
                        { flex: 1, headerName: 'Responses', field: 'response', enablePivot: true, aggFunc: 'rate', enableValue: true, cellDataType: 'text', valueFormatter: renderResponseRate },
                      ]}
                      rowData={(this.state.RTData && this.state.RTData.length > 0) ? this.state.RTData : []}
                      style={{ width: '100%', height: '30vh' }}
                      overlayLoadingTemplate={'<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>'}
                      overlayNoRowsTemplate={'<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>'}
                        groupDisplayType={'groupRows'}
                        //sideBar={"columns"}
                    >
                    </AgGridReact>
                    </div>
                    </div>                    
                )}
                {(showBundleHistoryFor && ((showBundleHistoryFor?.reportMetric === 'bidfloor'))) && (
                  <div>
                    <br /><br /><br /><br /><br /> <br />
                    <Button
                      bsSize="small"
                      onClick={() => {
                        this.loadBundleHistoryFilter({                          
                          response: this.state.response,
                          creativeType: this.state.creativeType,
                          deviceType: this.state.deviceType,
                          rewarded: this.state.rewarded,
                          interstitial: this.state.interstitial
                        });
                      }}
                      disabled={this.state.busy_bundlehistory}
                    ><i className={this.state.busy_bundlehistory ? "fa fa-refresh fa-spin" : "fa fa-refresh"}></i> Reload</Button><br/>
                    Filters:<br />
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Response"
                      type="checkbox"
                      value={this.state.response}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ response: e });
                          this.loadBundleHistoryFilter({ response: e });
                        }
                        else {
                          var prevState = this.state.response;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ response: value });
                          //this.selectIssuerToggle(value);
                          this.loadBundleHistoryFilter({ response: value });
                        }
                      }}
                    >
                      <ToggleButton value={"true"}>Response</ToggleButton>
                      <ToggleButton value={"false"}>No Response</ToggleButton>
                    </ToggleButtonGroup>&nbsp;&nbsp;
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Creative Type"
                      type="checkbox"
                      value={this.state.creativeType}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ creativeType: e });
                          this.loadBundleHistoryFilter({ creativeType: e });
                        }
                        else {
                          var prevState = this.state.creativeType;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ creativeType: value });
                          //this.selectIssuerToggle(value);
                          this.loadBundleHistoryFilter({ creativeType: value });
                        }
                      }}
                    >
                      <ToggleButton value={"banner"}>Banner</ToggleButton>
                      <ToggleButton value={"video"}>Video</ToggleButton>
                    </ToggleButtonGroup>&nbsp;&nbsp;
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Device Type"
                      type="checkbox"
                      value={this.state.deviceType}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ deviceType: e });
                          this.loadBundleHistoryFilter({ deviceType: e });
                        }
                        else {
                          var prevState = this.state.deviceType;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ deviceType: value });
                          //this.selectIssuerToggle(value);
                          this.loadBundleHistoryFilter({ deviceType: value });;
                        }
                      }}
                    >
                      <ToggleButton value={4}>Phone</ToggleButton>
                      <ToggleButton value={5}>Tablet</ToggleButton>
                    </ToggleButtonGroup>&nbsp;&nbsp;
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Rewarded"
                      type="checkbox"
                      value={this.state.rewarded}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ rewarded: e });
                          this.loadBundleHistoryFilter({ rewarded: e });
                        }
                        else {
                          var prevState = this.state.rewarded;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ rewarded: value });
                          //this.selectIssuerToggle(value);
                          this.loadBundleHistoryFilter({ rewarded: value });;
                        }
                      }}
                    >
                      <ToggleButton value={true}>Rewarded</ToggleButton>
                      <ToggleButton value={false}>Not Rewarded</ToggleButton>
                    </ToggleButtonGroup>&nbsp;&nbsp;
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Interstitial"
                      type="checkbox"
                      value={this.state.interstitial}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ interstitial: e });
                          this.loadBundleHistoryFilter({ interstitial: e });
                        }
                        else {
                          var prevState = this.state.interstitial;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ interstitial: value });
                          //this.selectIssuerToggle(value);
                          this.loadBundleHistoryFilter({ interstitial: value });;
                        }
                      }}
                    >
                      <ToggleButton value={true}>Interstitial</ToggleButton>
                      <ToggleButton value={false}>Not Interstitial</ToggleButton>
                    </ToggleButtonGroup>
                    <br />
                  </div>
                )}
              <Chart
                id={this.props.partner + "_" + this.props.reportDate + "_" + showHistoryFor?.country || showBundleHistoryFor?.bundle || showOsHistoryFor?.country + "_" + showOsHistoryFor?.os}
                data={chartData}
                reportMetric={showHistoryFor?.reportMetric || showCountryHistoryFor?.reportMetric || showBundleHistoryFor?.reportMetric || showOsHistoryFor?.reportMetric}
                reportMetricText={showHistoryFor?.reportMetricText || showCountryHistoryFor?.reportMetricText || showBundleHistoryFor?.reportMetricText || showOsHistoryFor?.reportMetricText}
                />
                Compare to Report date:
                <DateRangePicker
                  dateFormat="YYYY-MM-DD"
                  opens="right"
                  drops="up"
                  onChange={(value) => {
                    if (showHistoryFor?.reportMetric) {
                      this.loadHistoryDaysBack(value);
                    }
                    else if (showCountryHistoryFor?.reportMetric) {
                      this.loadCountryHistoryDaysBack(value);
                    }
                    else if (showOsHistoryFor?.reportMetric) {
                      this.loadOsHistoryDaysBack(value);
                    }
                    else if (showBundleHistoryFor?.reportMetric) {
                      this.loadBundleHistoryDaysBack(value);
                    }
                      
                  }}
                  minDate={moment().startOf("day").subtract(90, 'd')}
                  maxDate={moment(this.props.reportDate).endOf("day")}
                  startDate={moment(showHistoryFor?.reportDate).startOf("day")}
                  endDate={moment(showHistoryFor?.reportDate).startOf("day")}
                  singleDatePicker={true}
                />                
              </div>
            )}
          </div>
        );
      } else if (busy) {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center">Loading...</div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center text-muted">No data</div>
            </Col>
          </Row>
        );
      }
    };
    var _this = this;
    return (
      
      <Modal bsSize="large" show={true} onHide={this.props.onHide} className="modal-xxl">
        <Modal.Header closeButton>
          <Modal.Title>RTB Exchange report by DSP Partner for <b>{this.props.partner}</b> {/*- Report date: <b>{this.state.reportDate}</b>*/}</Modal.Title>
        </Modal.Header>
        <UserContext.Consumer>
          {user => (
          <Modal.Body>
            <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
              <FormGroup className="date-range-picker-filter pull-left">
                <label className="date-range-picker-filter-label">Report Date</label>
                <DateRangePicker
                  className="form-control date-range-picker-filter-input"
                  opens="right"
                  onChange={(value) => this.selectDate(value)}
                  ranges={{
                    Today: [moment().startOf("day"), moment().endOf("day")],
                    Yesterday: [
                      moment()
                        .subtract(1, "days")
                        .startOf("day"),
                      moment()
                        .subtract(1, "days")
                        .endOf("day")
                    ],
                    "This week so far": [
                      moment().startOf("isoWeek"),
                      moment().endOf("day")
                    ],
                    "Last week": [
                      moment()
                        .subtract(1, "weeks")
                        .startOf("isoWeek"),
                      moment()
                        .subtract(1, "weeks")
                        .endOf("isoWeek")
                    ],
                    "This month so far": [
                      moment().startOf("month"),
                      moment().endOf("day")
                    ],
                    "Last month": [
                      moment()
                        .subtract(1, "months")
                        .startOf("month"),
                      moment()
                        .subtract(1, "months")
                        .endOf("month")
                    ]
                  }}
                  minDate={moment("2019-01-18", "YYYY-MM-DD")}
                  maxDate={moment().endOf("day")}
                  startDate={moment().startOf("day")}
                  endDate={moment().endOf("day")}
                />
              </FormGroup>

              <ButtonGroup>
                <DataTable.ColumnFilter
                  tableRef={this.tableRef}
                  columnName="name"
                  bsSize="small"
                  title="DSP Partner"
                  defaultValue=""
                  values={[
                    ["*", ""],
                    ["Minimob", "Minimob"],
                    ["Pubmatic", "Pubmatic"],
                    ["Thirdpresence", "Thirdpresence_"],
                    ["ThirdpresenceEU", "ThirdpresenceEU"],
                    ["Yandex", "Yandex"],
                    ["Sharethrough", "Sharethrough"]
                  ]}
                />
              </ButtonGroup>
            </DataTable.Toolbar>
            
            {/*reportDetail1*/}
            {this.props.reportType === "reportDetail1" && (
              
            <div className="table-overflow-y-div">
              <DataTable
                ref={this.tableRef}
                ajax={{
                  url: "/api/v1/reports/dsppartnerrtbexchangedata",
                  dataSrc: "items",
                  data: function(d){
                    d.partner = _this.props.partner||null;
                    d.dateFrom = _this.getSelectedDate()[0];
                    d.dateTo = _this.getSelectedDate()[1];
                  }
                }}
                className="table table-condensed table-striped table-hover table-small-th-font report-detail1-table"
                //data={this.props.reportData||[]}
                    onLoading={() => this.setState({ loading: true, busy: true, showHistoryFor: null, showByCountryFor: null, showCountryHistoryFor: null, showByOsFor: null, showOsHistoryFor: null, showByBundleFor: null, showBundleHistoryFor: null })}
                onLoaded={() => this.setState({ loading: false, busy: false })}
                onUserCommand={({ commandData }) =>{
                  if (user.tokenHasExpired) {
                    window.location.replace('/login');
                    return;
                  }
                  else {
                    if(commandData.action === "showHistory"){
                      this.setState({ 
                        showHistoryFor: commandData,
                        showByBundleFor: null,
                        showBundleHistoryFor: null,
                        showByCountryFor: null,
                        showCountryHistoryFor: null,
                        showByOsFor: null, 
                        showOsHistoryFor: null, 
                        lastClickedRow: commandData.row
                      });                    
                      this.loadHistory(commandData.reportDate, commandData.dspPartnerId, commandData.reportMetric);
                    }
                    else if(commandData.action === "showByCountry"){
                      this.setState({ 
                        showByCountryFor: commandData,
                        showByBundleFor: null,
                        showBundleHistoryFor: null,
                        showCountryHistoryFor: null,
                        showHistoryFor: null,
                        showByOsFor: null,
                        showOsHistoryFor: null,
                        lastClickedRow: commandData.row,
                      }, ()=> this.toggleHiddenColumn(this.tableRef2));
                    }
                    else if (commandData.action === "showByBundle") {
                      this.setState({
                        showByBundleFor: commandData,
                        showBundleHistoryFor: null,
                        showByCountryFor: null,
                        showCountryHistoryFor: null,
                        showHistoryFor: null,
                        showByOsFor: null,
                        showOsHistoryFor: null,
                        lastClickedRow: commandData.row,
                      }, () => this.toggleHiddenColumn(this.tableRef2));
                    }
                    
                    if(commandData.row!==null){
                      this.colorizeRow(commandData.row);
                    }
                  }
                }}
                    columns={[
                      DataTable.createUserCommandColumn({
                        title: "",
                        data: null,
                        className: "text-right",
                        orderable: false,
                        defultContent: "",
                        width: "50px",
                        commandName: "",
                        commandData: ({ rowData, row }) => ({
                          action: 'showByCountry',
                          row: row,
                          id: rowData._id,
                          reportData: rowData?.perCountry || [],
                          type: 'dspPartner',
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner
                        }),
                        render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> <i class='fa fa-globe'></i> </button></div>"),
                      }),
                      DataTable.createUserCommandColumn({
                        title: "",
                        data: null,
                        className: "text-right",
                        orderable: false,
                        defultContent: "",
                        width: "50px",
                        commandName: "",
                        commandData: ({ rowData, row }) => ({
                          action: 'showByBundle',
                          row: row,
                          id: rowData._id,
                          reportData: rowData?.perBundle || [],
                          type: 'dspPartner',
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner
                        }),
                        render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> <i class='fa fa-list'></i> </button></div>"),
                      }),
                  {
                    title: "Report Date",
                    data: "reportDate",
                    name: "reportDate",
                    className: "text-nowrap",
                    width: "95px",
                    defaultContent: "",
                    render: (d, t, r, m) => renderDate(d, t, r, m),
                    order: "desc"
                  },                  
                  {
                    title: "DSP Partner",
                    data: "name",
                    name: "name",
                    className: "text-right",
                    defaultContent: "",
                    width: "5%",
                    render: (d, t, r, m) => renderPartner(d, t, r, m)
                  },
                  DataTable.createUserCommandColumn({
                    title: "RTB Cost",
                    data: "rtbCost",
                    className: "text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "rtbCost",
                        reportMetricText: "RTB Cost",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "DC Cost",
                    data: "datacenter_cost",
                    className: "text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "datacenter_cost",
                        reportMetricText: "DC Cost",
                      });
                    }
                  }),
                      DataTable.createUserCommandColumn({
                        title: "DC Cost Oth.",
                        data: "datacenter_cost_other",
                        className: "text-right",
                        defaultContent: "",
                        render: (d, t, r, m) => N(d, 2),
                        commandName: "",
                        commandData: ({ rowData, row }) => {
                          return ({
                            action: 'showHistory',
                            row: row,
                            id: rowData._id,
                            reportDate: rowData.reportDate,
                            dspPartner: rowData.name,
                            dspPartnerId: rowData.dspPartnerId,
                            partner: rowData.partner,
                            reportMetric: "datacenter_cost_other",
                            reportMetricText: "DC Cost Oth.",
                          });
                        }
                      }),
                  DataTable.createUserCommandColumn({
                    title: "RTB Requests",
                    data: "rtbRequests",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "rtbRequests",
                        reportMetricText: "RTB Requests",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Responses",
                    data: "responses",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "responses",
                        reportMetricText: "Responses",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Responses %",
                    data: "responsesPercentage",
                    className: "th-cell-nowrap text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "responsesPercentage",
                        reportMetricText: "Responses %",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Latency Response",
                    data: "latency.response.mean",
                    className: "cell-auto-width text-right",
                    defaultContent: "",
                    orderable: false,
                    render: renderLatencyResponse,
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "latencyResponse",
                        reportMetricText: "Latency Response",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Timeouts",
                    data: "timeouts",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "timeouts",
                        reportMetricText: "Timeouts",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Timeouts %",
                    data: "timeoutPercentage",
                    className: "th-cell-nowrap text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "timeoutPercentage",
                        reportMetricText: "Timeouts %",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Throttled",
                    data: "throttled",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "throttled",
                        reportMetricText: "Throttled",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Throttled %",
                    data: "throttlePercentage",
                    className: "th-cell-nowrap text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "throttlePercentage",
                        reportMetricText: "Throttled %",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Latency Throttled",
                    data: "latency.throttled.mean",
                    className: "cell-auto-width text-right",
                    defaultContent: "",
                    orderable: false,
                    render: renderLatencyThrottled,
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "latencyThrottled",
                        reportMetricText: "Latency Throttled",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "No Bid",
                    data: "no_bids",
                    className: "th-cell-nowrap text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "no_bids",
                        reportMetricText: "No Bid",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "No Bid %",
                    data: "nobidPercentage",
                    className: "th-cell-nowrap text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "nobidPercentage",
                        reportMetricText: "No Bid %",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Latency No Bid",
                    data: "latency.nobid.mean",
                    className: "cell-auto-width text-right",
                    defaultContent: "",
                    orderable: false,
                    render:renderLatencyNobid,
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "latencyNobid",
                        reportMetricText: "Latency No Bid",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Impressions",
                    data: "impressions",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "impressions",
                        reportMetricText: "Impressions",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Wins",
                    data: "wins",
                    className: "text-right",
                    defaultContent: "",
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "wins",
                        reportMetricText: "Wins",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "QPS",
                    data: "qps",
                    className: "text-right",
                    width: "8%",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "qps",
                        reportMetricText: "QPS",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "Revenue Exchange",
                    data: "revenueExchange",
                    className: "revenue-exchange-column text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    visible: false,
                    commandName: "",
                    commandData: ({rowData, row}) => {
                      return ({
                        action: 'showHistory',
                        row: row,
                        id: rowData._id,
                        reportDate: rowData.reportDate,
                        dspPartner: rowData.name,
                        dspPartnerId: rowData.dspPartnerId,
                        partner: rowData.partner,
                        reportMetric: "revenueExchange",
                        reportMetricText: "Revenue Exchange",
                      });
                    }
                  }),
                  DataTable.createUserCommandColumn({
                    title: "",
                    data: null,
                    className: "text-right",
                    orderable: false,
                    defultContent: "",
                    width: "50px",
                    commandName: "",
                    commandData: ({ rowData, row }) => ({
                      action: 'showByCountry',
                      row: row,
                      id: rowData._id,
                      reportData: rowData?.perCountry||[],
                      type: 'dspPartner',
                      reportDate: rowData.reportDate,
                      dspPartner: rowData.name,
                      dspPartnerId: rowData.dspPartnerId,
                      partner: rowData.partner,
                    }),
                    render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> By Country </button></div>"),
                  }),
                  DataTable.createUserCommandColumn({
                    title: "",
                    data: null,
                    className: "text-right",
                    orderable: false,
                    defultContent: "",
                    width: "50px",
                    commandName: "",
                    commandData: ({ rowData, row }) => ({
                      action: 'showByBundle',
                      row: row,
                      id: rowData._id,
                      reportData: rowData?.perBundle || [],
                      type: 'dspPartner',
                      reportDate: rowData.reportDate,
                      dspPartner: rowData.name,
                      dspPartnerId: rowData.dspPartnerId,
                      partner: rowData.partner,
                    }),
                    render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> By Bundle </button></div>"),
                  }),
                ]}
                order={[[22, "desc"]]}
                footerCallback={(tfoot, data, start, end, display) => {
                  const gt = {
                    rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                    datacenter_cost: display.reduce((acc, cur) => (acc += data[cur].datacenter_cost), 0),
                    datacenter_cost_other: display.reduce((acc, cur) => (acc += data[cur].datacenter_cost_other), 0),
                    rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                    responses: display.reduce((acc, cur) => (acc += data[cur].responses), 0),
                    timeouts: display.reduce((acc, cur) => (acc += data[cur].timeouts), 0),
                    throttled: display.reduce((acc, cur) => (acc += data[cur].throttled), 0),
                    no_bids: display.reduce((acc, cur) => (acc += data[cur].no_bids), 0),
                    impressions: display.reduce((acc, cur) => (acc += data[cur].impressions), 0),
                    wins: display.reduce((acc, cur) => (acc += data[cur].wins), 0),
                    revenueExchange: display.reduce((acc, cur) => (acc += data[cur].revenueExchange), 0),
                  };
                  const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                  const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                  const footer = $(tfoot).parent();
                  
                  // Table totals
                  footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                  footer.find("#gtdatacenter_cost").html(renderMoney(gt.datacenter_cost));
                  footer.find("#gtdatacenter_cost_other").html(renderMoney(gt.datacenter_cost_other));
                  footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                  footer.find("#gtresponses").html(renderCount(gt.responses));
                  footer.find("#gttimeouts").html(renderCount(gt.timeouts));
                  footer.find("#gtthrottled").html(renderCount(gt.throttled));
                  footer.find("#gtno_bids").html(renderCount(gt.no_bids));
                  footer.find("#gtimpressions").html(renderCount(gt.impressions));
                  footer.find("#gtwins").html(renderCount(gt.wins));
                  footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody></tbody>
                {/* prettier-ignore */}
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="text-right">
                      Totals
                    </td>
                    <td></td>
                    <td className="text-right" id="gtrtbCost">0</td>
                    <td className="text-right" id="gtdatacenter_cost">0</td>
                    <td className="text-right" id="gtdatacenter_cost_other">0</td>
                    <td className="text-right" id="gtrtbRequests">0</td>
                    <td className="text-right" id="gtresponses">0</td>
                    <td className="text-right" id="gtresponsesPercentage"></td>
                    <td className="text-right" id="gtlatencyResponse"></td>
                    <td className="text-right" id="gttimeouts">0</td>
                    <td className="text-right" id="gttimeoutPercentage"></td>
                    <td className="text-right" id="gtthrottled">0</td>
                    <td className="text-right" id="gtthrottlePercentage"></td>
                    <td className="text-right" id="gtlatencyThrottled"></td>
                    <td className="text-right" id="gtno_bids">0</td>
                    <td className="text-right" id="gtnobidPercentage"></td>
                    <td className="text-right" id="gtlatencyNobid"></td>
                    <td className="text-right" id="gtimpressions">0</td>
                    <td className="text-right" id="gtwins">0</td>
                    <td></td>
                    <td className="text-right" id="gtrevenueExchange">0</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </DataTable>
            </div>
            )} {/*End of reportDetail1 */}
            
            {/*reportDetail2*/}
            {this.props.reportType === "reportDetail2" && (

              <div className="table-overflow-y-div">
                <DataTable
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/reports/dsppartnerrtbexchangedata",
                    dataSrc: "items",
                    data: function(d){
                      d.partner = _this.props.partner||null;
                      d.dateFrom = _this.getSelectedDate()[0];
                      d.dateTo = _this.getSelectedDate()[1];
                    }
                  }}
                  className="table table-condensed table-striped table-hover table-small-th-font report-detail2-table"
                  //data={this.props.reportData||[]}
                  onLoading={() => this.setState({ loading: true, busy: true, showHistoryFor: null })}
                  onLoaded={() => this.setState({ loading: false, busy: false })}
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      if(commandData.action === "showHistory"){
                        this.setState({
                          showHistoryFor: commandData,
                          showByCountryFor: null,
                          showCountryHistoryFor: null,
                          showByOsFor: null,
                          showOsHistoryFor: null,
                          lastClickedRow: commandData.row
                        });
                        this.loadHistory(commandData.reportDate, commandData.dspPartnerId, commandData.reportMetric);
                      }

                      if(commandData.row!==null){
                        this.colorizeRow(commandData.row);
                      }
                    }
                  }}
                  columns={[
                    {
                      title: "Report Date",
                      data: "reportDate",
                      name: "reportDate",
                      className: "text-nowrap",
                      width: "95px",
                      defaultContent: "",
                      render: (d, t, r, m) => renderDate(d, t, r, m),
                      order: "desc"
                    },
                    {
                      title: "DSP Partner",
                      data: "name",
                      name: "name",
                      className: "text-right",
                      defaultContent: "",
                      width: "5%",
                      render: (d, t, r, m) => renderPartner(d, t, r, m)
                    },
                    DataTable.createUserCommandColumn({
                      title: "QPS",
                      data: "qps",
                      className: "text-right",
                      width: "8%",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "qps",
                          reportMetricText: "QPS",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Revenue Exchange",
                      data: "revenueExchange",
                      className: "revenue-exchange-column text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      visible: false,
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "revenueExchange",
                          reportMetricText: "Revenue Exchange",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "RTB Cost",
                      data: "rtbCost",
                      className: "text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "rtbCost",
                          reportMetricText: "RTB Cost",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "RTB Requests",
                      data: "rtbRequests",
                      className: "text-right",
                      defaultContent: "",
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "rtbRequests",
                          reportMetricText: "RTB Requests",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Throttled %",
                      data: "throttlePercentage",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "throttlePercentage",
                          reportMetricText: "Throttled %",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Timeouts %",
                      data: "timeoutPercentage",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "timeoutPercentage",
                          reportMetricText: "Timeouts %",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "No Bid %",
                      data: "nobidPercentage",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "nobidPercentage",
                          reportMetricText: "No Bid %",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Responses %",
                      data: "responsesPercentage",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "responsesPercentage",
                          reportMetricText: "Responses %",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Impressions",
                      data: "impressions",
                      className: "text-right",
                      defaultContent: "",
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "impressions",
                          reportMetricText: "Impressions",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Wins",
                      data: "wins",
                      className: "text-right",
                      defaultContent: "",
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "wins",
                          reportMetricText: "Wins",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Wins Responses %",
                      data: "winsResponsesPercentage",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "winsResponsesPercentage",
                          reportMetricText: "Wins Responses %",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "eCPM Wins",
                      data: "eCPMwins",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "eCPMwins",
                          reportMetricText: "eCPM Wins",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "eCPM Responses",
                      data: "eCPMresponses",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "eCPMresponses",
                          reportMetricText: "eCPM Responses",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "eCPM Requests",
                      data: "eCPMrequests",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "eCPMrequests",
                          reportMetricText: "eCPM Requests",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "CPM Wins",
                      data: "cpMwins",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "cpMwins",
                          reportMetricText: "CPM Wins",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "CPM Responses",
                      data: "cpMresponses",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "cpMresponses",
                          reportMetricText: "CPM Responses",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "CPM Requests",
                      data: "cpMrequests",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({rowData, row}) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "cpMrequests",
                          reportMetricText: "CPM Requests",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "avg SSP Bidfloor",
                      data: "avgBidRequestsFloorPrice",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({ rowData, row }) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "avgBidRequestsFloorPrice",
                          reportMetricText: "avg SSP Bidfloor",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "avg DSP Bid",
                      data: "avgBidPrice",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({ rowData, row }) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "avgBidPrice",
                          reportMetricText: "avg DSP Bid",
                        });
                      }
                    }),
                    DataTable.createUserCommandColumn({
                      title: "avg XCH Bid",
                      data: "avgExchangeBidPrice",
                      className: "th-cell-nowrap text-right",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 4),
                      commandName: "",
                      commandData: ({ rowData, row }) => {
                        return ({
                          action: 'showHistory',
                          row: row,
                          id: rowData._id,
                          reportDate: rowData.reportDate,
                          dspPartner: rowData.name,
                          dspPartnerId: rowData.dspPartnerId,
                          partner: rowData.partner,
                          reportMetric: "avgExchangeBidPrice",
                          reportMetricText: "avg XCH Bid",
                        });
                      }
                    })
                  ]}
                  order={[[0, "desc"]]}
                  footerCallback={(tfoot, data, start, end, display) => {
                    
                    const gt = {
                      revenueExchange: display.reduce((acc, cur) => (acc += data[cur].revenueExchange), 0),
                      rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                      rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                      impressions: display.reduce((acc, cur) => (acc += data[cur].impressions), 0),
                      wins: display.reduce((acc, cur) => (acc += data[cur].wins), 0),
                    };
                    const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                    const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                    const footer = $(tfoot).parent();

                    // Table totals
                    footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                    footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                    footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                    footer.find("#gtimpressions").html(renderCount(gt.impressions));                    
                    footer.find("#gtwins").html(renderCount(gt.wins));
                    
                  }}
                >
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody></tbody>
                  {/* prettier-ignore */}
                  {/*
                   DSP Partner, QPS, Revenue Exchange, RTB Cost, RTB Requests, 
                  Throttled %, Timeouts %, No Bid %, Responses %, Impressions,
                   Wins, Wins % Responses, 
                   eCPM Wins, eCPM Responses, eCPM Requests, 
                   CPM Wins, CPM Responses, CPM Requests*/}
                  <tfoot>
                  <tr>
                    <td className="text-right">
                      Totals
                    </td>
                    <td></td>
                    <td></td>
                    <td className="text-right" id="gtrevenueExchange">0</td>
                    <td className="text-right" id="gtrtbCost">0</td>
                    <td className="text-right" id="gtrtbRequests">0</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-right" id="gtimpressions">0</td>
                    <td className="text-right" id="gtwins">0</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tfoot>
                </DataTable>
              </div>
            )} {/*End of reportDetail2 */}
            <br/>
            
            <div>
              <div>{byCountryReportBody()}</div>
              <Spinner visible={busy} />
            </div>
            
            <div>
              <div>{byOsReportBody()}</div>
              <Spinner visible={busy} />
            </div>

            <div>
              <div>{byBundleReportBody()}</div>
              <Spinner visible={busy} />
            </div>
            
            <br/>
            
              {(this.state.showHistoryFor || this.state.showCountryHistoryFor || this.state.showBundleHistoryFor || this.state.showOsHistoryFor) && (
              <div>
                <div>{chartBody()}</div>
                <Spinner visible={busy} />
              </div>
            )}
            
          </Modal.Body>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
  
  selectDate(date, isInit) {
    
    this.setState({ selectedDate: date}, () => {
      if(this.tableRef?.current){
        if(!isInit){
          this.tableRef.current.reload();
        }        
      }
    });
    
  }
  
  getSelectedDate(){
    var selectedDate = this.state.selectedDate;
    if(selectedDate && selectedDate.length===2){
      var dateFrom = moment.utc(selectedDate[0]).format("YYYY-MM-DD");
      var dateTo = moment.utc(selectedDate[1]).format("YYYY-MM-DD");
      return [dateFrom,dateTo];
    }
    else {
      return [selectedDate,selectedDate];
    }
    
  }
  
  toggleHiddenColumn(tableRef){
    if(tableRef){
      const api = tableRef?.current?.api;
      if(api){
        if(this.state.partner!==null && this.state.partner.indexOf('Exchange')!==-1){
          api.columns(".revenue-exchange-column").visible(true);
        }else{
          api.columns(".revenue-exchange-column").visible(false);
        }
        api.draw(false);
      }
    }    
  }
  
  colorizeRow(row) {
    var api = this.tableRef?.current?.api;
    if (api) {
      api.rows()
        .nodes()
        .to$()
        .removeClass('hilighted-row');
      
      if (row === this.state.lastClickedRow) {
        api.rows([row])
          .nodes()
          .to$()
          .addClass('hilighted-row');
      }
      api.draw(false);
    }
  }

  colorizeRow2(row) {
    var api = this.tableRef2?.current?.api;
    if (api) {
      api.rows()
        .nodes()
        .to$()
        .removeClass('hilighted-row');

      if (row === this.state.lastClickedRow2) {
        api.rows([row])
          .nodes()
          .to$()
          .addClass('hilighted-row');
      }
      api.draw(false);
    }
  }
  
  colorizeRow3(row) {
    var api = this.tableRef3?.current?.api;
    if (api) {
      api.rows()
        .nodes()
        .to$()
        .removeClass('hilighted-row');

      if (row === this.state.lastClickedRow3) {
        api.rows([row])
          .nodes()
          .to$()
          .addClass('hilighted-row');
      }
      api.draw(false);
    }
  }
  
  loadHistory(reportDate, dspPartnerId, reportMetric) {
    this.setState({ busy: true });

    const dateFrom = moment(reportDate).format("YYYY-MM-DD");
    const dateTo = dateFrom;
    const partner = this.state.partner||null;
    
    if(reportMetric === "latencyResponse" || reportMetric === "latencyNobid" || reportMetric === "latencyThrottled")
    {
      reportMetric = "latency";
    }
    if(reportMetric === "cpMwins" || reportMetric === "cpMresponses" || reportMetric === "cpMrequests"){
      const regex = /cpM/i;
      reportMetric = reportMetric.replace(regex, 'CPM');
    }
    axios({
      url: '/api/v1/reports/dsppartnerrtbexchangedatahistory',
      params: { partner, dspPartnerId, dateFrom, dateTo, reportMetric }
    })
      .then(res => {
        const chartData = res.data.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );
        this.setState({
          chartData,
          selectedRange: reportDate,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }

  loadHistoryDaysBack(reportDate) {
    //const rDate = moment(reportDate);
    const now = moment(this.props.reportDate).utc();
    if (!reportDate[0].isSame(now, 'd')) {
      this.setState({ busy: true });

      const dateFrom = reportDate[0].format("YYYY-MM-DD");
      const dateTo = dateFrom;
      const partner = this.props.partner || null;
      let reportMetric = this.state.showHistoryFor?.reportMetric || null;
      const dspPartnerId = this.state.showHistoryFor?.dspPartnerId || null;

      if (reportMetric === "latencyResponse" || reportMetric === "latencyNobid" || reportMetric === "latencyThrottled") {
        reportMetric = "latency";
      }
      if (reportMetric === "cpMwins" || reportMetric === "cpMresponses" || reportMetric === "cpMrequests") {
        const regex = /cpM/i;
        reportMetric = reportMetric.replace(regex, 'CPM');
      }

      let chartData = this.state.chartData || null;

      axios({
        url: '/api/v1/reports/dsppartnerrtbexchangedatahistory',
        params: { partner, dspPartnerId, dateFrom, dateTo, reportMetric }
      })
        .then(res => {
          const chartDataDaysBack = res.data.sort(
            (a, b) =>
              moment(a.modified).valueOf() - moment(b.modified).valueOf()
          );

          const chartDataFiltered = chartData.filter((item) => moment(item.modified).isSame(now.endOf('day'), 'd'));

          chartData = chartDataDaysBack.concat(chartDataFiltered);
          this.setState({
            chartData,
            selectedRange: reportDate,
            busy: false
          });
        })
        .catch(err => {
          this.setState({
            error: err?.response?.data,
            busy: false
          });
        });
    }
  }

  loadCountryHistory(reportDate, dspPartnerId, reportMetric, country, ) {
    this.setState({ busy: true });

    const dateFrom = moment(reportDate).format("YYYY-MM-DD");
    const dateTo = dateFrom;
    const partner = this.state.partner||null;

    axios({
      url: '/api/v1/reports/countryrtbexchangedatahistory',
      params: { partner, dspPartnerId, country, dateFrom, dateTo, reportMetric }
    })
      .then(res => {
        const chartData = res.data.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );
        this.setState({
          chartData,
          selectedRange: reportDate,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }

  loadCountryHistoryDaysBack(reportDate) {
    //const rDate = moment(reportDate);
    const now = moment(this.props.reportDate).utc();
    if (!reportDate[0].isSame(now, 'd')) {
      this.setState({ busy: true });

      const dateFrom = reportDate[0].format("YYYY-MM-DD");
      const dateTo = dateFrom;
      const partner = this.props.partner || null;
      let reportMetric = this.state.showCountryHistoryFor?.reportMetric || null;
      const dspPartnerId = this.state.showCountryHistoryFor?.dspPartnerId || null;
      const country = this.state.showCountryHistoryFor?.country || null;

      let chartData = this.state.chartData || null;

      axios({
        url: '/api/v1/reports/countryrtbexchangedatahistory',
        params: { partner, dspPartnerId, country, dateFrom, dateTo, reportMetric }
      })
        .then(res => {
          const chartDataDaysBack = res.data.sort(
            (a, b) =>
              moment(a.modified).valueOf() - moment(b.modified).valueOf()
          );

          const chartDataFiltered = chartData.filter((item) => moment(item.modified).isSame(now.endOf('day'), 'd'));

          chartData = chartDataDaysBack.concat(chartDataFiltered);
          this.setState({
            chartData,
            selectedRange: reportDate,
            busy: false
          });
        })
        .catch(err => {
          this.setState({
            error: err?.response?.data,
            busy: false
          });
        });
    }
  }
  
  loadOsHistory(reportDate, dspPartnerId, reportMetric, country, os) {
    this.setState({ busy: true });

    const dateFrom = moment(reportDate).format("YYYY-MM-DD");
    const dateTo = dateFrom;
    const partner = this.state.partner||null;

    axios({
      url: '/api/v1/reports/osrtbexchangedatahistory',
      params: { partner, dspPartnerId, country, os, dateFrom, dateTo, reportMetric }
    })
      .then(res => {
        const chartData = res.data.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );
        this.setState({
          chartData,
          selectedRange: reportDate,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }

  loadOsHistoryDaysBack(reportDate) {
    //const rDate = moment(reportDate);
    const now = moment(this.props.reportDate).utc();
    if (!reportDate[0].isSame(now, 'd')) {
      this.setState({ busy: true });

      const dateFrom = reportDate[0].format("YYYY-MM-DD");
      const dateTo = dateFrom;
      const partner = this.props.partner || null;
      let reportMetric = this.state.showOsHistoryFor?.reportMetric || null;
      const dspPartnerId = this.state.showOsHistoryFor?.dspPartnerId || null;
      const country = this.state.showOsHistoryFor?.country || null;
      const os = this.state.showOsHistoryFor?.os || null;

      let chartData = this.state.chartData || null;

      axios({
        url: '/api/v1/reports/osrtbexchangedatahistory',
        params: { partner, dspPartnerId, country, os, dateFrom, dateTo, reportMetric }
      })
        .then(res => {
          const chartDataDaysBack = res.data.sort(
            (a, b) =>
              moment(a.modified).valueOf() - moment(b.modified).valueOf()
          );

          const chartDataFiltered = chartData.filter((item) => moment(item.modified).isSame(now.endOf('day'), 'd'));

          chartData = chartDataDaysBack.concat(chartDataFiltered);
          this.setState({
            chartData,
            selectedRange: reportDate,
            busy: false
          });
        })
        .catch(err => {
          this.setState({
            error: err?.response?.data,
            busy: false
          });
        });
    }
  }

  loadBundleHistory(reportDate, dspPartnerId, reportMetric, bundle,) {
    this.setState({ busy: true });

    const dateFrom = moment(reportDate).format("YYYY-MM-DD");
    const dateTo = dateFrom;
    const partner = this.state.partner || null;
    let filter = {};

    if (reportMetric === 'bidfloor') {
      filter.response = ["true"];
    }

    axios({
      url: '/api/v1/reports/bundlertbexchangedatahistory',
      params: { partner, dspPartnerId, bundle, dateFrom, dateTo, reportMetric, filter }
    })
      .then(res => {
        const chartData = res.data.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );
        this.setState({
          chartData,
          selectedRange: reportDate,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }

  loadBundleHistoryDaysBack(reportDate) {
    //const rDate = moment(reportDate);
    const now = moment(this.props.reportDate).utc();
    if (!reportDate[0].isSame(now, 'd')) {
      this.setState({ busy: true });

      const dateFrom = reportDate[0].format("YYYY-MM-DD");
      const dateTo = dateFrom;
      const partner = this.props.partner || null;
      let reportMetric = this.state.showBundleHistoryFor?.reportMetric || null;
      const dspPartnerId = this.state.showBundleHistoryFor?.dspPartnerId || null;
      const bundle = this.state.showBundleHistoryFor?.bundle || null;

      let chartData = this.state.chartData || null;

      axios({
        url: '/api/v1/reports/bundlertbexchangedatahistory',
        params: { partner, dspPartnerId, bundle, dateFrom, dateTo, reportMetric }
      })
        .then(res => {
          const chartDataDaysBack = res.data.sort(
            (a, b) =>
              moment(a.modified).valueOf() - moment(b.modified).valueOf()
          );

          const chartDataFiltered = chartData.filter((item) => moment(item.modified).isSame(now.endOf('day'), 'd'));

          chartData = chartDataDaysBack.concat(chartDataFiltered);
          this.setState({
            chartData,
            selectedRange: reportDate,
            busy: false
          });
        })
        .catch(err => {
          this.setState({
            error: err?.response?.data,
            busy: false
          });
        });
    }
  }

  loadBundleHistoryFilter(filter) {
    
    this.setState({ busy_bundlehistory: true });

    const partner = this.props.partner || null;
    let reportMetric = this.state.showBundleHistoryFor?.reportMetric || null;
    const dspPartnerId = this.state.showBundleHistoryFor?.dspPartnerId || null;
    const bundle = this.state.showBundleHistoryFor?.bundle || null;

    let chartData = this.state.chartData || null;

    if (filter.response || filter.creativeType || filter.deviceType || filter.rewarded || filter.interstitial) {
      if (filter.creativeType) {
        if (this.state.deviceType && this.state.deviceType.length > 0) {
          filter.deviceType = this.state.deviceType;
        }
        if (this.state.rewarded && this.state.rewarded.length > 0) {
          filter.rewarded = this.state.rewarded;
        }
        if (this.state.interstitial && this.state.interstitial.length > 0) {
          filter.interstitial = this.state.interstitial;
        }
        if (this.state.response && this.state.response.length > 0) {
          filter.response = this.state.response;
        }
        if (filter.creativeType.length === 0) {
          delete filter.creativeType;
        }
      }
      else if (filter.deviceType) {
        if (this.state.creativeType && this.state.creativeType.length > 0) {
          filter.creativeType = this.state.creativeType;
        }
        if (this.state.rewarded && this.state.rewarded.length > 0) {
          filter.rewarded = this.state.rewarded;
        }
        if (this.state.interstitial && this.state.interstitial.length > 0) {
          filter.interstitial = this.state.interstitial;
        }
        if (this.state.response && this.state.response.length > 0) {
          filter.response = this.state.response;
        }
        if (filter.deviceType.length === 0) {
          delete filter.deviceType;
        }
      }
      else if (filter.rewarded) {
        if (this.state.creativeType && this.state.creativeType.length > 0) {
          filter.creativeType = this.state.creativeType;
        }
        if (this.state.deviceType && this.state.deviceType.length > 0) {
          filter.deviceType = this.state.deviceType;
        }
        if (this.state.interstitial && this.state.interstitial.length > 0) {
          filter.interstitial = this.state.interstitial;
        }
        if (this.state.response && this.state.response.length > 0) {
          filter.response = this.state.response;
        }
        if (filter.rewarded.length === 0) {
          delete filter.rewarded;
        }
      }
      else if (filter.interstitial) {
        if (this.state.creativeType && this.state.creativeType.length > 0) {
          filter.creativeType = this.state.creativeType;
        }
        if (this.state.deviceType && this.state.deviceType.length > 0) {
          filter.deviceType = this.state.deviceType;
        }
        if (this.state.rewarded && this.state.rewarded.length > 0) {
          filter.rewarded = this.state.rewarded;
        }
        if (this.state.response && this.state.response.length > 0) {
          filter.response = this.state.response;
        }
        if (filter.interstitial.length === 0) {
          delete filter.interstitial;
        }
      }
      else if (filter.response) {
        if (this.state.creativeType && this.state.creativeType.length > 0) {
          filter.creativeType = this.state.creativeType;
        }
        if (this.state.deviceType && this.state.deviceType.length > 0) {
          filter.deviceType = this.state.deviceType;
        }
        if (this.state.rewarded && this.state.rewarded.length > 0) {
          filter.rewarded = this.state.rewarded;
        }
        if (this.state.interstitial && this.state.interstitial.length > 0) {
          filter.interstitial = this.state.interstitial;
        }
        if (filter.response.length === 0) {
          delete filter.response;
        }
      }
    }

    axios({
      url: '/api/v1/reports/bundlertbexchangedatahistory',
      params: { partner, dspPartnerId, bundle, reportMetric, filter }
    })
    .then(res => {

      const chartDataSorted = res.data.sort(
        (a, b) =>
          moment(a.modified).valueOf() - moment(b.modified).valueOf()
      );

      chartData = chartDataSorted;
      this.setState({
        chartData,
        busy_bundlehistory: false
      });
    })
    .catch(err => {
      this.setState({
        error: err?.response?.data,
        busy_bundlehistory: false
      });
    });
    
  }

  loadBundleRT(dspPartnerId, reportMetric, bundle) {
    this.setState({ busy: true });
    const partner = this.state.partner || null;
    let RTData = this.state.RTData || null;

    axios({
      url: '/api/v1/reports/bundlertbappsrt',
      params: { partner, dspPartnerId, bundle, reportMetric }
    })
      .then(res => {

        const RTDataSorted = res.data.items.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );

        RTData = RTDataSorted;
        this.setState({
          RTData,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }

 }
