import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import { digitGrouping } from "lib/Functions";
import $ from "jquery";

export default class AddDialog extends React.Component {
  static title = "Add Campaign Pacing";

  constructor(props) {
    super(props);
    this.state = {
      offerid: null,
      pacingType: "even",
      pacing: this.getDefaultPacing(), //manual pacing
      evenPacing: this.getDefaultPacing(),
      smartPacing: this.getDefaultPacing(),
      defaultImpCap: null,
      defaultDailyImpCap: null,
      defaultEvenImpCap: null,
      defaultEvenDailyImpCap: null,
      maxImpressionsPerDay: null,
      maxImpressionsPerHour: null,
      baselineImpressionsPerDay: null,
      extraImpressionsPerClickThroughInstall: null,
      sumHourlyCaps: 0,
      smartPacingFieldsDisabled: true,
      perHourFieldEditable: [],
      schedule: [{from:'00:00',to:'23:59'}],
      timeFrom: null,
      timeTo: null,
      error: null,
      busy: false,
      message: null,
      formErrors: {},
      formIsValid: null
    };
    this.handleAddCampaignPacing = this.handleAddCampaignPacing.bind(this);
  }

  componentDidMount() {
    this.getDefaultSmartPacing();
    this.editableFieldsInit();
  }
  
  componentWillUnmount() {
    $(document).off("mousedown");
  }
  
  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    var formErrors = this.state.formErrors;
    
    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Add Campaign Pacing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            Please enter the id of the campaign for pacing.
          </p>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Campaign ID</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    max={15}
                    onChange={e =>
                      this.setState({ offerid: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>      
            </Row>
            <Row>
              <Col md={12}>
                <h5 className={"modal-sub-header"}>Campaign Pacing</h5>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          value="even"
                          onChange={e => {
                              this.setState({ pacingType: e.target.value });
                              this.resetFormErrorsValidation();
                              this.sumHourlyCaps(this.state.evenPacing);
                            }
                          }
                          checked={this.state.pacingType === "even"}
                        />
                        Even
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          value="smart"
                          onChange={e => {
                              this.setState({ pacingType: e.target.value });
                              this.resetFormErrorsValidation();
                              var baselineImpressionsPerDay = this.state.baselineImpressionsPerDay;
                              (this.state.smartPacing || []).forEach((pace, idx, array) => {
                                if(baselineImpressionsPerDay){
                                  this.onBaselineImpressionCapChange(parseInt(baselineImpressionsPerDay/24),idx);
                                }
                              });
                              this.sumHourlyCaps(this.state.smartPacing);
                            }
                          }
                          checked={this.state.pacingType === "smart"}
                        />
                        Smart
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          value="manual"
                          onChange={e =>{
                              this.setState({ pacingType: e.target.value });
                              this.resetFormErrorsValidation();
                              this.sumHourlyCaps(this.state.pacing);
                            }
                          }
                          checked={this.state.pacingType === "manual"}
                        />
                        Manual
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <label className="control-label" style={{fontSize:"16px", marginBottom:"15px", textTransform: "capitalize"}}>{this.state.pacingType?this.state.pacingType+" Pacing":""}</label>
              </Col>
            </Row>
            {this.state.pacingType === "even" && (
            <div>
              <Row className={"pacing-default-impressioncap-row"}>
                <Col md={10}>
                  <FormGroup>
                    <Row className="pacing-default-impressioncap">
                      <Col md={5} style={{marginRight: "20px"}}>
                        <ControlLabel>Default Daily Imp / Click Cap</ControlLabel>
                      </Col>
                      <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                        {(this.state.perHourFieldEditable["defaultDailyCap"]) && (
                          <FormControl
                            componentClass="input"
                            className="per-hour-input editable-input text-right"
                            type="number"
                            min={1}
                            autoFocus={true}
                            defaultValue={this.state.defaultEvenDailyImpCap}
                            onChange={e =>
                              this.setState({ defaultEvenDailyImpCap: e.target.value })
                            }
                          />
                        )}
                        {(!this.state.perHourFieldEditable["defaultDailyCap"]) && (
                          <div className="textDiv perHourTextDiv editable-div text-right"
                               onClick={e => {
                                 var perHourFieldEditable = [];
                                 perHourFieldEditable["defaultDailyCap"] = true;
                                 this.setState({ perHourFieldEditable:perHourFieldEditable });
                               }}>
                            {digitGrouping(this.state.defaultEvenDailyImpCap)}
                          </div>
                        )}
                        
                        {/*<FormControl
                          componentClass="input"
                          type="number"
                          min={1}
                          defaultValue={this.state.defaultEvenDailyImpCap}
                          onChange={e =>
                            this.setState({ defaultEvenDailyImpCap: e.target.value })
                          }
                        />*/}
                      </Col>
                      <Col md={2} xs={5}>
                        <Button
                          bsStyle="primary"
                          name="daily"
                          onClick={this.handleApplyDefaultImpCap.bind(this)}
                        > Apply </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row className={"pacing-default-impressioncap-row"}>
                <Col md={10}>
                  <FormGroup>
                    <Row className="pacing-default-impressioncap">
                      <Col md={5} style={{marginRight: "20px"}}>
                        <ControlLabel>Default Hourly Imp / Click Cap</ControlLabel>
                      </Col>
                      <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                        {(this.state.perHourFieldEditable["defaultHourlyCap"]) && (
                          <FormControl
                            componentClass="input"
                            className="per-hour-input editable-input text-right"
                            type="number"
                            min={1}
                            autoFocus={true}
                            defaultValue={this.state.defaultEvenImpCap}
                            onChange={e =>
                              this.setState({ defaultEvenImpCap: e.target.value })
                            }
                          />
                        )}
                        {(!this.state.perHourFieldEditable["defaultHourlyCap"]) && (
                          <div className="textDiv perHourTextDiv editable-div text-right"
                               onClick={e => {
                                 var perHourFieldEditable = [];
                                 perHourFieldEditable["defaultHourlyCap"] = true;
                                 this.setState({ perHourFieldEditable:perHourFieldEditable });
                               }}>
                            {digitGrouping(this.state.defaultEvenImpCap)}
                          </div>
                        )}
                       {/* <FormControl
                          componentClass="input"
                          type="number"
                          min={1}
                          defaultValue={this.state.defaultEvenImpCap}
                          onChange={e =>
                            this.setState({ defaultEvenImpCap: e.target.value })
                          }
                        />*/}
                      </Col>
                      <Col md={2} xs={5}>
                        <Button
                          bsStyle="primary"
                          onClick={this.handleApplyDefaultImpCap.bind(this)}
                        > Apply </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            )}
            {this.state.pacingType === "manual" && (
              <div>
                <Row className={"pacing-default-impressioncap-row"}>
                  <Col md={10}>
                    <FormGroup>
                      <Row className="pacing-default-impressioncap">
                        <Col md={5} style={{marginRight: "20px"}}>
                          <ControlLabel>Default Daily Imp / Click Cap</ControlLabel>
                        </Col>
                        <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                          {(this.state.perHourFieldEditable["defaultDailyCap"]) && (
                            <FormControl
                              componentClass="input"
                              className="per-hour-input editable-input text-right"
                              type="number"
                              min={1}
                              autoFocus={true}
                              defaultValue={this.state.defaultDailyImpCap}
                              onChange={e =>
                                this.setState({ defaultDailyImpCap: e.target.value })
                              }
                            />
                          )}
                          {(!this.state.perHourFieldEditable["defaultDailyCap"]) && (
                            <div className="textDiv perHourTextDiv editable-div text-right"
                                 onClick={e => {
                                   var perHourFieldEditable = [];
                                   perHourFieldEditable["defaultDailyCap"] = true;
                                   this.setState({ perHourFieldEditable:perHourFieldEditable });
                                 }}>
                              {digitGrouping(this.state.defaultDailyImpCap)}
                            </div>
                          )}
                          {/*<FormControl
                            componentClass="input"
                            type="number"
                            min={1}
                            defaultValue={this.state.defaultDailyImpCap}
                            onChange={e =>
                              this.setState({ defaultDailyImpCap: e.target.value })
                            }
                          />*/}
                        </Col>
                        <Col md={2} xs={5}>
                          <Button
                            bsStyle="primary"
                            name="daily"
                            onClick={this.handleApplyDefaultImpCap.bind(this)}
                          > Apply </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"pacing-default-impressioncap-row"}>
                  <Col md={10}>
                    <FormGroup>
                      <Row className="pacing-default-impressioncap">
                        <Col md={5} style={{marginRight: "20px"}}>
                          <ControlLabel>Default Hourly Imp / Click Cap</ControlLabel>
                        </Col>
                        <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                          {(this.state.perHourFieldEditable["defaultHourlyCap"]) && (
                            <FormControl
                              componentClass="input"
                              className="per-hour-input editable-input text-right"
                              type="number"
                              min={1}
                              autoFocus={true}
                              defaultValue={this.state.defaultImpCap}
                              onChange={e =>
                                this.setState({ defaultImpCap: e.target.value })
                              }
                            />
                          )}
                          {(!this.state.perHourFieldEditable["defaultHourlyCap"]) && (
                            <div className="textDiv perHourTextDiv editable-div text-right"
                                 onClick={e => {
                                   var perHourFieldEditable = [];
                                   perHourFieldEditable["defaultHourlyCap"] = true;
                                   this.setState({ perHourFieldEditable:perHourFieldEditable });
                                 }}>
                              {digitGrouping(this.state.defaultImpCap)}
                            </div>
                          )}
                          {/*<FormControl
                            componentClass="input"
                            type="number"
                            min={1}
                            defaultValue={this.state.defaultImpCap}
                            onChange={e =>
                              this.setState({ defaultImpCap: e.target.value })
                            }
                          />*/}
                        </Col>
                        <Col md={2} xs={5}>
                          <Button
                            bsStyle="primary"
                            onClick={this.handleApplyDefaultImpCap.bind(this)}
                          > Apply </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            )}
            {this.state.pacingType === "smart" && (
              <div>
                <Row className={"pacing-default-impressioncap-row"}>
                  <Col md={10}>
                    <FormGroup>
                      <Row className="pacing-default-impressioncap">
                        <Col md={5} style={{marginRight: "20px"}}>
                          <ControlLabel>Max Imps / Clicks per day</ControlLabel>
                        </Col>
                        <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                          {(this.state.perHourFieldEditable["maxImpressionsPerDay"]) && (
                            <FormControl
                              componentClass="input"
                              className="per-hour-input editable-input text-right"
                              type="number"
                              min={1}
                              autoFocus={true}
                              defaultValue={this.state.maxImpressionsPerDay}
                              //disabled={this.state.smartPacingFieldsDisabled}
                              onChange={e =>
                                this.setState({ maxImpressionsPerDay: e.target.value })
                              }
                            />
                          )}
                          {(!this.state.perHourFieldEditable["maxImpressionsPerDay"]) && (
                            <div className="textDiv perHourTextDiv editable-div text-right"
                                 onClick={e => {
                                   var perHourFieldEditable = [];
                                   perHourFieldEditable["maxImpressionsPerDay"] = true;
                                   this.setState({ perHourFieldEditable:perHourFieldEditable });
                                 }}>
                              {digitGrouping(this.state.maxImpressionsPerDay)}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"pacing-default-impressioncap-row"}>
                  <Col md={10}>
                    <FormGroup>
                      <Row className="pacing-default-impressioncap">
                        <Col md={5} style={{marginRight: "20px"}}>
                          <ControlLabel>Max Imps / Clicks per Hour</ControlLabel>
                        </Col>
                        <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                          {(this.state.perHourFieldEditable["maxImpressionsPerHour"]) && (
                            <FormControl
                              componentClass="input"
                              className="per-hour-input editable-input text-right"
                              type="number"
                              min={1}
                              autoFocus={true}
                              defaultValue={this.state.maxImpressionsPerHour}
                              //disabled={this.state.smartPacingFieldsDisabled}
                              onChange={e =>
                                this.setState({ maxImpressionsPerHour: e.target.value })
                              }
                            />
                          )}
                          {(!this.state.perHourFieldEditable["maxImpressionsPerHour"]) && (
                            <div className="textDiv perHourTextDiv editable-div text-right"
                                 onClick={e => {
                                   var perHourFieldEditable = [];
                                   perHourFieldEditable["maxImpressionsPerHour"] = true;
                                   this.setState({ perHourFieldEditable:perHourFieldEditable });
                                 }}>
                              {digitGrouping(this.state.maxImpressionsPerHour)}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"pacing-default-impressioncap-row"}>
                  <Col md={10}>
                    <FormGroup>
                      <Row className="pacing-default-impressioncap">
                        <Col md={5} style={{marginRight: "20px"}}>
                          <ControlLabel>Baseline Imps / Clicks per Day</ControlLabel>
                        </Col>
                        <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                          {(this.state.smartPacingFieldsDisabled) && (
                            <div className="textDiv text-right">{digitGrouping(this.state.baselineImpressionsPerDay)}</div>
                          )}
                          {(!this.state.smartPacingFieldsDisabled) && this.state.perHourFieldEditable["baselineImpressionsPerDay"] && (
                            <FormControl
                              componentClass="input"
                              className="per-hour-input editable-input text-right"
                              type="number"
                              min={1}
                              autoFocus={true}
                              defaultValue={this.state.baselineImpressionsPerDay}
                              //disabled={this.state.smartPacingFieldsDisabled}
                              onChange={e => {
                                this.setState({ baselineImpressionsPerDay: e.target.value });
                                (this.state.smartPacing || []).forEach((pace, idx, array) => {
                                  this.onBaselineImpressionCapChange(parseInt(e.target.value/24),idx);
                                });
                              }}
                            />
                          )}
                          {(!this.state.smartPacingFieldsDisabled && !this.state.perHourFieldEditable["baselineImpressionsPerDay"]) && (
                            <div className="textDiv perHourTextDiv editable-div text-right"
                                 onClick={e => {
                                   var perHourFieldEditable = [];
                                   perHourFieldEditable["baselineImpressionsPerDay"] = true;
                                   this.setState({ perHourFieldEditable:perHourFieldEditable });
                                 }}>
                              {digitGrouping(this.state.baselineImpressionsPerDay)}
                            </div>
                          )}
                          {/*{(!this.state.smartPacingFieldsDisabled) && (
                              <FormControl
                                componentClass="input"
                                className="text-right"
                                type="number"
                                min={1}
                                defaultValue={this.state.baselineImpressionsPerDay}
                                disabled={this.state.smartPacingFieldsDisabled}
                                onChange={e => {
                                  this.setState({ baselineImpressionsPerDay: e.target.value });
                                  (this.state.smartPacing || []).forEach((pace, idx, array) => {
                                    this.onBaselineImpressionCapChange(parseInt(e.target.value/24),idx);
                                  });
                                }}
                              />
                            )}*/}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"pacing-default-impressioncap-row"}>
                  <Col md={10}>
                    <FormGroup>
                      <Row className="pacing-default-impressioncap">
                        <Col md={5} style={{marginRight: "20px"}}>
                          <ControlLabel style={{marginRight: "10px"}}>Extra Imps / Clicks per Clickthrough Install</ControlLabel>
                        </Col>
                        <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                          {(this.state.smartPacingFieldsDisabled) && (
                            <div className="textDiv text-right">{digitGrouping(this.state.extraImpressionsPerClickThroughInstall)}</div>
                          )}
                          {(!this.state.smartPacingFieldsDisabled) && this.state.perHourFieldEditable["extraImpressionsPerClickThroughInstall"] && (
                            <FormControl
                              componentClass="input"
                              className="per-hour-input editable-input text-right"
                              type="number"
                              min={1}
                              autoFocus={true}
                              defaultValue={this.state.extraImpressionsPerClickThroughInstall}
                              //disabled={this.state.smartPacingFieldsDisabled}
                              onChange={e =>
                                this.setState({ extraImpressionsPerClickThroughInstall: e.target.value })
                              }
                            />
                          )}
                          {(!this.state.smartPacingFieldsDisabled && !this.state.perHourFieldEditable["extraImpressionsPerClickThroughInstall"]) && (
                            <div className="textDiv perHourTextDiv editable-div text-right"
                                 onClick={e => {
                                   var perHourFieldEditable = [];
                                   perHourFieldEditable["extraImpressionsPerClickThroughInstall"] = true;
                                   this.setState({ perHourFieldEditable:perHourFieldEditable });
                                 }}>
                              {digitGrouping(this.state.extraImpressionsPerClickThroughInstall)}
                            </div>
                          )}
                          {/*{(!this.state.smartPacingFieldsDisabled) && (
                            <FormControl
                              componentClass="input"
                              className="text-right"
                              type="number"
                              min={1}
                              defaultValue={this.state.extraImpressionsPerClickThroughInstall}
                              disabled={this.state.smartPacingFieldsDisabled}
                              onChange={e =>
                                this.setState({ extraImpressionsPerClickThroughInstall: e.target.value })
                              }
                            />
                          )}*/}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className={"pacing-default-impressioncap-row"}>
                  <Col md={10}>
                    <FormGroup>
                      <Row className="pacing-default-impressioncap">
                        <Col md={2} xs={5}>
                          <Button
                            bsStyle="primary"
                            name="smartPacingFieldsEditBtn"
                            disabled={!this.validateNewDefaults()}
                            onClick={ e => this.setState({smartPacingFieldsDisabled: !this.state.smartPacingFieldsDisabled}) }
                          > {this.state.smartPacingFieldsDisabled?"Edit fields":"Cancel edit"} </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
               {/* <Row className={"pacing-default-impressioncap-row"}>
                  <Col md={10}>
                    <FormGroup>
                      <Row className="pacing-default-impressioncap">
                        <Col md={2} xs={5}>
                          <Button
                            bsStyle="primary"
                            name="newDefaults"
                            disabled={!this.validateNewDefaults()}
                            onClick={this.handleSetNewDefaults.bind(this)}
                          > Set new defaults for all Campaigns </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>*/}
              </div>
            )}
            
            <Row className={"pacing-default-impressioncap-row"}>
              <Col md={10}>
                <FormGroup>
                  <Row className="pacing-default-impressioncap">
                    <Col md={5} style={{ marginRight: "20px" }}>
                      <ControlLabel>Sum of Hourly Imp / Click Caps</ControlLabel>
                    </Col>
                    <Col md={4} xs={8} className={"pacing-default-impressioncap-input-col"}>
                      <div className="sumTextDiv text-right">{this.state.sumHourlyCaps}</div>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            {(this.state.pacingType === "even" || this.state.pacingType === "smart" ? (this.state[this.state.pacingType+"Pacing"] || []) : (this.state.pacing || [])).map((pace, idx, array) => {
              return(
                <Row key={"pacingRow_key_"+idx} className={"pacing-row"}>
                  <Col md={4} style={{maxWidth:"210px", marginLeft: "15px"}}>
                    <FormGroup>
                      <Row className={"pacing-fields modal-sub-fields"}>
                        <div>
                          <ControlLabel>From</ControlLabel>
                          <FormControl
                            componentClass="input"
                            type="string"
                            disabled={true}
                            style={{width:"70px"}}
                            value={array[idx].from}
                            onChange={e =>{
                                var pacing = this.getPacing();
                                pacing[idx].from = e.target.value;
                                this.setPacing(pacing);
                              }
                            }
                          />
                        </div>
                        <div>
                          <ControlLabel>To</ControlLabel>
                          <FormControl
                            componentClass="input"
                            type="string"
                            disabled={true}
                            style={{width:"70px"}}
                            value={array[idx].to}
                            onChange={e =>{
                                var pacing = this.getPacing();
                                pacing[idx].to = e.target.value;
                                this.setPacing(pacing);
                              }
                            }
                          />
                        </div>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className={'pacing-impressioncap'+(formErrors[idx]?' form-error':'')}>
                      <ControlLabel>Imp / Click Cap</ControlLabel>
                      {(this.state.pacingType==="even"||this.state.pacingType==="smart") && (
                        <div className="textDiv text-right" style={{marginLeft: "5px"}}>{digitGrouping(array[idx].impCap)}</div>
                      )}
                      {(this.state.pacingType!=="even"&&this.state.pacingType!=="smart"&&this.state.perHourFieldEditable[idx]) && (
                        <FormControl
                          componentClass="input"
                          className="per-hour-input editable-input text-right"
                          type="number"
                          min={1}
                          name={"perHourInput"}
                          autoFocus={true}
                          value={array[idx].impCap !== null ? array[idx].impCap : ''}
                          onChange={e => this.onImpressionCapChange(e.target.value,idx)}
                        />
                      )}
                      {(this.state.pacingType!=="even"&&this.state.pacingType!=="smart"&&!this.state.perHourFieldEditable[idx]) && (
                        <div className="textDiv perHourTextDiv editable-div text-right"
                             onClick={e => {
                               var perHourFieldEditable = [];
                               perHourFieldEditable[idx] = true;
                               this.setState({ perHourFieldEditable:perHourFieldEditable });
                             }}>
                          {digitGrouping(array[idx].impCap)}
                        </div>
                      )}
                      <Row>
                        <Col md={12} sm={8} xs={10}>
                          <div className={"error-msg"}>{formErrors[idx]||''}</div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>);              
            })
            }
            <FormGroup className={formErrors.pacing?'form-error':null}>
              <Row>
                <Col md={12}>
                  <div className={"error-msg"}>{formErrors.pacing||''}</div>
                </Col>
              </Row>
            </FormGroup>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !this.state.offerid ||
              !this.state.pacing 
            }
            bsStyle="primary"
            onClick={this.handleAddCampaignPacing}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  getPacing(pacingT){
    var pacingType = pacingT || this.state.pacingType;
    return (pacingType === "even" || pacingType === "smart" ? (this.state[pacingType+"Pacing"] || []) : (this.state.pacing || []));
  }
  
  setPacing(pacing) {
    if(this.state.pacingType === "even"){
      this.setState({ evenPacing: pacing });
    } 
    else if(this.state.pacingType === "smart"){
      this.setState({ smartPacing: pacing });
    }
    else{
      this.setState({ pacing: pacing });
    }    
  }
  
  resetFormErrorsValidation(){
    this.setState({formErrors:{}, formIsValid:null})
  }
  
  validateNewDefaults(){
    if(!this.state.maxImpressionsPerHour || !this.state.maxImpressionsPerDay || !this.state.baselineImpressionsPerDay || !this.state.extraImpressionsPerClickThroughInstall){
      return false;
    }
    return true;
  }

  handleSetNewDefaults(e){

    var formIsValid = this.validateNewDefaults();
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    var postData = {
      maxImpressionsPerHour: this.state.maxImpressionsPerHour,
      maxImpressionsPerDay: this.state.maxImpressionsPerDay,
      baselineImpressionsPerDay: this.state.baselineImpressionsPerDay,
      extraImpressionsPerClickThroughInstall: this.state.extraImpressionsPerClickThroughInstall,
    };

    if (formIsValid) {
      axios({
        url: "/api/v1/tools/SetCampaignPacingDefaults",
        method: "post",
        data: postData
      })
        .then(res => {
          this.setState({
            busy: false,
            message: "The new defaults were saved successfully.",
            error: null
          });
          //this.props.onHide();
        })
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;
          this.setState({
            busy: false,
            message: null,
            error
          });
        });
    }
  }
  
  editableFieldsInit(){
    var _this = this;
    
    $(document).off("mousedown").on("mousedown", function (e)
    {
      var container1 = $(".editable-input");
      var container2 = $(".editable-div");

      if ((!container1.is(e.target) // if the target of the click isn't the container
        && container1.has(e.target).length === 0) && // nor a descendant of the container
        (!container2.is(e.target)
          && container2.has(e.target).length === 0))
      {
        _this.setState({perHourFieldEditable:[]});
      }
    });
  }
  
  onImpressionCapChange(value,idx){
      var pacing = this.getPacing();
      pacing[idx].impCap = value;
      this.setPacing(pacing);
      this.sumHourlyCaps(pacing);
  }

  onBaselineImpressionCapChange(value,idx){
    var pacing = this.state.smartPacing;
    pacing[idx].impCap = value;
    this.setState({ smartPacing: pacing });
    this.sumHourlyCaps(pacing);
  }
  
  sumHourlyCaps(pacing){
    var sumHourlyCaps = 0;
    (pacing || []).forEach((pace, idx, array) => {
      var parsed = parseInt(pace.impCap);
      if (isNaN(parsed)) { parsed = 0; }
      sumHourlyCaps = sumHourlyCaps + parsed;
    });
    sumHourlyCaps = digitGrouping(sumHourlyCaps);
    
    this.setState({ sumHourlyCaps: sumHourlyCaps});
  }
  
  getDefaultSmartPacing(){
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/getCampaignPacingDefaults",
      method: "get",
    })
      .then(res => {
        var smartpacingDefaults = res.data || null;
        var maxImpressionsPerDay = this.state.maxImpressionsPerDay;
        var maxImpressionsPerHour = this.state.maxImpressionsPerHour;
        var baselineImpressionsPerDay = this.state.baselineImpressionsPerDay;
        var extraImpressionsPerClickThroughInstall = this.state.extraImpressionsPerClickThroughInstall;

        this.setState({
          smartpacingDefaults:smartpacingDefaults,
          maxImpressionsPerDay: !maxImpressionsPerDay?smartpacingDefaults.maxImpressionsPerDay||null:maxImpressionsPerDay,
          maxImpressionsPerHour: !maxImpressionsPerHour?smartpacingDefaults.maxImpressionsPerHour||null:maxImpressionsPerHour,
          baselineImpressionsPerDay: !baselineImpressionsPerDay?smartpacingDefaults.baselineImpressionsPerDay||null:baselineImpressionsPerDay,
          extraImpressionsPerClickThroughInstall: !extraImpressionsPerClickThroughInstall?smartpacingDefaults.extraImpressionsPerClickThroughInstall||null:extraImpressionsPerClickThroughInstall,
          busy: false,
          message: null,
          error: null
        });

      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
  
  getDefaultPacing(){
    var defaultPacing = [];
    
    for(var i=0; i<24; i++){
      var fromTime = (i<10?'0':'')+i+':00';
      var toTime = i<23 ? ( ((i+1)<10?'0':'')+(i+1)+':00') : i+':59';
      defaultPacing.push({
        from:fromTime,
        to:toTime,
        impCap:null
      });
    }
    
    return defaultPacing;
  }

  handleApplyDefaultImpCap(e){
    var pacingType = this.state.pacingType;
    var defaultImpCap = this.state.defaultImpCap;
    var defaultDailyImpCap = this.state.defaultDailyImpCap;
    var pacing = this.getPacing();
    
    if(pacingType === "even"){
      defaultImpCap = this.state.defaultEvenImpCap;
      defaultDailyImpCap = this.state.defaultEvenDailyImpCap;
    }
    (pacing||[]).forEach((pace,idx)=>{
      if(pace){
        if(e.currentTarget.name==="daily"){
          pace.impCap = parseInt(defaultDailyImpCap/24);
        }else{
          pace.impCap = defaultImpCap;
        }
      }
    });
    
    if(pacingType === "even"){
      this.setState({ evenPacing: pacing });
    }
    else if(pacingType === "smart"){
      this.setState({ smartPacing: pacing });
    }
    else{
      this.setState({ pacing: pacing });
    }
    
    this.sumHourlyCaps(pacing);
  }
  
  formatPacing(p){
    var pacing = p;
    var pacingFormatted = [];
    pacing.forEach((pace,idx)=>{
      var impressionCap = pace.impCap!==null && pace.impCap!==undefined ? parseInt(pace.impCap):null;
      pacingFormatted.push({timeRange:pace.from+"-"+pace.to, impressionCap:impressionCap});
    });
    return pacingFormatted;
  }

  validatePacing(p){
    var pacing = p;
    var formErrors = this.state.formErrors;
    var formIsValid = this.state.formIsValid;
    formErrors = {};
    formIsValid = true;

    pacing.forEach((pace,idx)=>{
      if(pace){
        formErrors[idx]=false;

        if((pace.impCap!==null && (parseInt(pace.impCap)<1 || isNaN(parseInt(pace.impCap)))) || !pace.impCap){
          formErrors[idx]="Please enter a valid Imp / Click Cap (must be > 0)";
          formErrors.pacing = "Enter Imp / Click Cap for all time slots";
          formIsValid = false;
        }
      }
    });

    this.setState({formErrors:formErrors, formIsValid:formIsValid});
    return formIsValid;
  }
  
  handleAddCampaignPacing() {
    var pacingType = this.state.pacingType||null;
    var pacing = this.state.pacing; //manual pacing
    if(pacingType==="even"){
      pacing = this.state.evenPacing;
    }
    if(pacingType==="smart"){
      pacing = this.state.smartPacing;
    }
    var formIsValid = this.validatePacing(pacing);
    
    this.setState({
      busy: true,
      message: null,
      error: null,
      formIsValid: formIsValid
    });
    
    var pacingFormatted = this.formatPacing(pacing);
    var pacingFiltered = pacingFormatted.filter(function (pace) {
      return pace.impressionCap > 0
    });

    var smartPacingFields = {
      maxImpressionsPerHour: this.state.maxImpressionsPerHour,
      maxImpressionsPerDay: this.state.maxImpressionsPerDay,
      baselineImpressionsPerDay: this.state.baselineImpressionsPerDay,
      extraImpressionsPerClickThroughInstall: this.state.extraImpressionsPerClickThroughInstall,
    }
    
    var postData = {
      OfferId: this.state.offerid,
      Pacing: pacingFiltered,
      SmartPacing: pacingType==="smart"?smartPacingFields:null,
      PacingType: pacingType,
    };
    
    if(formIsValid){
      axios({
        url: "/api/v1/tools/addCampaignPacing",
        method: "post",
        data: postData
      })
        .then(res => {
          this.setState({
            busy: false,
            message: "The operation completed successfully.",
            error: null
          });
          this.props.onHide();
        })
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;
          this.setState({
            busy: false,
            message: null,
            error
          });
        });
    }
    
  }
}
