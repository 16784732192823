import React from "react";
import FormControl from "react-bootstrap/lib/FormControl";
import { IDataTable } from "./types";

type SearchBoxProps = FormControl.FormControlProps & {
  tableRef: React.RefObject<IDataTable>;
};

const SearchBox: React.FC<SearchBoxProps> = ({ tableRef, ...rest }) => (
  <FormControl
    onChange={e => tableRef.current?.search((e.target as any).value)}
    {...rest}
  />
);

export default SearchBox;
