import React from "react";
import $ from "jquery";
import DataTable from "components/DataTable";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.createdStatusCell = this.createdStatusCell.bind(this);
  }

  render() {
    const { data } = this.props;
    const tableRef = React.createRef();

    return (
      <>
        <DataTable.Toolbar tableRef={tableRef} bsSize="small" noReloadButton />
        <DataTable
          ref={tableRef}
          className="table table-condensed table-bordered"
          columns={[
            DataTable.StockColumns.textTruncate("externalId", "ExternalID"),
            { title: "Internal ID", data: "campaignId" },
            { title: "Name", data: "name" },
            {
              title: "Import Status",
              data: "decision",
              createdCell: this.createdStatusCell
            }
          ]}
          data={data.details || []}
          order={[]}
          deferRender={true}
        />
      </>
    );
  }

  createdStatusCell(cell, cellData, rowData, rowIndex, colIndex) {
    const { onSelectOffer } = this.props;
    const status = this.getImportStatus(rowData);
    const content = {
      Accepted: $(`<span class="text-success">${status}</span>`),
      Removed: $(`<span class="text-muted">${status}</span>`),
      Suspended: $(`<span class="text-warning">${status}</span>`),
      Rejected: $(
        `<a href="#"><span class="text-danger">${status}</span></a>`
      ).on("click", e => {
        e.preventDefault();
        onSelectOffer(rowData);
      })
    }[status];
    $(cell).html(content);
  }

  getImportStatus(summary) {
    if (summary.shouldRemove) return "Removed";

    switch (summary.decision) {
      case "ReAdd":
      case "Revalidate":
      case "Resume":
      case "Add":
      case "Update":
        return "Accepted";

      case "SuspendReAdd":
      case "SuspendRevalidate":
      case "Suspend":
      case "AddSuspend":
      case "UpdateSuspended":
        return "Suspended";

      case "InvalidateReAdd":
      case "Invalidate":
      case "AddInvalidate":
      case "IgnoreUncertain":
      case "UpdateInvalid":
      default:
        return "Rejected";
    }
  }
}
