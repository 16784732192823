import React from "react";
import PageLayout from "components/PageLayout";
import { Box } from "components/admin-lte";
import { Row, FormGroup, ControlLabel, Col } from "react-bootstrap";
import DateRangePicker from "components/pickers/DateRangePicker";
import moment from "moment-timezone";
import download from "downloadjs";
import axios from "axios";
import { extractFilenameFromResponse } from "lib/Functions";
import buildErrorSummary from "components/ErrorSummary";
import DownloadButton from "components/stock/DownloadButton";

moment.tz.setDefault("UTC");

export default class WhitelabelPublisherReport extends React.Component {
  static title = "Whitelabel Publisher Report";

  constructor(props) {
    super(props);
    this.state = {
      publisher: null,
      date: null,
      busy: false,
      error: null
    };
    this.handleDownload = this.handleDownload.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={WhitelabelPublisherReport.title}
        description="This is a custom report for specific whitelabel publishers, designed according to their specs. It contains traffic data and revenues grouped by offer, one worksheet per day."
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={this.state.busy}>
          <Box.Body>
            <Row>
              <Col md={3} sm={12}>
                <FormGroup>
                  <ControlLabel>Publisher</ControlLabel>
                  <select
                    className="form-control"
                    onChange={e => this.setState({ publisher: e.target.value })}
                  >
                    <option value=""></option>
                    <option value="1373697849">
                      advertis.digital@pubs.spinx.biz
                    </option>
                    <option value="1373698039">
                      toptopmedia@pubs.spinx.biz
                    </option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Date</ControlLabel>
                  <DateRangePicker
                    className="form-control"
                    onChange={value => this.setState({ date: value })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <DownloadButton
              onClick={this.handleDownload}
              disabled={!(this.state.publisher && this.state.date)}
            />
          </Box.Footer>
        </Box>
      </PageLayout>
    );
  }

  handleDownload() {
    this.setState({ busy: true, error: null });

    axios({
      url: "/api/v1/reports/custom/whitelabelpublisherreport",
      method: "post",
      responseType: "blob",
      data: {
        publisherId: Number(this.state.publisher),
        fromDateInclusiveUtc: moment(this.state.date[0]).format("YYYY-MM-DD"),
        toDateExclusiveUtc: moment(this.state.date[1])
          .add(1, "second")
          .format("YYYY-MM-DD"),
        output: "Excel"
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          error: res.status === 204 ? "No data." : null
        });
        if (res.status === 200) {
          const filename = extractFilenameFromResponse(res) || "report.xlsx";
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        }
      })
      .catch(err => {
        const error =
          err.response && err.response.status === 401
            ? "Your session has expired. Please refresh the page."
            : err.response
            ? err.response.data
            : err.request || err.message;

        this.setState({
          busy: false,
          error
        });
      });
  }
}
