import React from "react";
import { Tab, Nav, NavItem } from "react-bootstrap";
import ResultTab from "./ResultTab";

export default class extends React.Component {
  render() {
    return (
      <div className="nav-tabs-custom overlay-wrapper">
        <Tab.Container id="result-tabs" defaultActiveKey="demand">
          <>
            <Nav bsStyle="tabs">
              <NavItem eventKey="demand">Demand</NavItem>
              <NavItem eventKey="supply">Supply</NavItem>
            </Nav>
            <Tab.Content animation={false}>
              <Tab.Pane eventKey="demand">
                <ResultTab
                  affiliateSide="demand"
                  response={this.props.response}
                  defaultDimension="revenue"
                  defaultRange={2}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="supply">
                <ResultTab
                  affiliateSide="supply"
                  response={this.props.response}
                  defaultDimension="revenue"
                  defaultRange={2}
                />
              </Tab.Pane>
            </Tab.Content>
          </>
        </Tab.Container>
      </div>
    );
  }
}
