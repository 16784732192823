import React from "react"
import $ from "jquery"
import moment from "moment-timezone"
import "bootstrap-daterangepicker"
import "bootstrap-daterangepicker/daterangepicker.css"

export const DATE_FORMAT = "DD/MM/YYYY"

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.handleApply = this.handleApply.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.notifyChanged = this.notifyChanged.bind(this)
  }

  render() {
    return (
      <div>
        <input className={this.props.className} disabled={this.props.disabled} ref="input" />
      </div>
    )
  }

  componentDidMount() {
    const input = $(this.refs.input)

    const defaults = {
      ranges: {
        "Today": [
          moment().startOf("day"),
          moment().endOf("day")
        ],
        "Yesterday": [
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day")
        ],
        "This week so far": [
          moment().startOf("isoWeek"),
          moment().endOf("day")
        ],
        "Last week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek")
        ],
        "This month so far": [
          moment().startOf("month"),
          moment().endOf("day")
        ],
        "Last month": [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month")
        ]
      },
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      locale: {
        format: this.props.dateFormat ? this.props.dateFormat:DATE_FORMAT,
        firstDay: 1,
        cancelLabel: "Clear"
      },
      minDate: moment().subtract(64, "days"),
      maxDate: moment().endOf("day")
    }

    // allow user to override everything but autoUpdateInput
    const { autoUpdateInput, ...rest } = this.props

    const options = Object.assign(defaults, { ...rest })

    input.daterangepicker(options)
    input.on("apply.daterangepicker", this.handleApply)
    input.on("cancel.daterangepicker", this.handleCancel)

    if (autoUpdateInput !== false) {
      this.handleApply(null, options)
    }
  }

  componentWillUnmount() {
    $(this.refs.input).data("daterangepicker").remove()
  }

  handleApply(e, picker) {
    const input = $(this.refs.input)
    const d1 = picker.startDate
    const d2 = picker.endDate
    this.updateDisplay(input, d1, d2)
    this.notifyChanged(d1, d2)
  }

  handleCancel(e, picker) {
    const input = $(this.refs.input)
    this.updateDisplay(input)
    this.notifyChanged()
  }

  updateDisplay(input, d1, d2) {
    if (!d1 || !d2) {
      input.val(null)
    }
    else {
      const f = x => x.format(this.props.dateFormat ? this.props.dateFormat : DATE_FORMAT)
      input.val(d1.isSame(d2, "date") ? f(d1) : f(d1) + " - " + f(d2))
    }
  }

  notifyChanged(d1, d2) {
    const { onChange } = this.props
    if (!d1 && !d2) {
      onChange(null)
    }
    else {
      onChange([d1, d2])
    }
  }
}