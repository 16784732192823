import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import UserPicker from "components/pickers/UserPicker";
import axios from "axios";
import DataTable from "components/DataTable";
import download from "downloadjs";
import { buildGetParams, extractFilenameFromResponse } from "lib/Functions";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Checkbox,
  ButtonToolbar
} from "react-bootstrap";
import Hint from "components/Hint";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

export default class PublisherFeed extends React.Component {
  static title = "Publisher Feed";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      publisher: null,
      includeOfferDetails: false,
      includeAdminReportInfo: false,
      busy: false,
      error: null,
      response: null
    };
  }

  render() {
    const { error, busy, response } = this.state;
    const errorSummary = buildErrorSummary(error);
    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={PublisherFeed.title}
        description="Offers that are assigned/targeted to a specific publisher with the advertiser information included."
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={busy}>
          <Box.Body>
            <Row>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <ControlLabel>Publisher</ControlLabel>
                  <UserPicker
                    className="form-control"
                    placeholder="Enter id or name"
                    onChange={value => this.setState({ publisher: value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Checkbox
                        inline
                        defaultChecked={this.state.includeAdminReportInfo}
                        onChange={e =>
                          this.setState({
                            includeAdminReportInfo: e.target.checked
                          })
                        }
                      >
                        Include admin report information&nbsp;
                        <Hint id="about-include-admin-report-information">
                          Includes conversions and events totals from today's
                          admin report for the selected publisher and for each
                          offer currently in the feed.
                        </Hint>
                      </Checkbox>
                    </Col>
                    <Col md={12}>
                      <Checkbox
                        inline
                        defaultChecked={this.state.includeOfferDetails}
                        onChange={e =>
                          this.setState({
                            includeOfferDetails: e.target.checked
                          })
                        }
                      >
                        Include offer details&nbsp;
                        <Hint id="about-include-offer-details">
                          <p>
                            Includes information from the popup window that
                            appears when you click on an offer in the{" "}
                            <em>My API Offers</em> page. Specifically:
                          </p>
                          <ul>
                            <li>Platform</li>
                            <li>Store</li>
                            <li>Geos</li>
                            <li>Traffic Type</li>
                            <li>Payout</li>
                            <li>Daily Cap</li>
                            <li>Tracking Link</li>
                            <li>Preview Link</li>
                          </ul>
                          <p className="text-danger">
                            This will generate a significant amount of calls to
                            Minimob, especially if the publisher's feed contains
                            many offers.
                            <strong>
                              Please DO NOT check this if you don't really need
                              it.
                            </strong>
                          </p>
                        </Hint>
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <DownloadButton
                onClick={this.handleDownloadOrPreview.bind(this, true)}
              />
              <PreviewButton
                onClick={this.handleDownloadOrPreview.bind(this, false)}
              />
            </ButtonToolbar>
          </Box.Footer>
        </Box>
        {response && (
          <Box>
            <Box.Body>
              <DataTable.Toolbar
                tableRef={this.tableRef}
                bsSize="small"
                noReloadButton
              />
              <DataTable
                className="table table-striped"
                ref={this.tableRef}
                data={response.data}
                columns={[
                  {
                    title: `<i title="Platform" class="fa fa-info-circle fa-1_4x grey"></i>`,
                    data: "targetPlatform",
                    className: "columnset-includeOfferDetails",
                    render: DataTable.StockRenderers.platform()
                  },
                  {
                    title: `<i title="Store" class="fa fa-info-circle fa-1_4x grey"></i>`,
                    data: "storeId",
                    className: "columnset-includeOfferDetails",
                    render: DataTable.StockRenderers.store()
                  },
                  {
                    title: "Offer ID",
                    data: "offer_id",
                    name: "Offer ID"
                  },
                  {
                    title: "Offer Name",
                    data: "offer_name",
                    name: "Offer Name"
                  },
                  {
                    title: "Advertiser Name",
                    data: "advertiser_name",
                    name: "Advertiser Name"
                  },
                  {
                    title: "Countries",
                    data: "geos",
                    className: "truncate-cell columnset-includeOfferDetails",
                    render: DataTable.StockRenderers.textWithTooltip()
                  },
                  {
                    title: `<i title="Traffic Type" class="fa fa-info-circle fa-1_4x grey"></i>`,
                    data: "incentivized",
                    className: "columnset-includeOfferDetails",
                    render: DataTable.StockRenderers.trafficType()
                  },
                  {
                    title: "Payout",
                    data: "payout",
                    className: "text-right columnset-includeOfferDetails",
                    render: DataTable.StockRenderers.money()
                  },
                  {
                    title: "Cap",
                    data: "dailyConversionCap",
                    className: "text-right columnset-includeOfferDetails",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Tracking Link",
                    data: "objectiveUrl",
                    className: "truncate-cell columnset-includeOfferDetails",
                    render: DataTable.StockRenderers.textWithTooltip()
                  },
                  {
                    title: "Preview Link",
                    data: "appPreviewLink",
                    className: "truncate-cell columnset-includeOfferDetails",
                    render: DataTable.StockRenderers.textWithTooltip()
                  },
                  {
                    title: "Clicks",
                    data: "clicks",
                    name: "Clicks",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Conversions",
                    data: "conversions",
                    name: "Conversions",
                    className: "text-right columnset-includeAdminReportInfo",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "<span>Conversions&nbsp;Opt</span>",
                    data: "conversionsOpt",
                    name: "Conversions Opt",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Events",
                    data: "events",
                    name: "Events",
                    className: "text-right columnset-includeAdminReportInfo",
                    render: DataTable.StockRenderers.count()
                  }
                ]}
                order={[]}
              />
            </Box.Body>
          </Box>
        )}
      </PageLayout>
    );
  }

  handleDownloadOrPreview(excel) {
    this.setState({ busy: true, error: null });
    axios
      .get("/api/v1/reports/PublisherFeed", {
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        responseType: excel ? "blob" : "json",
        params: buildGetParams({
          publisherId: this.state.publisher,
          includeOfferDetails: this.state.includeOfferDetails,
          includeAdminReportInfo: this.state.includeAdminReportInfo,
          output: excel ? "Excel" : "Resultset"
        })
      })
      .then(res => {
        if (excel) {
          this.setState({
            busy: false,
            error: null
          });
          const filename =
            extractFilenameFromResponse(res) ||
            `${this.state.publisher}_PublisherFeed.xlsx`;
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState(
            {
              busy: false,
              error: null,
              response: res.data
            },
            () => {
              if (!excel) this.toggleColumns.call(this);
            }
          );
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              busy: false,
              error: e.srcElement.result
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            busy: false,
            error
          });
        }
      });
  }

  toggleColumns() {
    const api = this.tableRef.current.api;
    const redraw = false;
    api
      .columns(".columnset-includeOfferDetails")
      .visible(this.state.includeOfferDetails, redraw);
    api
      .columns(".columnset-includeAdminReportInfo")
      .visible(this.state.includeAdminReportInfo, redraw);
    api.columns.adjust().draw(false);
  }
}
