import React from "react";
import ReactDOMServer from "react-dom/server";
import * as renderers from "./renderers";
import DataTable from "components/DataTable";

export default [
  {
    title: "Name",
    data: "advertiserName",
    className: "text-nowrap",
    render: renderers.renderName
  },
  {
    title: "Platform",
    data: "platformName",
    className: "text-nowrap columnset columnset-dev",
    render: renderers.renderPlatform
  },
  {
    title: "Server",
    data: "server",
    name: "server",
    className: "text-nowrap columnset columnset-default columnset-dev"
  },
  {
    title: "Click Param",
    data: "clickParameter",
    className: "text-nowrap columnset columnset-dev",
    render: renderers.renderClickParameter
  },
  {
    title: "Endpoints",
    data: "endpoints",
    className: "truncate-cell-wide columnset columnset-default columnset-dev",
    render: renderers.renderStringArray
  },
  {
    title: "Endpoints",
    data: "endpoints",
    className: "columnset columnset-endpoints",
    render: renderers.renderStringArray
  },
  {
    title: "Advertiser Account",
    data: "advertiserAccount",
    className: "columnset columnset-salesmen"
  },
  {
    title: "Management Accounts",
    data: "salesmen",
    className: "columnset columnset-default columnset-salesmen",
    render: renderers.renderStringArray
  },
  {
    title: "Office",
    data: "office",
    className: "columnset columnset-default columnset-salesmen"
  },
  {
    title: "Team",
    data: "team",
    className: "columnset columnset-default columnset-salesmen"
  },
  {
    title: "AM",
    data: "accountManager",
    className: "columnset columnset-default columnset-salesmen"
  },
  {
    title: "Adops",
    data: "adops",
    className: "columnset columnset-salesmen"
  },
  {
    title: "Discount",
    data: "discount",
    className: "columnset columnset-moderation"
  },
  {
    title: "Last Run",
    data: "lastRunTime",
    className: "text-nowrap columnset columnset-default",
    render: renderers.renderFriendlyTime
  },
  {
    title: "Next Run",
    data: "nextRunTime",
    className: "text-nowrap columnset columnset-default",
    render: renderers.renderFriendlyTime
  },
  {
    title: "Created",
    data: "registrationDate",
    className: "text-nowrap columnset columnset-dev",
    render: renderers.renderDate
  },
  {
    title: "EmptyFeed",
    data: "emptyFeed",
    className: "columnset columnset-advanced",
    render: renderers.renderSettingWithReason("emptyFeed")
  },
  {
    title: "DownloadFeedClientTimeout",
    data: "downloadFeedClientTimeout",
    className: "columnset columnset-advanced"
  },
  {
    title: "MinPayout",
    data: "minPayout",
    className: "columnset columnset-advanced"
  },
  {
    title: "MaxPayout",
    data: "maxPayout",
    className: "columnset columnset-advanced"
  },
  {
    title: "IncludeCountries",
    data: "includeCountries",
    className: "columnset columnset-advanced"
  },
  {
    title: "ExcludeCountries",
    data: "excludeCountries",
    className: "columnset columnset-advanced",
    render: renderers.renderSettingWithReason("excludeCountries")
  },
  {
    title: "IgnoreBlacklist",
    data: "ignoreBlacklist",
    className: "columnset columnset-advanced"
  },
  {
    title: "TestCampaigns",
    data: "testCampaigns",
    className: "columnset columnset-advanced"
  },
  {
    title: "Offers in Moderation",
    data: "offers",
    className: "text-right columnset columnset-default columnset-moderation"
  },
  {
    title: "Pending",
    data: "pendingOffers",
    className: "text-right columnset columnset-moderation"
  },
  {
    title: "Available",
    data: "availableOffers",
    className: "text-right columnset columnset-moderation"
  },
  {
    title: "Rejected",
    data: "rejectedOffers",
    className: "text-right columnset columnset-moderation"
  },
  {
    title: "Unique Package Names",
    data: "uniquePackageNames",
    className: "text-right columnset columnset-moderation"
  },
  {
    title: "Auto-commission",
    data: "autoCommission",
    className: "columnset columnset-moderation"
  },
  {
    title: "Auto-moderation",
    data: "autoModeration",
    className: "columnset columnset-moderation"
  },
  {
    title: "Auto-opt",
    data: "autoOptimization",
    className: "columnset columnset-moderation"
  },
  {
    title: "EmptyFeed",
    data: "emptyFeed",
    className: "text-nowrap columnset columnset-dev",
    render: renderers.renderSettingWithReason("emptyFeed")
  },
  {
    title: "Status",
    name: "status",
    data: "status",
    className: "status-max-width",
    render: renderers.renderStatus
  },
  DataTable.createUserCommandColumn({
    title: "",
    defaultContent: ReactDOMServer.renderToStaticMarkup(
      <i className="fa fa-history"></i>
    ),
    orderable: false,
    commandName: "",
    commandData: ({ rowData }) => ({
      id: rowData.advertiserId,
      name: rowData.advertiserName
    })
  }),
  {
    visible: false,
    name: "active",
    data: "active"
  },
  {
    visible: false,
    name: "failed",
    data: "failed"
  }
];
