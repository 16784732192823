import React from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import { AdvertiserPicker } from "components/pickers/SelectPickers";

function ByAdvertiserTab({ onChange }) {
  return (
    <>
      <p>
        Add all offers that belong to a network or advertiser and are currently
        in Moderation.
      </p>
      <Row>
        <Col md={6}>
          <FormGroup>
            <AdvertiserPicker
              className="form-control"
              placeholder="Enter advertiser id or name"
              onChange={advertiserId => onChange(advertiserId)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default ByAdvertiserTab;
