import React from "react";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { input: "" };
  }

  render() {
    const { publishers, busy, onUpdateList, onClearList } = this.props;
    const { input } = this.state;

    const rows =
      publishers === null || publishers.length === 0 ? (
        <tr>
          <td colSpan="2" className="text-center text-muted">
            No publishers selected
          </td>
        </tr>
      ) : (
        publishers.map(x => (
          <tr key={x._user_id}>
            <td>{x._user_id}</td>
            <td>{x._username}</td>
          </tr>
        ))
      );

    return (
      <div>
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Enter ANY TEXT containing publisher ids"
            disabled={busy}
            onChange={e => this.setState({ input: e.target.value })}
          ></textarea>
        </div>
        <div className="form-group">
          <div className="btn-group btn-group-sm">
            <button
              className="btn btn-default"
              disabled={busy || !input}
              onClick={() => onUpdateList(input)}
            >
              Update list
            </button>
            <button
              className="btn btn-default"
              disabled={busy || publishers === null || publishers.length === 0}
              onClick={() => onClearList()}
            >
              Clear list
            </button>
          </div>
        </div>
        <div className="form-group">
          <table className="table table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
