import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import axios from "axios";
import download from "downloadjs";
import { extractFilenameFromResponse, N } from "lib/Functions";
import PageLayout from "components/PageLayout";
import { ButtonGroup, Button } from "react-bootstrap";
import HopsDialog from "./HopsDialog";
import ApprovalDialog from "./ApprovalDialog";
import TestingDialog from "./TestingDialog";
import UnblockingDialog from "./UnblockingDialog";
import CloneDialog from "./CloneDialog";
import DownloadButton from "components/stock/DownloadButton";
import { UserContext } from "lib/user";

function renderRemainingCap(d, t, r, m) {
  if (t === "display") {
    if (d === 0) return "-";
    return N(d, 0);
  }
  return d;
}

function renderClickRange(d, t, r, m) {
  if (t === "filter") {
    if (d > 0 && d < 5001) return "a";
    if (d > 5000 && d < 20001) return "b";
    if (d > 20000 && d < 100001) return "c";
    if (d > 100000 && d < 500001) return "d";
    if (d > 500000) return "e";
    if (d === 0) return "f";
  }
  return d;
}

function renderConversionsRange(d, t, r, m) {
  if (t === "filter") {
    if (d > 0 && d < 6) return "a";
    if (d > 5 && d < 21) return "b";
    if (d > 20 && d < 51) return "c";
    if (d > 50 && d < 101) return "d";
    if (d > 100) return "e";
    if (d === 0) return "f";
  }
  return d;
}

function renderHopsRange(d, t, r, m) {
  if (t === "filter") {
    if (d > 0 && d < 6) return "a";
    if (d > 5 && d < 11) return "b";
    if (d > 10) return "c";
  }
  return d;
}

function renderDirectOffer(d, t, r, m) {
  if (t === "display") {
    return d === true ? "<span title='This offer is Direct'>D</span>" : "";
  } else if (t === "filter") {
    return `${d === true}`;
  }
  return d;
}

export default class RedirectionData extends React.Component {
  static title = "Affilitest Data";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      remainingCalls: null,
      selectedRows: [],
      showHopsFor: null,
      showApproval: false,
      showTesting: false,
      showUnblocking: false,
      showClone: false,
      busy: false,
      error: null
    };
    this.handleDownload = this.handleDownload.bind(this);
  }

  render() {
    return (
      <>
        <PageLayout
          breadcrumbs={["Reports"]}
          title={RedirectionData.title}
          description={
            <p>
              The offers are checked through the Affilitest API twice a day at
              00:00 UTC and at 12:00 UTC.
              <br />
              If you want to test an offer outside the scheduled task, you can
              select it and click the "Test..." button.
              <br />
              If an offer does not contain any information about the hops or the
              redirection to the store, then it is not tested yet by the
              Affilitest API or the response from the API was: 'timeout'.
              <br />
              The displayed Offer Stats are calculated using a rolling 24-hour
              period.
              <br />
              The revenues data are calculated based on a rolling 24-hour
              period.
              <br />
              New Offer: True means that the Offer was created in the last 48
              hours.
              <br />
              Recycled: True means that this is our own offer and was returned
              to us by a network.
              <br />
              Clone: You can use this button in order to clone a campaign. It is
              working only for the advertiser ui campaigns that have a payout lower
              than 100$. The service is not applicable for network offers.
              <br />
              Remaining calls:{" "}
              {this.state.remainingCalls
                ? this.state.remainingCalls.toLocaleString("en-US")
                : "Calculating..."}
            </p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box busy={this.state.busy}>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                  <ButtonGroup>
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="targetPlatform"
                      bsSize="small"
                      title="Platform"
                      values={[
                        ["*", ""],
                        ["Android", "Android"],
                        ["iOS", "iOS"]
                      ]}
                      defaultValue=""
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="incentivized"
                      bsSize="small"
                      title="Traffic Type"
                      values={[
                        ["*", ""],
                        ["Both", 0],
                        ["Incent", 1],
                        ["Non-incent", 2],
                        ["Uncertain", 3]
                      ]}
                      defaultValue=""
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="isDirectOffer"
                      bsSize="small"
                      title="Direct"
                      defaultValue=""
                      values={[
                        ["*", ""],
                        ["Direct Only", "true"],
                        ["Not Direct", "false"]
                      ]}
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="clicks"
                      bsSize="small"
                      title="Clicks"
                      values={[
                        ["*", ""],
                        ["= 0", "f"],
                        ["1 - 5000", "a"],
                        ["5001 - 20000", "b"],
                        ["20001 - 100000", "c"],
                        ["100001 - 500000", "d"],
                        ["500000+", "e"]
                      ]}
                      defaultValue=""
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="conversions"
                      bsSize="small"
                      title="Installs"
                      values={[
                        ["*", ""],
                        ["= 0", "f"],
                        ["1 - 5", "a"],
                        ["6 - 20", "b"],
                        ["21 - 50", "c"],
                        ["51 - 100", "d"],
                        ["100+", "e"]
                      ]}
                      defaultValue=""
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="moderationStatus"
                      bsSize="small"
                      title="Status"
                      values={[
                        ["*", ""],
                        ["Available", 1],
                        ["Pending", 0],
                        ["Rejected", 2]
                      ]}
                      defaultValue=""
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="hops"
                      bsSize="small"
                      title="Hops"
                      values={[
                        ["*", ""],
                        ["1 - 5", "a"],
                        ["6 - 10", "b"],
                        ["10+", "c"]
                      ]}
                      defaultValue=""
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="isPreviewValid"
                      bsSize="small"
                      title="Redirects To Store"
                      values={[
                        ["*", ""],
                        ["False", "false"],
                        ["True", "true"]
                      ]}
                      defaultValue=""
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="newOffer"
                      bsSize="small"
                      title="New Offer"
                      values={[
                        ["*", ""],
                        ["False", "false"],
                        ["True", "true"]
                      ]}
                      defaultValue=""
                    />
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="isRecycled"
                      bsSize="small"
                      title="Recycled"
                      values={[
                        ["*", ""],
                        ["False", "false"],
                        ["True", "true"]
                      ]}
                      defaultValue=""
                    />
                  </ButtonGroup>
                  <ButtonGroup bsSize="small">
                    <Button
                      disabled={this.state.selectedRows.length === 0}
                      //onClick={() => this.setState({ showApproval: true })}
                      onClick={(e) => {
                        if(!this.checkTokenExpired(user)){
                          this.setState({ showApproval: true });
                        }
                      }}
                    >
                      Approve...
                    </Button>
                    <Button
                      disabled={this.state.selectedRows.length === 0}
                      //onClick={() => this.setState({ showTesting: true })}
                      onClick={(e) => {
                        if(!this.checkTokenExpired(user)){
                          this.setState({ showTesting: true });
                        }
                      }}
                    >
                      Test...
                    </Button>
                    <Button
                      disabled={this.state.selectedRows.length === 0}
                      onClick={(e) => {
                        if(!this.checkTokenExpired(user)){
                          this.setState({ showUnblocking: true });
                        }
                      }}
                    >
                      Clear Blocking Rules...
                    </Button>
                    <Button
                      disabled={this.state.selectedRows.length === 0}
                      //onClick={() => this.setState({ showClone: true })}
                      onClick={(e) => {
                        if(!this.checkTokenExpired(user)){
                          this.setState({ showClone: true });
                        }
                      }}
                    >
                      Clone...
                    </Button>
                  </ButtonGroup>
                </DataTable.Toolbar>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped table-hover"
                  ref={this.tableRef}
                  onLoaded={({ json }) =>
                    this.setState({
                      remainingCalls: json.remainingCalls
                    })
                  }
                  onUserCommand={({ commandData }) => {
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      this.setState({ showHopsFor: commandData })
                    }
                  }}
                  onSelect={rows => this.setState({ selectedRows: rows })}
                  showCheckBoxes
                  noSelectedOnly
                  ajax={{
                    url: "/api/v1/reports/RedirectionData",
                    dataSrc: "records"
                  }}
                  deferRender={true}
                  columns={[
                    {
                      title: `<i title="Platform" class="fa fa-info-circle fa-1_4x grey"></i>`,
                      data: "targetPlatform",
                      name: "targetPlatform",
                      render: DataTable.StockRenderers.platform()
                    },
                    {
                      title: `<i title="Store" class="fa fa-info-circle fa-1_4x grey"></i>`,
                      data: "storeId",
                      name: "storeId",
                      render: DataTable.StockRenderers.store()
                    },
                    {
                      title: "Offer ID",
                      data: "offerId",
                      name: "offerId"
                    },
                    {
                      title: "Offer Name",
                      data: "name",
                      name: "name",
                      className: "truncate-cell-wide",
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: "Package Name",
                      data: "packageName",
                      name: "packageName",
                      className: "truncate-cell-wide",
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: "Payout",
                      data: "payout",
                      name: "payout",
                      className: "text-right",
                      render: DataTable.StockRenderers.money()
                    },
                    {
                      title: "Countries",
                      data: "targetedCountries",
                      name: "targetedCountries",
                      className: "truncate-cell",
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: "Advertiser",
                      data: "advertiserName",
                      name: "advertiserName",
                      className: "truncate-cell",
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: `<i title="Traffic Type" class="fa fa-info-circle fa-1_4x grey"></i>`,
                      data: "incentivized",
                      name: "incentivized",
                      render: DataTable.StockRenderers.trafficType()
                    },
                    {
                      title: `<i title="Direct offer" class="fa fa-info-circle fa-1_4x grey"></i>`,
                      data: "isDirectOffer",
                      name: "isDirectOffer",
                      render: renderDirectOffer
                    },
                    {
                      title: "Cap",
                      data: "dailyConversionCap",
                      name: "dailyConversionCap",
                      render: renderRemainingCap,
                      className: "text-right"
                    },
                    {
                      title: "Clicks",
                      data: "clicks",
                      name: "clicks",
                      className: "text-right",
                      render: renderClickRange
                    },
                    {
                      title: `<span title="Total conversions last 24hours">Inst.</span>`,
                      data: "conversions",
                      name: "conversions",
                      className: "text-right",
                      render: renderConversionsRange
                    },
                    {
                      title: `<i title="Status" class="fa fa-info-circle fa-1_4x grey"></i>`,
                      data: "moderationStatus",
                      name: "moderationStatus",
                      className: "text-center",
                      render: DataTable.StockRenderers.moderationStatus()
                    },
                    DataTable.createUserCommandColumn({
                      title: "Hops",
                      data: "hops",
                      name: "hops",
                      className: "text-right",
                      commandName: "hops",
                      commandData: ({ rowData }) => ({
                        id: rowData.offerId,
                        name: rowData.name
                      }),
                      render: renderHopsRange
                    }),
                    {
                      title: "Red. To Store",
                      data: "isPreviewValid",
                      name: "isPreviewValid",
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: "New Offer",
                      data: "newOffer",
                      name: "newOffer",
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: "Rec.",
                      data: "isRecycled",
                      name: "isRecycled",
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: "Rev.",
                      data: "revenues",
                      name: "revenues",
                      className: "text-right",
                      render: (d, t, r, m) => N(d, 2)
                    }
                  ]}
                  order={[]}
                />
              </Box.Body>
              <Box.Footer>
                <DownloadButton
                  onClick={this.handleDownload}
                  disabled={this.state.loading}
                />
              </Box.Footer>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {this.state.showHopsFor && (
          <HopsDialog
            id={this.state.showHopsFor.id}
            name={this.state.showHopsFor.name}
            onHide={() => this.setState({ showHopsFor: null })}
          />
        )}
        {this.state.showApproval && (
          <ApprovalDialog
            selectedRows={this.state.selectedRows}
            onHide={() => this.setState({ showApproval: false })}
            onRowsChanged={() => {
              this.tableRef.current.api
                .rows()
                .invalidate()
                .draw();
            }}
          />
        )}
        {this.state.showTesting && (
          <TestingDialog
            selectedRows={this.state.selectedRows}
            onHide={() => this.setState({ showTesting: false })}
            onRowsChanged={() => {
              this.tableRef.current.api
                .rows()
                .invalidate()
                .draw();
            }}
          />
        )}
        {this.state.showUnblocking && (
          <UnblockingDialog
            selectedRows={this.state.selectedRows}
            onHide={() => this.setState({ showUnblocking: false })}
            onRowsChanged={() => {
              this.tableRef.current.api
                .rows()
                .invalidate()
                .draw();
            }}
          />
        )}
        {this.state.showClone && (
          <CloneDialog
            selectedRows={this.state.selectedRows}
            onHide={() => this.setState({ showClone: false })}
            onRowsChanged={() => {
              this.tableRef.current.api
                .rows()
                .invalidate()
                .draw();
            }}
          />
        )}
      </>
    );
  }

  handleDownload() {
    this.setState({ busy: true, error: null });
    axios({
      url: `/api/v1/reports/RedirectionData?output=Excel`,
      method: "get",
      responseType: "blob"
    })
      .then(res => {
        this.setState({ busy: false, error: null });
        const filename = extractFilenameFromResponse(res);
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }
  
  checkTokenExpired(user){
    if (user.tokenHasExpired) {
      window.location.replace('/login');
      return true;
    }
    return false;
  }
}
