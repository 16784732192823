import React from "react";
import {
  Form,
  FormGroup,
  ButtonToolbar,
  ButtonGroup,
  ControlLabel,
  Sizes
} from "react-bootstrap";
import { IDataTable } from "./types";
import ReloadButton from "./ReloadButton";
import SearchBox from "./SearchBox";
import PageSizer from "./PageSizer";

type ToolbarProps = {
  tableRef: React.RefObject<IDataTable>;
  bsSize?: Sizes;
  noReloadButton?: boolean;
};

const Toolbar: React.FC<ToolbarProps> = ({
  tableRef,
  bsSize,
  noReloadButton,
  children
}) => (
  <Form inline>
    <FormGroup>
      <ButtonToolbar style={{ display: "inline-block" }}>
        {!noReloadButton && (
          <ButtonGroup bsSize={bsSize}>
            <ReloadButton tableRef={tableRef} />
          </ButtonGroup>
        )}
        {children}
      </ButtonToolbar>
    </FormGroup>
    <FormGroup bsClass="pull-right">
      <SearchBox tableRef={tableRef} bsSize={bsSize} />
    </FormGroup>
    <FormGroup bsClass="pull-right">
      <PageSizer tableRef={tableRef} bsSize={bsSize} />
    </FormGroup>
  </Form>
);

export default Toolbar;
