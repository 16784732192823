import React from "react";
import { FormGroup, Row, Col } from "react-bootstrap";
import axios from "axios";
import download from "downloadjs";
import { Box, HorizontalLine } from "components/admin-lte";
import buildErrorSummary from "components/ErrorSummary";
import DataTable from "components/DataTable";
import PageLayout from "components/PageLayout";
import { extractFilenameFromResponse } from "lib/Functions";

export default class MassBlock extends React.Component {
  static title = "Mass Block";

  constructor(props) {
    super(props);
    this.state = {
      hasFile: false,
      showHelp: false,
      checkResults: null,
      busy: false,
      error: null,
      message: null
    };
    this.buildFormData = this.buildFormData.bind(this);
    this.fileInput = React.createRef();
    this.tableRef = React.createRef();
  }

  render() {
    const {
      hasFile,
      showHelp,
      checkResults,
      busy,
      error,
      message
    } = this.state;
    const errorSummary = buildErrorSummary(error);

    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={MassBlock.title}
        description="Imports one or more blocking rules (optimizations) from a file. Multiple file formats are supported."
      >
        <>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {message}
            </div>
          )}
          <Box busy={busy}>
            <Box.Header>
              <Row>
                <Col md={12}>
                  <p>
                    Select an Excel file containing blocking rules and click on
                    button:
                  </p>
                  <ul>
                    <li>
                      <strong>Block</strong> to add the contained rules to
                      Minimob's blacklist.
                    </li>
                    <li>
                      <strong>Unblock</strong> to remove the contained rules
                      from Minimob's blacklist.
                    </li>
                    <li>
                      <strong>Check File</strong> to examine the rules contained
                      in the file without sending anything to Minimob.
                    </li>
                  </ul>
                  <p>
                    <a
                      href="/"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ showHelp: !showHelp });
                      }}
                    >
                      Supported Excel file formats&nbsp;
                      <i
                        className={
                          showHelp ? "fa fa-caret-up" : "fa fa-caret-down"
                        }
                      ></i>
                    </a>
                  </p>
                  {showHelp && (
                    <>
                      <ul>
                        <li>
                          <strong>Rejected Installs</strong>
                          <p>
                            This is the file generated by the{" "}
                            <a href="/Reports/RejectedInstalls" target="_blank">
                              Rejected Installs
                            </a>{" "}
                            report.
                            <br />
                            You need to have grouped at least by Advertiser and
                            Publisher for the report to be compatible with this
                            tool.
                          </p>
                        </li>
                        <li>
                          <strong>Blocking Rules</strong>
                          <p>
                            This is the file generated by the{" "}
                            <a href="/Reports/BlockingRules" target="_blank">
                              Blocking Rules
                            </a>{" "}
                            tool.
                          </p>
                        </li>
                        <li>
                          <strong>CR by Subpublisher</strong>
                          <p>
                            This is the file generated by the{" "}
                            <a href="/Reports/CRBySubpublisher" target="_blank">
                              CR by Subpublisher
                            </a>{" "}
                            report.
                            <br />
                            You need to have grouped at least by Advertiser and
                            Publisher for the report to be compatible with this
                            tool.
                          </p>
                        </li>
                        <li>
                          <strong>Custom</strong>
                          <p>
                            You can provide a custom Excel file with a
                            2-dimensional table starting at cell A1 of the first
                            worksheet, formatted as below:
                          </p>
                          <table
                            className="table table-bordered table-condensed"
                            style={{
                              backgroundColor: "#d2d6de",
                              width: "auto"
                            }}
                          >
                            <thead>
                              <tr>
                                <th>AdvertiserId</th>
                                <th>PublisherId</th>
                                <th>SubpublisherId</th>
                                <th>ApplicationId</th>
                                <th>CampaignId</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1373664660</td>
                                <td>
                                  1373692012
                                  <div>&mdash;or&mdash;</div>
                                  a379e2ace2a7ede23c371266c582671c
                                  <div>&mdash;or&mdash;</div>
                                  M18xMzczNjk3NTAy
                                  <div>&mdash;or&mdash;</div>
                                  m18xmzcznjk1ntyy
                                </td>
                                <td>
                                  <em>empty</em>
                                  <div>&mdash;or&mdash;</div>
                                  017ff60a556fa0d009bf145c0498c824d8469758
                                  <div>&mdash;or&mdash;</div>
                                  AED337AF8F22147FF36FC02439505B50
                                </td>
                                <td>
                                  <em>empty</em>
                                  <div>&mdash;or&mdash;</div>
                                  5df39667672eb8b
                                </td>
                                <td>
                                  <em>empty</em>
                                  <div>&mdash;or&mdash;</div>
                                  5df39667672eb8b
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td></td>
                                <td>
                                  Both decoded and encoded forms supported.
                                </td>
                                <td>Only encoded form supported.</td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </table>
                          <small>
                            NOTE:
                            <ul className="list-unstyled">
                              <li>
                                The casing of the headers and the order of
                                columns are irrelevant.
                              </li>
                              <li>Extra columns will be ignored.</li>
                              <li>
                                ApplicationId can be deduced from CampaignId.
                              </li>
                              <li>
                                Encoded PublisherId will be automatically
                                decoded.
                              </li>
                            </ul>
                          </small>
                        </li>
                      </ul>
                      <HorizontalLine />
                    </>
                  )}
                </Col>
              </Row>
            </Box.Header>
            <Box.Body>
              <FormGroup>
                <input
                  type="file"
                  ref={this.fileInput}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={e =>
                    this.setState({
                      hasFile: e.target.files && e.target.files.length > 0
                    })
                  }
                />
              </FormGroup>
            </Box.Body>
            <Box.Footer>
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "80px" }}
                disabled={!hasFile}
                onClick={this.block.bind(this, true)}
              >
                Block
              </button>
              <button
                type="button"
                className="btn btn-primary tool-margin"
                style={{ width: "80px" }}
                disabled={!hasFile}
                onClick={this.block.bind(this, false)}
              >
                Unblock
              </button>
              <button
                type="button"
                className="btn btn-default tool-margin"
                disabled={!hasFile}
                onClick={this.check.bind(this)}
              >
                Check File
              </button>
            </Box.Footer>
          </Box>
          {checkResults && (
            <Box>
              <Box.Header>
                <div className="text-center">
                  The following information will be sent to Minimob when you
                  click the Block or Unblock button.
                </div>
              </Box.Header>
              <Box.Body>
                <DataTable.Toolbar tableRef={this.tableRef} noReloadButton />
                <DataTable
                  ref={this.tableRef}
                  className="table table-condensed table-striped"
                  data={checkResults}
                  columns={[
                    {
                      defaultContent: "",
                      title: "AdvertiserId",
                      data: "advertiserId"
                    },
                    {
                      defaultContent: "",
                      title: "PublisherId",
                      data: "publisherId"
                    },
                    {
                      defaultContent: "",
                      title: "SubpublisherId",
                      data: "subpublisherId"
                    },
                    {
                      defaultContent: "",
                      title: "ApplicationId",
                      data: "applicationId"
                    },
                    {
                      defaultContent: "",
                      title: "CampaignId",
                      data: "campaignId"
                    }
                  ]}
                  order={[]}
                />
              </Box.Body>
            </Box>
          )}
        </>
      </PageLayout>
    );
  }

  buildFormData() {
    let input = this.fileInput.current;
    let data = new FormData();
    data.append("file", input.files[0]);
    return data;
  }

  block(shouldBlock) {
    this.setState({ busy: true });
    const action = shouldBlock ? "block" : "unblock";
    axios({
      url: `/api/v1/tools/massblock/${action}`,
      method: "post",
      data: this.buildFormData()
    })
      .then(res => {
        this.setState({
          checkResults: null,
          message: "The operation completed successfully.",
          error: null,
          busy: false
        });
        const filename = extractFilenameFromResponse(res);
        if (filename) download(res.data, filename, "text/csv");
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;
        this.setState({
          message: null,
          error,
          busy: false
        });
      });
  }

  check() {
    this.setState({ busy: true });
    axios({
      url: `/api/v1/tools/massblock/check`,
      method: "post",
      data: this.buildFormData()
    })
      .then(res => {
        this.setState({
          checkResults: res.data,
          message:
            "The file is valid. You may proceed with blocking or unblocking.",
          error: null,
          busy: false
        });
      })
      .catch(err => {
        const error =
          (err.response
            ? err.response.data
            : err.request
            ? err.request
            : err.message) || "Unknown error";
        this.setState({
          message: null,
          error,
          busy: false
        });
      });
  }
}
