import React from "react";
import $ from "jquery";
import "select2";
import "select2/dist/css/select2.css";

class UserPicker extends React.Component {
  render() {
    const { className, multiple } = this.props;

    return (
      <div>
        <select className={className} ref="select" multiple={multiple}></select>
      </div>
    );
  }

  componentDidMount() {
    const { includeAdmins, placeholder, onChange, ...rest } = this.props;
    const select = $(this.refs.select);

    select.select2({
      allowClear: true,
      placeholder: placeholder || " ",
      minimumInputLength: 4,
      language: {
        inputTooShort() {
          return "Enter id or part of name";
        }
      },
      ajax: {
        delay: 250,
        data(params) {
          return {
            partialName: params.term,
            includeAdmins: includeAdmins
          };
        },
        processResults(data) {
          return {
            results: data.map(x => ({
              id: x.id || x._user_id,
              text: x.text || x._username
            }))
          };
        },
        url: `/api/v1/users/lookup`,
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      },
      ...rest
    });
    select.on("change", e => onChange($(e.target).val()));
    select.siblings("span.select2-container").css("width", "100%");
  }

  setFocus() {
    $(this.refs.select).select2("open");
  }

  clearSingle() {
    $(this.refs.select)
      .val(null)
      .trigger("change");
  }
}

export default UserPicker;
