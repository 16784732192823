import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import {
  AdvertiserPicker,
  PublisherPicker,
  ApplicationPicker,
  CampaignPicker
} from "components/pickers/SelectPickers";
import DateRangePicker from "components/pickers/DateRangePicker";
import moment from "moment-timezone";
import axios from "axios";
import DataTable from "components/DataTable";
import download from "downloadjs";
import { extractFilenameFromResponse, buildGetParams } from "lib/Functions";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import Legend from "./Legend";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Checkbox,
  ButtonToolbar
} from "react-bootstrap";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

export default class RejectedInstalls extends React.Component {
  static title = "Rejected Installs";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      advertiserIds: [],
      publisherIds: [],
      applicationIds: [],
      campaignIds: [],
      date: null,
      groupByAdvertiser: false,
      groupByPublisher: false,
      groupBySubpublisher: false,
      groupByApplication: false,
      groupByCampaign: false,
      groupByDeviceid: false,
      groupByCountry: false,
      busy: false,
      error: null,
      response: null,
      showLegend: false
    };
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={RejectedInstalls.title}
        description="Rejected installs received from attribution platforms, like AppsFlyer, Adjust etc."
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={this.state.busy}>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Advertisers</ControlLabel>
                  <AdvertiserPicker
                    className="form-control"
                    multiple
                    placeholder="Enter ids or names"
                    onChange={value => this.setState({ advertiserIds: value })}
                    includeNetworks={false} // only manual networks send reject postbacks inside their tracking links or use AppsFlyer
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Publishers</ControlLabel>
                  <PublisherPicker
                    className="form-control"
                    multiple
                    placeholder="Enter ids or names"
                    onChange={value => this.setState({ publisherIds: value })}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Applications</ControlLabel>
                  <ApplicationPicker
                    className="form-control"
                    multiple
                    placeholder="Enter hex ids"
                    onChange={value => this.setState({ applicationIds: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Campaigns</ControlLabel>
                  <CampaignPicker
                    className="form-control"
                    multiple
                    placeholder="Enter hex ids"
                    onChange={value => this.setState({ campaignIds: value })}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Date</ControlLabel>
                  <DateRangePicker
                    className="form-control"
                    onChange={value => this.setState({ date: value })}
                    minDate={moment("2018-10-01")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Group By</ControlLabel>
                  <Row>
                    <Col md={12}>
                      <Checkbox
                        inline
                        onChange={e =>
                          this.setState({ groupByAdvertiser: e.target.checked })
                        }
                      >
                        Advertiser
                      </Checkbox>
                      <Checkbox
                        inline
                        onChange={e =>
                          this.setState({ groupByPublisher: e.target.checked })
                        }
                      >
                        Publisher
                      </Checkbox>
                      <Checkbox
                        inline
                        onChange={e =>
                          this.setState({
                            groupBySubpublisher: e.target.checked
                          })
                        }
                      >
                        Subpublisher
                      </Checkbox>
                      <Checkbox
                        inline
                        onChange={e =>
                          this.setState({
                            groupByApplication: e.target.checked
                          })
                        }
                      >
                        Application
                      </Checkbox><br/>
                      <Checkbox
                        inline
                        onChange={e =>
                          this.setState({ groupByCampaign: e.target.checked })
                        }
                      >
                        Campaign
                      </Checkbox>
                      <Checkbox
                        inline
                        onChange={e =>
                          this.setState({ groupByDeviceid: e.target.checked })
                        }
                      >
                        Device Id
                      </Checkbox>
                      <Checkbox
                        inline
                        onChange={e =>
                          this.setState({ groupByCountry: e.target.checked })
                        }
                      >
                        Country
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <a
                    href="/"
                    onClick={e => {
                      e.preventDefault();
                      this.setState(state => ({
                        showLegend: !state.showLegend
                      }));
                    }}
                  >
                    Show Reject Reason legend&nbsp;
                    <i
                      className={
                        this.state.showLegend
                          ? "fa fa-caret-up"
                          : "fa fa-caret-down"
                      }
                    ></i>
                  </a>
                </FormGroup>
              </Col>
            </Row>
            {this.state.showLegend && <Legend />}
          </Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <DownloadButton
                onClick={this.handleDownloadOrPreview.bind(this, true)}
              />
              <PreviewButton
                onClick={this.handleDownloadOrPreview.bind(this, false)}
              />
            </ButtonToolbar>
          </Box.Footer>
        </Box>
        {this.state.response && (
          <Box>
            <Box.Body>
              <DataTable.Toolbar
                tableRef={this.tableRef}
                bsSize="small"
                noReloadButton
              />
              <DataTable
                className="table table-striped"
                ref={this.tableRef}
                data={this.state.response.data}
                columns={[
                  {
                    title: "Advertiser",
                    data: "advertiser_fk",
                    defaultContent: "",
                    name: "advertiser",
                    render: (d, t, r, m) =>
                      d &&
                      this.state.response &&
                      this.state.response.userNames[d]
                  },
                  {
                    title: "Publisher",
                    data: "publisher_fk",
                    defaultContent: "",
                    name: "publisher",
                    render: (d, t, r, m) =>
                      d &&
                      this.state.response &&
                      this.state.response.userNames[d]
                  },
                  {
                    title: "Encoded Subpublisher",
                    data: "trafficsource_subid_encoded_fk",
                    name: "trafficsource_subid_encoded_fk"
                  },
                  {
                    title: "Decoded Subpublisher",
                    data: "trafficsource_subid_fk",
                    name: "trafficsource_subid_fk"
                  },
                  {
                    title: "Device Id",
                    data: "device_id",
                    name: "device_id"
                  },
                  {
                    title: "Country",
                    data: "country_code",
                    name: "country_code"
                  },
                  {
                    title: "Application",
                    data: "adv_application_fk",
                    defaultContent: "",
                    name: "adv_application",
                    render: (d, t, r, m) =>
                      d &&
                      this.state.response &&
                      this.state.response.applicationNames[d]
                  },
                  {
                    title: "Campaign ID",
                    data: "offer_fk",
                    defaultContent: "",
                    name: "offerid",
                  },
                  {
                    title: "Campaign",
                    data: "offer_fk",
                    defaultContent: "",
                    name: "offer",
                    render: (d, t, r, m) =>
                      d &&
                      this.state.response &&
                      this.state.response.campaignNames[d]
                  },
                  {
                    title: "Rejection Reason",
                    data: "reject_reason"
                  },
                  {
                    title: "Rejection Sub Reason",
                    data: "rejected_sub_reason",
                    defaultContent: "",
                  },
                  {
                    title: "Installs",
                    data: "rejected_installs",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Revenue",
                    data: "rejected_revenue",
                    className: "text-right",
                    render: DataTable.StockRenderers.money()
                  },
                  {
                    title: "Cost",
                    data: "rejected_cost",
                    className: "text-right",
                    render: DataTable.StockRenderers.money()
                  }
                ]}
              />
            </Box.Body>
          </Box>
        )}
      </PageLayout>
    );
  }

  handleDownloadOrPreview(excel) {
    const {
      date,
      groupByAdvertiser,
      groupByPublisher,
      groupBySubpublisher,
      groupByApplication,
      groupByCampaign,
      groupByDeviceid,
      groupByCountry
    } = this.state;

    this.setState({ busy: true, error: null });
    axios
      .get("/api/v1/reports/rejectedinstalls", {
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        responseType: excel ? "blob" : "json",
        params: buildGetParams({
          advertiserIds: this.state.advertiserIds,
          publisherIds: this.state.publisherIds,
          applicationIds: this.state.applicationIds,
          campaignIds: this.state.campaignIds,
          fromDateInclusiveUtc: date
            ? moment(date[0]).format("YYYY-MM-DD")
            : null,
          toDateExclusiveUtc: date
            ? moment(date[1])
                .add(1, "second")
                .format("YYYY-MM-DD")
            : null,
          groupByAdvertiser,
          groupByPublisher,
          groupBySubpublisher,
          groupByApplication,
          groupByCampaign,
          groupByDeviceid,
          groupByCountry,
          output: excel ? "Excel" : "Resultset"
        })
      })
      .then(res => {
        if (excel) {
          this.setState({
            busy: false,
            error: null
          });
          const filename =
            extractFilenameFromResponse(res) || "RejectedInstalls.xlsx";
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState({
            busy: false,
            error: null,
            response: res.data
          });
          const dataTable = this.tableRef.current;
          dataTable.setColumnVisibility("advertiser:name", groupByAdvertiser);
          dataTable.setColumnVisibility("publisher:name", groupByPublisher);
          dataTable.setColumnVisibility(
            "trafficsource_subid_encoded_fk:name",
            groupBySubpublisher
          );
          dataTable.setColumnVisibility(
            "trafficsource_subid_fk:name",
            groupBySubpublisher
          );
          dataTable.setColumnVisibility(
            "adv_application:name",
            groupByApplication
          );
          dataTable.setColumnVisibility("offer:name", groupByCampaign);
          dataTable.setColumnVisibility("offerid:name", groupByCampaign);
          dataTable.setColumnVisibility("device_id:name", groupByDeviceid);
          dataTable.setColumnVisibility("country_code:name", groupByCountry);
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              busy: false,
              error: e.srcElement.result
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            busy: false,
            error
          });
        }
      });
  }
}
