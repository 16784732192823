import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import { Modal, Button, ButtonGroup } from "react-bootstrap";
import DataTable from "components/DataTable";
import ActionDialog from "./ActionDialog";
import { Box } from "components/admin-lte";

export default class CloneDialog extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      error: null,
      message: null,
      showAction: false,
      selectedRows: []
    };
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <>
      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Offers to be Cloned</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            Please select the offers that you want to copy and click the "Clone" button.
          </p>
          <Box.Header>
              <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" noReloadButton>
              <ButtonGroup>
                
              </ButtonGroup>
            </DataTable.Toolbar>
          </Box.Header>
          <DataTable
            className="table table-condensed table-striped table-hover"
            ref={this.tableRef}
            data={this.props.selectedRows}
            onSelect={rows => this.setState({ selectedRows: rows })}
            showCheckBoxes
            noSelectedOnly
            deferRender={true}
            columns={[
              {
                title: `<i title="Platform" class="fa fa-info-circle fa-1_4x grey"></i>`,
                data: "targetPlatform",
                name: "targetPlatform",
                render: DataTable.StockRenderers.platform()
              },
              {
                title: "Offer ID",
                data: "offerid",
                name: "offerid"
              },
              {
                title: "Offer Name",
                data: "name",
                name: "name",
                className: "truncate-cell-wide",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: "Payout",
                data: "payout",
                name: "payout",
                className: "text-right",
                render: DataTable.StockRenderers.money()
              },
              {
                title: "Countries",
                data: "targetedCountries",
                name: "targetedCountries",
                className: "truncate-cell",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: "Advertiser",
                data: "adNetworkName",
                name: "adNetworkName",
                className: "truncate-cell",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: "Clicks",
                data: "clicks",
                name: "clicks",
                className: "text-left",
                render: DataTable.StockRenderers.textWithTooltip()
              },
              {
                title: "Conv",
                data: "conversions",
                name: "conversions",
                className: "text-left",
                render: DataTable.StockRenderers.textWithTooltip()
              }
            ]}
            order={[]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={() => this.setState({ showAction: true })}
            disabled={this.state.selectedRows.length === 0}
          >
            Clone
          </Button>
        </Modal.Footer>
        </Modal>
        {
          this.state.showAction && (
            <ActionDialog
              selectedRows={this.state.selectedRows}
              onHide={() => this.setState({ showAction: false })}
              onRowsChanged={() => {
                this.tableRef.current.api
                  .rows()
                  .invalidate()
                  .draw();
              }}
            />
          )
        }
      </>
    );
  }

}
