import React from "react";
import { FormGroup } from "react-bootstrap";
import { CampaignPicker } from "components/pickers/SelectPickers";

function ByIdTab({ onChange }) {
  return (
    <>
      <p>Add one or more offers by entering their id directly.</p>
      <FormGroup>
        <CampaignPicker
          className="form-control"
          placeholder="Enter hex id"
          multiple
          onChange={(_, campaigns) => onChange(campaigns)}
        />
      </FormGroup>
    </>
  );
}

export default ByIdTab;
