import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {ButtonGroup, Button} from "react-bootstrap";
import AddDialog from "./AddDialog";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import { UserContext } from "lib/user";

export default class ImpCapping extends React.Component {
  static title = "Click Rate";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {     
      showAdd: false,
      showEdit: false,
      showDelete: false,
      busy: false,
      error: null,
      showEditDataFor: null,
      showDeleteDataFor: null
    };
  }
  
  render() {
    const { showEditDataFor } = this.state;
    const { showDeleteDataFor } = this.state;
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={ImpCapping.title}
          description={
          <p>
            Offers with click rate setting.
          </p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
                <ButtonGroup bsSize="small">
                  <Button onClick={this.handleAddClick.bind(this,user)}>
                    Add
                  </Button>
                </ButtonGroup>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped"
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/reports/impcapping",
                    dataSrc: "items"
                  }}
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      if(commandData && commandData.action==='delete')
                        this.setState({ showDeleteDataFor: commandData })
                      else
                        this.setState({ showEditDataFor: commandData })
                      }
                    }
                  }
                  columns={[
                    {
                      title: "Offer ID",
                      data: "offerid"
                    },
                    {
                      title: "Offer Name",
                      data: "offer_name"
                    },
                    {
                      title: "Advertiser Name",
                      data: "advertiser_name"
                    },
                    /*{
                      title: "Impression Threshold",
                      data: "cap",
                      className: "text-right",
                      render: DataTable.StockRenderers.count()
                    },*/
                    {
                      title: "1 Click Every # Impressions",
                      data: "clickRate",
                      className: "text-right",
                      render: DataTable.StockRenderers.count()
                    },
                   /* {
                      title: "Status",
                      data: "state",
                      className: "text-right"
                    },*/
                    {
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "20px",
                      render: (d, t, r, m) => ( "<div></div>")
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        offerid: rowData.offerid,
                        cap: rowData.cap,
                        clickRate: rowData.clickRate           
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Edit </button></div>")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Actions",
                      data: null,
                      className: "text-left",
                      orderable: false,
                      width: "30px",
                      defultContent: "",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'delete',
                        offerid: rowData.offerid,
                        offerName: rowData.offer_name,
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Delete </button></div>")
                    })
                  ]}
                  order={[[1, "asc"]]}
                />
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {this.state.showAdd && (
          <AddDialog   
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showAdd: false 
              })
            }}
          />
        )}

        {showEditDataFor && (
          <EditDialog
            offerid={showEditDataFor.offerid}
            cap={showEditDataFor.cap}
            clickRate={showEditDataFor.clickRate}
            onHide={() => {
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showEdit: false, 
                  showEditDataFor: null
                })
              );
            }}
          />
        )}

        {showDeleteDataFor && (
          <DeleteDialog
            offerid={showDeleteDataFor.offerid}
            offerName={showDeleteDataFor.offerName}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showDelete: false, 
                showDeleteDataFor: null 
              })
            }}
          />
        )}
      </>
    );
  }
  
  handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({showAdd: true});
    }
  }
}
