import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import moment from "moment";
import TimeInput from "components/TimeInput";
import _ from 'lodash';

export default class EditDialog extends React.Component {
  static title = "Edit Campaign Schedule";

  constructor(props) {
    super(props);
    this.state = {
      offerid: props.offerid||null,
      schedule: this.unformatSchedule(props.schedule||[])||null,
      error: null,
      busy: false,
      message: null,
      formErrors: {},
      formIsValid: null
    };
    this.handleEditCampaignSchedule = this.handleEditCampaignSchedule.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    var formErrors = this.state.formErrors;
    
    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Edit Campaign Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            Please edit the campaign schedule.
          </p>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Campaign ID</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    disabled={true}
                    defaultValue={this.state.offerid}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h5 className={"modal-sub-header"}>Campaign Schedule</h5>
              </Col>
            </Row>
            {(this.state.schedule || []).map((schedule, idx, array) => {
              return(
                <Row key={"scheduleRow_key_"+idx}>
                  <Col md={4}>
                    <FormGroup className={formErrors[idx]?'form-error':null}>
                      <Row className={"modal-sub-fields"}>
                        <Col md={3}>
                          <ControlLabel>From</ControlLabel>
                          <TimeInput
                            style={{width:"70px"}}
                            value={array[idx].from}
                            onChange={value => this.handleTimeFromChange(value, idx)}
                          />
                        </Col>
                        <Col md={9}>
                          <ControlLabel>To</ControlLabel>
                          <TimeInput
                            style={{width:"70px"}}
                            value={array[idx].to}
                            onChange={value => this.handleTimeToChange(value, idx)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className={"error-msg"}>{formErrors[idx]||''}</div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={4} style={{paddingTop:"25px"}}>
                    {array.length>1 &&(
                      <Button
                        id={"id_"+idx}
                        bsStyle="danger"
                        onClick={this.handleRemoveScheduleRule.bind(this, idx)}
                      > - Remove </Button>
                    )}
                    {array.length === idx+1 &&(
                      <Button
                        id={"id_"+idx}
                        bsStyle="primary"
                        onClick={this.handleAddScheduleRule.bind(this)}
                        style={{"marginLeft":"15px"}}
                      > + Add </Button>
                    )}
                  </Col>
                </Row>);
            })
            }
            <FormGroup className={formErrors.schedule?'form-error':null}>
              <Row>
                <Col md={12}>
                  <div className={"error-msg"}>{formErrors.schedule||''}</div>
                </Col>
              </Row>
            </FormGroup>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !this.state.offerid ||
              !this.state.schedule
            }
            bsStyle="primary"
            onClick={this.handleEditCampaignSchedule}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  unformatSchedule(schedule){
    var scheduleUnformatted = [];
    schedule.forEach((scheduleRule,idx,scheduleArr)=>{
      var fromDate = scheduleRule.split('-').length?scheduleRule.split('-')[0]:'';
      var toDate = scheduleRule.split('-').length?scheduleRule.split('-')[1]:'';
      scheduleUnformatted.push({"from":fromDate,"to":toDate});
    });
    return scheduleUnformatted;
  }
  
  formatSchedule(){
    var schedule = this.state.schedule;
    var scheduleFormatted = [];
    schedule.forEach((scheduleRule,idx,scheduleArr)=>{
      scheduleFormatted.push(scheduleRule.from+"-"+scheduleRule.to);
    });
    return scheduleFormatted;
  }

  validateScheduleRules(){
    var schedule = this.state.schedule;
    var formErrors = this.state.formErrors;
    var formIsValid = this.state.formIsValid;
    formErrors = {};
    formIsValid = true;

    schedule.forEach((scheduleRule,idx, scheduleArr)=>{
      if(scheduleRule){
        var fromDate;
        var toDate;
        formErrors[idx]=false;

        if (moment(scheduleRule.from, 'HH:mm', true).isValid()) {
          fromDate = moment(scheduleRule.from, 'HH:mm');
        } else {
          formErrors[idx] = "From Time has invalid format. Please enter time in HH:mm format.";
          formIsValid = false;
        }

        if (moment(scheduleRule.to, 'HH:mm', true).isValid()) {
          toDate = moment(scheduleRule.to, 'HH:mm');
        } else {
          formErrors[idx] = "To Time has invalid format. Please enter time in HH:mm format.";
          formIsValid = false;
        }

        var timeDiff = toDate.diff(fromDate);
        if (timeDiff <= 0) {
          formErrors[idx] = "To Time must be after From Time in a 24-hour period (00:00-23:59)";
          formIsValid = false;
        }
      }
    });

    //Check for overlapping schedule rules
    function compare( rule1, rule2 ) {
      if ( rule1.from < rule2.from ){
        return -1;
      }
      if ( rule1.from > rule2.from ){
        return 1;
      }
      return 0;
    }

    if(schedule.length>1){
      var scheduleCopy = _.cloneDeep(schedule);
      scheduleCopy.sort( compare );

      scheduleCopy.forEach((scheduleRule,idx, scheduleArr)=>{
        if((idx+1)<scheduleArr.length){
          var fromDate1 = moment(scheduleRule.from, 'HH:mm');
          var fromDate2 = moment(scheduleArr[idx+1].from, 'HH:mm');
          var toDate1 = moment(scheduleRule.to, 'HH:mm');
          //var toDate2 = moment(scheduleArr[idx+1].to, 'HH:mm');

          var fromTimeDiff = fromDate1.diff(fromDate2);
          var toFromTimeDiff = toDate1.diff(fromDate2);
          if(fromTimeDiff === 0 || toFromTimeDiff>0){
            formErrors.schedule = "Overlapping time schedule rule ranges";
            formIsValid = false;
          }
        }
      });
    }


    this.setState({formErrors:formErrors, formIsValid:formIsValid});
    return formIsValid;
  }

  handleTimeFromChange(value, idx){
    var state = this.state;
    state.schedule[idx].from = value;
    this.setState({schedule: state.schedule});
  }

  handleTimeToChange(value, idx){
    var state = this.state;
    state.schedule[idx].to = value;
    this.setState({schedule: state.schedule});
  }

  handleRemoveScheduleRule(index){
    var state = this.state;
    state.schedule.splice(index, 1);
    this.setState({ schedule: state.schedule });
    console.log(this.state);
  }

  handleAddScheduleRule(){
    var state = this.state;
    state.schedule.push({from:'00:00',to:'23:59'});
    this.setState({ schedule: state.schedule });
    console.log(this.state);
  }

  handleEditCampaignSchedule() {
    var formIsValid = this.validateScheduleRules();
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    var scheduleFormatted = this.formatSchedule();

    var postData = {
      OfferId: this.state.offerid,
      Schedule: scheduleFormatted,
    };

    if(formIsValid){
      axios({
        url: "/api/v1/tools/editCampaignSchedule",
        method: "post",
        data: postData
      })
        .then(res => {
          this.setState({
            busy: false,
            message: "The operation completed successfully.",
            error: null
          });
          this.props.onHide();
        })
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;
          this.setState({
            busy: false,
            message: null,
            error
          });
        });
    }

  }
}
