import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";

export default class ClickCapping extends React.Component {
  static title = "Click Capping";

  tableRef = React.createRef();

  render() {
    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={ClickCapping.title}
        description={
          <p>
            By enabling click capping for an advertiser, Minimob will
            automatically block a subpublisher for the next 24 hours, if their
            traffic has exceeded the click threshold without any conversions.
            <br />
            If the incoming traffic has no subpublisher information, then the
            entire publisher will be blocked.
            <br />
            The minimum number of clicks that this feature can support is 3000.
            <br />
            This list is maintained by <strong>k.paganos@minimob.com</strong>.
          </p>
        }
      >
        <Box>
          <Box.Header>
            <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
          </Box.Header>
          <Box.Body>
            <DataTable
              className="table table-condensed table-striped"
              ref={this.tableRef}
              ajax={{
                url: "/api/v1/reports/clickcapping",
                dataSrc: "items"
              }}
              columns={[
                {
                  title: "Advertiser ID",
                  data: "_id"
                },
                {
                  title: "Advertiser Name",
                  data: "_id",
                  render: DataTable.StockRenderers.responseLookup("names")
                },
                {
                  title: "Clicks Threshold",
                  data: "clicksLimit",
                  className: "text-right",
                  render: DataTable.StockRenderers.count()
                }
              ]}
              order={[[1, "asc"]]}
            />
          </Box.Body>
        </Box>
      </PageLayout>
    );
  }
}
