import React from "react";
import $ from "jquery";
import PageLayout from "components/PageLayout";
import { Box } from "components/admin-lte";
import DataTable, { DataTableToolbar } from "components/DataTable";
import { ButtonGroup, Button, DropdownButton, MenuItem } from "react-bootstrap";
import AddOfferDialog from "components/dialogs/AddOfferDialog";
import download from "downloadjs";
import { extractFilenameFromResponse } from "lib/Functions";
import { UserContext } from "lib/user";

function OfferDetails() {
  const [busy, setBusy] = React.useState(false);
  const [offers, setOffers] = React.useState([]);
  const [selectedOffers, setSelectedOffers] = React.useState([]);
  const [adding, setAdding] = React.useState(false);
  const [showDecimalIds, setShowDecimalIds] = React.useState(false);
  const tableRef = React.useRef();

  React.useEffect(() => {
    if (!tableRef.current) return;
    const api = tableRef.current.api;
    const redraw = false;
    api.columns(".columnset-id-dec").visible(showDecimalIds, redraw);
    api.columns(".columnset-id-hex").visible(!showDecimalIds, redraw);
    api.draw();
  }, [showDecimalIds]);

  function colorizeRow(row, data, dataIndex, cells) {
    if (data.deleted) {
      $(row).addClass("bg-danger");
    }
  }

  function handleAddStart() {
    setAdding(true);
  }

  function handleAddEnd(ids) {
    setAdding(false);
    setBusy(true);
    const existingIds = offers.map(x => x.id);
    const newIds = ids.filter(x => !existingIds.includes(x));
    fetch("/api/v1/reports/offerdetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ offerIds: newIds })
    })
      //.then(res => res.json())
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        return res.json();
      })
      .then(json => setOffers(json.concat(offers)))
      .finally(() => setBusy(false));
  }

  function handleReload() {
    setBusy(true);
    fetch("/api/v1/reports/offerdetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ offerIds: offers.map(x => x.id) })
    })
      //.then(res => res.json())
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        return res.json();
      })
      .then(json => setOffers(json))
      .finally(() => setBusy(false));
  }

  function handleRemove() {
    const selectedIds = selectedOffers.map(x => x.id);
    setOffers(
      offers.reduce((acc, cur) => {
        if (!selectedIds.includes(cur.id)) acc.push(cur);
        return acc;
      }, [])
    );
    setSelectedOffers([]);
  }

  function handleClear(e) {
    if (!e.crtlKey && !window.confirm("Do you want to clear the list?")) return;
    setBusy(true);
    setOffers([]);
    setSelectedOffers([]);
    setBusy(false);
  }

  function handleDownload() {
    setBusy(true);
    let filename = "";
    fetch("/api/v1/reports/offerdetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ offerIds: offers.map(x => x.id), output: "Excel" })
    })
      /*.then(res => {
        filename = extractFilenameFromResponse(res);
        return res.blob();
      })*/
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        filename = extractFilenameFromResponse(res);
        return res.blob();
      })
      .then(blob =>
        download(
          blob,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      )
      .finally(() => setBusy(false));
  }
  
  function handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      handleAddStart()
    }
  }

  return (
    <PageLayout
      breadcrumbs={["Tools"]}
      title={OfferDetails.title}
      description="View details or perform bulk operations on a set of offers."
    >
      <Box busy={busy}>
        <Box.Header>
          <DataTableToolbar tableRef={tableRef} bsSize="small" noReloadButton>
            <ButtonGroup bsSize="small">
              {/*<Button bsStyle="primary" onClick={handleAddStart}>
                <i className="fa fa-plus"></i> Add
              </Button>*/}
              <UserContext.Consumer>
                {user => (
                  <Button
                    bsStyle="primary"
                    onClick={handleAddClick.bind(this,user)}
                  >
                    <i className="fa fa-plus"></i> Add
                  </Button>
                )}
              </UserContext.Consumer>
            </ButtonGroup>
            <ButtonGroup bsSize="small">
              <Button disabled={offers.length === 0} onClick={handleReload}>
                <i className="fa fa-refresh"></i> Reload
              </Button>
              <Button
                disabled={selectedOffers.length === 0}
                onClick={handleRemove}
              >
                <i className="fa fa-minus"></i> Remove
              </Button>
              <Button disabled={offers.length === 0} onClick={handleClear}>
                <i className="fa fa-trash-o"></i> Clear
              </Button>
            </ButtonGroup>
            <ButtonGroup bsSize="small">
              <Button disabled={offers.length === 0} onClick={handleDownload}>
                <i className="fa fa-download"></i> Download
              </Button>
              {false && (
                <DropdownButton
                  id="sendto"
                  bsSize="small"
                  title={
                    <>
                      <i className="fa fa-share-square-o"></i> Send to
                    </>
                  }
                  disabled={selectedOffers.length === 0}
                >
                  <MenuItem key="a" eventKey={1}>
                    Add/Remove
                  </MenuItem>
                </DropdownButton>
              )}
            </ButtonGroup>
            <ButtonGroup bsSize="small">
              <Button
                active={!showDecimalIds}
                onClick={() => setShowDecimalIds(false)}
              >
                Hex
              </Button>
              <Button
                active={showDecimalIds}
                onClick={() => setShowDecimalIds(true)}
              >
                Dec
              </Button>
            </ButtonGroup>
          </DataTableToolbar>
        </Box.Header>
        <Box.Body>
          <DataTable
            className="table table-condensed table-hover"
            ref={tableRef}
            data={offers}
            columns={[
              DataTable.StockColumns.platform("osPlatform"),
              DataTable.StockColumns.store("storeId"),
              {
                title: "ID",
                data: "id",
                className: "columnset-id-hex"
              },
              {
                title: "ID",
                data: "decimalId",
                className: "columnset-id-dec"
              },
              {
                title: "External ID",
                data: "externalId"
              },
              DataTable.StockColumns.textTruncateWide("name", "Name"),
              DataTable.StockColumns.textTruncate(
                "packageName",
                "Package Name"
              ),
              DataTable.StockColumns.money("payout", "Payout"),
              DataTable.StockColumns.textTruncate("geos", "Countries"),
              {
                title: "Advertiser",
                data: "advertiserName"
              },
              DataTable.StockColumns.trafficType("incentivized"),
              DataTable.StockColumns.recycled("isRecycled"),
              DataTable.StockColumns.redirects("isPreviewValid"),
              DataTable.StockColumns.moderationStatus("moderationStatus"),
              {
                title: "Description",
                data: "description"
              }
            ]}
            order={[]}
            showCheckBoxes={true}
            onSelect={setSelectedOffers}
            createdRow={colorizeRow}
          />
        </Box.Body>
      </Box>
      <AddOfferDialog
        title="Add offers to list"
        show={adding}
        onHide={() => setAdding(false)}
        onAdd={handleAddEnd}
      />
    </PageLayout>
  );
}

OfferDetails.title = "Offer Details";

export default OfferDetails;
