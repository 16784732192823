import moment from "moment-timezone";
import React from "react";
import { N } from "lib/Functions";

import ADSTAMP_LOGO from "assets/invoice/adstamp-logo.png";
import APPMULTIPLE_LOGO from "assets/invoice/appmultiple-logo.png";
import KANMOBILE_LOGO from "assets/invoice/kanmobile-logo.png";
import MINIMOB_LOGO from "assets/invoice/minimob-logo.png";
import SPINX_LOGO from "assets/invoice/spinx-logo.png";
import ADSPIN_LOGO from "assets/invoice/adspin-logo.png";

const ADSPIN_ID = "635a5b2f55fc474b24607509";
const ADSTAMP_ID = "6352911f39f28166b1b99e42";
const APPMULTIPLE_ID = "6352910839f28166b1b99e40";
const KANMOBILE_ID = "6352958b39f28166b1b99ea4";
const MINIMOB_ID = "6352912639f28166b1b99e43";
const SPINX_ID = "6352959139f28166b1b99ea5";
const NYDRI_ID = "6352911439f28166b1b99e41";

export default class InvoiceHtml extends React.Component {
  static title = "Invoice";

  constructor(props) {
    super(props);
    this.state = {
      invoiceData: this.props.invoiceData || null,
      showPdfDataFor: this.props.showPdfDataFor || null,
      error: null,
      busy: false,
      message: null
    };

    this.formatInvoicePeriod = this.formatInvoicePeriod.bind(this);
    this.calculateInvoiceLinesTotal = this.calculateInvoiceLinesTotal.bind(this);
    this.calculateInvoiceTotal = this.calculateInvoiceTotal.bind(this);
    this.calculateVat = this.calculateVat.bind(this);
    this.getInvoiceLinesHtml = this.getInvoiceLinesHtml.bind(this);
  }

  render() {
    const { invoiceData } = this.state;

    return (
      <div hidden={true}>
      
        {/* ADSPIN */}
        {invoiceData && invoiceData?.issuerId === ADSPIN_ID && (
          <div id={"invoice-html"}
            style={{ width: "500px", marginLeft: '1rem', marginRight: '1rem', color: "#000", fontFamily: 'Helvetica' }}>
            <table style={{ width: "500px", marginBottom: "20px" }}>
              <tbody>
                <tr>
                  <td><p style={{ fontSize: "1.2rem", marginTop: "10px" }}></p></td>
                  <td style={{ textAlign: "right" }}><img alt="adspin-logo" src={ADSPIN_LOGO} style={{ width: "120px" }} /></td>
                </tr>
              </tbody>
            </table>
            <p style={{width: "50rem", fontSize: "0.8rem", textAlign: "right"}}>
              {/*ADSPIN TECHNOLOGY SOLUTIONS<br/>
                          26TH FLOOR, BEAUTIFUL GROUP<br/>
                          77 CONNAUGHT ROAD<br/>
                          CENTRAL HONG KONG<br/>
                          Co. Reg. No: 60408436-000-09-12-6<br/>*/}
              {invoiceData?.issuerTitle}<br/>
              {invoiceData?.issuerAddress}<br/>
              {invoiceData?.issuerAddress2}<br/>
              Co. Reg. No: {invoiceData?.issuerRegNo}<br/>
            </p>
  
            <h2 style={{
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "center",
              width: "500px",
              marginBottom: "20px",
              letterSpacing: "1px"
            }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "CREDIT NOTE" : "INVOICE"}</h2>
            <p style={{ width: "50rem", fontSize: "0.8rem", textAlign: "right", marginBottom: "0" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"} Number: {invoiceData?.invoiceNo}</p>
            <p style={{ width: "50rem", fontSize: "0.8rem", textAlign: "right" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"} Date: {moment(invoiceData?.invoiceDate).format('DD/MM/YYYY')}</p>
  
            <table style={{width: "350px", fontSize: "0.8rem"}}>
              <tbody>
              <tr>
                <td style={{width: "40px"}}>To:</td>
                <td>{invoiceData?.recipientTitle}</td>
              </tr>
              <tr>
                <td style={{width: "40px"}}>Address:</td>
                <td>{invoiceData?.recipientAddress}</td>
              </tr>
              <tr>
                <td style={{width: "40px"}}></td>
                <td>{invoiceData?.recipientAddress2}</td>
              </tr>
              <tr>
                <td style={{width: "40px"}}>Reg. No:</td>
                <td>{invoiceData?.recipientRegNo}</td>
              </tr>
              </tbody>
            </table>
  
            <table style={{width: "500px", fontSize: "0.8rem", marginTop: "30px", marginBottom: "30px"}}>
              <tbody>
              <tr>
                <td style={{width: "400px"}}></td>
                <td style={{textAlign: "center"}}>{this.state.showPdfDataFor?.currency}</td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{textAlign: "center", paddingLeft: "15px"}}>{this.state.showPdfDataFor?.currency2}</td>
                )}
              </tr>
              <tr>
                  <td>Online Ads Services {!invoiceData?.invoiceText && (<span>for the period: {this.formatInvoicePeriod()}</span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)}</td>
                <td></td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td></td>
                )}
              </tr>
              {this.getInvoiceLinesHtml()}
              <tr>
                  <td style={{ width: "300px" }}>Total {!invoiceData?.invoiceText && (<span>{this.formatInvoicePeriod()}</span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)}</td>
                <td>{this.state.showPdfDataFor?.currencySymbol} <span
                    style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency), 2))}</span></td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft: "15px"}}>
                    {this.state.showPdfDataFor?.currencySymbol2} <span
                        style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency2), 2))}</span>
                  </td>
                )}
              </tr>
              <tr>
                <td colSpan={"2"} style={{height: "30px"}}></td>
              </tr>
              <tr>
                  <td style={{ textAlign: "right", paddingRight: "10px" }}>V.A.T. ({invoiceData?.taxRate}%)</td>
                <td>
                  <div>{this.state.showPdfDataFor?.currencySymbol} <span
                      style={{ float: "right" }}>{this.calculateVat(this.state.showPdfDataFor?.currency) > 0 ? this.calculateVat(this.state.showPdfDataFor?.currency) : "-"}</span></div>
                  <hr style={{height: "1px", margin: "0"}}/>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft: "15px"}}>
                    <div>{this.state.showPdfDataFor?.currencySymbol2} <span
                      style={{float: "right"}}>{this.calculateVat(this.state.showPdfDataFor?.currency2) > 0 ? this.calculateVat(this.state.showPdfDataFor?.currency2) : "-"}</span>
                    </div>
                    <hr style={{height: "1px", margin: "0"}}/>
                  </td>
                )}
              </tr>
              <tr>
                <td colSpan={this.state.showPdfDataFor?.currency2 ? "3" : "2"} style={{height: "10px"}}></td>
              </tr>
              <tr>
                <td style={{textAlign: "right", paddingRight: "10px"}}>Total</td>
                <td>
                  <div>{this.state.showPdfDataFor?.currencySymbol} <span
                      style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency)), 2))}</span></div>
                  <hr style={{height: "1px", margin: "0"}}/>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft: "15px"}}>
                    <div>{this.state.showPdfDataFor?.currencySymbol2} <span
                        style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency2)), 2))}</span>
                    </div>
                    <hr style={{height: "1px", margin: "0"}}/>
                  </td>
                )}
              </tr>
              </tbody>
            </table>
  
            <p style={{width: "500px", fontSize: "0.7rem", textAlign: "center", marginBottom: "20px"}}>No signature is
              required as this is an authorised {invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"}</p>
  
            <p style={{width: "500px", fontSize: "0.8rem"}}>
              Payment with Wire Transfer<br/>
              ADSPIN TECHNOLOGY SOLUTIONS LIMITED<br/>
              COMMERZBANK AG<br/>
              COBADEFFXXX<br/>
              DE19 2004 0000 0620 1701 00<br/>
            </p>
  
            <hr style={{width: "500px", height: "3px", color: "#000"}}/>
          </div>
        )}
        {/* End of ADSPIN */}
        
        {/* ADSTAMP */}
        {invoiceData && invoiceData?.issuerId === ADSTAMP_ID && (
          <div id={"invoice-html"} style={{marginLeft: '1rem', marginRight: '1rem', color: "#000", fontFamily:'Helvetica'}}>
              <table style={{width:"500px"}}>
                <tbody>
                <tr>
                  <td style={{width:"150px"}}><img alt="adstamp-logo" src={ADSTAMP_LOGO} style={{width:"150px"}} /></td>
                  <td>
                    <p style={{fontSize:"0.7rem", textAlign:"right", lineHeight:"0.8rem"}}>
                      {invoiceData?.issuerTitle}<br/>
                      {invoiceData?.issuerAddress}<br/>
                      {invoiceData?.issuerAddress2}<br/>
                      Reg. No {invoiceData?.issuerRegNo}<br/>
                    </p>
                  </td>
                </tr>
                </tbody>
              </table>
              
            <div style={{ width: "500px", textAlign: "center", fontSize: "1.4rem", marginBottom: "20px", letterSpacing: "1px" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "CREDIT NOTE" : "INVOICE"}</div>
            <p style={{ width: "50rem", fontSize: "0.8rem", textAlign: "right", marginBottom: "0" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"} Number: {invoiceData?.invoiceNo}</p>
            <p style={{ width: "50rem", fontSize: "0.8rem", textAlign: "right" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"} Date: {moment(invoiceData?.invoiceDate).format('DD/MM/YYYY')}</p>
  
              <table style={{width:"350px", fontSize:"0.8rem"}}>
                <tbody>
                  <tr>
                    <td style={{width:"40px"}}>To:</td>
                    <td>{invoiceData?.recipientTitle}</td>
                  </tr>
                  <tr>
                    <td style={{width:"40px"}}>VAT No:</td>
                    <td>{invoiceData?.recipientVatNo}</td>
                  </tr>
                  <tr>
                    <td style={{width:"40px"}}>Address:</td>
                    <td>{invoiceData?.recipientAddress}</td>
                  </tr>
                  <tr>
                    <td style={{width:"40px"}}></td>
                    <td>{invoiceData?.recipientAddress2}</td>
                  </tr>
                </tbody>
              </table>
  
              <table style={{width:"500px", fontSize:"0.8rem", marginTop:"30px", marginBottom:"30px"}}>
                <tbody>
                <tr>
                  <td style={{width:"400px"}}>{/*Mobile Traffic*/}</td>
                  <td style={{textAlign:"center"}}>{this.state.showPdfDataFor?.currency}</td>
                  {this.state.showPdfDataFor?.currency2 && (
                    <td style={{textAlign:"center", paddingLeft:"15px"}}>{this.state.showPdfDataFor?.currency2}</td>
                  )}
                </tr>
                <tr>
                  <td style={{ width: "350px" }}>{!invoiceData?.invoiceText && (<span>{this.formatInvoicePeriod()}</span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)}</td>
                  <td></td>
                  {this.state.showPdfDataFor?.currency2 && (
                    <td></td>
                  )}
                </tr>
                {this.getInvoiceLinesHtml()}
                <tr>
                  <td style={{ width: "300px" }}>Total {!invoiceData?.invoiceText && (<span>{this.formatInvoicePeriod()}</span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)}</td>
                  <td>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency), 2))}</span></td>
                  {this.state.showPdfDataFor?.currency2 && (
                    <td style={{paddingLeft:"15px"}}>
                      {this.state.showPdfDataFor?.currencySymbol2} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency2), 2))}</span>
                    </td>
                  )}
                </tr>
                <tr>
                  <td colSpan={"2"} style={{height:"30px"}}></td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right", paddingRight: "10px" }}>VAT ({invoiceData?.taxRate}%)</td>
                  <td>
                    <hr style={{height: "1px", margin: "0"}} />
                    <div>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right" }}>{this.calculateVat(this.state.showPdfDataFor?.currency) > 0 ? N(this.calculateVat(this.state.showPdfDataFor?.currency), 2):"-"}</span></div>
                  </td>
                  {this.state.showPdfDataFor?.currency2 && (
                    <td style={{paddingLeft:"15px"}}>
                      <hr style={{height: "1px", margin: "0"}} />
                      <div>{this.state.showPdfDataFor?.currencySymbol2} <span style={{float:"right"}}>{this.calculateVat(this.state.showPdfDataFor?.currency2)>0?this.calculateVat(this.state.showPdfDataFor?.currency2):"-"}</span></div>
                    </td>
                  )}
                </tr>
                <tr>
                  <td style={{textAlign:"right", paddingRight:"10px"}}>TOTAL</td>
                  <td>
                    <div>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency)), 2))}</span></div>
                    <hr style={{height: "1px", margin: "0"}} />
                  </td>
                  {this.state.showPdfDataFor?.currency2 && (
                    <td style={{paddingLeft:"15px"}}>
                      <div>{this.state.showPdfDataFor?.currencySymbol2} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency2)), 2))}</span></div>
                      <hr style={{height: "1px", margin: "0"}} />
                    </td>
                  )}
                </tr>
                </tbody>
              </table>
  
              <p style={{width:"500px",fontSize:"0.8rem", textAlign:"center"}}>
                PAYMENT BY WIRE TRANSFER<br/>
                DBS BANK LTD<br/>
                BIC/SWIFT CODE: DBSSSGSGXXX<br/>
                BENEFICIARY: ADSTAMP PTE LTD<br/>
                ACCOUNT: 027-908255-0<br/>
                BRANCH CODE: 027<br/>
              </p>                
          </div>
        )}
        {/* End of ADSTAMP */}
        
        {/* APPMULTIPLE */}
        {invoiceData && invoiceData?.issuerId === APPMULTIPLE_ID && (
          <div id={"invoice-html"} style={{marginLeft: '1rem', marginRight: '1rem', color:'#000', fontFamily:'Arial'}}>
            <table style={{width:"500px",  marginBottom:"20px"}}>
              <tbody>
              <tr>
                <td><p style={{fontSize:"1.2rem", marginTop:"10px"}}>{invoiceData?.issuerTitle}</p></td>
                <td style={{textAlign:"right"}}><img alt="appmultiple-logo" src={APPMULTIPLE_LOGO} style={{width:"120px"}} /></td>
              </tr>
              </tbody>
            </table>
  
            <p style={{width:"50rem",fontSize:"0.8rem", textAlign:"left"}}>
              {invoiceData?.issuerAddress}{invoiceData?.issuerAddress2? ", " + invoiceData?.issuerAddress2:""}<br/>
              REGISTRATION NUMBER {invoiceData?.issuerRegNo} VAT No {invoiceData?.issuerVatNo}
              {/*ANTONI LOUKAIDI, 114, OASIS COMPLEX - BLOCK E, 4th FLOOR, FLAT/OFFICE 401, 3031, LIMASSOL, CYPRUS<br/>
                    REGISTRATION NUMBER HE314186 EU VAT No CY10314186R<br/>*/}
            </p>
            <h2 style={{ fontSize: "1.4rem", textAlign: "center", width: "500px", marginBottom: "20px", letterSpacing: "1px"}}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "CREDIT NOTE" : "INVOICE"}</h2>
  
            <table style={{width:"350px", fontSize:"0.8rem"}}>
              <tbody>
              <tr>
                  <td style={{ width: "75px" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"} Number:</td>
                <td>{invoiceData?.invoiceNo}</td>
              </tr>
              <tr>
                  <td>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"} Date:</td>
                <td>{moment(invoiceData?.invoiceDate).format('DD/MM/YYYY')}</td>
              </tr>
              <tr style={{height:"20px"}}>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>To:</td>
                <td>{invoiceData?.recipientTitle}</td>
              </tr>
              <tr>
                <td>Reg. No:</td>
                <td>{invoiceData?.recipientRegNo}</td>
              </tr>
              <tr>
                <td>Address:</td>
                <td>{invoiceData?.recipientAddress}</td>
              </tr>
              <tr>
                <td></td>
                <td>{invoiceData?.recipientAddress2}</td>
              </tr>
              </tbody>
            </table>
  
            <table style={{width:"500px", fontSize:"0.8rem", marginTop:"30px", marginBottom:"30px"}}>
              <tbody>
              <tr>
                <td style={{width:"400px"}}></td>
                <td style={{textAlign:"center"}}>{this.state.showPdfDataFor?.currency}</td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{textAlign:"center", paddingLeft:"15px"}}>{this.state.showPdfDataFor?.currency2}</td>
                )}
              </tr>
              <tr>
                <td colSpan={this.state.showPdfDataFor?.currency2?"3":"2"}></td>
              </tr>
              <tr>
                  <td>{!invoiceData?.invoiceText && (<span>Period: {this.formatInvoicePeriod()}</span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)}</td>
                <td colSpan={this.state.showPdfDataFor?.currency2?"2":"1"}></td>
              </tr>
              {this.getInvoiceLinesHtml()}
              <tr>
                <td colSpan={this.state.showPdfDataFor?.currency2?"3":"2"} style={{height:"30px"}}></td>
              </tr>
              <tr>
                  <td style={{ textAlign: "right", paddingRight: "10px" }}>VAT ({invoiceData?.taxRate}%)</td>
                <td>
                  <hr style={{height: "1px", margin: "0"}} />
                    <div>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right", paddingRight: "2px" }}>{this.calculateVat(this.state.showPdfDataFor?.currency) > 0 ? this.calculateVat(this.state.showPdfDataFor?.currency):"-"}</span></div>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft:"15px"}}>
                    <hr style={{height: "1px", margin: "0"}} />
                      <div>{this.state.showPdfDataFor?.currencySymbol2} <span style={{ float: "right", paddingRight: "2px"}}>{this.calculateVat(this.state.showPdfDataFor?.currency2)>0?this.calculateVat(this.state.showPdfDataFor?.currency2):"-"}</span></div>
                  </td>
                )}
              </tr>
              <tr>
                <td style={{textAlign:"right", paddingRight:"10px"}}>TOTAL</td>
                <td>
                    <div>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right", paddingRight: this.state.showPdfDataFor?.currencySymbol === '$' ? "2px" : "1px" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency)), 2))}</span></div>
                  <hr style={{height: "1px", margin: "0"}} />
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft:"15px"}}>
                      <div>{this.state.showPdfDataFor?.currencySymbol2} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency2)), 2))}</span></div>
                    <hr style={{height: "1px", margin: "0"}} />
                  </td>
                )}
              </tr>
              </tbody>
            </table>
  
            <p style={{ width: "500px", fontSize: "0.8rem", textAlign: "center" }}>
              PAYMENT BY WIRE TRANSFER<br />
              OPTIMA BANK S.A.<br />
              BIC/SWIFT CODE: IBOGGRAA<br />
              BENEFICIARY: The AppMultiple Ltd<br />
              ACCOUNT: 11 024790 0271 97<br />
              IBAN: GR24 0340 0110 0110 2479 0027 197<br />
            </p>

          </div>
        )}
        {/* End of APPMULTIPLE */}

        {/* KANMOBILE */}
        {invoiceData && invoiceData?.issuerId === KANMOBILE_ID && (
          <div id={"invoice-html"} style={{marginLeft: '1rem', marginRight: '1rem', color: "#000", fontFamily:'Arial', height:'800px'}}>

            <table style={{width:"500px", fontSize:"0.8rem", marginBottom:"20px"}}>
              <tbody>
              <tr>
                <td colSpan="2" style={{textAlign:"left"}}><img alt="kanmobile-logo" src={KANMOBILE_LOGO} style={{width:"90px", marginBottom:"20px"}} /></td>
              </tr>
              <tr>
                <td style={{textAlign:"left"}}>
                        <span style={{color:"#ed1857", textDecoration:"underline", fontSize:"0.8rem", lineHeight:"0.8rem"}}>
                          {invoiceData?.issuerTitle}{invoiceData?.issuerAddress?", "+invoiceData?.issuerAddress:""}
                        </span><br/>
                  <p>
                    {invoiceData?.recipientTitle}<br/>
                    {invoiceData?.recipientAddress}<br/>
                    {invoiceData?.recipientAddress2}<br/>
                  </p>
                </td>
                <td>
                  <div style={{border:"1px solid #ed1857", padding:"2px", width:"200px", height:"90px", backgroundColor:"#eee"}}>
                    <span style={{fontSize:"0.9rem", fontWeight:"bold"}}>invoice: {invoiceData?.invoiceNo}</span>
                    <p>
                      document date: {moment(invoiceData?.invoiceDate).format('DD.MM.YYYY')}<br/>
                      creation date: {moment(invoiceData?.invoiceDate).format('DD.MM.YYYY')}<br/>
                      VAT ID: {invoiceData?.recipientVatNo}{/*ESB66848326*/}<br/>
                      <br/>
                      our contact email: finance@kanmobi.net
                    </p>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <table style={{width:"500px", fontSize:"0.8rem", marginTop:"30px", marginBottom:"40px", border:"1px solid #ed1857", padding:"2px"}}>
              <tbody>
              <tr style={{backgroundColor:"#cc3366", color:"#fff"}}>
                  <td colSpan="4" style={{ padding: "2px" }}><b>{!invoiceData?.invoiceText && (<span>{this.formatInvoicePeriod()}</span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)} - Mobile Performance Advertising Costs</b></td>
                <td style={{padding:"2px"}}></td>
              </tr>
              <tr style={{fontWeight:"bold", backgroundColor:"#eee", borderBottom:"1px solid #cc3366"}}>
                <td style={{width:"20%", padding:"2px"}}>article</td>
                <td style={{width:"20%", padding:"2px", textAlign:"right"}}>amount<br/> unit price</td>
                <td style={{width:"20%", padding:"2px", textAlign:"right"}}>net amount</td>
                <td style={{width:"20%", padding:"2px", textAlign:"right"}}>VAT</td>
                <td style={{padding:"2px", textAlign:"right"}}>gross amount</td>
              </tr>
              {
                (invoiceData?.invoiceLines||[]).map((invoiceLine, idx, array) => {
                  if(invoiceLine.deleted !== true) {
                    return(<tr key={"invoiceLine_" + idx}>
                      <td style={{padding:"2px"}}>{invoiceLine.description || ""}</td>
                      <td style={{textAlign:"right"}}>
                        {(this.state.showPdfDataFor?.currency !== "USD" ? this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice) * parseFloat(invoiceData?.exchangeRate), 2)) : this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice), 2)) ) + " " + this.state.showPdfDataFor?.currency}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>);
                  }
                  else{
                    return null;
                  }
                })
              }
              <tr>
                <td></td>
                <td></td>
                  <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency)), 2))} {this.state.showPdfDataFor?.currency}</td>{/*net amount*/}
                  <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateVat(this.state.showPdfDataFor?.currency)), 2)) + this.state.showPdfDataFor?.currency}</td>{/*VAT*/}
                  <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency)), 2))} {this.state.showPdfDataFor?.currency}</td>{/*gross amount*/}
              </tr>
              <tr style={{fontWeight:"bold", backgroundColor:"#eee", borderTop:"1px solid #cc3366", borderBottom:"1px solid #cc3366"}}>
                <td style={{padding:"2px"}}>result</td>
                <td></td>
                  <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency)), 2))} {this.state.showPdfDataFor?.currency}</td>{/*net amount*/}
                  <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateVat(this.state.showPdfDataFor?.currency)), 2))} {this.state.showPdfDataFor?.currency}</td>{/*VAT*/}
                  <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency)), 2))} {this.state.showPdfDataFor?.currency}</td>{/*gross amount*/}
              </tr>
              </tbody>
            </table>

            {this.state.showPdfDataFor?.currency2 && (
              <table style={{width:"500px", fontSize:"0.8rem", marginTop:"30px", marginBottom:"40px", border:"1px solid #ed1857", padding:"2px"}}>
                <tbody>
                <tr style={{backgroundColor:"#cc3366", color:"#fff"}}>
                  <td colSpan="4" style={{padding:"2px"}}><b>{this.formatInvoicePeriod()} - Mobile Performance Advertising Costs</b></td>
                  <td style={{padding:"2px"}}></td>
                </tr>
                <tr style={{fontWeight:"bold", backgroundColor:"#eee", borderBottom:"1px solid #cc3366"}}>
                  <td style={{width:"20%", padding:"2px"}}>article</td>
                  <td style={{width:"20%", padding:"2px", textAlign:"right"}}>amount<br/> unit price</td>
                  <td style={{width:"20%", padding:"2px", textAlign:"right"}}>net amount</td>
                    <td style={{ width: "20%", padding: "2px", textAlign: "right" }}>VAT ({invoiceData?.taxRate}%)</td>
                  <td style={{padding:"2px", textAlign:"right"}}>gross amount</td>
                </tr>
                {/*{this.getInvoiceLinesHtml()}*/}
                {
                  (invoiceData?.invoiceLines||[]).map((invoiceLine, idx, array) => {
                    if(invoiceLine.deleted !== true) {
                      return(<tr key={"invoiceLine_" + idx}>
                        <td style={{padding:"2px"}}>{invoiceLine.description || ""}</td>
                        <td style={{textAlign:"right"}}>
                          {(this.state.showPdfDataFor?.currency2 !== "USD" ? this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice) * parseFloat(invoiceData?.exchangeRate), 2)) : this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice), 2))) + " " + this.state.showPdfDataFor?.currency2}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>);
                    }
                    else{
                      return null;
                    }
                  })
                }
                <tr>
                  <td></td>
                  <td></td>
                    <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency2)), 2))} {this.state.showPdfDataFor?.currency2}</td>{/*net amount*/}
                    <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateVat(this.state.showPdfDataFor?.currency2)), 2)) + this.state.showPdfDataFor?.currency2}</td>{/*VAT*/}
                    <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency2)), 2))} {this.state.showPdfDataFor?.currency2}</td>{/*gross amount*/}
                </tr>
                <tr style={{fontWeight:"bold", backgroundColor:"#eee", borderTop:"1px solid #cc3366", borderBottom:"1px solid #cc3366"}}>
                  <td style={{padding:"2px"}}>result</td>
                  <td></td>
                    <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency2)), 2))} {this.state.showPdfDataFor?.currency2}</td>{/*net amount*/}
                    <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateVat(this.state.showPdfDataFor?.currency2)), 2))} {this.state.showPdfDataFor?.currency2}</td>{/*VAT*/}
                    <td style={{ padding: "2px", textAlign: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency2)), 2))} {this.state.showPdfDataFor?.currency2}</td>{/*gross amount*/}
                </tr>
                </tbody>
              </table>
            )}


            <p style={{width:"500px",fontSize:"0.8rem"}}>
              Due positions will be offset against each other as far as possible. Please settle the outstanding amount on time to our bank account mentioned below:<br/>
              <br/>
              Commerzbank<br/>
              IBAN DE03 2004 0000 0621 7996 00<br/>
              SWIFT COBADEFFXXX<br/>
              <br/>
              All bank charges are to be borne by you<br/>
              <br/>
              Objections against this settlement need to be made within four weeks after receipt thereof, otherwise this settlement shall be deemed to be accepted by you.
            </p>
            <div style={{position:"absolute", bottom:"60px", textAlign:"left" }}>
              <p style={{width:"500px",fontSize:"0.7rem",lineHeight:"0.7rem"}}>
                <b><span style={{color:"#cc3366"}}>KAN</span> mobile entertainment GmbH</b><br/>
                {invoiceData.issuerAddress}<br/>
                {invoiceData.issuerAddress2}<br/>
                <a title="www.kanmobi.net" href="https://kanmobi.net/" style={{textDecoration:"underline"}}>www.kanmobi.net</a>
              </p>
            </div>
          </div>
        )}
        {/* End of KANMOBILE */}

        {/* MINIMOB */}
        {invoiceData && invoiceData?.issuerId === MINIMOB_ID && (
          <div id={"invoice-html"} style={{marginLeft: '1rem', marginRight: '1rem', color:"#000", fontFamily:'Times New Roman'}}>
            <div style={{width:"500px", textAlign:"center", marginBottom:"20px"}}><img alt="minimob-logo" src={MINIMOB_LOGO} style={{width:"170px"}} /></div>
            <div style={{width:"500px", textAlign:"center", fontSize:"0.9rem", marginBottom:"20px"}}>
              <b>{invoiceData?.issuerTitle}</b><br/>
              {invoiceData?.issuerTitle?.toUpperCase()}
              {invoiceData?.issuerAddress?.toUpperCase()}{invoiceData?.issuerAddress2?", "+invoiceData?.issuerAddress2?.toUpperCase():""}<br/>
              CO REGISTRATION NUMBER: {invoiceData?.issuerRegNo?.toUpperCase()}<br/>
              GST REGISTRATION NUMBER: {invoiceData?.issuerVatNo?.toUpperCase()}<br/>

              {/* 111, NORTH BRIDGE ROAD, #08-11, PENINSULA PLAZA, SINGAPORE 179098<br/>
                  CO REGISTRATION NUMBER: 201113289D<br/>
                  GST REGISTRATION NUMBER: 201113289D<br/>*/}
            </div>

            <div style={{ width: "500px", textAlign: "center", fontSize: "1.6rem", marginBottom: "20px", letterSpacing: "1px" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"}</div>

            <table style={{width:"500px", fontSize:"0.9rem", marginBottom:"20px"}}>
              <tbody>
              <tr>
                <td style={{width:"10%"}}><b>To:</b></td>
                <td style={{width:"65%"}}>{invoiceData?.recipientTitle}</td>
                <td style={{width:"15%"}}><b>Invoice No.:</b></td>
                <td style={{width:"10%"}}>{invoiceData?.invoiceNo}</td>
              </tr>
              <tr>
                <td><b>Address:</b></td>
                <td>{invoiceData?.recipientAddress}<br/>{invoiceData?.recipientAddress2}</td>
                <td style={{textAlign:"left"}}><b>Invoice Date:</b></td>
                <td>{moment(invoiceData?.invoiceDate).format('DD/MM/YYYY')}</td>
                </tr>
                {invoiceData?.recipientVatNo && (
                  <tr>
                    <td style={{letterSpacing: "0.01px"}}><b>VAT No:</b></td>
                    <td style={{letterSpacing: "0.01px"}}>{invoiceData?.recipientVatNo}</td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                {invoiceData?.recipientRegNo && (
                  <tr>
                    <td style={{ letterSpacing: "0.01px" }}><b>Reg No:</b></td>
                    <td style={{ letterSpacing: "0.01px" }}>{invoiceData?.recipientRegNo}</td>
                    <td></td>
                    <td></td>
                  </tr>
                )}                
              </tbody>
            </table>

            <table style={{width:"500px", fontSize:"0.9rem", marginTop:"30px", marginBottom:"100px", borderTop:"1px solid #000", padding:"2px"}}>
              <tbody>
              <tr style={{fontWeight:"bold", borderBottom:"1px solid #000"}}>
                <td style={{width:"380px", padding:"2px"}}>DESCRIPTION</td>
                  <td style={{ padding: "2px", textAlign: "right" }}>AMOUNT {this.state.showPdfDataFor?.currencySymbol}</td>
                {this.state.showPdfDataFor?.currency2 && (
                    <td style={{ textAlign: "right", paddingLeft: "15px" }}>AMOUNT {this.state.showPdfDataFor?.currencySymbol2}</td>
                )}
              </tr>
              <tr>
                  <td>{!invoiceData?.invoiceText && (<span>Period: {this.formatInvoicePeriod()}</span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)}</td>
                <td colSpan={this.state.showPdfDataFor?.currency2?"2":"1"}></td>
              </tr>
              {this.getInvoiceLinesHtml()}
              <tr style={{textAlign:"right"}}>
                  <td style={{ padding: "2px" }}>GST ({invoiceData?.taxRate}%)</td>
                <td style={{padding:"2px"}}>
                    <div><span>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateVat(this.state.showPdfDataFor?.currency)), 2))}</span> {this.state.showPdfDataFor?.currencySymbol}</div>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft:"15px", padding:"2px"}}>
                      <div><span>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateVat(this.state.showPdfDataFor?.currency2)), 2))}</span> {this.state.showPdfDataFor?.currencySymbol2} </div>
                  </td>
                )}
              </tr>
              <tr style={{fontWeight:"bold", textAlign:"right"}}>
                <td style={{padding:"2px"}}>Total</td>
                <td style={{padding:"2px"}}>
                    <div><span>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency)), 2))}</span> {this.state.showPdfDataFor?.currencySymbol} </div>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft:"15px", padding:"2px"}}>
                      <div><span>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency2)), 2))}</span> {this.state.showPdfDataFor?.currencySymbol2}</div>
                  </td>
                )}
              </tr>
              </tbody>
            </table>

            <p style={{ width: "500px", fontSize: "0.8rem", textAlign: "center", marginBottom: "20px" }}>No signature is required as this is an authorised {invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"}</p>

            <p style={{width:"500px",fontSize:"0.8rem", lineHeight:"0.8rem"}}>
              Payment with Wire Transfer to:<br/>
              Bank Details: United Overseas Bank Limited, UOB Main Branch<br/>
              80 Raffles Place, UOB Plaza 1, Singapore 048624<br/>
              <br/>
              Payment to: Minimob Pte Ltd<br/>
              <br/>
              Accounts Details: 451-905-397-1 USD<br/>
              Accounts Details: 451-905-400-5 EUR<br/>
              Accounts Details: 451-305-873-4 SGD<br/>
              <br/>
              Bank Swift Code: UOVB SG SG<br/>
              Bank Code: 7375<br/>
              Branch Code: 001<br/>
            </p>

          </div>
        )}
        {/* End of MINIMOB */}

        {/* SPINX */}
        {invoiceData && invoiceData?.issuerId === SPINX_ID && (
          <div id={"invoice-html"} style={{marginLeft: '1rem', marginRight: '1rem', color: "#000", fontFamily:'Times New Roman', height:'800px'}}>

            <div style={{width:"500px", textAlign:"left", marginBottom:"20px"}}><img alt="spinx-logo" src={SPINX_LOGO} style={{width:"110px", marginBottom:"20px"}} /></div>
            <div style={{ width: "500px", textAlign: "center", fontSize: "1.6rem", marginBottom: "40px", fontWeight: "bold", letterSpacing: "1px" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "CREDIT NOTE" : "INVOICE"}</div>

            <table style={{width:"500px", fontSize:"0.9rem", marginBottom:"30px"}}>
              <tbody>
              <tr>
                <td style={{width:"10%"}}>TO:</td>
                <td style={{width:"60%"}}>{invoiceData?.recipientTitle?.toUpperCase()}</td>
                  <td style={{ width: "20%" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "CREDIT NOTE" : "INVOICE"} NUMBER:</td>
                <td style={{width:"10%"}}>{invoiceData?.invoiceNo?.toUpperCase()}</td>
              </tr>
              <tr>
                <td colSpan="2"></td>
                <td>INVOICE DATE:</td>
                <td>{moment(invoiceData?.invoiceDate).format('DD/MM/YYYY')}</td>
              </tr>
              <tr>
                <td>REG.NUM.:</td>
                <td>{invoiceData?.recipientRegNo?.toUpperCase()}</td>
                <td>OUR REG NUMBER:</td>
                <td>{invoiceData?.issuerRegNo?.toUpperCase()}</td>
              </tr>
              <tr>
                <td>ADDRESS:</td>
                <td>{invoiceData?.recipientAddress?.toUpperCase()}</td>
                <td>
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>{invoiceData?.recipientAddress2?.toUpperCase()}</td>
                <td></td>
                <td></td>
              </tr>
              </tbody>
            </table>

            <table style={{width:"500px", fontSize:"0.8rem", marginTop:"30px", marginBottom:"40px", padding:"2px"}}>
              <tbody>
              <tr>
                  <td colSpan="2" style={{ padding: "2px", borderTop: "1px solid #fff", borderLeft: "1px solid #fff", borderBottom: "1px solid #000", borderRight: "1px solid #000" }}>{!invoiceData?.invoiceText && (<span><b>Period: {this.formatInvoicePeriod()}</b></span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)}</td>
                <td style={{padding:"2px", border:"1px solid #000", borderLeft:"none", textAlign:"center"}}>{this.state.showPdfDataFor?.currency}</td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{textAlign:"center", paddingLeft:"15px", padding:"2px", border:"1px solid #000", borderLeft:"none"}}>{this.state.showPdfDataFor?.currency2}</td>
                )}
              </tr>
              {this.getInvoiceLinesHtml()}
              <tr>
                <td colSpan="2" style={{padding:"2px", border:"1px solid #000", borderTop:"none", height:"100px", verticalAlign:"top"}}></td>
                <td style={{padding:"2px", border:"1px solid #000", borderTop:"none", borderLeft:"none"}}></td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{padding:"2px", border:"1px solid #000", borderTop:"none", borderLeft:"none"}}></td>
                )}
              </tr>
              <tr>
                <td style={{width:"60%"}}></td>
                <td style={{padding:"2px", textAlign:"right", borderLeft:"1px solid #000", borderRight:"1px solid #000", borderBottom:"1px solid #000"}}>TOTAL</td>
                  <td style={{ padding: "2px", borderRight: "1px solid #000", borderBottom: "1px solid #000" }}>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency), 2))}</span></td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft:"15px", padding:"2px", borderRight:"1px solid #000", borderBottom:"1px solid #000"}}>
                      {this.state.showPdfDataFor?.currencySymbol2} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(this.calculateInvoiceLinesTotal(this.state.showPdfDataFor?.currency2), 2))}</span>
                  </td>
                )}
              </tr>
              <tr>
                <td></td>
                  <td style={{ padding: "2px", textAlign: "right", borderLeft: "1px solid #000", borderRight: "1px solid #000", borderBottom: "1px solid #000" }}>GST ({invoiceData?.taxRate}%)</td>
                <td style={{padding:"2px", borderRight:"1px solid #000", borderBottom:"1px solid #000"}}>
                    <div>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right" }}>{this.calculateVat(this.state.showPdfDataFor?.currency) > 0 ? this.calculateVat(this.state.showPdfDataFor?.currency):"-"}</span></div>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft:"15px", padding:"2px", borderRight:"1px solid #000", borderBottom:"1px solid #000"}}>
                    <div>{this.state.showPdfDataFor?.currencySymbol2} <span style={{float:"right"}}>{this.calculateVat(this.state.showPdfDataFor?.currency2)>0?this.calculateVat(this.state.showPdfDataFor?.currency2):"-"}</span></div>
                  </td>
                )}
              </tr>
              <tr>
                <td></td>
                <td style={{padding:"2px", textAlign:"right", borderLeft:"1px solid #000", borderRight:"1px solid #000", borderBottom:"1px solid #000"}}>TOTAL</td>
                <td style={{padding:"2px", borderRight:"1px solid #000", borderBottom:"1px solid #000"}}>
                    <div>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency)), 2))}</span></div>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft:"15px", padding:"2px", borderRight:"1px solid #000", borderBottom:"1px solid #000"}}>
                      <div>{this.state.showPdfDataFor?.currencySymbol2} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency2)), 2))}</span></div>
                  </td>
                )}
              </tr>
              </tbody>
            </table>

            <div style={{position:"absolute", bottom:"60px", textAlign:"center" }}>
              <p style={{width:"500px",fontSize:"0.8rem", textAlign:"center", marginBottom:"60px" }}>
                PAYMENT BY BANK WIRE TRANSFER<br/>
                BANK: DBS<br/>
                SWIFT CODE : DBSSSGSG<br/>
                BENEFICIARY : SPINX PTE LTD<br/>
                MULTI-CURRENCY ACCT. NO 072-007687-7<br/>
              </p>

              <p style={{width:"500px",fontSize:"0.8rem", textAlign:"center" }}>
                531A UPPER CROSS STREET , #04-95 HONG LIM COMPLEX , SINGAPORE 051531<br/>
              </p>
            </div>
          </div>
        )}
        {/* End of SPINX */}

        {/* NYDRI */}
        {invoiceData && invoiceData?.issuerId === NYDRI_ID && (
          <div id={"invoice-html"} style={{width:"500px", marginLeft: '1rem', marginRight: '1rem', color: "#000", fontFamily:'Helvetica'}}>

            <div style={{fontSize:"1.6rem", fontWeight:"bold", textAlign:"center", textDecoration:"underline"}}>{invoiceData?.issuerTitle?.toUpperCase()}</div>
            <div style={{fontSize:"0.9rem", textAlign:"center", marginBottom:"20px"}}>
              REGISTRATION NUMBER {invoiceData?.issuerRegNo?.toUpperCase()}<br/>
              {invoiceData?.issuerAddress?.toUpperCase()}{invoiceData?.issuerAddress2?", "+invoiceData?.issuerAddress2?.toUpperCase():""}<br/>
              {/*REGISTRATION NUMBER 1719613<br/>
                  P.O. BOX 3159, Road Town, Tortola, VG1110, British Virgin Islands*/}
            </div>
            <div style={{ fontSize: "1rem", textAlign: "center", marginBottom: "30px", letterSpacing: "1px" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "CREDIT NOTE" : "INVOICE"}</div>

            <table style={{width:"500px", fontSize:"0.8rem", marginBottom:"40px"}}>
              <tbody>
              <tr>
                  <td style={{ width: "60" }}>{invoiceData?.invoiceTypeId.startsWith('CN-') ? "Credit Note" : "Invoice"} Number:</td>
                <td>{invoiceData?.invoiceNo}</td>
              </tr>
              <tr>
                <td style={{width:"60px"}}>Invoice Date:</td>
                <td>{moment(invoiceData?.invoiceDate).format('DD/MM/YYYY')}</td>
              </tr>
              <tr>
                <td colSpan="2" style={{height:"10px"}}></td>
              </tr>
              <tr>
                <td style={{width:"40px"}}>To:</td>
                <td>{invoiceData?.recipientTitle}</td>
              </tr>
              <tr>
                <td style={{width:"40px"}}>Re. No:</td>
                <td>{invoiceData?.recipientRegNo}</td>
              </tr>
              <tr>
                <td colSpan="2" style={{height:"10px"}}></td>
              </tr>
              <tr>
                <td style={{width:"40px"}}>Address:</td>
                <td>{invoiceData?.recipientAddress}</td>
              </tr>
              <tr>
                <td style={{width:"40px"}}></td>
                <td>{invoiceData?.recipientAddress2}</td>
              </tr>
              </tbody>
            </table>

            <table style={{width:"500px", fontSize:"0.8rem", marginBottom:"40px"}}>
              <tbody>
              {/*<tr>
                    <td style={{width:"400px"}}>Prepayment Invoice for {this.formatInvoicePeriod()}</td>
                    <td><span  style={{float:"left"}}>{this.state.showPdfDataFor?.currencySymbol}</span><span style={{float:"right"}}>{N( (parseFloat(this.state.showPdfDataFor?.confirmedAmount)||0) - (parseFloat(this.state.showPdfDataFor?.deductedAmount)||0), 2)}</span></td>
                  </tr>*/}
              <tr>
                  <td style={{ width: "420px" }}>{!invoiceData?.invoiceText && (<span><b>Period: {this.formatInvoicePeriod()}</b>Period: {this.formatInvoicePeriod()}</span>)}{invoiceData?.invoiceText && (<span>{invoiceData?.invoiceText}</span>)}</td>
                <td style={{textAlign:"center"}}>{this.state.showPdfDataFor?.currency}</td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{textAlign:"center", paddingLeft:"15px"}}>{this.state.showPdfDataFor?.currency2}</td>
                )}
              </tr>
              {this.getInvoiceLinesHtml()}
              <tr>
                <td colSpan={this.state.showPdfDataFor?.currency2?"3":"2"} style={{height:"30px"}}></td>
              </tr>
              <tr>
                  <td style={{ textAlign: "right", paddingRight: "10px" }}>VAT ({invoiceData?.taxRate}%)</td>
                <td style={{borderTop:"1px solid #000"}}>
                  {/*<div>{this.state.showPdfDataFor?.currencySymbol} <span style={{float:"right"}}>-{N( (parseFloat(invoiceData?.taxRate)||0), 2)}</span></div>*/}
                    <div>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right" }}>{this.calculateVat(this.state.showPdfDataFor?.currency) > 0 ? this.calculateVat(this.state.showPdfDataFor?.currency):"-"}</span></div>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{borderTop:"1px solid #000", paddingLeft:"15px"}}>
                    <div>{this.state.showPdfDataFor?.currencySymbol2} <span style={{float:"right"}}>{this.calculateVat(this.state.showPdfDataFor?.currency2)>0?this.calculateVat(this.state.showPdfDataFor?.currency2):"-"}</span></div>
                  </td>
                )}
              </tr>
              <tr>
                <td style={{textAlign:"right", paddingRight:"10px"}}>TOTAL</td>
                <td style={{borderBottom:"1px solid #000"}}>
                  {/*<div>{this.state.showPdfDataFor?.currencySymbol} <span style={{float:"right"}}>{N( (parseFloat(this.state.showPdfDataFor?.confirmedAmount)||0) - (parseFloat(this.state.showPdfDataFor?.deductedAmount)||0), 2)}</span></div>*/}
                    <div>{this.state.showPdfDataFor?.currencySymbol} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency)), 2))}</span></div>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={{paddingLeft:"15px", borderBottom:"1px solid #000"}}>
                      <div>{this.state.showPdfDataFor?.currencySymbol2} <span style={{ float: "right" }}>{this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(this.calculateInvoiceTotal(this.state.showPdfDataFor?.currency2)), 2))}</span></div>
                  </td>
                )}
              </tr>
              </tbody>
            </table>

            <p style={{width:"500px",fontSize:"0.8rem", textAlign:"left"}}>
              PAYMENT BY WIRE TRANSFER<br/>
              ANK: BANK OF CYPRUS PLC<br/>
              SWIFT CODE : BCYPCY2NXXX<br/>
              BENEFICIARY: NYDRI LIMITED<br/>
              IBAN : CY82 0020 0195 0000 3570 0585 5034 (EUR ACC)<br/>
              IBAN : CY60 0020 0195 0000 3570 0585 5042 (USD ACC)<br/>
            </p>
          </div>
        )}
        {/* End of NYDRI */}
        
      </div>
    );
  }

  formatInvoicePeriod(){
    var invoicePeriod = this.state.invoiceData?.invoicePeriod || null;
    var invoicePeriodFormatted = '';
    if(invoicePeriod){
      invoicePeriodFormatted = moment(invoicePeriod, "MM/YYYY").format('MMMM YYYY');
    }
    return invoicePeriodFormatted;
  }

  calculateInvoiceLinesTotal(currency){
    var invoiceLines = this.state.invoiceData?.invoiceLines || [];
    var exchangeRate = this.state.invoiceData?.exchangeRate || 0;
    var total = 0;
    invoiceLines.forEach((invoiceLine,idx)=>{
      if(invoiceLine.deleted !== true){
        total += parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice);
      }
    });
    if(currency!=="USD"){
      return (total * parseFloat(exchangeRate));
    }
    return total;
  }

  calculateVat(currency){
    var taxRate = this.state.invoiceData?.taxRate || null;
    //var exchangeRate = this.state.invoiceData?.exchangeRate || 0;
    var total = this.calculateInvoiceLinesTotal(currency);
    var vat = 0;
    if(taxRate!==null){
      vat = (Math.round((taxRate / 100) * total * 100) / 100);
    }
    /*if(this.state.showPdfDataFor?.currency==="EUR"){
      return (vat * parseFloat(exchangeRate));
    }*/
    return vat;
  }

  calculateInvoiceTotal(currency){
    var total = this.calculateInvoiceLinesTotal(currency);
    var vat = this.calculateVat(currency);

    return (parseFloat(total) + parseFloat(vat));
  }

  applyLocaleFormat(issuerId, value) {
    switch (issuerId) {
      case ADSPIN_ID:
        return parseFloat(value).toLocaleString('en-HK', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
      case ADSTAMP_ID:
        return parseFloat(value).toLocaleString('en-SG', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
      case APPMULTIPLE_ID:
        return parseFloat(value).toLocaleString('en-CY', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
      case KANMOBILE_ID:
        return parseFloat(value).toLocaleString('en-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
      case MINIMOB_ID:
        return parseFloat(value).toLocaleString('en-SG', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
      case SPINX_ID:
        return parseFloat(value).toLocaleString('en-SG', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
      case NYDRI_ID:
        return parseFloat(value).toLocaleString('en_VG', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
      default:
        return parseFloat(value).toLocaleString('en-SG', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
  }

  getInvoiceLinesHtml(){
    var invoiceData = this.state.invoiceData || null;
    if(invoiceData){
      var colSpan = invoiceData?.issuerId === SPINX_ID?"2":"1";
      var cssStyle1 = invoiceData?.issuerId === SPINX_ID?{padding:"2px", borderLeft:"1px solid #000", borderRight:"1px solid #000"}:null;
      var cssStyle2 = invoiceData?.issuerId === SPINX_ID?{padding:"2px", borderRight:"1px solid #000"}:null;
      var cssStyle3 = invoiceData?.issuerId === SPINX_ID?{padding:"2px", borderRight:"1px solid #000"}:{paddingLeft:"15px"};
      var cssStyle4 = invoiceData?.issuerId === MINIMOB_ID?{borderBottom: "1px solid #333"}:null;
      var cssStyle5 = invoiceData?.issuerId === MINIMOB_ID?{float:"right", marginLeft:"3px"}:null;

      var invoiceLinesHtml = (invoiceData?.invoiceLines||[]).map((invoiceLine, idx, array) => {
        if(invoiceLine.deleted !== true) {

          if(invoiceData?.issuerId === KANMOBILE_ID){
            return (
              <tr key={"invoiceLine_" + idx}>
                <td>{invoiceLine.description || ""}</td>
                <td style={{textAlign:"right"}}>
                  {this.state.showPdfDataFor?.currency !== "USD" ? this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice) * parseFloat(invoiceData?.exchangeRate), 2)) : this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice), 2)) + " " + this.state.showPdfDataFor?.currency}
                </td>
                <td></td>
                <td></td>
                <td></td>
                {/*{this.state.showPdfDataFor?.currency2 && (
                  <td style={{textAlign:"right"}}>
                    {this.state.showPdfDataFor?.currency2==="EUR"?N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice) * parseFloat(invoiceData?.exchangeRate), 2) : N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice), 2) + " " + this.state.showPdfDataFor?.currency }
                  </td>
                )}*/}
              </tr>);
          }
          else{
            return (
              <tr key={"invoiceLine_" + idx} style={cssStyle4}>
                <td colSpan={colSpan} style={cssStyle1}>{invoiceLine.description || ""}</td>
                <td style={cssStyle2}>
                  <span style={cssStyle5}>{this.state.showPdfDataFor?.currencySymbol}</span>
                  <span style={{float:"right"}}>{
                    this.state.showPdfDataFor?.currency !== "USD" ? this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice) * parseFloat(invoiceData?.exchangeRate), 2)) : this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice), 2))
                  }
                </span>
                </td>
                {this.state.showPdfDataFor?.currency2 && (
                  <td style={cssStyle3}>
                    <span style={cssStyle5}>{this.state.showPdfDataFor?.currencySymbol2}</span>
                    <span style={{float:"right"}}>{
                      this.state.showPdfDataFor?.currency2 !== "USD" ? this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice) * parseFloat(invoiceData?.exchangeRate), 2)) : this.applyLocaleFormat(invoiceData?.issuerId, N(parseFloat(invoiceLine.quantity) * parseFloat(invoiceLine.unitPrice), 2))
                    }
                </span>
                  </td>
                )}
              </tr>);
          }

        }
        else{
          return null;
        }
      });
      return invoiceLinesHtml;
    }

  }
}