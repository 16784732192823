import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {ButtonGroup, Button, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import AddDialog from "./AddDialog";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import { UserContext } from "lib/user";
import { digitGrouping } from "lib/Functions";
import moment from "moment-timezone";

function renderPacing(d, t, r, m) {
  if (t === "display") {
    var sumHourlyCaps = 0;
    if(r.pacingType==="even"){
      sumHourlyCaps = 0;
      (r.pacing || []).forEach((pace, idx, array) => {
        var parsed = parseInt(pace.impressionCap);
        if (isNaN(parsed)) { parsed = 0; }
        sumHourlyCaps = sumHourlyCaps + parsed;
      });
      //return (d && {<div>Even Pacing - <strong>{sumHourlyCaps}</strong> Daily Imps / Clicks</div>});
      return (d && `Even Pacing - <strong>${digitGrouping(sumHourlyCaps)}</strong> Daily Imps / Clicks`);
    }
    else if(r.pacingType==="smart"){
      var maxImpressionsPerDay = r.smartpacing && r.smartpacing.hasOwnProperty("maxImpressionsPerDay")?r.smartpacing.maxImpressionsPerDay:"N/A";
      return (d && `Smart Pacing - <strong>${digitGrouping(maxImpressionsPerDay)}</strong> Max Daily Imps / Clicks`);
    }
    else{
      var pacings = d.map(x => `${x.timeRange}&nbsp;(<strong>${digitGrouping(x.impressionCap)}</strong>)`).join(", ");
      sumHourlyCaps = 0;
      (r.pacing || []).forEach((pace, idx, array) => {
        var parsed = parseInt(pace.impressionCap);
        if (isNaN(parsed)) { parsed = 0; }
        sumHourlyCaps = sumHourlyCaps + parsed;
      });
      var sumText = `Manual Pacing - <strong>${digitGrouping(sumHourlyCaps)}</strong> Daily Imps / Clicks</br>`
      return (
        d &&
        sumText + pacings 
      );
    }
    
  } else if (t === "filter" || t === "sort") {
    if(r.pacingType==="even" || r.pacingType==="smart"){
      return d && r.pacingType;
    }else{
      return d && d.map(x => x.timeRange);
    }
  }
  return d;
}

function renderUserName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    return m.settings.json.names && m.settings.json.names[d] ? m.settings.json.names[d] : d;
  }
  return d;
}

function renderDateTime(d, t, r, m) {
  return t === "display" && d ? moment.utc(d).format("YYYY-MM-DD HH:mm:ss") : d;
}

export default class CampaignPacing extends React.Component {
  static title = "Campaign Pacing";

  tableRef = React.createRef();
  
  constructor(props) {
    super(props);
    this.state = {
      showAdd: false,
      showEdit: false,
      showDelete: false,
      busy: false,
      error: null,
      showEditDataFor: null,
      showDeleteDataFor: null,
      tableUrl: "/api/v1/tools/campaignpacing",
      selectedCampaignState:["Active"],
      selectedModerationStatus:["Approved","PendingModeration"], //Approved=Available
    };
  }
  
  render() {
    const { showEditDataFor } = this.state;
    const { showDeleteDataFor } = this.state;
    
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={CampaignPacing.title}
          description={
          <p>
            Campaigns that have a campaign pace.
          </p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
                <ButtonGroup bsSize="small">
                  <Button onClick={this.handleAddClick.bind(this,user)}>
                    Add
                  </Button>
                </ButtonGroup>
                <div style={{position:'absolute', top:'10px', left:'90px'}}>
                  <ToggleButtonGroup
                    style={{marginRight:"15px"}}
                    bsSize="small"
                    label="Campaign state"
                    type="checkbox"
                    value={this.state.selectedCampaignState}
                    onChange={(e) => {
                      var value = e;
                      if(e && (e.length>2 || e.includes("All"))){
                        var prevState = this.state.selectedCampaignState;
                        if(prevState.includes("All") && !e.includes("All")){
                          value = [];
                        }else{
                          if(prevState.length>e.length){
                            value = e.filter(function(val) { return val !== "All" })
                          }else{
                            value = ["Active","Inactive","Deleted","All"];
                          }
                        }
                      }
                      this.setState({ selectedCampaignState: value });
                      this.handleReload("campaignState",value);
                    }}
                  >
                    <ToggleButton value={"Active"}>Active</ToggleButton>
                    <ToggleButton value={"Inactive"}>Inactive</ToggleButton>
                    <ToggleButton value={"Deleted"}>Deleted</ToggleButton>
                    <ToggleButton value={"All"}>All</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    bsSize="small"
                    label="Moderation status"
                    type="checkbox"
                    value={this.state.selectedModerationStatus}
                    onChange={(e) => {
                      var value = e;
                      if(e && (e.length>2 || e.includes("All"))){
                        var prevState = this.state.selectedModerationStatus;
                        if(prevState.includes("All") && !e.includes("All")){
                          value = [];
                        }else{
                          if(prevState.length>e.length){
                            value = e.filter(function(val) { return val !== "All" })
                          }else{
                            value = ["Approved","PendingModeration","Rejected","All"];
                          }
                        }
                      }
                      this.setState({ selectedModerationStatus: value });
                      this.handleReload("moderationStatus",value);
                    }}
                  >
                    <ToggleButton value={"Approved"}>Available</ToggleButton>
                    <ToggleButton value={"PendingModeration"}>Pending Moderation</ToggleButton>
                    <ToggleButton value={"Rejected"}>Rejected</ToggleButton>
                    <ToggleButton value={"All"}>All</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped"
                  ref={this.tableRef}
                  ajax={{
                    url: this.getTableUrl('init'),
                    dataSrc: "items"
                  }}
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      if(commandData && commandData.action==='delete')
                        this.setState({ showDeleteDataFor: commandData })
                      else
                        this.setState({ showEditDataFor: commandData })
                      }
                    }
                  }
                  columns={[
                    {
                      title: "Campaign ID",
                      data: "offerid"
                    },
                    {
                      title: "Campaign Name",
                      data: "offer_name",
                      defultContent: "",
                      width: "300px",
                    },
                    {
                      title: "Campaign state",
                      data: "campaignState",
                      name: "campaignState",
                      width: "120px",
                    },
                    DataTable.StockColumns.moderationStatus("moderationStatus", "moderationStatus"),
                    {
                      title: "Campaign Pacing",
                      data: "pacing",
                      defultContent: "",
                      render: renderPacing,
                    },
                    {
                      title: "Modified at",
                      data: "moderationDate",
                      className: "text-nowrap",
                      order: "desc",
                      defultContent: "",
                      render: renderDateTime,
                    },
                    {
                      title: "Modified by",
                      data: "moderatorId",
                      className: "text-nowrap",
                      defultContent: "",
                      sortable: true,
                      render: renderUserName,
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        offerid: rowData.offerid,
                        pacingType: rowData.pacingType,
                        pacing: rowData.pacing,
                        smartpacing: rowData.smartpacing||null,
                        offerName: rowData.offer_name
                      }),
                      render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default'> Edit </button></div>"),
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Actions",
                      data: null,
                      className: "text-left",
                      orderable: false,
                      width: "30px",
                      defultContent: "",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'delete',
                        offerid: rowData.offerid,
                        offerName: rowData.offer_name,
                      }),
                      render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default'> Delete </button></div>"),
                    })
                  ]}
                  order={[[5, "desc"]]}
                />
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {this.state.showAdd && (
          <AddDialog   
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showAdd: false 
              })
            }}
          />
        )}

        {showEditDataFor && (
          <EditDialog
            offerid={showEditDataFor.offerid}
            pacing={showEditDataFor.pacing}
            pacingType={showEditDataFor.pacingType}
            smartpacing={showEditDataFor.smartpacing}
            offerName={showEditDataFor.offerName}
            onHide={() => {
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showEdit: false, 
                  showEditDataFor: null
                })
              );
            }}
          />
        )}

        {showDeleteDataFor && (
          <DeleteDialog
            offerid={showDeleteDataFor.offerid}
            offerName={showDeleteDataFor.offerName}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showDelete: false, 
                showDeleteDataFor: null 
              })
            }}
          />
        )}
      </>
    );
  }

  handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({showAdd: true});
    }
  }

  constructTableUrl(name,e){
    var selectedCampaignState = name==="campaignState"?e:this.state.selectedCampaignState;
    var selectedModerationStatus = name==="moderationStatus"?e:this.state.selectedModerationStatus;
    var values1 = (selectedCampaignState && (selectedCampaignState.includes("All") || selectedCampaignState.length>2)) ? "" : selectedCampaignState.join(",");
    var values2 = (selectedModerationStatus && (selectedModerationStatus.includes("All") || selectedModerationStatus.length>2)) ? "" : selectedModerationStatus.join(",");
    var qs1 = ((values1&&values1.length)?"CampaignState="+values1:"");
    var qs2 = ((values2&&values2.length)?"ModerationStatus="+values2:"");

    var qs = "";
    if(qs1.length||qs2.length){
      qs = "?"+qs1+(qs2.length?"&"+qs2:"");
    }
    var newUrl = this.state.tableUrl+qs;

    return newUrl;
  }

  handleReload(name,e){
    var newUrl = this.constructTableUrl(name,e);
    this.tableRef.current.setNewUrl(newUrl);
  }

  getTableUrl(init){
    return this.constructTableUrl(init);
  }
}
