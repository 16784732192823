import DataTable from "components/DataTable";
import { isDemand } from "../functions";

function commonColumns(affiliateSide, response) {
  return [
    {
      data: "affiliateId",
      title: isDemand(affiliateSide) ? "Advertiser Id" : "Publisher Id"
    },
    {
      data: "affiliateId",
      title: isDemand(affiliateSide) ? "Advertiser Name" : "Publisher Name",
      render: (d, t, r, m) =>
        d && response.affiliateNames && response.affiliateNames[parseInt(d)]
    }
  ];
}

function adopsColumns(response) {
  return [
    {
      title: "Office",
      data: "affiliateId",
      defaultContent: "",
      className: "columnset columnset-adops",
      render: (d, t, r, m) => d && response.extras && response.extras[d].office
    },
    {
      title: "Team",
      data: "affiliateId",
      defaultContent: "",
      className: "columnset columnset-adops",
      render: (d, t, r, m) => d && response.extras && response.extras[d].team
    },
    {
      title: "AM",
      data: "affiliateId",
      defaultContent: "",
      className: "columnset columnset-adops",
      render: (d, t, r, m) =>
        d && response.extras && response.extras[d].accountManager
    },
    {
      title: "Adops",
      data: "affiliateId",
      defaultContent: "",
      className: "columnset columnset-adops",
      render: (d, t, r, m) =>
        d &&
        response.extras &&
        [response.extras[d].adops, response.extras[d].adops2]
          .filter(x => x)
          .join(", ")
    }
  ];
}

function groupByAffiliateColumns(affiliateSide, response) {
  return [
    {
      title: isDemand(affiliateSide) ? "Publisher Id" : "Advertiser Id",
      data: "groupByAffiliateId",
      defaultContent: "",
      className: "columnset columnset-groupByAffiliate"
    },
    {
      title: isDemand(affiliateSide) ? "Publisher Name" : "Advertiser Name",
      data: "groupByAffiliateId",
      defaultContent: "",
      className: "columnset columnset-groupByAffiliate",
      render: (d, t, r, m) =>
        d && response.affiliateNames && response.affiliateNames[parseInt(d)]
    }
  ];
}

function groupByCampaignColumns(response) {
  return [
    {
      title: "Campaign Id",
      data: "groupByCampaignId",
      defaultContent: "",
      className: "columnset columnset-groupByCampaign"
    },
    {
      title: "Campaign Name",
      data: "groupByCampaignId",
      defaultContent: "",
      className: "columnset columnset-groupByCampaign",
      render: (d, t, r, m) =>
        d && response.campaignNames && response.campaignNames[d]
    }
  ];
}

function leftColumns() {
  return [
    {
      title: "Clicks",
      data: "clicks",
      defaultContent: "",
      className: "text-right range-cell-0 columnset columnset-clicks",
      render: DataTable.StockRenderers.count()
    },
    {
      title: "Installs",
      data: "installs",
      defaultContent: "",
      className: "text-right range-cell-0 columnset columnset-installs",
      render: DataTable.StockRenderers.count()
    },
    {
      title: "InstallsOpt",
      data: "installsOpt",
      defaultContent: "",
      className: "text-right range-cell-0 columnset columnset-installsOpt",
      render: DataTable.StockRenderers.count()
    },
    {
      title: "Events",
      data: "events",
      defaultContent: "",
      className: "text-right range-cell-0 columnset columnset-events",
      render: DataTable.StockRenderers.count()
    },
    {
      title: "Revenue",
      data: "revenue",
      defaultContent: "",
      className: "text-right range-cell-0 columnset columnset-revenue",
      render: DataTable.StockRenderers.money()
    },
    {
      title: "Cost",
      data: "cost",
      defaultContent: "",
      className: "text-right range-cell-0 columnset columnset-cost",
      render: DataTable.StockRenderers.money()
    }
  ];
}

function rangeColumns(rangeId) {
  return [
    {
      title: "Clicks",
      data: `clicks${rangeId}`,
      defaultContent: "",
      className: `text-right range-cell-${rangeId} columnset columnset-clicks-${rangeId}`,
      render: DataTable.StockRenderers.count()
    },
    {
      title: "Increase",
      data: `clicks${rangeId}Incr`,
      defaultContent: "",
      className: `text-right columnset columnset-clicks-${rangeId}`,
      render: DataTable.StockRenderers.countIncrease()
    },
    {
      title: "Increase %",
      data: `clicks${rangeId}IncrPrc`,
      defaultContent: "",
      className: `text-right columnset columnset-clicks-${rangeId}`,
      render: DataTable.StockRenderers.countIncreasePercent()
    },
    {
      title: "Installs",
      data: `installs${rangeId}`,
      defaultContent: "",
      className: `text-right range-cell-${rangeId} columnset columnset-installs-${rangeId}`,
      render: DataTable.StockRenderers.count()
    },
    {
      title: "Increase",
      data: `installs${rangeId}Incr`,
      defaultContent: "",
      className: `text-right columnset columnset-installs-${rangeId}`,
      render: DataTable.StockRenderers.countIncrease()
    },
    {
      title: "Increase %",
      data: `installs${rangeId}IncrPrc`,
      defaultContent: "",
      className: `text-right columnset columnset-installs-${rangeId}`,
      render: DataTable.StockRenderers.countIncreasePercent()
    },
    {
      title: "InstallsOpt",
      data: `installsOpt${rangeId}`,
      defaultContent: "",
      className: `text-right range-cell-${rangeId} columnset columnset-installsOpt-${rangeId}`,
      render: DataTable.StockRenderers.count()
    },
    {
      title: "Increase",
      data: `installsOpt${rangeId}Incr`,
      defaultContent: "",
      className: `text-right columnset columnset-installsOpt-${rangeId}`,
      render: DataTable.StockRenderers.countIncrease()
    },
    {
      title: "Increase %",
      data: `installsOpt${rangeId}IncrPrc`,
      defaultContent: "",
      className: `text-right columnset columnset-installsOpt-${rangeId}`,
      render: DataTable.StockRenderers.countIncreasePercent()
    },
    {
      title: "Events",
      data: `events${rangeId}`,
      defaultContent: "",
      className: `text-right range-cell-${rangeId} columnset columnset-events-${rangeId}`,
      render: DataTable.StockRenderers.count()
    },
    {
      title: "Increase",
      data: `events${rangeId}Incr`,
      defaultContent: "",
      className: `text-right columnset columnset-events-${rangeId}`,
      render: DataTable.StockRenderers.countIncrease()
    },
    {
      title: "Increase %",
      data: `events${rangeId}IncrPrc`,
      defaultContent: "",
      className: `text-right columnset columnset-events-${rangeId}`,
      render: DataTable.StockRenderers.countIncreasePercent()
    },
    {
      title: "Revenue",
      data: `revenue${rangeId}`,
      defaultContent: "",
      className: `text-right range-cell-${rangeId} columnset columnset-revenue-${rangeId}`,
      render: DataTable.StockRenderers.money()
    },
    {
      title: "Increase",
      data: `revenue${rangeId}Incr`,
      defaultContent: "",
      className: `text-right columnset columnset-revenue-${rangeId}`,
      render: DataTable.StockRenderers.moneyIncrease()
    },
    {
      title: "Increase %",
      data: `revenue${rangeId}IncrPrc`,
      defaultContent: "",
      className: `text-right columnset columnset-revenue-${rangeId}`,
      render: DataTable.StockRenderers.moneyIncreasePercent()
    },
    {
      title: "Cost",
      data: `cost${rangeId}`,
      defaultContent: "",
      className: `text-right range-cell-${rangeId} columnset columnset-cost-${rangeId}`,
      render: DataTable.StockRenderers.money()
    },
    {
      title: "Increase",
      data: `cost${rangeId}Incr`,
      defaultContent: "",
      className: `text-right columnset columnset-cost-${rangeId}`,
      render: DataTable.StockRenderers.moneyIncrease()
    },
    {
      title: "Increase %",
      data: `cost${rangeId}IncrPrc`,
      defaultContent: "",
      className: `text-right columnset columnset-cost-${rangeId}`,
      render: DataTable.StockRenderers.moneyIncreasePercent()
    }
  ];
}

export function getColumns(affiliateSide, response) {
  return [
    ...commonColumns(affiliateSide, response),
    ...adopsColumns(response),
    ...groupByAffiliateColumns(affiliateSide, response),
    ...groupByCampaignColumns(response),
    ...leftColumns(),
    ...rangeColumns(1),
    ...rangeColumns(2),
    ...rangeColumns(3),
    ...rangeColumns(4),
    ...rangeColumns(5)
  ];
}
