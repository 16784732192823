import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  ButtonToolbar,
  Alert,
  FormControl, Button
} from "react-bootstrap";

const STARTFROM_MIN_VALUE = 1;
const STARTFROM_MAX_VALUE = 100000000;

export default class PublisherMaxRisk extends React.Component {
  static title = "Publisher Max Risk";
  
  constructor(props) {
    super(props);
    this.state = {
      campaignId: null,
      publisherMaxRisk: null,
      isPIE: false,
      error: null,
      busy: false,
      response: null,
      msg: null,
      formErrors: {},
      formIsValid: null
    };
  }

  render() {
    //const { error, busy, response } = this.state;
    const errorSummary = buildErrorSummary(this.state.error);
    const msgSummary = this.state.msg;
    var formErrors = this.state.formErrors;
    var saveBtnDisabled = (!this.state.campaignId ||
      !this.state.publisherMaxRisk || formErrors.publisherMaxRisk) ? true:false;
    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={PublisherMaxRisk.title}
        description={""}
      >
        <>
          {errorSummary && (
            <Alert
              bsStyle="danger"
              onDismiss={() => this.setState({ error: null })}
            >
              {errorSummary}
            </Alert>
          )}
          {msgSummary && (
            <Alert
              bsStyle="success"
              onDismiss={() => this.setState({ msg: null })}
            >
              {msgSummary}
            </Alert>
          )}
          <Box busy={this.state.busy}>            
            <Box.Header>
              Please insert a campaign ID.
            </Box.Header>
            <Box.Body>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Campaign ID</ControlLabel>
                    <FormControl
                      componentClass="input"
                      placeholder="Enter campaign ID"
                      maxLength={15}
                      onChange={e =>
                        this.setState({ campaignId: e.target.value })
                      }
                      value={this.state.campaignId||''}
                    />
                    <ButtonToolbar style={{marginTop:'10px'}}>
                      <Button
                        bsStyle="primary"
                        disabled={!this.state.campaignId}
                        onClick={this.handleGetPublisherMaxRisk.bind(this)}
                      >Retrieve Publisher Max Risk</Button>
                    </ButtonToolbar>
                  </FormGroup>
                  
                  <FormGroup className={formErrors.publisherMaxRisk?'form-error':null}>
                    <ControlLabel>Publisher Max Risk</ControlLabel>
                    <FormControl
                      componentClass="input"
                      placeholder="Enter publisher max risk"
                      type={"number"}
                      maxLength={"9"}
                      max={STARTFROM_MAX_VALUE}
                      min={STARTFROM_MIN_VALUE}
                      onChange={e=>this.handleInputChange(e,'publisherMaxRisk')}
                      value={this.state.publisherMaxRisk||''}
                    />
                    <div className={"error-msg"}>{formErrors.publisherMaxRisk||''}</div>
                  </FormGroup>
                </Col>
              </Row>
            </Box.Body>
            <Box.Footer>
              <ButtonToolbar>
                <Button
                  bsStyle="primary"
                  disabled={saveBtnDisabled}
                  onClick={this.handleChangePublisherMaxRisk.bind(this, false)}
                >Change Publisher Max Risk</Button>
              </ButtonToolbar>
            </Box.Footer>
          </Box>
        </>
        
      </PageLayout>
    );
  }

  handleInputChange(e, name){

    var val = e.target.value;
    var formErrors = this.state.formErrors;
    const decimalRegex = new RegExp(/^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/);
    
    if(typeof val !== "undefined"){
      if(val.length>9+3 || val.split('.')[0].length>9){return false;}
      if(decimalRegex.test(val) && (val!==null && val.length>0 && parseInt(val)>=STARTFROM_MIN_VALUE && parseInt(val)<=STARTFROM_MAX_VALUE)){
        formErrors[name] = null;
      }else{
        formErrors[name] = val===""?"This field cannot be empty":"Only numbers with max 2 decimals are allowed. Allowed range: 1.00 - 100000000.00";
      }
      
      var newState = { formErrors: formErrors }
      newState[name] = e.target.value;
      this.setState(newState)
    }
  }
  
  handleChangePublisherMaxRisk(){
    this.handleGetCampaignInfo();
  }
  
  resetStateData(){
    this.setState({
      campaignId: null,
      publisherMaxRisk: null,
      isPIE: false,
      response: null,
      formErrors: {},
      formIsValid: null,
    });
  }
  
  handleSave(campaignId) {
    this.setState({ busy: true, error: null, msg: null });    
    
    var postData = {
      CampaignId: campaignId,
      PublisherRiskCeiling: parseFloat(this.state.publisherMaxRisk)
    }
    
    axios({
      url: "/api/v1/tools/savepublishermaxrisk?id="+campaignId,
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "json",
      data: postData,
    })
      .then(res => {
        var msgList = [];
        var msg = null;
        
        if(res && res.data && res.data.length>0){
          (res.data).forEach((item,idx)=>{
            if(item){              
              var campaignId = item.campaignId||"N/A";
              var publisherMaxRisk = item.publisherRiskCeiling!==undefined?item.publisherRiskCeiling:"N/A";
              msgList.push(<span>Campaign with <strong>ID: {campaignId}</strong> was saved successfully with the following value:</span>);
              msgList.push(<span><strong>Publisher Max Risk: </strong>{publisherMaxRisk}</span>);
            }
          });
        }
        if(msgList.length>0){
          msg = <ul>
            {msgList.map((x, i) => (
              <li style={(i===0?{listStyle:'none', marginLeft:'-15px'}:{wordBreak:'break-all'})} key={i}>{x}</li>
            ))}
          </ul>;
        }
        
        this.resetStateData();
        this.setState({
          msg: msg,
          error: null,
          busy: false
        });
        return;
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        this.setState({
          error,
          busy: false
        });

      });
  }
  
  getCampaignInfoCallback(res, campaignId){
    
    var resData = res.data;
    var campaignFound = resData&&resData._id?true:false;

    if(!campaignFound){
      this.resetStateData();
      this.setState({
        error: "Campaign with id: '"+campaignId+"' was not found.",
        busy: false
      });
      return;
    }

    var isPIE = resData&&resData.isCPIOnly!==true?true:false;

    if(!isPIE){
      this.resetStateData();
      this.setState({
        error: "Campaign with id: '"+campaignId+"' is not a PIE campaign.",
        busy: false
      });
      return;
    }
    
    this.setState({
      isPIE: isPIE,
      error: null,
      busy: false,
      response: resData||null
    });
    
    this.handleSave(campaignId)
      
  }
  
  handleGetCampaignInfo() {
    this.setState({ busy: true, error: null, msg: null });
    
    var campaignId = this.state.campaignId!==null&&(this.state.campaignId).length>0?this.state.campaignId.replaceAll(" ",""):"";
    
    axios({
      url: "/api/v1/tools/getcampaigninfo?id="+campaignId,
      method: "get",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "json",
    })
      .then(res => this.getCampaignInfoCallback(res, campaignId))
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        
          this.setState({
            error,
            busy: false
          });
        
      });
  }

  getPublisherMaxRiskCallback(res, campaignId){

    var resData = res.data;
    var campaignFound = resData&&resData.campaignId?true:false;

    this.setState({
      publisherMaxRisk: campaignFound&&resData&&resData.hasOwnProperty("publisherRiskCeiling")?resData.publisherRiskCeiling:null,
      error: null,
      busy: false,
      response: resData||null
    });

  }
  
  handleGetPublisherMaxRisk() {
    this.setState({ busy: true, error: null, msg: null });

    var campaignId = this.state.campaignId!==null&&(this.state.campaignId).length>0?this.state.campaignId.replaceAll(" ",""):"";

    axios({
      url: "/api/v1/tools/getpublishermaxrisk?CampaignId="+campaignId,
      method: "get",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "json",
    })
      .then(res => this.getPublisherMaxRiskCallback(res, campaignId))
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        
        this.resetStateData();
        this.setState({
          error,
          busy: false
        });

      });
  }
}
