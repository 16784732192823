import React from "react"

const ServerStats = ({ active, inactive, name }) =>
  <div className="text-center text-sm pad margin" style={{ width: "125px" }} title="Active feeders per server">
    <div>
      <strong style={{ fontSize: "1.5em" }}>{active}</strong>
      {`/${active + inactive}`}
    </div>
    <div>{name}</div>
  </div>

const ServerStatsSeparator = () =>
  <div style={{ marginTop: "auto", marginBottom: "auto", width: "2px", height: "50px", backgroundColor: "#f4f4f4" }}></div>

const AllServerStats = ({ stats }) => Object.keys(stats)
  .sort((a, b) => {
    if (a === "NO SERVER") return 1
    if (b === "NO SERVER") return -1
    return stats[b].active - stats[a].active
  })
  .map((name, i) => (
    <React.Fragment key={name}>
      {i > 0 && (
        <ServerStatsSeparator />
      )}
      <ServerStats name={name} {...stats[name]} />
    </React.Fragment>
  ))

export default AllServerStats