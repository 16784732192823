import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col,
  ToggleButton,
  ButtonToolbar,
  ToggleButtonGroup
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

import {PublisherPicker} from "components/pickers/SelectPickers";
export default class AddDialog extends React.Component {
  static title = "Add App to blacklist";

  constructor(props) {
    super(props);
    this.state = {
      osPlatform: '0',
      package: null,
      title: null,
      radioValue:'package',
      publisherIds:[],
      error: null,
      busy: false,
      message: null,
      formErrors: {},
      formIsValid: null
    };
    this.handleAppAddition = this.handleAppAddition.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    var formErrors = this.state.formErrors;
    
    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Add App to Blacklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            Please enter the package name (bundle id) or part of the app title of the app that you want to blacklist.
          </p>
          <Box.Body>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <label>OS Platform</label>
                  <select
                    className="form-control"
                    value={this.state.osPlatform||'0'}
                    onChange={e => {this.setState({ osPlatform: e.target.value }); this.handleInputChange({target:{value:this.state.package}})}}
                  >
                    <option value="0">Android</option>
                    <option value="1">iOS</option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <label>Blacklist App by:</label>
                  <ButtonToolbar>
                    <ToggleButtonGroup type="radio" name="blacklistBy" defaultValue={'package'} style={{width:'100%'}}>
                      <ToggleButton 
                        value={'package'}
                        checked={(this.state.radioValue === 'package')} 
                        style={{width:'50%'}}
                        onChange={e =>
                          this.setState({ radioValue: e.target.value })
                        }>Package Name</ToggleButton>
                      <ToggleButton
                        value={'title'}
                        checked={(this.state.radioValue === 'title')}
                        style={{width:'50%'}}
                        onChange={e =>
                          this.setState({ radioValue: e.target.value })
                        }>App Title</ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </FormGroup>
                {(this.state.radioValue === 'package') &&(
                <FormGroup className={formErrors.package?'form-error':null}>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={100}
                    placeholder="Enter package name (bundle id)"
                    onChange={e=>this.handleInputChange(e,'package')}
                    defaultValue={this.state.package}
                  />
                  <div className={"error-msg"}>{formErrors.package||''}</div>
                </FormGroup>)}
                {(this.state.radioValue === 'title') &&(
                <FormGroup className={formErrors.title?'form-error':null}>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={100}
                    placeholder="Enter part of app title"
                    onChange={e=>this.handleInputChange(e,'title')}
                    defaultValue={this.state.title}
                  />
                  <div className={"error-msg"}>{formErrors.title||''}</div>
                </FormGroup>)}
                {!(this.state.osPlatform === '0' && this.state.radioValue === 'package') &&(
                <FormGroup>
                  <ControlLabel>User Exclusions</ControlLabel>
                  <PublisherPicker
                    className="form-control"
                    multiple
                    placeholder="Enter ids or names"
                    onChange={value => this.setState({ publisherIds: value })}
                  />
                </FormGroup>)}
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              ((this.state.radioValue === 'package' && (!this.state.package || formErrors.package)) || (this.state.radioValue === 'title' && (!this.state.title || formErrors.title)))?true:false
            }
            bsStyle="primary"
            onClick={this.handleAppAddition}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  handleInputChange(e, name){
    
      var val = e.target.value;
      var formErrors = this.state.formErrors;
      var allowDots = this.state.osPlatform === '0' && name === 'package';
      const regex = new RegExp(/^[a-zA-Z0-9.]+$/);
      const regexNoDots = new RegExp(/^[a-zA-Z0-9]+$/);
      if(typeof val !== "undefined"){
        
        if(allowDots){
          if(!regex.test(val)){
            formErrors[name] = val===""?"This field cannot be empty":"Only letters, numbers and dots are allowed.";
          }else{
            formErrors[name] = null;
          }
        }else{
          if(!regexNoDots.test(val)){
            formErrors[name] = val===""?"This field cannot be empty":"Only letters and numbers are allowed.";
          }else{
            formErrors[name] = null;
          }
        }
        
        var newState = { formErrors: formErrors }
        newState[name] = e.target.value;
        this.setState(newState)
      }
  }
  
  handleAppAddition() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });
    let postData = {
      osPlatform: this.state.osPlatform&&this.state.osPlatform.length?parseInt(this.state.osPlatform):null
    };
    if(this.state.radioValue === 'package'){
      postData.package = this.state.package||'';
    }else if(this.state.radioValue === 'title'){
      postData.title = this.state.title||'';
    }
    if(this.state.publisherIds&&this.state.publisherIds.length){
      postData.exclusions = this.state.publisherIds;
    }
    axios({
      url: "/api/v1/reports/addblacklistedapp",
      method: "post",
      data: postData
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
