import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import DetailReportModal from "./DetailReportModal";
import {ButtonGroup, FormGroup} from "react-bootstrap";
import { UserContext } from "lib/user";
import moment from "moment-timezone";
import DateRangePicker from "components/pickers/DateRangePicker";
import { N } from "lib/Functions";
import $ from "jquery";
import RtbDataHistoryModal from "./RtbDataHistoryModal";

function renderDate(d, t, r, m, selectedDate) {
  
  if(d && (t === "filter")){
    var date = new Date(moment.utc(d).format("YYYY-MM-DD"));
    if(selectedDate && selectedDate.length===2){
        var _dateMin = moment.utc(selectedDate[0]).format("YYYY-MM-DD");
        var _dateMax = moment.utc(selectedDate[1]).format("YYYY-MM-DD");
        var dateMin = new Date(_dateMin);
        var dateMax = new Date(_dateMax);
      if (
        ( dateMin === null && dateMax === null ) ||
        ( dateMin === null && date <= dateMax ) ||
        ( dateMin <= date   && dateMax === null ) ||
        ( dateMin <= date   && date <= dateMax )
      ) {
        return _dateMin+"_"+_dateMax;
      }
    }
    return moment.utc(d).format("YYYY-MM-DD");
  }
  if (d && t === "display") {
    return moment.utc(d).format("YYYY-MM-DD");
  }
  return d;
}

function renderPartner(d, t, r, m) {
  if (t === "filter") {
    return d?d:"";
  }
  return d;
}

export default class RtbReport extends React.Component {
  static title = "RTB Report";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showDetailReportFor: null,
      showHistoryFor: null,
      selectedDate:[moment().startOf("day"),moment().endOf("day")]
    };
    this.selectDate = this.selectDate.bind(this);
  }

  componentDidMount() {
    this.selectDate(this.state.selectedDate);
  }
  
  render() {
    const { showDetailReportFor, showHistoryFor } = this.state;
    return (
      <>
        <PageLayout
          breadcrumbs={["Reports"]}
          title={RtbReport.title}
          description={
            <p></p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                  <FormGroup className="date-range-picker-filter pull-left">
                    <label className="date-range-picker-filter-label">Report Date</label>
                    <DateRangePicker
                      className="form-control date-range-picker-filter-input"
                      opens="right"
                      onChange={(value) => this.selectDate(value)}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment()
                            .subtract(1, "days")
                            .startOf("day"),
                          moment()
                            .subtract(1, "days")
                            .endOf("day")
                        ],
                        "This week so far": [
                          moment().startOf("isoWeek"),
                          moment().endOf("day")
                        ],
                        "Last week": [
                          moment()
                            .subtract(1, "weeks")
                            .startOf("isoWeek"),
                          moment()
                            .subtract(1, "weeks")
                            .endOf("isoWeek")
                        ],
                        "This month so far": [
                          moment().startOf("month"),
                          moment().endOf("day")
                        ],
                        "Last month": [
                          moment()
                            .subtract(1, "months")
                            .startOf("month"),
                          moment()
                            .subtract(1, "months")
                            .endOf("month")
                        ]
                      }}
                      minDate={moment("2019-01-18", "YYYY-MM-DD")}
                      maxDate={moment().endOf("day")}
                      startDate={moment().startOf("day")}
                      endDate={moment().endOf("day")}
                    />
                  </FormGroup>
                  
                  <ButtonGroup>
                    <DataTable.ColumnFilter
                      tableRef={this.tableRef}
                      columnName="partner"
                      bsSize="small"
                      title="Partner"
                      defaultValue=""
                      values={[
                        ["*", ""],
                        ["adinfinitum", "adinfinitum"],
                        ["brave", "brave"],
                        ["yandex", "yandex"]
                      ]}
                    />
                  </ButtonGroup>
                </DataTable.Toolbar>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped table-hover"
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/reports/rtbdata",
                    dataSrc: "items"
                  }}
                  onLoading={() => this.setState({ loading: true })}
                  onLoaded={() => this.setState({ loading: false })}
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      if(commandData.action === "showHistory"){
                        this.setState({ showHistoryFor: commandData });
                      }else{
                        this.setState({ showDetailReportFor: commandData });
                      }
                      
                    }
                  }}
                  columns={[
                    {
                      title: "Report Date",
                      data: "reportDate",
                      name: "reportDate",
                      className: "text-nowrap",
                      width: "10%",
                      defaultContent: "",
                      render: (d, t, r, m) => renderDate(d, t, r, m, this.state.selectedDate),
                      order: "desc"
                    },
                    { 
                      title: "Partner", 
                      data: "partner",
                      name: "partner",
                      width: "15%",
                      defaultContent: "",
                      render: (d, t, r, m) => renderPartner(d, t, r, m)
                    },
                    { 
                      title: "RTB Cost",
                      data: "rtbCost",
                      className: "text-right",
                      width: "12%",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2)
                    },
                    { 
                      title: "RTB Requests",
                      data: "rtbRequests",
                      className: "text-right",
                      width: "15%",
                      defaultContent: ""
                    },
                    DataTable.createUserCommandColumn({
                      title: "QPS",
                      data: "qps",
                      className: "text-right",
                      width: "12%",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2),
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'showHistory',
                        id: rowData._id,
                        reportData: rowData.perCountry||[],
                        reportDate: rowData.reportDate?moment.utc(rowData.reportDate).format("YYYY-MM-DD"):'',
                        partner: rowData.partner
                      })
                    }),
                    {
                      title: "Revenue",
                      data: "revenue",
                      className: "text-right",
                      width: "12%",
                      defaultContent: "",
                      render: (d, t, r, m) => N(d, 2)
                    },
                    {
                      title: "",
                      data:null,
                      width: "3%",
                      defaultContent: "",
                      sortable: false,
                    },
                    DataTable.createUserCommandColumn({
                      title: "Report Details",
                      data: "perCountry",
                      name: "perCountry",
                      //className: "text-right",
                      sortable: false,
                      searchable: false,
                      render:(d,t)=>("<button type='button' class='btn btn-default btn-small'> Report detail </button>"),
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        id: rowData._id,
                        reportData: rowData.perCountry||[],
                        reportDate: rowData.reportDate?moment.utc(rowData.reportDate).format("YYYY-MM-DD"):'',
                        partner: rowData.partner
                      })
                    }),
                  ]}
                  order={[[0, "desc"]]}
                  footerCallback={(tfoot, data, start, end, display) => {
                    const gt = {
                      rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                      rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                      revenue: display.reduce((acc, cur) => (acc += data[cur].revenue), 0)
                    };
                    const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                    const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                    const footer = $(tfoot).parent();
                    
                    // Table totals
                    footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                    footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                    footer.find("#gtrevenue").html(renderMoney(gt.revenue));
                  }}
                >
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody></tbody>
                  {/* prettier-ignore */}
                  <tfoot>
                  <tr>
                    <td className="text-right" colSpan={2}>
                      Totals
                    </td>
                    <td className="text-right" id="gtrtbCost">0</td>
                    <td className="text-right" id="gtrtbRequests">0</td>
                    <td></td>
                    <td className="text-right" id="gtrevenue">0</td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tfoot>
                </DataTable>
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {showDetailReportFor && (
          <DetailReportModal
            id={showDetailReportFor.id}
            reportData={showDetailReportFor.reportData}
            reportDate={showDetailReportFor.reportDate}
            partner={showDetailReportFor.partner}
            onHide={() => this.setState({ showDetailReportFor: null })}
          />
        )}

        {this.state.showHistoryFor && (
          <RtbDataHistoryModal
            id={showHistoryFor.id}
            partner={showHistoryFor.partner}
            reportDate={showHistoryFor.reportDate}
            onHide={() => this.setState({ showHistoryFor: null })}
          />
        )}
      </>
    );
  }

  selectDate(date) {
    this.setState({ selectedDate: date }, () => {
      var columnName = "reportDate";
      if(date && date.length===2){
        var dateMin = moment.utc(date[0]).format("YYYY-MM-DD");
        var dateMax = moment.utc(date[1]).format("YYYY-MM-DD");
        
        this.tableRef.current.setColumnFilter(`${columnName}:name`, dateMin+"_"+dateMax);
      }
      else{
        this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
      }
      this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
    });
  }
}
