import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import $ from "jquery";
import { UserContext } from "lib/user";

const Login: React.FC = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [busy, setBusy] = React.useState(false);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const history = useHistory();
  const location = useLocation();
  const user = React.useContext(UserContext);

  React.useLayoutEffect(() => {
    $("body")
      .removeClass()
      .addClass("hold-transition login-page");
  }, []);

  React.useEffect(() => {
    (emailRef.current as HTMLInputElement).focus();
  }, []);
  
  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <Link to="/">
            <b>Minimob</b>Adops
          </Link>
        </div>
        {Boolean(error) && (
          <Alert bsStyle="warning" onDismiss={() => setError("")}>
            <i className="icon fa fa-ban"></i>
            {error}
          </Alert>
        )}
        <div className="login-box-body">
          <p className="login-box-msg">
            Enter your Minimob dashboard credentials
          </p>
          <form
            method="post"
            onSubmit={e => {
              e.preventDefault();
              setBusy(true);
              user
                .login(username, password)
                .then(success => {
                  setBusy(false);
                  if (success) {
                    setError("");
                    history.replace((location.state as any)?.from || "/");
                    window.location.reload();
                  } else {
                    setError("Login failed.");
                  }
                })
                .catch(err => {
                  setBusy(false);
                  setError(err.message);
                });
            }}
          >
            <div className="form-group has-feedback">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                ref={emailRef}
                value={username}
                onChange={e => setUsername(e.target.value)}
                disabled={busy}
              />
              <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
            </div>
            <div className="form-group has-feedback">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                disabled={busy}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-flat"
                >
                  {busy ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    <>Login</>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
