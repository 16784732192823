import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col, //ButtonGroup, ToggleButton
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import _ from "lodash";

export default class EditDialog extends React.Component {
  static title = "Edit Invoice Recipient details";

  constructor(props) {
    super(props);
    this.state = {
      id: props.id||null,
      title: props.title||null,
      address: props.address||null,
      address2: props.address2||null,
      companyDetails: props.companyDetails||null,
      currency: props.currency||"USD",
      notes: props.notes||null,
      interCompany: props.interCompany||null,
      accountCategoryId: props.accountCategoryId||1,
      externalId: props.externalId||null,
      postalCode: props.postalCode||null,
      city: props.city||null,
      country: props.country||null,
      vatNo: props.vatNo||null,
      regNo: props.regNo||null,
      email: props.email || null,
      max_credit_amount: props.max_credit_amount || null,
      settings: props.settings || [],
      error: null,
      busy: false,
      message: null
    };
    this.handleInvoiceRecipient = this.handleInvoiceRecipient.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Edit Invoice Recipient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            Please edit the invoice recipient details.
          </p>
          <Box.Body>
            {(!this.state.title || !this.state.address || (!this.state.vatNo && !this.state.regNo)) && (
              <Row>
                <Col md={12}>
                  <div className="alert alert-warning" style={{ height: "60px" }}>
                    <span style={{ float: "left", paddingTop: "5px" }}><i className="fa fa-exclamation-triangle">&nbsp;</i> Please enter required fields: Company Name, Address, Tax ID or Reg ID.</span>
                  </div>
                </Col>
              </Row>
            )
            }
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Company Name</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={200}
                    onChange={e => {
                      this.setState({ title: e.target.value });
                    }
                    }
                    defaultValue={this.state.title}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Address</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={200}
                    onChange={e => {
                      this.setState({ address: e.target.value });
                    }
                    }
                    defaultValue={this.state.address}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Address 2</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={200}
                    onChange={e =>
                      this.setState({ address2: e.target.value })
                    }
                    defaultValue={this.state.address2}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Tax ID.</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={20}
                    onChange={e =>
                      this.setState({ vatNo: e.target.value })
                    }
                    defaultValue={this.state.vatNo}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Reg. No.</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={20}
                    onChange={e =>
                      this.setState({ regNo: e.target.value })
                    }
                    defaultValue={this.state.regNo}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <ControlLabel>External Id</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={20}
                    onChange={e =>
                      this.setState({ externalId: e.target.value })
                    }
                    defaultValue={this.state.externalId}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <label>Currency</label>
                  <select
                    className="form-control"
                    defaultValue={this.state.currency||"USD"}
                    onChange={e =>
                      this.setState({ currency: e.target.value })
                    }
                  >
                    <option value="USD">USD</option>
                    <option value="SGD">SGD</option>
                    <option value="GBP">GBP</option>
                    <option value="EUR">EUR</option>
                    {/*<option value="IL">IL</option>*/}
                  </select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label>Max Credit Amount</label>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={20}
                    onChange={e =>
                      this.setState({ max_credit_amount: e.target.value })
                    }
                    defaultValue={this.state.max_credit_amount}
                  />
                </FormGroup>
                {/*<FormGroup>*/}
                {/*  <label>Account Category</label>*/}
                {/*  <select*/}
                {/*    className="form-control"*/}
                {/*    defaultValue={this.state.accountCategoryId||"1"}*/}
                {/*    onChange={e =>*/}
                {/*      this.setState({ accountCategoryId: e.target.value })*/}
                {/*    }*/}
                {/*  >*/}
                {/*    <option value="1">ZR - 0%</option>*/}
                {/*    <option value="2">SR - 7%</option>*/}
                {/*  </select>*/}
                {/*</FormGroup>*/}
              </Col>
              <Col md={3}>
                {/*<FormGroup style={{marginTop:"24px"}}>*/}
                {/*  <ButtonGroup style={{width: "100%"}}>*/}
                {/*    <ToggleButton*/}
                {/*      id="toggle-check"*/}
                {/*      type="checkbox"*/}
                {/*      variant="secondary"*/}
                {/*      checked={this.state.interCompany===true?true:false}*/}
                {/*      value="1"*/}
                {/*      style={{*/}
                {/*        width: "100%",*/}
                {/*        textAlign: "left"*/}
                {/*      }}*/}
                {/*      onChange={(e) => {*/}
                {/*        this.setState({ interCompany: e.currentTarget.checked });*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <span style={{marginLeft:"5px"}}>Inter Company</span>*/}
                {/*    </ToggleButton>*/}
                {/*  </ButtonGroup>*/}
                {/*</FormGroup>*/}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {/*<FormGroup>
                  <ControlLabel>Notes</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={200}
                    onChange={e =>
                      this.setState({ notes: e.target.value })
                    }
                    defaultValue={this.state.notes}
                  />
                </FormGroup>*/}
                <FormGroup>
                  <ControlLabel>Notes</ControlLabel>
                  <textarea
                    className="form-control"
                    maxLength={1000}
                    onChange={e =>
                      this.setState({ notes: e.target.value })
                    }
                    defaultValue={this.state.notes}
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Email</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={100}
                    onChange={e =>
                      this.setState({ email: e.target.value })
                    }
                    defaultValue={this.state.email}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Postal Code</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={10}
                    onChange={e =>
                      this.setState({ postalCode: e.target.value })
                    }
                    defaultValue={this.state.postalCode}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>City</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e =>
                      this.setState({ city: e.target.value })
                    }
                    defaultValue={this.state.city}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Country</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e =>
                      this.setState({ country: e.target.value })
                    }
                    defaultValue={this.state.country}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Issuer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={10}
                    defaultValue="APPMULTIPLE"
                    disabled="true"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Account Type</ControlLabel>
                  <select
                    className="form-control"
                    defaultValue={_.find(this.state.settings, { 'issuer_id':'6352910839f28166b1b99e40'})?_.find(this.state.settings, { 'issuer_id':'6352910839f28166b1b99e40'}).account_type:"" }
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352910839f28166b1b99e40' })) {
                        _.find(settings, { 'issuer_id': '6352910839f28166b1b99e40' }).account_type = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352910839f28166b1b99e40',
                          'account_type': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }                      
                    }>
                    <option default value="1">Normal</option>
                    <option value="2">Prepaid</option>
                    <option value="3">External</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Minimob Account</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352910839f28166b1b99e40' })) {
                        _.find(settings, { 'issuer_id': '6352910839f28166b1b99e40' }).minimob_account = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352910839f28166b1b99e40',
                          'minimob_account': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }}
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352910839f28166b1b99e40' }) ? _.find(this.state.settings, { 'issuer_id': '6352910839f28166b1b99e40' }).minimob_account : ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Issuer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={10}
                    defaultValue="NYDRI"
                    disabled="true"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Account Type</ControlLabel>
                  <select
                    className="form-control"
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352911439f28166b1b99e41' }) ? _.find(this.state.settings, { 'issuer_id': '6352911439f28166b1b99e41' }).account_type : ""}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352911439f28166b1b99e41' })) {
                        _.find(settings, { 'issuer_id': '6352911439f28166b1b99e41' }).account_type = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352911439f28166b1b99e41',
                          'account_type': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }
                    }>
                    <option default value="1">Normal</option>
                    <option value="2">Prepaid</option>
                    <option value="3">External</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Minimob Account</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352911439f28166b1b99e41' })) {
                        _.find(settings, { 'issuer_id': '6352911439f28166b1b99e41' }).minimob_account = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352911439f28166b1b99e41',
                          'minimob_account': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }}
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352911439f28166b1b99e41' }) ? _.find(this.state.settings, { 'issuer_id': '6352911439f28166b1b99e41' }).minimob_account : ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Issuer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={10}
                    defaultValue="ADSTAMP"
                    disabled="true"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Account Type</ControlLabel>
                  <select
                    className="form-control"
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352911f39f28166b1b99e42' }) ? _.find(this.state.settings, { 'issuer_id': '6352911f39f28166b1b99e42' }).account_type : ""}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352911f39f28166b1b99e42' })) {
                        _.find(settings, { 'issuer_id': '6352911f39f28166b1b99e42' }).account_type = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352911f39f28166b1b99e42',
                          'account_type': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }
                    }>
                    <option default value="1">Normal</option>
                    <option value="2">Prepaid</option>
                    <option value="3">External</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Minimob Account</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352911f39f28166b1b99e42' })) {
                        _.find(settings, { 'issuer_id': '6352911f39f28166b1b99e42' }).minimob_account = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352911f39f28166b1b99e42',
                          'minimob_account': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }}
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352911f39f28166b1b99e42' }) ? _.find(this.state.settings, { 'issuer_id': '6352911f39f28166b1b99e42' }).minimob_account : ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Issuer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={10}
                    defaultValue="MINIMOB PTE"
                    disabled="true"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Account Type</ControlLabel>
                  <select
                    className="form-control"
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352912639f28166b1b99e43' }) ? _.find(this.state.settings, { 'issuer_id': '6352912639f28166b1b99e43' }).account_type : ""}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352912639f28166b1b99e43' })) {
                        _.find(settings, { 'issuer_id': '6352912639f28166b1b99e43' }).account_type = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352912639f28166b1b99e43',
                          'account_type': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }
                    }>
                    <option default value="1">Normal</option>
                    <option value="2">Prepaid</option>
                    <option value="3">External</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Minimob Account</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352912639f28166b1b99e43' })) {
                        _.find(settings, { 'issuer_id': '6352912639f28166b1b99e43' }).minimob_account = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352912639f28166b1b99e43',
                          'minimob_account': e.target.value
                        })
                      }                      
                      this.setState({ settings: settings })
                    }}
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352912639f28166b1b99e43' }) ? _.find(this.state.settings, { 'issuer_id': '6352912639f28166b1b99e43' }).minimob_account : ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Issuer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={10}
                    defaultValue="KAN"
                    disabled="true"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Account Type</ControlLabel>
                  <select
                    className="form-control"
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352958b39f28166b1b99ea4' }) ? _.find(this.state.settings, { 'issuer_id': '6352958b39f28166b1b99ea4' }).account_type : ""}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352958b39f28166b1b99ea4' })) {
                        _.find(settings, { 'issuer_id': '6352958b39f28166b1b99ea4' }).account_type = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352958b39f28166b1b99ea4',
                          'account_type': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }
                    }>
                    <option default value="1">Normal</option>
                    <option value="2">Prepaid</option>
                    <option value="3">External</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Minimob Account</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352958b39f28166b1b99ea4' })) {
                        _.find(settings, { 'issuer_id': '6352958b39f28166b1b99ea4' }).minimob_account = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352958b39f28166b1b99ea4',
                          'minimob_account': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }}
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352958b39f28166b1b99ea4' }) ? _.find(this.state.settings, { 'issuer_id': '6352958b39f28166b1b99ea4' }).minimob_account : ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Issuer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={10}
                    defaultValue="SPINX"
                    disabled="true"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Account Type</ControlLabel>
                  <select
                    className="form-control"
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352959139f28166b1b99ea5' }) ? _.find(this.state.settings, { 'issuer_id': '6352959139f28166b1b99ea5' }).account_type : ""}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352959139f28166b1b99ea5' })) {
                        _.find(settings, { 'issuer_id': '6352959139f28166b1b99ea5' }).account_type = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352959139f28166b1b99ea5',
                          'account_type': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }
                    }>
                    <option default value="1">Normal</option>
                    <option value="2">Prepaid</option>
                    <option value="3">External</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Minimob Account</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '6352959139f28166b1b99ea5' })) {
                        _.find(settings, { 'issuer_id': '6352959139f28166b1b99ea5' }).minimob_account = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '6352959139f28166b1b99ea5',
                          'minimob_account': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }}
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '6352959139f28166b1b99ea5' }) ? _.find(this.state.settings, { 'issuer_id': '6352959139f28166b1b99ea5' }).minimob_account : ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Issuer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={10}
                    defaultValue="ADSPIN"
                    disabled="true"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Account Type</ControlLabel>
                  <select
                    className="form-control"
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '635a5b2f55fc474b24607509' }) ? _.find(this.state.settings, { 'issuer_id': '635a5b2f55fc474b24607509' }).account_type : ""}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '635a5b2f55fc474b24607509' })) {
                        _.find(settings, { 'issuer_id': '635a5b2f55fc474b24607509' }).account_type = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '635a5b2f55fc474b24607509',
                          'account_type': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }
                    }>
                    <option default value="1">Normal</option>
                    <option value="2">Prepaid</option>
                    <option value="3">External</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Minimob Account</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={50}
                    onChange={e => {
                      let settings = JSON.parse(JSON.stringify(this.state.settings));
                      if (_.find(settings, { 'issuer_id': '635a5b2f55fc474b24607509' })) {
                        _.find(settings, { 'issuer_id': '635a5b2f55fc474b24607509' }).minimob_account = e.target.value;
                      }
                      else {
                        settings.push({
                          'issuer_id': '635a5b2f55fc474b24607509',
                          'minimob_account': e.target.value
                        })
                      }
                      this.setState({ settings: settings })
                    }}
                    defaultValue={_.find(this.state.settings, { 'issuer_id': '635a5b2f55fc474b24607509' }) ? _.find(this.state.settings, { 'issuer_id': '635a5b2f55fc474b24607509' }).minimob_account : ""}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                {/*<FormGroup>
                  <ControlLabel>Company Details</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={300}
                    onChange={e =>
                      this.setState({ companyDetails: e.target.value })
                    }
                    defaultValue={this.state.companyDetails}
                  />
                </FormGroup>*/}
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              (!this.state.title || !this.state.address || (!this.state.vatNo && !this.state.regNo))
            }
            bsStyle="primary"
            onClick={this.handleInvoiceRecipient}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleInvoiceRecipient() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/editinvoicerecipient",
      method: "post",
      data: {
        Id: this.state.id,
        Title: this.state.title?this.state.title:null,
        Address: this.state.address?this.state.address:null,
        Address2: this.state.address?this.state.address2:null,
        Currency: this.state.currency?this.state.currency:null,
        Notes: this.state.notes?this.state.notes:null,
        CompanyDetails: this.state.companyDetails?this.state.companyDetails:null,
        Inter_company: this.state.interCompany?this.state.interCompany:null,
        Account_category_id: this.state.accountCategoryId?parseInt(this.state.accountCategoryId):null,
        External_id: this.state.externalId?this.state.externalId:null,
        Postal_code: this.state.postalCode?this.state.postalCode:null,
        City: this.state.city?this.state.city:null,
        Country: this.state.country?this.state.country:null,
        Vat_no: this.state.vatNo?this.state.vatNo:null,
        Reg_no: this.state.regNo?this.state.regNo:null,
        Email: this.state.email ? this.state.email : null,
        Max_credit_amount: this.state.max_credit_amount ? this.state.max_credit_amount : null,
        Settings: this.state.settings ? this.state.settings : null
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
