import React from "react";
import ReactDOMServer from "react-dom/server";
import DataTable from "./DataTable";

const MARKUP_INFO_GREY = (title: string) =>
  ReactDOMServer.renderToStaticMarkup(
    <i title={title} className="fa fa-info-circle fa-1_4x grey"></i>
  );

export function count(data: string, title: string): DataTables.ColumnSettings {
  return {
    title,
    data,
    className: "text-right",
    render: DataTable.StockRenderers.count()
  };
}

export function countries(
  data: string,
  title: string
): DataTables.ColumnSettings {
  return {
    title,
    data,
    className: "truncate-cell",
    render: DataTable.StockRenderers.csvOrdered()
  };
}

export function moderationStatus(data: string, name: string ): DataTables.ColumnSettings {
  return {
    title: MARKUP_INFO_GREY("Status"),
    data,
    name,
    className: "cell-auto-width",
    render: DataTable.StockRenderers.moderationStatus()
  };
}

export function money(data: string, title: string): DataTables.ColumnSettings {
  return {
    title,
    data,
    className: "text-right",
    render: DataTable.StockRenderers.money()
  };
}

export function platform(data: string): DataTables.ColumnSettings {
  return {
    title: MARKUP_INFO_GREY("Platform"),
    data,
    className: "cell-auto-width",
    render: DataTable.StockRenderers.platform()
  };
}

export function recycled(
  data: string,
  title?: string
): DataTables.ColumnSettings {
  return {
    title: title || MARKUP_INFO_GREY("Recycled"),
    data,
    className: "cell-auto-width",
    render: DataTable.StockRenderers.recycled()
  };
}

export function redirects(
  data: string,
  title?: string
): DataTables.ColumnSettings {
  return {
    title: title || MARKUP_INFO_GREY("Redirects"),
    data,
    className: "cell-auto-width",
    render: DataTable.StockRenderers.redirects()
  };
}

export function store(data: string): DataTables.ColumnSettings {
  return {
    title: MARKUP_INFO_GREY("Store"),
    data,
    className: "cell-auto-width",
    render: DataTable.StockRenderers.store()
  };
}

export function text(data: string, title: string): DataTables.ColumnSettings {
  return {
    title,
    data
  };
}

export function textTruncate(
  data: string,
  title: string
): DataTables.ColumnSettings {
  return {
    title,
    data,
    className: "truncate-cell",
    render: DataTable.StockRenderers.textWithTooltip()
  };
}

export function textTruncateWide(
  data: string,
  title: string
): DataTables.ColumnSettings {
  return {
    title,
    data,
    className: "truncate-cell-wide",
    render: DataTable.StockRenderers.textWithTooltip()
  };
}

export function trafficType(data: string): DataTables.ColumnSettings {
  return {
    title: MARKUP_INFO_GREY("Traffic Type"),
    data,
    className: "cell-auto-width",
    render: DataTable.StockRenderers.trafficType()
  };
}

export function toggle(data: string, title: string): DataTables.ColumnSettings {
  return {
    title,
    data,
    render: DataTable.StockRenderers.toggle()
  };
}
