import React from "react";
import { DropdownButton, MenuItem, Sizes } from "react-bootstrap";
import { IDataTable } from "./types";

type ColumnFilterProps = {
  tableRef: React.RefObject<IDataTable>;
  title: string;
  columnName: string;
  values: Array<[string, any]>;
  defaultValue?: string;
  bsClass?: string;
  disabled?: boolean;
  dropup?: boolean;
  pullRight?: boolean;
  bsSize?: Sizes;
  bsStyle?: string | null;
  noCaret?: boolean;
};

const ColumnFilter: React.FC<ColumnFilterProps> = ({
  tableRef,
  title,
  columnName,
  values,
  defaultValue,
  disabled,
  ...rest
}) => {
  const [loading, setLoading] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  const [displayText, setDisplayText] = React.useState<React.ReactNode>(title + ": " + selectedValue);

  React.useEffect(() => {
    const api = tableRef.current?.api;

    api?.on("preXhr", () => setLoading(true));
    api?.on("xhr", () => setLoading(false));

    return () => {
      api?.off("preXhr").off("xhr");
    };
  }, [tableRef]);

  return (
    <DropdownButton
      id={columnName}
      title={displayText}
      onSelect={(eventKey: any) => {
        const [text, value] = eventKey;
        setSelectedValue(value);
        setDisplayText(
          value === defaultValue ? (
            <span>
              {title}:&nbsp;{text}
            </span>
          ) : (
            <span>
              {title}:&nbsp;<strong>{text}</strong>
            </span>
          )
        );
        tableRef.current?.setColumnFilter(`${columnName}:name`, value);
      }}
      disabled={disabled || loading}
      {...rest}
    >
      {values.map((x, i) => {
        const [text, value] = x;
        return (
          <MenuItem key={i} eventKey={x} active={value === selectedValue}>
            {text}
          </MenuItem>
        );
      })}
    </DropdownButton>
  );
};

export default ColumnFilter;
