import React from 'react';
import {
  Input,
} from 'reactstrap';
import TimeField from 'react-simple-timefield';
import classnames from 'classnames';
//import FieldValidationErrors from '../FieldValidationErrors';

class TimeInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastValue: this.props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        lastValue: nextProps.value,
      });
    }
  }

  handleChange = value => {
    this.setState({
      lastValue: value,
    }, () => {
      if (typeof (this.props.onChange) === 'function') {
        this.props.onChange(this.state.lastValue);
      }
    });
  };

  render() {
    let {
      //value,
      placeholder,
      className,
      style,
      size,
    } = this.props;

    return (
      <div
        style={style}
      >
        <TimeField
          value={this.state.lastValue || '00:00'}
          onChange={this.handleChange}
          input={<Input
            className={classnames(className)}
            placeholder={placeholder}
            bsSize={size}
          />}
          colon=":"
          showSeconds={false}
        />
        {/*<FieldValidationErrors
          errors={errors}
        />*/}
      </div>
    );
  }

}

export default TimeInput;
