import React from "react";
import { Row, Col, FormGroup, Checkbox } from "react-bootstrap";
import {
  AdvertiserPicker,
  PublisherPicker,
  ApplicationPicker,
  CampaignPicker
} from "components/pickers/SelectPickers";
import Hint from "components/Hint";
import { HorizontalLine } from "components/admin-lte";

export default function({
  visible,
  onChange,
  groupByAffiliate,
  groupByCampaign,
  includeAdopsInfo,
  includeLastDays
}) {
  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <HorizontalLine />
      <p>
        Use the following settings to apply filters and control the report
        output.
      </p>
      <Row>
        <Col sm={12} lg={4}>
          <FormGroup>
            <label>Advertisers</label>
            <AdvertiserPicker
              className="form-control"
              multiple
              placeholder="Enter ids or names"
              onChange={x => onChange({ advertiserIds: x })}
            />
          </FormGroup>
          <FormGroup>
            <label>Publishers</label>
            <PublisherPicker
              className="form-control"
              multiple
              placeholder="Enter ids or names"
              onChange={x => onChange({ publisherIds: x })}
            />
          </FormGroup>
        </Col>
        <Col sm={12} lg={4}>
          <FormGroup>
            <label>Applications</label>
            <ApplicationPicker
              className="form-control"
              multiple
              placeholder="Enter hex ids"
              onChange={x => onChange({ applicationIds: x })}
            />
          </FormGroup>
          <FormGroup>
            <label>Campaigns</label>
            <CampaignPicker
              className="form-control"
              multiple
              placeholder="Enter hex ids"
              onChange={x => onChange({ campaignIds: x })}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={4}>
          <FormGroup>
            <Row>
              <Col md={12}>
                <label>Group By</label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Checkbox
                  inline
                  defaultChecked={groupByAffiliate}
                  onChange={e =>
                    onChange({ groupByAffiliate: e.target.checked })
                  }
                >
                  Affiliate
                </Checkbox>
                <Checkbox
                  inline
                  defaultChecked={groupByCampaign}
                  onChange={e =>
                    onChange({ groupByCampaign: e.target.checked })
                  }
                >
                  Campaign
                </Checkbox>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={4}>
          <FormGroup>
            <Row>
              <Col md={12}>
                <label>Other</label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Checkbox
                  inline
                  defaultChecked={includeAdopsInfo}
                  onChange={e =>
                    onChange({ includeAdopsInfo: e.target.checked })
                  }
                >
                  Include adops information&nbsp;
                  <Hint id="about-include-adops-information">
                    Includes columns for Office, Team and Account Manager.
                  </Hint>
                </Checkbox>
              </Col>
              <Col md={12}>
                <Checkbox
                  inline
                  defaultChecked={includeLastDays}
                  onChange={e =>
                    onChange({ includeLastDays: e.target.checked })
                  }
                >
                  Include data for the last 3, 10, 20 and 30 days
                </Checkbox>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}
