import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

export default class EditDialog extends React.Component {
  static title = "Edit Offer click rate";

  constructor(props) {
    super(props);
    this.state = {
      offerid: props.offerid||null,
      cap: props.cap||null,
      clickRate: props.clickRate||null,
      error: null,
      busy: false,
      message: null
    };
    this.handleOffers = this.handleOffers.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Edit Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            Please edit the offer click rate.
          </p>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Offer ID</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    disabled={true}
                    defaultValue={this.state.offerid}
                  />
                </FormGroup>
                {/*<FormGroup>
                  <ControlLabel># Impression Cap</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    min={1}
                    onChange={e =>
                      this.setState({ cap: e.target.value })
                    }
                    defaultValue={this.state.cap}
                  />
                </FormGroup>*/}
                <FormGroup>
                  <ControlLabel>1 Click Every # Impressions</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    min={1}
                    onChange={e =>
                      this.setState({ clickRate: e.target.value })
                    }
                    defaultValue={this.state.clickRate}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !this.state.offerid ||
              (!this.state.cap && !this.state.clickRate)
            }
            bsStyle="primary"
            onClick={this.handleOffers}
          >
            Save
              </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleOffers() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/reports/editimpcap",
      method: "post",
      data: {
        offerid: this.state.offerid,
        numcap: this.state.cap?parseInt(this.state.cap):null,
        clickrate: this.state.clickRate?parseInt(this.state.clickRate):null
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
