import React from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import download from "downloadjs";
import moment from "moment-timezone";
import { Box } from "components/admin-lte";
import DateRangePicker from "components/pickers/DateRangePicker";
import buildErrorSummary from "components/ErrorSummary";
import { extractFilenameFromResponse } from "lib/Functions";
import PageLayout from "components/PageLayout";
import DownloadButton from "components/stock/DownloadButton";

export default class AdjustCohorts extends React.Component {
  static title = "Adjust Cohorts";

  constructor(props) {
    super(props);
    this.state = {
      date: null,
      granularity: "day",
      period: "month",
      grouping: {
        adgroups: true,
        creatives: true,
        campaigns: true
      },
      registeredApps: null,
      appEvents: null,
      selectedApp: null,
      selectedEvents: null,
      busy: false,
      error: null
    };
    this.selectApp = this.selectApp.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    fetch("/api/v1/Reports/Custom/AdjustCohorts/RegisteredApps", {
      method: "GET"
    })
      //.then(res => res.json())
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        return res.json();
      })
      .then(json => this.setState({ registeredApps: json }));
  }

  render() {
    const {
      granularity,
      period,
      grouping,
      registeredApps,
      appEvents,
      selectedEvents,
      busy,
      error
    } = this.state;
    const errorSummary = buildErrorSummary(error);

    return (
      <PageLayout breadcrumbs={["Reports"]} title={AdjustCohorts.title}>
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={busy}>
          <Box.Body>
            <Row>
              <Col sm={12} lg={3}>
                <FormGroup>
                  <label>Date</label>
                  <DateRangePicker
                    className="form-control"
                    onChange={value => this.setState({ date: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Granularity</label>
                  <select
                    className="form-control"
                    value={granularity}
                    onChange={e =>
                      this.setState({ granularity: e.target.value })
                    }
                  >
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <label>Period</label>
                  <select
                    className="form-control"
                    value={period}
                    onChange={e => this.setState({ period: e.target.value })}
                  >
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <label>Grouping</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      {Object.keys(grouping).map(key => (
                        <label key={key} className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={grouping[key]}
                            onChange={e => {
                              grouping[key] = e.target.checked;
                              this.setState({ grouping });
                            }}
                          />
                          {key}
                        </label>
                      ))}
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col sm={12} lg={3}>
                <FormGroup>
                  <label>Application</label>
                  <select
                    className="form-control"
                    onChange={e => this.selectApp(e.target.value)}
                  >
                    <option value=""></option>
                    {registeredApps &&
                      registeredApps.map(app => (
                        <option key={app.app_token} value={app.app_token}>
                          {app.name}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={12} lg={3}>
                <FormGroup>
                  <label>Events</label>
                  {selectedEvents && (
                    <span className="pull-right">
                      Selected: {selectedEvents.length}
                    </span>
                  )}
                  <select
                    className="form-control"
                    size={12}
                    multiple={true}
                    onChange={e => {
                      let ids = [];
                      for (
                        let i = 0;
                        i < e.target.selectedOptions.length;
                        i++
                      ) {
                        ids.push(e.target.selectedOptions[i].value);
                      }
                      this.setState({ selectedEvents: ids });
                    }}
                  >
                    {appEvents &&
                      Object.keys(appEvents).map(key => (
                        <option key={key} value={key}>
                          {appEvents[key]}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <DownloadButton onClick={this.handleDownload} />
          </Box.Footer>
        </Box>
      </PageLayout>
    );
  }

  selectApp(appToken) {
    const { registeredApps } = this.state;
    this.setState({ busy: true });
    if (appToken === "") {
      this.setState({
        appEvents: null,
        selectedApp: null,
        selectedEvents: null,
        busy: false
      });
      return;
    }
    const selectedApp = registeredApps.find(x => x.app_token === appToken);
    fetch(
      `/api/v1/Reports/Custom/AdjustCohorts/AppEvents?user_token=${selectedApp.user_token}&app=${selectedApp.app_token}`,
      {
        method: "GET"
      }
    )
      //.then(res => res.json())
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        return res.json();
      })
      .then(json =>
        this.setState({
          appEvents: json,
          selectedApp,
          selectedEvents: [],
          busy: false
        })
      );
  }

  handleDownload() {
    const {
      date,
      granularity,
      period,
      grouping,
      selectedApp,
      selectedEvents
    } = this.state;
    this.setState({ busy: true, error: null });
    let success = false;
    let filename = "";
    fetch("/api/v1/Reports/Custom/AdjustCohorts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user_token: selectedApp ? selectedApp.user_token : null,
        app: selectedApp ? selectedApp.app_token : null,
        start_date: date ? moment(date[0]).format("YYYY-MM-DD") : null,
        end_date: date ? moment(date[1]).format("YYYY-MM-DD") : null,
        kpis: ["cohort_size", "retained_users"],
        event_kpis: selectedEvents
          ? selectedEvents.map(x => `${x}_events`)
          : null,
        grouping: [granularity].concat(
          Object.keys(grouping).filter(key => grouping[key])
        ),
        period,
        Output: "Excel"
      })
    })
      /*.then(res => {
        if (Boolean((success = res.ok))) {
          filename = extractFilenameFromResponse(res);
          return res.blob();
        } else {
          return res.json();
        }
      })*/
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        if (Boolean((success = res.ok))) {
          filename = extractFilenameFromResponse(res);
          return res.blob();
        } else {
          return res.json();
        }
      })
      .then(res => {
        if (success) {
          download(
            res,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        }
        this.setState({
          busy: false,
          error: success ? null : res
        });
      })
      .catch(err => this.setState({ busy: false, error: err }));
  }
}
