import React from "react";
import $ from "jquery";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import columns from "./columns";
import ServerStats from "./ServerStats";
import ExecutionHistoryModal from "./ExecutionHistoryModal";
import { UserContext } from "lib/user";
import "./Styles.css";

export default class Feeders extends React.Component {
  static title = "Feeders";

  tableRef = React.createRef();
  shouldColorizeRow = false;

  constructor(props) {
    super(props);
    this.state = {
      stats: null,
      loading: true,
      selectedSubset: "Active",
      selectedColumnset: "columnset-default",
      showExecutionHistoryFor: null
    };
    this.handleTableLoaded = this.handleTableLoaded.bind(this);
    this.colorizeRow = this.colorizeRow.bind(this);
    this.selectSubset = this.selectSubset.bind(this);
    this.selectColumnset = this.selectColumnset.bind(this);
  }

  componentDidMount() {
    this.selectColumnset(this.state.selectedColumnset);
  }

  render() {
    const { stats, loading, selectedSubset, selectedColumnset } = this.state;
    const tableRef = this.tableRef;
    const serverNames =
      stats === null
        ? []
        : Object.keys(stats)
            .filter(x => x !== "NO SERVER")
            .sort((a, b) => a.localeCompare(b))
            .map(x => [x, x]);

    return (
      <PageLayout title={Feeders.title}>
        <UserContext.Consumer>
          {user => (
          <Box>
            <Box.Header>
              <DataTable.Toolbar tableRef={tableRef} bsSize="small">
                <ToggleButtonGroup
                  type="radio"
                  name="subset"
                  bsSize="small"
                  defaultValue={selectedSubset}
                  onChange={this.selectSubset}
                >
                  <ToggleButton
                    disabled={loading}
                    style={{ minWidth: "60px" }}
                    value="Active"
                  >
                    Active
                  </ToggleButton>
                  <ToggleButton
                    disabled={loading}
                    style={{ minWidth: "60px" }}
                    value="Running"
                  >
                    Running
                  </ToggleButton>
                  <ToggleButton
                    disabled={loading}
                    style={{ minWidth: "60px" }}
                    value="Failed"
                  >
                    Failed
                  </ToggleButton>
                  <ToggleButton
                    disabled={loading}
                    style={{ minWidth: "60px" }}
                    value="All"
                  >
                    All
                  </ToggleButton>
                </ToggleButtonGroup>
                <DataTable.ColumnFilter
                  tableRef={tableRef}
                  columnName="server"
                  title="Server"
                  bsSize="small"
                  values={[["*", ""], ...serverNames]}
                  defaultValue=""
                  disabled={loading}
                />
                <ToggleButtonGroup
                  type="radio"
                  name="columnset"
                  bsSize="small"
                  defaultValue={selectedColumnset}
                  onChange={this.selectColumnset}
                >
                  <ToggleButton disabled={loading} value="columnset-default">
                    <i className="fa fa-dashboard" />
                  </ToggleButton>
                  <ToggleButton disabled={loading} value="columnset-endpoints">
                    <i className="fa fa-link" />
                  </ToggleButton>
                  <ToggleButton disabled={loading} value="columnset-salesmen">
                    <i className="fa fa-user" />
                  </ToggleButton>
                  <ToggleButton disabled={loading} value="columnset-moderation">
                    <i className="fa fa-dollar" />
                  </ToggleButton>
                  <ToggleButton disabled={loading} value="columnset-dev">
                    <i className="fa fa-code" />
                  </ToggleButton>
                  <ToggleButton disabled={loading} value="columnset-advanced">
                    <i className="fa fa-sliders" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </DataTable.Toolbar>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center"
                }}
              >
                {stats && <ServerStats stats={stats} />}
              </div>
            </Box.Header>
            <Box.Body>
              <DataTable
                className="table table-condensed table-striped table-hover"
                ref={tableRef}
                onLoading={() => this.setState({ loading: true })}
                onLoaded={this.handleTableLoaded}
                ajax={{
                  url: `/api/v1/feeders`,
                  dataSrc: ""
                }}
                columns={columns}
                order={[[0, "asc"]]}
                createdRow={this.colorizeRow}
                searchCols={columns.map(c => {
                  if (c.name === "active" && selectedSubset === "Active") {
                    return { search: "true" };
                  }
                  if (c.name === "status" && selectedSubset === "Running") {
                    return { search: "Running" };
                  }
                  if (
                    c.name === "failed" &&
                    (selectedSubset === "Failed" || selectedSubset === "Critical")
                  ) {
                    return { search: "true" };
                  }
                  return null;
                })}
                deferRender={true}
                onUserCommand={({ commandData }) => {
                  if (user.tokenHasExpired) {
                    window.location.replace('/login');
                    return;
                  }
                  else {
                    this.setState({
                      showExecutionHistoryFor: commandData
                    })
                  }
                }}
              />
            </Box.Body>
          </Box>
        )}
        </UserContext.Consumer>
        
        {this.state.showExecutionHistoryFor && (
          <ExecutionHistoryModal
            id={this.state.showExecutionHistoryFor.id}
            name={this.state.showExecutionHistoryFor.name}
            onHide={() => this.setState({ showExecutionHistoryFor: null })}
          />
        )}
      </PageLayout>
    );
  }

  selectSubset(subset) {
    const api = this.tableRef.current.api;
    api.column("active:name").search(subset === "Active" ? "true" : "");
    api.column("status:name").search(subset === "Running" ? "Running" : "");
    api
      .column("failed:name")
      .search(subset === "Failed" || subset === "Critical" ? "true" : "");
    api
      .column("failureRate:name")
      .search(subset === "Critical" ? "^1$" : "", true);
    api.columns().draw();
    this.setState({ selectedSubset: subset });
  }

  selectColumnset(columnset) {
    const api = this.tableRef.current.api;
    api.columns(".columnset").visible(false);
    api.columns("." + columnset).visible(true);
    api.draw();
    this.setState({ selectedColumnset: columnset });
  }

  colorizeRow(row, data, dataIndex, cells) {
    if (this.shouldColorizeRow) {
      if (!data.active) {
        $(row).addClass("bg-warning");
      } else if (data.failed) {
        $(row).addClass("bg-danger");
      }
    }
  }

  handleTableLoaded({ json }) {
    let stats = null;

    if (json) {
      stats = json.reduce((acc, cur) => {
        const key = cur.server || "NO SERVER";
        if (!acc[key]) {
          acc[key] = { active: 0, inactive: 0 };
        }
        if (cur.active) {
          acc[key].active++;
        } else {
          acc[key].inactive++;
        }
        return acc;
      }, {});
    }

    this.setState({ loading: false, stats });
  }
}
