import React from "react";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button
} from "react-bootstrap";
import { PublisherPicker } from "components/pickers/SelectPickers";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

export default class AddDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publisher: "",
      subpublisher: "",
      error: null
    };
    this.userPickerRef = React.createRef();
    this.handleAdd = this.handleAdd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.setState({
        publisher: "",
        subpublisher: "",
        error: null
      });
      this.userPickerRef.current.clearSingle();
    }
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.props.onHide}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Publisher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          <FormGroup>
            <ControlLabel>Publisher</ControlLabel>
            <PublisherPicker
              ref={this.userPickerRef}
              className="form-control"
              placeholder="Enter id or name"
              onChange={value => this.setState({ publisher: value })}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Encoded Subpublisher (optional)</ControlLabel>
            <FormControl
              type="text"
              value={this.state.subpublisher}
              onChange={e => this.setState({ subpublisher: e.target.value })}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleAdd}>
            Add New
          </Button>
          <Button onClick={this.props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleAdd() {
    axios({
      url: "/api/v1/tools/globallyblockedpubs/add",
      method: "post",
      data: {
        rules: [
          {
            publisherId: this.state.publisher,
            encodedSubpublisherId: this.state.subpublisher
          }
        ]
      }
    })
      .then(() => {
        this.props.onAdded();
        this.props.onHide();
      })
      .catch(error => this.setState({ error: error.response.data }));
  }
}
