import React from "react";
import moderation_available from "assets/moderation/moderation_available.png";
import moderation_rejected from "assets/moderation/moderation_rejected.png";
import moderation_pending from "assets/moderation/moderation_pending.png";

export default function(props) {
  if (props.value === 0) {
    return (
      <img
        src={moderation_pending}
        title="Pending Moderation"
        alt="Pending Moderation"
      />
    );
  } else if (props.value === 1) {
    return <img src={moderation_available} title="Available" alt="Available" />;
  } else if (props.value === 2) {
    return <img src={moderation_rejected} title="Rejected" alt="Rejected" />;
  }
}
