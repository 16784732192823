import React from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import { Box } from "components/admin-lte";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import download from "downloadjs";
import { extractFilenameFromResponse } from "lib/Functions";
import moment from "moment-timezone";
import DateRangePicker from "components/pickers/DateRangePicker";
import PageLayout from "components/PageLayout";
import DownloadButton from "components/stock/DownloadButton";

moment.tz.setDefault("UTC");

export default class DiscontinuedOffers extends React.Component {
  static title = "Discontinued Offers";

  constructor(props) {
    super(props);
    this.state = {
      date: null,
      busy: false,
      error: null
    };
    this.handleDownload = this.handleDownload.bind(this);
  }

  render() {
    const { date, busy, error } = this.state;
    const errorSummary = buildErrorSummary(error);

    const startOfLastMonth = moment()
      .subtract(1, "months")
      .startOf("month");
    const ranges = {
      Yesterday: [
        moment()
          .subtract(1, "days")
          .startOf("day"),
        moment()
          .subtract(1, "days")
          .endOf("day")
      ],
      "Last week": [
        moment()
          .subtract(1, "weeks")
          .startOf("isoWeek"),
        moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      ],
      "Last month": [
        startOfLastMonth,
        moment()
          .subtract(1, "months")
          .endOf("month")
      ]
    };

    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={DiscontinuedOffers.title}
        description={
          <>
            <p>
              List of offers that have received at least one conversion during a
              time period and are currently unavailable for one of the following
              reasons:
            </p>
            <ul>
              <li>They've been stopped/paused by their advertiser</li>
              <li>They've been rejected by an Account Manager</li>
              <li>They are in Pending state</li>
            </ul>
          </>
        }
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={busy}>
          <Box.Body>
            <Row>
              <Col sm={12} lg={3}>
                <FormGroup>
                  <label>Time period</label>
                  <DateRangePicker
                    className="form-control"
                    ranges={ranges}
                    onChange={value => this.setState({ date: value })}
                    minDate={moment("2018-10-01")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <DownloadButton onClick={this.handleDownload} disabled={!date} />
          </Box.Footer>
        </Box>
      </PageLayout>
    );
  }

  handleDownload() {
    const { date } = this.state;
    this.setState({ busy: true, error: null });
    axios({
      url: "/api/v1/reports/discontinuedoffers",
      method: "post",
      data: {
        fromDateInclusiveUtc: moment(date[0]).format("YYYY-MM-DD"),
        toDateExclusiveUtc: moment(date[1])
          .add(1, "second")
          .format("YYYY-MM-DD")
      },
      responseType: "blob"
    })
      .then(res => {
        this.setState({ busy: false, error: null });
        const filename = extractFilenameFromResponse(res);
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }
}
