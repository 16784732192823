import React from "react";
import {Button, ButtonGroup, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import { Box } from "components/admin-lte";
import DataTable from "components/DataTable";
import $ from "jquery";
import { UserContext } from "lib/user";
import axios from "axios";
import { CampaignPicker } from "components/pickers/SelectPickers";
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import ChangeOrderDialog from "./ChangeOrderDialog";

export default class EditDialog extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.loadOffers = this.loadOffers.bind(this);
    this.colorizeRow = this.colorizeRow.bind(this);
    this.handleAddOffersClick = this.handleAddOffersClick.bind(this);
    this.handleRemoveOffersClick = this.handleRemoveOffersClick.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setSelectedOffers = this.setSelectedOffers.bind(this);
    this.handleRowReorder = this.handleRowReorder.bind(this);
    this.onHide = this.onHide.bind(this);
    this.tableRef = React.createRef();
  }

  getDefaultState() {
    return {
      isNew: this.props.isNew,
      id: this.props.id,
      groupName: this.props.groupName,
      offers: this.props.offers||[],
      showAddOffers: this.props.isNew?true:false,
      changeOrderFor: null,
      selectedOfferIds: [],
      lastClickedRow: null,
      error: null,
      busy: false
    };
  }

  componentDidMount() {
    this.loadOffers();
  }
  
  render() {
    var title = this.state.isNew?"Add new offer group":("Offers in group: " +this.props.groupName +(this.props.selectedPublisherName?" [ Publisher: "+this.props.selectedPublisherName+" ]":""));
    var changeOrderFor = this.state.changeOrderFor;
    return (
      
      <Modal bsSize="lg" show={true} className={"modal-xl-container"} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title} </Modal.Title>
        </Modal.Header>
        <UserContext.Consumer>
          {user => (
          <Modal.Body>
            
            {!this.state.isNew && this.props.selectedPublisherName &&(
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Publisher</ControlLabel>
                    <div>{this.props.selectedPublisherName}</div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Group name</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    max={50}
                    defaultValue={this.state.groupName||null}
                    onChange={e =>
                      this.setState({groupName:e.target.value})
                    }
                    />

                </FormGroup>
              </Col>
              </Row>
            {!this.state.isNew &&(
              <Row>
                <Col sm={12} lg={6}>
                  <ButtonGroup bsSize="small">
                    <Button
                      bsStyle="primary"
                      onClick={this.handleAddOffersClick.bind(this,user)}
                    >
                      <i className="fa fa-plus"></i> Add Offers to Group
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            )}
            {this.state.showAddOffers &&(
              <Row>
                  <Col sm={12} lg={6}>
                    <FormGroup style={{ paddingTop: '20px' }}>
                      <ControlLabel>Enter OfferIds seperated by comma</ControlLabel>
                      <FormControl
                        componentClass="input"
                        type="string"
                        max={3000}
                        onChange={e => {
                          var stateOffers_ = [];
                          var stateOffers = [];
                          stateOffers_ = e.target.value.split(',')
                          stateOffers_.forEach(stateOffer_ => {
                            stateOffers.push({ 'offerId': stateOffer_})
                          });

                          this.tableRef.current.api.clear();
                          this.tableRef.current.api.rows.add(stateOffers); // Add new data
                          this.tableRef.current.api.draw(); // Redraw the DataTable
                          //this.tableRef.current.api.columns.adjust().draw(); 
                          this.setState({ offers: stateOffers });                          
                        }}
                      />

                      <ControlLabel>Offers</ControlLabel>
                    <CampaignPicker
                      url={"/api/v1/lookup/OfferExtended"}
                      className="form-control"
                      multiple
                      placeholder="Enter hex ids"
                      onChange={(offerIds,offers) => {
                        
                        var addedOffers = offers.map(x => ({ 
                          offerId: x.id, 
                          name: x.text,
                          payoutModel: x.payoutModel,
                          payout: x.payout,
                          targetedCountries: x.targetedCountries,
                          advertiserName: x.advertiserName,
                          isNew: true 
                        }));
                        var stateOffers_ = this.state.offers;
                        var addedOffers_ = [];
                        
                        var stateOffers = stateOffers_.filter(value => {
                          return(!value.isNew)
                        });
                        
                        if(addedOffers.length>0){
                          addedOffers.forEach(addedOffer => {
                            var found = false;
                            stateOffers.forEach(stateOffer => {
                              if(stateOffer.offerId === addedOffer.offerId){
                                found = true;
                              }
                            });
                            if(!found){
                              addedOffers_.push(addedOffer);
                              stateOffers.push(addedOffer);
                            }
                          });
                        }                       
                        
                        this.tableRef.current.api.clear();
                        this.tableRef.current.api.rows.add(stateOffers); // Add new data
                        this.tableRef.current.api.draw(); // Redraw the DataTable
                        //this.tableRef.current.api.columns.adjust().draw(); 
                        this.setState({ offers: stateOffers });
                      }}
                      onProcessResults={(data)=>{
                        return data.map(x => ({ 
                          id: x.id, 
                          text: x.name, 
                          payoutModel: x.isCPIOnly?"CPI":"PIE", 
                          payout: x.payout, 
                          targetedCountries: x.targetedCountries,
                          advertiserName: x.advertiserName,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <br/>
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" noReloadButton={true} />
              </Box.Header>
              
              <Box.Body>
                <DataTable
                  ref={this.tableRef}
                  className="table table-condensed table-striped table-hover offer-group-offers-table"
                  showCheckBoxes={true}
                  onSelect={this.setSelectedOffers}
                  data={this.state.offers||[]}
                  //deferRender={true}
                  createdRow={ this.colorizeRow }
                  order={[]}
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    if(commandData.action === "rowReorder"){
                      this.handleRowReorder(commandData.row, commandData.offerId, commandData.direction);
                    }else if(commandData.action === "rowReorderCustom"){
                      this.setState({changeOrderFor:commandData});
                    }
                  }}
                  columns={[
                    {
                      title: "Pos.",
                      name: "rowNumber",
                      width: "30px",
                      orderable: false,
                      defaultContent: "",
                      render:(d,t,r,m)=>{
                        if(t==='display'){
                          return m.row+1;
                        }
                      }
                    },
                    DataTable.createUserCommandColumn({
                      title: `<span class="change-position-column">Change Offer Pos.</span>`,
                      data: "",
                      name: "rowReorderCustom",
                      width: "30px",
                      className: "move-custom-column",
                      orderable: false,
                      defaultContent: ReactDOMServer.renderToStaticMarkup(
                        <button type='button' className='btn btn-default btn-small move-custom-btn' title="Click to change the offer position">
                          <i className="fa fa-pencil fa-1_4x"></i>
                        </button>
                      ),
                      commandName: "",
                      commandData: ({ rowData, row }) => ({
                        action: "rowReorderCustom",
                        offerId: rowData.offerId,
                        offerName: rowData.name,
                        position: row
                      }),
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "",
                      name: "rowReorderTop",
                      width: "30px",
                      className: "move-up-column",
                      orderable: false,
                      defaultContent: ReactDOMServer.renderToStaticMarkup(
                        <button type='button' className='btn btn-default btn-small move-up-btn' title='Move this offer to the top'>
                          <i className="fa fa-angle-double-up fa-1_8x"></i>
                        </button>
                      ),
                      commandName: "",
                      commandData: ({ rowData, row }) => ({
                        action: "rowReorder",
                        offerId: rowData.offerId,
                        row: row,
                        direction: 2,
                      }),
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "",
                      name: "rowReorderUp",
                      width: "30px",
                      className: "move-up-column",
                      orderable: false,
                      defaultContent: ReactDOMServer.renderToStaticMarkup(
                          <button type='button' className='btn btn-default btn-small move-up-btn' title='Move this offer up'>
                            <i className="fa fa-arrow-up fa-1_4x"></i>
                          </button>
                      ),
                      commandName: "",
                      commandData: ({ rowData, row }) => ({
                        action: "rowReorder",
                        offerId: rowData.offerId,
                        row: row,
                        direction: 1,
                      }),
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "",
                      name: "rowReorderDown",
                      width: "30px",
                      className: "move-down-column",
                      orderable: false,
                      defaultContent: ReactDOMServer.renderToStaticMarkup(
                        <button type='button' className='btn btn-default btn-small move-down-btn' title='Move this offer down'>
                          <i className="fa fa-arrow-down fa-1_4x"></i>
                        </button>
                      ),
                      commandName: "",
                      commandData: ({ rowData, row }) => ({
                        action: "rowReorder",
                        offerId: rowData.offerId,
                        row: row,
                        direction: -1,
                      })
                    }),
                    
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "",
                      name: "rowReorderBottom",
                      width: "30px",
                      className: "move-down-column",
                      orderable: false,
                      defaultContent: ReactDOMServer.renderToStaticMarkup(
                        <button type='button' className='btn btn-default btn-small move-down-btn' title='Move this offer to bottom'>
                          <i className="fa fa-angle-double-down fa-1_8x"></i>
                        </button>
                      ),
                      commandName: "",
                      commandData: ({ rowData, row }) => ({
                        action: "rowReorder",
                        offerId: rowData.offerId,
                        row: row,
                        direction: 3,
                      }),
                    }),
                    {
                      title: "Offer ID",
                      data: "offerId",
                      name: "offerId",
                      width: "80px",
                      orderable: false,
                      defaultContent: ""                      
                    },
                    {
                      title: "Offer Name",
                      data: "name",
                      name: "name",
                      className: "truncate-cell-wide",
                      orderable: false,
                      defaultContent: "",                      
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: "Model",
                      data: "payoutModel",
                      name: "payoutModel",
                      width: "50px",
                      orderable: false,
                      defaultContent: "",
                    },
                    {
                      title: "Payout",
                      data: "payout",
                      name: "payout",
                      className: "text-right",
                      width: "50px",
                      orderable: false,
                      defaultContent: "",
                      render: DataTable.StockRenderers.money()
                    },
                    {
                      title: "Countries",
                      data: "targetedCountries",
                      name: "targetedCountries",
                      className: "truncate-cell",
                      width: "50px",
                      orderable: false,
                      defaultContent: "",
                      render: DataTable.StockRenderers.textWithTooltip()
                    },
                    {
                      title: "Advertiser",
                      data: "advertiserName",
                      name: "advertiserName",
                      className: "truncate-cell-medium",
                      width: "200px",
                      orderable: false,
                      defaultContent: "",
                      render: DataTable.StockRenderers.textWithTooltip()
                    }
                  ]}
                  //ordering={false}
                />
                {!this.state.isNew && this.props.selectedPublisherId!==null && (
                  <Row>
                    <Col sm={12} lg={6}>
                      <div id="indicator-table-wrapper">
                        <table id="indicator-table">
                          <tbody>
                          <tr>
                            <td className="indicator-color">
                              <div className="indicator-color-div row-bg-danger"></div>
                            </td>
                            <td className="indicator-text small-font">Selected publisher is not included in offer</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Box.Body>
            </Box>
            <Row>
              <Col sm={12} lg={6}>
                <ButtonGroup bsSize="small">
                  <Button
                    bsStyle="primary"
                    disabled={this.state.selectedOfferIds.length>0?false:true}
                    onClick={this.handleRemoveOffersClick.bind(this,user)}
                  >
                    <i className="fa fa-minus"></i> Remove selected Offers from Group
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>

            {changeOrderFor && (
              <ChangeOrderDialog
                id={changeOrderFor.id||null}
                offerId={changeOrderFor.offerId}
                offerName={changeOrderFor.offerName}
                position={changeOrderFor.position}
                offers={this.state.offers}
                title="Change offer position"
                show={true}
                onHide={(offers) => {
                  this.setState({ changeOrderFor: null, offers: offers }, ()=>{
                    this.tableRef.current.api.clear();
                    this.tableRef.current.api.rows.add(offers);
                    this.tableRef.current.api.draw(false);
                  });
                }}
              />
            )}
          </Modal.Body>
          )}
        </UserContext.Consumer>
        <Modal.Footer>
          {this.state.isNew && (
            <Button
              bsStyle="primary"
              disabled={!this.state.groupName||this.state.groupName.length<1}
              onClick={this.handleAdd}
            >
              Add
            </Button>
          )}
          {!this.state.isNew && (
          <Button
            bsStyle="primary"
            disabled={!this.state.groupName||this.state.groupName.length<1}
            onClick={this.handleSave}
          >
            Save
          </Button>
          )}
          <Button onClick={this.props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  loadOffers(){
    var offers = this.state.offers||[];
    
    this.setState({
      busy: true,
      message: null,
      error: null
    });
    
    axios({
      url: "/api/v1/tools/OfferGroupOffers",
      method: "post",
      data: offers
    })
      .then(res => {
        this.setState({
          offers: res.data.items||[],
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
  
  setSelectedOffers(selectedOffers){
    var selectedOfferIds = selectedOffers.map(x => (x.offerId));
    this.setState({selectedOfferIds:selectedOfferIds});
  }
  
  handleRowReorder(row,offerId,direction){
    var data = this.state.offers||[];
    var dataLength = data.length;
    var index = null;
    var dataToMove = null;
    var showCustomOrder = false;
    
    if(data.length>0){
      data.forEach((d, idx) => {
        if(d.offerId===offerId){
          index = idx;
          dataToMove = d;
        }
      });

      if(index!==null){
        if(direction===1)
        { //direction=up
          if(index-1>=0){
            data.splice(index, 1);
            data.splice(index-1, 0, dataToMove);
          }
        }
        else if(direction===2){ //move to top
          data.splice(index, 1);
          data.splice(0, 0, dataToMove);
        }
        else if(direction===3){ //move to bottom
          data.splice(index, 1);
          data.push(dataToMove);
        }
        else if(direction===4){ //custom
          showCustomOrder = true;
          data.push(dataToMove);
        }
        else
        {
          if(index+1<=dataLength-1){
            data.splice(index, 1);
            data.splice(index+1, 0, dataToMove);
          }              
        }
        
        this.setState({offers:data, showCustomOrder:showCustomOrder}, ()=>{
          this.tableRef.current.api.clear();
          this.tableRef.current.api.rows.add(data);
          this.tableRef.current.api.draw(false);
        });
      }
    }    
  }
  
  handleAdd() {
    const name = this.state.groupName !== null && this.state.groupName.length > 0 ? this.state.groupName : '';
    const ids = (this.state.offers||[]).map(x => x.offerId);
    this.props.onAdd(name, ids);
  }
  
  handleSave(){
    //TODO: validation
    
    var offers = this.state.offers;
    var offerids = offers.map(x=>x.offerId);
    var postData = {
      Id: this.state.isNew?null:this.state.id,
      Name: this.state.groupName,
      Offers: offerids
    }
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/editoffergroup",
      method: "post",
      data: postData
    })
      .then(res => {
        this.setState({
          offers: res.data.items||[],
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
  
  onHide(){

  }
  
  handleAddOffersClick(){
    this.setState({showAddOffers:true});
  }

  handleRemoveOffersClick(){
    var selectedOfferIds = this.state.selectedOfferIds||[];
    var stateOffers = this.state.offers||[];
    
    if(selectedOfferIds.length>0){
      stateOffers.forEach((offer,idx)=>{
        selectedOfferIds.forEach((selectedOfferId)=>{
          if(offer.offerId===selectedOfferId){
            stateOffers.splice(idx,1);
          }
        });
      });
    }
    
    this.tableRef.current.api.clear();
    this.tableRef.current.api.rows.add(stateOffers); // Add new data
    this.tableRef.current.api.draw(); // Redraw the DataTable
    //this.tableRef.current.api.columns.adjust().draw();
    this.setState({ offers: stateOffers, selectedOfferIds:[] });
  }

  colorizeRow(row, data, dataIndex) {

    var selectedPublisherId = this?.props?.selectedPublisherId||null;
    var found = false;

    if (selectedPublisherId) {
      if (data.publishers) {
        found = _.includes((data.publishers||[]), selectedPublisherId);
        if(!found){
          $(row).addClass( 'row-bg-danger' );
        }
      }
    }
    var dataLength = this?.state?.offers.length;
    if(dataIndex===0){
      $(row).addClass( 'row-first' );
      $(row).find('.move-up-btn').addClass( 'disabled' );
    }
    if(dataIndex===dataLength-1){
      $(row).addClass( 'row-last' );
      $(row).find('.move-down-btn').addClass( 'disabled' );
    }
  }
}
