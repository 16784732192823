import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  Checkbox,
  Radio,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import * as XLSX from 'xlsx/xlsx.mjs';
import UPLOAD from "assets/upload.gif";

export default class AddDialog extends React.Component {
  static title = "Upload Blacklist";

  constructor(props) {
    super(props);
    let filteredDSPs = null;
    if (props.listData && props.listData.items && props.listData.items.length > 0) {
      filteredDSPs = [];
      props.listData.items.map(listDataItem => {
        listDataItem.perDspPartner.map(listDataItemPerDspPartnerItem => {
          if (!filteredDSPs.includes(listDataItemPerDspPartnerItem.name)){
            filteredDSPs.push(listDataItemPerDspPartnerItem.name)
          }
        })
      })

    }
    this.state = {
      allssps: true,
      ssp: null,
      alldsps: true,
      dsp: null,
      filteredDSPs: filteredDSPs,
      file: null,
      expireDays: 60,
      removeExisting: false,
      error: null,
      busy: false,
      message: null
    };
    this.handleUploadBlacklist = this.handleUploadBlacklist.bind(this);
    

  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Upload Blacklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>Please fill out the blacklist details.</p>
          <Box.Body>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Radio checked={(this.state.allssps ? true : false)} onChange={e => {
                    if (e.target.checked) {
                      this.setState({ allssps: true })
                      let filteredDSPs = null;
                      if (this.props.listData && this.props.listData.items && this.props.listData.items.length > 0) {
                        filteredDSPs = [];
                        this.props.listData.items.map(listDataItem => {
                          listDataItem.perDspPartner.map(listDataItemPerDspPartnerItem => {
                            if (!filteredDSPs.includes(listDataItemPerDspPartnerItem.name)) {
                              filteredDSPs.push(listDataItemPerDspPartnerItem.name)
                            }
                          })
                        })
                        this.setState({ filteredDSPs: filteredDSPs })
                      }
                    }
                  }
                  }>All SSPs</Radio>
                  <Radio checked={(this.state.allssps ? false : true)} onChange={e =>{
                      if (e.target.checked){
                        this.setState({ allssps: false })
                        if (this.props.listData && this.props.listData.items && this.props.listData.items.length > 0) {
                          let filteredDSPs = null;
                          this.props.listData.items.map(listDataItem => {
                            if (this.state.ssp === listDataItem.partner) {
                              filteredDSPs = [];
                              listDataItem.perDspPartner.map(listDataItemPerDspPartnerItem => {
                                if (!filteredDSPs.includes(listDataItemPerDspPartnerItem.name)) {
                                  filteredDSPs.push(listDataItemPerDspPartnerItem.name)
                                }
                              })
                            }                            
                          })
                          this.setState({ filteredDSPs: filteredDSPs })
                        }
                      }
                    }                    
                  }>SSP</Radio>
                  <div style={{ 'display': (this.state.allssps ? 'none' : 'block') }}>
                    <FormControl componentClass="select" placeholder="select" onChange={e => {
                      this.setState({ ssp: e.target.value.trim() })
                      let filteredDSPs = [];
                      this.props.listData.items.map(listDataItem => {
                        if (listDataItem.partner === e.target.value.trim()) {
                          listDataItem.perDspPartner.map(dspItem => {
                            filteredDSPs.push(dspItem.name)
                          })
                        }
                      })
                      this.setState({ filteredDSPs: filteredDSPs })
                      }
                    }>
                      <option value={null}>Select SSP</option>
                      {this.props.listData.items.map(listDataItem => (
                        <option value={listDataItem.partner}>{listDataItem.partner}</option>
                      ))}
                    </FormControl>

                    </div>
                </FormGroup>
                <FormGroup>
                  <Radio checked={(this.state.alldsps ? true : false)} onChange={e => {
                    if (e.target.checked) {
                      this.setState({ alldsps: true })
                    }
                  }
                  }>All DSPs</Radio>
                  <Radio checked={(this.state.alldsps ? false : true)} onChange={e => {
                    if (e.target.checked) {
                      this.setState({ alldsps: false })
                    }
                  }
                  }>DSP</Radio>
                  <div style={{ 'display': (this.state.alldsps ? 'none' : 'block') }}>
                    <FormControl componentClass="select" placeholder="select" onChange={e =>
                      this.setState({ dsp: e.target.value.trim() })
                    }>
                      <option value={null}>Select DSP</option>
                      {this.state.filteredDSPs?.map(filteredDSPsItem => (
                        <option value={filteredDSPsItem}>{filteredDSPsItem}</option>                       
                      ))}
                    </FormControl>
                    </div>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Days to expire</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="string"
                    maxLength={100}
                    onChange={e =>
                      this.setState({ expireDays: e.target.value.trim() })
                    }
                    //defaultValue={this.state.expireDays}
                    value={this.state.expireDays}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Excel file</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="file"
                    maxLength={200}
                    onChange={e =>
                      this.setState({ file: e.target.files[0] })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Checkbox onChange={e =>
                    this.setState({ removeExisting: e.target.checked })
                  }>Remove existing entries</Checkbox>
                </FormGroup>
              </Col>  
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              (!this.state.file || !this.state.expireDays)
            }
            bsStyle="primary"
            onClick={this.handleUploadBlacklist}
          >
            Save
          </Button><img src={UPLOAD} hidden={this.state.message === 'File Uploading. Please Wait!' ? false : true}/>
        </Modal.Footer>
      </Modal>
    );
  }

  handleUploadBlacklist() {
    this.setState({
      message: 'File Uploading. Please Wait!',
      file: null,
      busy: true,
      error: null
    });

    const file = this.state.file;
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      axios({
        url: "/api/v1/tools/updateRTBBlacklist",
        method: "post",
        data: {
          removeExisting: this.state.removeExisting,
          fileName: file.name,
          blocked_dsp: this.state.alldsps ? null : this.state.dsp,
          blocked_ssp: this.state.allssps? null : this.state.ssp,
          expireDays: parseInt(this.state.expireDays),
          Items: sheetData
        }
      })
        .then(res => {
          this.setState({
            busy: false,
            message: "The operation completed successfully.",
            error: null
          });
          this.props.onHide();
        })
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;
          this.setState({
            busy: false,
            message: null,
            error
          });
        });

    };
    reader.readAsBinaryString(file);

    
  }
}
