import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Login from "./Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import * as serviceWorker from "./serviceWorker";
import SkinContext from "components/skin";
import { TextEncoder } from "text-encoding";
import axios from 'axios';
import { handleStatus } from "lib/Functions";

import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "ionicons/dist/css/ionicons.css";
import "admin-lte/dist/css/AdminLTE.css";
import "admin-lte/dist/css/skins/_all-skins.css";
import "./styles.css";
const $ = require("jquery/dist/jquery");
(window as any).jQuery = (window as any).$ = $;
require("admin-lte/dist/js/adminlte");

if (typeof window.TextEncoder === "undefined") {
  // This is a special case only for the "pre-Chromium" Edge browser,
  // where a TextEncoder polyfill is required to support the @waiting/base64
  // library used by the Login component.
  // See the "Browser requirement" section at https://github.com/waitingsong/base64 and
  // the Note on the top at https://developer.mozilla.org/en-US/docs/Web/API/TextEncoder.
  console.log("Setting up TextEncoder polyfill");
  window.TextEncoder = TextEncoder;
}

axios.defaults.validateStatus = function (status) {
  return handleStatus(status);
};

ReactDOM.render(
  <Router>
    <SkinContext.Provider value={localStorage.getItem("skin") || "skin-blue"}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute loginPath="/login">
          <App />
        </PrivateRoute>
      </Switch>
    </SkinContext.Provider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
