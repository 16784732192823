import $ from "jquery"
import moment from "moment"
import "bootstrap-daterangepicker"
import React from "react"

import "bootstrap-daterangepicker/daterangepicker.css"

const DATE_FORMAT = "DD/MM/YYYY HH:mm"

export default class extends React.Component {
    render() {
        return (
            <div>
                <input className={this.props.className} ref="input" />
            </div>
        )
    }

    componentDidMount() {
        const input = $(this.refs.input)
        const options = Object.assign({
            ranges: {
                "Today": [
                    moment().startOf("day"),
                    moment().endOf("day")
                ],
                "Yesterday": [
                    moment().subtract(1, "days").startOf("day"),
                    moment().subtract(1, "days").endOf("day")
                ],
                "This week so far": [
                    moment().startOf("isoWeek"),
                    moment().endOf("day")
                ],
                "Last week": [
                    moment().subtract(1, "weeks").startOf("isoWeek"),
                    moment().subtract(1, "weeks").endOf("isoWeek")
                ],
                "This month so far": [
                    moment().startOf("month"),
                    moment().endOf("day")
                ],
                "Last month": [
                    moment().subtract(1, "months").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                ]
            },
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            locale: {
                format: DATE_FORMAT,
                firstDay: 1,
                cancelLabel: "Clear"
            },
            minDate: moment().subtract(64, "days"),
            timePicker: true,
            timePicker24Hour: true
        }, this.props)

        input.daterangepicker(options)
        
        input.on("apply.daterangepicker", (ev, picker) => {
            const d1 = picker.startDate
            const d2 = picker.endDate
            const f = x => x.format(DATE_FORMAT)
            input.val(d1.isSame(d2, "minute") ? f(d1) : f(d1) + " - " + f(d2))
            this.props.onChange([d1, d2])
        })
        input.on("cancel.daterangepicker", (ev, picker) => {
            input.val(null)
            this.props.onChange(null)
        })
    }
}