import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import {ButtonGroup, Button} from "react-bootstrap";
import AddDialog from "./AddDialog";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import { UserContext } from "lib/user";

export default class InvoiceIssuers extends React.Component {
  static title = "Invoice Issuers";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {     
      showAdd: false,
      showEdit: false,
      showDelete: false,
      busy: false,
      error: null,
      showEditDataFor: null,
      showDeleteDataFor: null
    };
  }
  
  render() {
    const { showEditDataFor } = this.state;
    const { showDeleteDataFor } = this.state;
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={InvoiceIssuers.title}
          description={
          <p>
          </p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                  <ButtonGroup bsSize="small">
                    <Button
                      bsStyle="primary"
                      onClick={this.handleAddClick.bind(this,user)}>
                      <i className="fa fa-plus"></i> Add Invoice Issuer
                    </Button>
                  </ButtonGroup>
                </DataTable.Toolbar>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped"
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/tools/invoiceissuers",
                    dataSrc: "items"
                  }}
                  onUserCommand={({ commandData }) => {
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    } else {
                      if (commandData) {
                        if (commandData.action === 'delete') {
                          this.setState({showDeleteDataFor: commandData});
                        }
                        if (commandData.action === 'edit') {
                          this.setState({showEditDataFor: commandData});
                        }
                        if (commandData.action === 'disable')
                          this.setState({showDisableDataFor: commandData});
                        }
                      }
                    }
                  }
                  columns={[
                    {
                      title: "ID",
                      data: "_id",
                      visible: false
                    },
                    {
                      title: "Title",
                      data: "title"
                    },
                    {
                      title: "Address",
                      data: "address"
                    },
                    {
                      title: "Address 2",
                      data: "address2"
                    },
                    {
                      title: "Vat no",
                      data: "vat_no"
                    },
                    {
                      title: "Reg no",
                      data: "reg_no"
                    },
                   /* {
                      title: "Company Details",
                      data: "companyDetails"
                    },*/
                    {
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "20px",
                      render: (d, t, r, m) => ( "<div></div>")
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        id: rowData._id,
                        title: rowData.title,
                        address: rowData.address,           
                        address2: rowData.address2,           
                        vatNo: rowData.vat_no,         
                        regNo: rowData.reg_no,       
                        companyDetails: rowData.companyDetails
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Edit </button></div>")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Actions",
                      data: null,
                      className: "text-left",
                      orderable: false,
                      width: "30px",
                      defultContent: "",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'delete',
                        id: rowData._id,
                        title: rowData.title
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Delete </button></div>")
                    })
                  ]}
                  order={[[1, "asc"]]}
                />
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {this.state.showAdd && (
          <AddDialog   
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showAdd: false 
              })
            }}
          />
        )}

        {showEditDataFor && (
          <EditDialog
            id={showEditDataFor.id}
            title={showEditDataFor.title}
            address={showEditDataFor.address}
            address2={showEditDataFor.address2}
            vatNo={showEditDataFor.vatNo}
            regNo={showEditDataFor.regNo}
            companyDetails={showEditDataFor.companyDetails}
            onHide={() => {
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showEdit: false, 
                  showEditDataFor: null
                })
              );
            }}
          />
        )}

        {showDeleteDataFor && (
          <DeleteDialog
            id={showDeleteDataFor.id}
            title={showDeleteDataFor.title}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showDelete: false, 
                showDeleteDataFor: null 
              })
            }}
          />
        )}

        {/*{showDisableDataFor && (
          <DisableDialog
            id={showDisableDataFor.id}
            title={showDisableDataFor.title}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showDisable: false,
                showDisableDataFor: null
              })
            }}
          />
        )}*/}
      </>
    );
  }
  
  handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({showAdd: true});
    }
  }
}
