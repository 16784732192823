import React from "react";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { UserContext } from "lib/user";
import AddViewCommentsDialog from "./AddViewCommentsDialog";
import 'react-data-grid/lib/styles.css';

import ICON_MODERATION_PENDING from "assets/moderation/moderation_pending.png";
import ICON_MODERATION_AVAILABLE from "assets/moderation/moderation_available.png";
import COMMENTS from "assets/commentsicon.png";

import { AgGridReact } from '@ag-grid-community/react'
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-quartz.css';

import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MasterDetailModule,
  MenuModule,
  ColumnsToolPanelModule,
  ClipboardModule,
  ExcelExportModule,
  SetFilterModule,
  RangeSelectionModule
]);

function renderConversionPoint(params) {
  const eventTypes = {
    '0': 'CPI',
    '1': 'Registration',
    '2': 'UpdateApp',
    '3': 'Search',
    '4': 'ViewContent',
    '5': 'ClickItem',
    '6': 'Login',
    '7': 'AddedPaymentInfo',
    '8': 'PurchaseSpendCredit',
    '9': 'Retention',
    '10': 'LevelReached',
    '11': 'LevelCompleted',
    '12': 'TutorialCompletion',
    '13': 'AchievementUnlocked',
    '14': 'RewardGameGoods',
    '15': 'OtherGameAction',
    '16': 'BookingReservation',
    '17': 'AddToCart',
    '18': 'AddToWishlist',
    '19': 'CheckoutInitiated',
    '20': 'MessageSentReceived',
    '21': 'PostComment',
    '22': 'ReviewRatingLike',
    '23': 'FollowShareInvite',
    '1000': 'Other'
  };
  let d = params.data.biddingModels;

  if (d !== null && d.length >= 1) {
    let ret = ''
    d.forEach((dItem, i) => {
      if (dItem.advertiserBidPrice && dItem.advertiserBidPrice > 0) {
        ret = ret + eventTypes[dItem.type.toString()] + ": " + (dItem.advertiserBidPrice ? dItem.advertiserBidPrice : '') + ', '
        if (i === d.length - 1) {
          ret = ret.substring(0, ret.length - 2)
        }
      }
    });
    return ret;
  }
  else
    return '';
}

function renderCommentStatus(params) {
  const commentStatus = {
    0: 'Pending Reply',
    1: 'Resolved',
    2: 'Pending Reply from AM'
  };
  let d;
  if (params.data) {
    d = params.data.commentStatus;
  }
  if (params.value) {
    d = params.value;
  }
  if (d !== null) {
    return commentStatus[d];
  }
  else
    return '';
}

const createImageSpan = (image) => {
  const resultElement = document.createElement('span');
  const imageElement = document.createElement('img');
  imageElement.src = image;
  imageElement.width = '22';
  imageElement.height = '22';
  resultElement.appendChild(imageElement);
  return resultElement;
};

class moderationStatusRenderer {
  init(params) {
    let rendererImage;
    if (params.value === 0) {
      rendererImage = ICON_MODERATION_PENDING;
    }
    if (params.value === 1) {
      rendererImage = ICON_MODERATION_AVAILABLE;
    }

    this.eGui = createImageSpan(rendererImage);

  }
  getGui() {
    return this.eGui;
  }
}

const commentReply = (params, node) => {
  const resultElement = document.createElement('span');
  const buttonElement = document.createElement('button');
  buttonElement.onclick = function () { this.setState({ showReply: true, node: node }) }
  buttonElement.innerHTML = 'Reply'
  resultElement.appendChild(buttonElement);
  return resultElement;
};

class commentReplyRenderer {
  init(data, node, api) {
    this.eGui = commentReply(data.data, node);
  }
  getGui() {
    return this.eGui;
  }
}

function commentsInnerRenderer(params) {
  if (params && params.data && params.data.comments && params.data.comments.length > 0) {
    return <div><img src={COMMENTS}/></div>
  }
}

function moderationStatusValueFormatter(params) {
  if (params.value === 0)
    return 'Pending Moderation'
  if (params.value === 1)
    return 'Available'
}

function renderPacingDateModified(params) {
  let d;
  if (params.data && params.data.pacingDateModified) {
    d = params.data.pacingDateModified.substring(0, params.data.pacingDateModified.indexOf('T'));
    return d
  }
  else
    return '';
}

export default class CampaignOperations extends React.Component {
  static title = "Campaign Management Operations";

  tableRef = React.createRef();
  detailTableRef = React.createRef();
  static customFilter = 'showAll';

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: 'Account Mgr', field: 'accountManagerUsername', pinned: 'left',
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          },
        },
        {
          headerName: '(i)', field: 'moderationStatus', pinned: 'left', cellRenderer: moderationStatusRenderer, width: '10px',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: moderationStatusValueFormatter,
          },
        },
        {
          headerName: 'Campaign Name', field: 'originalName', pinned: 'left',
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          },
          cellStyle: { 'fontWeight': 'bold' }
        },
        {
          headerName: 'Campaign ID', field: '_id', pinned: 'left',
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          },
          cellStyle: params => {
            if (((params.data.installs === 0 || params.data.installs === null) && (params.data.moderationStatus === 1)) &&
              (!params.data.suppressWarning || params.data.suppressWarning === false)) {
              return { color: 'black', backgroundColor: '#fbbc04' };
            }
            if (((params.data.installs === 0 || params.data.installs === null) && (params.data.moderationStatus === 1)) &&
              (params.data.suppressWarning && params.data.suppressWarning === true)) {
              return { color: 'black', backgroundColor: '#fffaeb' };
            }
            
            return null;
          }
        },
        {
          filter: 'agNumberColumnFilter',          
          headerName: 'Installs (7d)', field: 'installs', pinned: 'left', minWidth: 100
        },
        {
          headerName: 'Package', field: 'packageName',
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          },
        },
        {
          headerName: 'Advertiser/Agency', field: 'invoiceRecipientTitle',
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          },
        },

        {
          headerName: 'Managed by', field: 'invoiceIssuerTitle',
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
          },
        },
        { headerName: 'Conversion Point', field: 'biddingModels', valueGetter: renderConversionPoint, cellStyle: { 'whiteSpace': 'pre' }, autoHeight: true },
        {
          headerName: 'Comments', field: 'comments', cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: { innerRenderer: commentsInnerRenderer }
          //cellRenderer: commentsRenderer
        },
        {
          headerName: 'Comment Status', field: 'commentStatus', valueGetter: renderCommentStatus,
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: renderCommentStatus,
          },
        },
        //{ headerName: 'Last Comments', field: 'comments', valueGetter: renderLastComments, cellStyle: { 'whiteSpace': 'pre' }, autoHeight: true },
        /*
        {
          headerName: 'Modified At', field: 'dateModified'
        },
        */

        {
          headerName: 'Max Imps / Clicks per day', field: 'maxImpsClicksPerDay'
        },
        {
          headerName: 'Extra Imps / Clicks per Clickthrough Install', field: 'extraImpsClicksPerClickthroughInstall'
        },
        {
          headerName: 'Pacing Modified At', field: 'pacingDateModified', cellRenderer: renderPacingDateModified, minWidth: 200
        },
        {
          headerName: 'Notes', field: 'notes', cellEditor: 'agTextCellEditor', cellEditorPopup: true, editable: true, minWidth: 300,
          valueSetter: this.notesSetter,
          cellEditorParams: {
            rows: 10,
            cols: 50,
            maxLength: 2000
          },
          cellEditorPopup: false
        }

      ],
      detailColumnDefs: [
        { headerName: 'From', field: 'from', cellStyle: { 'white-space': 'pre' }, autoHeight: true },
        { headerName: 'Comment', field: 'comment', cellStyle: { 'white-space': 'pre' }, autoHeight: true, minWidth: 600 },
        { headerName: 'Date', field: 'dateModified', cellStyle: { 'white-space': 'pre' }, autoHeight: true }
      ],
      user: props.user,
      showReply: false,
      showViewAllComments: false,
      commentStatus: null,
      moderationStatus: null,
      rowData: null,
      rowDetailData: null,
      loading: false,
      selectedIds: [],
      customFilter: 'showAll',
      node: null
    }
  }

  fetchData(nodeid, comments) {
    if (nodeid) {
      const rowNode = this.tableRef.current.api.getRowNode(this.state.node.id.substring(this.state.node.id.indexOf('_') + 1));
      rowNode.setDataValue('comments', [{
        "from": "",
        "dateModified": "",
        "comment": ""
      }])
    }
    const API = '/api/v1/tools/campaignoperations';
    this.setState({ loading: true });
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        this.setState({ rowData: res, loading: false });
      })
      .catch(function (error) {
        window.location.replace("/login");
      });
  }

  rangeHandle(event, suppressWarning) {
    let cellRanges = event.api.getCellRanges();
    let selectedIds = [];
    if (cellRanges && cellRanges.length > 0) {
      cellRanges.forEach((range) => {
        let startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
        let endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          range.columns.forEach((column) => {
            if (column.colId === '_id') {
              let rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
              let value = event.api.getValue(column, rowNode);
              selectedIds.push(value);
            }
          });
        }
      });
    }
    else {
      selectedIds = []
    }

    if (selectedIds.length > 0) {
      try {
        const API = '/api/v1/tools/updatesuppresswarning';
        const response = fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            '_id': selectedIds,
            'suppressWarning': suppressWarning
          })
          //body: JSON.stringify(selectedIds),
        });

        const result = response.json();
        console.log("Success:", result);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }

  rangeHandleState(event) {
    let cellRanges = event.api.getCellRanges();
    let selectedIds = [];
    if (cellRanges && cellRanges.length > 0) {
      cellRanges.forEach((range) => {
        let startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
        let endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          range.columns.forEach((column) => {
            if (column.colId === '_id') {
              let rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
              let value = event.api.getValue(column, rowNode);
              selectedIds.push(value);
            }
          });
        }
      });
    }
    else {
      selectedIds = []
    }

    if (document.getElementById("removeWarningBtn") && document.getElementById("enableWarningBtn")) {
      if (selectedIds.length > 0) {
        document.getElementById("removeWarningBtn").classList.remove('disabled')
        document.getElementById("enableWarningBtn").classList.remove('disabled')
      } else {
        document.getElementById("removeWarningBtn").classList.add('disabled')
        document.getElementById("enableWarningBtn").classList.add('disabled')
      }
    }

  }

  isExternalFilterPresent() {
    return true;
  };

  doesExternalFilterPass(node) {
    if (node.data) {
      if (document.getElementById("showAll").checked) {
        return true;
      }
      if (document.getElementById("showPending").checked) {
        return (((node.data.installs === 0 || node.data.installs === null) && (node.data.moderationStatus === 1)) &&
          (!node.data.suppressWarning || node.data.suppressWarning === false));
      }
    }
    return true;
  };

  externalFilterChanged(filter) {
    this.setState({ 'customFilter': filter})
    this.tableRef.current.api.onFilterChanged();
  };

  getRowId(params) {
    return params.data._id
  }

  notesSetter(params) {
    
    if ((params.data.notes && params.data.notes !== params.newValue) || (!params.data.notes && params.newValue !== null)) {
      params.data.notes = params.newValue
      try {
        const API = '/api/v1/tools/updatenotes';
        fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            '_id': params.data._id,
            'notes': params.data.notes
          })
        })
          .then((response) => {
            const result = response.json();
            console.log("Success:", result);
            return true
          });

      }
      catch (error) {
        console.error("Error:", error);
        return false;
      }
    }
    else {
      return true;
    }
  }


  componentDidMount() {
    this.fetchData()
  }

  render() {

    const defaultColDef = {
      flex: 1,
      minWidth: 150,
      filter: 'agTextColumnFilter',
      menuTabs: ['filterMenuTab'],
    };
    const autoSizeStrategy = {
      type: 'fitCellContents'
    };

    const detailCellRendererParams = {
      // provide the Grid Options to use on the Detail Grid
      detailGridOptions: {
        columnDefs: [
          {
            headerName: "View",
            colId: "view",
            cellRenderer: commentReplyRenderer
          },
          { headerName: 'From', field: 'from', cellStyle: { 'white-space': 'pre' }, autoHeight: true },
          { headerName: 'Comment', field: 'comment', cellStyle: { 'white-space': 'pre' }, autoHeight: true, minWidth: 600 },
          { headerName: 'Date', field: 'dateModified', cellStyle: { 'white-space': 'pre' }, autoHeight: true },
        ],
        defaultColDef: {
          flex: 1,
        },
        autoSizeStrategy: {
          type: 'fitCellContents'
        }
      },
      // get the rows for each Detail Grid
      getDetailRowData: params => {
        if (params?.data?.comments && params.data.comments.length > 0) {
          params.successCallback(params.data.comments);
        }
        else {
          params.successCallback([{
            "from": "",
            "dateModified": "",
            "comment": ""
          }]);
        }
      }
    };

    const detailCellRenderer = (data, node, api) => (
      <div style={{ backgroundColor: '#EBEBEB' }}>

        <h4 style={{ padding: '10px' }}>Comments</h4>
        <div style={{ padding: '10px' }}>
          
          {((data.data.comments && data.data.comments.length > 0 && (data.data.commentStatus == 0 || data.data.commentStatus == 2)) && (
            <button type='button' class='btn btn-default btn-small'
              onClick={(e) => this.setState({ showReply: true, rowDetailData: data.data, node: data.node })}>Reply</button>
          ))}

          {((!data.data.comments || data.data.comments.length === 0 || data.data.commentStatus == 1 ) && (
            <button type='button' class='btn btn-default btn-small'
              onClick={(e) => this.setState({ showReply: true, rowDetailData: data.data, node: data.node })}>New Comment</button>
          ))}
          
        </div>

        <div style={{ padding: '10px' }}>
          {(data.data?.comments || []).slice().reverse().map((comment, idx, array) => {
            if ((data.data?.lastResolvedDate && (comment.dateModified > data.data?.lastResolvedDate)) || (!data.data?.lastResolvedDate)) {
              return (<span> {comment.from + (comment.from?":":"")}<br /> <i><b>{comment.comment}</b></i><br />{comment.dateModified}<br /><br /></span>);
            }            
          })}
        </div>

        <AgGridReact
          id="detailGrid"
          ref={this.detailTableRef}
          columnMenu={'new'}
          autoSizeStrategy={autoSizeStrategy}
          columnDefs={this.state.detailColumnDefs}
          rowDetailData={(data.data && data.data.comments && data.data.comments.length > 0) ?
            data.data.comments : []}
          defaultColDef={defaultColDef}
        >
        </AgGridReact>
        
     </div>
    );

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });


    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={CampaignOperations.title}
          description={
            <p></p>
          }
        >
          <UserContext.Consumer>
            {user => (
              <Box>
                <Box.Header>
                  <div style={{ 'display': 'flex'}}>
                  <input
                    type="text"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onChange={(e) => {
                      this.onFilterTextBoxChanged();
                    }}
                      style={{ 'height': '28px'}}
                    />&nbsp;
                    <button type='button' class={'btn btn-default btn-small ' + ((this.state.loading) ? 'disabled' : '') }
                      onClick={() => {
                        this.tableRef.current.api.showLoadingOverlay();
                        this.fetchData();
                      //this.tableRef.current.api.setGridOption('rowData', this.state.rowData.items)
                      }}>Refresh</button>&nbsp;
                    {(this.state.user?._jwt?.email === 'a.dimitropoulos@minimob.com') && (
                    <button type='button' id="removeWarningBtn" class={'btn btn-default btn-small disabled'}
                      onClick={() => {
                        this.rangeHandle(this.tableRef.current, true);
                        this.tableRef.current.api.showLoadingOverlay();
                        this.fetchData();
                        //this.tableRef.current.api.setGridOption('rowData', this.state.rowData.items)
                        }}>Suppress Warning</button>
                    )}&nbsp;
                    {(this.state.user?._jwt?.email === 'a.dimitropoulos@minimob.com') && (
                      <button type='button' id="enableWarningBtn" class={'btn btn-default btn-small disabled'}
                        onClick={() => {
                          this.rangeHandle(this.tableRef.current, false);
                          this.tableRef.current.api.showLoadingOverlay();
                          this.fetchData();
                          //this.tableRef.current.api.setGridOption('rowData', this.state.rowData.items)
                        }}>Enable Warning</button>
                    )}&nbsp;
                    <label>
                      <input type='radio' id="showAll" name="filter"
                        onClick={() => {
                        this.externalFilterChanged('showAll');
                        }} />&nbsp;&nbsp;All&nbsp;&nbsp;
                    </label>
                    <label>
                    <input type='radio' id="showPending" name="filter"
                      onClick={() => {
                        this.externalFilterChanged('showPending');
                        }} />&nbsp;&nbsp;Pending to be started&nbsp;&nbsp;
                    </label>
                    </div>
                </Box.Header>
                <Box.Body>
                  <div
                    className="ag-theme-quartz"
                    style={{
                      width: '100%'
                    }}
                  >
                    <AgGridReact
                      id="masterGrid"
                      columnMenu={'new'}
                      autoSizeStrategy={autoSizeStrategy}
                      ref={this.tableRef}
                      columnDefs={this.state.columnDefs}
                      rowData={(this.state.rowData && this.state.rowData.items && this.state.rowData.items.length > 0) ?
                        this.state.rowData.items : []}
                      defaultColDef={defaultColDef}
                      masterDetail={true}
                      detailCellRenderer={detailCellRenderer}
                      detailCellRendererParams={detailCellRendererParams}
                      detailRowAutoHeight={true}
                      enableRangeSelection={true}
                      enableRangeHandle={true}
                      style={{ width: '100%', height: '70vh' }}
                      overlayLoadingTemplate={'<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>'}
                      overlayNoRowsTemplate={'<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>'}
                      onRangeSelectionChanged={this.rangeHandleState}
                      isExternalFilterPresent={this.isExternalFilterPresent}
                      doesExternalFilterPass={this.doesExternalFilterPass}
                      getRowId={this.getRowId}
                    >
                    </AgGridReact>
                  </div>
                </Box.Body>
              </Box>
            )}
          </UserContext.Consumer>

          {this.state.showReply && (
            <AddViewCommentsDialog
              showReply={true}
              rowDetailData={this.state.rowDetailData}
              user={this.state.user}
              onHide={() => {
                this.fetchData(this.state.node.id)
                //const rowNode = this.tableRef.current.api.getRowNode(this.state.node.id);
                //rowNode.setDataValue('comments', rowNode.data.comments);
                this.setState({
                  busy: false,
                  error: null,
                  showReply: false,
                  //rowData: null
                })
              }}
            />
          )}
          {this.state.showViewAllComments && (
            <AddViewCommentsDialog
              showViewAllComments={true}
              rowData={this.state.rowData}
              onHide={() => {
                //this.tableRef.current.reload();
                this.setState({
                  busy: false,
                  error: null,
                  showViewAllComments: false,
                  rowData: null
                })
              }}
            />
          )}

        </PageLayout>
      </>
    );

  }

  onFilterTextBoxChanged() {
    this.tableRef.current.api.setGridOption(
      'quickFilterText',
      document.getElementById('filter-text-box').value
    );
  }
}
