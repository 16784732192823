import React from "react";
import PageLayout from "components/PageLayout";
import { Box } from "components/admin-lte";
import {
  ButtonGroup,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Alert,
  Row,
  Col
} from "react-bootstrap";
import { PublisherPicker } from "components/pickers/SelectPickers";
import buildErrorSummary from "components/ErrorSummary";
import CampaignPicker from "components/pickers/CampaignPicker";
import axios from "axios";
import download from "downloadjs";
import { extractFilenameFromResponse } from "lib/Functions";

export default class OfflineOffers extends React.Component {
  static title = "Offline Offers";

  constructor(props) {
    super(props);
    this.state = {
      publisher: null,
      campaign: null,
      conversions: "1",
      error: null,
      busy: false,
      message: null
    };
    this.handleSendConversions = this.handleSendConversions.bind(this);
    this.disablePIEopt = this.disablePIEopt.bind(this);
    this.setPIEopt = this.setPIEopt.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={OfflineOffers.title}
        description={
          <>
            This tool should be used in order to send fake conversions to a
            publisher for a specific CPI or CPI/PIE offer.{" "}
          </>
        }
      >
        <>
          {errorSummary && (
            <Alert
              bsStyle="danger"
              onDismiss={() => this.setState({ error: null })}
            >
              {errorSummary}
            </Alert>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <Box busy={this.state.busy}>
            <Box.Header>
              Please insert a publisher, an offer and a number from 1 to 10
              which represents the number of conversions that you want to send
              to the publisher.
              <br />
              Please note that the click time of the conversions is always:
              today.
              <br />   
                If your offer is offline and the revenues that are going to be
                recorded are not real, you are strongly advised to mark the
                offer as "own media" through the moderation page.
              <br />
              <ButtonGroup bsSize="small">
                <Button
                  disabled={
                    !this.state.publisher ||
                    !this.state.campaign ||
                    !this.state.conversions
                  }
                  bsStyle="primary"
                  onClick={this.disablePIEopt}
                >
                  Disable PIE opt
              </Button>
              </ButtonGroup>
              <ButtonGroup bsSize="small">
                <Button
                  disabled={
                    !this.state.publisher ||
                    !this.state.campaign ||
                    !this.state.conversions
                  }
                  bsStyle="primary"
                  onClick={this.setPIEopt}
                >
                  Set default PIE opt
              </Button>
              </ButtonGroup>
            </Box.Header>
            <Box.Body>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Publisher</ControlLabel>
                    <PublisherPicker
                      onChange={value => this.setState({ publisher: value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Campaign</ControlLabel>
                    <CampaignPicker
                      onChange={value => this.setState({ campaign: value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel># Conversions (1-10)</ControlLabel>
                    <FormControl
                      componentClass="input"
                      type="number"
                      min={1}
                      max={10}
                      onChange={e =>
                        this.setState({ conversions: e.target.value })
                      }
                      defaultValue={this.state.conversions}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Box.Body>
            <Box.Footer>
              <Button
                disabled={
                  !this.state.publisher ||
                  !this.state.campaign ||
                  !this.state.conversions
                }
                bsStyle="primary"
                onClick={this.handleSendConversions}
              >
                Send Conversions
              </Button>
            </Box.Footer>
          </Box>
        </>
      </PageLayout>
    );
  }

  handleSendConversions() {
    this.setState({ busy: true, error: null });

    axios({
      url: "/api/v1/tools/offlineoffers",
      method: "GET",
      params: {
        publisherid: this.state.publisher,
        offerid: this.state.campaign,
        numconversions: this.state.conversions
      },
      responseType: "blob"
    })
      .then(res => {
        this.setState({
          busy: false,
          error: null
        });
        const filename =
          extractFilenameFromResponse(res) || "OfflineOffers_Conversions.xlsx";
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }

  disablePIEopt() {
    this.setState({
      busy: true,
      message: null,
      error: null });

    axios({
      url: "/api/v1/tools/disablepieopt",
      method: "post",
      data: {
        publisherid: this.state.publisher,
        offerid: this.state.campaign,
        numconversions: this.state.conversions
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  setPIEopt() {
    this.setState({
      busy: true,
      message: null,
      error: null });

    axios({
      url: "/api/v1/tools/setpieopt",
      method: "post",
      data: {
        publisherid: this.state.publisher,
        offerid: this.state.campaign,
        numconversions: this.state.conversions
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }


}
