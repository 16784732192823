import React from "react"

export default function (props) {
  if (props.value === 0 || props.value === "Android") {
    return (
      <span className="fa fa-android fa-1_6x light_green" title="Android"></span>
    )
  }
  else if (props.value === 1 || props.value === "iOS") {
    return (
      <span className="fa fa-apple fa-1_6x dark_grey" title="iOS"></span>
    )
  }
  else {
    return (
      <span>{props.value}</span>
    )
  }
}