import React from "react";
import { Modal } from "react-bootstrap";
import DataTable from "components/DataTable";

export default function UniquePackageNamesModal({ id, name, onHide }) {
  const tableRef = React.createRef();
  return (
    <Modal bsSize="large" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{name}: Unique Package Names</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable.Toolbar tableRef={tableRef} bsSize="small" noReloadButton />
        <DataTable
          ref={tableRef}
          className="table table-condensed table-striped table-hover"
          ajax={{
            url: `/api/v1/networks/${id}/packagenames`,
            dataSrc: ""
          }}
          columns={[
            {
              title: `<i title="Platform" class="fa fa-info-circle fa-1_4x grey"></i>`,
              data: "osPlatform",
              render: DataTable.StockRenderers.platform()
            },
            {
              title: `<i title="Store" class="fa fa-info-circle fa-1_4x grey"></i>`,
              data: "storeId",
              render: DataTable.StockRenderers.store()
            },
            { title: "PackageName", data: "packageName" },
            { title: "Title", data: "title" }
          ]}
          order={[[2, "asc"]]}
        />
      </Modal.Body>
    </Modal>
  );
}
