import React from "react";
import { Button } from "react-bootstrap";
import { combineClasses } from "lib/Functions";

export default class DownloadButton extends Button {
  render() {
    const { className, ...rest } = this.props;
    return (
      <Button className={combineClasses(className, "bg-olive")} {...rest}>
        <i className="glyphicon glyphicon-download-alt"></i> Download
      </Button>
    );
  }
}
