import React from "react";
import { Box } from "components/admin-lte";
import { Button, Modal } from "react-bootstrap";
import { Tabs, Tab, FocusStyleManager } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Spinner } from "components/admin-lte";
import DataTable, { DataTableToolbar } from "components/DataTable";
import ByIdTab from "./tabs/ByIdTab";
import ByAdvertiserTab from "./tabs/ByAdvertiserTab";
import ByExternalIdTab from "./tabs/ByExternalIdTab";
import FromTextTab from "./tabs/FromTextTab";

export default function AddOfferDialog({ title, show, onHide, onAdd }) {
  const [selectedTabId, setSelectedTabId] = React.useState("sng");
  const [offers, setOffers] = React.useState(null);
  const [selectedOffers, setSelectedOffers] = React.useState(null);
  const [busy, setBusy] = React.useState(false);
  const tableRef = React.useRef();

  React.useEffect(() => FocusStyleManager.onlyShowFocusOnTabs(), []);

  React.useEffect(() => {
    if (!tableRef.current) return;
    //const api = tableRef.current.api;
    setOffers(null);
    setSelectedOffers(null);
  }, [show]);

  function findOffersById(campaigns) {
    setOffers(campaigns.map(x => ({ key: x.id, value: x.text })));
  }

  function findOffersByAdvertiser(advertiserId) {
    setBusy(true);
    fetch(`/api/v1/lookup/offer?advertiserId=${advertiserId}`)
      //.then(res => res.json())
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        return res.json();
      })
      .then(json => setOffers(json))
      .finally(() => setBusy(false));
  }

  function findOffersByExternalId(advertiserId, text) {
    setBusy(true);
    fetch(`/api/v1/lookup/offer?advertiserId=${advertiserId}`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain"
      },
      body: text
    })
      //.then(res => res.json())
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        return res.json();
      })
      .then(json => setOffers(json))
      .finally(() => setBusy(false));
  }

  function findOffersFromText(text) {
    setBusy(true);
    fetch("/api/v1/lookup/offer", {
      method: "POST",
      headers: {
        "Content-Type": "text/plain"
      },
      body: text
    })
      //.then(res => res.json())
      .then(res => {
        if (res.status === 401) {
          window.location.replace('/login');
        }
        return res.json();
      })
      .then(json => setOffers(json))
      .finally(() => setBusy(false));
  }

  function handleAdd() {
    const ids = (selectedOffers !== null && selectedOffers.length > 0
      ? selectedOffers
      : offers
    ).map(x => x.key);
    onAdd(ids);
  }

  return (
    <Modal bsSize="large" show={show} onHide={onHide} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="tabs"
          animate={false}
          defaultSelectedTabId={selectedTabId}
          onChange={setSelectedTabId}
        >
          <Tab
            id="sng"
            title="By ID"
            panel={<ByIdTab onChange={findOffersById} />}
          />
          <Tab
            id="adv"
            title="By Advertiser"
            panel={<ByAdvertiserTab onChange={findOffersByAdvertiser} />}
          />
          <Tab
            id="ext"
            title="By External ID"
            panel={<ByExternalIdTab onChange={findOffersByExternalId} />}
          />
          <Tab
            id="txt"
            title="From Text"
            panel={<FromTextTab onChange={findOffersFromText} />}
          />
        </Tabs>

        {offers !== null && (
          <Box busy={busy}>
            <Box.Header>
              <DataTableToolbar tableRef={tableRef} bsSize="small" noReloadButton>
              </DataTableToolbar>
            </Box.Header>
            <Box.Body>
              <DataTable
                ref={tableRef}
                className="table table-condensed"
                columns={[
                  {
                    title: "ID",
                    data: "key"
                  },
                  {
                    title: "Name",
                    data: "value"
                  }
                ]}
                order={[]}
                data={offers}
                showCheckBoxes={true}
                onSelect={setSelectedOffers}
              />
            </Box.Body>         
          </Box>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="primary"
          onClick={handleAdd}
          disabled={offers === null || offers.length === 0}
        >
          {selectedOffers !== null && selectedOffers.length > 0
            ? "Add selected"
            : "Add"}
        </Button>
        <Button onClick={onHide}>Cancel</Button>
      </Modal.Footer>
      <Spinner visible={busy} />
    </Modal>
  );
}
