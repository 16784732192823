import React from "react";

export default function() {
  return (
    <>
      <table
        className="table table-condensed table-striped"
        style={{ fontSize: ".9em" }}
      >
        <tbody>
          <tr>
            <th>Reject Reason</th>
            <th>Reject Reason Value</th>
            <th style={{ width: "400px" }}>Description</th>
          </tr>
          <tr>
            <td>devicerank</td>
            <td>[Device ID]</td>
            <td>Device IDs known to be perpetrating fraud.</td>
          </tr>
          <tr>
            <td>ctit_anomalies</td>
            <td>[CTIT in Seconds]</td>
            <td>Clicks blocked based on unreasonable Click To Install Time.</td>
          </tr>
          <tr>
            <td>store_install_validation</td>
            <td></td>
            <td>Installs that failed validation from the App Store.</td>
          </tr>
          <tr>
            <td>site_blacklist</td>
            <td>[Site ID]</td>
            <td>
              Installs from Site IDs with high fraudulent activity, determined
              by Appsflyer's internal algorithms.
            </td>
          </tr>
          <tr>
            <td>install_validation_rules</td>
            <td>[Ruleset ID]</td>
            <td>
              Depending on the Validation Rules the advertiser has set in
              AppsFlyer.
            </td>
          </tr>
          <tr>
            <td>bots</td>
            <td></td>
            <td>Blocked install attempt made by a Bot.</td>
          </tr>
          <tr>
            <td>click_flood</td>
            <td>[Site ID]</td>
            <td>
              Installs from Site IDs with a high amount of installs with high
              CTIT and low Conversion Rate.
            </td>
          </tr>
          <tr>
            <td>behavioral_anomalies</td>
            <td></td>
            <td>Inconsistent and abnormal post install behavior.</td>
          </tr>
          <tr>
            <td>install_hijacking</td>
            <td></td>
            <td>Clicks with unreasonable click and install time.</td>
          </tr>
          <tr>
            <td>anonymous_traffic</td>
            <td></td>
            <td>
              IPs associated with known data centers, VPNs, Tor exit nodes,
              cloud services and more.
            </td>
          </tr>
          <tr>
            <td>engagement_injection</td>
            <td></td>
            <td>
              A fraudulent click between an app download and the first app-open
              to steal the attribution from another source.
            </td>
          </tr>
          <tr>
            <td>distribution_outlier</td>
            <td></td>
            <td>Click spam related fraud.</td>
          </tr>
          <tr>
            <td>too_many_engagements</td>
            <td></td>
            <td>Click spamming : an install attributed to too many clicks.</td>
          </tr>
          <tr>
            <td>invalid_signature</td>
            <td></td>
            <td>Invalid or missing Adjust SDK Signature.</td>
          </tr>
        </tbody>
      </table>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.appsflyer.com/hc/en-us/articles/115004744123-Postbacks-on-Rejected-Installs-to-Integrated-Partners"
      >
        Appsflyer Rejected Install Guide
      </a>
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.adjust.com/en/fraud-prevention"
      >
        Adjust Rejected Install Guide
      </a>
    </>
  );
}
