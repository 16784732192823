import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

export default class DeleteDialog extends React.Component {
  static title = "Delete Transaction";

  constructor(props) {
    super(props);
    this.state = {
      groupId: props.groupId||null,
      invoiceNo: props.invoiceNo||null,
      error: null,
      busy: false,
      message: null
    };
    this.handleDeleteTransaction = this.handleDeleteTransaction.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Delete Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          
          <Box.Body>
            <Row>
              <Col md={12}>
                <p>
                  You are about to <strong>delete Transaction </strong> !
                </p>
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !this.props.id
            }
            bsStyle="danger"
            onClick={this.handleDeleteTransaction}
          >
            Delete Transaction
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleDeleteTransaction() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/deletetransaction",
      method: "post",
      data: {
        Id: this.props.id
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
