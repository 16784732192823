import React from "react";
import { ContentHeader, Content } from "./admin-lte";
import { Link } from "react-router-dom";

type PageLayoutProps = {
  title?: string;
  documentTitle?: string;
  description?: React.ReactNode;
  breadcrumbs?: React.ReactNodeArray | null;
  error?: string;
};

type PageLayoutState = {
  /**
   * An error thrown by a child component and
   * caught by React's error boundary mechanism.
   */
  boundaryError?: string;
};

class PageLayout extends React.Component<PageLayoutProps, PageLayoutState> {
  static getDerivedStateFromError(error: any): PageLayoutState {
    return { boundaryError: String(error) };
  }

  componentDidMount() {
    document.title = (t => (t ? `${t} - Minimob Adops` : "Minimob Adops"))(
      this.props.documentTitle || this.props.title
    );
  }

  render() {
    const { title, breadcrumbs, description, children } = this.props;

    if (this.state?.boundaryError) {
      return (
        <Content>
          <p className="text-red">
            <i className="fa fa-warning text-red"></i>&nbsp;
            {this.state.boundaryError}
          </p>
        </Content>
      );
    }

    return (
      <>
        <ContentHeader>
          <h1>{title}</h1>
          {breadcrumbs !== null && (
            <ol className="breadcrumb">
              <li>
                <Link to="/">
                  <i className="fa fa-home"></i> Home
                </Link>
              </li>
              {breadcrumbs && breadcrumbs.map((b, i) => <li key={i}>{b}</li>)}
              <li className="active">{title}</li>
            </ol>
          )}
          {description && (
            <div style={{ marginTop: "0.8em" }}>{description}</div>
          )}
        </ContentHeader>
        <Content>{children}</Content>
      </>
    );
  }
}

export default PageLayout;
