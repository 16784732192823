import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment-timezone";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import Chart from "./Chart";
import Master from "./Master";
import Details from "./Details";
import RejectionReason from "./RejectionReason";
import { Spinner } from "components/admin-lte";
import { extractFilenameFromResponse } from "lib/Functions";
import download from "downloadjs";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.loadSummaries = this.loadSummaries.bind(this);
    this.loadSummary = this.loadSummary.bind(this);
    this.downloadRejectedOffers = this.downloadRejectedOffers.bind(this);
    this.tableRef = React.createRef();
  }

  getDefaultState() {
    return {
      summaries: null,
      selectedRange: 0,
      selectedSummary: null,
      selectedOffer: null,
      error: null,
      busy: false
    };
  }

  componentDidMount() {
    this.loadSummaries(this.state.selectedRange);
  }

  render() {
    const {
      summaries,
      selectedRange,
      selectedSummary,
      selectedOffer,
      busy
    } = this.state;

    const body = () => {
      if (selectedSummary) {
        return (
          <>
            <Row className="text-center">
              <Col md={12}>
                <ToggleButtonGroup
                  type="radio"
                  name="time"
                  bsSize="small"
                  defaultValue={selectedRange}
                  onChange={value => this.loadSummaries(value)}
                >
                  <ToggleButton value={0}>Latest</ToggleButton>
                  <ToggleButton value={6}>6h</ToggleButton>
                  <ToggleButton value={12}>12h</ToggleButton>
                  <ToggleButton value={24}>24h</ToggleButton>
                  <ToggleButton value={48}>48h</ToggleButton>
                </ToggleButtonGroup>
              </Col>
            </Row>
            <br />
            {summaries && (
              <Chart
                data={summaries}
                selectedSummary={selectedSummary}
                onSummaryClick={id => {
                  this.setState({ busy: true });
                  this.loadSummary(id)
                    .then(batchId =>
                      this.setState({
                        selectedSummary: batchId,
                        selectedOffer: null,
                        busy: false
                      })
                    )
                    .catch(error =>
                      this.setState({
                        error,
                        busy: false
                      })
                    );
                }}
              />
            )}
            <Master data={selectedSummary} />
            <RejectionReason
              data={selectedOffer}
              onDismiss={() => this.setState({ selectedOffer: null })}
              visible={selectedOffer}
            />
            <Details
              data={selectedSummary}
              onSelectOffer={selectedOffer => this.setState({ selectedOffer })}
            />
          </>
        );
      } else if (busy) {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center">Loading...</div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center text-muted">No data</div>
            </Col>
          </Row>
        );
      }
    };

    return (
      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <small>
              {this.props.name} ({this.props.id})
            </small>
            <br />
            Execution History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body()}</Modal.Body>
        {selectedSummary && selectedSummary.downloadableOffersCount > 0 && (
          <Modal.Footer>
            <Button
              className="bg-olive pull-left"
              bsSize="small"
              onClick={() =>
                this.downloadRejectedOffers(selectedSummary.batchId)
              }
              disabled={busy}
            >
              <i className="glyphicon glyphicon-download-alt"></i> Download
              rejected offers
            </Button>
          </Modal.Footer>
        )}
        <Spinner visible={busy} />
      </Modal>
    );
  }

  loadSummaries(range) {
    const { id } = this.props;
    this.setState({ busy: true });
    if (range === 0) {
      axios({
        url: `/api/v1/feeders/${id}/ExecutionSummary`
      })
        .then(res => {
          this.setState({
            summaries: null,
            selectedSummary: res.data,
            selectedRange: range,
            selectedOffer: null,
            busy: false
          });
        })
        .catch(err => {
          this.setState({
            error: err.response.data,
            busy: false
          });
        });
    } else {
      const to = moment().format("YYYY-MM-DD HH:mm");
      const from = moment()
        .subtract(range, "hours")
        .format("YYYY-MM-DD HH:mm");

      axios({
        url: `/api/v1/feeders/${id}/ExecutionSummaries`,
        params: { from, to }
      })
        .then(res => {
          const summaries = res.data.sort(
            (a, b) =>
              moment(a.startUtc).valueOf() - moment(b.startUtc).valueOf()
          );

          if (summaries.length) {
            const summaryId = summaries[summaries.length - 1].batchId;
            this.loadSummary(summaryId)
              .then(selectedSummary =>
                this.setState({
                  summaries,
                  selectedSummary,
                  selectedRange: range,
                  selectedOffer: null,
                  busy: false
                })
              )
              .catch(error =>
                this.setState({
                  error,
                  busy: false
                })
              );
          } else {
            this.setState({
              summaries,
              selectedSummary: null,
              selectedRange: range,
              selectedOffer: null,
              busy: false
            });
          }
        })
        .catch(err => {
          this.setState({
            error: err.response.data,
            busy: false
          });
        });
    }
  }

  loadSummary(summaryId) {
    const { id } = this.props;
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v1/feeders/${id}/ExecutionSummary`,
        params: { summaryId }
      })
        .then(res => resolve(res.data))
        .catch(err => reject(err.response.data));
    });
  }

  downloadRejectedOffers(summaryId) {
    const { id } = this.props;
    this.setState({ busy: true, error: null });
    axios({
      url: `/api/v1/feeders/${id}/RejectedOffers`,
      params: { summaryId },
      method: "get",
      responseType: "blob"
    })
      .then(res => {
        this.setState({ busy: false, error: null });
        const filename = extractFilenameFromResponse(res);
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }
}
