import React from "react";
import moment from "moment-timezone";
import { Row, Col } from "react-bootstrap";

export default ({ data }) => {
  const advancedSettings = getAdvancedSettings(data);

  return (
    <Row>
      <Col md={4}>
        <dl>
          <dt>SummaryId</dt>
          <dd>{data.batchId}</dd>
          <dt>Started at</dt>
          <dd>{moment(data.startUtc).format("YYYY-MM-DD HH:mm")}</dd>
          <dt>Ended at</dt>
          <dd>{moment(data.endUtc).format("YYYY-MM-DD HH:mm")}</dd>
          <dt>Duration</dt>
          <dd>
            {data.duration.toLocaleString("en-US", {
              useGrouping: false,
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            })}{" "}
            sec
          </dd>
        </dl>
      </Col>
      <Col md={4}>
        <dl>
          <dt>Success</dt>
          <dd>{data.result.toString()}</dd>
          {data.result ? (
            <>
              <dt>Downloaded</dt>
              <dd>{data.downloaded}</dd>
              <dt>Accepted</dt>
              <dd>{data.accepted}</dd>
              <dt>Removed</dt>
              <dd>{data.removed}</dd>
            </>
          ) : (
            <>
              <dt className="text-danger">Error</dt>
              <dd className="text-danger">
                {data.error || data.exception.message}
              </dd>
            </>
          )}
        </dl>
      </Col>
      {advancedSettings && <Col md={4}>{advancedSettings}</Col>}
    </Row>
  );
};

function getAdvancedSettings(summary) {
  const settings = summary.advancedSettings;

  if (settings === null) return null;

  return (
    <dl>
      {settings.FallbackCap && (
        <>
          <dt>Fallback Cap</dt>
          <dd>{settings.FallbackCap}</dd>
        </>
      )}
      {settings.MinPayout && (
        <>
          <dt>Min Payout</dt>
          <dd>{getPayout(settings.MinPayout)}</dd>
        </>
      )}
      {settings.MaxPayout && (
        <>
          <dt>Max Payout</dt>
          <dd>{getPayout(settings.MaxPayout)}</dd>
        </>
      )}
      {settings.IncludeCountries && (
        <>
          <dt>Whitelisted Countries</dt>
          <dd>{getCountries(settings.IncludeCountries)}</dd>
        </>
      )}
      {settings.ExcludeCountries && (
        <>
          <dt>Blacklisted Countries</dt>
          <dd>{getCountries(settings.ExcludeCountries)}</dd>
        </>
      )}
    </dl>
  );
}

function getPayout(payout) {
  const b = payout.BothIncentivizedAndNot.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const i = payout.Incentivized.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const n = payout.NonIncentivized.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const u = payout.Uncertain.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  if (b === i && b === n && b === u) {
    return b;
  } else {
    return `B:${b}, I:${i}, N:${n}, U:${u}`;
  }
}

function getCountries(countries) {
  return countries.join(", ");
}
