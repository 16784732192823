import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import EditDialog from "./EditDialog";
import DeleteDialog from "./DeleteDialog";
import {Button, ButtonGroup, FormGroup, Row, Col} from "react-bootstrap";
import { UserContext } from "lib/user";
import {  PublisherPicker } from "components/pickers/SelectPickers";
import $ from "jquery";
import axios from "axios";
import _ from 'lodash';
import ReactDOMServer from "react-dom/server";

function renderCopy(d, t, r, m) {
  if (t === "display") {
    if (r.clickTrackingUrl && r.clickTrackingUrl.length > 0) {
      return (ReactDOMServer.renderToStaticMarkup(
          <button type='button' className='btn btn-default btn-small'>
            <i className="fa fa-copy fa-1_4x" title="Copy Click Tracking Url to clipboard"></i>
          </button>
        )
      );
    } else {
      return "";
    }
  }
  return d;
}

export default class OfferGroups extends React.Component {
  static title = "Offer Groups";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      tableUrl:"/api/v1/tools/offergroups",
      loading: true,
      showAddGroup: false,
      showEditFor: null,
      showDeleteFor: null,
      publisherId: null,
      publisherName: null
    };
    this.handleReload = this.handleReload.bind(this);
    this.getTableUrl = this.getTableUrl.bind(this);
    this.selectPublisher = this.selectPublisher.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleAddEnd = this.handleAddEnd.bind(this);
    this.colorizeRow = this.colorizeRow.bind(this);
  }

  componentDidMount() {
    this.selectPublisher(this.state.publisherId);
  }
  
  render() {
    const { showEditFor, showAddGroup, showDeleteFor } = this.state;
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={OfferGroups.title}
          description={
            <p></p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>                
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                  <ButtonGroup bsSize="small">
                    <Button
                      bsStyle="primary"
                      onClick={this.handleAddClick.bind(this,user)}
                    >
                      <i className="fa fa-plus"></i> Add Group
                    </Button>
                  </ButtonGroup>
                  
                </DataTable.Toolbar>
                <br/>
                <Row>
                  <Col xs={12} sm={6} lg={3}>
                    <FormGroup>
                      <label>Publisher</label>
                      <PublisherPicker
                        className="form-control"
                        placeholder="Enter id or name"
                        onChange={(publisherId,publisherName) => this.selectPublisher(publisherId,publisherName)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped table-hover"
                  ref={this.tableRef}
                  ajax={{
                    url: this.getTableUrl(), //"/api/v1/tools/offergroups",
                    dataSrc: 'items'
                  }}
                  onLoading={()=> this.setState({ loading: true })}
                  onLoaded={() => this.setState({ loading: false })}
                  createdRow={ this.colorizeRow }
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      if(commandData.action === "showEdit"){
                        this.setState({ showEditFor: commandData });
                      } else if(commandData.action === "delete"){
                        this.setState({ showDeleteFor: commandData });
                      } else if(commandData.action==='copy'){
                        navigator.clipboard.writeText(commandData.clickTrackingUrl)
                      }           
                    }
                  }}
                  columns={[
                    DataTable.createUserCommandColumn({
                      title: "Group Name",
                      data: "name",
                      name: "name",
                      className: "truncate-cell-wide",
                      width: "20%",
                      defaultContent: "",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'showEdit',
                        id: rowData._id,
                        groupName: rowData.name,
                        offers: rowData.offers||[]
                      })
                    }),
                    { 
                      title: "# Offers",
                      data: "offers",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        if(d && d.length > 0){
                          return d.length;
                        }
                        return '';
                      },
                    },
                    {
                      title: "Click Tracking Url",
                      data: "clickTrackingUrl",
                      name: "clickTrackingUrl",
                      className: "columnset-publisher-related row-break-word",
                      width: "60%",
                      defaultContent: "",
                      visible: false,
                      render: (d, t, r, m) => {
                        if(d && d.length > 0){
                          return decodeURIComponent(d);
                        }
                        return '';
                      },
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "",
                      name: "copy",
                      className: "columnset-publisher-related",
                      width: "60px",
                      defaultContent: "",
                      visible: false,
                      render:renderCopy,
                      orderable: false,
                      searchable: false,
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'copy',
                        id: rowData._id,
                        clickTrackingUrl: rowData.clickTrackingUrl&&rowData.clickTrackingUrl.length>0?decodeURIComponent(rowData.clickTrackingUrl):''
                      })
                    }),
                    DataTable.createUserCommandColumn({
                      title: `<div class="th-actions-div">Actions</div>`,
                      name: "actions",
                      className: "text-right column-th-actions",
                      orderable: false,
                      searchable: false,
                      defaultContent: ReactDOMServer.renderToStaticMarkup(
                        <button type='button' className='btn btn-default btn-small'> View/Edit Offers </button>
                      ),
                      width: "40px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'showEdit',
                        id: rowData._id,
                        groupName: rowData.name,
                        offers: rowData.offers||[]
                      })
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      name: "actionsDelete",
                      orderable: false,
                      searchable: false,
                      defaultContent: ReactDOMServer.renderToStaticMarkup(
                        <div>
                          <button type='button' className='btn btn-default btn-small'> Delete</button>
                        </div>
                      ),
                      width: "40px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'delete',
                        id: rowData._id,
                        groupName: rowData.name
                      })
                    }),
                  ]}
                  order={[[0, "asc"]]}
                />
                
                {this.state.publisherId!==null && (
                  <Row>
                    <Col sm={12} lg={6}>
                      <div id="indicator-table-wrapper">
                        <table id="indicator-table">
                          <tbody>
                          <tr>
                            <td className="indicator-color">
                              <div className="indicator-color-div row-bg-warning"></div>
                            </td>
                            <td className="indicator-text small-font">Selected publisher is not included in some offers in group</td>
                          </tr>
                          <tr>
                            <td className="indicator-color">
                              <div className="indicator-color-div row-bg-danger"></div>
                            </td>
                            <td className="indicator-text small-font">Selected publisher is not included in all offers in group</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>  
                )}
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {showEditFor && (
          <EditDialog
            isNew={false}
            id={showEditFor.id}
            groupName={showEditFor.groupName}
            offers={showEditFor.offers}
            selectedPublisherId={this.state.publisherId||null}
            selectedPublisherName={this.state.publisherName||null}
            onHide={() => {
              this.setState({ showEditFor: null });
              this.tableRef.current.reload();
            }}
          />
        )}

        {showAddGroup && (
          <EditDialog
            isNew={true}
            onAdd={this.handleAddEnd}
            onHide={() => this.setState({ showAddGroup: false })}
          />
        )}
        
        {showDeleteFor && (
          <DeleteDialog
            id={showDeleteFor.id}
            groupName={showDeleteFor.groupName}
            title="Delete offer group"
            show={true}
            onHide={() => {
              this.setState({ showDeleteFor: null });
              this.tableRef.current.reload();
            }}
          />
        )}
      </>
    );
  }

  constructTableUrl(){
    var publisherId=this.state.publisherId||null;

    var qs = "";
    if(publisherId!==null&&publisherId.length>0){
      qs = "?publisherId="+publisherId;
    }
    var newUrl = this.state.tableUrl+qs;

    return newUrl;
  }

  handleReload(){
    var newUrl = this.constructTableUrl();
    this.tableRef.current.setNewUrl(newUrl);
  }

  getTableUrl(){
    return this.constructTableUrl();
  }
  
  handleAddEnd(name,ids){
    //TODO: validation
    if(ids && ids.length>0){
      ids = ids.join(",");
    }
    
    this.setState({
      busy: true,
      message: null,
      error: null
    });
    
    axios({
      url: "/api/v1/tools/addoffergroup",
      method: "post",
      data: {
        Name: name,
        Offers: JSON.stringify(ids)
      }
    })
      .then(res => {
        this.setState({
          showAddGroup: false,
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });

        this.tableRef.current.reload();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
 
  selectPublisher(publisherId, publisherName) {
    this.setState({ publisherId: publisherId, publisherName: publisherName }, () => {
      this.handleReload();
      const api = this.tableRef.current.api;
      if(publisherId!==null && publisherId.length>0){
        api.columns(".columnset-publisher-related").visible(true);
      }else{
        api.columns(".columnset-publisher-related").visible(false);
      }
      
      api.draw(false);
    });
  }

  handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      //handleAddStart()
      this.setState({showAddGroup:true});
    }
  }

  colorizeRow(row, data, dataIndex) {
    
    var publisherId = this?.state?.publisherId||null;
    var found = false;
    var foundCount = 0;
    
    if (publisherId) {
      if (data.publishersByOffer) {
        
        data.publishersByOffer.forEach(offer => {
          found = _.includes((offer.targetedTrafficSources||[]), publisherId);
          if(found){
            ++foundCount;
            //return false;
          }
        });
        
        if(foundCount === 0){
          $(row).addClass( 'row-bg-danger' );
        }else if(foundCount < data.publishersByOffer.length){
          $(row).addClass( 'row-bg-warning' );
        }
      }
      
    }
  }
  
}
