import React from "react";
import { FormGroup, Button, FormControl, Row, Col } from "react-bootstrap";
import { AdvertiserPicker } from "components/pickers/SelectPickers";

function ByExternalIdTab({ onChange }) {
  const [advertiserId, setAdvertiserId] = React.useState(null);
  const [text, setText] = React.useState("");

  return (
    <>
      <p>
        Add offers currently in Moderation using their external ID. The
        advertiser is mandatory.
      </p>
      <FormGroup>
        <Row>
          <Col md={6}>
            <AdvertiserPicker
              placeholder="Enter advertiser id or name"
              onChange={setAdvertiserId}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <FormControl
          componentClass="textarea"
          placeholder="Enter one external id per line"
          onChange={e => setText(e.target.value)}
          rows={6}
        />
      </FormGroup>
      <FormGroup>
        <Button
          bsSize="small"
          onClick={() => onChange(advertiserId, text)}
          disabled={advertiserId === null || text === ""}
        >
          <i className="fa fa-search"></i> Fetch
        </Button>
      </FormGroup>
    </>
  );
}

export default ByExternalIdTab;
