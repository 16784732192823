import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import DataTable from "components/DataTable";
import AddDialog from "./AddDialog";
import { Box } from "components/admin-lte";
import axios from "axios";
import PageLayout from "components/PageLayout";
import { ButtonGroup, Button } from "react-bootstrap";
import moment from "moment-timezone";
import { UserContext } from "lib/user";

export default class GloballyBlockedPubs extends React.Component {
  static title = "Globally Blocked Pubs";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      adding: false,
      selectedItems: [],
      busy: false,
      error: null
    };
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={GloballyBlockedPubs.title}
        description={
          <p>
            The publishers and subpublishers in this list are blocked globally
            from all advertisers, applications and campaigns.
          </p>
        }
      >
        <>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          <Box busy={this.state.busy}>
            <Box.Header>
              <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                <UserContext.Consumer>
                  {user => (
                    <ButtonGroup bsSize="small">
                      <Button
                        //onClick={this.handleAdd}
                        onClick={(e) => {
                          if(!this.checkTokenExpired(user)){
                            this.handleAdd();
                          }
                        }}
                        disabled={this.state.loading}
                      >
                        <i className="fa fa-plus"></i> Add New
                      </Button>
                      <Button
                        //onClick={this.handleRemove}
                        onClick={(e) => {
                          if(!this.checkTokenExpired(user)){
                            this.handleRemove();
                          }
                        }}
                        disabled={
                          this.state.loading ||
                          this.state.selectedItems.length === 0
                        }
                      >
                        <i className="fa fa-trash-o"></i> Remove Selected
                      </Button>
                    </ButtonGroup>
                  )}
                </UserContext.Consumer>
              </DataTable.Toolbar>
            </Box.Header>
            <Box.Body>
              <DataTable
                ref={this.tableRef}
                className="table table-condensed table-striped"
                onLoading={() => this.setState({ loading: true })}
                onLoaded={() => this.setState({ loading: false })}
                onSelect={rows =>
                  this.setState({
                    selectedItems: rows.map(x => ({
                      publisherId: x.publisherId,
                      encodedSubpublisherId: x.encodedSubpublisherId
                    }))
                  })
                }
                showCheckBoxes
                noSelectAll
                order={[]}
                ajax={{
                  url: "/api/v1/tools/globallyblockedpubs",
                  dataSrc: "rules"
                }}
                columns={[
                  {
                    title: "Publisher ID",
                    data: "publisherId"
                  },
                  {
                    title: "Publisher Name",
                    data: "publisherId",
                    render: (d, t, r, m) => d && m.settings.json.userNames[d]
                  },
                  {
                    title: "Encoded Subpublisher",
                    data: "encodedSubpublisherId",
                    defaultContent: ""
                  },
                  {
                    title: "Subpublisher",
                    data: "decodedSubpublisherId",
                    defaultContent: ""
                  },
                  {
                    title: "Moderation Date",
                    data: "moderationDate",
                    defaultContent: "",
                    className: "text-nowrap text-sm",
                    render: (d, t, r, m) => {
                      d = d || r.creationDate;
                      return d && moment.utc(d).format("YYYY-MM-DD HH:mm");
                    }
                  },
                  {
                    title: "Moderator",
                    data: "moderatorId",
                    defaultContent: "",
                    className: "truncate-cell text-sm",
                    render: (d, t, r, m) => {
                      d = d || r.creatorId;
                      return m.settings.json.userNames[d];
                    }
                  }
                ]}
              />
            </Box.Body>
          </Box>
          <AddDialog
            show={this.state.adding}
            onHide={() => {
              this.setState({ adding: false });
            }}
            onAdded={() => {
              this.setState({ busy: true });
              this.tableRef.current.reload(json =>
                this.setState({
                  busy: false,
                  error: null
                })
              );
            }}
          />
        </>
      </PageLayout>
    );
  }

  handleAdd() {
    this.setState({ adding: true });
  }

  handleRemove() {
    const confirmation = window.confirm(
      this.state.selectedItems.length === 1
        ? "Do you want to remove the selected item?"
        : `Do you want to remove the ${this.state.selectedItems.length} selected items?`
    );
    if (!confirmation) return;

    this.setState({ busy: true });

    axios({
      url: "/api/v1/tools/globallyblockedpubs/remove",
      method: "post",
      data: {
        rules: this.state.selectedItems
      }
    })
      .then(() => {
        this.tableRef.current.selectNone();
        this.tableRef.current.reload(() =>
          this.setState({
            busy: false,
            error: null
          })
        );
      })
      .catch(error => {
        this.setState({
          busy: false,
          error: error.response.data
        });
      });
  }

  checkTokenExpired(user){
    if (user.tokenHasExpired) {
      window.location.replace('/login');
      return true;
    }
    return false;
  }
}
