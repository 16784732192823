import React from "react";
import google_play from "assets/storeIcons/play_icon_22x22.png";
import apple_itunes from "assets/storeIcons/app_store_icon_22x22.png";
import minimob_store from "assets/storeIcons/minimob_store_icon.png";

const Store: React.FC<{ value: string }> = ({ value }) => {
  switch (value) {
    case "1":
      return (
        <img
          src={google_play}
          alt="Google Play"
          title="Google Play: The official google market store for Android apps."
        />
      );
    case "2":
      return (
        <img
          src={apple_itunes}
          alt="Apple iTunes"
          title="Apple iTunes: The official market store for Apple iOS apps."
        />
      );
    case "3":
      return (
        <img
          src={minimob_store}
          alt="Minimob Store"
          title="Minimob Store: The official market store for Minimob."
          height={22}
          width={22}
        />
      );
    default:
      return <span>{value}</span>;
  }
};

export default Store;
