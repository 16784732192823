import React from "react";
import moment from "moment-timezone";
import axios from "axios";
import download from "downloadjs";
import { FormGroup, Row, Col } from "react-bootstrap";
import {
  AdvertiserPicker,
  PublisherPicker,
  ApplicationPicker,
  CampaignPicker
} from "components/pickers/SelectPickers";
import DateRangePicker, {
  DATE_FORMAT
} from "components/pickers/DateRangePicker";
import Hint from "components/Hint";
import buildErrorSummary from "components/ErrorSummary";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { extractFilenameFromResponse } from "lib/Functions";
import DownloadButton from "components/stock/DownloadButton";

moment.tz.setDefault("UTC");

export default class ScrubReport extends React.Component {
  static title = "Scrub Report";

  constructor(props) {
    super(props);
    this.state = {
      advertiserIds: [],
      publisherIds: [],
      subpublisherIds: [],
      applicationIds: [],
      campaignIds: [],
      date: null,
      correlators: "",
      includeEvents: false,
      includeNetworkInfo: false,
      includePostbacks: false,
      useBigQuery: false,
      recipient: "Publisher",
      busy: false,
      error: null,
      formErrors: {}
    };
    this.handleDownload = this.handleDownload.bind(this);
  }

  render() {
    const { allowBigQuery, minBillableDate } = this.props;
    const { error, useBigQuery, formErrors } = this.state;
    const errorSummary = buildErrorSummary(error);

    const minVerticaDate = moment.utc(minBillableDate);
    const minBigQueryDate = moment("2015-01-01");
    const minDate = allowBigQuery ? minBigQueryDate : minVerticaDate;
    const startOfLastMonth = moment()
      .subtract(1, "months")
      .startOf("month");
    const ranges = startOfLastMonth.isBefore(minDate)
      ? {
          Today: [moment().startOf("day"), moment().endOf("day")],
          Yesterday: [
            moment()
              .subtract(1, "days")
              .startOf("day"),
            moment()
              .subtract(1, "days")
              .endOf("day")
          ],
          "This week so far": [
            moment().startOf("isoWeek"),
            moment().endOf("day")
          ],
          "Last week": [
            moment()
              .subtract(1, "weeks")
              .startOf("isoWeek"),
            moment()
              .subtract(1, "weeks")
              .endOf("isoWeek")
          ],
          "This month so far": [
            moment().startOf("month"),
            moment().endOf("day")
          ],
          [`Last month, from ${minDate.format(DATE_FORMAT)} onwards`]: [
            minDate,
            moment()
              .subtract(1, "months")
              .endOf("month")
          ]
        }
      : {
          Today: [moment().startOf("day"), moment().endOf("day")],
          Yesterday: [
            moment()
              .subtract(1, "days")
              .startOf("day"),
            moment()
              .subtract(1, "days")
              .endOf("day")
          ],
          "This week so far": [
            moment().startOf("isoWeek"),
            moment().endOf("day")
          ],
          "Last week": [
            moment()
              .subtract(1, "weeks")
              .startOf("isoWeek"),
            moment()
              .subtract(1, "weeks")
              .endOf("isoWeek")
          ],
          "This month so far": [
            moment().startOf("month"),
            moment().endOf("day")
          ],
          "Last month": [
            startOfLastMonth,
            moment()
              .subtract(1, "months")
              .endOf("month")
          ]
        };

    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={ScrubReport.title}
        description={
          <p>
            Traffic data &mdash; installations and events &mdash; for a specific
            date range, matching a set of criteria.
          </p>
        }
      >
        <>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
        </>
        <Box busy={this.state.busy}>
          <Box.Body>
            <Row>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <label>Advertisers</label>
                  <AdvertiserPicker
                    className="form-control"
                    multiple
                    placeholder="Enter ids or names"
                    onChange={value => this.setState({ advertiserIds: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Publishers</label>
                  <PublisherPicker
                    className="form-control"
                    multiple
                    placeholder="Enter ids or names"
                    onChange={value => this.setState({ publisherIds: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Encoded Subpublishers</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter one id per line"
                    onChange={e =>
                      this.setState({
                        subpublisherIds: e.target.value
                          .split("\n")
                          .filter(x => x !== "")
                      })
                    }
                  ></textarea>
                </FormGroup>
              </Col>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <label>Applications</label>
                  <ApplicationPicker
                    className="form-control"
                    multiple
                    placeholder="Enter hex ids"
                    onChange={value => this.setState({ applicationIds: value })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Campaigns</label>
                  <CampaignPicker
                    className="form-control"
                    multiple
                    placeholder="Enter hex ids"
                    onChange={value => this.setState({ campaignIds: value })}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} lg={4}>
                <FormGroup className={formErrors.date ? "has-error" : ""}>
                  <label>Date</label>
                  {!allowBigQuery && (
                    <>
                      &nbsp;
                      <Hint id="about-date">
                        <p>
                          Scrub Report can access data since{" "}
                          {minDate.format("YYYY-MM-DD")}.
                        </p>
                      </Hint>
                    </>
                  )}
                  <DateRangePicker
                    className="form-control"
                    opens="left"
                    minDate={minDate}
                    ranges={ranges}
                    onChange={value => {
                      const date = value;
                      this.setState((state, props) => {
                        const formErrors = state.formErrors;

                        if (
                          date &&
                          moment(date[0]).isBefore(minVerticaDate) &&
                          !useBigQuery
                        ) {
                          formErrors.date = `Minimum date for Vertica is ${minVerticaDate.format(
                            "DD/MM/YYYY"
                          )}. Try using Google BigQuery instead.`;
                        } else {
                          delete formErrors.date;
                        }

                        return { date, formErrors };
                      });
                    }}
                  />
                  {formErrors.date && (
                    <span className="help-block">{formErrors.date}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label>Correlators</label>
                  <textarea
                    placeholder="Enter one correlator per line, either prefixed with 003_ or not"
                    onChange={e =>
                      this.setState({ correlators: e.target.value })
                    }
                    className="form-control"
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <label>Recipient</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          value="Publisher"
                          onChange={e =>
                            this.setState({ recipient: e.target.value })
                          }
                          checked={this.state.recipient === "Publisher"}
                        />
                        Publisher
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          value="Advertiser"
                          onChange={e =>
                            this.setState({ recipient: e.target.value })
                          }
                          checked={this.state.recipient === "Advertiser"}
                        />
                        Advertiser
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          value="Expert"
                          onChange={e =>
                            this.setState({ recipient: e.target.value })
                          }
                          checked={this.state.recipient === "Expert"}
                        />
                        Expert
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <label>Other</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          onChange={e =>
                            this.setState({ includeEvents: e.target.checked })
                          }
                          disabled={this.state.recipient === "Publisher"}
                        />
                        Include events
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          onChange={e =>
                            this.setState({
                              includePostbacks: e.target.checked
                            })
                          }
                          disabled={this.state.recipient === "Advertiser"}
                        />
                        Include postbacks
                      </label>
                      &nbsp;
                      <Hint id="about-include-postbacks">
                        <p>
                          Includes the clickid, URL and response of the postback
                          that we sent to the publisher.
                        </p>
                      </Hint>
                    </Col>
                  </Row>
                  {allowBigQuery && (
                    <>
                      <Row>
                        <Col md={12}>
                          <label className="checkbox-inline">
                            <input
                              type="checkbox"
                              onChange={e =>
                                this.setState({
                                  includeNetworkInfo: e.target.checked
                                })
                              }
                            />
                            Include IP addresses and network information
                          </label>
                          &nbsp;
                          <Hint id="about-include-network-info">
                            <p>
                              Includes the user's IP address and ISP and a flag
                              whether the address is duplicate within the
                              resultset.
                            </p>
                            <p>
                              Enabling this option will make the query{" "}
                              <strong>significantly heavier</strong>. Do not
                              check this, if you don't need it.
                            </p>
                          </Hint>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label className="checkbox-inline">
                            <input
                              type="checkbox"
                              onChange={e => {
                                const useBigQuery = e.target.checked;

                                this.setState((state, props) => {
                                  const date = state.date;
                                  const formErrors = state.formErrors;

                                  if (
                                    date &&
                                    moment(date[0]).isBefore(minVerticaDate) &&
                                    !useBigQuery
                                  ) {
                                    formErrors.date = `Minimum date for Vertica is ${minVerticaDate.format(
                                      "DD/MM/YYYY"
                                    )}. Try using Google BigQuery instead.`;
                                  } else {
                                    delete formErrors.date;
                                  }

                                  return { useBigQuery, formErrors };
                                });
                              }}
                            />
                            Use Google BigQuery
                          </label>
                          &nbsp;
                          <Hint id="about-use-google-bigquery">
                            <p>
                              This option is visible only to members of the{" "}
                              <strong>ScrubReportBigQuery</strong> role.
                            </p>
                            <p>
                              By enabling this option, you will first be
                              notified about the cost of the queries in Bytes.
                              Then you may choose not to proceed with the actual
                              execution.
                            </p>
                          </Hint>
                        </Col>
                      </Row>
                    </>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <DownloadButton
              onClick={this.handleDownload}
              disabled={Object.keys(formErrors).length>0?true:false}
            />
          </Box.Footer>
        </Box>
      </PageLayout>
    );
  }

  handleDownload(e, skipCostEvaluation = false) {
    const { useBigQuery } = this.state;
    this.setState({ busy: true, error: null });

    axios({
      url: "/api/v1/tools/scrubreport",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "blob",
      data: {
        advertiserIds: this.state.advertiserIds,
        publisherIds: this.state.publisherIds,
        encodedSubpublisherIds: this.state.subpublisherIds,
        applicationIds: this.state.applicationIds,
        campaignIds: this.state.campaignIds,
        includeEvents: this.state.includeEvents,
        includeNetworkInfo: this.state.includeNetworkInfo,
        includePostbacks: this.state.includePostbacks,
        useBigQuery: this.state.useBigQuery,
        fromDateInclusiveUtc: this.state.date
          ? moment(this.state.date[0]).format("YYYY-MM-DD")
          : null,
        toDateExclusiveUtc: this.state.date
          ? moment(this.state.date[1])
              .add(1, "second")
              .format("YYYY-MM-DD")
          : null,
        correlators: this.state.correlators
          .split("\n")
          .filter(x => x !== null && x !== ""),
        recipient: this.state.recipient,
        skipCostEvaluation
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          error: res.status === 204 ? "No data." : null
        });

        if (res.status === 200) {
          const filename =
            extractFilenameFromResponse(res) || `${this.state.recipient}.xlsx`;
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        }
      })
      .catch(err => {
        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          if (err.response.status === 403 && useBigQuery) {
            const cost = JSON.parse(e.srcElement.result);
            const msg = [
              "The cost of the queries you are about to execute will be approximately:",
              "",
              ...cost.map(
                c => `\u2022  ${c.Name}: ${c.FriendlyCost || "no cost"}`
              ),
              "",
              "Do you want to continue?"
            ].join("\r\n");

            if (window.confirm(msg)) {
              this.handleDownload(null, (skipCostEvaluation = true));
              return;
            }
          }

          this.setState({
            busy: false,
            error: err.response.status === 403 ? null : e.srcElement.result
          });
        });
        reader.readAsText(err.response.data);
      });
  }
}
