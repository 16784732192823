import React from "react";
import ReactDOMServer from "react-dom/server";
import moment from "moment-timezone";

function FirstRow({ actualRanges }) {
  return (
    <tr>
      {/* COMMON COLUMNS */}
      {/* ADOPS COLUMNS */}
      {/* GROUP BY AFFILIATE COLUMNS */}
      {/* GROUP BY CAMPAIGN COLUMNS */}
      <td colSpan={10}></td>
      {/* LEFT COLUMNS */}
      <td className="range-surtitle-0">
        Left Date Range{actualRanges[0].daysDenominator > 1 && " (daily avg)"}
      </td>
      <td className="range-surtitle-0">
        Left Date Range{actualRanges[0].daysDenominator > 1 && " (daily avg)"}
      </td>
      <td className="range-surtitle-0">
        Left Date Range{actualRanges[0].daysDenominator > 1 && " (daily avg)"}
      </td>
      <td className="range-surtitle-0">
        Left Date Range{actualRanges[0].daysDenominator > 1 && " (daily avg)"}
      </td>
      <td className="range-surtitle-0">
        Left Date Range{actualRanges[0].daysDenominator > 1 && " (daily avg)"}
      </td>
      <td className="range-surtitle-0">
        Left Date Range{actualRanges[0].daysDenominator > 1 && " (daily avg)"}
      </td>
      {/* COMPARISON TRIPLETS */}
      {[
        {
          id: 1,
          surtitle:
            "Right Date Range" +
            (actualRanges[1].daysDenominator > 1 ? " (daily avg)" : "")
        },
        { id: 2, surtitle: "Last 3 days (daily avg)" },
        { id: 3, surtitle: "Last 10 days (daily avg)" },
        { id: 4, surtitle: "Last 20 days (daily avg)" },
        { id: 5, surtitle: "Last 30 days (daily avg)" }
      ].map(({ id, surtitle }) => (
        <React.Fragment key={id}>
          <td className={`range-surtitle-${id}`} colSpan={3}>
            {surtitle}
          </td>
          <td className={`range-surtitle-${id}`} colSpan={3}>
            {surtitle}
          </td>
          <td className={`range-surtitle-${id}`} colSpan={3}>
            {surtitle}
          </td>
          <td className={`range-surtitle-${id}`} colSpan={3}>
            {surtitle}
          </td>
          <td className={`range-surtitle-${id}`} colSpan={3}>
            {surtitle}
          </td>
          <td className={`range-surtitle-${id}`} colSpan={3}>
            {surtitle}
          </td>
        </React.Fragment>
      ))}
    </tr>
  );
}

function SecondRow({ actualRanges }) {
  const actualRangeSubtitles = actualRanges.map(actualRange => {
    let from = moment.utc(actualRange.fromDateInclusiveUtc);
    let to = moment.utc(actualRange.toDateExclusiveUtc);
    let fromHour = from.format("HH:mm");
    let toHour = to.format("HH:mm");

    if (to.isSame(moment(to).startOf("day"))) {
      toHour = "24:00";
      to.subtract(1, "day");
    }

    return [
      from.isSame(to, "date")
        ? from.format("DD/MM/YYYY")
        : from.format("DD/MM/YYYY") + " - " + to.format("DD/MM/YYYY"),
      fromHour + " - " + toHour
    ];
  });

  return (
    <tr>
      {/* COMMON COLUMNS */}
      {/* ADOPS COLUMNS */}
      {/* GROUP BY AFFILIATE COLUMNS */}
      {/* GROUP BY CAMPAIGN COLUMNS */}
      <td colSpan={10}></td>
      {/* LEFT COLUMNS */}
      <td className="range-subtitle-0">
        <div>{actualRangeSubtitles[0][0]}</div>
        <div>{actualRangeSubtitles[0][1]}</div>
      </td>
      <td className="range-subtitle-0">
        <div>{actualRangeSubtitles[0][0]}</div>
        <div>{actualRangeSubtitles[0][1]}</div>
      </td>
      <td className="range-subtitle-0">
        <div>{actualRangeSubtitles[0][0]}</div>
        <div>{actualRangeSubtitles[0][1]}</div>
      </td>
      <td className="range-subtitle-0">
        <div>{actualRangeSubtitles[0][0]}</div>
        <div>{actualRangeSubtitles[0][1]}</div>
      </td>
      <td className="range-subtitle-0">
        <div>{actualRangeSubtitles[0][0]}</div>
        <div>{actualRangeSubtitles[0][1]}</div>
      </td>
      <td className="range-subtitle-0">
        <div>{actualRangeSubtitles[0][0]}</div>
        <div>{actualRangeSubtitles[0][1]}</div>
      </td>
      {/* COMPARISON TRIPLETS */}
      {[1, 2, 3, 4, 5].map(id => {
        if (actualRangeSubtitles[id]) {
          return (
            <React.Fragment key={id}>
              <td className={`range-subtitle-${id}`} colSpan={3}>
                <div>{actualRangeSubtitles[id][0]}</div>
                <div>{actualRangeSubtitles[id][1]}</div>
              </td>
              <td className={`range-subtitle-${id}`} colSpan={3}>
                <div>{actualRangeSubtitles[id][0]}</div>
                <div>{actualRangeSubtitles[id][1]}</div>
              </td>
              <td className={`range-subtitle-${id}`} colSpan={3}>
                <div>{actualRangeSubtitles[id][0]}</div>
                <div>{actualRangeSubtitles[id][1]}</div>
              </td>
              <td className={`range-subtitle-${id}`} colSpan={3}>
                <div>{actualRangeSubtitles[id][0]}</div>
                <div>{actualRangeSubtitles[id][1]}</div>
              </td>
              <td className={`range-subtitle-${id}`} colSpan={3}>
                <div>{actualRangeSubtitles[id][0]}</div>
                <div>{actualRangeSubtitles[id][1]}</div>
              </td>
              <td className={`range-subtitle-${id}`} colSpan={3}>
                <div>{actualRangeSubtitles[id][0]}</div>
                <div>{actualRangeSubtitles[id][1]}</div>
              </td>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={id}>
              <td className={`range-subtitle-${id}`} colSpan={3}></td>
              <td className={`range-subtitle-${id}`} colSpan={3}></td>
              <td className={`range-subtitle-${id}`} colSpan={3}></td>
              <td className={`range-subtitle-${id}`} colSpan={3}></td>
              <td className={`range-subtitle-${id}`} colSpan={3}></td>
              <td className={`range-subtitle-${id}`} colSpan={3}></td>
            </React.Fragment>
          );
        }
      })}
    </tr>
  );
}

function ThirdRow() {
  return (
    <tr>
      {/* COMMON COLUMNS */}
      <th></th>
      <th></th>
      {/* ADOPS COLUMNS */}
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      {/* GROUP BY AFFILIATE COLUMNS */}
      <th></th>
      <th></th>
      {/* GROUP BY CAMPAIGN COLUMNS */}
      <th></th>
      <th></th>
      {/* LEFT COLUMNS */}
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      {/* RIGHT COLUMNS */}
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      {/* RANGE 2 COLUMNS */}
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      {/* RANGE 3 COLUMNS */}
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      {/* RANGE 4 COLUMNS */}
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      {/* RANGE 5 COLUMNS */}
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  );
}

export function getHeader(actualRanges) {
  return {
    __html: ReactDOMServer.renderToStaticMarkup([
      <FirstRow actualRanges={actualRanges} />,
      <SecondRow actualRanges={actualRanges} />,
      <ThirdRow />
    ])
  };
}
