import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import moment from "moment-timezone";
import axios from "axios";
import download from "downloadjs";
import { extractFilenameFromResponse } from "lib/Functions";
import { Row, Col, FormGroup, ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import { Box } from "components/admin-lte";
import DateRangePicker from "components/pickers/DateRangePicker";
import PageLayout from "components/PageLayout";
import CloneDialog from "./CloneDialog";
import DownloadButton from "components/stock/DownloadButton";

moment.tz.setDefault("UTC");

export default class MassClone extends React.Component {
  static title = "Mass Clone";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      date: null,
      busy: false,
      selectedRows: [],
      showClone: false,
      error: null,
      results: []
    };
  }

  render() {
    const { error, busy } = this.state;
    const errorSummary = buildErrorSummary(error);
    const startOfLastMonth = moment()
      .subtract(1, "months")
      .startOf("month");
    const ranges = {
      Yesterday: [
        moment()
          .subtract(1, "days")
          .startOf("day"),
        moment()
          .subtract(1, "days")
          .endOf("day")
      ],
      "Last week": [
        moment()
          .subtract(1, "weeks")
          .startOf("isoWeek"),
        moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      ],
      "Last month": [
        startOfLastMonth,
        moment()
          .subtract(1, "months")
          .endOf("month")
      ]
    };

    return (
      <>
      <PageLayout
        breadcrumbs={["Tools"]}
        title={MassClone.title}
        description={
          <p>
            Find the available converting offers that have not a cloned offer running in the selected timeframe.
            <br />
            Click 'Proceed' to select the offers that you want to clone.
            <br />
            Click 'Download' to download the report.
          </p>
        }
      >
        <>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          <Box busy={busy}>
            <Box.Body>
              <Row>
                <Col sm={12} lg={3}>
                  <FormGroup>
                    <label>Time period</label>
                    <DateRangePicker
                      className="form-control"
                      ranges={ranges}
                      onChange={value => this.setState({ date: value })}
                      minDate={moment("2020-08-01")}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Box.Body>
            <Box.Footer>
              <ButtonToolbar>
                <ButtonGroup bsSize="large">
                  <Button
                    disabled={this.state.date == null}
                    onClick={this.convert.bind(this, false)}
                  >
                    Find Converting Offers
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Box.Footer>
            </Box>
            {this.state.results.length > 0 && (
              <Box>
                <Box.Header>
                  <ButtonGroup>
                    <Button
                      onClick={() => this.setState({ showClone: true })}
                    >
                      Proceed...
                  </Button>
                  <DownloadButton
                      onClick={this.convert.bind(this, true)}
                   />
                  </ButtonGroup>
                </Box.Header>
                <Box.Body>
                  <table className="table table-condensed table-striped table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Offer ID</th>
                        <th>Offer Name</th>
                        <th>Advertiser</th>
                        <th>Payout</th>
                        <th>Clicks</th>
                        <th>Conversions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.results.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.offerid}</td>
                          <td>{x.name}</td>
                          <td>{x.adNetworkName}</td>
                          <td>{x.payout}</td>
                          <td>{x.clicks}</td>
                          <td>{x.conversions}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box.Body>
              </Box>
            )}
        </>
      </PageLayout>
      {
      this.state.showClone && (
          <CloneDialog
          selectedRows={this.state.results}
          onHide={() => this.setState({ showClone: false })}
          onRowsChanged={() => {
            this.tableRef.current.api
              .rows()
              .invalidate()
              .draw();
          }}
        />
      )
    }
    </>
    );
  }

  convert(excel, e) {
    e.preventDefault();

    const { date } = this.state;
    this.setState({ busy: true, error: null });

    axios({
      url: "/api/v1/tools/massclone",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: {
        fromDateInclusiveUtc: moment(date[0]).format("YYYY-MM-DD"),
        toDateExclusiveUtc: moment(date[1])
          .add(1, "second")
          .format("YYYY-MM-DD"),
        output: excel ? "Excel" : "Resultset"
      },
      responseType: excel ? "blob" : "json"
    })
      .then(res => {
        const response = res.data;
        if (excel) {
          this.setState({
            error: null,
            busy: false
          });
          const filename = extractFilenameFromResponse(res) || "MassClone.xlsx";
          download(
            response,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          const response = res.data;
          this.setState({
            results: response.map(x => ({
              offerid: x.offerid,
              name: x.name,
              targetPlatform: x.targetPlatform,
              storeId: x.storeId,
              targetedCountries: x.targetedCountries,
              adNetworkName: x.adNetworkName,
              clicks: x.clicks,
              payout: x.payout,
              conversions: x.conversions,
              moderationStatus: x.moderationStatus,
              cloneOfferId: x.cloneOfferId
            })),
            error: null,
            busy: false
          });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              error: e.srcElement.result,
              busy: false
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            error,
            busy: false
          });
        }
      });
  }
}

