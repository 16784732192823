import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import moment from "moment-timezone";
import axios from "axios";
import download from "downloadjs";
import { extractFilenameFromResponse } from "lib/Functions";
import { Row, Col, FormGroup, ButtonToolbar } from "react-bootstrap";
import { Box } from "components/admin-lte";
import DateRangePicker from "components/pickers/DateRangePicker";
import PageLayout from "components/PageLayout";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

moment.tz.setDefault("UTC");

export default class RTB extends React.Component {
  static title = "Foreign ClickIDs";

  constructor(props) {
    super(props);
    this.state = {
      date: null,
      lines: [],
      busy: false,
      error: null,
      results: []
    };
  }

  render() {
    const { error, busy } = this.state;
    const errorSummary = buildErrorSummary(error);
    const startOfLastMonth = moment()
      .subtract(1, "months")
      .startOf("month");
    const ranges = {
      Yesterday: [
        moment()
          .subtract(1, "days")
          .startOf("day"),
        moment()
          .subtract(1, "days")
          .endOf("day")
      ],
      "Last week": [
        moment()
          .subtract(1, "weeks")
          .startOf("isoWeek"),
        moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      ],
      "Last month": [
        startOfLastMonth,
        moment()
          .subtract(1, "months")
          .endOf("month")
      ]
    };

    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={RTB.title}
        description={
          <p>
            Finds the click id data from lines of text.
            <br />
            Each line should contain a single value.
            <br />
            Event: 0 or Blank value means that the event is an installation,
            otherwise it is an in app event.
            <br />
            Search Timeframe: 30 Days.
          </p>
        }
      >
        <>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          <Box busy={busy}>
            <Box.Body>
              <Row>
                <Col sm={12} lg={3}>
                  <FormGroup>
                    <label>Time period</label>
                    <DateRangePicker
                      className="form-control"
                      ranges={ranges}
                      onChange={value => this.setState({ date: value })}
                      minDate={moment("2020-02-01")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <label>Click IDs</label>
                    <span className="pull-right">
                      Lines entered: {this.state.lines.length}
                    </span>
                    <textarea
                      className="form-control"
                      rows="5"
                      onChange={e =>
                        this.setState({
                          lines: e.target.value.split("\n").filter(x => x)
                        })
                      }
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
            </Box.Body>
            <Box.Footer>
              <ButtonToolbar>
                <DownloadButton
                  onClick={this.handleDownloadOrPreview.bind(this, true)}
                  disabled={this.state.lines.length === 0}
                />
                <PreviewButton
                  onClick={this.handleDownloadOrPreview.bind(this, false)}
                  disabled={this.state.lines.length === 0}
                />
              </ButtonToolbar>
            </Box.Footer>
          </Box>
          {this.state.results.length > 0 && (
            <Box>
              <Box.Body>
                <table className="table table-condensed table-striped table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Original Text</th>
                      <th>Publisher ID</th>
                      <th>Offer ID</th>
                      <th>isOptimized</th>
                      <th>Postback URL</th>
                      <th>Cost</th>
                      <th>Revenues</th>
                      <th>Event Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.results.map((x, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{x.input}</td>
                        <td>{x.traffic_source_name}</td>
                        <td>{x.offer_id}</td>
                        <td>{x.is_optimized}</td>
                        <td>{x.request_url}</td>
                        <td>{x.publisher_credit}</td>
                        <td>{x.advertiser_debit}</td>
                        <td>{x.event_type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box.Body>
            </Box>
          )}
        </>
      </PageLayout>
    );
  }

  handleDownloadOrPreview(excel, e) {
    e.preventDefault();

    const { date } = this.state;
    this.setState({ busy: true, error: null });

    axios({
      url: "/api/v1/reports/RTB",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: {
        fromDateInclusiveUtc: moment(date[0]).format("YYYY-MM-DD"),
        toDateExclusiveUtc: moment(date[1])
          .add(1, "second")
          .format("YYYY-MM-DD"),
        inputs: this.state.lines,
        output: excel ? "Excel" : "Resultset"
      },
      responseType: excel ? "blob" : "json"
    })
      .then(res => {
        const response = res.data;
        if (excel) {
          this.setState({
            error: null,
            busy: false
          });
          const filename = extractFilenameFromResponse(res) || "ClickIDs.xlsx";
          download(
            response,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          const response = res.data;
          this.setState({
            results: response.map(x => ({
              input: x.click_id,
              traffic_source_name: x.traffic_source_name,
              offer_id: x.offer_id,
              is_optimized: x.is_optimized,
              request_url: x.request_url,
              publisher_credit: x.publisher_credit,
              advertiser_debit: x.advertiser_debit,
              event_type: x.event_type
            })),
            error: null,
            busy: false
          });
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              error: e.srcElement.result,
              busy: false
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            error,
            busy: false
          });
        }
      });
  }
}
