import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import axios from "axios";
import moment from "moment-timezone";
import Chart from "./Chart";
import { Spinner } from "components/admin-lte";
import DateRangePicker from "../../../../components/pickers/DateRangePicker";



export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.loadHistory = this.loadHistory.bind(this);
    this.tableRef = React.createRef();
  }

  getDefaultState() {
    return {
      chartData: null,
      partner: this.props.partner,
      selectedRange: this.props.reportDate,
      reportMetric: this.props.reportMetric,
      reportMetricText: this.props.reportMetricText,
      error: null,
      busy: false
    };
  }

  componentDidMount() {
    this.loadHistory(this.state.selectedRange);
  }

  selectDate(date, isInit) {
    this.setState({ selectedDate: date }, () => {

      if (this.tableRef?.current) {
        var columnName = "reportDate";
        if (date && date.length === 2) {
          var dateMin = moment.utc(date[0]).format("YYYY-MM-DD");
          var dateMax = moment.utc(date[1]).format("YYYY-MM-DD");

          this.tableRef.current.setColumnFilter(`${columnName}:name`, dateMin + "_" + dateMax);
        }
        else {
          this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
        }
        this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();

        if (!isInit) {
          this.tableRef.current.reload();
        }
      }
    });
  }

  render() {
    const {
      chartData,
      partner,
      reportMetric,
      reportMetricText,
      busy
    } = this.state;

    const body = () => {
      if (partner) {
        return (
          <>

            <br />
            {chartData && (
              <div>
                <Chart
                  id={this.props.partner + "_" + this.props.reportDate}
                  data={chartData}
                  reportMetric={reportMetric}
                  reportMetricText={reportMetricText}
                />
                Compare to Report date: 
                <DateRangePicker
                  dateFormat="YYYY-MM-DD"
                  opens="right"
                  onChange={(value) => this.loadHistoryDaysBack(value)}
                  minDate={moment().startOf("day").subtract(90, 'd')}
                  maxDate={moment(this.props.reportDate).endOf("day")}
                  startDate={moment(this.props.reportDate).startOf("day")}
                  endDate={moment(this.props.reportDate).startOf("day")}
                  singleDatePicker={true}
                />
              </div>

            )}
          </>
        );
      } else if (busy) {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center">Loading...</div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center text-muted">No data</div>
            </Col>
          </Row>
        );
      }
    };

    return (
      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {reportMetricText || ""} by minute for <b>{this.props.partner}</b> - Report date: <b>{this.props.reportDate}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body()}</Modal.Body>
        <Spinner visible={busy} />
      </Modal>
    );
  }

  loadHistory(reportDate) {
    this.setState({ busy: true });

    const dateFrom = moment(reportDate).format("YYYY-MM-DD");
    const dateTo = dateFrom;
    const partner = this.props.partner || null;
    const reportMetric = this.state.reportMetric || null;
    let chartData;

    axios({
      url: `/api/v1/reports/rtbexchangedatahistory`,
      params: { partner, dateFrom, dateTo, reportMetric }
    })
      .then(res => {
        chartData = res.data.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );
        this.setState({
          chartData,
          selectedRange: reportDate,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }

  loadHistoryDaysBack(reportDate) {
    //const rDate = moment(reportDate);
    const now = moment(this.props.reportDate).utc();
    if (!reportDate[0].isSame(now, 'd')) {
      this.setState({ busy: true });

      const dateFrom = reportDate[0].format("YYYY-MM-DD");
      const dateTo = dateFrom;
      const partner = this.props.partner || null;
      const reportMetric = this.state.reportMetric || null;
      let chartData = this.state.chartData || null;

      axios({
        url: `/api/v1/reports/rtbexchangedatahistory`,
        params: { partner, dateFrom, dateTo, reportMetric }
      })
        .then(res => {
          const chartDataDaysBack = res.data.sort(
            (a, b) =>
              moment(a.modified).valueOf() - moment(b.modified).valueOf()
          );

          const chartDataFiltered = chartData.filter((item) => moment(item.modified).isSame(now.endOf('day'), 'd'));

          chartData = chartDataDaysBack.concat(chartDataFiltered);
          this.setState({
            chartData,
            selectedRange: reportDate,
            busy: false
          });
        })
        .catch(err => {
          this.setState({
            error: err?.response?.data,
            busy: false
          });
        });
    }
  }
}