import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import { N } from "lib/Functions";
import axios from "axios";
import moment from "moment-timezone";

export default class AddDialog extends React.Component {
  static title = "Edit";

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id || null,
      status: this.props.status || null,
      advertiserRevenue: this.props.advertiserRevenue!==null ? (N(this.props.advertiserRevenue,2)) : null,
      advertiserConfirmedAmount: this.props.advertiserConfirmedAmount !== null ? (N(this.props.advertiserConfirmedAmount, 2)) : null,
      advertiserDiscountAmount: this.props.advertiserDiscountAmount !== null ? (N(this.props.advertiserDiscountAmount, 2)) : null,
      notes: this.props.notes !== null ? this.props.notes : null,
      invoice_id: this.props.invoice_id !== null ? this.props.invoice_id : null,
      invoice_date: this.props.invoice_date !== null ? this.props.invoice_date : null,
      error: null,
      busy: false,
      message: null
    };
    
    this.handleSaveAccountingOperation = this.handleSaveAccountingOperation.bind(this);
  }
  
  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    
    return (

      <Modal bsSize="lg" show={true} onHide={this.props.onHide} enforceFocus={false}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          
          <Row><Col md={12}><p></p></Col></Row>
          <Box.Body>
            {/*<Row>
              <Col md={6}>
                <FormGroup>
                  <label>Status</label>
                  <select
                    className="form-control"
                    value={this.state.status||""}
                    onChange={e =>
                      this.setState({ status: e.target.value })
                    }
                  >
                    <option key="key_status_1" value="1">Pending</option>
                    <option key="key_status_2" value="2">Invoice Created</option>
                    <option key="key_status_3" value="3">Invoice Issued</option>                    
                  </select>
                </FormGroup>
              </Col>
            </Row>*/}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Issuer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    disabled={true}
                    type="string"
                    defaultValue={this.props.issuerName||""}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Customer</ControlLabel>
                  <FormControl
                    componentClass="input"
                    disabled={true}
                    type="string"
                    defaultValue={this.props.customerName||""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Platform Revenue</ControlLabel>
                  <FormControl
                    componentClass="input"
                    disabled={true}
                    type="string"
                    defaultValue={N(this.props.platformRevenue,2)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Platform Media Cost</ControlLabel>
                  <FormControl
                    componentClass="input"
                    disabled={true}
                    type="string"
                    defaultValue={N(this.props.platformMediacost,2)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Advertiser Revenue</ControlLabel>
                  <FormControl
                    componentClass="input"
                    //className="text-right"
                    type="string"
                    defaultValue={this.state.advertiserRevenue}
                    onChange={e =>
                      this.setState({ advertiserRevenue: e.target.value })
                    }
                    disabled={!this.allowEditAccountingOperations(this.state.invoice_date)}
                  />      
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Advertiser Confirmed Amount</ControlLabel>
                  <FormControl
                    componentClass="input"
                    //className="text-right"
                    type="string"
                    defaultValue={this.state.advertiserConfirmedAmount}
                    onChange={e =>
                      this.setState({ advertiserConfirmedAmount: e.target.value })
                    }
                    disabled={!this.allowEditAccountingOperations(this.state.invoice_date)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Advertiser Discount Amount</ControlLabel>
                  <FormControl
                    componentClass="input"
                    //className="text-right"
                    type="string"
                    defaultValue={this.state.advertiserDiscountAmount}
                    onChange={e =>
                      this.setState({ advertiserDiscountAmount: e.target.value })
                    }
                    disabled={!this.allowEditAccountingOperations(this.state.invoice_date)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Notes</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    value={this.state.notes||""}
                    onChange={(e) => {
                      this.setState({ notes: e.target.value });
                    }}
                    disabled={false}
                    rows={3}
                  />
                </FormGroup>
              </Col>
            </Row>           
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            disabled={!(this.state.advertiserRevenue!==null && parseFloat(this.state.advertiserRevenue)>=0 && this.state.advertiserConfirmedAmount!==null && parseFloat(this.state.advertiserConfirmedAmount)>=0)}
            onClick={this.handleSaveAccountingOperation}
          >
            Save
          </Button>
          <Button
            onClick={this.props.onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleSaveAccountingOperation() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    var postData = {
      Id: this.state.id,
      Status: this.state.status!==null?parseInt(this.state.status):null,
      AdvertiserRevenue: this.state.advertiserRevenue!==null?parseFloat(this.state.advertiserRevenue):null,
      AdvertiserConfirmedAmount: this.state.advertiserConfirmedAmount !== null ? parseFloat(this.state.advertiserConfirmedAmount) : null,
      AdvertiserDiscountAmount: this.state.advertiserDiscountAmount !== null ? parseFloat(this.state.advertiserDiscountAmount) : null,
      Notes: this.state.notes && this.state.notes.length > 0 ? this.state.notes.trim() : null,
      invoice_id: this.state.invoice_id ? this.state.invoice_id : null
    };

    axios({
      url: "/api/v1/tools/editadoperation",
      method: "post",
      data: postData
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  allowEditAccountingOperations(invoiceDate) {
  let invoiceEditDateEnd;
  invoiceEditDateEnd = moment(invoiceDate);
  invoiceEditDateEnd.add(1, 'months').date(10);
  let now = moment();
  if (now > invoiceEditDateEnd) {
    return false;
  }
  else {
    return true;
  }
}
  
}
