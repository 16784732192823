import moment from "moment-timezone";
import { htmlEscape } from "lib/Functions";

export function renderName(d, t, r, m) {
  if (t === "display") {
    return `
        <div>${r.advertiserName}</div>
        <div class="text-muted">${r.advertiserId}</div>`;
  } else if (t === "filter") {
    return [r.advertiserId, r.advertiserName].join(" ");
  }
  return d;
}

export function renderPlatform(d, t, r, m) {
  if (t === "display") {
    return `
        <div>${r.platformName}</div>
        <div class="text-muted">${r.platformId}</div>
        `;
  } else if (t === "filter") {
    return [r.platformId, r.platformName].join(" ");
  }
  return d;
}

export function renderClickParameter(d, t, r, m) {
  if (t === "display") {
    if (r.missingClickParameter)
      return `<div class="text-danger">MISSING</div>`;
    if (d) return `<code>${d}</code>`;
  } else if (t === "filter" || t === "sort") {
    if (r.missingClickParameter) return "MISSING";
  }
  return d;
}

export function renderStringArray(d, t, r, m) {
  if (t === "display") {
    return (d || []).map(x => `<div>${htmlEscape(x)}</div>`).join("");
  } else if (t === "filter") {
    return (d || []).join(" ");
  }
  return d;
}

export function renderDateTimeUtc(d, t, r, m) {
  if (d && t === "display") {
    return moment.utc(d).format("YYYY-MM-DD HH:mm:ss");
  }
  return d;
}

export function renderDateTimeLocal(d, t, r, m) {
  if (d && t === "display") {
    return moment(d).format("YYYY-MM-DD HH:mm:ss");
  }
  return d;
}

export function renderFriendlyTime(d, t, r, m) {
  if (d && t === "display") {
    return `
        <div>${moment(d).format("HH:mm")}</div>
        <div><small>${moment(d).fromNow()}</small></div>
        `;
  }
  return d;
}

export function renderDate(d, t, r, m) {
  if (d && t === "display") {
    return moment(d).format("YYYY-MM-DD");
  }
  return d;
}

export function renderPercentage(d, t, r, m) {
  if (d !== null && t === "display") {
    return d === 0 ? "" : (d * 100).toFixed(2);
  }
  return d;
}

export function renderSettingWithReason(fieldName) {
  const fieldNameReason = `${fieldName}Reason`;
  return function(d, t, r, m) {
    if (d && t === "display") {
      return `
            <div>${r[fieldName]}</div>
            <div class="text-muted">${r[fieldNameReason] || ""}</div>

            `;
    } else if (d && t === "filter") {
      return [r[fieldName], r[fieldNameReason]].join(" ");
    }
    return d;
  };
}

export function renderStatus(d, t, r, m) {
  if (t === "display") {
    if (d === "Running") {
      return `<span class="label label-success">${d}</span>`;
    } else if (d === "Failed") {
      return `
            <div><span class="label label-danger">${d}</span></div>
            <div class="text-danger text-sm">${htmlEscape(r.lastError)}</div>`;
    } else if ((d === "Closed" || d === "Disabled") && r.emptyFeedReason) {
      return `
            <div>${d}</div>
            </div><span class="text-muted text-sm">${r.emptyFeedReason}</span></div>`;
    }
  }
  return d;
}
