import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { ButtonGroup, Button, ToggleButtonGroup, ToggleButton, FormGroup, Row, Col, ControlLabel } from "react-bootstrap";
import { InvoiceRecipientPicker } from "components/pickers/SelectPickers";
import AddDialog from "./AddDialog";
import InvoiceHtml from "./InvoiceHtml";
import { UserContext } from "lib/user";
import moment from "moment-timezone";
//import DateRangePicker from "components/pickers/DateRangePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import DeleteDialog from "./DeleteDialog";
import axios from "axios";
import jsPDF from "jspdf"; 
import { extractFilenameFromResponse, N} from "lib/Functions";
import download from "downloadjs";
import DownloadButton from "components/stock/DownloadButton";
import "./styles.css";

function renderPeriod(d, t, r, m) {
 /* if (d && (t === "display" || t === "filter")) {
    return moment.utc(d).format("MM/YYYY");
  }*/
  return d;
}

function renderInvoiceDate(d, t, r, m) {
  if (d && (t === "display" || t === "filter")) {
    return moment.utc(d).format("DD/MM/YYYY");
  }
  return d;
}

function renderAccountManagerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if(d!==null)
      return m.settings.json.accountManagerNames[d] || d;
    else 
      return '';
  }
  return d;
}

function renderCustomerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if(d!==null)
      return m.settings.json.customerNames[d] || d;
    else
      return '';
  }
  return d;
}

function renderCustomerExternalId(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null)
      return m.settings.json.externalIds[d] || '';
    else
      return '';
  }
  return d;
}

function renderIssuerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if(d!==null)
      return m.settings.json.issuerNames[d] || d;
    else
      return '';
  }
  return d;
}

function renderGtotalDollar(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    var total = 0;
    if(r!==null && r.invoice_lines!==null){
      (r.invoice_lines||[]).forEach((invoiceLine, idx) =>{
        if(invoiceLine.quantity!==null && invoiceLine.unit_price!==null){
          total = total + (invoiceLine.quantity * invoiceLine.unit_price) + (invoiceLine.quantity * invoiceLine.unit_price * (r.tax_rate/100));
        }
      });
      return N(total, 2);
    }     
  }
  return '';
}

function renderLocked(d, t, r, m){
  var isLocked = false;
  if(r!==null && r.exported === true && r.unlocked === false){
    isLocked = true;
  }
  if (t === "display") {
    return (isLocked?'<div><i class="fa fa-lock"></i></div>':'');
  }
  return isLocked;
}

function renderLinked(d, t, r, m) {
  if (t === "display") {
    return (r.is_linked ? '<div><i class="fa fa-check-circle"></i></div>' : '');
  }
  return r.is_linked;
}

function renderDateTime(d, t, r, m) {
  return t === "display" && d ? moment.utc(d).format("DD/MM/YYYY HH:mm:ss") : d;
}

function allowDeleteInvoice(invoiceDate) {
  let invoiceEditDateEnd;
  invoiceEditDateEnd = moment(invoiceDate);
  invoiceEditDateEnd.add(1, 'months').date(10);
  let now = moment();
  if (now > invoiceEditDateEnd) {
    return true;
  }
  else {
    return false;
  }
}

export default class Invoices extends React.Component {
  static title = "Invoices";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {     
      showAdd: false,
      busy: false,
      error: null,
      showEditDataFor: null,
      showDeleteDataFor: null, 
      showPdfDataFor: null, 
      invoiceData: null,
      //selectedDate: [moment().startOf("day"),moment().endOf("day")],
      //selectedDateFrom: moment().startOf("day"),
      //selectedDateTo: moment().endOf("day"),
      selectedDate: [null,null],
      selectedDateFrom: null,
      selectedDateTo: null,
      defaultInvoicePeriodValue: moment().subtract(1, "month").format('MM/YYYY'),
      tableUrl: "/api/v1/tools/invoices",
      selectedPeriod: new Date((new Date()).setMonth(new Date().getMonth() - 1)),
      selectedStartDate: new Date((new Date()).setMonth(new Date().getMonth() - 1)),
      selectedEndDate: new Date((new Date()).setMonth(new Date().getMonth() - 1))
    };

    this.handlePdfClick = this.handlePdfClick.bind(this);
    //this.onPrintPdfModal = this.onPrintPdfModal.bind(this);
    this.getInvoice = this.getInvoice.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    //this.selectDate(this.state.selectedDate, true);
    //this.selectInvoicePeriod(this.state.defaultInvoicePeriodValue);
  }
  
  render() {
    const { showEditDataFor, showDeleteDataFor, showPdfDataFor, invoiceData } = this.state;
    const selectedPeriod = (this.state && this.state.selectedPeriod) ? this.state.selectedPeriod : null;
    const selectedStartDate = (this.state && this.state.selectedStartDate) ? this.state.selectedStartDate : null;
    const selectedEndDate = (this.state && this.state.selectedEndDate) ? this.state.selectedEndDate : null;

    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={Invoices.title}
          description={
          <p>
          </p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                  <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                    <ButtonGroup>
                      <DatePicker
                        className="form-control date-picker-filter-input"
                        wrapperClassName="datePicker"
                        selected={selectedPeriod}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        onChange={(values) => {
                          const newStartDate = values && values[0] ? values[0] : null;
                          const newEndDate = values && values[1] ? values[1] : null;
                          this.selectDate(newStartDate, newEndDate);
                        }
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        isClearable
                        selectsRange
                      />
                    </ButtonGroup>

                    <ToggleButtonGroup
                      bsSize="small"
                      label="Issuer"
                      type="checkbox"
                      value={this.state.issuerName}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ issuerName: e });
                          this.handleReload("issuer_id", value);
                        }
                        else {
                          var prevState = this.state.issuerName;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ issuerName: value });
                          this.handleReload("issuer_id", value);
                        }
                      }}
                    >
                      <ToggleButton value={"6352910839f28166b1b99e40"}>APPMULTIPLE</ToggleButton>
                      <ToggleButton value={"6352911439f28166b1b99e41"}>NYDRI</ToggleButton>
                      <ToggleButton value={"6352911f39f28166b1b99e42"}>ADSTAMP</ToggleButton>
                      <ToggleButton value={"6352912639f28166b1b99e43"}>MINIMOB PTE</ToggleButton>
                      <ToggleButton value={"6352958b39f28166b1b99ea4"}>KAN</ToggleButton>
                      <ToggleButton value={"6352959139f28166b1b99ea5"}>SPINX</ToggleButton>
                      <ToggleButton value={"635a5b2f55fc474b24607509"}>ADSPIN</ToggleButton>
                    </ToggleButtonGroup>
                    <br /><br />

                    <ToggleButtonGroup
                      bsSize="small"
                      label="Account Manager"
                      type="checkbox"
                      value={this.state.amName}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ amName: e });
                          this.handleReload("salesman_id", value);
                        }
                        else {
                          var prevState = this.state.amName;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ amName: value });
                          this.handleReload("salesman_id", value);
                        }
                      }}
                    >
                      <ToggleButton value={"40"}>f.mpilis</ToggleButton>
                      <ToggleButton value={"24"}>a.gkoutzina</ToggleButton>
                      <ToggleButton value={"5"}>m.dimitropoulou</ToggleButton>
                      <ToggleButton value={"32"}>d.velonis</ToggleButton>
                      <ToggleButton value={"25"}>j.chua</ToggleButton>
                      <ToggleButton value={"4"}>a.dimitropoulos</ToggleButton>
                      <ToggleButton value={"61"}>m.namachivayam</ToggleButton>
                    </ToggleButtonGroup>
                  <ButtonGroup bsSize="small" className="pull-left">
                    <Button
                      bsStyle="primary"
                      onClick={this.handleAddClick.bind(this,user)}>
                      <i className="fa fa-plus"></i> Add Invoice
                    </Button>
                  </ButtonGroup>
                  </DataTable.Toolbar>
                  <br />
                  <Row>
                    <Col xs={12} sm={4} lg={2}>
                      <FormGroup>
                        <ControlLabel style={{ width: "100%" }}>
                          Customer
                        </ControlLabel>
                        <InvoiceRecipientPicker
                          ref={this.recipientRef}
                          className="form-control"
                          placeholder="Enter name"
                          onChange={(customerId, customerName) => {
                            this.setState({ customerId: customerId });
                            this.handleReload("customer_id", customerId)
                          }
                          }//this.selectCustomer(customerId,customerName)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped"
                  ref={this.tableRef}
                  ajax={{
                    url: this.getTableUrl('init'),
                    dataSrc: "items"
                  }}
                  onUserCommand={({ commandData }) => {
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    } else {
                      if (commandData) {
                          if (commandData.action === 'edit') {
                            this.setState({showEditDataFor: commandData});
                          }
                          if (commandData.action === 'delete') {
                            this.setState({showDeleteDataFor: commandData});
                          }
                          if (commandData.action === 'exportInvoiceToPdf') {
                            this.setState({ showPdfDataFor: commandData });
                            this.handlePdfClick(commandData.invoiceId);
                          }
                        }
                      }
                    }
                  }
                  columns={[
                    {
                      title: "Invoice ID",
                      data: "invoice_id",
                      width: "5%",
                      visible: false
                    },
                    {
                      title: "Issuer",
                      name: "issuer_id",
                      data: "issuer_id",
                      className: "truncate-cell-wide",
                      render: renderIssuerName
                    },
                    DataTable.createUserCommandColumn({
                      title: "Invoice No.",
                      data: "invoice_no",
                      width: "90px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        id: rowData._id,
                        invoiceNo: rowData.invoice_no,
                      })
                    }),
                    {
                      title: "Invoice Date",
                      data: "invoice_date",
                      width: "90px",
                      render: renderInvoiceDate
                    },
                    {
                      title: "Period",
                      data: "invoice_period",
                      name: "invoice_period",
                      render: renderPeriod
                    },
                    {
                      title: "Notes",
                      data: "notes",
                      width: "8%",
                    },
                    {
                      title: "Customer",
                      data: "customer_id",
                      width: "10%",
                      render: renderCustomerName
                    },
                    {
                      title: "External Id",
                      data: "customer_id",
                      width: "10%",
                      render: renderCustomerExternalId
                    },
                    {
                      title: "Account Manager",
                      name: "salesman_id",
                      data: "salesman_id",
                      render: renderAccountManagerName
                    },
                    {
                      title: "Exchange Rate",
                      data: "exchange_rate"
                    },                    
                    {
                      title: "G.Total $",
                      data: "",
                      className: "text-right",
                      width: "5%",
                      render: renderGtotalDollar
                    },
                    {
                      title: "Linked",
                      data: "is_linked",
                      className: "text-center",
                      width: "5%",
                      render: renderLinked
                    },
                    {
                      title: "Modified By",
                      data: "modified_by",
                      className: "text-center",
                      width: "5%"
                    },
                    {
                      title: "Modified At",
                      data: "modified_at",
                      className: "text-center",
                      width: "5%",
                      render: renderDateTime
                    },
                    /*
                    {
                      title: "Locked",
                      data: null,
                      className: "text-center",
                      width: "5%",
                      render: renderLocked
                    },
                    */
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "invoice_no",
                      className: "text-right no-padding",
                      width: "30px",
                      orderable: false,
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'exportInvoiceToPdf',
                        id: rowData._id,
                        invoiceId: rowData.invoice_id,
                        confirmedAmount: rowData.advertiser_confirmed_amount,
                        deductedAmount: 0, //Todo: new field 
                        currency:"USD",
                        currencySymbol:"$"
                      }),
                      render: (d, t, r, m) => (r.invoice_no !== null ? ("<div><button type='button' class='btn btn-default btn-small' style='width: 30px'> $ </button></div>"):"")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Invoice",
                      data: "invoice_no",
                      className: "text-center no-padding",
                      width: "30px",
                      orderable: false,
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'exportInvoiceToPdf',
                        id: rowData._id,
                        invoiceId: rowData.invoice_id,
                        confirmedAmount: rowData.advertiser_confirmed_amount,
                        deductedAmount: 0, //Todo: new field 
                        currency: rowData.currency !== "USD" ? rowData.currency: "EUR",
                        currencySymbol: rowData.currency_symbol !== "$" ? rowData.currency_symbol : "€"
                      }),
                      render: (d, t, r, m) => (r.invoice_no !== null ? ("<div><button type='button' class='btn btn-default btn-small' style='width: 30px' " + ((r.exchange_rate > 0) ? "" : "disabled") + "> " + (r.currency_symbol !== '$' ? r.currency_symbol :'€')+" </button></div>"):"")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: "invoice_no",
                      className: "no-padding",
                      width: "30px",
                      orderable: false,
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'exportInvoiceToPdf',
                        id: rowData._id,
                        invoiceId: rowData.invoice_id,
                        confirmedAmount: rowData.advertiser_confirmed_amount,
                        deductedAmount: 0, //Todo: new field 
                        currency:"USD",
                        currency2: rowData.currency !== "USD" ? rowData.currency : "EUR",
                        currencySymbol:"$",
                        currencySymbol2: rowData.currency_symbol !== "$" ? rowData.currency_symbol : "€",
                      }),
                      render: (d, t, r, m) => (r.invoice_no !== null ? ("<div><button type='button' class='btn btn-default btn-small' style='width: 36px' " + ((r.exchange_rate > 0) ? "" : "disabled") + "> $ " + (r.currency_symbol !== '$' ? r.currency_symbol : '€') +" </button></div>"):"")
                    }),
                    {
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "20px",
                      render: (d, t, r, m) => ( "<div></div>")
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        id: rowData._id,
                        title: rowData.title,
                        address: rowData.address,           
                        address2: rowData.address2,           
                        vatNo: rowData.vat_no,         
                        regNo: rowData.reg_no,       
                        companyDetails: rowData.companyDetails
                      }),
                      render: (d, t, r, m) => ( "<div></div>")
                      //render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Edit </button></div>")
                    }),
                    DataTable.createUserCommandColumn({
                      title: "Actions",
                      data: null,
                      className: "text-left",
                      orderable: false,
                      width: "30px",
                      defultContent: "",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'delete',
                        id: rowData._id,
                        invoiceNo: rowData.invoice_no||""
                      }),
                      render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'" + (((r !== null && r.exported === true && r.unlocked === false) || (!this.props.userIsAccountingAdmin) || (!allowDeleteInvoice(r.invoice_date)) || (r.credit_invoice_id) || (r.invoice_type_id.startsWith('CN-')) ) ? ' disabled' : '') + "> Create Credit Note </button></div>")
                    })
                  ]}
                  order={[[3, "desc"]]}
                    footerCallback={(tfoot, data, start, end, display) => {
                      const gt = {
                        gtTotalDollar: display.reduce((acc, cur) => {
                          if (!data[cur].invoice_no.startsWith('CN-')) {
                            acc = acc + data[cur].totalDollar;
                          }
                          else {
                            acc = acc - data[cur].totalDollar;
                          }
                          return acc;
                          
                        }
                          , 0),
                        gtTotalEuro: display.reduce((acc, cur) => (acc += data[cur].totalEuro), 0)
                      };
                    
                    
                    const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                    const footer = $(tfoot).parent();

                    // Table totals
                      footer.find("#gtgTotalDollar").html(renderMoney(gt.gtTotalDollar));
                      footer.find("#gtgTotalEuro").html(renderMoney(gt.gtTotalEuro));
                  }}
                >
                  <thead>
                  <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    {/*<th></th>*/}
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody></tbody>
                  <tfoot>
                  <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>                   
                    <td className="text-right">
                      Totals
                    </td>
                    <td className="text-right" id="gtgTotalDollar">0</td>
                        {/*<td className="text-right" id="gtgTotalEuro">0</td>*/}
                        <td></td>
                        <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tfoot>
                </DataTable>
                </Box.Body>
              <Box.Footer>
                <DownloadButton
                  onClick={this.handleDownload}
                  disabled={this.state.loading}
                  />
              </Box.Footer>
            </Box>
          )}
          </UserContext.Consumer>

          {/* Invoice html */}
          {invoiceData && (
            <InvoiceHtml
              invoiceData={invoiceData || null}
              showPdfDataFor={showPdfDataFor || null}
            />
          )}
          
        </PageLayout>
        {this.state.showAdd && (
          <AddDialog   
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showAdd: false 
              })
            }}
          />
        )}

        {showEditDataFor && (
          <AddDialog
            action={'edit'}
            id={showEditDataFor.id}
            invoiceNo={showEditDataFor.invoiceNo}
            //onPrintPdfModal={this.onPrintPdfModal}
            onHide={() => {
              this.setState({
                showEdit: false,
                showEditDataFor: null
              })
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showEdit: false, 
                  showEditDataFor: null
                })
              );
            }}
          />
        )}

        {showDeleteDataFor && (
          <DeleteDialog
            id={showDeleteDataFor.id}
            invoiceNo={showDeleteDataFor.invoiceNo}
            title="Delete Invoice"
            show={true}
            onHide={() => {
              this.setState({ showDeleteDataFor: null });
              this.tableRef.current.reload();
            }}
          />
        )}
      </>
    );
  }
  
  /*onPrintPdfModal(invoiceId,currency){
    var showPdfDataFor = {
      action: 'exportInvoiceToPdf',
      id: null,
      invoiceId: invoiceId,
      currency:"USD",
      currencySymbol:"$"
    }
    if(currency === 'EUR'){
      showPdfDataFor.currency = "EUR";
      showPdfDataFor.currencySymbol = "€";
    }
    if(currency === 'USD-EUR'){
      showPdfDataFor.currency = "USD";
      showPdfDataFor.currency2 = "EUR";
      showPdfDataFor.currencySymbol = "$";
      showPdfDataFor.currencySymbol2 = "€";
    }
    this.setState(showPdfDataFor);
    this.handlePdfClick(invoiceId);
  }*/
  
  handlePdfClick(invoiceId){
    this.getInvoice(invoiceId);
  }

  getInvoice(invoiceId) {
    var _this = this;
    this.setState({
      invoiceData: null,
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/getinvoice",
      method: "get",
      params: {id:invoiceId||null}
    })
      .then(res => {
        var resData = res && res.data ? res.data : null;

        var invoiceLines = (resData.invoice_lines||[]).map((line,idx)=>{
          line.unitPrice = line.unit_price;
          delete line.unit_price;
          return line;
        });

        var invoiceData = null;

        if(resData){
          invoiceData = {
            id: resData._id || null,
            issuerId: resData.issuer_id||null,
            issuerTitle: resData.issuer_title||null,
            issuerAddress: resData.issuer_address||null,
            issuerAddress2: resData.issuer_address2||null,
            issuerRegNo: resData.issuer_reg_no||null,
            issuerVatNo: resData.issuer_vat_no||null,
            recipientId: resData.recipient_id||null,
            recipientTitle: resData.recipient_title||null,
            recipientAddress: resData.recipient_address||null,
            recipientAddress2: resData.recipient_address2||null,
            recipientRegNo: resData.recipient_reg_no||null,
            recipientVatNo: resData.recipient_vat_no || null,
            recipientCurrency: resData.recipient_currency || null,
            accountManagerId: resData.account_manager_id!==null?parseInt(resData.account_manager_id):null,
            accountManagerTitle: resData.account_manager_title||null,
            invoiceNo: resData.invoice_no||null,
            invoiceDate: resData.invoice_date||null,
            invoicePeriod:  resData.invoice_period_from?moment(resData.invoice_period_from).month()+1+"/"+moment(resData.invoice_period_from).year():null,
            //invoicePeriodFrom: resData.invoice_period_from||null,
            invoicePeriodTo: resData.invoice_period_to||null,
            invoiceTypeId: resData.invoice_type_id||null,
            accountCategoryId: resData.account_category_id!==null?parseInt(resData.account_category_id):null,
            accountCode: resData.account_code||null,
            interCompany: resData.inter_company===true?true:false,
            notes: resData.notes || null,
            invoiceText: resData.invoice_text || null,
            exchangeRate: resData.exchange_rate?resData.exchange_rate:0,
            taxRate: resData.tax_rate?resData.tax_rate:0,
            unlocked: resData.unlocked===true?true:false,
            exported: resData.exported===true?true:false,
            invoiceLines: invoiceLines||[],
            busy: false,
            message: null,
            error: null
          }
        }

        this.setState({invoiceData:invoiceData},
          () => {
            //this.getInvoiceTypes();

            var doc = new jsPDF('p', 'px', [595, 842]); //2480 x 3508 //595 x 842
            //var doc = new jsPDF('p', 'mm', 'a4'); 

            var pdfjs = document.querySelector('#invoice-html');

            // Convert HTML to PDF in JavaScript
            doc.html(pdfjs, {
              callback: function(doc) {
                doc.save("invoice_no_"+(_this.state?.invoiceData?.invoiceNo||"")+".pdf");
              },
              x: 40,
              y: 40
            });
          });

      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
  
  selectInvoicePeriod(date) {
    var columnName = "invoice_period";
    if(date){
      this.tableRef.current.setColumnFilter(`${columnName}:name`, date);
    }
    else{
      this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
    }
    this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
  }
  
  handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({
        showAdd: true
      });
    }
  }

  selectDate(newStartDate, newEndDate, isInit) {
    this.setState({ selectedStartDate: newStartDate }, () => {
      this.setState({ selectedEndDate: newEndDate }, () => {
        this.handleReload("selectedPeriod", [newStartDate, newEndDate]);
        this.setState({ selectedDate: newStartDate }, () => {
          if (this.tableRef?.current) {
            if (!isInit) {
              this.tableRef.current.reload();
            }
          }
        });
      });
    });
  }

  getSelectedDate(){
    var selectedDate = this.state.selectedDate;
    if(selectedDate && selectedDate.length===2){
      var dateFrom = moment.utc(selectedDate[0]).format("YYYY-MM-DD");
      var dateTo = moment.utc(selectedDate[1]).format("YYYY-MM-DD");
      return [dateFrom,dateTo];
    }
    else {
      return [selectedDate,selectedDate];
    }
  }

  selectIssuerToggle(issuerName) {
    var columnName = "issuer_id";
    if (issuerName) {
      this.tableRef.current.setColumnFilter(`${columnName}:name`, issuerName);
    }
    else {
      this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
    }
    this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
  }

  selectAccountManagerToggle(amName) {
    var columnName = "salesman_id";
    if (amName) {
      this.tableRef.current.setColumnFilter(`${columnName}:name`, amName);
    }
    else {
      this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
    }
    this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
  }

  handleDownload() {
    this.setState({ busy: true, error: null });
    axios({
      url: this.getTableExportUrl('init'),
      method: "get",
      responseType: "blob"
    })
      .then(res => {
        this.setState({ busy: false, error: null });
        const filename = extractFilenameFromResponse(res);
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }

  constructTableUrl(name, e) {
    //var isInit = name=="init"?true:false;
    var selectedStartDate = (name === "selectedStartDate") ? e : this.state.selectedStartDate ? this.state.selectedStartDate : null;
    var selectedEndDate = (name === "selectedEndDate") ? e : this.state.selectedEndDate ? this.state.selectedEndDate : null;

    var issuer_id = (name === "issuer_id") ? e : (this.state.issuerName ? this.state.issuerName[0] : "");
    var salesman_id = (name === "salesman_id") ? e : (this.state.amName ? this.state.amName[0] : "");
    var customer_id = (name === "customer_id") ? e : (this.state.customerId);
    var qs0 = (selectedStartDate ? "selectedStartDate=" + (selectedStartDate.getFullYear() + '-' + ((selectedStartDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs1 = (selectedEndDate ? "selectedEndDate=" + (selectedEndDate.getFullYear() + '-' + ((selectedEndDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs2 = (customer_id ? "customer_id=" + customer_id : "");
    var qs3 = (issuer_id ? "issuer_id=" + issuer_id : "");
    var qs4 = (salesman_id ? "salesman_id=" + salesman_id : "");

    var qs = "";
    if (qs0.length || qs1.length || qs2.length || qs3.length || qs4.length ) {
      qs = "?" + (qs0.length ? "&" + qs0 : "") + (qs1.length ? "&" + qs1 : "") + (qs2.length ? "&" + qs2 : "") + (qs3.length ? "&" + qs3 : "") + (qs4.length ? "&" + qs4 : "");
    }

    var newUrl = this.state.tableUrl + qs;

    return newUrl;
  }


  constructTableExportUrl(name, e) {
    //var isInit = name=="init"?true:false;
    var selectedStartDate = (name === "selectedStartDate") ? e : this.state.selectedStartDate ? this.state.selectedStartDate : null;
    var selectedEndDate = (name === "selectedEndDate") ? e : this.state.selectedEndDate ? this.state.selectedEndDate : null;
    var issuer_id = (name === "issuer_id") ? e : (this.state.issuerName ? this.state.issuerName[0] : "");
    var salesman_id = (name === "salesman_id") ? e : (this.state.amName ? this.state.amName[0] : "");
    var customer_id = (name === "customer_id") ? e : (this.state.customerId);
    var qs0 = (selectedStartDate ? "selectedStartDate=" + (selectedStartDate.getFullYear() + '-' + ((selectedStartDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs1 = (selectedEndDate ? "selectedEndDate=" + (selectedEndDate.getFullYear() + '-' + ((selectedEndDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs2 = (customer_id ? "customer_id=" + customer_id : "");
    var qs3 = (issuer_id ? "issuer_id=" + issuer_id : "");
    var qs4 = (salesman_id ? "salesman_id=" + salesman_id : "");

    var qs = "";
    if (qs0.length || qs1.length || qs2.length || qs3.length || qs4.length) {
      qs = "?" + (qs0.length ? "&" + qs0 : "") + (qs1.length ? "&" + qs1 : "") + (qs2.length ? "&" + qs2 : "") + (qs3.length ? "&" + qs3 : "") + (qs4.length ? "&" + qs4 : "");
    }

    var newUrl = '/api/v1/tools/invoicesexport' + qs;

    return newUrl;
  }

  handleReload(name, e) {
    var newUrl = this.constructTableUrl(name, e);
    this.tableRef.current.setNewUrl(newUrl);
  }

  getTableUrl(init) {
    return this.constructTableUrl(init);
  }

  getTableExportUrl(init) {
    return this.constructTableExportUrl(init);
  }
}
