import React from "react"

export default function (error) {
  if (typeof error === "string") {
    let matches = null;

    if (Array.isArray(matches = error.match(/^"(.*)"$/))) {
      return matches[1]
    }
    else if (Array.isArray(matches = error.match(/^({.*})$/))) {
      error = JSON.parse(matches[1])
    }
    else {
      return error
    }
  }

  if (typeof error === "object" && error != null) {
    // The .NET ObjectState is an object whose keys are either an empty string
    // or the name of a property and its values are arrays of error messages.
    // {
    //     "": [
    //         "This a global error."
    //     ],
    //     "Field1": [
    //         "This is an error about Field1.",
    //         "This is an error about Field1 and Field2."
    //     ],
    //     "Field2": [
    //         "This is an error about Field1 and Field2."
    //     ]
    // }
    // That's why we need to flatten the array and take only distinct values.
    const flattenedValues = Object.values(error).reduce((acc, val) => acc.concat(val), [])
    const flattenedDistinctValues = Array.from(new Set(flattenedValues))

    return (
      <ul>
        {flattenedDistinctValues.map((x, i) => (
          <li key={i}>{x}</li>
        ))}
      </ul>
    )
  }

  return null
}