import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { ButtonGroup, Button, Row, Col, ControlLabel, FormGroup, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { InvoiceRecipientPicker } from "components/pickers/SelectPickers";
import { UserContext } from "lib/user";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import axios from "axios";
import jsPDF from "jspdf"; 
import { extractFilenameFromResponse, N} from "lib/Functions";
import download from "downloadjs";
import DownloadButton from "components/stock/DownloadButton";
import "./styles.css";

function renderIssuerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null)
      return m.settings.json.issuerNames[d] || d;
    else
      return '';
  }
  return d;
}

function renderCustomerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if(d!==null)
      return m.settings.json.customerNames[d] || d;
    else
      return '';
  }
  return d;
}

function renderCustomerExternalId(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null)
      return m.settings.json.externalIds[d] || '';
    else
      return '';
  }
  return d;
}

function renderGtotalDollar(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    var total = 0;
    if(r!==null && r.invoice_lines!==null){
      (r.invoice_lines||[]).forEach((invoiceLine, idx) =>{
        if(invoiceLine.quantity!==null && invoiceLine.unit_price!==null){
          total += (invoiceLine.quantity * invoiceLine.unit_price) + (invoiceLine.quantity * invoiceLine.unit_price * (r.tax_rate/100));
        }
      });
      return N(total,2);
    }     
  }
  return '';
}

function renderN(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null && d !== undefined)
      return N(d, 2);
    else
      return '';
  }
  return d;
}

export default class Balances extends React.Component {
  static title = "Balances";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      error: null,
      showPdfDataFor: null,
      tableUrl: "/api/v1/tools/balances"
    };

    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
  }

  render() {
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={Balances.title}
          description={
            <p>
            </p>
          }
        >
          <UserContext.Consumer>
            {user => (
              <Box>
                <Box.Header>
                  <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Issuer"
                      type="checkbox"
                      value={this.state.issuerName}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ issuerName: e });
                          this.handleReload("issuer_id", value);
                        }
                        else {
                          var prevState = this.state.issuerName;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ issuerName: value });
                          this.handleReload("issuer_id", value);
                        }
                      }}
                    >
                      <ToggleButton value={"6352910839f28166b1b99e40"}>APPMULTIPLE</ToggleButton>
                      <ToggleButton value={"6352911439f28166b1b99e41"}>NYDRI</ToggleButton>
                      <ToggleButton value={"6352911f39f28166b1b99e42"}>ADSTAMP</ToggleButton>
                      <ToggleButton value={"6352912639f28166b1b99e43"}>MINIMOB PTE</ToggleButton>
                      <ToggleButton value={"6352958b39f28166b1b99ea4"}>KAN</ToggleButton>
                      <ToggleButton value={"6352959139f28166b1b99ea5"}>SPINX</ToggleButton>
                      <ToggleButton value={"635a5b2f55fc474b24607509"}>ADSPIN</ToggleButton>
                    </ToggleButtonGroup>
                  </DataTable.Toolbar>
                  <br />
                  <Row>
                    <Col xs={12} sm={4} lg={2}>
                      <FormGroup>
                        <ControlLabel style={{ width: "100%" }}>
                          Customer
                        </ControlLabel>
                        <InvoiceRecipientPicker
                          ref={this.recipientRef}
                          className="form-control"
                          placeholder="Enter name"
                          onChange={(customerId, customerName) => {
                            this.setState({ customerId: customerId });
                            this.handleReload("customer_id", customerId)
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Box.Header>
                <Box.Body>
                  <DataTable
                    className="table table-condensed table-striped"
                    ref={this.tableRef}
                    ajax={{
                      url: this.getTableUrl('init'),
                      dataSrc: "items"
                    }}
                    onUserCommand={({ commandData }) => {
                      if (user.tokenHasExpired) {
                        window.location.replace('/login');
                        return;
                      } else {
                        if (commandData) {
                          if (commandData.action === 'edit') {
                            this.setState({ showEditDataFor: commandData });
                          }
                          if (commandData.action === 'delete') {
                            this.setState({ showDeleteDataFor: commandData });
                          }
                          if (commandData.action === 'exportTransactionToPdf') {
                            this.setState({ showPdfDataFor: commandData });
                            this.handlePdfClick(commandData.transactionId);
                          }
                        }
                      }
                    }
                    }
                    columns={[
                      {
                        title: "Issuer",
                        name: "issuer_id",
                        data: "issuer_id",
                        className: "truncate-cell-wide",
                        render: renderIssuerName
                      },
                      {
                        title: "Customer",
                        data: "customer_id",
                        width: "15%",
                        render: renderCustomerName
                      },
                      {
                        title: "Balance + Uninvoiced",
                        data: "balance_uninvoiced",
                        className: "text-right",
                        width: "8%",
                        render: renderN
                      },
                      {
                        title: "Balance",
                        data: "balance",
                        className: "text-right",
                        width: "8%",
                        render: renderN
                      },                      
                      {
                        title: "Overdue Amount",
                        data: "overdue_amount",
                        className: "text-right",
                        width: "8%",
                        render: renderN
                      },
                      {
                        title: "Max Credit Amount",
                        data: "max_credit_amount",
                        className: "text-right",
                        width: "8%",
                        render: renderN
                      },
                      {
                        title: "",
                        data: null,
                        className: "text-right",
                        orderable: false,
                        defultContent: "",
                        width: "200px",
                        render: (d, t, r, m) => ("<div></div>")
                      }
                    ]}
                    order={[[3, "asc"]]}
                    footerCallback={(tfoot, data, start, end, display) => {
                      const gt = {
                        gtTotalBalance: display.reduce((acc, cur) => (acc += data[cur].balance), 0),
                        gtTotalBalanceUninvoiced: display.reduce((acc, cur) => (acc += data[cur].balance_uninvoiced), 0),
                        gtTotalOverdueAmount: display.reduce((acc, cur) => (acc += data[cur].overdue_amount), 0)
                      };
                      const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                      const footer = $(tfoot).parent();

                      // Table totals
                      footer.find("#gtTotalBalance").html(renderMoney(gt.gtTotalBalance));
                      footer.find("#gtTotalBalanceUninvoiced").html(renderMoney(gt.gtTotalBalanceUninvoiced));
                      footer.find("#gtTotalOverdueAmount").html(renderMoney(gt.gtTotalOverdueAmount));
                    }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td className="text-right">
                          Totals
                        <td></td>
                        <td></td>
                        <td></td>
                        </td>
                        <td className="text-right" id="gtTotalBalanceUninvoiced">0</td>
                        <td className="text-right" id="gtTotalBalance">0</td>
                        <td className="text-right" id="gtTotalOverdueAmount">0</td>
                      </tr>
                    </tfoot>
                  </DataTable>
                </Box.Body>
                <Box.Footer>
                  <DownloadButton
                    onClick={this.handleDownload}
                    disabled={this.state.loading}
                  />
                </Box.Footer>
              </Box>
            )}
          </UserContext.Consumer>
        </PageLayout>
      </>
    );
  }

  handleDownload() {
    this.setState({ busy: true, error: null });
    axios({
      url: this.getTableExportUrl('init'),
      method: "get",
      responseType: "blob"
    })
      .then(res => {
        this.setState({ busy: false, error: null });
        const filename = extractFilenameFromResponse(res);
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }

  constructTableUrl(name, e) {
    var customer_id = (name === "customer_id") ? e : (this.state.customerId);
    var issuer_id = (name === "issuer_id") ? e : (this.state.issuerName ? this.state.issuerName[0] : "");
    var qs1 = (customer_id ? "customer_id=" + customer_id : "");
    var qs2 = (issuer_id ? "issuer_id=" + issuer_id : "");

    var qs = "";
    if (qs1.length || qs2.length) {
      qs = "?" + (qs1.length ? "&" + qs1 : "") + (qs2.length ? "&" + qs2 : "");
    }
    var newUrl = this.state.tableUrl + qs;

    return newUrl;
  }

  constructTableExportUrl(name, e) {
    var customer_id = (name === "customer_id") ? e : (this.state.customerId);
    var issuer_id = (name === "issuer_id") ? e : (this.state.issuerName ? this.state.issuerName[0] : "");
    var qs1 = (customer_id ? "customer_id=" + customer_id : "");
    var qs2 = (issuer_id ? "issuer_id=" + issuer_id : "");

    var qs = "";
    if (qs1.length || qs2.length) {
      qs = "?" + qs1 + (qs2.length ? "&" + qs2 : "");
    }
    var newUrl = '/api/v1/tools/balancesexport' + qs;

    return newUrl;
  }

  handleReload(name, e) {
      var newUrl = this.constructTableUrl(name, e);
      this.tableRef.current.setNewUrl(newUrl);
    }

  getTableUrl(init) {
      return this.constructTableUrl(init);
    }

  getTableExportUrl(init) {
      return this.constructTableExportUrl(init);
    }

}
