import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { ButtonGroup, Button, Row, Col, ControlLabel, FormGroup, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { InvoiceRecipientPicker } from "components/pickers/SelectPickers";
import AddDialog from "./AddDialog";
import InvoiceHtml from "./InvoiceHtml";
import { UserContext } from "lib/user";
import moment from "moment-timezone";
//import DateRangePicker from "components/pickers/DateRangePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import DeleteDialog from "./DeleteDialog";
import axios from "axios";
import jsPDF from "jspdf"; 
import { extractFilenameFromResponse, N} from "lib/Functions";
import download from "downloadjs";
import DownloadButton from "components/stock/DownloadButton";
import "./styles.css";

//status: //1: pending, 2: complete
const Statuses = {
  1: "Pending",
  2: "Complete"
};

//transactionTypes: //1: payment, 2:prepayment, 3:bonus
const TransactionTypes = {
  1: "Payment",
  2: "Prepayment",
  3: "Bonus",
  4: 'Offset'
};

function renderStatus(d, t, r, m) {
  if (t === "display") {
    if (d !== null)
      return Statuses[d];
    else
      return '';
  }
  return d;
}

function renderTransactionType(d, t, r, m) {
  if (t === "display") {
    if (d !== null)
      return TransactionTypes[d];
    else
      return '';
  }
  return d;
}

function renderPeriod(d, t, r, m) {
 /* if (d && (t === "display" || t === "filter")) {
    return moment.utc(d).format("MM/YYYY");
  }*/
  return d;
}

function renderBankName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null)
      return m.settings.json.bankNames[d] || d;
    else
      return '';
  }
  return d;
}

function renderIssuerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null)
      return m.settings.json.issuerNames[d] || d;
    else
      return '';
  }
  return d;
}

function renderTransactionDate(d, t, r, m) {
  if (d && (t === "display" || t === "filter")) {
    return moment.utc(d).format("DD/MM/YYYY");
  }
  return d;
}

function renderCustomerName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if(d!==null)
      return m.settings.json.customerNames[d] || d;
    else
      return '';
  }
  return d;
}

function renderCustomerExternalId(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null)
      return m.settings.json.externalIds[d] || '';
    else
      return '';
  }
  return d;
}

function renderGtotalDollar(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    var total = 0;
    if(r!==null && r.invoice_lines!==null){
      (r.invoice_lines||[]).forEach((invoiceLine, idx) =>{
        if(invoiceLine.quantity!==null && invoiceLine.unit_price!==null){
          total += (invoiceLine.quantity * invoiceLine.unit_price) + (invoiceLine.quantity * invoiceLine.unit_price * (r.tax_rate/100));
        }
      });
      return N(total,2);
    }     
  }
  return '';
}

function renderN(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    if (d !== null && d !== undefined)
      return N(d, 2);
    else
      return '';
  }
  return d;
}

export default class Transactions extends React.Component {
  static title = "Transactions";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      showAdd: false,
      busy: false,
      error: null,
      showEditDataFor: null,
      showDeleteDataFor: null,
      showPdfDataFor: null,
      transactionData: null,
      selectedDate: [null, null],
      selectedDateFrom: null,
      selectedDateTo: null,
      defaultInvoicePeriodValue: moment().subtract(1, "month").format('MM/YYYY'),
      tableUrl: "/api/v1/tools/transactions",
      selectedPeriod: new Date((new Date()).setMonth(new Date().getMonth())),
      selectedStartDate: new Date((new Date()).setMonth(new Date().getMonth() - 1)),
      selectedEndDate: new Date((new Date()).setMonth(new Date().getMonth() - 1))
    };

    this.handlePdfClick = this.handlePdfClick.bind(this);
    this.getTransaction = this.getTransaction.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
  }

  render() {
    const { showEditDataFor, showDeleteDataFor, showPdfDataFor, transactionData } = this.state;
    const selectedPeriod = (this.state && this.state.selectedPeriod) ? this.state.selectedPeriod : null;
    const selectedStartDate = (this.state && this.state.selectedStartDate) ? this.state.selectedStartDate : null;
    const selectedEndDate = (this.state && this.state.selectedEndDate) ? this.state.selectedEndDate : null;

    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={Transactions.title}
          description={
            <p>
            </p>
          }
        >
          <UserContext.Consumer>
            {user => (
              <Box>
                <Box.Header>
                  <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                    <ButtonGroup>
                      <DatePicker
                        className="form-control date-picker-filter-input"
                        wrapperClassName="datePicker"
                        selected={selectedPeriod}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        onChange={(values) => {
                          const newStartDate = values && values[0] ? values[0] : null;
                          const newEndDate = values && values[1] ? values[1] : null;
                          this.selectDate(newStartDate, newEndDate);
                        }
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        isClearable
                        selectsRange
                      />
                    </ButtonGroup>
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Issuer"
                      type="checkbox"
                      value={this.state.issuerName}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ issuerName: e });
                          this.handleReload("issuer_id", value);
                        }
                        else {
                          var prevState = this.state.issuerName;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ issuerName: value });
                          this.handleReload("issuer_id", value);
                        }
                      }}
                    >
                      <ToggleButton value={"6352910839f28166b1b99e40"}>APPMULTIPLE</ToggleButton>
                      <ToggleButton value={"6352911439f28166b1b99e41"}>NYDRI</ToggleButton>
                      <ToggleButton value={"6352911f39f28166b1b99e42"}>ADSTAMP</ToggleButton>
                      <ToggleButton value={"6352912639f28166b1b99e43"}>MINIMOB PTE</ToggleButton>
                      <ToggleButton value={"6352958b39f28166b1b99ea4"}>KAN</ToggleButton>
                      <ToggleButton value={"6352959139f28166b1b99ea5"}>SPINX</ToggleButton>
                      <ToggleButton value={"635a5b2f55fc474b24607509"}>ADSPIN</ToggleButton>
                    </ToggleButtonGroup>
                    <br /><br />
                    <ToggleButtonGroup
                      bsSize="small"
                      label="Bank"
                      type="checkbox"
                      value={this.state.bankName}
                      onChange={(e) => {
                        var value = e;
                        if (value.length === 0) {
                          this.setState({ bankName: e });
                          this.handleReload("bank_id", value);
                        }
                        else {
                          var prevState = this.state.bankName;
                          if (prevState && prevState.length > 0) {
                            value = e.filter(function (val) { return val !== prevState[0] })
                          }
                          this.setState({ bankName: value });
                          this.handleReload("bank_id", value);
                        }
                      }}
                    >
                      <ToggleButton value={"63f36981ac04121f785b898e"}>Paypal</ToggleButton>
                      <ToggleButton value={"63f36a09ac04121f785b8992"}>Payoneer</ToggleButton>
                      <ToggleButton value={"63fded2939f28166b1df072a"}>Commerce</ToggleButton>
                      <ToggleButton value={"63fded4239f28166b1df0731"}>DBS</ToggleButton>
                      <ToggleButton value={"63fded5039f28166b1df0732"}>UOB</ToggleButton>
                      <ToggleButton value={"63fded5939f28166b1df0733"}>Optima</ToggleButton>
                    </ToggleButtonGroup>
                    <ButtonGroup bsSize="small" className="pull-left">
                      <Button
                        bsStyle="primary"
                        onClick={this.handleAddClick.bind(this, user)}>
                        <i className="fa fa-plus"></i> Add Transaction
                      </Button>
                    </ButtonGroup>
                  </DataTable.Toolbar>
                  <br />
                  <Row>
                    <Col xs={12} sm={4} lg={2}>
                      <FormGroup>
                        <ControlLabel style={{ width: "100%" }}>
                          Customer
                        </ControlLabel>
                        <InvoiceRecipientPicker
                          ref={this.recipientRef}
                          className="form-control"
                          placeholder="Enter name"
                          onChange={(customerId, customerName) => this.handleReload("customer_id", customerId)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Box.Header>
                <Box.Body>
                  <DataTable
                    className="table table-condensed table-striped"
                    ref={this.tableRef}
                    ajax={{
                      url: this.getTableUrl('init'),
                      dataSrc: "items"
                    }}
                    onUserCommand={({ commandData }) => {
                      if (user.tokenHasExpired) {
                        window.location.replace('/login');
                        return;
                      } else {
                        if (commandData) {
                          if (commandData.action === 'edit') {
                            this.setState({ showEditDataFor: commandData });
                          }
                          if (commandData.action === 'delete') {
                            this.setState({ showDeleteDataFor: commandData });
                          }
                          if (commandData.action === 'exportTransactionToPdf') {
                            this.setState({ showPdfDataFor: commandData });
                            this.handlePdfClick(commandData.transactionId);
                          }
                        }
                      }
                    }
                    }
                    columns={[
                      {
                        title: "Transaction ID",
                        data: "_id",
                        width: "5%",
                        visible: false
                      },
                      {
                        title: "Transaction Date",
                        data: "transaction_date",
                        width: "8%",
                        render: renderTransactionDate
                      },
                      {
                        title: "Bank",
                        name: "bank_id",
                        data: "bank_id",
                        className: "truncate-cell-wide",
                        render: renderBankName
                      },
                      {
                        title: "Issuer",
                        name: "issuer_id",
                        data: "issuer_id",
                        className: "truncate-cell-wide",
                        render: renderIssuerName
                      },
                      {
                        title: "Customer",
                        data: "customer_id",
                        width: "10%",
                        render: renderCustomerName
                      },
                      {
                        title: "Amount",
                        data: "amount",
                        className: "text-right",
                        width: "8%",
                        render: renderN
                      },
                      {
                        title: "Currency",
                        data: "currency",
                        width: "8%",
                      },
                      {
                        title: "Exchange Rate",
                        data: "exchange_rate",
                        width: "8%",
                      },
                      {
                        title: "Transaction Type",
                        data: "transaction_type",
                        name: "transaction_type",
                        className: "text-center",
                        width: "8%",
                        render: renderTransactionType
                      },
                      {
                        title: "Status",
                        data: "status",
                        name: "status",
                        className: "text-center",
                        width: "8%",
                        render: renderStatus
                      },
                      {
                        title: "Notes",
                        data: "notes",
                        width: "8%",
                      },
                      {
                        title: "",
                        data: null,
                        className: "text-right",
                        orderable: false,
                        defultContent: "",
                        width: "20px",
                        render: (d, t, r, m) => ("<div></div>")
                      },
                      DataTable.createUserCommandColumn({
                        title: "",
                        data: null,
                        className: "text-right",
                        orderable: false,
                        defultContent: "",
                        width: "30px",
                        commandName: "",
                        commandData: ({ rowData }) => ({
                          action: 'edit',
                          id: rowData._id,
                        }),
                        render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'>Edit</button></div>")
                      }),
                      DataTable.createUserCommandColumn({
                        title: "Actions",
                        data: null,
                        className: "text-left",
                        orderable: false,
                        width: "30px",
                        defultContent: "",
                        commandName: "",
                        commandData: ({ rowData }) => ({
                          action: 'delete',
                          id: rowData._id
                        }),
                        render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> Delete </button></div>")
                      })
                    ]}
                    order={[[1, "desc"]]}
                    footerCallback={(tfoot, data, start, end, display) => {
                      const gt = {
                        gtTotalDollar: display.reduce((acc, cur) => (acc += data[cur].amount), 0)
                      };
                      const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                      const footer = $(tfoot).parent();

                      // Table totals
                      footer.find("#gtgTotalDollar").html(renderMoney(gt.gtTotalDollar));
                    }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right">
                          Totals
                        </td>
                        <td className="text-right" id="gtgTotalDollar">0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </DataTable>
                </Box.Body>
                <Box.Footer>
                  <DownloadButton
                    onClick={this.handleDownload}
                    disabled={this.state.loading}
                  />
                </Box.Footer>
              </Box>
            )}
          </UserContext.Consumer>

          {/* Invoice html */}
          {transactionData && (
            <InvoiceHtml
              transactionData={transactionData || null}
              showPdfDataFor={showPdfDataFor || null}
            />
          )}

        </PageLayout>
        {this.state.showAdd && (
          <AddDialog
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showAdd: false
              })
            }}
          />
        )}

        {showEditDataFor && (
          <AddDialog
            action={'edit'}
            id={showEditDataFor.id}
            onHide={() => {
              this.setState({
                showEdit: false,
                showEditDataFor: null
              })
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showEdit: false,
                  showEditDataFor: null
                })
              );
            }}
          />
        )}

        {showDeleteDataFor && (
          <DeleteDialog
            id={showDeleteDataFor.id}
            invoiceNo={showDeleteDataFor.invoiceNo}
            title="Delete Transaction"
            show={true}
            onHide={() => {
              this.setState({ showDeleteDataFor: null });
              this.tableRef.current.reload();
            }}
          />
        )}
      </>
    );
  }

  handlePdfClick(transactionId) {
    this.getTransaction(transactionId);
  }

  getTransaction(transactionId) {
    var _this = this;
    this.setState({
      transactionData: null,
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/gettransaction",
      method: "get",
      params: { id: transactionId || null }
    })
      .then(res => {
        var resData = res && res.data ? res.data : null;

        var invoiceLines = (resData.invoice_lines || []).map((line, idx) => {
          line.unitPrice = line.unit_price;
          delete line.unit_price;
          return line;
        });

        var transactionData = null;

        if (resData) {
          transactionData = {
            id: resData._id || null,
            issuerId: resData.issuer_id || null,
            issuerTitle: resData.issuer_title || null,
            issuerAddress: resData.issuer_address || null,
            issuerAddress2: resData.issuer_address2 || null,
            issuerRegNo: resData.issuer_reg_no || null,
            issuerVatNo: resData.issuer_vat_no || null,
            recipientId: resData.recipient_id || null,
            recipientTitle: resData.recipient_title || null,
            recipientAddress: resData.recipient_address || null,
            recipientAddress2: resData.recipient_address2 || null,
            recipientRegNo: resData.recipient_reg_no || null,
            recipientVatNo: resData.recipient_vat_no || null,
            recipientCurrency: resData.recipient_currency || null,
            accountManagerId: resData.account_manager_id !== null ? parseInt(resData.account_manager_id) : null,
            accountManagerTitle: resData.account_manager_title || null,
            invoiceNo: resData.invoice_no || null,
            invoiceDate: resData.invoice_date || null,
            invoicePeriod: resData.invoice_period_from ? moment(resData.invoice_period_from).month() + 1 + "/" + moment(resData.invoice_period_from).year() : null,
            //invoicePeriodFrom: resData.invoice_period_from||null,
            invoicePeriodTo: resData.invoice_period_to || null,
            invoiceTypeId: resData.invoice_type_id || null,
            accountCategoryId: resData.account_category_id !== null ? parseInt(resData.account_category_id) : null,
            accountCode: resData.account_code || null,
            interCompany: resData.inter_company === true ? true : false,
            notes: resData.notes || null,
            invoiceText: resData.invoice_text || null,
            exchangeRate: resData.exchange_rate ? resData.exchange_rate : 0.000,
            taxRate: resData.tax_rate ? resData.tax_rate : 0,
            unlocked: resData.unlocked === true ? true : false,
            exported: resData.exported === true ? true : false,
            invoiceLines: invoiceLines || [],
            busy: false,
            message: null,
            error: null
          }
        }

        this.setState({ transactionData: transactionData },
          () => {

            var doc = new jsPDF('p', 'px', [595, 842]); //2480 x 3508 //595 x 842
            var pdfjs = document.querySelector('#invoice-html');

            // Convert HTML to PDF in JavaScript
            doc.html(pdfjs, {
              callback: function (doc) {
                doc.save("invoice_no_" + (_this.state?.transactionData?.invoiceNo || "") + ".pdf");
              },
              x: 40,
              y: 40
            });
          });

      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }

  selectInvoicePeriod(date) {
    var columnName = "invoice_period";
    if (date) {
      this.tableRef.current.setColumnFilter(`${columnName}:name`, date);
    }
    else {
      this.tableRef.current.setColumnFilter(`${columnName}:name`, "");
    }
    this.tableRef.current.api.cells(null, `${columnName}:name`).invalidate().draw();
  }

  handleAddClick(user, e) {
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({
        showAdd: true
      });
    }
  }

  selectDate(newStartDate, newEndDate, isInit) {
    this.setState({ selectedStartDate: newStartDate }, () => {
      this.setState({ selectedEndDate: newEndDate }, () => {
        this.handleReload("selectedPeriod", [newStartDate, newEndDate]);
        this.setState({ selectedDate: newStartDate }, () => {
          if (this.tableRef?.current) {
            if (!isInit) {
              this.tableRef.current.reload();
            }
          }
        });
      });
    });
  }

  getSelectedDate() {
    var selectedDate = this.state.selectedDate;
    if (selectedDate && selectedDate.length === 2) {
      var dateFrom = moment.utc(selectedDate[0]).format("YYYY-MM-DD");
      var dateTo = moment.utc(selectedDate[1]).format("YYYY-MM-DD");
      return [dateFrom, dateTo];
    }
    else {
      return [selectedDate, selectedDate];
    }
  }

  handleDownload() {
    this.setState({ busy: true, error: null });
    axios({
      url: this.getTableExportUrl('init'),
      method: "get",
      responseType: "blob"
    })
      .then(res => {
        this.setState({ busy: false, error: null });
        const filename = extractFilenameFromResponse(res);
        download(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        const reader = new FileReader();
        reader.addEventListener("loadend", e => {
          this.setState({
            error: e.srcElement.result,
            busy: false
          });
        });
        reader.readAsText(error);
      });
  }

  constructTableUrl(name, e) {
    //var isInit = name=="init"?true:false;
    var selectedStartDate = (name === "selectedStartDate") ? e : this.state.selectedStartDate ? this.state.selectedStartDate : null;
    var selectedEndDate = (name === "selectedEndDate") ? e : this.state.selectedEndDate ? this.state.selectedEndDate : null;
    var customer_id = (name === "customer_id") ? e : (this.state.customerId);
    var issuer_id = (name === "issuer_id") ? e : (this.state.issuerName ? this.state.issuerName[0] : "");
    var bank_id = (name === "bank_id") ? e : (this.state.bankName ? this.state.bankName[0] : "");
    var qs0 = (selectedStartDate ? "selectedStartDate=" + (selectedStartDate.getFullYear() + '-' + ((selectedStartDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs1 = (selectedEndDate ? "selectedEndDate=" + (selectedEndDate.getFullYear() + '-' + ((selectedEndDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs2 = (customer_id ? "customer_id=" + customer_id : "");
    var qs3 = (issuer_id ? "issuer_id=" + issuer_id : "");
    var qs4 = (bank_id ? "bank_id=" + bank_id : "");

    var qs = "";
    if (qs0.length || qs1.length || qs2.length || qs3.length || qs4.length ) {
      qs = "?" + (qs0.length ? "&" + qs0 : "") + (qs1.length ? "&" + qs1 : "") + (qs2.length ? "&" + qs2 : "") + (qs3.length ? "&" + qs3 : "") + (qs4.length ? "&" + qs4 : "");
    }
    var newUrl = this.state.tableUrl + qs;

    return newUrl;
  }

  constructTableExportUrl(name, e) {
    //var isInit = name=="init"?true:false;
    var selectedStartDate = (name === "selectedStartDate") ? e : this.state.selectedStartDate ? this.state.selectedStartDate : null;
    var selectedEndDate = (name === "selectedEndDate") ? e : this.state.selectedEndDate ? this.state.selectedEndDate : null;
    var customer_id = (name === "customer_id") ? e : (this.state.customerId);
    var issuer_id = (name === "issuer_id") ? e : (this.state.issuerName ? this.state.issuerName[0] : "");
    var bank_id = (name === "bank_id") ? e : (this.state.bankName ? this.state.bankName[0] : "");
    var qs0 = (selectedStartDate ? "selectedStartDate=" + (selectedStartDate.getFullYear() + '-' + ((selectedStartDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs1 = (selectedEndDate ? "selectedEndDate=" + (selectedEndDate.getFullYear() + '-' + ((selectedEndDate.getMonth() + 1).toString()).padStart(2, "0") + '-01') : "");
    var qs2 = (customer_id ? "customer_id=" + customer_id : "");
    var qs3 = (issuer_id ? "issuer_id=" + issuer_id : "");
    var qs4 = (bank_id ? "bank_id=" + bank_id : "");

    var qs = "";
    if (qs0.length || qs1.length || qs2.length || qs3.length || qs4.length) {
      qs = "?" + (qs0.length ? "&" + qs0 : "") + (qs1.length ? "&" + qs1 : "") + (qs2.length ? "&" + qs2 : "") + (qs3.length ? "&" + qs3 : "") + (qs4.length ? "&" + qs4 : "");
    }
    var newUrl = '/api/v1/tools/transactionsexport' + qs;

    return newUrl;
  }

  handleReload(name, e) {
      var newUrl = this.constructTableUrl(name, e);
      this.tableRef.current.setNewUrl(newUrl);
    }

  getTableUrl(init) {
      return this.constructTableUrl(init);
    }

  getTableExportUrl(init) {
      return this.constructTableExportUrl(init);
    }

}
