import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import AddDialog from "./AddDialog";
import EditDialog from "./EditDialog";
import DeleteDialog from "./DeleteDialog";
import {Button, ButtonGroup} from "react-bootstrap";
import { UserContext } from "lib/user";

function renderExclusions(d, t, r, m) {
  if (t === "display") {
    return (
      d &&
      d.map(x => `<div>${m.settings.json.names[x]}&nbsp;(${x})</div>`).join("")
    );
  } else if (t === "filter" || t === "sort") {
    return d && d.map(x => m.settings.json.names[x]);
  }
  return d;
}

export default class BlacklistedApps extends React.Component {
  static title = "Blacklisted Apps";

  tableRef = React.createRef();
  
  constructor(props) {
    super(props);
    this.state = {
      showAdd: false,
      showEdit: false,
      showDelete: false,
      busy: false,
      error: null,
      showEditDataFor: null,
      showDeleteDataFor: null
    };
  }

  render() {
    const { showEditDataFor } = this.state;
    const { showDeleteDataFor } = this.state;
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={BlacklistedApps.title}
          description={
            <p>
              Apps are blacklisted either by package name (bundle id) or by a part
              of their title.
            </p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box>
              <Box.Header>
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" />
                <ButtonGroup bsSize="small">
                  <Button onClick={this.handleAddClick.bind(this,user)}>
                    Add
                  </Button>
                </ButtonGroup>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed"
                  ref={this.tableRef}
                  ajax={{
                    url: "/api/v1/reports/blacklistedapps",
                    dataSrc: "items"
                  }}
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      if(commandData && commandData.action==='delete')
                        this.setState({ showDeleteDataFor: commandData })
                      else if(commandData && commandData.action==='edit')
                        this.setState({ showEditDataFor: commandData })
                      }
                    }
                  }
                  columns={[
                    {
                      title: `<i title="Platform" class="fa fa-info-circle fa-1_4x grey"></i>`,
                      data: "osPlatform",
                      render: DataTable.StockRenderers.platform()
                    },
                    {
                      title: "Package Name",
                      data: "package",
                      defaultContent: ""
                    },
                    {
                      title: "Title",
                      data: "title",
                      defaultContent: ""
                    },
                    {
                      title: "Exclusions",
                      data: "exclusions",
                      defaultContent: "",
                      render: renderExclusions
                    },
                    {
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "20px",
                      render: (d, t, r, m) => ( "<div></div>")
                    },
                    /*DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'edit',
                        package: rowData.package,
                        title: rowData.title,
                        exclusions: rowData.exclusions
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Edit </button></div>")
                    }),*/
                    DataTable.createUserCommandColumn({
                      title: "Actions",
                      data: null,
                      className: "text-left",
                      orderable: false,
                      width: "30px",
                      defultContent: "",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'delete',
                        entryId: rowData._id,
                        osPlatform: rowData.osPlatform,
                        package: rowData.package,
                        title: rowData.title,
                        exclusions: rowData.exclusions,
                        //usernames: rowData.names
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Delete </button></div>")
                    })
                  ]}
                  order={[]}
                />
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>

        {this.state.showAdd && (
          <AddDialog
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showAdd: false
              })
            }}
          />
        )}

        {showEditDataFor && (
          <EditDialog
            offerid={showEditDataFor.offerid}
            cap={showEditDataFor.cap}
            clickRate={showEditDataFor.clickRate}
            onHide={() => {
              this.tableRef.current.reload(() =>
                this.setState({
                  busy: false,
                  error: null,
                  showEdit: false,
                  showEditDataFor: null
                })
              );
            }}
          />
        )}

        {showDeleteDataFor && (
          <DeleteDialog
            entryId={showDeleteDataFor.entryId}
            osPlatform={showDeleteDataFor.osPlatform}
            package={showDeleteDataFor.package}
            title={showDeleteDataFor.title}
            exclusions={showDeleteDataFor.exclusions}
            usernames={showDeleteDataFor.usernames}
            onHide={() => {
              this.tableRef.current.reload();
              this.setState({
                busy: false,
                error: null,
                showDelete: false,
                showDeleteDataFor: null
              })
            }}
          />
        )}
        
      </>
      
    );
  }

  handleAddClick(user,e){
    if (user.tokenHasExpired) {
      e.preventDefault();
      window.location.replace('/login');
    } else {
      this.setState({showAdd: true});
    }
  }
}
