import React from "react";
//import Highcharts from "highcharts";
import moment from "moment-timezone";
import { Row, Col } from "react-bootstrap";
var Highcharts = require('highcharts/highstock');

moment.tz.setDefault("UTC");

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.createChart = this.createChart.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      this.createChart();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data || this.props.id !== prevProps.id) {
      this.createChart();
    }
  }
  /*componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data || nextProps.id !== this.props.id) {
      this.createChart();
    }
  }*/
  render() {
    return (
      <Row>
        <Col md={12}>
          <div height="200" style={{ width: "100%" }} ref="container"></div>
        </Col>
      </Row>
    );
  }

  createChart() {
    const { data } = this.props;

    return Highcharts.StockChart(this.refs.container, {
      credits: { enabled: false },

      title: { text: "" },

      zoomType: "x",

      rangeSelector : {
        enabled: false
      },

      subtitle: {
        text: ""
      },

      tooltip: {
        //shared: true,
        dateTimeLabelFormats: {
          millisecond: "%A, %b %e, %H:%M"
        }
      },

      xAxis: [
        {
          title: { text: "Modified at (UTC)" },
          type: "datetime",
          //softMax: moment().valueOf()
        }
      ],

      yAxis: [
        {
          title: { text: "QPS" },
          allowDecimals: true,
          opposite: false
        }
      ],

      series: [{
        //id:'qps',
        name: 'qps',
        type: "line",
        color: "#3c8dbc",
        tooltip: {
          valueDecimals: 2
        },
        data: data.map(x => ({
          record: x,
          x: moment(x.modified).valueOf(),
          y: x.qps
        })),
        dataGrouping: {
          enabled: true,
          forced: true,
          units: [
            ['minute', [1]]
          ]
        },
        turboThreshold:1440
      }]

    });
  }
}
