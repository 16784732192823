import React from "react"
import $ from "jquery"
import "select2"
import "select2/dist/css/select2.css"

class CampaignPicker extends React.Component {
  render() {
    return (
      <div>
        <select className={this.props.className} ref="ctl" multiple={this.props.multiple}></select>
      </div>
    )
  }
  componentDidMount() {
    let select = $(this.refs.ctl)
    select.select2({
      allowClear: true,
      placeholder: this.props.placeholder || " ",
      minimumInputLength: 10,
      language: {
        inputTooShort() {
          return "Enter hex id of the campaign"
        }
      },
      ajax: {
        delay: 250,
        data(params) {
          return {
            id: params.term
          }
        },
        processResults(data) {
          return {
            results: data.map(x => ({
              id: x.id || x._id,
              text: x.text || x.name
            }))
          }
        },
        url: `/api/v1/campaigns/lookup`,
        error:(xhr,error)=>{
          if(xhr.status === 401){
            window.location.replace('/login');
          }
        }
      }
    })
    select.on("change", e => this.props.onChange($(e.target).val()))
    select.siblings('span.select2-container').css('width', '100%')
  }
}

export default CampaignPicker