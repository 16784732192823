import React from "react";
import PageLayout from "components/PageLayout";

const FaultyComponent = () => {
  throw new Error("This is an error raised by the fault component.");
};

export default class Sandbox extends React.Component {
  static title = "Sandbox";

  render() {
    return (
      <PageLayout
        breadcrumbs={["User"]}
        title={Sandbox.title}
        description="A playground for testing controls and stuff."
      >
        <FaultyComponent />
      </PageLayout>
    );
  }
}
