import React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import DataTable from "components/DataTable";
import { N } from "lib/Functions";
import $ from "jquery";
import moment from "moment-timezone";
import { UserContext } from "lib/user";
import axios from "axios";
import Chart from "./RtbDataHistoryModal/Chart";
import { Spinner } from "components/admin-lte";

export default class DetailReportModal extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.loadHistory = this.loadHistory.bind(this);
    this.loadOsHistory = this.loadOsHistory.bind(this);
    this.colorizeRow = this.colorizeRow.bind(this);
    this.colorizeRow2 = this.colorizeRow2.bind(this);
    this.toggleHiddenColumn = this.toggleHiddenColumn.bind(this);
    this.tableRef = React.createRef();
    this.tableRef2 = React.createRef();
  }

  getDefaultState() {
    return {
      chartData: null,
      partner: this.props.partner,
      reportDate: this.props.reportDate,
      showHistoryFor: null,
      showOsHistory: null,
      showByOsFor: null,
      lastClickedRow: null,
      lastClickedRow2: null,
      error: null,
      busy: false
    };
  }
  
  componentDidMount() {
    this.toggleHiddenColumn(this.tableRef);
  }
  
  render() {
    const {partner, showHistoryFor, showOsHistoryFor, showByOsFor, chartData, busy } = this.state;
    
    const byOsReportBody = () => {
      if (partner) {
        return (
          <>
            
          <div className={showByOsFor?"borderTop":"hidden"}>
            <p>By OS report for country: <b>{showByOsFor?.country}</b></p>
            <br />
              <DataTable
                ref={this.tableRef2}
                className="table table-condensed table-striped table-hover"
                data={showByOsFor?.reportData||[]}
                onUserCommand={({ commandData }) =>{
                  if(commandData.action === "showOsHistory"){
                    this.setState({ 
                      showOsHistoryFor: commandData, 
                      showHistoryFor: null, 
                      lastClickedRow2: commandData.row
                    });
                    
                    this.loadOsHistory(this.props.reportDate, commandData.country, commandData.os);

                    if(commandData.row!==null){
                      this.colorizeRow2(commandData.row);
                    }
                  }
                }}
                columns={[
                  {
                    title: "OS",
                    data: "os",
                    defaultContent: "",
                    width: "70px",
                  },
                  {
                    title: "RTB Cost",
                    data: "rtbCost",
                    className: "text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2)
                  },
                  {
                    title: "RTB Requests",
                    data: "rtbRequests",
                    className: "text-right",
                    defaultContent: "",
                  },
                  DataTable.createUserCommandColumn({
                    title: "QPS",
                    data: "qps",
                    className: "text-right",
                    width: "8%",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2),
                    commandName: "",
                    commandData: ({ rowData, row }) => {
                      return ({
                        action: 'showOsHistory',
                        row: row,
                        id: rowData._id,
                        reportData: rowData.perCountry || [],
                        type: 'os',
                        country: this.state.showByOsFor?.country,
                        os: rowData.os,
                        partner: rowData.partner
                      })
                    }
                  }),
                  {
                    title: "Revenue",
                    data: "revenue",
                    className: "text-right",
                    defaultContent: "",
                    render: (d, t, r, m) => N(d, 2)
                  },
                  {
                    title: "Revenue Exchange",
                    data: "revenueExchange",
                    className: "revenue-exchange-column text-right",
                    width:"140px",
                    defaultContent: "",
                    visible: false
                  },
                  {
                    title: "Installs",
                    data: "installs",
                    className: "text-right",
                    defaultContent: ""
                  },
                  {
                    title: "PIEs",
                    data: "pies",
                    className: "text-right",
                    defaultContent: ""
                  }
                ]}
                order={[[1, "desc"]]}
                footerCallback={(tfoot, data, start, end, display) => {
                  const gt = {
                    rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                    rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                    revenue: display.reduce((acc, cur) => (acc += data[cur].revenue), 0),
                    revenueExchange: display.reduce((acc, cur) => (acc += data[cur].revenueExchange), 0),
                    installs: display.reduce((acc, cur) => (acc += data[cur].installs), 0),
                    pies: display.reduce((acc, cur) => (acc += data[cur].pies), 0),
                  };
                  const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                  const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                  const footer = $(tfoot).parent();

                  // Table totals
                  footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                  footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                  footer.find("#gtrevenue").html(renderMoney(gt.revenue));
                  footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                  footer.find("#gtinstalls").html(renderCount(gt.installs));
                  footer.find("#gtpies").html(renderCount(gt.pies));
                }}
              >
                <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                <tr>
                  <td className="text-right">
                    Totals
                  </td>
                  <td className="text-right" id="gtrtbCost">0</td>
                  <td className="text-right" id="gtrtbRequests">0</td>
                  <td></td>
                  <td className="text-right" id="gtrevenue">0</td>
                  <td className="text-right" id="gtrevenueExchange">0</td>
                  <td className="text-right" id="gtinstalls">0</td>
                  <td className="text-right" id="gtpies">0</td>
                </tr>
                </tfoot>
              </DataTable>
            </div>
          </>
        );
      }
    };

    const chartBody = () => {
      if (partner) {
        return (
          <div className="borderTop" >
            {showHistoryFor &&(
              <p>QPS per minute for country: <b>{showHistoryFor.country}</b></p>
            )}
            {showOsHistoryFor &&(
              <p>QPS per minute for country: <b>{showOsHistoryFor.country}</b> and OS: <b>{showOsHistoryFor.os}</b></p>
            )}
            <br />
            {chartData && (
              <Chart
                id={this.props.partner+"_"+this.props.reportDate+"_"+showHistoryFor?.country||showOsHistoryFor?.country+"_"+showOsHistoryFor?.os}
                data={chartData}
              />
            )}
          </div>
        );
      } else if (busy) {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center">Loading...</div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col md={12}>
              <div className="text-center text-muted">No data</div>
            </Col>
          </Row>
        );
      }
    };
    
    return (
      
      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>RTB report by Country for <b>{this.props.partner}</b> - Report date: <b>{this.props.reportDate}</b></Modal.Title>
        </Modal.Header>
        <UserContext.Consumer>
          {user => (
          <Modal.Body>
            <DataTable.Toolbar tableRef={this.tableRef} bsSize="small" noReloadButton />
            <DataTable
              ref={this.tableRef}
              className="table table-condensed table-striped table-hover"
              data={this.props.reportData||[]}
              onUserCommand={({ commandData }) =>{
                if (user.tokenHasExpired) {
                  window.location.replace('/login');
                  return;
                }
                else {
                  if(commandData.action === "showHistory"){
                    this.setState({ 
                      showHistoryFor: commandData, 
                      showByOsFor: null, 
                      showOsHistoryFor: null, 
                      lastClickedRow: commandData.row
                    });                    
                    this.loadHistory(this.props.reportDate, commandData.country);
                  }
                  else if(commandData.action === "showByOs"){
                    this.setState({ 
                      showByOsFor: commandData, 
                      showHistoryFor: null, 
                      showOsHistoryFor: null,
                      lastClickedRow: commandData.row
                    }, ()=> this.toggleHiddenColumn(this.tableRef2));
                  }
                  
                  if(commandData.row!==null){
                    this.colorizeRow(commandData.row);
                  }
                }
              }}
              columns={[
                {
                  title: "Country",
                  data: "country",
                  defaultContent: "",
                  width: "8%",
                },
                {
                  title: "RTB Cost",
                  data: "rtbCost",
                  className: "text-right",
                  defaultContent: "",
                  render: (d, t, r, m) => N(d, 2)
                },
                { 
                  title: "RTB Requests", 
                  data: "rtbRequests",
                  className: "text-right",
                  defaultContent: "",
                },
                DataTable.createUserCommandColumn({
                  title: "QPS",
                  data: "qps",
                  className: "text-right",
                  width: "8%",
                  defaultContent: "",
                  render: (d, t, r, m) => N(d, 2),
                  commandName: "",
                  commandData: ({rowData, row}) => {
                    return ({
                      action: 'showHistory',
                      row: row,
                      id: rowData._id,
                      reportData: rowData.perCountry || [],
                      country: rowData.country,
                      partner: rowData.partner
                    });
                  }
                }),
                {
                  title: "Revenue",
                  data: "revenue",
                  className: "text-right",
                  defaultContent: "",
                  render: (d, t, r, m) => N(d, 2)
                },
                {
                  title: "Revenue Exchange",
                  data: "revenueExchange",
                  className: "revenue-exchange-column text-right",
                  defaultContent: "",
                  visible: false
                },
                {
                  title: "Installs",
                  data: "installs",
                  className: "text-right",
                  defaultContent: ""
                },
                {
                  title: "PIEs",
                  data: "pies",
                  className: "text-right",
                  defaultContent: ""
                },
                DataTable.createUserCommandColumn({
                  title: "",
                  data: null,
                  className: "text-right",
                  orderable: false,
                  defultContent: "",
                  width: "50px",
                  commandName: "",
                  commandData: ({ rowData, row }) => ({
                    action: 'showByOs',
                    row: row,
                    id: rowData._id,
                    reportData: rowData?.perOS||[],
                    type: 'country',
                    country: rowData.country,
                    partner: rowData.partner,
                    
                  }),
                  render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default btn-small'> By OS </button></div>"),
                }),
              ]}
              order={[[1, "desc"]]}
              footerCallback={(tfoot, data, start, end, display) => {
                const gt = {
                  rtbCost: display.reduce((acc, cur) => (acc += data[cur].rtbCost), 0),
                  rtbRequests: display.reduce((acc, cur) => (acc += data[cur].rtbRequests), 0),
                  revenue: display.reduce((acc, cur) => (acc += data[cur].revenue), 0),
                  revenueExchange: display.reduce((acc, cur) => (acc += data[cur].revenueExchange), 0),
                  installs: display.reduce((acc, cur) => (acc += data[cur].installs), 0),
                  pies: display.reduce((acc, cur) => (acc += data[cur].pies), 0),
                };
                const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                const footer = $(tfoot).parent();
                
                // Table totals
                footer.find("#gtrtbCost").html(renderMoney(gt.rtbCost));
                footer.find("#gtrtbRequests").html(renderCount(gt.rtbRequests));
                footer.find("#gtrevenue").html(renderMoney(gt.revenue));
                footer.find("#gtrevenueExchange").html(renderMoney(gt.revenueExchange));
                footer.find("#gtinstalls").html(renderCount(gt.installs));
                footer.find("#gtpies").html(renderCount(gt.pies));
              }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody></tbody>
              {/* prettier-ignore */}
              <tfoot>
                <tr>
                  <td className="text-right">
                    Totals
                  </td>
                  <td className="text-right" id="gtrtbCost">0</td>
                  <td className="text-right" id="gtrtbRequests">0</td>
                  <td></td>
                  <td className="text-right" id="gtrevenue">0</td>
                  <td className="text-right" id="gtrevenueExchange">0</td>
                  <td className="text-right" id="gtinstalls">0</td>
                  <td className="text-right" id="gtpies">0</td>
                  <td></td>
                </tr>
              </tfoot>
            </DataTable>
            
            <br/>
            
            <div>
              <div>{byOsReportBody()}</div>
              <Spinner visible={busy} />
            </div>
            
            <br/>
            
            {(this.state.showHistoryFor || this.state.showOsHistoryFor) && (
              <div>
                <div>{chartBody()}</div>
                <Spinner visible={busy} />
              </div>
            )}
            
          </Modal.Body>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
  
  toggleHiddenColumn(tableRef){
    if(tableRef){
      const api = tableRef.current.api;
      if(this.state.partner!==null && this.state.partner.indexOf('Exchange')!==-1){
        api.columns(".revenue-exchange-column").visible(true);
      }else{
        api.columns(".revenue-exchange-column").visible(false);
      }
      api.draw(false);
    }    
  }
  
  colorizeRow(row) {
    var api = this.tableRef?.current?.api;
    if (api) {
      api.rows()
        .nodes()
        .to$()
        .removeClass('hilighted-row');
      
      if (row === this.state.lastClickedRow) {
        api.rows([row])
          .nodes()
          .to$()
          .addClass('hilighted-row');
      }
      api.draw(false);
    }
  }

  colorizeRow2(row) {
    var api = this.tableRef2?.current?.api;
    if (api) {
      api.rows()
        .nodes()
        .to$()
        .removeClass('hilighted-row');

      if (row === this.state.lastClickedRow2) {
        api.rows([row])
          .nodes()
          .to$()
          .addClass('hilighted-row');
      }
      api.draw(false);
    }
  }
  
  loadHistory(reportDate, country) {
    this.setState({ busy: true });

    const dateFrom = moment(reportDate).format("YYYY-MM-DD");
    const dateTo = dateFrom;
    const partner = this.props.partner||null;

    axios({
      url: '/api/v1/reports/countryrtbdatahistory',
      params: { partner, country, dateFrom, dateTo }
    })
      .then(res => {
        const chartData = res.data.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );
        this.setState({
          chartData,
          selectedRange: reportDate,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }

  loadOsHistory(reportDate, country, os) {
    this.setState({ busy: true });

    const dateFrom = moment(reportDate).format("YYYY-MM-DD");
    const dateTo = dateFrom;
    const partner = this.props.partner||null;

    axios({
      url: '/api/v1/reports/osrtbdatahistory',
      params: { partner, country, os, dateFrom, dateTo }
    })
      .then(res => {
        const chartData = res.data.sort(
          (a, b) =>
            moment(a.modified).valueOf() - moment(b.modified).valueOf()
        );
        this.setState({
          chartData,
          selectedRange: reportDate,
          busy: false
        });
      })
      .catch(err => {
        this.setState({
          error: err?.response?.data,
          busy: false
        });
      });
  }
}
