import React from "react";
import moment from "moment-timezone";
import Axios from "axios";
import $ from "jquery";
import PageLayout from "components/PageLayout";
import { Box } from "components/admin-lte";
import DateRangePicker from "components/pickers/DateRangePicker";
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Checkbox,
  ButtonToolbar
} from "react-bootstrap";
import { buildGetParams, extractFilenameFromResponse } from "lib/Functions";
import download from "downloadjs";
import buildErrorSummary from "components/ErrorSummary";
import DataTable from "components/DataTable";
import DownloadButton from "components/stock/DownloadButton";
import PreviewButton from "components/stock/PreviewButton";

export default class RtbTraffic extends React.Component {
  static title = "RTB Traffic";

  tableRef = React.createRef();

  state = {
    date: null,
    groupByCountry: false,
    groupByOffer: false,
    groupByPlatform: false,
    busy: false,
    resultset: null
  };

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    //prettier-ignore
    const ranges = {
      "Yesterday": [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day")
      ],
      "This week so far": [
        moment().startOf("isoWeek"), moment().endOf("day")
      ],
      "Last week": [
        moment().subtract(1, "weeks").startOf("isoWeek"),
        moment().subtract(1, "weeks").endOf("isoWeek")
      ],
      "This month so far": [
        moment().startOf("month"), moment().endOf("day")
      ],
      "Last month": [
        moment().subtract(1, "months").startOf("month"),
        moment().subtract(1, "months").endOf("month")
      ]
    };

    return (
      <PageLayout
        breadcrumbs={["Reports"]}
        title={RtbTraffic.title}
        description="Collective report for all RTB publisher accounts."
      >
        {errorSummary != null && (
          <div className="alert alert-danger">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.setState({ error: null })}
            >
              &times;
            </button>
            {errorSummary}
          </div>
        )}
        <Box busy={this.state.busy}>
          <Box.Body>
            <Row>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <ControlLabel>Date</ControlLabel>
                  <DateRangePicker
                    className="form-control"
                    ranges={ranges}
                    onChange={value => this.setState({ date: value })}
                    startDate={moment()
                      .subtract(1, "days")
                      .startOf("day")}
                    endDate={moment()
                      .subtract(1, "days")
                      .endOf("day")}
                    maxDate={moment().subtract(1, "day")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={4}>
                <FormGroup>
                  <ControlLabel>Group By</ControlLabel>
                  <Row>
                    <Col md={12}>
                      <Checkbox
                        inline
                        defaultChecked={this.state.groupByOffer}
                        onChange={e =>
                          this.setState({ groupByOffer: e.target.checked })
                        }
                      >
                        Offer
                      </Checkbox>
                      <Checkbox
                        inline
                        defaultChecked={this.state.groupByCountry}
                        onChange={e =>
                          this.setState({ groupByCountry: e.target.checked })
                        }
                      >
                        Country
                      </Checkbox>
                      <Checkbox
                        inline
                        defaultChecked={this.state.groupByPlatform}
                        onChange={e =>
                          this.setState({ groupByPlatform: e.target.checked })
                        }
                      >
                        Platform
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <ButtonToolbar>
              <DownloadButton
                onClick={this.handleDownloadOrPreview.bind(this, true)}
              />
              <PreviewButton
                onClick={this.handleDownloadOrPreview.bind(this, false)}
              />
            </ButtonToolbar>
          </Box.Footer>
        </Box>
        {this.state.resultset && (
          <Box>
            <Box.Body>
              <DataTable.Toolbar
                tableRef={this.tableRef}
                bsSize="small"
                noReloadButton
              />
              <DataTable
                ref={this.tableRef}
                className="table table-condensed table-striped"
                data={this.state.resultset.records}
                columns={[
                  {
                    title: "Offer ID",
                    data: "offerId",
                    defaultContent: "",
                    className: "columnset-groupBy-offer"
                  },
                  {
                    title: "Offer Name",
                    data: "offerName",
                    defaultContent: "",
                    className: "columnset-groupBy-offer"
                  },
                  {
                    title: "Country",
                    data: "country",
                    defaultContent: "",
                    className: "columnset-groupBy-country"
                  },
                  {
                    title: "Platform",
                    data: "platformName",
                    defaultContent: "",
                    className: "columnset-groupBy-platform"
                  },
                  {
                    title: "Clicks",
                    data: "clicks",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Installs",
                    data: "installs",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "InstallsOpt",
                    data: "installsOpt",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Events",
                    data: "events",
                    className: "text-right",
                    render: DataTable.StockRenderers.count()
                  },
                  {
                    title: "Revenue",
                    data: "revenue",
                    className: "text-right",
                    render: DataTable.StockRenderers.money()
                  }
                ]}
                order={[]}
                // prettier-ignore
                footerCallback={(tfoot, data, start, end, display) => {
                  const pt = {
                    clicks: display.slice(start, end).reduce((acc, cur) => (acc += data[cur].clicks), 0),
                    installs: display.slice(start, end).reduce((acc, cur) => (acc += data[cur].installs), 0),
                    installsOpt: display.slice(start, end).reduce((acc, cur) => (acc += data[cur].installsOpt), 0),
                    events: display.slice(start, end).reduce((acc, cur) => (acc += data[cur].events), 0),
                    revenue: display.slice(start, end).reduce((acc, cur) => (acc += data[cur].revenue), 0)
                  };
                  const gt = {
                    clicks: display.reduce((acc, cur) => (acc += data[cur].clicks), 0),
                    installs: display.reduce((acc, cur) => (acc += data[cur].installs), 0),
                    installsOpt: display.reduce((acc, cur) => (acc += data[cur].installsOpt), 0),
                    events: display.reduce((acc, cur) => (acc += data[cur].events), 0),
                    revenue: display.reduce((acc, cur) => (acc += data[cur].revenue), 0)
                  };
                  const renderCount = d => DataTable.StockRenderers.count()(d, "display");
                  const renderMoney = d => DataTable.StockRenderers.money()(d, "display");
                  const footer = $(tfoot).parent();

                  footer.find("#ptclicks").html(renderCount(pt.clicks));
                  footer.find("#ptinstalls").html(renderCount(pt.installs));
                  footer.find("#ptinstallsOpt").html(renderCount(pt.installsOpt));
                  footer.find("#ptevents").html(renderCount(pt.events));
                  footer.find("#ptrevenue").html(renderMoney(pt.revenue));

                  footer.find("#gtclicks").html(renderCount(gt.clicks));
                  footer.find("#gtinstalls").html(renderCount(gt.installs));
                  footer.find("#gtinstallsOpt").html(renderCount(gt.installsOpt));
                  footer.find("#gtevents").html(renderCount(gt.events));
                  footer.find("#gtrevenue").html(renderMoney(gt.revenue));
                }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody></tbody>
                {/* prettier-ignore */}
                <tfoot>
                  <tr>
                    <td className="text-right top-footer-row" colSpan={4}>
                      Page Totals
                    </td>
                    <td className="text-right top-footer-row" id="ptclicks">0</td>
                    <td className="text-right top-footer-row" id="ptinstalls">0</td>
                    <td className="text-right top-footer-row" id="ptinstallsOpt">0</td>
                    <td className="text-right top-footer-row" id="ptevents">0</td>
                    <td className="text-right top-footer-row" id="ptrevenue">0</td>
                  </tr>
                  <tr>
                    <td className="text-right" colSpan={4}>
                      Table Totals
                    </td>
                    <td className="text-right" id="gtclicks">0</td>
                    <td className="text-right" id="gtinstalls">0</td>
                    <td className="text-right" id="gtinstallsOpt">0</td>
                    <td className="text-right" id="gtevents">0</td>
                    <td className="text-right" id="gtrevenue">0</td>
                  </tr>
                </tfoot>
              </DataTable>
            </Box.Body>
          </Box>
        )}
      </PageLayout>
    );
  }

  //prettier-ignore
  toggleColumns = () => {
    const api = this.tableRef.current.api;
    api
      .columns(`.columnset-groupBy-country`)
      .visible(this.state.groupByCountry);
    api
      .columns(`.columnset-groupBy-offer`)
      .visible(this.state.groupByOffer);
    api
      .columns(`.columnset-groupBy-platform`)
      .visible(this.state.groupByPlatform);
    api.draw();
  };

  handleDownloadOrPreview = excel => {
    this.setState({ busy: true });
    Axios({
      url: "/api/v1/reports/rtbtraffic",
      method: "GET",
      params: buildGetParams({
        fromDateInclusiveUtc: moment(this.state.date[0]).format("YYYY-MM-DD"),
        toDateExclusiveUtc: moment(this.state.date[1])
          .add(1, "second")
          .format("YYYY-MM-DD"),
        groupByOffer: this.state.groupByOffer,
        groupByCountry: this.state.groupByCountry,
        groupByPlatform: this.state.groupByPlatform,
        output: excel ? "Excel" : "Resultset"
      }),
      responseType: excel ? "blob" : "json"
    })
      .then(res => {
        if (excel) {
          this.setState({ error: null, busy: false });
          const filename = extractFilenameFromResponse(res);
          download(
            res.data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        } else {
          this.setState(
            { error: null, busy: false, resultset: res.data },
            this.toggleColumns
          );
        }
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
          ? err.request
          : err.message;

        if (excel) {
          const reader = new FileReader();
          reader.addEventListener("loadend", e => {
            this.setState({
              error: e.srcElement.result,
              busy: false
            });
          });
          reader.readAsText(error);
        } else {
          this.setState({
            error,
            busy: false
          });
        }
      });
  };
}
