import React from "react";

/** A div that wraps the whole site. */
export const Wrapper: React.FC = ({ children }) => (
  <div className="wrapper">{children}</div>
);

/** Contains the page header and content. */
export const ContentWrapper: React.FC = ({ children }) => (
  <div className="content-wrapper">{children}</div>
);

/** Page content. */
export const Content: React.FC = ({ children }) => (
  <div className="content">{children}</div>
);

/** Page header. */
export const ContentHeader: React.FC = ({ children }) => (
  <section className="content-header">{children}</section>
);

/** Page footer. */
export const ContentFooter: React.FC = ({ children }) => (
  <div className="content-footer">{children}</div>
);

/** Contains the logo and navbar. */
export const MainHeader: React.FC = ({ children }) => (
  <header className="main-header">{children}</header>
);

export const MainFooter: React.FC = ({ children }) => (
  <footer className="main-footer">{children}</footer>
);

type AlertProps = {
  type: "danger" | "warning" | "info" | "success";
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  visible?: boolean;
};

export const Alert: React.FC<AlertProps> = ({
  type,
  onClose,
  visible,
  children
}) => {
  if (!visible) return null;
  return (
    <div className={`alert alert-${type}`}>
      <button
        type="button"
        className="close"
        aria-hidden="true"
        onClick={onClose || (() => {})}
      >
        &times;
      </button>
      {children}
    </div>
  );
};

type BoxProps = {
  busy?: boolean;
};

type BoxHeaderProps = {
  title?: string;
};

export const BoxHeader: React.FC<BoxHeaderProps> = ({ title, children }) => (
  <div className="box-header">
    {title && <h3 className="box-title">{title}</h3>}
    {children}
  </div>
);

export const BoxBody: React.FC = ({ children }) => (
  <div className="box-body">{children}</div>
);

export const BoxFooter: React.FC = ({ children }) => (
  <div className="box-footer">{children}</div>
);

export class Box extends React.Component<BoxProps> {
  render() {
    return (
      <div className="box">
        {this.props.children}
        {this.props.busy && (
          <div className="overlay">
            <i className="fa fa-refresh fa-spin"></i>
          </div>
        )}
      </div>
    );
  }
  public static Header = BoxHeader;
  public static Body = BoxBody;
  public static Footer = BoxFooter;
}

export const HorizontalLine = () => (
  <hr
    style={{
      marginTop: "20px",
      marginBottom: "20px",
      border: 0,
      borderTop: "1px solid #eeeeee"
    }}
  />
);

export const Spinner: React.FC<{ visible?: boolean }> = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className="overlay">
      <i className="fa fa-refresh fa-spin"></i>
    </div>
  );
};
